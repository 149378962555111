
import React from "react";
import AccInput from "../../../../common/components/AccInput";
import { BUSINESS_CLASSIFICATIONS, COUNTRIES, US_STATES } from "../../../../common/constants";
import AccOptionSelect from "../../../../common/components/AccInput/variants/AccOptionSelect";
import { Typography } from "@material-ui/core";
import { StyledInput } from "../components/ThemedComponents";
import { useFormikContext } from "formik";
import { UMBCommercialFormValues, UMBYesNoValues } from "../common/umb.interfaces";
import { Checkbox } from "@mui/material";
import { useQueryClient } from "react-query";
import { QueryKeys, useCommercialEntityTypesResponse } from "../../../../api/hooks/graphqlHooks";
import COLORS from "../../../../common/colors";


export const CompanyInformation = () => {

  const { values, handleChange, setFieldValue } = useFormikContext<UMBCommercialFormValues>();
  const queryClient = useQueryClient();
  const commercialEntityTypesData: useCommercialEntityTypesResponse | undefined = queryClient.getQueryData(QueryKeys.COMMERCIAL_ENTITY_TYPES);
  return (
    <div className="start width-100 width-100-mobile">
      <Typography
        style={{ marginBottom: 30, textAlign: "left" }}
        variant="h5"
        className="acc-white bold">Company Information</Typography>
      <div className="row column-mobile width-100-mobile">
        <div className="column start m-right-30 width-30 width-100-mobile m-right-0-mobile m-bottom-15-mobile">
          <Typography
            variant="subtitle1"
            className="acc-white bold">Company Information</Typography>
          <StyledInput
            margin="dense"
            as={AccInput}
            name="company.legalEntityName"
            value={values?.company?.legalEntityName}
            onChange={handleChange}
            label="Legal Entity Name"
          />
          <StyledInput
            margin="dense"
            as={AccInput}
            name="company.dba"
            value={values?.company?.dba}
            onChange={handleChange}
            label="DBA/Disregarded Entity"
          />
          <StyledInput
            margin="dense"
            as={AccInput}
            name="company.taxIdNumber"
            value={values?.company?.taxIdNumber}
            onChange={handleChange}
            label="Legal Entity Tax Identification Number"
          />
          <StyledInput
            margin="dense"
            as={AccOptionSelect}
            name="company.entityType"
            value={values?.company?.entityType}
            onChange={handleChange}
            label="Entity Type"
            select
            options={commercialEntityTypesData?.getCommercialEntityTypes?.map(item => ({ label: item.name, value: item.name }))}
          />
          <StyledInput
            margin="dense"
            as={AccOptionSelect}
            name="company.organizationState"
            value={values?.company?.organizationState}
            onChange={handleChange}
            label="State of Organization"
            options={US_STATES}
          />
          <StyledInput
            margin="dense"
            as={AccInput}
            name="company.yearFounded"
            value={values?.company?.yearFounded}
            onChange={handleChange}
            label="Year Business Established"
            style={{
              marginBottom: 50,
            }}
          />
          <StyledInput
            margin="dense"
            as={AccOptionSelect}
            name="company.businessClassification"
            value={values?.company?.businessClassification}
            onChange={handleChange}
            label="Business Classification"
            options={BUSINESS_CLASSIFICATIONS}
          />
          <StyledInput
            margin="dense"
            as={AccInput}
            name="company.primaryBusinessActivity"
            value={values?.company?.primaryBusinessActivity}
            onChange={handleChange}
            label="Primary Business Activity"
          />
        </div>
        <div className="column start m-left-30 width-30 width-100-mobile m-0-mobile">
          <Typography
            variant="subtitle1"
            className="acc-white bold">Company Address</Typography>
          <StyledInput
            as={AccInput}
            margin="dense"
            name="company.address.line1"
            value={values?.company?.address.line1}
            onChange={handleChange}
            label="Address Line 1"
          />
          <StyledInput
            as={AccInput}
            margin="dense"
            name="company.address.line2"
            value={values?.company?.address.line2}
            onChange={handleChange}
            label="Address Line 2"
          />
          <StyledInput
            as={AccInput}
            margin="dense"
            name="company.address.city"
            value={values?.company?.address.city}
            onChange={handleChange}
            label="City"
          />
          <StyledInput
            as={AccOptionSelect}
            margin="dense"
            name="company.address.state"
            value={values?.company?.address.state}
            onChange={handleChange}
            label="State"
            options={US_STATES}
          />
          <StyledInput
            as={AccInput}
            margin="dense"
            name="company.address.postalCode"
            value={values?.company?.address.postalCode}
            onChange={handleChange}
            label="Zip Code"
          />
          <StyledInput
            as={AccOptionSelect}
            margin="dense"
            name="company.address.country"
            value={values?.company?.address.country}
            onChange={handleChange}
            label="Country"
            options={COUNTRIES}
          />
          <div className="row start-row m-top-15">
            <Checkbox
              checked={values?.company?.mailingAddressDifferentFromPrimary === UMBYesNoValues.YES}
              className="start"
              onChange={() => setFieldValue("company.mailingAddressDifferentFromPrimary", values?.company?.mailingAddressDifferentFromPrimary === UMBYesNoValues.YES ? UMBYesNoValues.NO : UMBYesNoValues.YES)}
              style={{ alignSelf: "flex-start", marginTop: 0, marginRight: 5, color: COLORS.green }}
            />
            <Typography
              onClick={() => setFieldValue("company.mailingAddressDifferentFromPrimary", values?.company?.mailingAddressDifferentFromPrimary === UMBYesNoValues.YES ? UMBYesNoValues.NO : UMBYesNoValues.YES)}
              className="acc-white implicit-link"
              variant="subtitle1"
            >Company mailing address is different from the address above</Typography>
          </div>
        </div>
        {values?.company?.mailingAddressDifferentFromPrimary === UMBYesNoValues.YES && <div className="column start m-left-30 width-30 width-100-mobile m-0-mobile">
          <Typography
            variant="subtitle1"
            className="acc-white bold">Mailing Address</Typography>
          <StyledInput
            as={AccInput}
            margin="dense"
            name="company.mailingAddress.line1"
            value={values?.company?.mailingAddress.line1}
            onChange={handleChange}
            label="Address Line 1"
          />
          <StyledInput
            as={AccInput}
            margin="dense"
            name="company.mailingAddress.line2"
            value={values?.company?.mailingAddress.line2}
            onChange={handleChange}
            label="Address Line 2"
          />
          <StyledInput
            as={AccInput}
            margin="dense"
            name="company.mailingAddress.city"
            value={values?.company?.mailingAddress.city}
            onChange={handleChange}
            label="City"
          />
          <StyledInput
            as={AccOptionSelect}
            margin="dense"
            name="company.mailingAddress.state"
            value={values?.company?.mailingAddress.state}
            onChange={handleChange}
            label="State"
            options={US_STATES}
          />
          <StyledInput
            as={AccInput}
            margin="dense"
            name="company.mailingAddress.postalCode"
            value={values?.company?.mailingAddress.postalCode}
            onChange={handleChange}
            label="Zip Code"
          />
          <StyledInput
            as={AccOptionSelect}
            margin="dense"
            name="company.mailingAddress.country"
            value={values?.company?.mailingAddress.country}
            onChange={handleChange}
            label="Country"
            options={COUNTRIES}
          />
        </div>}
      </div>
    </div>);
};
