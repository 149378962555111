import { Typography } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import React from "react";
import COLORS from "../../../../common/colors";
import LabeledFigure from "../../../../common/components/LabeledFigure";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";
import "./styles.css";
import "../../../../styles/tabletStyles.css";
import { InvestmentStatus } from "../../../../enums";
import {
  formatCurrency,
  formatPercentage,
  isPeriodOver24Months,
  toSentenceCase,
} from "../../../../common/utilities";
import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AccButton, { AccButtonColors } from "../../../../common/components/AccButton";
import { useMediaQuery } from "@mui/material";
import { NUMERICAL_AMOUNTS, THEME_BREAKPOINTS } from "../../../../common/constants";
import Timer from "../../../../common/components/Timer";

interface Props {
  image: string;
  title: string | React.ReactFragment;
  description: string;
  invested?: string;
  distributions?: string | number;
  projectedIRR: number;
  projectedDistribution: number;
  projectedTerm: number;
  targetedMOIC?: number;
  status?: string | JSX.Element;
  id: number;
  isPreviouslyCommittedInvestment?: boolean;
  targetedFundingDate?: string;
  remainingAmount: number;
  createdAt?: string;
  committedDate?: string;
}

interface ColorVariant {
  background: string;
  color: string;
}

const PortfolioInvestment: React.FC<Props> = ({
  image,
  title,
  description,
  invested,
  distributions,
  projectedIRR,
  remainingAmount,
  projectedDistribution,
  projectedTerm,
  status,
  id,
  isPreviouslyCommittedInvestment,
  targetedFundingDate,
  committedDate,
}) => {
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  const laptopLayout = useMediaQuery(THEME_BREAKPOINTS.laptop);

  const colorVariant: ColorVariant = {
    background: "",
    color: "",
  };

  switch (status) {
    case InvestmentStatus.COMMITTED: {
      colorVariant.background = COLORS.lightGreen;
      colorVariant.color = COLORS.darkBlue;
      break;
    }
    case InvestmentStatus.ACTIVE: {
      colorVariant.background = COLORS.lightGreen;
      colorVariant.color = COLORS.darkBlue;
      break;
    }
    case InvestmentStatus.COMPLETED: {
      colorVariant.background = COLORS.darkBlue;
      colorVariant.color = COLORS.lightGreen;
      break;
    }
    case InvestmentStatus.AVAILABLE: {
      colorVariant.background = COLORS.green;
      colorVariant.color = COLORS.darkBlue;
      break;
    }
  }

  const remainingAmountFormatter = () => {
    if (remainingAmount > NUMERICAL_AMOUNTS.ONE_MILLION) {
      return `${formatCurrency(remainingAmount / NUMERICAL_AMOUNTS.ONE_MILLION)}MM`;
    } else {
      return formatCurrency(remainingAmount, 0);
    }
  };

  const parsedDate = new Date(Number(committedDate));

  return (
    <Link
      to={
        isPreviouslyCommittedInvestment
          ? `/investor/portfolio/investment/${id}`
          : `/investor/invest/opportunity/${id}`
      }
      className="row stretch width-100 portfolio-invest m-bottom-30"
      style={{ background: COLORS.darkBlueGradient, borderRadius: 16 }}
    >
      <div
        className="wrap justify-center"
        style={{
          width: mobileLayout ? "125px" : "170px",
          display: "flex",
          backgroundImage: `${COLORS.imageGradientOverlay}, 
          url(${image})`,
          ...(styles.image as React.CSSProperties),
        }}
      >
        <Chip
          label={typeof status === "string" ? toSentenceCase(status) : status}
          size="small"
          style={{
            height: isPreviouslyCommittedInvestment ? "" : "0px",
            position: isPreviouslyCommittedInvestment ? "relative" : "initial",
            bottom: "10px",
            padding: 0,
            backgroundColor: colorVariant.background,
            color: colorVariant.color,
            ...(styles.chip as React.CSSProperties),
          }}
        />
        {!isPreviouslyCommittedInvestment && <div style={{ alignSelf: "flex-end", fontSize: 7, width: "115px", marginTop: laptopLayout ? "100px" : "190px" }}>
          <Timer
            iconSize="2x"
            descriptionStyle={{ fontSize: 5 }}
            valueStyle={{ fontSize: 15 }}
            endDate={targetedFundingDate} />
        </div>}
      </div>
      <div
        className="center flex row wrap"
        style={{ width: "-webkit-fill-available", paddingLeft: mobileLayout ? "10px" : "" }}
      >
        <div className="row center stretch column-mobile width-100 portfolio-title-div">
          <div 
          className="width-100"
          style={{position: "relative", bottom: mobileLayout ? "5px" : ""}}>
            {isPreviouslyCommittedInvestment && (
              <span
                className="acc-green investmentIn"
                style={{
                  fontSize: "11px",
                  fontWeight: 500,
                }}
              >
                Investment in
              </span>
            )}
            <Typography
              variant="h5"
              className="acc-white bold portfolio-title"
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              className="acc-white display-none-mobile portfolio-description asset-summary"
              style={{ fontWeight: 300, width: "90%", fontSize: "11px" }}
            >
              {description}
            </Typography>
          </div>
          {isPreviouslyCommittedInvestment ? (
            <div className="row center stretch width-100-mobile m-top-10-mobile">
              <div
              className="display-none-mobile"
                style={{
                  background: COLORS.white,
                  height: mobileLayout ? 50 : undefined,
                  borderRadius: 16,
                  padding: "10px 20px",
                  marginRight: "20px",
                }}
              >
                <LabeledFigure
                  value={invested}
                  description="Invested"
                  className="acc-blue"
                  alignment="start"
                  valueStyles={{
                    fontSize: 20,
                  }}
                  descriptionStyles={{
                    fontSize: 15,
                    minWidth: 100,
                    textAlign: "left",
                  }}
                />
              </div>
              {!mobileLayout ?
                <div
                  className="row center"
                  style={{
                    borderStyle: "solid",
                    borderColor: COLORS.white,
                    borderRadius: 16,
                    padding: "10px 20px",
                    borderWidth: "1px",
                    minWidth: 200,
                    height: 75,
                  }}
                >
                  <FontAwesomeIcon
                    className="acc-lightgreen m-right-15"
                    style={{
                      fontSize: 21,
                      marginBottom: 20,
                    }}
                    icon={faCalendarAlt}
                  />
                  <span
                    className="acc-white"
                    style={{
                      textAlign: "left",
                      fontSize: mobileLayout ? 8 : 12,
                      lineHeight: "14px",
                    }}
                  >
                    Investment made on{" "}
                    <b className="acc-green">
                      {moment(parsedDate).format("MM/DD/YYYY")}
                    </b>{" "}
                    <br /> at{" "}
                    <b className="acc-green">
                      {moment(parsedDate).format("h:mm A")}
                    </b>
                  </span>
                </div>
               : <div 
                    className="row center" 
                    style={{ position: "relative", right: "120px", top: "75px" }}>
                  <span
                    className="acc-white"
                    style={{
                      textAlign: "left",
                      fontSize: mobileLayout ? 8 : 12,
                    }}
                  >
                    Investment made on{" "}
                    <br/>
                    <span className="acc-green">
                      {moment(parsedDate).format("MM/DD/YYYY")}
                      <span className="acc-white"> at{" "}</span>
                      {moment(parsedDate).format("h:mm A")}
                    </span>
                  </span>
            </div>}
              {status === InvestmentStatus.COMMITTED ? null : (
                <div>
                  <LabeledFigure
                    value={distributions}
                    description="Distributions"
                    className="acc-green"
                    alignment="start"
                    descriptionStyles={{
                      fontSize: 15,
                    }}
                  />
                </div>
              )}
            </div>
          ) : null}
        </div>
        <Divider
          variant="middle"
          style={{
            position: mobileLayout ? "relative" : "initial",
            bottom: isPreviouslyCommittedInvestment ? "35px" : "10px",
            width: "100%",
            margin: 0,
            backgroundColor: "white",
            height: "1px",
          }}
        />
        <div className="row start stretch width-100 m-top-15 m-top-10-mobile">
          <div 
          className="row start stretch width-100"
          style={{position: mobileLayout ? "relative" : "initial", bottom: isPreviouslyCommittedInvestment ? "25px" : ""}}>
            <div
              className="row start wrap"
              style={{ fontSize: "8px", justifyContent: "space-between" }}>
              <LabeledFigure
                value={formatPercentage(projectedIRR)}
                description="Targeted IRR"
                className="acc-white"
                alignment="start"
                containerStyles={{
                  marginRight: mobileLayout ? 0 : 45,
                }}
                descriptionStyles={{
                  color: COLORS.white,
                  textAlign: isPreviouslyCommittedInvestment ? "center" : "left",
                  fontSize: mobileLayout ? "8px" : "10px",
                }}
                valueStyles={{
                  fontSize: "22px",
                }}
              />
              <LabeledFigure
                value={formatPercentage(projectedDistribution)}
                description={mobileLayout ? "Targ. Avg. Cash Yield" : "Targeted Average Cash Yield"}
                className="acc-white"
                alignment="start"
                containerStyles={{
                  marginLeft: mobileLayout ? 40 : 0,
                  marginRight: mobileLayout && isPreviouslyCommittedInvestment ? 15 : 0,
                }}
                descriptionStyles={{
                  color: COLORS.white,
                  textAlign: isPreviouslyCommittedInvestment ? "center" : "left",
                  fontSize: mobileLayout ? "8px" : "10px",
                  width: mobileLayout ? 80 : 90,
                }}
                valueStyles={{
                  fontSize: "22px",
                }}
              />
              <div className="m-left-50-tablet">
                <LabeledFigure
                  value={isPeriodOver24Months(projectedTerm)}
                  className="acc-white m-left-0-mobile"
                  description={mobileLayout ? "Targ. Inv. Period" : "Targeted Investment Period"}
                  alignment="start"
                  containerStyles={{
                    marginTop: mobileLayout ? "10px" : "",
                    marginRight: mobileLayout ? "" : 30,
                    marginLeft: mobileLayout ? "" : 40,
                  }}
                  descriptionStyles={{
                    color: COLORS.white,
                    textAlign: isPreviouslyCommittedInvestment ? "center" : "left",
                    fontSize: mobileLayout ? "8px" : "10px",
                  }}
                  valueStyles={{
                    fontSize: "22px",
                  }}
                />
              </div>
              {!isPreviouslyCommittedInvestment && (
                <div className="m-left-50-tablet">
                  <LabeledFigure
                    value={remainingAmountFormatter()}
                    description="Remaining Amount"
                    className="acc-white  m-left-0-mobile"
                    alignment={isPreviouslyCommittedInvestment ? "center" : "start"}
                    containerStyles={{

                      marginTop: mobileLayout ? "10px" : "",
                      marginRight: "8px",

                    }}
                    descriptionStyles={{
                      color: COLORS.white,
                      textAlign: isPreviouslyCommittedInvestment ? "center" : "left",
                      fontSize: mobileLayout ? "8px" : "10px",
                    }}
                    valueStyles={{
                      fontSize: mobileLayout ? "20px" : "22px",
                      color: COLORS.lightGreen,
                    }}
                  />
                </div>
              )}
              {isPreviouslyCommittedInvestment && mobileLayout ?
               <div className="m-left-50-tablet">
               <LabeledFigure
                 value={invested}
                 description="Invested"
                 className="acc-white m-left-0-mobile"
                 alignment="start"
                 containerStyles={{
                   marginTop: mobileLayout ? "10px" : "",
                   marginRight: "95px",
                   width: "0px",
                 }}
                 descriptionStyles={{
                   color: COLORS.white,
                   textAlign: isPreviouslyCommittedInvestment ? "center" : "left",
                   fontSize: mobileLayout ? "8px" : "10px",
                 }}
                 valueStyles={{
                   fontSize: mobileLayout ? "20px" : "22px",
                   color: COLORS.lightGreen,
                 }}
               />
             </div>
              : null }
            </div>
            <AccButton
              label={isPreviouslyCommittedInvestment ? "View" : "access"}
              className="bold m-top-10 display-none-mobile display-none-tablet"
              color={AccButtonColors.GREEN}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                borderRadius: 16,
                backgroundColor: COLORS.lightGreen,
                fontSize: "12px",
                width: "110px",
                height: "35px",
                borderStyle: "none",
                fontFamily: isPreviouslyCommittedInvestment ? undefined : "Gilroy",
              }}
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

const styles = {
  chip: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500,
  },
  image: {
    //width: "25%",
    height: "100%",
    borderRadius: "16px 0px 0px 16px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
};
export default PortfolioInvestment;
