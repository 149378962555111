import React from "react";
import { Typography } from "@material-ui/core";
import { Field } from "formik";
import COLORS from "../../colors";
import AccInput from "../AccInput";
import { formatCurrency } from "../../utilities";
import { Asset } from "../../types";


interface Props {
  asset: Asset | any;
  limitInvestmentAmount: (floatValue: any) => boolean;
  setTotalInvestmentAmountInput: Function;
}

const CashFlowSimulationInput: React.FC<Props> = ({
  asset,
  limitInvestmentAmount,
  setTotalInvestmentAmountInput,
}) => {

  return (
    <div>
      <Typography
        className="acc-white bold"
        style={{ fontSize: 18 }}
      >
        Input Test Investment Amount
      </Typography>
      <Field
        type="number"
        min="0"
        autoFocus
        isAllowed={limitInvestmentAmount}
        onInput={(e: any) => {
          if (e.target.value.toString().startsWith("$0")) {
            e.target.value = "$0";
          }
        }}
        onChange={(e: any) => {
          if (e.target.value > 0) {
            setTotalInvestmentAmountInput(e.target.value);
          } else {
            setTotalInvestmentAmountInput(0);
          }
        }}
        name="investmentAmount"
        as={AccInput}
        currencyMask
        fullWidth
        placeholder={`${formatCurrency(asset.minimumInvestmentAmount)}`}
        InputProps={{
          inputProps: {
            min: asset.minimumInvestmentAmount,
            step: asset.investmentAmountIncrement,
            style: {
              textAlign: "left",
              fontSize: 50,
              color: COLORS.lightGreen,
              fontWeight: 600,
              padding: 0,
              marginTop: 0,
              marginRight: 10,
            },
          },
        }}
      />
    </div>
  );
};

export default CashFlowSimulationInput;