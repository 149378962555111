import React from "react";
import { Typography } from "@material-ui/core";
import ClickableLinkWithIcon from "../../../../../common/components/ClickableLinkWithIcon";
import { faFilePdf } from "@fortawesome/pro-light-svg-icons";
import { DocumentSignature } from "../../../../../common/types";

interface Props {
  data: DocumentSignature[] | undefined;
}

const SubscriptionDocuments: React.FC<Props> = ({ data }) => {
  return (
    <div
      className="width-100 m-top-30 row column-mobile"
    >
      <div className="width-100-mobile">
        <Typography
          variant="h5"
          className="acc-blue bold">
          Subscription Documents
        </Typography>
        <div className="m-top-15">
          {data?.map(item => (
            <ClickableLinkWithIcon
              key={item.id}
              text="Download signed documents.pdf"
              icon={faFilePdf}
              link={item.downloadURL}
            />))}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDocuments;