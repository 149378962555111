import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { UMBCommercialFormValues, UMBYesNoValues } from "./common/umb.interfaces";
import RadioSelectCard from "../../../common/components/RadioSelectCard";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import AccInput, { AccMaskedInputTypes } from "../../../common/components/AccInput";
import { isNil, over100OrLessThan18YearsAgo, validateEmail, validatePhoneNumber } from "../../../common/utilities";
import { COUNTRIES, CountryCodes } from "../../../common/constants";
import AccOptionSelect from "../../../common/components/AccInput/variants/AccOptionSelect";
import { FormPages, formSelectOptions, commercialFormPageOrder, commercialFormInitialValues, nestedBeneficialOwnerFormValues } from "./common/umb.consts";
import COLORS from "../../../common/colors";
import { Checkbox, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationSquare } from "@fortawesome/pro-solid-svg-icons";
import { MFARequest } from "./components/MFARequest";
import { StyledInput, StyledRadio } from "./components/ThemedComponents";
import PageWrapper from "../../../common/components/PageWrapper";
import { LabelValueInput } from "../../../common/types";
import { CompanyInformation } from "./components/CompanyInformation";
import { CommercialDueDilligence } from "./components/CommercialDueDilligence";
import { CommercialPersonalInformation } from "./components/CommercialPersonalInformation";
import { FormControlLabel } from "@mui/material";
import { ACHAuthorizedSigners } from "./components/ACHAuthorizedSigners";
import { CommercialArtifactsUpload } from "./components/CommercialArtifactsUpload";
import { commercialOnboard } from "../../../api/rest/umb";
import ReactJson from "react-json-view";
import { useCommercialEntityTypes } from "../../../api/hooks/graphqlHooks";
import { CommercialPatriotAct } from "./components/CommercialPatriotAct";
import AccStateSelect from "../../../common/components/AccInput/variants/AccStateSelect";


export const UMBCommercialOnboard = () => {
  const [accessTermsChecked, setAccessTermsChecked] = useState(false);
  const [onboardResponse, setOnboardResponse] = useState({});
  const [onboardSubmitting, setOnboardSubmitting] = useState(false);
  const [numberOfbeneficialOwners, setNumberOfbeneficialOwners] = useState<number | undefined>(undefined);
  const [applicantIsbeneficialOwner, setApplicantIsBeneficialOwner] = useState<number>(0);
  const [numberOfAuthorizedSigners, setNumberOfAuthorizedSigners] = useState(1);
  const [uploadedArtifacts, setUploadedArtifacts] = useState([]);


  const { isFetching: commercialEntityTypesFetching } = useCommercialEntityTypes();

  // If applicant is only beneficial owner:
  const beneficialOwnersAdditionalFormsNeeded = !(
    (numberOfbeneficialOwners === 1 && applicantIsbeneficialOwner === 1)
    || numberOfbeneficialOwners === 0
  );
  const [nestedCurrentPages, setNestedCurrentPages] = useState<{
    [key: string]: {
      currentPageNumber: number;
      totalNumberOfPages: number;
      nestedPagesVisible: Function;
      nestedValues: any;
    }
  }>({
    [FormPages.IsUSCitizen]: {
      currentPageNumber: 1,
      totalNumberOfPages: 2,
      nestedPagesVisible: (value: string) => value === UMBYesNoValues.NO,
      nestedValues: {
        [1]: "isUSCitizen",
        [2]: "isResidentAlien",
      },
    },
    [FormPages.CountryOfBirth]: {
      currentPageNumber: 1,
      totalNumberOfPages: 2,
      nestedPagesVisible: (value: string) => value !== CountryCodes.UnitedStates,
      nestedValues: {
        [1]: "countryOfBirth",
        [2]: [
          "countryOfCitizenship",
          "yearOfUSCitizenship",
        ],
      },
    },
    [FormPages.BeneficialOwners]: {
      currentPageNumber: 1,
      totalNumberOfPages: numberOfbeneficialOwners ?? 0 - applicantIsbeneficialOwner,
      nestedPagesVisible: () => beneficialOwnersAdditionalFormsNeeded,
      nestedValues: nestedBeneficialOwnerFormValues,
    },
  });
  const beneficialOwnerIndex = (nestedCurrentPages[FormPages.BeneficialOwners].currentPageNumber) + applicantIsbeneficialOwner;


  useEffect(() => {
    if (numberOfbeneficialOwners != null) {

      // const totalNumberOfBeneficiaries = (numberOfbeneficialOwners + applicantIsbeneficialOwner)
      setNestedCurrentPages({
        ...nestedCurrentPages,
        [FormPages.BeneficialOwners]: {
          ...nestedCurrentPages[FormPages.BeneficialOwners],
          currentPageNumber: 1,
          totalNumberOfPages: numberOfbeneficialOwners - applicantIsbeneficialOwner,
          nestedPagesVisible: () => beneficialOwnersAdditionalFormsNeeded,
        },
      });
    }

  }, [numberOfbeneficialOwners, applicantIsbeneficialOwner]);

  // Non-UMB Form values:
  const formPageOrder = commercialFormPageOrder;
  const [currentPage, setCurrentPage] = useState<FormPages>(formPageOrder[0]);

  const [accountName, setAccountName] = useState<string>("");

  const isForwardDisabled = (values: Partial<UMBCommercialFormValues>, errors: any) => {
    if (currentPage === FormPages.DateOfBirth) {
      if (over100OrLessThan18YearsAgo(values.applicant?.dateOfBirth ?? "")) {
        return true;
      }
    }
    if (
      currentPage === FormPages.ApplicantPersonalInformation) {
      return (
        (isNil(values.applicant?.firstName) || errors.firstName) ||
        (isNil(values.applicant?.lastName) || errors.lastName) ||
        (isNil(values.applicant?.dateOfBirth) || errors.dateOfBirth || over100OrLessThan18YearsAgo(values.applicant?.dateOfBirth ?? "")) ||
        (isNil(values.applicant?.address) || errors.address) ||
        (isNil(values.applicant?.address?.city) || errors.city) ||
        (isNil(values.applicant?.address?.state) || errors.state || values.applicant?.address?.state === "Test") ||
        (isNil(values.applicant?.address?.country) || errors.country) ||
        (isNil(values.applicant?.countryOfBirth) || errors.countryOfBirth) ||
        (isNil(values.applicant?.ssn) || errors.ssn) ||
        (isNil(values.applicant?.phone) || errors.phone || !validatePhoneNumber(values.applicant?.phone.toString() ?? "")) ||
        (isNil(values.applicant?.email) || errors.email) ||
        (isNil(values.applicant?.address?.country) || errors.country) ||
        (values.applicant?.isUSCitizen === UMBYesNoValues.YES && (
          (isNil(values.applicant?.identification.driversLicenseNumber)) ||
          (isNil(values.applicant?.identification.driversLicenseIssuingState)) ||
          (isNil(values.applicant?.identification.driversLicenseExpirationDate))
        )) ||
        (values.applicant?.isUSCitizen === UMBYesNoValues.NO && (
          (isNil(values.applicant?.identification.passportNumber)) ||
          (isNil(values.applicant?.identification.passportIssuingCountry)) ||
          (isNil(values.applicant?.identification.passportExpirationDate))
        )));

    }
    if (currentPage === FormPages.AccountName) return isNil(accountName);
    if (currentPage === FormPages.USAPatriotAct) return false;

    if (currentPage === FormPages.SubmitScreen) {
      return !accessTermsChecked;
    }
    if (currentPage === FormPages.IsUSCitizen && nestedCurrentPages[FormPages.IsUSCitizen].currentPageNumber === 2) {
      return values.applicant?.isResidentAlien === UMBYesNoValues.NO;
    }
    if (currentPage === FormPages.CountryOfBirth && nestedCurrentPages[FormPages.CountryOfBirth].currentPageNumber === 2) {
      return isNil(values.applicant?.countryOfCitizenship) && isNil(values.applicant?.yearOfUSCitizenship) || errors.yearOfUSCitizenship;
    }
    if (currentPage === FormPages.IsAuthorizedManager) {
      return values.applicant?.isAuthorizedManager !== UMBYesNoValues.YES;
    }
    if (currentPage === FormPages.AuthorizedACHSigners) {
      return values?.authorizedSignersWithACHAuthority?.some((signerForm, idx) => {
        if (signerForm == null) {
          // If array index is greater than current number of form elements displayed, disregard them
          if ((idx + 1) > numberOfAuthorizedSigners && idx !== 0) {
            return false;
          } else {
            return true;
          }
        }
        return (
          isNil(signerForm?.firstName) ||
          isNil(signerForm?.lastName) ||
          (isNil(signerForm?.email) || !validateEmail(signerForm?.email)) ||
          isNil(signerForm?.title)
        );
      });
    }
    if (currentPage === FormPages.UploadArtifacts) {
      return uploadedArtifacts?.length === 0;
    }


    // Nested pages:
    if (nestedCurrentPages[currentPage]) {
      const { nestedValues, currentPageNumber } = nestedCurrentPages[currentPage];
      if (typeof nestedValues[currentPageNumber] === "string") {
        // Pages with one value
        // @ts-ignore
        return isNil(values?.applicant?.[nestedValues[currentPageNumber]]);
        // errors[nestedValues[currentPageNumber]] || 
      } else if (Array.isArray(nestedValues[currentPageNumber])) {
        // Pages with multiple values

        // Beneficial owners are a pain since they are nested form values and cant use string object notation for 2x nesting
        if (currentPage === FormPages.BeneficialOwners) {
          return nestedValues[currentPageNumber].some((fieldName: string) => {
            let validationValue;
            // 2x nested fields (benficiaryOwner.address.[field] + benficiaryOwner.identification.[field] in this case)
            if (fieldName.includes("address.line1")) {
              const actualFieldName = fieldName.split(".")[2];
              // @ts-ignore
              validationValue = values?.beneficialOwners?.[beneficialOwnerIndex]?.address?.[actualFieldName];
            } else if (fieldName.includes("address.city")) {
              const actualFieldName = fieldName.split(".")[2];
              // @ts-ignore
              validationValue = values?.beneficialOwners?.[beneficialOwnerIndex]?.address?.[actualFieldName];
            } else if (fieldName.includes("address.state")) {
              const actualFieldName = fieldName.split(".")[2];
              // @ts-ignore
              validationValue = values?.beneficialOwners?.[beneficialOwnerIndex]?.address?.[actualFieldName];
            } else if (fieldName.includes("address.postalCode")) {
              const actualFieldName = fieldName.split(".")[2];
              // @ts-ignore
              validationValue = values?.beneficialOwners?.[beneficialOwnerIndex]?.address?.[actualFieldName];
            } else if (fieldName.includes("address.country")) {
              const actualFieldName = fieldName.split(".")[2];
              // @ts-ignore
              validationValue = values?.beneficialOwners?.[beneficialOwnerIndex]?.address?.[actualFieldName];
            } else if (fieldName.includes("identification.driversLicenseNumber") && values.applicant?.isUSCitizen === UMBYesNoValues.YES) {
              const actualFieldName = fieldName.split(".")[2];
              // @ts-ignore
              validationValue = values?.beneficialOwners?.[beneficialOwnerIndex]?.identification?.[actualFieldName];
            } else if (fieldName.includes("identification.driversLicenseIssuingState") && values.applicant?.isUSCitizen === UMBYesNoValues.YES) {
              const actualFieldName = fieldName.split(".")[2];
              // @ts-ignore
              validationValue = values?.beneficialOwners?.[beneficialOwnerIndex]?.identification?.[actualFieldName];
            } else if (fieldName.includes("identification.driversLicenseExpirationDate") && values.applicant?.isUSCitizen === UMBYesNoValues.YES) {
              const actualFieldName = fieldName.split(".")[2];
              // @ts-ignore
              validationValue = values?.beneficialOwners?.[beneficialOwnerIndex]?.identification?.[actualFieldName];
            } else if (fieldName.includes("identification.passportNumber") && values.applicant?.isUSCitizen === UMBYesNoValues.NO) {
              const actualFieldName = fieldName.split(".")[2];
              // @ts-ignore
              validationValue = values?.beneficialOwners?.[beneficialOwnerIndex]?.identification?.[actualFieldName];
            } else if (fieldName.includes("identification.passportIssuingCountry") && values.applicant?.isUSCitizen === UMBYesNoValues.NO) {
              const actualFieldName = fieldName.split(".")[2];
              // @ts-ignore
              validationValue = values?.beneficialOwners?.[beneficialOwnerIndex]?.identification?.[actualFieldName];
            } else if (fieldName.includes("identification.passportExpirationDate") && values.applicant?.isUSCitizen === UMBYesNoValues.NO) {
              const actualFieldName = fieldName.split(".")[2];
              // @ts-ignore
              validationValue = values?.beneficialOwners?.[beneficialOwnerIndex]?.identification?.[actualFieldName];
            } else {
              // 1x nested fields (ex. benficiaryOwner.firstName)
              const actualFieldName = fieldName.split(".")[1];
              // @ts-ignore
              validationValue = values?.beneficialOwners?.[beneficialOwnerIndex]?.[actualFieldName];
            }
            return isNil(validationValue);
          });
        } else {
          // @ts-ignore
          return nestedValues[currentPageNumber].some((value: string) => isNil(values[value]));
        }
      } else {
        return false;
      }
    }


    if (currentPage === FormPages.DateOfBirth || currentPage === FormPages.SSN) {
      return isNil(values?.applicant?.[currentPage]);
    }

    if (currentPage === FormPages.CompanyInformation) {
      return (
        (isNil(values.company?.legalEntityName) || errors.legalEntityName) ||
        (isNil(values.company?.dba) || errors.dba) ||
        (isNil(values.company?.taxIdNumber) || errors.taxIdNumber) ||
        (isNil(values.company?.address) || errors.address) ||
        (isNil(values.company?.address?.city) || errors.city) ||
        (isNil(values.company?.address?.state) || errors.state || values.company?.address?.state === "Test") ||
        (isNil(values.company?.address?.country) || errors.country) ||
        (isNil(values.company?.entityType) || errors.entityType) ||
        (isNil(values.company?.organizationState) || errors.organizationState) ||
        (isNil(values.company?.yearFounded) || errors.yearFounded) ||
        (isNil(values.company?.businessClassification) || errors.businessClassification) ||
        (isNil(values.company?.primaryBusinessActivity) || errors.primaryBusinessActivity)
      );
    }

    if (currentPage === FormPages.DueDilligence) {
      return (
        (isNil(values.dueDilligence?.operatesMoneyServicesBusiness)) ||
        (isNil(values.dueDilligence?.hasOrProcessesATMTransactions)) ||
        (isNil(values.dueDilligence?.hasOverseasOffice)) ||
        (values.dueDilligence?.hasOverseasOffice === UMBYesNoValues.YES && isNil(values.dueDilligence?.overseasOfficeLocation)) ||
        (isNil(values.dueDilligence?.hasPEPOwnerOrController)) ||
        (values.dueDilligence?.hasPEPOwnerOrController === UMBYesNoValues.YES && isNil(values.dueDilligence?.ownerOrControllerPEPDescription)) ||
        (isNil(values.dueDilligence?.controlledByNonUSPerson)) ||
        (values.dueDilligence?.controlledByNonUSPerson === UMBYesNoValues.YES && (isNil(values.dueDilligence?.foreignOwnershipDescription)
          || isNil(values.dueDilligence?.businessHeadquarters))) ||
        (isNil(values.dueDilligence?.engagesInImportExportActivity)) ||
        (values.dueDilligence?.engagesInImportExportActivity === UMBYesNoValues.YES &&
          isNil(values.dueDilligence?.anticpatedImportExportGoodsInvolved)) ||
        (values.dueDilligence?.engagesInImportExportActivity === UMBYesNoValues.YES &&
          (isNil(values.dueDilligence?.anticpatedImportExportGoodsInvolved) ||
            (isNil(values.dueDilligence?.expectedImportExportTradeDollarAmount) || isNil(values.dueDilligence?.anticipatedImportExportCountries)) ||
            (isNil(values.dueDilligence?.majorImportExportCustomersOrSuppliers)))) ||
        (isNil(values.dueDilligence?.isThirdPartyProcessorOrOriginator)) ||
        (values.dueDilligence?.isThirdPartyProcessorOrOriginator === UMBYesNoValues.YES &&
          isNil(values.dueDilligence?.processorOrOriginatorPaymentNatureDescription))
      );
    }
    if (currentPage === FormPages.NumberOfBeneficialOwners) {
      return numberOfbeneficialOwners == null;
    }
    if (currentPage === FormPages.IsApplicantBeneficialOwner) {
      return false;
    }

    // Default check if form value name is currentPage name
    // @ts-ignore
    return isNil(values?.[currentPage]);
  };
  const submitOnboard = async (values: any) => {
    setOnboardSubmitting(true);
    // adding to values an array with all attached files
    values.files = uploadedArtifacts;
    const res = await commercialOnboard(values);
    setOnboardResponse(res?.data);
    setOnboardSubmitting(false);
  };


  return (
    <PageWrapper
      showLoader={onboardSubmitting || commercialEntityTypesFetching}
    >
      <Formik
        initialValues={commercialFormInitialValues}
        enableReinitialize
        // validationSchema={UMBCommercialFormValueSchema}
        onSubmit={submitOnboard}
      >
        {({ values, handleChange, setFieldValue, errors }) => {

          return (
            <div
              className="column center stretch"
              style={{ background: COLORS.darkBlueGradient, borderRadius: 16, height: "fit-content", minHeight: 750, padding: 35 }}>
              <div
                className="width-100 column center justify-center"
                style={{ minHeight: 650 }}>
                {currentPage === FormPages.MFALogin && (
                  <MFARequest onSuccess={() => setCurrentPage(FormPages.USAPatriotAct)} />
                )}
                {currentPage === FormPages.AccountName && (
                  <>
                    <Typography
                      style={{ marginBottom: 30, textAlign: "center" }}
                      variant="h5"
                      className="acc-white bold"
                    >Please input your investment account Nickname</Typography>
                    <StyledInput
                      as={AccInput}
                      autoFocus
                      className="width-50 width-100-mobile"
                      style={{ borderColor: AccButtonColors.WHITE }}
                      name="accountName"
                      value={accountName}
                      onChange={(e: any) => setAccountName(e.target.value)}
                      label="Nickname"
                    />
                  </>
                )}
                {currentPage === FormPages.USAPatriotAct && (
                  <CommercialPatriotAct />
                )}
                {currentPage === FormPages.IsAuthorizedManager && (
                  <>
                    <Typography
                      style={{ marginBottom: 30, textAlign: "center" }}
                      variant="h5"
                      className="acc-white bold">Are you an authorized individual with significant management responsibility for this entity?</Typography>
                    <div className="radio-container column center">
                      {formSelectOptions.yesNoOptions.map((item: LabelValueInput) => (
                        <RadioSelectCard
                          key={item.label}
                          onClick={() => setFieldValue("applicant.isAuthorizedManager", item.value)}
                          selected={values.applicant?.isAuthorizedManager === item.value}
                          label={item.label}
                          cardStyle={{ marginBottom: 35, width: "80%" }}
                          className="width-100-mobile"
                        />
                      ))}
                    </div>
                  </>
                )}
                {currentPage === FormPages.DateOfBirth && (
                  <>
                    <Typography
                      style={{ marginBottom: 30, textAlign: "center" }}
                      variant="h5"
                      className="acc-white bold">What is your date of birth?</Typography>
                    <StyledInput
                      autoFocus
                      as={AccInput}
                      type="date"
                      className="width-50 width-100-mobile"
                      name="applicant.dateOfBirth"
                      value={values.applicant?.dateOfBirth}
                      onChange={handleChange}
                      label="Date of Birth"
                      // helperText={errors.dateOfBirth}
                      // error={errors.dateOfBirth}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </>
                )}
                {currentPage === FormPages.IsUSCitizen && (
                  <>
                    {nestedCurrentPages[FormPages.IsUSCitizen].currentPageNumber === 1 && (
                      <>
                        <Typography
                          style={{ marginBottom: 30, textAlign: "center" }}
                          variant="h5"
                          className="acc-white bold">Are you a US citizen?</Typography>
                        <div className="radio-container column center">
                          {formSelectOptions.yesNoOptions.map((item: LabelValueInput) => (
                            <RadioSelectCard
                              key={item.label}
                              onClick={() => setFieldValue("applicant.isUSCitizen", item.value)}
                              selected={values.applicant?.isUSCitizen === item.value}
                              label={item.label}
                              cardStyle={{ marginBottom: 35, width: "80%" }}
                              className="width-100-mobile"
                            />
                          ))}
                        </div>
                      </>
                    )}
                    {nestedCurrentPages[FormPages.IsUSCitizen].currentPageNumber === 2 && (
                      <>
                        <Typography
                          style={{ marginBottom: 30, textAlign: "center" }}
                          variant="h5"
                          className="acc-white bold">Are you a Resident alien?</Typography>
                        {values.applicant?.isResidentAlien === UMBYesNoValues.NO &&
                          <div
                            className="m-bottom-30 row center width-35 width-100-mobile"
                            style={{ background: COLORS.red, padding: 20, borderRadius: 16 }}>
                            <FontAwesomeIcon
                              icon={faExclamationSquare}
                              color={COLORS.darkBlue}
                              size="lg"
                              className="m-right-15" />
                            <Typography
                              variant="caption"
                              className="acc-darkblue bold condensed-lineheight">Unfortunately we cannot open an account if you are not a resident alien.</Typography>
                          </div>
                        }
                        <div className="radio-container column center">
                          {formSelectOptions.yesNoOptions.map((item: LabelValueInput) => (
                            <RadioSelectCard
                              key={item.label}
                              onClick={() => setFieldValue("applicant.isResidentAlien", item.value)}
                              selected={values.applicant?.isResidentAlien === item.value}
                              label={item.label}
                              cardStyle={{ marginBottom: 35, width: "80%" }}
                              className="width-100-mobile"
                            />
                          ))}
                        </div>
                      </>
                    )}
                  </>
                )}
                {currentPage === FormPages.CountryOfBirth && (
                  <>
                    {nestedCurrentPages[FormPages.CountryOfBirth].currentPageNumber === 1 && (
                      <>
                        <Typography
                          style={{ marginBottom: 30, textAlign: "center" }}
                          variant="h5"
                          className="acc-white bold">Please provide the country you were born in.
                        </Typography>
                        <StyledInput
                          as={AccStateSelect}
                          autoFocus
                          style={{ height: "100px" }}
                          margin="normal"
                          className="width-50 width-100-mobile"
                          select
                          name="applicant.countryOfBirth"
                          value={values.applicant?.countryOfBirth}
                          onChange={handleChange}
                          label="Select Country"
                        />
                      </>
                    )}
                    {nestedCurrentPages[FormPages.CountryOfBirth].currentPageNumber === 2 && (
                      <>
                        {values.applicant?.countryOfBirth !== CountryCodes.UnitedStates &&
                          values.applicant?.isUSCitizen === UMBYesNoValues.YES &&
                          <>
                            <Typography
                              style={{ marginBottom: 30, textAlign: "center" }}
                              variant="h5"
                              className="acc-white bold">What year did you become a U.S. citizen?</Typography>
                            <StyledInput
                              as={AccInput}
                              autoFocus
                              className="width-50 width-100-mobile"
                              type="number"
                              name="applicant.yearOfUSCitizenship"
                              value={values.applicant?.yearOfUSCitizenship}
                              onChange={handleChange}
                              label="Year of Citizenship"
                            // helperText={errors.yearOfUSCitizenship && touched.yearOfUSCitizenship ? errors.yearOfUSCitizenship : null}
                            // error={errors.yearOfUSCitizenship}
                            />
                          </>
                        }
                        {values.applicant?.countryOfBirth !== CountryCodes.UnitedStates &&
                          values.applicant?.isUSCitizen === UMBYesNoValues.NO &&
                          values.applicant?.isResidentAlien === UMBYesNoValues.YES &&
                          <>
                            <Typography
                              style={{ marginBottom: 30, textAlign: "center" }}
                              variant="h5"
                              className="acc-white bold">Please provide your country of citizenship:</Typography>
                            <StyledInput
                              as={AccOptionSelect}
                              autoFocus
                              className="width-50 width-100-mobile"
                              name="applicant.countryOfCitizenship"
                              value={values.applicant?.countryOfCitizenship}
                              onChange={handleChange}
                              label="Country"
                              options={COUNTRIES}
                            />
                          </>}
                      </>
                    )}
                  </>
                )}
                {currentPage === FormPages.SSN && (
                  <>
                    <Typography
                      style={{ marginBottom: 30, textAlign: "center" }}
                      variant="h5"
                      className="acc-white bold">Please provide your social security number.</Typography>
                    <StyledInput
                      as={AccInput}
                      autoFocus
                      maskedInput
                      mask={AccMaskedInputTypes.SSN}
                      className="width-50 width-100-mobile"
                      name="applicant.ssn"
                      value={values.applicant?.ssn}
                      onChange={handleChange}
                      label="Social Security Number"
                    // helperText={errors.ssn && touched.ssn ? errors.ssn : null}
                    // error={errors.ssn}
                    />
                  </>
                )}
                {currentPage === FormPages.CompanyInformation && (
                  <CompanyInformation />
                )}
                {currentPage === FormPages.DueDilligence && (
                  <CommercialDueDilligence />
                )}
                {currentPage === FormPages.ApplicantPersonalInformation && (
                  <CommercialPersonalInformation />
                )}
                {currentPage === FormPages.NumberOfBeneficialOwners && (
                  <div
                    className="column center justify-center "
                    style={{ height: "100%" }}>
                    <Typography
                      style={{ marginBottom: 30, textAlign: "center" }}
                      variant="h5"
                      className="acc-white bold">Beneficial Owners</Typography>
                    <Typography
                      style={{ marginBottom: 30, textAlign: "center" }}
                      variant="h6"
                      className="acc-white bold width-70">
                      How many individuals own directly or
                      indirectly through any agreement, arrangement,
                      understanding, relationship,
                      or otherwise - 25% or more of the equity
                      interests of the legal entity?
                    </Typography>
                    <div className="row center">
                      {[0, 1, 2, 3, 4].map((item) => ({ value: item, label: item })).map(numericalSelectOption => (
                        <FormControlLabel
                          key={numericalSelectOption.value}
                          style={{ color: COLORS.white }}
                          onChange={() => setNumberOfbeneficialOwners(numericalSelectOption.value)}
                          name={"numberOfbeneficialOwners"}
                          value={numberOfbeneficialOwners}
                          className={numericalSelectOption.value < 4 ? "m-right-50 column" : "column"}
                          control={<StyledRadio />}
                          label={numericalSelectOption.label}
                          checked={numberOfbeneficialOwners === numericalSelectOption.value}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {currentPage === FormPages.IsApplicantBeneficialOwner && (
                  <div className="column center">
                    <Typography
                      style={{ marginBottom: 30, textAlign: "center" }}
                      variant="h5"
                      className="acc-white bold">
                      Do you personally own directly or indirectly through
                      any agreement, arrangement, understanding, relationship,
                      or otherwise - 25% or more of the equity interests of the legal entity?
                    </Typography>
                    <div className="radio-container column center">
                      {formSelectOptions.yesNoOptions.map((item: LabelValueInput) => (
                        <RadioSelectCard
                          key={item.label}
                          onClick={() => {
                            setApplicantIsBeneficialOwner(item.value === UMBYesNoValues.YES ? 1 : 0);
                            setFieldValue("applicantIsBeneficialOwner", item.value);
                            const currentBeneficialOwners = [...values?.beneficialOwners ?? []] ?? [];
                            if (item.value === UMBYesNoValues.YES) {
                              // Add applicant to beneficial owners array
                              currentBeneficialOwners.unshift(values?.applicant!);
                              const newBeneficialOwners = currentBeneficialOwners;
                              setFieldValue("beneficialOwners", newBeneficialOwners);
                            } else {
                              // Remove applicant from beneficial owners array
                              const newBeneficialOwners = currentBeneficialOwners.filter((item) => item?.ssn !== values?.applicant?.ssn);
                              setFieldValue("beneficialOwners", newBeneficialOwners);
                            }
                          }}
                          selected={values?.applicantIsBeneficialOwner === item.value}
                          label={item.label}
                          cardStyle={{ marginBottom: 35, width: "80%" }}
                          className="width-100-mobile"
                        />
                      ))}
                    </div>
                  </div>
                )}
                {currentPage === FormPages.BeneficialOwners && (
                  <CommercialPersonalInformation
                    beneficialOwnerIndex={
                      (nestedCurrentPages[FormPages.BeneficialOwners].currentPageNumber) + applicantIsbeneficialOwner
                    } />
                )}
                {currentPage === FormPages.AuthorizedACHSigners && (
                  <ACHAuthorizedSigners
                    numberOfAuthorizedSigners={numberOfAuthorizedSigners}
                    setNumberOfAuthorizedSigners={setNumberOfAuthorizedSigners}
                  />
                )}
                {currentPage === FormPages.UploadArtifacts && (
                  <CommercialArtifactsUpload
                    uploadedArtifacts={uploadedArtifacts}
                    setUploadedArtifacts={setUploadedArtifacts}
                  />
                )}
                {currentPage === FormPages.SubmitScreen && (
                  <div className="width-60 width-100-mobile column center">
                    <Typography
                      style={{ marginBottom: 30, textAlign: "center" }}
                      variant="h5"
                      className="acc-white bold"><span className="acc-text acc-lightgreen">access</span>Wallet Account Confirmation</Typography>
                    <div className="row start-row width-100-mobile">
                      <Checkbox
                        checked={accessTermsChecked}
                        onClick={() => setAccessTermsChecked(!accessTermsChecked)}
                        style={styles.checkbox}
                      />
                      <Typography
                        style={{ fontSize: 16, lineHeight: "20px" }}
                        className="acc-white"
                        variant="subtitle1">
                        By clicking this box, you agree to the terms and conditions of the {" "}
                        <a
                          className="acc-green underlined-link"
                          href="#">
                          <span
                            className="acc-text"
                          >access</span>Wallet Account Customer Agreement</a>, the {" "}
                        <a
                          className="acc-lightgreen underlined-link"
                          href="#">UMB Privacy Notice</a> and the {" "}
                        <a
                          className="acc-lightgreen underlined-link"
                          href="#">UMB Consent to Electronic Signatures and Records</a>,
                        and the creation of your <span className="acc-green acc-text">access</span><span className="bold acc-text">Wallet</span> bank account with UMB Bank, N.A.
                      </Typography>
                    </div>
                    <AccButton
                      label="Confirm"
                      color={isForwardDisabled(values, errors) ? AccButtonColors.GREEN_OUTLINE : AccButtonColors.GREEN}
                      onClick={() => submitOnboard(values)}
                      style={{ marginTop: 30, width: 150 }} />
                    {/* <p style={{ width: 500, color: COLORS.white }}>{onboardResponse}</p> */}
                    <ReactJson
                      style={{ backgroundColor: "rgba(0,0,0,0)" }}
                      theme="ocean"
                      src={onboardResponse} />

                  </div>
                )}
              </div>
              <div className="width-100 column end">
                {(currentPage !== FormPages.SubmitScreen && currentPage !== FormPages.MFALogin) && (
                  <div
                    className="row width-30 column-reverse-mobile width-100-mobile"
                    style={{ justifyContent: "flex-end" }}>
                    {currentPage !== FormPages.WalletType && (<AccButton
                      color={AccButtonColors.WHITE}
                      className="white-button width-100-mobile m-top-15-mobile"
                      style={{ width: 130 }}
                      // disabled={!values.walletType}
                      label="Back"
                      onClick={() => {
                        // Handle beneficialOwners page either displaying or not
                        if (currentPage === FormPages.AuthorizedACHSigners) {
                          if (beneficialOwnersAdditionalFormsNeeded) {
                            return setCurrentPage(FormPages.BeneficialOwners);
                          } else {
                            return setCurrentPage(FormPages.IsApplicantBeneficialOwner);
                          }
                        }
                        if (Object.keys(nestedCurrentPages).includes(currentPage)) {
                          const { nestedPagesVisible, currentPageNumber } = nestedCurrentPages[currentPage];
                          // @ts-ignore
                          if (nestedPagesVisible(values?.applicant?.[currentPage])) {
                            if (currentPageNumber > 1) {
                              return setNestedCurrentPages({
                                ...nestedCurrentPages,
                                [currentPage]: {
                                  ...nestedCurrentPages[currentPage],
                                  currentPageNumber: currentPageNumber - 1,
                                },
                              });
                            }
                          } else if (currentPageNumber > 1) {
                            return setNestedCurrentPages({
                              ...nestedCurrentPages,
                              [currentPage]: {
                                ...nestedCurrentPages[currentPage],
                                currentPageNumber: 1,
                              },
                            });
                          }
                        }
                        window.scrollTo(0, 0);
                        const prevIndex = formPageOrder[formPageOrder.indexOf(currentPage) - 1];
                        setCurrentPage(prevIndex);
                      }}
                    />)}
                    <AccButton
                      style={{ width: 130 }}
                      submitOnEnter
                      color={isForwardDisabled(values, errors) ? AccButtonColors.GREEN_OUTLINE : AccButtonColors.GREEN}
                      className="m-left-15 width-100-mobile m-0-mobile"
                      // @ts-ignore
                      disabled={isForwardDisabled(values, errors)}
                      label="Next"
                      onClick={() => {
                        // Handle beneficialOwners page either displaying or not
                        if (currentPage === FormPages.IsApplicantBeneficialOwner) {
                          if (beneficialOwnersAdditionalFormsNeeded) {
                            return setCurrentPage(FormPages.BeneficialOwners);
                          } else {
                            return setCurrentPage(FormPages.AuthorizedACHSigners);
                          }
                        }
                        if (Object.keys(nestedCurrentPages).includes(currentPage)) {
                          const { nestedPagesVisible, currentPageNumber, totalNumberOfPages } = nestedCurrentPages[currentPage];
                          // @ts-ignore
                          if (nestedPagesVisible(values?.applicant?.[currentPage])) {
                            if (currentPageNumber < totalNumberOfPages) {
                              return setNestedCurrentPages({
                                ...nestedCurrentPages,
                                [currentPage]: {
                                  ...nestedCurrentPages[currentPage],
                                  currentPageNumber: currentPageNumber + 1,
                                },
                              });
                            }
                          }
                        }
                        window.scrollTo(0, 0);
                        const nextIndex = formPageOrder[formPageOrder.indexOf(currentPage) + 1];
                        return setCurrentPage(nextIndex);
                      }}
                    />
                  </div>)}
              </div>
            </div>
          );
        }
        }
      </Formik>
    </PageWrapper>
  );
};

const styles = {
  checkbox: {
    color: COLORS.green,
    "&.Mui-checked": {
      color: COLORS.green,
    },
    padding: "0px 10px 0px 0px",
    marginBottom: "60px",
    bottom: "5px",
  },
};
