import React from "react";
import COLORS from "../../../../../common/colors";
import LabeledFigure from "../../../../../common/components/LabeledFigure";
import moment from "moment";
import { AssetStatus } from "../../../../../enums";

interface Props {
  closingDate: string;
  vertical: string;
  assetType: string;
  assetClass: string;
  investmentJurisdiction: string;
  creditType: string;
  assetStatus?: string;
  taxDocument: string;
  investmentStructure: string;
}

const OpportunityDetailsBox: React.FC<Props> = (props) => {

  const showAdditionalAssetData = props.assetStatus === AssetStatus.AVAILABLE
    || props.assetStatus === AssetStatus.COMING_SOON;

  return (
    <div>
      {showAdditionalAssetData ?
        <>
          <LabeledFigure
            {...props}
            alignment="start"
            value="Targeted Funding Date"
            description={moment(props.closingDate).format("MM/DD/YYYY")}
            className="acc-white font-size-mobile-3"
            valueStyles={{ fontSize: 18 }}
            descriptionStyles={{ fontSize: 15, color: COLORS.lightGreen, fontWeight: 300 }}
          />
          <br />
        </> : null}
      <LabeledFigure
        {...props}
        alignment="start"
        value="Vertical"
        description={props.vertical}
        className="acc-white font-size-mobile-3"
        valueStyles={{ fontSize: 18 }}
        descriptionStyles={{ fontSize: 15, color: COLORS.lightGreen, fontWeight: 300 }}
      />
      <br />
      <LabeledFigure
        {...props}
        alignment="start"
        value="Asset Class"
        description={props.assetClass}
        className="acc-white font-size-mobile-3"
        valueStyles={{ fontSize: 18 }}
        descriptionStyles={{ fontSize: 15, color: COLORS.lightGreen, fontWeight: 300 }}
      />
      <br />
      <LabeledFigure
        {...props}
        alignment="start"
        value="Asset Type"
        description={props.assetType}
        className="acc-white font-size-mobile-3"
        valueStyles={{ fontSize: 18 }}
        descriptionStyles={{ fontSize: 15, color: COLORS.lightGreen, fontWeight: 300 }}
      />
      <br />
      <LabeledFigure
        {...props}
        alignment="start"
        value="Investment Jurisdiction"
        description={props.investmentJurisdiction}
        className="acc-white font-size-mobile-3"
        valueStyles={{ fontSize: 18 }}
        descriptionStyles={{ fontSize: 15, color: COLORS.lightGreen, fontWeight: 300 }}
      />
      <br />
      <LabeledFigure
        {...props}
        alignment="start"
        value="Market Type"
        description={props.creditType}
        className="acc-white font-size-mobile-3"
        valueStyles={{ fontSize: 18 }}
        descriptionStyles={{ fontSize: 15, color: COLORS.lightGreen, fontWeight: 300 }}
      />
      <br />
      <LabeledFigure
        {...props}
        alignment="start"
        value="Tax Document"
        description={props.taxDocument}
        className="acc-white font-size-mobile-3"
        valueStyles={{ fontSize: 18 }}
        descriptionStyles={{ fontSize: 15, color: COLORS.lightGreen, fontWeight: 300 }}
      />
      <br />
      <LabeledFigure
        {...props}
        alignment="start"
        value="Investment Structure"
        description={props.investmentStructure}
        className="acc-white font-size-mobile-3"
        valueStyles={{ fontSize: 18 }}
        descriptionStyles={{ fontSize: 15, color: COLORS.lightGreen, fontWeight: 300 }}
      />
    </div>
  );
};

export default OpportunityDetailsBox;
