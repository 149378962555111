import React, { useContext } from "react";
import {
  Route,
} from "react-router-dom";
import { Assets } from "../screens/Admin/Assets";
import User from "../screens/Admin/Users";
import UsersForm from "../screens/Admin/Users/UsersForm";
import UserType from "../screens/Admin/UserTypes";
import UserTypesForm from "../screens/Admin/UserTypes/UserTypesForm";
import AssetClass from "../screens/Admin/AssetClasses";
import AssetClassesForm from "../screens/Admin/AssetClasses/AssetClassesForm";
import Account from "../screens/Admin/Accounts";
import AccountsForm from "../screens/Admin/Accounts/AccountsForm";
import WalletType from "../screens/Admin/WalletTypes";
import WalletTypesForm from "../screens/Admin/WalletTypes/WalletTypesForm";
import AssetItem from "../screens/Admin/AssetItems";
import AssetItemsForm from "../screens/Admin/AssetItems/AssetItemForm";
import AssetPartner from "../screens/Admin/AssetPartners";
import AssetPartnersForm from "../screens/Admin/AssetPartners/AssetPartnerForm";
import AssetStatus from "../screens/Admin/AssetStatuses";
import AssetStatusesForm from "../screens/Admin/AssetStatuses/AssetStatusesForm";
import AssetType from "../screens/Admin/AssetTypes";
import AssetTypesForm from "../screens/Admin/AssetTypes/AssetTypesForm";
import Vertical from "../screens/Admin/Verticals";
import VerticalsForm from "../screens/Admin/Verticals/VerticalsForm";
import Investment from "../screens/Admin/Investments";
import InvestmentsForm from "../screens/Admin/Investments/InvestmentsForm";
import Wallets from "../screens/Admin/Wallets";
import WalletsForm from "../screens/Admin/Wallets/WalletsForm";
import AssetsForm from "../screens/Admin/Assets/AssetsForm";
import { SessionContext } from "../context";
import { UserTypes } from "../common/types";
import Profile from "../screens/Profile";
import AddressForm from "../screens/Admin/Addresses/AddressForm";
import FundingRequestsForm from "../screens/Admin/FundingRequests/FundingRequestsForm";
import FundingRequests from "../screens/Admin/FundingRequests";
import DealType from "../screens/Admin/DealTypes";
import DealTypesForm from "../screens/Admin/DealTypes/DealTypesForm";

export const AdminRoutes = () => {
  const { user } = useContext(SessionContext);

  return (
    <>
      <Route
        exact
        path={["/admin", "/"]}>
        <Assets />
      </Route>
      <Route
        exact
        path="/admin/assets">
        <Assets />
      </Route>
      <Route
        exact
        path="/admin/assets/*">
        <Route
          exact
          path={["/admin/assets/add", "/admin/assets/:id"]}>
          <AssetsForm />
        </Route>
      </Route>
      <Route
        exact
        path="/admin/assetitems">
        <AssetItem />
      </Route>
      <Route
        exact
        path="/admin/assetitems/*">
        <Route
          exact
          path={["/admin/assetitems/add", "/admin/assetitems/:id"]}>
          <AssetItemsForm />
        </Route>
      </Route>
      <Route
        exact
        path="/admin/assetpartners">
        <AssetPartner />
      </Route>
      <Route
        exact
        path="/admin/assetpartners/*">
        <Route
          exact
          path={["/admin/assetpartners/add", "/admin/assetpartners/:id"]}>
          <AssetPartnersForm />
        </Route>
      </Route>
      <Route
        exact
        path="/admin/assetstatuses">
        <AssetStatus />
      </Route>
      <Route
        exact
        path="/admin/assetstatuses/*">
        <Route
          exact
          path={["/admin/assetstatuses/add", "/admin/assetstatuses/:id"]}>
          <AssetStatusesForm />
        </Route>
      </Route>
      <Route
        exact
        path="/admin/assettypes">
        <AssetType />
      </Route>
      <Route
        exact
        path="/admin/assettypes/*">
        <Route
          exact
          path={["/admin/assettypes/add", "/admin/assettypes/:id"]}>
          <AssetTypesForm />
        </Route>
      </Route>
      <Route
        exact
        path="/admin/dealtypes">
        <DealType />
      </Route>
      <Route
        exact
        path="/admin/dealtypes/*">
        <Route
          exact
          path={["/admin/dealtypes/add", "/admin/dealtypes/:id"]}>
          <DealTypesForm />
        </Route>
      </Route>
      <Route
        exact
        path="/admin/assetclasses">
        <AssetClass />
      </Route>
      <Route
        exact
        path="/admin/assetclasses/*">
        <Route
          exact
          path={["/admin/assetclasses/add", "/admin/assetclasses/:id"]}>
          <AssetClassesForm />
        </Route>
      </Route>
      <Route
        exact
        path="/admin/verticals">
        <Vertical />
      </Route>
      <Route
        exact
        path="/admin/verticals/*">
        <Route
          exact
          path={["/admin/verticals/add", "/admin/verticals/:id"]}>
          <VerticalsForm />
        </Route>
      </Route>
      <Route
        exact
        path="/admin/profile">
        <Profile />
      </Route>
      {user?.userType?.name === UserTypes.ADMIN && (
        <>
          <Route
            exact
            path="/admin/users">
            <User />
          </Route>
          <Route
            exact
            path="/admin/users/*">
            <Route
              exact
              path={["/admin/users/add", "/admin/users/:id"]}>
              <UsersForm />
            </Route>
          </Route>
          <Route
            exact
            path="/admin/usertypes">
            <UserType />
          </Route>
          <Route
            exact
            path="/admin/usertypes/*">
            <Route
              exact
              path={["/admin/usertypes/add", "/admin/usertypes/:id"]}>
              <UserTypesForm />
            </Route>
          </Route>
          <Route
            exact
            path="/admin/accounts">
            <Account />
          </Route>
          <Route
            exact
            path="/admin/accounts/*">
            <Route
              exact
              path={["/admin/accounts/add", "/admin/accounts/:id"]}>
              <AccountsForm />
            </Route>
          </Route>
          <Route
            exact
            path="/admin/address/*">
            <Route
              exact
              path={["/admin/address/add/:id", "/admin/address/:id"]}>
              <AddressForm />
            </Route>
          </Route>
          <Route
            exact
            path="/admin/wallettypes">
            <WalletType />
          </Route>
          <Route
            exact
            path="/admin/wallettypes/*">
            <Route
              exact
              path={["/admin/wallettypes/add", "/admin/wallettypes/:id"]}>
              <WalletTypesForm />
            </Route>
          </Route>
          <Route
            exact
            path="/admin/fundingrequests">
            <FundingRequests />
          </Route>
          <Route
            exact
            path="/admin/fundingrequests/*">
            <Route
              exact
              path={["/admin/fundingrequests/add", "/admin/fundingrequests/:id"]}>
              <FundingRequestsForm />
            </Route>
          </Route>
          <Route
            exact
            path="/admin/investments">
            <Investment />
          </Route>
          <Route
            exact
            path="/admin/investments/*">
            <Route
              exact
              path={["/admin/investments/add", "/admin/investments/:id"]}>
              <InvestmentsForm />
            </Route>
          </Route>
          <Route
            exact
            path="/admin/wallets">
            <Wallets />
          </Route>
          <Route
            exact
            path="/admin/wallets/*">
            <Route
              exact
              path={["/admin/wallets/add", "/admin/wallets/:id"]}>
              <WalletsForm />
            </Route>
          </Route></>)}
    </>
  );
};