import React, { useContext, useState } from "react";
import { Typography, Modal, Box, Divider, useMediaQuery } from "@material-ui/core";
import PageCard from "../../common/components/PageCard";
import PageTitle from "../../common/components/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpenText, faHeadphones } from "@fortawesome/pro-solid-svg-icons";
import COLORS from "../../common/colors";
import AccButton, { AccButtonColors } from "../../common/components/AccButton";
import AccInput from "../../common/components/AccInput";
import { Formik, Form, Field } from "formik";
import { sendEmail } from "../../api/rest/customerSupport";
import AccOptionSelect from "../../common/components/AccInput/variants/AccOptionSelect";
import { toast } from "react-toastify";
import LoadingAnimation from "../../common/components/LoadingAnimation";
import { TicketCategory } from "../../enums";
import { SessionContext } from "../../context";
import { UserTypes } from "../../common/types";
import { THEME_BREAKPOINTS } from "../../common/constants";


const Help: React.FC = () => {

  const { user: sessionUser } = useContext(SessionContext);
  const [openMessageModal, setOpenMessageModal] = useState<boolean>(false);
  const [openPhoneCallModal, setOpenPhoneCallModal] = useState<boolean>(false);
  const [isEmailRequestSubmitting, setIsEmailRequestSubmitting] = useState<boolean>(false);
  const toggleMessageModal = () => setOpenMessageModal(!openMessageModal);
  const togglePhoneCallModal = () => setOpenPhoneCallModal(!openPhoneCallModal);
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const sendCustomerSupportEmail = async (values: any) => {
    setIsEmailRequestSubmitting(true);
    try {
      await sendEmail(values.description, values.subject, values.requestType);
      setOpenMessageModal(false);
      setIsEmailRequestSubmitting(false);
    } catch (err: any) {
      setIsEmailRequestSubmitting(false);
      toast(err.message || err.msg || err.toString() || "There was an error requesting support!", {
        type: toast.TYPE.ERROR,
      });
    }
  };

  const emailSupportFormValues = {
    description: "",
    subject: "",
    requestType: TicketCategory.EMAIL_REQUEST,
  };

  const phoneSupportFormValues = {
    description: "",
    subject: "",
    requestType: TicketCategory.PHONE_CALL_REQUEST,
  };

  if (isEmailRequestSubmitting) {
    return <LoadingAnimation />;
  }

  return (
    <div>
      <PageTitle
        title="Help Center"
        subtitle="The access team is here to help you"
      />
      <div className="column start m-top-50">
        {sessionUser?.userType.name === UserTypes.ADVISOR ?
          <PageCard
            className="width-65 width-100-mobile m-bottom-30"
            style={{
              display: "flex",
              background: COLORS.darkBlueGradient,
              position: "relative",
              overflow: "hidden",
              height: "200px",
            }}
          >
            <FontAwesomeIcon
              style={styles.backgroundIcon as React.CSSProperties}
              icon={faHeadphones}
              size="2x"
              color={COLORS.green} />
            <div className="column start stretch">
              <Typography
                style={{ fontSize: 26 }}
                className="acc-white bold">
                Request a Phone Call
              </Typography>
              <div className="row stretch">
                <Typography
                  variant="h6"
                  className="light acc-white"
                  style={{ fontSize: mobileLayout ? "15px" : "20px" }}>
                  Hours today:&nbsp;
                </Typography>
                <Typography
                  variant="h6"
                  className="light acc-white"
                  style={{ fontSize: mobileLayout ? "15px" : "20px" }}>
                  10:00 AM - 7:00 PM
                </Typography>
              </div>
              <AccButton
                color={AccButtonColors.GREEN}
                width={"65%"}
                label={(<span className="acc-black bold">Request Now</span>)}
                onClick={togglePhoneCallModal} />
            </div>
          </PageCard>
          : null}
        <Modal
          open={openPhoneCallModal}
          onClose={togglePhoneCallModal}
        >
          <Box
            sx={{
              ...styles.modal,
              height: "fit-content",
            }}
            className="width-90-mobile">
            <Formik
              initialValues={phoneSupportFormValues}
              onSubmit={(data, { resetForm }) => {
                sendCustomerSupportEmail(data);
                resetForm();
              }}
            >
              {({ values, handleChange }) => (
                <Form>
                  <Typography
                    variant="h5"
                    className="acc-darkblue bold">
                    Request a phone call
                  </Typography>
                  <br />
                  <Divider />
                  <br />
                  <div className="column start">
                    <Typography
                      variant="h6"
                      className="bold acc-darkgray">
                      What is your call related to?
                    </Typography>
                    <Field
                      as={AccOptionSelect}
                      name="subject"
                      value={values.subject}
                      onChange={handleChange}
                      options={SUBJECTS}
                    />
                    <Typography
                      style={{ marginTop: 15 }}
                      variant="h6"
                      className="bold acc-darkgray">
                      Tell us more
                    </Typography>
                    <Field
                      name="description"
                      type="text"
                      value={values.description}
                      as={AccInput}
                      required
                      multiline
                      minRows={5} />
                    <Divider
                      className="width-100"
                      style={{ marginTop: 15 }}
                    />
                  </div>
                  <div className="m-top-50 column center">
                    <Typography
                      variant="subtitle2"
                      className="acc-darkgray"
                      style={{ textAlign: "center" }}
                    >
                      By clicking &rdquo;Request a phone call&rdquo;, you authorize access to contact you at your phone number
                      and record that phone call for quality and training purposes.
                    </Typography>
                    <Divider
                      variant="middle"
                      className="width-100"
                      style={{ marginTop: 15 }}
                    />
                    <div className="row column-mobile center m-top-30">
                      <AccButton
                        color={AccButtonColors.WHITE}
                        label="Cancel"
                        style={{ backgroundColor: COLORS.white, marginRight: mobileLayout ? "" : "15px" }}
                        onClick={togglePhoneCallModal}
                        className="width-60-mobile m-bottom-15-mobile"
                        width="250px"
                      />
                      <AccButton
                        color={AccButtonColors.GREEN}
                        style={{ marginLeft: mobileLayout ? "" : "15px" }}
                        label="Request a phone call"
                        className="width-60-mobile"
                        width="250px"
                        type="submit"
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Box>
        </Modal>
        <PageCard
          className="width-65 width-100-mobile"
          style={{
            display: "flex",
            background: COLORS.darkBlueGradient,
            position: "relative",
            overflow: "hidden",
            height: "200px",
          }}>
          <FontAwesomeIcon
            style={styles.backgroundIcon as React.CSSProperties}
            icon={faEnvelopeOpenText}
            size="2x"
            color={COLORS.green} />
          <div className="column start stretch">
            <Typography
              style={{ fontSize: 26 }}
              className="acc-white bold">
              Email Us
            </Typography>
            <Typography
              variant="h6"
              className="light acc-white"
              style={{ fontSize: mobileLayout ? "15px" : "20px" }}>
              We&apos;ll respond in a few business days.
            </Typography>
            <AccButton
              color={AccButtonColors.GREEN}
              width={"65%"}
              label={(<span className="acc-black bold">Request Now</span>)}
              onClick={toggleMessageModal} />
          </div>
        </PageCard>
        <Modal
          open={openMessageModal}
          onClose={toggleMessageModal}>
          <Box
            sx={{
              ...styles.modal,
              height: "fit-content",
            }}
            className="width-90-mobile">
            <Typography
              variant="h5"
              className="acc-darkblue bold">
              Send us a message
            </Typography>
            <br />
            <Divider />
            <br />
            <Formik
              initialValues={emailSupportFormValues}
              onSubmit={(data, { resetForm }) => {
                resetForm();
                sendCustomerSupportEmail(data);
              }}
            >
              {({ values, handleChange }) => (
                <Form>
                  <Typography
                    variant="h6"
                    className="bold acc-darkgray">
                    What is your email related to?
                  </Typography>
                  <Field
                    as={AccOptionSelect}
                    name="subject"
                    value={values.subject}
                    onChange={handleChange}
                    options={SUBJECTS}
                  />
                  <Typography
                    style={{ marginTop: 15 }}
                    variant="h6"
                    className="bold acc-darkgray">
                    Tell us more
                  </Typography>
                  <Field
                    name="description"
                    type="text"
                    value={values.description}
                    as={AccInput}
                    required
                    multiline
                    minRows={5} />
                  <Divider
                    className="width-100"
                    style={{ marginTop: 15 }}
                  />
                  <div
                    className="m-top-30 column-mobile"
                    style={{ display: "flex", justifyContent: "center" }}>
                    <AccButton
                      color={AccButtonColors.WHITE}
                      label="Cancel"
                      style={{ backgroundColor: COLORS.white, marginRight: mobileLayout ? "" : "15px" }}
                      onClick={toggleMessageModal}
                      className="m-bottom-15-mobile"
                      width="250px"
                    />
                    <AccButton
                      color={AccButtonColors.GREEN}
                      style={{ marginLeft: mobileLayout ? "" : "15px" }}
                      label="Send"
                      type="submit"
                      width="250px"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

const SUBJECTS = [
  { label: "Investment", value: "Investment" },
  { label: "Wallet", value: "Wallet" },
  { label: "Funding", value: "Funding" },
  { label: "External Bank Account", value: "External Bank Account" },
  { label: "Portfolio", value: "Portfolio" },
  { label: "Other", value: "Other" },
];

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
    width: "45%",
    overflowY: "scroll",
  },
  radioButton: {
    color: "#1BD0AE",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  backgroundIcon: {
    position: "absolute",
    top: "30px",
    right: "30px",
    fontSize: "8em",
    color: "#1bd0ae",
    opacity: "0.25",
  },
};

export default Help;