import gql from "graphql-tag";
import { makeGraphqlRequest } from "../../utils";
import { gql as gqlv2 } from "graphql-request";

export const CREATE_ASSET_TYPE = gql`
  mutation CreateAssetType($input: AssetTypeInput!) {
    createAssetType(input: $input) {
      name
    }
  }
`;

export const UPDATE_ASSET_TYPE = gql`
  mutation UpdateAssetType($id: String!, $input: AssetTypeInput!) {
    updateAssetType(_id: $id, input: $input) {
      id
      name
      assetClass {
        name
      }
    }
  }
`;

export const DELETE_ASSET_TYPE = gql`
  mutation DeleteAssetType($id: String!) {
    deleteAssetType(_id: $id) {
      id
      assetTypeId
    }
  }
`;

const GET_ASSET_TYPE_V2 = gqlv2`
  query GetAssetType($assetTypeId: Int!) {
    getAssetType(assetTypeId: $assetTypeId) {
      id
      assetTypeId
      name
      assetClass {
          name
          id
          assetClassId
          vertical {
            name
          }
        }
    }
  }
`;

export const getAssetType = (assetTypeId: number) => makeGraphqlRequest(GET_ASSET_TYPE_V2, { assetTypeId });

export const GET_ASSET_TYPES_V2 = gqlv2`
  query GetAssetTypes {
    getAssetTypes {
      id
      assetTypeId
      name
      assetClass {
          name
          id
          assetClassId
          vertical {
            name
          }
        }
    }
  }
`;

export const getAssetTypes = () => makeGraphqlRequest(GET_ASSET_TYPES_V2);