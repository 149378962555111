// Replace local.apiHost with http://localhost:4000 to hit local server
const apiEnvironments: { [key: string]: { apiHost: string } } = {
  local: {
    apiHost: "http://localhost:4000",
  },
  development: {
    apiHost: "https://apidev.accessinvestments.com",
  },
  stage: {
    apiHost: "https://apiqa.accessinvestments.com",
  },
  production: {
    apiHost: "https://api.accessinvestments.com",
  },
};
// @ts-ignore
export const config: {
  apiHost: string;
} = apiEnvironments[process.env.REACT_APP_ENVIRONMENT || "development"];