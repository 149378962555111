import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { Link } from "react-router-dom";
import { useFundingRequests } from "../../../api/hooks/graphqlHooks";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { DataGridStyles, renderEditCell } from "../Assets";
import { formatCurrency } from "../../../common/utilities";
import PageWrapper from "../../../common/components/PageWrapper";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";

interface Props {

}

const FundingRequests: React.FC<Props> = withErrorBoundary({}, () => {

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const { data, isLoading, isFetching, isError } = useFundingRequests();

  const columns: GridColDef[] = [
    renderEditCell("/admin/fundingrequests/"),
    { field: "amount", headerName: "Amount", width: 250 },
    { field: "status", headerName: "Status", width: 200, hide: mobileLayout },
    { field: "wallet", headerName: "Wallet", width: 200, hide: mobileLayout },
    { field: "externalAccount", headerName: "External account", width: 200, hide: mobileLayout },
    { field: "displayName", headerName: "Name", width: 200, hide: mobileLayout },
    { field: "lastFour", headerName: "Last four digits", width: 200, hide: mobileLayout },
  ];

  const rows = data?.getFundingRequests?.map(row =>
  ({
    ...row,
    amount: formatCurrency(row.amount),
    status: row.status,
    id: row?.fundingRequestId,
    wallet: row?.wallet?.name,
    externalAccount: row?.externalAccount?.bankName,
    displayName: row?.externalAccount?.displayName,
    lastFour: row?.externalAccount?.lastFour,
  }),
  );

  return (
    <PageWrapper
      title="Funding requests"
      showLoader={isLoading || isFetching}
      showError={isError}
      errorMessage="Cannot retrieve funding requests"
    >
      <div style={{ height: "80vh", width: "100%" }}>
        <div className="row-mobile stretch">
          <div style={{ justifyContent: "flex-start" }}>
          </div>
          <div
            className="row width-100"
            style={{ justifyContent: "flex-end" }}>
            <Link to="/admin/fundingrequests/add">
              <AccButton
                color={AccButtonColors.GREEN}
                label="Add"
              />
            </Link>
          </div>
        </div>
        <br />
        <Divider />
        <br />
        <DataGrid
          rows={rows || []}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            panel: DataGridStyles.panel,
          }}
          sx={DataGridStyles.toolBar}
        />
      </div>
    </PageWrapper>
  );
});

export default FundingRequests;