import React, { useContext, useState } from "react";
import AccButton, { AccButtonColors } from "../../../../common/components/AccButton";
import AccInput, { AccMaskedInputTypes } from "../../../../common/components/AccInput";
import { isNil } from "../../../../common/utilities";
import { mfaRequest, validateMfaCode } from "../../../../api/rest/user";
import { AuthActions } from "../../../../App";
import LoadingAnimation from "../../../../common/components/LoadingAnimation";
import { Typography } from "@material-ui/core";
import { MFARequestTypes } from "../../../../enums";
import { AuthContext } from "../../../../context";
import { StyledInput } from "./ThemedComponents";

interface Props {
  onSuccess: () => void;
}

export const MFARequest = ({ onSuccess }: Props) => {
  const dispatch = useContext(AuthContext);

  const [mfaRequestStatus, setMfaRequestStatus] = useState({
    error: false,
    sent: false,
    loading: false,
  });
  const [mfaCodeInput, setMfaCodeInput] = useState("");

  const mfaLoginRequest = async () => {
    setMfaRequestStatus({
      ...mfaRequestStatus,
      loading: true,
    });
    try {
      const mfaResponse = await mfaRequest(MFARequestTypes.SMS);
      setMfaRequestStatus({
        ...mfaRequestStatus,
        loading: false,
      });
      if (mfaResponse?.data?.sent === true) {
        setMfaRequestStatus({
          ...mfaRequestStatus,
          error: false,
          sent: true,
          loading: false,
        });
      }
    } catch (err) {
      setMfaRequestStatus({
        ...mfaRequestStatus,
        error: true,
        sent: false,
        loading: false,
      });
      console.log("MFA Error", err);
    }
  };

  const validateMfaCodeRequest = async () => {
    setMfaRequestStatus({
      ...mfaRequestStatus,
      loading: true,
    });
    const mfaResponse = await validateMfaCode(mfaCodeInput);
    setMfaRequestStatus({
      ...mfaRequestStatus,
      loading: false,
    });
    if (mfaResponse.data?.verified === true) {
      const { expiresAt, sessionId } = mfaResponse.data.session;
      dispatch({
        type: AuthActions.MFA_VERIFIED,
        mfaSessionId: sessionId,
        mfaExpiresAt: expiresAt,
      });
      setMfaRequestStatus({
        error: false,
        sent: true,
        loading: false,
      });
      onSuccess();
    } else {
      setMfaRequestStatus({
        error: true,
        sent: false,
        loading: false,
      });
    }
    setMfaRequestStatus({
      ...mfaRequestStatus,
      loading: false,
    });
  };

  return (
    <>
      {mfaRequestStatus.loading ? <LoadingAnimation containerStyles={{ marginTop: "50px" }} /> :
        <>
          {mfaRequestStatus.sent === false && (
            <>
              <Typography
                style={{ marginBottom: 30, textAlign: "center" }}
                variant="h5"
                className="acc-white bold">Click the button below to receive a one-time code on your phone</Typography>
              <AccButton
                disabled={mfaRequestStatus.sent || mfaRequestStatus.error}
                color={mfaRequestStatus.sent || mfaRequestStatus.error ? AccButtonColors.WHITE : AccButtonColors.GREEN}
                onClick={mfaLoginRequest}
                label="Send Code to Phone"
                style={{ width: 201 }}
              />
            </>
          )}
          <>
            {mfaRequestStatus.sent === true && (
              <>
                <Typography
                  style={{ marginBottom: 30, textAlign: "center" }}
                  variant="h5"
                  className="acc-white bold">Please enter the 6 digit code received on your device</Typography>
                <StyledInput
                  autoFocus
                  as={AccInput}
                  maskedInput
                  mask={AccMaskedInputTypes.MFA_CODE}
                  className="width-50 width-100-mobile"
                  name="mfaCodeInput"
                  autoCapitalize="characters"
                  value={mfaCodeInput}
                  onChange={(e: any) => setMfaCodeInput(e.target.value?.toUpperCase())}
                  label="One Time Code"
                />
                <AccButton
                  submitOnEnter
                  disabled={isNil(mfaCodeInput)}
                  color={isNil(mfaCodeInput) ? AccButtonColors.WHITE : AccButtonColors.GREEN}
                  onClick={validateMfaCodeRequest}
                  label="Validate"
                  style={{ width: 200, marginTop: 50 }}
                />
              </>
            )}
          </>
        </>}
    </>
  );
};