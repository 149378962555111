import lottie from "lottie-web";
import React, { useEffect } from "react";
import animation from "../../../assets/animations/access_card_4.json";

interface Props {
  containerStyles?: {};
}

const LoadingAnimation: React.FC<Props> = ({ containerStyles }) => {
  const animationContainer = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current as HTMLDivElement,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    });
    anim.setSpeed(1);
    return () => anim.destroy();
  }, []);

  return (
    <div
      style={{ ...styles.container, marginTop: "250px", ...containerStyles }}
      ref={animationContainer} />
  );
};

const styles = {
  container: {
    height: "300px",
  },
};

export default LoadingAnimation;
