import React from "react";
import PageCard from "../../../../../common/components/PageCard";
import { Typography } from "@material-ui/core";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper } from "@mui/material";
import { formatCurrency } from "../../../../../common/utilities";
import { Investment } from "../../../../../common/types";
import moment from "moment";

interface Props {
    data: Investment | undefined;
}

const InvestmentCashFlow: React.FC<Props> = ({ data }) => {

    return (
        <div>
            <PageCard>
                <Typography
                    variant="h5"
                    className="acc-darkblue bold">
                    Investment Cash Flow
                </Typography>
                <br />
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">Description</TableCell>
                                <TableCell align="right">Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell
                                    component="th"
                                    scope="row">{moment(Number(data?.createdAt)).format("MM/DD/YYYY")}</TableCell>
                                <TableCell align="left">Investment committed to {data?.asset.name}</TableCell>
                                <TableCell align="right">-{formatCurrency(data?.amount!, 2)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </PageCard>
        </div>
    );
};

export default InvestmentCashFlow;