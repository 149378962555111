import React from "react";
import { Box } from "@mui/material";
import { useFormikContext, Field, FormikContextType } from "formik";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import AccInput from "../../../common/components/AccInput";
import moment from "moment";

interface FormValues {
  [key: string]: any;
  targetedIRR?: number;
  targetedAverageCashYield?: number;
  targetedMOIC: number;
  targetedInvestmentPeriod: Date;
  targetedMaturity: Date;
  targetedFundingDate: Date;
  minimumInvestmentAmount: number;
  netAssetValue: number;
}

const AssetTabViewForm = ({ tabValue, setIsCodeView, tabs }: any) => {
  const { values, setFieldValue, errors, touched }: FormikContextType<FormValues> = useFormikContext<FormValues>();
  const today = moment(new Date()).format("YYYY-MM-DD");
  return (
    <Box sx={{ width: "100%" }}>
      {tabs.map((config: any, index: number) => (
        <Box
role="tabpanel"
hidden={tabValue !== index}
key={index}
p={3}>
          {tabValue === index && (
            <>
              <SunEditor
                setOptions={{
                  height: "200",
                  buttonList: [
                    ["undo", "redo", "font", "fontSize", "paragraphStyle", "blockquote",
                     "bold", "underline", "italic", "strike", "subscript", "superscript", "fontColor", "hiliteColor",
                     "align", "list", "lineHeight", "table", "link", "image", "video", "fullScreen", "showBlocks", "codeView",
                     "preview", "print", "save", "template"],
                  ],
                }}
                placeholder={config.placeholder}
                defaultValue={values.tabDescriptions[index]?.content ?? values[config.editorFieldName] as string}
                onChange={(content) => {
                  setFieldValue(config.editorFieldName, content);
                  setIsCodeView && setIsCodeView(false);
                }}
              />
            </>
          )}
        <div className="width-100 row stretch column-mobile">
          <div className="column width-45 width-100-mobile">
            <Field
              name="targetedIRR"
              type="number"
              label="Targeted IRR"
              as={AccInput}
              required
              helperText={errors.targetedIRR && touched.targetedIRR ? errors.targetedIRR : null}
              error={errors.targetedIRR && touched.targetedIRR ? true : false}
              InputLabelProps={{ shrink: typeof values.targetedIRR === "number" ? true : false }}
            />
            <Field
              name="targetedAverageCashYield"
              type="number"
              label="Targeted Average Cash Yield"
              as={AccInput}
              required
              helperText={errors.targetedAverageCashYield && touched.targetedAverageCashYield ? errors.targetedAverageCashYield : null}
              error={errors.targetedAverageCashYield}
              InputLabelProps={{ shrink: typeof values.targetedAverageCashYield === "number" ? true : false }}
            />
            <Field
              name="targetedMOIC"
              type="number"
              label="Targeted MoIC"
              as={AccInput}
              required
              helperText={errors.targetedMOIC && touched.targetedMOIC ? errors.targetedMOIC : null}
              error={errors.targetedMOIC}
              InputLabelProps={{ shrink: typeof values.targetedMOIC === "number" ? true : false }}
            />
            <Field
              name="targetedInvestmentPeriod"
              type="number"
              label="Targeted Investment Period"
              as={AccInput}
              required
              helperText={errors.targetedInvestmentPeriod && touched.targetedInvestmentPeriod ? errors.targetedInvestmentPeriod : null}
              error={errors.targetedInvestmentPeriod}
              InputLabelProps={{ shrink: typeof values.targetedInvestmentPeriod === "number" ? true : false }}
            />
            <Field
              name="targetedMaturity"
              type="date"
              label="Targeted Maturity"
              InputLabelProps={{ shrink: true }}
              as={AccInput}
              required
              helperText={errors.targetedMaturity}
              error={errors.targetedMaturity}
              inputProps={{
                min: today,
              }}
            />
            <Field
              name="targetedFundingDate"
              type="date"
              label="Targeted Funding Date"
              InputLabelProps={{ shrink: true }}
              as={AccInput}
              required
              helperText={errors.targetedFundingDate}
              error={errors.targetedFundingDate}
              inputProps={{
                min: today,
              }}
            />
            <Field
              name="minimumInvestmentAmount"
              type="text"
              label="Minimum Investment Amount"
              as={AccInput}
              required
              currencyMask
              helperText={errors.minimumInvestmentAmount && touched.minimumInvestmentAmount ? errors.minimumInvestmentAmount : null}
              error={errors.minimumInvestmentAmount}
              InputLabelProps={{ shrink: typeof values.minimumInvestmentAmount === "number" ? true : false }}
            />
            <Field
              name="netAssetValue"
              type="text"
              label="Net Asset Value"
              as={AccInput}
              required
              currencyMask
            />
            <Field
              name="spvAccountNumber"
              type="text"
              label="SPV Account Number"
              as={AccInput}
            />
          </div>
          <div className="column width-45 width-100-mobile">
            <Field
              name="investmentAmountIncrement"
              type="text"
              label="Investment Amount Increment"
              as={AccInput}
              required
              currencyMask
            />
            <Field
              name="totalInvestmentAmount"
              type="text"
              label="Total Investment Amount"
              required
              as={AccInput}
              currencyMask
              helperText={errors.totalInvestmentAmount && touched.totalInvestmentAmount ? errors.totalInvestmentAmount : null}
              error={errors.totalInvestmentAmount}
              InputLabelProps={{ shrink: typeof values.totalInvestmentAmount === "number" ? true : false }}
            />
            <Field
              name="managementCompany"
              type="text"
              label="Management Company"
              as={AccInput}
              required
            />
            <Field
              name="investmentJurisdiction"
              type="text"
              label="Investment Jurisdiction"
              as={AccInput}
              required
            />
            <Field
              name="investmentStructure"
              type="text"
              label="Investment Structure"
              as={AccInput}
              required
            />
            <Field
              name="marketType"
              type="text"
              label="Market Type"
              required
              as={AccInput}
            />
            <Field
              name="videoId"
              type="text"
              label="Video ID"
              as={AccInput}
            />
            <Field
              name="spvRoutingNumber"
              type="text"
              label="SPV Routing Number"
              as={AccInput}
            />
          </div>
        </div>
      </Box>
      ))}
    </Box>
  );
};

export default AssetTabViewForm;