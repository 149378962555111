import { toast } from "react-toastify";
import restClient from "../restClient";

interface GetPlaidLinkTokenResponse {
  data: {
    expiration: string;
    link_token: string;
    request_id: string;
  }
  success: boolean;
}


async function getPlaidLinkToken(): Promise<string | undefined> {
  try {
    const response: { data: GetPlaidLinkTokenResponse } = await restClient({
      url: "/external-accounts/link",
      method: "POST",
    });
    if (response.data?.success === true) {
      return response.data?.data?.link_token;
    } else {
      throw new Error("Error retrieving Plaid token");
    }
  } catch (err: any) {
    console.log(err);
    toast(err.message || err.msg || err.toString() || "Error retrieving Plaid token", {
      type: toast.TYPE.ERROR,
    });
  }
}

async function setPlaidToken({ publicToken, walletId }: { publicToken: string, walletId: number }): Promise<any | undefined> {
  try {
    const response: { data: GetPlaidLinkTokenResponse } = await restClient({
      url: "/external-accounts/set-token",
      method: "POST",
      data: { publicToken, walletId },
    });
    if (response.data?.success === true) {
      return response.data?.data;
    } else {
      throw new Error("Error setting Plaid token");
    }
  } catch (err: any) {
    console.log(err);
    toast(err.message || err.msg || err.toString() || "Error setting Plaid token", {
      type: toast.TYPE.ERROR,
    });
  }
}

export {
  getPlaidLinkToken,
  setPlaidToken,
}; 
