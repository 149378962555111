import { BaseTextFieldProps, TextField } from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";
import COLORS from "../../colors";
import { styled } from "@mui/material";
import { isNotNil } from "../../utilities";
import InputMask from "react-input-mask";

export interface AccInputProps extends BaseTextFieldProps {
  currencyMask?: boolean;
  mask?: string;
  maskedInput?: boolean;
  autoCapitalize?: "characters" | "words" | "sentences" | "none";
  autoFocus?: boolean;
  onChange: (event: { target: { name: string; value: any } }) => void;
  name: string;
  value?: any;
  label?: string;
  select?: boolean;
  required?: boolean;
  type?: React.HTMLInputTypeAttribute;
  style?: React.CSSProperties;
  className?: string;
  error?: boolean;
}

export enum AccMaskedInputTypes {
  SSN = "999-99-9999",
  US_PHONE_NUMBER = "(999) 999-9999",
  MFA_CODE = "999999",
}

const AccInput: React.FC<AccInputProps> = ({
  currencyMask,
  mask,
  maskedInput,
  autoCapitalize,
  autoFocus,
  onChange,
  name,
  value,
  label,
  select,
  required,
  type,
  style,
  className,
  error,
  ...props
}) => {
  if (currencyMask) {
    return (
      // @ts-ignore
      <NumberFormat
        allowLeadingZeros={false}
        allowNegative={false}
        prefix="$"
        error={error}
        customInput={TextField}
        className={className}
        variant="outlined"
        thousandSeparator={","}
        onValueChange={values => {
          onChange({
            target: {
              name: name,
              value: parseInt(values.value),
            },
          });
        }}
        isNumericString
        fullWidth
        margin="normal"
        name={name}
        value={value}
        label={label}
        select={select}
        required={required}
        style={style}
        {...props}
      />
    );
  }
  if (maskedInput) {
    return (
      <InputMask
        // @ts-ignore
        maskplaceholder={null}
        // @ts-ignore
        maskChar={null}
        mask={mask ?? ""}
        className={className}
        onChange={onChange}
        name={name}
        value={value}
        required={required}
        {...props}
      >
        {(props: any) => (
          <TextField
            variant="outlined"
            fullWidth
            autoCapitalize={autoCapitalize}
            autoFocus={autoFocus}
            margin="normal"
            type={type}
            className={className}
            onChange={onChange}
            name={name}
            value={value}
            label={label}
            error={error}
            select={select}
            placeholder=""
            required={required}
            InputLabelProps={{ shrink: type === "date" || isNotNil(value), placeholder: "" }}
            style={style}
            {...props}
          />
        )}
      </InputMask>
    );
  }
  return (
    <TextField
      variant="outlined"
      fullWidth
      autoCapitalize={autoCapitalize}
      autoFocus={autoFocus}
      margin="normal"
      type={type}
      className={className}
      onChange={onChange}
      name={name}
      value={value}
      label={label}
      error={error}
      select={select}
      placeholder=""
      required={required}
      InputLabelProps={{ shrink: type === "date" || isNotNil(value), placeholder: "" }}
      style={style}
      {...props}
    />
  );
};

export default styled(AccInput)({
  "& label.Mui-focused": {
    color: COLORS.darkBlue,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.green,
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: COLORS.green,
    },
  },
});
