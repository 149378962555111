import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import COLORS from "../../common/colors";
import AccButton, { AccButtonColors } from "../../common/components/AccButton";
import "../../styles/login.css";
import { resetPassword, userPasswordUpdate, userLogin } from "../../api/rest/user/index";
import { RouteComponentProps, withRouter } from "react-router";
import Logo from "../../assets/images/illustrations/access_logo_regular.svg";
import { Link } from "react-router-dom";
import { AuthContext, SessionContext } from "../../context";
import { isNotNil, standardErrorHandle } from "../../common/utilities";
import InputHelperTextWithIcon from "../../common/components/InputHelperTextWithIcon";
import * as Yup from "yup";
import { logIn } from "../../api/utils";

type ResetPasswordProps = RouteComponentProps;

const ResetPassword: React.FC<ResetPasswordProps> = ({ history }) => {
  const [values, setValues] = React.useState({
    email: "",
    resetPasswordCode: "",
    temporaryPassword: "",
    newPassword: "",
    newPassword2: "",
  });
  const [passwordFieldVisibility, setPasswordFieldVisibility] = useState({
    password1: false,
    password2: false,
    temporaryCode: false,
    temporaryPassword: false,
  });
  const passwordRequirementsFailed =
    !values.newPassword.match(/[a-z]/g) ||
    !values.newPassword.match(/[A-Z]/g) ||
    !values.newPassword.match(/[0-9]/g) ||
    !values.newPassword.match(/[^a-zA-Z\d]/g) ||
    values.newPassword.length < 8;

  const arePasswordEqual = values.newPassword === values.newPassword2;
  const updatePasswordRequiredFields = values.email && values.resetPasswordCode && values.newPassword && values.newPassword2;
  const resetPasswordRequiredFields = values.temporaryPassword && values.newPassword && values.newPassword2;

  const ResetPasswordValueSchema = Yup.object().shape({
    email: Yup.string().email("Must be a valid email").max(255),
    resetPasswordCode: Yup.string(),
    currentPassword: Yup.string(),
    newPassword: Yup.string(),
    newPassword2: Yup.string(),
  });

  const handleChange =
    (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = (field: "password1" | "password2" | "temporaryCode" | "temporaryPassword") => {
    setPasswordFieldVisibility({
      ...passwordFieldVisibility,
      [field]: !passwordFieldVisibility[field],
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
  };

  const { user } = useContext(SessionContext);
  const dispatch = useContext(AuthContext);

  const loginAfterResetPassword = async () => {
    try {
      const userLoginReq = {
        email: user?.email,
        password: values.newPassword,
      };
      const loginResponse = await userLogin(userLoginReq);
      const authPayload = loginResponse?.data;
      if (authPayload) {
        logIn(authPayload);
        dispatch({
          type: "SIGN_IN",
          ...authPayload,
        });
      }
    } catch (err: any) {
      standardErrorHandle(err, "Error logging in");
    }
    history.push("/");
  };

  const updatePassword = async () => {
    const userRequest = { ...user };
    userRequest.newPassword = values.newPassword;
    userRequest.currentPassword = values.temporaryPassword;
    try {
      const updatePasswordResponse = await userPasswordUpdate(userRequest);
      if (updatePasswordResponse?.data?.success === true) {
        loginAfterResetPassword();
      }
    } catch (err: any) {
      toast(
        err.message ||
        err.msg ||
        err.toString() ||
        "There was an error updating user password.",
        {
          type: toast.TYPE.ERROR,
        },
      );
    }
  };

  const resetOldPassword = async () => {
    try {
      const updatePasswordResponse = await resetPassword(
        values.resetPasswordCode,
        values.email,
        values.newPassword,
      );
      if (updatePasswordResponse?.success === true) {
        history.push("/");
      }
    } catch (err: any) {
      toast(
        err.message ||
        err.msg ||
        err.toString() ||
        "There was an error trying to reset user password.",
        {
          type: toast.TYPE.ERROR,
        },
      );
    }
  };

  const resetPasswordClick = () => {
    ResetPasswordValueSchema.validate(values)
      .then(() => {
        if (!values.newPassword) {
          return toast("Please enter a new password", {
            type: toast.TYPE.INFO,
          });
        } else if (!values.newPassword2) {
          return toast("Please re-type new password", {
            type: toast.TYPE.INFO,
          });
        } else if (!user && !values.resetPasswordCode) {
          return toast("Please enter reset password code", {
            type: toast.TYPE.INFO,
          });
        } else if (user && !values.temporaryPassword) {
          return toast("Please enter current password", {
            type: toast.TYPE.INFO,
          });
        } else if (!user && !values.email) {
          return toast("Please enter your email", {
            type: toast.TYPE.INFO,
          });
        }

        if (passwordRequirementsFailed) {
          return toast("Your password is not strong enough", {
            type: toast.TYPE.ERROR,
          });
        }

        if (values.newPassword !== values.newPassword2) {
          return toast("New password does not match", {
            type: toast.TYPE.ERROR,
          });
        }

        if (user) {
          updatePassword();
        } else {
          resetOldPassword();
        }
      })
      .catch(function (err: any) {
        return toast(
          err.errors[0] ||
          "Validation error",
          {
            type: toast.TYPE.ERROR,
          },
        );
      });
  };

  const getYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div style={styles.background as React.CSSProperties}>
      <div style={styles.container as React.CSSProperties}>
        <Link to="/">
          <img
            style={{ width: "100%" }}
            src={Logo}
            alt=""
          />
        </Link>
        <div>
          {user ? (
            <>
              <Typography
                className="font-size-mobile-3"
                variant="h5"
                style={styles.text as React.CSSProperties}
              >
                Welcome back!
              </Typography>
              <Typography
                className="light"
                variant="subtitle1"
                align="center"
                style={styles.text as React.CSSProperties}
              >
                Complete the form to update your password.
              </Typography>
            </>
          ) : (
            <>
              <Typography
                className="font-size-mobile-3"
                variant="h5"
                style={styles.text as React.CSSProperties}
              >
                Password Reset
              </Typography>
              <Typography
                className="light"
                variant="subtitle1"
                align="center"
                style={styles.text as React.CSSProperties}
              >
                Complete the form to reset your password.
              </Typography>
            </>
          )}
        </div>
        <div className="width-100">
          {!user ? (
            <>
              <OutlinedInput
                fullWidth
                placeholder="Temporary Code"
                name="resetPasswordCode"
                onChange={handleChange("resetPasswordCode")}
                style={styles.input}
                inputProps={{
                  autoComplete: "new-password",
                }}
                type={passwordFieldVisibility.temporaryCode ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    {/* @ts-ignore */}
                    <IconButton
                      disableRipple
                      disableFocusRipple
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("temporaryCode")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {passwordFieldVisibility.temporaryCode ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <OutlinedInput
                fullWidth
                placeholder="Email Address"
                name="email"
                onChange={handleChange("email")}
                style={styles.input}
                inputProps={{
                  autoComplete: "off",
                }}
              />
            </>
          ) : (
            <OutlinedInput
              fullWidth
              placeholder="Temporary password"
              name="temporaryPassword"
              onChange={handleChange("temporaryPassword")}
              style={styles.input}
              inputProps={{
                autoComplete: "new-password",
              }}
              type={passwordFieldVisibility.temporaryPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  {/* @ts-ignore */}
                  <IconButton
                    disableRipple
                    disableFocusRipple
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("temporaryPassword")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {passwordFieldVisibility.temporaryPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
          <OutlinedInput
            fullWidth
            onChange={handleChange("newPassword")}
            style={styles.input}
            name="newPassword"
            inputProps={{
              autoComplete: "new-password",
            }}
            type={passwordFieldVisibility.password1 ? "text" : "password"}
            placeholder="New password"
            endAdornment={
              <InputAdornment position="end">
                {/* @ts-ignore */}
                <IconButton
                  disableRipple
                  disableFocusRipple
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("password1")}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {passwordFieldVisibility.password1 ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <Typography
            variant="subtitle2"
            className="acc-black bold"
            style={{ marginBottom: 1 }}
          >
            Your password must:
          </Typography>
          <InputHelperTextWithIcon
            variant="body2"
            text={"Include at least 1 lowercase letter"}
            data={!values.newPassword.match(/[a-z]/g) === false}
          />
          <InputHelperTextWithIcon
            variant="body2"
            text="Include at least 1 uppercase letter"
            data={!values.newPassword.match(/[A-Z]/g) === false}
          />
          <InputHelperTextWithIcon
            variant="body2"
            text="Include at least 1 number"
            data={!values.newPassword.match(/[0-9]/g) === false}
          />
          <InputHelperTextWithIcon
            variant="body2"
            text="Include at least 1 special character"
            data={!values.newPassword.match(/[^a-zA-Z\d]/g) === false}
          />
          <InputHelperTextWithIcon
            variant="body2"
            text="Be at least 8 characters long"
            data={!(values.newPassword.length > 8) === false}
          />
          <br />
          <OutlinedInput
            fullWidth
            onChange={handleChange("newPassword2")}
            style={styles.input}
            name="newPassword2"
            type={passwordFieldVisibility.password2 ? "text" : "password"}
            placeholder="Confirm new password"
            endAdornment={
              <InputAdornment position="end">
                {/* @ts-ignore */}
                <IconButton
                  disableRipple
                  disableFocusRipple
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("password2")}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {passwordFieldVisibility.password2 ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        <AccButton
          onClick={() => resetPasswordClick()}
          color={isNotNil(updatePasswordRequiredFields || resetPasswordRequiredFields) && arePasswordEqual && !passwordRequirementsFailed
            ? AccButtonColors.GREEN
            : AccButtonColors.WHITE
          }
          label="Reset Password"
        />
      </div>
      <Typography style={{ color: COLORS.white }}>
        © {getYear()}&nbsp;
        <span style={{ color: COLORS.green }}>
          Access Alternative Investments, Inc.
        </span>
      </Typography>
    </div>
  );
};

const styles = {
  background: {
    background: "transparent linear-gradient(360deg, #001227 0%, #05254b 100%) 0% 0% no-repeat padding-box",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: COLORS.darkBlue,
    fontWeight: "bold",
    textAlign: "center",
    margin: "5px",
  },
  container: {
    backgroundColor: "white",
    margin: "30px",
    minWidth: 210,
    maxWidth: 400,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    padding: "40px 55px 30px 55px",
    height: 810,
    borderRadius: 16,
    boxShadow: "0px 0px 60px #00000066",
  },
  input: {
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: 16,
    opacity: 1,
    width: "100%",
    marginBottom: 25,
  },
  secondaryButton: {
    border: "1px solid #4399A8",
    background: "white",
    marginTop: "15px",
  },
  checkbox: {
    color: COLORS.darkBlue,
    "&.Mui-checked": {
      color: COLORS.darkBlue,
    },
  },
};

export default withRouter(ResetPassword);