import gql from "graphql-tag";
import { makeGraphqlRequest } from "../../utils";
import { gql as gqlv2 } from "graphql-request";

export const CREATE_ASSET_STATUS = gql`
  mutation CreateAssetStatus($input: AssetStatusInput!) {
    createAssetStatus(input: $input) {
      name
    }
  }
`;

export const UPDATE_ASSET_STATUS = gql`
  mutation UpdateAssetStatus($id: String!, $input: AssetStatusInput!) {
    updateAssetStatus(_id: $id, input: $input) {
      id
      name
    }
  }
`;

export const DELETE_ASSET_STATUS = gql`
  mutation DeleteAssetStatus($id: String!) {
    deleteAssetStatus(_id: $id) {
      id
      assetStatusId
    }
  }
`;

const GET_ASSET_STATUS_V2 = gqlv2`
  query GetAssetStatus($assetStatusId: Int!) {
    getAssetStatus(assetStatusId: $assetStatusId) {
      id
      assetStatusId
      name
    }
  }
`;

export const getAssetStatus = (assetStatusId: number) => makeGraphqlRequest(GET_ASSET_STATUS_V2, { assetStatusId });

const GET_ASSET_STATUSES_V2 = gqlv2`
  query GetAssetStatuses {
    getAssetStatuses {
      id
      assetStatusId
      name
    }
  }
`;

export const getAssetStatuses = () => makeGraphqlRequest(GET_ASSET_STATUSES_V2);
