import { Typography } from "@material-ui/core";
import React from "react";
import "./styles.css";

interface Props {
  title: string | React.ReactFragment;
  subtitle?: string | React.ReactFragment;
  className?: string;
  style?: React.CSSProperties;
}

// Deprecated - please use PageWrapper
const PageTitle: React.FC<Props> = ({
  title, subtitle, className, style,
}) => (
  <div
    className={className}
    style={{ ...style }}>
    <Typography
      className="acc-darkblue page-title"
      variant="h4"
      style={{ marginBottom: 10, fontWeight: 700 }}>{title}</Typography>
    <Typography
      className="acc-darkgray page-subtitle"
      variant="h6"
      style={{ fontWeight: 300 }}>{subtitle}</Typography>
  </div>
);

export default PageTitle;
