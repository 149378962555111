import { faSquare } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import React, { useContext, useState } from "react";
import { RegistrationContext } from "..";
import COLORS from "../../../common/colors";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import { RegistrationFormValue, TermsAndConditions, UserTypes } from "../../../common/types";
import LoadingAnimation from "../../../common/components/LoadingAnimation";
import { THEME_BREAKPOINTS, US_STATES } from "../../../common/constants";
import AccInput, { AccMaskedInputTypes } from "../../../common/components/AccInput";
import AccOptionSelect from "../../../common/components/AccInput/variants/AccOptionSelect";
import { checkCityInputHasNoDigits, isNil, isNotNil } from "../../../common/utilities";
import { StyledInput } from "../../Onboard/umb/components/ThemedComponents";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { useMediaQuery } from "@mui/material";
import { downloadTCs } from "../../../api/rest/download";
import AccStateSelect from "../../../common/components/AccInput/variants/AccStateSelect";

const IdentityForm: React.FC = () => {
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  // @ts-ignore
  const { loading } = useContext(RegistrationContext);
  const [accessTermsChecked, setAccessTermsChecked] = useState<boolean>(false);
  const [mfaTermsChecked, setMfaTermsChecked] = useState<boolean>(false);

  const { values, handleSubmit, handleChange, errors, touched } = useFormikContext<RegistrationFormValue>();

  if (loading) {
    return <LoadingAnimation containerStyles={{ marginTop: "40px" }} />;
  }

  const isValidEmailAddress = (address: string) => {
    return address! && address.match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/);
  };

  const buttonDisabled = () => {
    if (!accessTermsChecked || !mfaTermsChecked) {
      return true;
    }
    if (isNil(values.firstName) ||
      isNil(values.lastName) ||
      (isNil(values.phone) || isNotNil(errors.phone)) ||
      isNil(values.email) ||
      isNil(values?.address?.street1) ||
      isNil(values?.address?.city) ||
      isNil(values?.address?.postalCode) ||
      isNil(values?.address?.country) ||
      isNil(values?.address?.stateProvince) ||
      (values?.address?.city && checkCityInputHasNoDigits(values?.address?.city))
    ) {
      return true;
    }
    if (values.userTypeName === UserTypes.ADVISOR) {
      if (isNil(values.CRDNumber) || isNil(values.firmCRDNumber)) {
        return true;
      }
    }
    return false;
  };

  const renderTermsLink = {
    [TermsAndConditions.GENERAL_TERMS]: () => <span
      style={styles.downloadLink}
      onClick={() => downloadTCs(TermsAndConditions.GENERAL_TERMS)}>Terms of Service</span>,
    [TermsAndConditions.SUPPLEMENTAL_AGREEMENTS]: () => <span
      style={styles.downloadLink}
      onClick={() => downloadTCs(TermsAndConditions.SUPPLEMENTAL_AGREEMENTS)}>Supplemental Agreements</span>,
    [TermsAndConditions.PRIVACY_POLICY]: () => <span
      style={styles.downloadLink}
      onClick={() => downloadTCs(TermsAndConditions.PRIVACY_POLICY)}>Privacy Policy</span>,
  };

  return (
    <div
      className="column center stretch"
    >
      <div
        className="form-container column-mobile width-60 width-90-mobile"
        style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", marginBottom: 30 }}>
        <div
          id="registration-identity-column-left"
          style={{ minWidth: !mobileLayout ? 200 : 0 }}>
          <Typography
            className="bold"
            variant="h6"
            style={styles.sectionHeader}
          >
            Contact Information
          </Typography>
          <StyledInput
            name="firstName"
            as={AccInput}
            type="text"
            margin="dense"
            style={styles.input}
            value={values?.firstName}
            onChange={handleChange}
            label="Legal First Name"
          />
          <StyledInput
            name="middleName"
            as={AccInput}
            type="text"
            margin="dense"
            style={styles.input}
            value={values?.middleName}
            onChange={handleChange}
            label="Legal Middle Name"
          />
          <StyledInput
            as={AccInput}
            type="text"
            onChange={handleChange}
            margin="dense"
            style={styles.input}
            value={values?.lastName}
            name="lastName"
            label="Legal Last Name" />
          <StyledInput
            name="email"
            margin="dense"
            style={styles.input}
            as={AccInput}
            onChange={handleChange}
            type="email"
            value={values?.email}
            label="Email Address"
            error={isNil(values?.email) || isValidEmailAddress(values?.email) ? false : true}
          />
          <StyledInput
            as={AccInput}
            onChange={handleChange}
            name="phone"
            margin="dense"
            style={styles.input}
            maskedInput
            mask={AccMaskedInputTypes.US_PHONE_NUMBER}
            type="phone"
            value={values?.phone}
            label="Phone Number"
            helperText={errors.phone && touched.phone ? errors.phone : null}
            error={errors.phone && touched.phone}
          />
          {values.userTypeName === UserTypes.ADVISOR && (
            <div style={{ display: "flex" }}>
              <StyledInput
                as={AccInput}
                onChange={handleChange}
                name="CRDNumber"
                label="CRD #"
                margin="dense"
                style={{ ...styles.input, marginRight: 10 }}
                value={values?.CRDNumber}
              />
              <StyledInput
                as={AccInput}
                onChange={handleChange}
                margin="dense"
                style={styles.input}
                name="firmCRDNumber"
                label="Firm CRD #"
                value={values?.firmCRDNumber}
              />
            </div>
          )}
        </div>
        <div
          id="registration-identity-column-right"
          style={{ minWidth: !mobileLayout ? 200 : 0 }}>
          <Typography
            className="bold"
            variant="h6"
            style={styles.sectionHeader}
          >
            Address
          </Typography>
          <StyledInput
            as={AccInput}
            onChange={handleChange}
            type="text"
            label="Address Line 1"
            margin="dense"
            style={styles.input}
            value={values?.address?.street1}
            name="address.street1"
          />
          <StyledInput
            as={AccInput}
            onChange={handleChange}
            type="text"
            margin="dense"
            style={styles.input}
            value={values?.address?.street2}
            label="Address Line 2"
            name="address.street2"
          />
          <div className="row stretch">
            <StyledInput
              as={AccInput}
              onChange={handleChange}
              type="text"
              name="address.city"
              margin="dense"
              style={styles.input}
              label="City"
              value={values?.address?.city}
              error={values?.address?.city && checkCityInputHasNoDigits(values?.address?.city)}
            />
            <StyledInput
              as={AccOptionSelect}
              type="text"
              margin="dense"
              value={values?.address?.stateProvince}
              label="State"
              onChange={handleChange}
              style={{ ...styles.input, marginLeft: 10 }}
              options={US_STATES}
              name="address.stateProvince"
            />
          </div>
          <div className="row stretch">
            <StyledInput
              as={AccInput}
              type="text"
              margin="dense"
              style={styles.input}
              onChange={handleChange}
              label="Postal Code"
              value={values.address?.postalCode}
              name="address.postalCode"
            />
            <StyledInput
              as={AccStateSelect}
              type="text"
              margin="dense"
              value={values?.address?.country}
              label="Country"
              onChange={handleChange}
              style={{ ...styles.input, marginLeft: 10 }}
              name="address.country"
              select
            />
          </div>
        </div>
      </div>
      <div
        className="width-60 width-100-mobile"
        // @ts-ignore
        style={styles.checkboxRow}
      >
        <FontAwesomeIcon
          onClick={() => setAccessTermsChecked(!accessTermsChecked)}
          color={accessTermsChecked ? COLORS.green : COLORS.white}
          style={styles.checkbox}
          icon={accessTermsChecked ? faCheckSquare : faSquare} />
        <Typography
          style={{
            fontSize: 15, fontWeight: 400, lineHeight: 1.25, color: COLORS.white,
          }}
        >
          By checking this box and creation of an access account investment profile,
          you agree to our {renderTermsLink[TermsAndConditions.GENERAL_TERMS]?.()},&nbsp;
          {renderTermsLink[TermsAndConditions.PRIVACY_POLICY]?.()} and&nbsp;
          {renderTermsLink[TermsAndConditions.SUPPLEMENTAL_AGREEMENTS]?.()};
          each of these is available through the hyperlink on the related document names.
        </Typography>
      </div>
      <div
        className="width-60 width-100-mobile"
        // @ts-ignore
        style={styles.checkboxRow}
      >
        <FontAwesomeIcon
          onClick={() => setMfaTermsChecked(!mfaTermsChecked)}
          color={mfaTermsChecked ? COLORS.green : COLORS.white}
          style={styles.checkbox}
          icon={mfaTermsChecked ? faCheckSquare : faSquare} />
        <Typography
          style={{
            fontSize: 15, fontWeight: 400, lineHeight: 1.25, color: COLORS.white,
          }}
        >
          You consent to accept and receive from us text messages using this telephone number,
          which may include two-factor authentication requests.
        </Typography>
      </div>
      <div
        className="row column-mobile width-100-mobile"
      >
        <AccButton
          type="button"
          color={!buttonDisabled() ? AccButtonColors.GREEN : AccButtonColors.WHITE}
          className={!buttonDisabled() ? "m-top-15-mobile" : "no-pointer m-top-15-mobile"}
          disabled={buttonDisabled()}
          label="Submit"
          onClick={() => {
            return handleSubmit();
          }
          }
        />
      </div>
    </div >
  );
};
export default IdentityForm;

const styles = {
  downloadLink: {
    textDecoration: "underline",
    cursor: "pointer",
    color: COLORS.green,
  },
  sectionHeader: {
    color: COLORS.white,
    width: "100%",
    marginBottom: 10,
  },
  checkbox: {
    marginRight: 15, marginTop: 2.5, cursor: "pointer",
  },
  checkboxRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 30,
  },
  input: {
    marginBottom: 15,
  },
};
