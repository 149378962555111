/*eslint-disable */
export enum EditModeTypes {
  ADD = 'ADD',
  EDIT = 'EDIT'
}
export enum InvestmentStatus {
  // First step initiated
  ATTEMPTED = "ATTEMPTED",
  // Money is committed from UMB wallet (on hold?)
  COMMITTED = "COMMITTED",
  // Money has arrived in SPV account for asset
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  AVAILABLE = "AVAILABLE"
}
export enum InvestmentType {
  INVESTMENT = "INVESTMENT",
  DISTRIBUTION = "DISTRIBUTION"
}
export enum AccreditedInvestorStatus {
  PENDING = "Pending",
  APPROVED = "Approved",
  NEED_MORE_INFO = "Need More Info",
}
export enum AssetStatus {
  COMING_SOON = "Coming Soon",
  AVAILABLE = "Available",
  ACTIVE = "Active",
  COMPLETED = "Completed",
  FULLY_SUBSCRIBED = "Fully Subscribed",
  DRAFT = "Draft",
  ARCHIVED = "Archived",
}

export enum AccreditationStatus {
  REJECTED = "Rejected",
  PENDING = "Pending",
}

export enum WalletStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}

export enum AccountStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}

export enum MFARequestTypes {
  EMAIL = "EMAIL",
  SMS = "SMS",
}

export enum WalletTypes {
  INDIVIDUAL = "INDIVIDUAL",
  JOINT = "JOINT",
  COMMERCIAL_ENTITY = "COMMERCIAL_ENTITY",
}

export enum TicketCategory {
  EMAIL_REQUEST = "EMAIL_REQUEST",
  PHONE_CALL_REQUEST = "PHONE_CALL_REQUEST",
}