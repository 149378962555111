import { Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import COLORS from "../../../common/colors";
import { THEME_BREAKPOINTS } from "../../constants";
import { formatCurrency } from "../../utilities";
import { AccButtonColors } from "../AccButton";
import InputHelperTextWithIcon from "../InputHelperTextWithIcon";
import { Asset, CashFlow } from "../../types";
import CashFlowSimulationInput from "../CashFlowSimulationInput";
import CashFlowSimulationBar from "../CashFlowSimulationBar";

type CashFlowSimulationParams = {
  asset: Asset | any,
  cashFlowModelRows: Array<Object>,
  cashFlow: CashFlow | undefined,
}

const CashFlowModal: React.FC<CashFlowSimulationParams> = ({
  asset, cashFlowModelRows,
}) => {
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  const [totalInvestmentAmountInput, setTotalInvestmentAmountInput] = React.useState(0);

  let cashFlowModelFormattedRows: Array<Object> = [];
  for (let i = 0; i < cashFlowModelRows.length; i++) {
    cashFlowModelFormattedRows.push({
      id: i,
      month: `Month ${i + 1}`,
      input: formatCurrency(Object.values(cashFlowModelRows[i])[2]),
    });
  }

  // Limit of Input Test Investment Amount based on the asset's total investment amount
  const limitInvestmentAmount = (floatValue: any) => {
    const { value } = floatValue;
    if (value <= (asset.totalInvestmentAmount)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      className="m-top-50 column end stretch"
      style={{ ...styles.innerContainer, padding: mobileLayout ? "50px 25px" : "15px 50px", width: "65%" }}>
      <div
        style={{ width: "100%" }}
      >
        <Typography
          className="acc-green bold"
          style={{ marginBottom: 10, fontSize: 25 }}
        >
          Cash Flow Simulation
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <CashFlowSimulationInput
          asset={asset}
          limitInvestmentAmount={limitInvestmentAmount}
          setTotalInvestmentAmountInput={setTotalInvestmentAmountInput}
        />
        <div style={{ width: "90%" }}>
          <Typography
            // variant="subtitle1"
            className="acc-white bold"
            style={{ marginBottom: 10, fontSize: 18 }}
          >
            Investment Checklist
          </Typography>
          <InputHelperTextWithIcon
            text="Increment of"
            color={AccButtonColors.WHITE}
            value={formatCurrency(asset.investmentAmountIncrement ?? 0)}
            variant="subtitle3"
            data={(totalInvestmentAmountInput !== 0 && totalInvestmentAmountInput % asset.investmentAmountIncrement! === 0)}
          />
          <InputHelperTextWithIcon
            text="Above minimum investment amount"
            color={AccButtonColors.WHITE}
            value={formatCurrency(asset.minimumInvestmentAmount ?? 0)}
            variant="subtitle3"
            data={totalInvestmentAmountInput >= asset.minimumInvestmentAmount!}
          />
          <InputHelperTextWithIcon
            text="Below maximum investment amount"
            color={AccButtonColors.WHITE}
            value={formatCurrency(asset.totalInvestmentAmount!)}
            variant="subtitle3"
            data={totalInvestmentAmountInput <= asset.totalInvestmentAmount!}
          />
          <InputHelperTextWithIcon
            text="Below or equal to asset's remaining amount"
            color={AccButtonColors.WHITE}
            value={formatCurrency(asset.remainingAmount!)}
            variant="subtitle3"
            data={totalInvestmentAmountInput <= asset.remainingAmount!}
          />
        </div>
      </div>
      <CashFlowSimulationBar
        targetedFundingDate={asset.targetedFundingDate.substring(0, 7)}
        cashFlowModelRows={cashFlowModelRows ?? []}
        totalInvestmentAmount={asset.totalInvestmentAmount}
        investmentAmount={totalInvestmentAmountInput}
        displayTargetedCashFlow={false}
        isPerformanceCashFlow={false}
      />
      <br />
    </div>
  );
};

const styles = {
  innerContainer: {
    borderRadius: "16px",
    height: "fit-content",
    alignItems: "center",
    width: "50%",
    background: COLORS.layeredImageGradient,
    display: "flex",
  },
};

export default CashFlowModal;
