import {
  UMBApplicationTypes,
  UMBCommercialFormValues,
  UMBEmploymentTypes,
  UMBIdTypes,
  UMBIncomeTiers,
  UMBTaxClassifications,
  UMBYesNoValues,
} from "./umb.interfaces";
import * as Yup from "yup";
import moment from "moment";

export const formSelectOptions = {
  walletType: [
    {
      label: "Individual",
      value: UMBApplicationTypes.INDIVIDUAL,
    },
    {
      label: "Joint",
      value: UMBApplicationTypes.JOINT,
    },
    {
      label: "Commercial Entity (i.e Corporation, Partnership, LLC, Trust etc.)",
      value: UMBApplicationTypes.ENTITY,
    },
  ],
  yesNoOptions: [
    {
      label: "Yes",
      value: UMBYesNoValues.YES,
    },
    {
      label: "No",
      value: UMBYesNoValues.NO,
    },
  ],
  yesNoNaOptions: [
    {
      label: "Yes",
      value: UMBYesNoValues.YES,
    },
    {
      label: "No",
      value: UMBYesNoValues.NO,
    },
    {
      label: "Not Applicable",
      value: UMBYesNoValues.NOT_APPLICABLE,
    },
  ],
  employmentType: [
    {
      label: "Full-Time",
      value: UMBEmploymentTypes.FULL_TIME,
    },
    {
      label: "Part-Time",
      value: UMBEmploymentTypes.PART_TIME,
    },
    {
      label: "Not Employed",
      value: UMBEmploymentTypes.NOT_EMPLOYED,
    },
    {
      label: "Retired",
      value: UMBEmploymentTypes.RETIRED,
    },
  ],
  annualIncome: [
    {
      label: "Less than $100k",
      value: UMBIncomeTiers.UNDER_100K,
    },
    {
      label: "$100k-$300k",
      value: UMBIncomeTiers.BETWEEN_100K_300K,
    },
    {
      label: "$300k-$500k",
      value: UMBIncomeTiers.BETWEEN_300K_500K,
    },
    {
      label: "Above $500k",
      value: UMBIncomeTiers.ABOVE_500K,
    },
  ],
  identificationType: [
    {
      label: "Driver's License",
      value: UMBIdTypes.DRIVERS_LICENSE,
    },
    {
      label: "State Issued ID",
      value: UMBIdTypes.STATE_ID,
    },
    {
      label: "US Issued Military ID",
      value: UMBIdTypes.US_MILITARY_ID,
    },
    {
      label: "Passport",
      value: UMBIdTypes.PASSPORT,
    },
    {
      label: "Green Card",
      value: UMBIdTypes.GREEN_CARD,
    },
    // {
    //   label: "Visa",
    //   value: UMBIdTypes.VISA,
    // },
  ],
  federalTaxClassification: [
    {
      label: "Individual",
      value: UMBTaxClassifications.INDIVIDUAL,
    },
    {
      label: "Trust/Estate",
      value: UMBTaxClassifications.TRUST,
    },
    {
      label: "Limited Liability Company",
      value: UMBTaxClassifications.LLC,
    },
  ],
  commercialYesNoValues: [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ],
};

export enum FormPages {
  MFALogin = "mfaLogin",
  WalletType = "walletType",
  AccountName = "accountName",
  USAPatriotAct = "USAPatriotAct",
  DateOfBirth = "dateOfBirth",
  IsUSCitizen = "isUSCitizen",
  CountryOfBirth = "countryOfBirth",
  SSN = "ssn",
  VerifyInitialInformation = "verifyInitialInformation",
  IsOfficialGovernmentSenior = "isOfficialGovernmentSenior",
  IdentificationType = "identificationType",
  IsEmployed = "isEmployed",
  IsPrimaryAccountHolder = "isPrimaryAccountHolder",
  //DoesFatcaCodeIndicateExempt = "doesFatcaCodeIndicateExempt",
  NotifiedByIRSSubjectToBackupWithholding = "notifiedByIRSSubjectToBackupWithholding",
  AreTaxStatementsAccurate = "areTaxStatementsAccurate",
  SubmitScreen = "submitScreen",
  // Joint screens
  JointApplicantInfo = "jointApplicantInfo",
  // Commercial screens
  IsAuthorizedManager = "isAuthorizedManager",
  CompanyInformation = "companyInformation",
  DueDilligence = "dueDilligence",
  ApplicantPersonalInformation = "applicantPersonalInformation",
  NumberOfBeneficialOwners = "numberOfBeneficialOwners",
  IsApplicantBeneficialOwner = "isApplicantBeneficialOwner",
  BeneficialOwners = "beneficialOwners",
  AuthorizedACHSigners = "authorizedACHSigners",
  UploadArtifacts = "uploadArtifacts",
}

export const individualFormPageOrder = [
  FormPages.WalletType,
  FormPages.MFALogin,
  FormPages.AccountName,
  FormPages.USAPatriotAct,
  FormPages.DateOfBirth,
  FormPages.IsUSCitizen,
  FormPages.CountryOfBirth,
  FormPages.SSN,
  FormPages.VerifyInitialInformation,
  FormPages.IsOfficialGovernmentSenior,
  FormPages.IdentificationType,
  FormPages.IsEmployed,
  FormPages.IsPrimaryAccountHolder,
  //FormPages.DoesFatcaCodeIndicateExempt,
  FormPages.NotifiedByIRSSubjectToBackupWithholding,
  FormPages.AreTaxStatementsAccurate,
  FormPages.SubmitScreen,
];
export const secondaryJointFormPageOrder = [
  FormPages.MFALogin,
  FormPages.AccountName,
  FormPages.USAPatriotAct,
  FormPages.DateOfBirth,
  FormPages.IsUSCitizen,
  FormPages.CountryOfBirth,
  FormPages.SSN,
  FormPages.VerifyInitialInformation,
  FormPages.IsOfficialGovernmentSenior,
  FormPages.IdentificationType,
  FormPages.IsEmployed,
  FormPages.IsPrimaryAccountHolder,
  //FormPages.DoesFatcaCodeIndicateExempt,
  FormPages.NotifiedByIRSSubjectToBackupWithholding,
  FormPages.AreTaxStatementsAccurate,
  FormPages.SubmitScreen,
];

export const primaryJointFormPageOrder = [
  FormPages.WalletType,
  FormPages.MFALogin,
  FormPages.AccountName,
  FormPages.USAPatriotAct,
  FormPages.DateOfBirth,
  FormPages.IsUSCitizen,
  FormPages.CountryOfBirth,
  FormPages.SSN,
  FormPages.VerifyInitialInformation,
  FormPages.IsOfficialGovernmentSenior,
  FormPages.IdentificationType,
  FormPages.IsEmployed,
  FormPages.IsPrimaryAccountHolder,
  //FormPages.DoesFatcaCodeIndicateExempt,
  FormPages.NotifiedByIRSSubjectToBackupWithholding,
  FormPages.AreTaxStatementsAccurate,
  FormPages.JointApplicantInfo,
  FormPages.SubmitScreen,
];

export const commercialFormPageOrder = [
  FormPages.AccountName,
  FormPages.MFALogin,
  FormPages.USAPatriotAct,
  FormPages.IsAuthorizedManager,
  FormPages.DateOfBirth,
  FormPages.IsUSCitizen,
  FormPages.CountryOfBirth,
  FormPages.SSN,
  FormPages.CompanyInformation,
  FormPages.DueDilligence,
  FormPages.ApplicantPersonalInformation,
  FormPages.NumberOfBeneficialOwners,
  FormPages.IsApplicantBeneficialOwner,
  FormPages.BeneficialOwners,
  FormPages.AuthorizedACHSigners,
  FormPages.UploadArtifacts,
  FormPages.SubmitScreen,
];

export const UMBFormValueSchema = Yup.object().shape({
  dateOfBirth: Yup.date()
    .min(new Date(new Date().setFullYear(1900, 1, 1)), "Must be a valid date")
    .test({
      message: "You must be 18 years old or over to proceed!",
      test: (value) => moment().diff(moment(value), "years") >= 18,
    }),
  yearOfUSCitizenship: Yup.number()
    .min(1900, "Must be a valid year")
    .max(new Date().getFullYear(), "Must be in past"),
  ssn: Yup.string().length(11, "Must be a valid social security number"),
  email: Yup.string().email("Must be a valid email").max(255),
  documentExpirationDate: Yup.date()
    .min(new Date(), "Date must be after today")
    .max(
      new Date(new Date().setFullYear(2075, 1, 1)),
      "Must be a valid expiration date",
    ),
});

export const commercialFormInitialValues: Partial<UMBCommercialFormValues> = {
  doesConsentToElectronicRecords: "YES",
  applicant: {
    isAuthorizedManager: "",
    doesApplicantOwn25PercentOrMore: "",
    firstName: "",
    middleName: "",
    lastName: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
    },
    phone: "",
    email: "",
    ssn: "",
    dateOfBirth: "",
    countryOfBirth: "",
    yearOfUSCitizenship: "",
    isUSCitizen: "",
    countryOfCitizenship: "",
    isResidentAlien: "",
    identification: {
      driversLicenseNumber: "",
      driversLicenseIssuingState: "",
      driversLicenseExpirationDate: "",
      passportNumber: "",
      passportIssuingCountry: "",
      passportExpirationDate: "",
    },
  },
  applicantIsBeneficialOwner: "",
  beneficialOwners: [
    {
      middleName: "",
      firstName: "",
      address: {
        line1: "",
        line2: "",
        city: "",
        postalCode: "",
        state: "",
        country: "",
      },
      ssn: "",
      identification: {
        driversLicenseIssuingState: "",
        driversLicenseNumber: "",
        driversLicenseExpirationDate: "",
        passportNumber: "",
      },
      countryOfBirth: "",
      lastName: "",
      email: "",
      phone: "",
      dateOfBirth: "",
      yearOfUSCitizenship: "",
    },
    {
      middleName: "",
      firstName: "",
      address: {
        line1: "",
        line2: "",
        city: "",
        postalCode: "",
        state: "",
        country: "",
      },
      ssn: "",
      identification: {
        driversLicenseIssuingState: "",
        driversLicenseNumber: "",
        driversLicenseExpirationDate: "",
        passportNumber: "",
      },
      countryOfBirth: "",
      lastName: "",
      email: "",
      phone: "",
      dateOfBirth: "",
      yearOfUSCitizenship: "",
    },
    {
      middleName: "",
      firstName: "",
      address: {
        line1: "",
        line2: "",
        city: "",
        postalCode: "",
        state: "",
        country: "",
      },
      ssn: "",
      identification: {
        driversLicenseIssuingState: "",
        driversLicenseNumber: "",
        driversLicenseExpirationDate: "",
        passportNumber: "",
      },
      countryOfBirth: "",
      lastName: "",
      email: "",
      phone: "",
      dateOfBirth: "",
      yearOfUSCitizenship: "",
    },
    {
      middleName: "",
      firstName: "",
      address: {
        line1: "",
        line2: "",
        city: "",
        postalCode: "",
        state: "",
        country: "",
      },
      ssn: "",
      identification: {
        driversLicenseIssuingState: "",
        driversLicenseNumber: "",
        driversLicenseExpirationDate: "",
        passportNumber: "",
      },
      countryOfBirth: "",
      lastName: "",
      email: "",
      phone: "",
      dateOfBirth: "",
      yearOfUSCitizenship: "",
    },
  ],
  authorizedSignersWithACHAuthority: [null],
  company: {
    legalEntityName: "",
    dba: "",
    taxIdNumber: "",
    entityType: "",
    organizationState: "",
    yearFounded: "",
    businessClassification: "",
    primaryBusinessActivity: "",
    mailingAddressDifferentFromPrimary: "NO",
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
    },
    mailingAddress: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
    },
  },
  dueDilligence: {
    operatesMoneyServicesBusiness: "",
    hasOrProcessesATMTransactions: "",
    hasOverseasOffice: "",
    overseasOfficeLocation: "",
    controlledByNonUSPerson: "",
    foreignOwnershipDescription: "",
    businessHeadquarters: "",
    hasPEPOwnerOrController: "",
    ownerOrControllerPEPDescription: "",
    engagesInImportExportActivity: "",
    anticpatedImportExportGoodsInvolved: "",
    expectedImportExportTradeDollarAmount: "",
    anticipatedImportExportCountries: "",
    majorImportExportCustomersOrSuppliers: "",
    receivesOrSendsInfoForBets: "",
    isThirdPartyProcessorOrOriginator: "",
    processorOrOriginatorPaymentNatureDescription: "",
  },
};

const beneficialOwnerFormValues = (idx: number) => [
  `beneficialOwners[${idx}].firstName`,
  `beneficialOwners[${idx}].lastName`,
  `beneficialOwners[${idx}].dateOfBirth`,
  `beneficialOwners[${idx}].countryOfBirth`,
  `beneficialOwners[${idx}].ssn`,
  `beneficialOwners[${idx}].address.line1`,
  `beneficialOwners[${idx}].address.line2`,
  `beneficialOwners[${idx}].address.city`,
  `beneficialOwners[${idx}].address.state`,
  `beneficialOwners[${idx}].address.postalCode`,
  `beneficialOwners[${idx}].address.country`,
  `beneficialOwners[${idx}].identification.driversLicenseNumber`,
  `beneficialOwners[${idx}].identification.driversLicenseIssuingState`,
  `beneficialOwners[${idx}].identification.driversLicenseExpirationDate`,
  `beneficialOwners[${idx}].identification.passportNumber`,
  `beneficialOwners[${idx}].identification.passportIssuingCountry`,
  `beneficialOwners[${idx}].identification.passportExpirationDate`,
];

export const nestedBeneficialOwnerFormValues = {
  [1]: beneficialOwnerFormValues(1),
  [2]: beneficialOwnerFormValues(2),
  [3]: beneficialOwnerFormValues(3),
  [4]: beneficialOwnerFormValues(4),
};
