import gql from "graphql-tag";
import { makeGraphqlRequest } from "../../utils";
import { gql as gqlv2 } from "graphql-request";

export const CREATE_INVESTMENT = gql`
  mutation CreateInvestment($input: InvestmentInput!) {
    createInvestment(input: $input) {
      name
      amount
      investmentId
      status
      type
      id
      committedDate
      wallet {
        walletId
        name
      }
      asset {
        assetId
        name
        totalInvestmentAmount
      }
    }
  }
`;

export const CREATE_INVESTMENT_V2 = gqlv2`
  mutation CreateInvestment($input: InvestmentInput!) {
    createInvestment(input: $input) {
      name
      amount
      investmentId
      status
      type
      id
      committedDate
      wallet {
        walletId
        name
      }
      asset {
        assetId
        name
        totalInvestmentAmount
      }
    }
  }
`;

export interface CreateInvestmentInput {
  input: {
    name: string;
    amount: string | number;
    status: string;
    type: string;
    assetObjectId: string;
    walletObjectId?: string;
    committedDate?: string;
  };
}

export const createInvestment = (investmentPayload: CreateInvestmentInput) =>
  makeGraphqlRequest(CREATE_INVESTMENT_V2, investmentPayload);

export const UPDATE_INVESTMENT = gql`
  mutation UpdateInvestment($id: String!, $input: InvestmentInput!) {
    updateInvestment(_id: $id, input: $input) {
      id
      name
      amount
      status
      type
      committedDate
      wallet {
        walletId
        name
      }
      asset {
        assetId
        name
        totalInvestmentAmount
      }
    }
  }
`;

export const UPDATE_INVESTMENT_V2 = gqlv2`
  mutation UpdateInvestment($id: String!, $input: InvestmentInput!) {
    updateInvestment(_id: $id, input: $input) {
      id
      name
      amount
      status
      type
      committedDate
      wallet {
        walletId
        name
      }
      asset {
        assetId
        name
        totalInvestmentAmount
      }
    }
  }
`;

export interface UpdateInvestmentInput {
  id: string;
  input: {
    name?: string;
    amount?: string | number;
    status?: string;
    type?: string;
    assetObjectId: string;
    walletObjectId?: string;
    committedDate: string;
  };
}

export const updateInvestment = (investmentPayload: UpdateInvestmentInput) =>
  makeGraphqlRequest(UPDATE_INVESTMENT_V2, investmentPayload);

export const DELETE_INVESTMENT = gql`
  mutation DeleteInvestment($id: String!) {
    deleteInvestment(_id: $id) {
      id
      investmentId
    }
  }
`;

const GET_INVESTMENT_V2 = gqlv2`
  query GetInvestment($investmentId: Int!) {
  getInvestment(investmentId: $investmentId) {
    id
    name
    investmentId
    amount
    createdAt
    status
    type
    committedDate
    wallet{
      id
      walletId
      name
      walletStatus{
        id
        walletStatusId
        name
      }
    }
    asset{
      id
      assetId
      name
      summary
      coverImageLocation
      totalInvestmentAmount
      targetedIRR
      targetedAverageCashYield
      targetedMOIC
      targetedMaturity
      targetedInvestmentPeriod
      targetedFundingDate
      assetDocuments
      assetStatus {
        name
      }
    }
  }
}
`;

export const getInvestment = (investmentId: number) =>
  makeGraphqlRequest(GET_INVESTMENT_V2, { investmentId });

export const GET_INVESTMENTS_V2 = gqlv2`
  query getInvestments {
    getInvestments {
      id
      investmentId
      name
      amount
      createdAt
      status
      type
      committedDate
      wallet {
        id
        walletId
        name
      }
      asset {
        id
        assetId
        name
        totalInvestmentAmount
      }
    }
  }
`;

export const getInvestments = () => makeGraphqlRequest(GET_INVESTMENTS_V2);

export const GET_USER_PORTFOLIO_INVESTMENTS_V2 = gqlv2`
query GetUserPortfolioInvestments {
  getUserPortfolioInvestments {
    walletName
    walletObjectId
    walletAvailableAmount
    walletId
    walletStatusObjectId
    investments {
      name
      amount
      investmentId
      status
      type
      createdAt
      committedDate
      asset {
        id
        name
        summary
        coverImageLocation
        targetedIRR
        targetedAverageCashYield
        targetedMaturity
        targetedInvestmentPeriod
        targetedMOIC
        targetedFundingDate
        totalInvestmentAmount
        remainingAmount
        assetStatus {
          name
        }
        assetType {
          assetClass {
            vertical {
              name
            }
          }
        }
      }
    }
  }
}
`;

export const getUserPortfolioInvestments = () =>
  makeGraphqlRequest(GET_USER_PORTFOLIO_INVESTMENTS_V2);
