import { faCircle, faDotCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import React from "react";
import COLORS from "../../colors";
import PageCard from "../PageCard";

interface Props {
  selected: boolean;
  label: string;
  onClick: () => void;
  image?: string;
  cardStyle?: React.CSSProperties;
  className?: string;
}

const RadioSelectCard: React.FC<Props> = ({
  selected, label, onClick, image, cardStyle, className,
}) => (
  <PageCard
    className={className}
    onClick={onClick}
    style={{
      border: `1px solid ${selected ? COLORS.green : "rgba(0,0,0,0)"}`,
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      padding: 25,
      height: image ? 225 : "inherit",
      ...cardStyle,
    }}
  >
    <div
      style={{
        display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", height: "100%", width: "100%",
      }}
    >
      <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
        {selected
          ? <FontAwesomeIcon
            size="lg"
            color={COLORS.green}
            icon={faDotCircle} />
          : <FontAwesomeIcon
            size="lg"
            color={COLORS.darkGray}
            icon={faCircle} />}
        <Typography style={{ fontSize: 17.5, fontWeight: 300, marginLeft: 15 }}>{label}</Typography>
      </div>
      {image ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <img
            style={{ maxHeight: 150, maxWidth: 175, paddingTop: 25 }}
            src={image} />
        </div>
      ) : null}
    </div>
  </PageCard>
);

export default RadioSelectCard;
