import { makeGraphqlRequest } from "../../utils";
import { gql as gqlv2 } from "graphql-request";


export const GET_COMMERCIAL_ENTITY_TYPES_V2 = gqlv2`
  query GetCommercialEntityTypes {
    getCommercialEntityTypes {
      name
      requiredDocuments
      commercialEntityTypeId
      commercialEntityTypeObjectId
    }
  }
`;

export const getCommercialEntityTypes = () => makeGraphqlRequest(GET_COMMERCIAL_ENTITY_TYPES_V2);