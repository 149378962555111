import { useMutation } from "@apollo/client";
import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik, Form, Field, FormikValues } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DELETE_DEAL_TYPE, UPDATE_DEAL_TYPE, CREATE_DEAL_TYPE } from "../../../api/query/DealTypes";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import { isNil, isNotNil } from "../../../common/utilities";
import { EditModeTypes } from "../../../enums";
import CancelConfirmDialogModal from "../../../common/components/CancelConfirmDialogModal";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import COLORS from "../../../common/colors";
import { useQueryClient } from "react-query";
import { useDealType, QueryKeys } from "../../../api/hooks/graphqlHooks";
import AccInput from "../../../common/components/AccInput";
import { getAllTemplates, Template } from "../../../api/rest/documents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFilePdf, faTrash } from "@fortawesome/pro-light-svg-icons";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import ClickableLinkWithIcon, { TitleLinkInput } from "../../../common/components/ClickableLinkWithIcon";
import { UploadTypes } from "../../../common/types";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "../Assets/EditorToolbar";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";
import PageWrapper from "../../../common/components/PageWrapper";

type DealTypeDetailParams = {
  id?: string;
};

type DealTypeDetailProps = RouteComponentProps<DealTypeDetailParams>;
const DealTypeForm: React.FC<DealTypeDetailProps> = withErrorBoundary({}, ({ match, history }: DealTypeDetailProps) => {
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  const queryClient = useQueryClient();
  const editMode: EditModeTypes = isNil(match.params.id) ? EditModeTypes.ADD : EditModeTypes.EDIT;
  if (editMode === EditModeTypes.ADD) {
    queryClient.removeQueries(QueryKeys.DEAL_TYPE);
  }

  const {
    data: getDealTypeData,
    isLoading: getDealTypeLoading,
    isFetching: getDealTypeFetching,
    isError: getDealTypeError,
  } = useDealType(Number(match.params.id), {
    enabled: editMode === EditModeTypes.EDIT,
    refetchOnMount: true,
  });

  const [allHelloSignTemplates, setAllHelloSignTemplates] = useState<Array<Template>>([]);
  const [selectedHelloSignTemplate, setSelectedHelloSignTemplate] = useState<any>({});
  const [cancelDialogVisible, setCancelDialogVisibible] = useState(false);

  const [disqualifyingEventDocumentPreview, setDisqualifyingEventDocumentPreview] = useState<TitleLinkInput | null>(null);
  const [uploadedDisqualifyingEvent, setUploadedDisqualifyingEvent] = useState<any>();

  const [investmentProceduresPreview, setInvestmentProceduresPreview] = useState<TitleLinkInput | null>(null);
  const [uploadedInvestmentProcedures, setUploadedInvestmentProcedures] = useState<any>();

  async function getAllHelloSignTemplates() {
    const allTemplates = await getAllTemplates();
    if (isNil(allTemplates)) {
      return toast("Error getting templates!");
    }
    setAllHelloSignTemplates(allTemplates.sort((itemA, itemB) => itemA.title.localeCompare(itemB.title)));
  }

  useEffect(() => {
    const currentTemplate = allHelloSignTemplates.find((item) => item?.template_id === getDealTypeData?.getDealType?.helloSignTemplateId);
    setSelectedHelloSignTemplate(currentTemplate);
  }, [allHelloSignTemplates]);

  useEffect(() => {
    getAllHelloSignTemplates();
  }, []);

  const [createDealType] = useMutation(CREATE_DEAL_TYPE);
  const [deleteDealType] = useMutation(DELETE_DEAL_TYPE);
  const [updateDealType] = useMutation(UPDATE_DEAL_TYPE);

  const openCancelDialog = () => setCancelDialogVisibible(true);
  const closeCancelDialog = () => setCancelDialogVisibible(false);
  const BLANK_FORM_VALUES = {
    name: "",
    helloSignTemplateId: "",
    taxDocumentType: "",
    requiresAccreditation: false,
    accreditationConfirmation: "",
    generalPartner: "",
    lawFirm: "",
    offeringEntity: "",
    disqualifyingEvent: "",
  };

  const initialFormFieldValues = () => (getDealTypeData?.getDealType ? {
    name: getDealTypeData?.getDealType.name,
    helloSignTemplateId: getDealTypeData?.getDealType.helloSignTemplateId,
    taxDocumentType: getDealTypeData?.getDealType.taxDocumentType,
    requiresAccreditation: getDealTypeData?.getDealType.requiresAccreditation,
    accreditationConfirmation: getDealTypeData?.getDealType?.accreditationConfirmation!,
    generalPartner: getDealTypeData?.getDealType.generalPartner,
    lawFirm: getDealTypeData?.getDealType.lawFirm,
    offeringEntity: getDealTypeData?.getDealType.offeringEntity,
    disqualifyingEvent: getDealTypeData?.getDealType.disqualifyingEvent,
    investmentProceduresDocumentLocation: null,
    disqualifyingEventDocumentLocation: null,
  } : BLANK_FORM_VALUES);


  const handleDelete = async (objectId: string | number) => {
    try {
      await deleteDealType({
        variables: {
          id: objectId,
        },
      });
      toast("Deal type deleted successfully!", {
        type: toast.TYPE.SUCCESS,
      });
      queryClient.refetchQueries(QueryKeys.DEAL_TYPES);
      history.push("/admin/dealtypes");
    } catch (err: any) {
      toast(err.message || err.msg || err.toString() || "There was an error deleting deal type.", {
        type: toast.TYPE.WARNING,
      });
    }
  };

  const submitForm = async (values: FormikValues, { resetForm }: any) => {
    if (!disqualifyingEventDocumentPreview || !investmentProceduresPreview) {
      return toast("Please attach documents", {
        type: toast.TYPE.WARNING,
      });
    }
    if (editMode === EditModeTypes.ADD) {
      try {
        await createDealType({
          variables: {
            uploadedDisqualifyingEvent,
            uploadedInvestmentProcedures,
            input: {
              name: values.name,
              helloSignTemplateId: values.helloSignTemplateId,
              taxDocumentType: values.taxDocumentType,
              requiresAccreditation: values.requiresAccreditation,
              accreditationConfirmation: values.accreditationConfirmation,
              generalPartner: values.generalPartner,
              lawFirm: values.lawFirm,
              offeringEntity: values.offeringEntity,
              disqualifyingEvent: values.disqualifyingEvent,
              investmentProceduresDocumentLocation: investmentProceduresPreview.title,
              disqualifyingEventDocumentLocation: disqualifyingEventDocumentPreview.title,
            },
          },
        });
        toast("Deal Type added successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        queryClient.refetchQueries(QueryKeys.DEAL_TYPES);
        history.push("/admin/dealtypes");
        resetForm();
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error adding deal type.", {
          type: toast.TYPE.ERROR,
        });
      }
    } else if (editMode === EditModeTypes.EDIT) {
      try {
        await updateDealType({
          variables: {
            uploadedDisqualifyingEvent,
            uploadedInvestmentProcedures,
            id: getDealTypeData?.getDealType.id,
            input: {
              name: values.name,
              helloSignTemplateId: values.helloSignTemplateId,
              taxDocumentType: values.taxDocumentType,
              requiresAccreditation: values.requiresAccreditation,
              accreditationConfirmation: values.requiresAccreditation === true ? values.accreditationConfirmation : "",
              generalPartner: values.generalPartner,
              lawFirm: values.lawFirm,
              offeringEntity: values.offeringEntity,
              disqualifyingEvent: values.disqualifyingEvent,
            },
          },
        });
        toast("Deal Type updated successfully!", {
          type: toast.TYPE.SUCCESS,
        });
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error updating deal type.", {
          type: toast.TYPE.ERROR,
        });
      }
    }
    queryClient.refetchQueries(QueryKeys.DEAL_TYPES);
    history.push("/admin/dealtypes");
  };

  const renderSingleFile = (uploadedFile: TitleLinkInput, uploadType: UploadTypes) => {
    return (
      <>
        {isNotNil(uploadedFile) ?
          <div
            className="row center stretch m-top-15">
            <ClickableLinkWithIcon
              containerStyle={{
                marginBottom: 0,
              }}
              text={uploadedFile?.title}
              link={uploadedFile?.link}
              icon={faFilePdf}
            />
            <button
              style={{ ...styles.button, cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                switch (uploadType) {
                  case UploadTypes.DISQUALIFYING_EVENT_DOCUMENT:
                    setUploadedDisqualifyingEvent(null);
                    setDisqualifyingEventDocumentPreview(null);
                    break;
                  case UploadTypes.INVESTMENT_PROCEDURES:
                    setUploadedInvestmentProcedures(null);
                    setInvestmentProceduresPreview(null);
                    break;
                  default:
                    break;
                }
              }
              }>
              <FontAwesomeIcon
                size="1x"
                className="acc-red"
                icon={faTrash}
              />
            </button>
          </div> : null}
      </>
    );
  };

  const handleSingleFileUpload = async (e: ChangeEvent<HTMLInputElement>, uploadType: UploadTypes) => {
    const uploadEvent = e.target as HTMLInputElement;
    if (uploadEvent.files?.length) {
      const filePreview = {
        title: uploadEvent.files[0].name,
        link: URL.createObjectURL(uploadEvent.files[0]),
      };
      const uploadedFile = uploadEvent.files[0];
      const reader = new FileReader();

      reader.onloadend = async () => {
        switch (uploadType) {
          case UploadTypes.DISQUALIFYING_EVENT_DOCUMENT:
            setDisqualifyingEventDocumentPreview(filePreview);
            setUploadedDisqualifyingEvent(uploadedFile);
            break;
          case UploadTypes.INVESTMENT_PROCEDURES:
            setInvestmentProceduresPreview(filePreview);
            setUploadedInvestmentProcedures(uploadedFile);
            break;
          default:
            break;
        }
      };
      reader.readAsDataURL(uploadedFile);
    }
  };

  const setUserMessage = (values: FormikValues) => {
    if (!values.accreditationConfirmation || values.accreditationConfirmation === "<p><br></p>") {
      toast("Accreditation Confirmation is required.", {
        type: toast.TYPE.WARNING,
      });
    }
  };

  useEffect(() => {
    const currentInvestmentProceduresDocument = getDealTypeData?.getDealType?.investmentProceduresDocumentLocation;
    if (currentInvestmentProceduresDocument) {
      const fileName = decodeURIComponent(currentInvestmentProceduresDocument.split("/investmentProcedures/")[1]);
      const displayCurrentInvestmentProceduresDocument: TitleLinkInput = { link: currentInvestmentProceduresDocument, title: fileName };
      setInvestmentProceduresPreview(displayCurrentInvestmentProceduresDocument);
    }
    const currentDisqualifyingEventDocument = getDealTypeData?.getDealType?.disqualifyingEventDocumentLocation;
    if (currentDisqualifyingEventDocument) {
      const fileName = decodeURIComponent(currentDisqualifyingEventDocument.split("/disqualifyingEvent/")[1]);
      const displayCurrentDisqualifyingEventDocument: TitleLinkInput = { link: currentDisqualifyingEventDocument, title: fileName };
      setDisqualifyingEventDocumentPreview(displayCurrentDisqualifyingEventDocument);
    }
  }, [getDealTypeData]);

  return (
    <PageWrapper
      title={`${editMode === EditModeTypes.ADD ? "Add" : "Update"} Deal Type`}
      showLoader={getDealTypeLoading || getDealTypeFetching}
      showError={getDealTypeError}
      errorMessage="Cannot retrieve deal types"
    >
      <br />
      <div>
        <Formik
          initialValues={initialFormFieldValues()}
          enableReinitialize
          onSubmit={submitForm}
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form>
              <div className="column-reverse-mobile">
                <div className="row stretch center width-100 column-mobile">
                  <Link
                    to="/admin/dealtypes"
                    className="width-100-mobile">
                    <AccButton
                      color={AccButtonColors.WHITE}
                      label="Cancel"
                      style={{ backgroundColor: COLORS.white }}
                    />
                  </Link>
                  <div className="row width-100-mobile column-mobile">
                    <AccButton
                      color={AccButtonColors.GREEN}
                      type="submit"
                      label={editMode === EditModeTypes.ADD ? "Submit" : "Update"}
                      className="green-button m-top-15-mobile m-bottom-15-mobile"
                      onClick={() => {
                        if (values.requiresAccreditation) {
                          setUserMessage(values);
                        }
                      }}
                    />
                    {editMode === EditModeTypes.EDIT
                      ? (
                        <>
                          <AccButton
                            color={AccButtonColors.RED_OUTLINE}
                            label="Delete"
                            className="delete-button m-left-15 display-none-mobile"
                            type="button"
                            onClick={openCancelDialog}
                          />
                          {mobileLayout
                            && (
                              <AccButton
                                color={AccButtonColors.RED_OUTLINE}
                                label="Delete Deal Type"
                                className="delete-button"
                                type="button"
                                width="40%"
                                style={styles.mobileDeleteButton}
                                onClick={openCancelDialog}
                              />
                            )}
                        </>
                      )
                      : null}
                    {getDealTypeData ? <CancelConfirmDialogModal
                      isVisible={cancelDialogVisible}
                      dialogBody="Are you sure you would like to delete this Deal Type?"
                      onClickCancel={closeCancelDialog}
                      onClickDelete={() => handleDelete(getDealTypeData?.getDealType.id)}
                    /> : null}
                  </div>
                </div>
                <br />
                <div className="width-100 row start stretch">
                  <div
                    style={styles.border}
                    className="width-50 width-100-mobile m-right-50">
                    <Field
                      name="name"
                      type="text"
                      label="Deal Type Name"
                      as={AccInput}
                      required
                    />
                    <Field
                      name="taxDocumentType"
                      type="text"
                      label="Tax Document"
                      as={AccInput}
                      required
                    />
                    <Field
                      name="generalPartner"
                      type="text"
                      label="General Partner"
                      as={AccInput}
                    //required
                    />
                    <Field
                      name="lawFirm"
                      type="text"
                      label="Law Firm"
                      as={AccInput}
                      required
                    />
                    <Field
                      name="offeringEntity"
                      type="text"
                      label="Offering Entity"
                      as={AccInput}
                      required
                    />
                    <br />
                    <FormControlLabel
                      control={<Checkbox
                        checked={values.requiresAccreditation}
                        name="requiresAccreditation"
                        onChange={handleChange}
                        value={values.requiresAccreditation}
                        style={styles.checkbox} />}
                      label="Requires accreditation verification" />
                    <br />
                    {values.requiresAccreditation &&
                      <div className="form-group col-md-12 editor m-top-15">
                        <EditorToolbar toolbarId="t1" />
                        <ReactQuill
                          theme="snow"
                          onChange={(e) => setFieldValue("accreditationConfirmation", e, false)}
                          placeholder="Accreditation Confirmation*"
                          modules={modules("t1")}
                          formats={formats}
                          value={values.accreditationConfirmation || ""}
                        />
                      </div>}
                  </div>
                  <div
                    style={styles.border}
                    className="width-50 width-100-mobile column start">
                    <AccInput
                      value={values.helloSignTemplateId}
                      name="helloSignTemplateId"
                      label="Templates"
                      select
                      required
                      onChange={handleChange}
                    >
                      {allHelloSignTemplates ? allHelloSignTemplates
                        .map((item) => {
                          const isSelected = selectedHelloSignTemplate?.template_id === item.template_id;
                          return (
                            <MenuItem
                              selected={isSelected}
                              key={item.template_id}
                              value={item.template_id}>
                              {item.title}
                              {isSelected && <FontAwesomeIcon
                                icon={faCheck}
                                className="acc-green m-left-15" />}
                            </MenuItem>
                          );
                        }) : null}
                    </AccInput>
                    <Field
                      name="disqualifyingEvent"
                      type="text"
                      label="Disqualifying Event"
                      as={AccInput}
                      multiline
                      minRows={5}
                      required
                    />
                    <div
                      className="width-100 m-top-15"
                      style={styles.file}>
                      <label
                        htmlFor="disqualifyingEvent"
                        style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          size="lg"
                          className="acc-darkblue m-right-15"
                          icon={faFilePdf}
                        />
                        {" "}
                        Upload Disqualifying Event Document
                      </label>
                      <input
                        type="file"
                        className="display-none"
                        accept="application/pdf,application/vnd.ms-excel"
                        id="disqualifyingEvent"
                        onChange={(e) => handleSingleFileUpload(e, UploadTypes.DISQUALIFYING_EVENT_DOCUMENT)}
                      />
                      {renderSingleFile(disqualifyingEventDocumentPreview!, UploadTypes.DISQUALIFYING_EVENT_DOCUMENT)}
                    </div>
                    <div
                      className="width-100 m-top-15"
                      style={styles.file}>
                      <label
                        htmlFor="investmentProcedures"
                        style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          size="lg"
                          className="acc-darkblue m-right-15"
                          icon={faFilePdf}
                        />
                        {" "}
                        Upload Investment Procedures
                      </label>
                      <input
                        type="file"
                        className="display-none"
                        accept="application/pdf,application/vnd.ms-excel"
                        id="investmentProcedures"
                        onChange={(e) => handleSingleFileUpload(e, UploadTypes.INVESTMENT_PROCEDURES)}
                      />
                      {renderSingleFile(investmentProceduresPreview!, UploadTypes.INVESTMENT_PROCEDURES)}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </PageWrapper >
  );
});

const styles = {
  border: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    padding: "15px",
  },
  mobileDeleteButton: {
    alignSelf: "flex-start",
    border: "none",
    textAlign: "left",
    padding: 0,
    height: 20,
  },
  checkbox: {
    color: COLORS.green,
    "&.Mui-checked": {
      color: COLORS.green,
    },
  },
  file: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    padding: "15px",
  },
  button: {
    backgroundColor: "transparent",
    border: "none",
  },
};

export default withRouter(DealTypeForm);