import { IconButton, InputAdornment, styled, Typography } from "@material-ui/core";
import { Field, Form, Formik, FormikValues } from "formik";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import COLORS from "../../../common/colors";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import AccInput from "../../../common/components/AccInput";
import InputHelperTextWithIcon from "../../../common/components/InputHelperTextWithIcon";
import { isNil } from "../../../common/utilities";
import { userPasswordUpdate } from "../../../api/rest/user";
import { SessionContext } from "../../../context";
import LoadingAnimation from "../../../common/components/LoadingAnimation";
import { Visibility, VisibilityOff } from "@material-ui/icons";

interface Props {
    setShowResetPassword: (arg: boolean) => void;
}

const ResetPassword: React.FC<Props> = ({ setShowResetPassword }) => {

    const { user: sessionUser } = useContext(SessionContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [passwordFieldVisibility, setPasswordFieldVisibility] = useState({
        currentPassword: false,
        newPassword: false,
        newPassword2: false,
    });

    const handleClickShowPassword = (field: "newPassword" | "newPassword2" | "currentPassword") => {
        setPasswordFieldVisibility({
            ...passwordFieldVisibility,
            [field]: !passwordFieldVisibility[field],
        });
    };

    const validatePasswordFields = async (values: FormikValues) => {
        if (!values.newPassword) {
            return toast("Please enter a new password", {
                type: toast.TYPE.WARNING,
            });
        } else if (!values.newPassword2) {
            return toast("Please re-type new password", {
                type: toast.TYPE.WARNING,
            });
        } else if (!values.currentPassword) {
            return toast("Please enter current password", {
                type: toast.TYPE.WARNING,
            });
        } else if (values.newPassword !== values.newPassword2) {
            return toast("New passwords do not match", {
                type: toast.TYPE.WARNING,
            });
        } else if (
            !values.newPassword.match(/[a-z]/g) ||
            !values.newPassword.match(/[A-Z]/g) ||
            !values.newPassword.match(/[0-9]/g) ||
            !values.newPassword.match(/[^a-zA-Z\d]/g) ||
            values.newPassword.length < 8
        ) {
            return toast("Your password is not strong enough", {
                type: toast.TYPE.WARNING,
            });
        } else {
            updatePassword(values);
        }
    };

    const updatePassword = async (values: FormikValues) => {
        const userRequest = { ...sessionUser };
        userRequest.newPassword = values.newPassword;
        userRequest.currentPassword = values.currentPassword;
        setIsSubmitting(true);
        try {
            const response = await userPasswordUpdate(userRequest);
            if (response?.data?.success === true) {
                setIsSubmitting(false);
                setShowResetPassword(false);
            }
            setIsSubmitting(false);
        } catch (err: any) {
            toast(
                err.message || err.msg || err.toString() || "There was an error updating user password.", {
                type: toast.TYPE.ERROR,
            });
        }
    };

    if (isSubmitting) {
        return <LoadingAnimation containerStyles={{ marginTop: "200px" }} />;
    }

    return (
        <Formik
            initialValues={{
                currentPassword: "",
                newPassword: "",
                newPassword2: "",
            }}
            onSubmit={validatePasswordFields}
        >
            {({ values }) => (
                <Form>
                    <div>
                        <Typography
                            variant="h4"
                            className="acc-white bold">Change password</Typography>
                        <br />
                        <Typography
                            variant="subtitle1"
                            className="acc-white">Type your current password</Typography>
                        <StyledInput
                            as={AccInput}
                            margin="normal"
                            fullWidth
                            name="currentPassword"
                            placeholder="Current Password"
                            type={passwordFieldVisibility.currentPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            disableRipple
                                            disableFocusRipple
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword("currentPassword")}
                                            edge="end"
                                        >
                                            {passwordFieldVisibility.currentPassword ? (
                                                <Visibility style={{ fill: COLORS.lightGreen }} />
                                            ) : (
                                                <VisibilityOff style={{ fill: COLORS.green }} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>,
                            }}
                        />
                        <Typography
                            variant="subtitle1"
                            className="acc-white m-top-15">Type your new password</Typography>
                        <StyledInput
                            as={AccInput}
                            fullWidth
                            type={passwordFieldVisibility.newPassword ? "text" : "password"}
                            name="newPassword"
                            placeholder="New Password"
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            disableRipple
                                            disableFocusRipple
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword("newPassword")}
                                            edge="end"
                                        >
                                            {passwordFieldVisibility.newPassword ? (
                                                <Visibility style={{ fill: COLORS.lightGreen }} />
                                            ) : (
                                                <VisibilityOff style={{ fill: COLORS.green }} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>,
                            }}
                        />
                        <Typography
                            variant="subtitle1"
                            className="acc-white m-top-15">Retype your new password</Typography>
                        <StyledInput
                            as={AccInput}
                            fullWidth
                            name="newPassword2"
                            placeholder="Retype Password"
                            type={passwordFieldVisibility.newPassword2 ? "text" : "password"}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            disableRipple
                                            disableFocusRipple
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword("newPassword2")}
                                            edge="end"
                                        >
                                            {passwordFieldVisibility.newPassword2 ? (
                                                <Visibility style={{ fill: COLORS.lightGreen }} />
                                            ) : (
                                                <VisibilityOff style={{ fill: COLORS.green }} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>,
                            }}
                        />
                        <Typography
                            variant="subtitle2"
                            className="acc-white bold"
                            style={{ marginBottom: 1, marginTop: 20 }}
                        >Your password must:
                        </Typography>
                        <InputHelperTextWithIcon
                            color="white"
                            variant="body2"
                            text="Be at least 8 characters long"
                            data={!(values.newPassword.length > 8) === false}
                        />
                        <InputHelperTextWithIcon
                            color="white"
                            variant="body2"
                            text={"Include at least 1 lowercase letter"}
                            data={!values.newPassword.match(/[a-z]/g) === false}
                        />
                        <InputHelperTextWithIcon
                            color="white"
                            variant="body2"
                            text="Include at least 1 uppercase letter"
                            data={!values.newPassword.match(/[A-Z]/g) === false}
                        />
                        <InputHelperTextWithIcon
                            color="white"
                            variant="body2"
                            text="Include at least 1 number"
                            data={!values.newPassword.match(/[0-9]/g) === false}
                        />
                        <InputHelperTextWithIcon
                            color="white"
                            variant="body2"
                            text="Include at least 1 special character"
                            data={!values.newPassword.match(/[^a-zA-Z\d]/g) === false}
                        />
                    </div>
                    <div className="width-100 column end">
                        <AccButton
                            type="submit"
                            style={{
                                width: 200,
                                pointerEvents: isNil(values.currentPassword && values.newPassword && values.newPassword2) ? "none" : "",
                            }}
                            className="m-top-30"
                            label="Save Password"
                            color={isNil(values.currentPassword && values.newPassword && values.newPassword2)
                                ? AccButtonColors.GREEN_OUTLINE : AccButtonColors.GREEN} />
                    </div>
                </Form>
            )}
        </Formik >
    );
};


const StyledInput = styled(Field)({
    "& .MuiInputBase-root": {
        color: COLORS.white,
    },
    "& label.Mui-focused": {
        color: COLORS.green,
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: COLORS.green,
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: COLORS.white,
            color: COLORS.white,
        },
        "&.Mui-focused fieldset": {
            borderColor: COLORS.white,
            color: COLORS.white,
        },
        "&:hover fieldset": {
            borderColor: COLORS.white,
        },
        "& .MuiInputLabel-outlined": {
            color: COLORS.green,
        },
        "& .MuiSelect-iconOutlined": {
            color: COLORS.white,
        },
    },
    "& .MuiFormLabel-root": {
        color: COLORS.green,
    },
    "& .MuiOutlinedInput-input:-webkit-autofill": {
        "-webkit-box-shadow": " 0 0 0 30px #021b38 inset",
        "-webkit-text-fill-color": "#fff",
    },
});

export default ResetPassword;