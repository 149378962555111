import gql from "graphql-tag";
import { makeGraphqlRequest } from "../../utils";
import { gql as gqlv2 } from "graphql-request";

export const CREATE_VERTICAL = gql`
  mutation CreateVertical($input: VerticalInput!) {
    createVertical(input: $input) {
      name
    }
  }
`;

export const UPDATE_VERTICAL = gql`
  mutation UpdateVertical($id: String!, $input: VerticalInput!) {
    updateVertical(_id: $id, input: $input) {
      id
      name
    }
  }
`;

export const DELETE_VERTICAL = gql`
  mutation DeleteVertical($id: String!) {
    deleteVertical(_id: $id) {
      id
      verticalId
    }
  }
`;

const GET_VERTICAL_V2 = gqlv2`
  query GetVertical($verticalId: Int!) {
    getVertical(verticalId: $verticalId) {
      id
      verticalId
      name
    }
  }
`;

export const getVertical = (verticalId: number) => makeGraphqlRequest(GET_VERTICAL_V2, { verticalId });

const GET_VERTICALS_V2 = gqlv2`
  query GetVerticals {
    getVerticals {
      id
      verticalId
      name
    }
  }
`;

export const getVerticals = () => makeGraphqlRequest(GET_VERTICALS_V2);