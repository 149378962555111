import { toast } from "react-toastify";
import restClient from "../restClient";

export interface GetSignedDocumentURLResponse {
  signUrl: string;
  signatureRequestId: string;
  userId: number;
  clientId: string;
}

async function getSignedDocumentUrl(
  investmentObjectId: string,
  assetObjectId: string,
  userAddress: string,
): Promise<GetSignedDocumentURLResponse | undefined> {
  try {
    const signedDocumentUrlResponse = await restClient({
      url: "/documents/sign-url",
      method: "GET",
      params: {
        investmentObjectId: `${investmentObjectId}`,
        assetObjectId: `${assetObjectId}`,
        userAddress: `${userAddress}`,
      },
    });
    if (signedDocumentUrlResponse.data?.success) {
      return signedDocumentUrlResponse.data?.data;
    }
  } catch (err: any) {
    console.log(err);
    toast(err.message || err.msg || err.toString() || "Error getting signed document URL", {
      type: toast.TYPE.ERROR,
    });
  }
}

export type Template = {
  documents: TemplateDocument[],
  title: string,
  template_id: string,
}

export type TemplateDocument = {
  name: string,
  index: number,
}

async function getAllTemplates(): Promise<Template[] | undefined> {
  try {
    const templatesResponse = await restClient.get("/documents/templates", {
    });
    if (templatesResponse.data?.success) {
      return templatesResponse.data?.data;
    }
  } catch (err: any) {
    console.log(err);
    toast(err.message || err.msg || err.toString() || "Error getting list of documents", {
      type: toast.TYPE.ERROR,
    });
  }
}


export interface GetTemplateFilesUrlResponse {
  fileUrl: string,
}

async function getTemplateFileUrl(templateId: string): Promise<GetTemplateFilesUrlResponse | undefined> {
  try {
    const templateFileUrlResponse = await restClient.get(`/documents/template-files/${templateId}`);

    if (templateFileUrlResponse?.data?.success === true) {
      return templateFileUrlResponse.data?.data;
    } else {
      throw new Error(templateFileUrlResponse?.data?.message ?? "Error retrieving template file URL");
    }
  } catch (err: any) {
    console.log(err);
    toast(err.message || err.msg || err.toString() || "Error getting list of documents", {
      type: toast.TYPE.ERROR,
    });
  }
}

const uploadBackupInvestmentDocument = async (formData: any, assetObjectId: string) => {
  try {
    const result = await restClient.post(`/documents/download-signed-document/${assetObjectId}`,
      formData,
    );
    if (result?.data?.success === true) {
      toast("Document uploaded successfully!", {
        type: toast.TYPE.SUCCESS,
      });
    } else if (result?.data?.success === false) {
      toast(result.data.msg || "There was an error uploading document.", {
        type: toast.TYPE.ERROR,
      });
    }
    return result;
  } catch (err: any) {
    console.log(err);
    toast(err.message || err.msg || err.toString() || "Error uploading signed document", {
      type: toast.TYPE.ERROR,
    });
  }
};


export {
  getSignedDocumentUrl,
  getAllTemplates,
  getTemplateFileUrl,
  uploadBackupInvestmentDocument,
}; 
