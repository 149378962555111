import React from "react";
import LoadingAnimation from "../../common/components/LoadingAnimation";
import PageTitle from "../../common/components/PageTitle";
import { useAssets } from "../../api/hooks/graphqlHooks";
import InDepthInvestmentOpportunity from "../../common/components/InDepthInvestmentOpportunity";
import { Typography } from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import { THEME_BREAKPOINTS } from "../../common/constants";
import { Asset } from "../../common/types";
import { AssetStatus } from "../../enums";
import AvailableAssetsCard from "../../common/components/AvailableAssetsCard";


interface FilteredAssetSectionProps {
  relevantStatuses: AssetStatus[];
  sectionTitle?: string | AssetStatus;
}

const FilteredAssetSection: React.FC<FilteredAssetSectionProps> = ({ relevantStatuses, sectionTitle }) => {

  const { data } = useAssets();
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  // Filtering assets to only return the ones specified by `assetStatus` prop
  const filterRelevantAssetsBasedOnStatus =
    (list: Array<Asset>) => list.filter((item: Asset) => relevantStatuses.includes(item.assetStatus.name as AssetStatus));

  return (
    // For a section heading, simply pass a sectionTitle
    <>
      {data?.getAssets ?
        <>
          {filterRelevantAssetsBasedOnStatus(data?.getAssets).length > 0 &&
            <>
              <Typography
                variant="h4"
                className="acc-blue bold font-size-mobile-1 m-top-30-mobile">
                {sectionTitle}
              </Typography>
              <br />
            </>
          }
          <>
            {mobileLayout ?
              <>
                <div className="row m-top-30 column-mobile">
                  <div className="width-70 m-right-50 width-100-mobile m-top-30-mobile m-0-mobile">
                    <Typography
                      variant="h5"
                      className="bold acc-darkblue">
                      New Available Opportunities*
                    </Typography>
                    <br />
                    <div className="column-mobile">
                      <AvailableAssetsCard />
                    </div>
                  </div>
                </div>
                <br />
              </> :
              <>
                {filterRelevantAssetsBasedOnStatus(data?.getAssets).map((item: Asset) =>
                  <InDepthInvestmentOpportunity
                    key={item.assetId}
                    item={item}
                  />,
                )}
              </>
            }
          </>
        </>
        : null}
    </>
  );
};

interface Props {

}

const Invest: React.FC<Props> = () => {

  const { data, isLoading, isFetching } = useAssets();
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  if (isLoading || !data || isFetching) {
    return (
      <LoadingAnimation />
    );
  }
  return (
    <div>
      <PageTitle
        title={<b>Invest in New Opportunities</b>}
        subtitle="Participate in the fastest growing classes of investable assets, and upgrade your investment strategy, 
        by adding to your portfolio previously inaccessible, institutional-quality investment opportunities*"
      />
      <div className="m-top-30">
        {
          mobileLayout ?
            <FilteredAssetSection
              relevantStatuses={[AssetStatus.AVAILABLE]} />
            :
            <>
              <FilteredAssetSection
                relevantStatuses={[AssetStatus.AVAILABLE]}
              />
              <FilteredAssetSection
                relevantStatuses={[AssetStatus.FULLY_SUBSCRIBED, AssetStatus.COMING_SOON]}
                sectionTitle={AssetStatus.FULLY_SUBSCRIBED}
              />
              <FilteredAssetSection
                relevantStatuses={[AssetStatus.ACTIVE]}
                sectionTitle={AssetStatus.ACTIVE}
              />
              <FilteredAssetSection
                relevantStatuses={[AssetStatus.COMPLETED]}
                sectionTitle={AssetStatus.COMPLETED}
              />
            </>
        }
      </div>
      <div
        className="row width-100"
        style={{ fontWeight: 300, fontSize: 14 }}>
        <span>
          *Investment advisory services are provided by Access Capital Management LLC,
          an investment advisor registered (or expected to be registered) with the Securities and Exchange Commission and loan and
          other non-securities related investment services are provided by Access Key Management LLC.
        </span>
      </div>
    </div>
  );
};

export default Invest;