import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs, Divider, Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import LabeledFigure from "../../../../common/components/LabeledFigure";
import "./index.css";
import COLORS from "../../../../common/colors";
import PageCard from "../../../../common/components/PageCard";
import { Chip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faWallet } from "@fortawesome/pro-light-svg-icons";
import InvestmentCashFlow from "./InvestmentCashFlow";
import { faCalendarDay, faChartBar, faFileAlt, faSackDollar } from "@fortawesome/pro-solid-svg-icons";
import InvestmentPerformance from "./InvestmentPerformance";
import TabView from "../../../../common/components/TabView";
import AccessCard from "../../../../common/components/AccessCard";
import { RouteComponentProps, withRouter } from "react-router";
import { useInvestment } from "../../../../api/hooks/graphqlHooks";
import moment from "moment";
import { formatCurrency, formatPercentage, isPeriodOver24Months } from "../../../../common/utilities";
import LoadingAnimation from "../../../../common/components/LoadingAnimation";
import { AssetStatus, InvestmentStatus } from "../../../../enums";
import CustomTooltip from "../../../../common/components/CustomTooltip";
import InvestmentStatements from "./InvestmentStatements";

type InvestmentDetailParams = {
  id: string;
};

type InvestmentDetailProps = RouteComponentProps<InvestmentDetailParams>;

const InvestmentDetails: React.FC<InvestmentDetailProps> = ({ match }) => {

  const {
    data: investmentData,
    isLoading: investmentLoading,
    isFetching: investmentFetching,
  } = useInvestment(Number(match.params.id),
    {
      refetchOnMount: true,
    });

  const portfolioInvestmentStatus = (assetStatus: string) => {
    if (assetStatus === AssetStatus.AVAILABLE || assetStatus === AssetStatus.FULLY_SUBSCRIBED) {
      return InvestmentStatus.COMMITTED;
    } else if (assetStatus === AssetStatus.COMPLETED) {
      return InvestmentStatus.COMPLETED;
    };
  };

  if (investmentLoading || !investmentData || investmentFetching) {
    return (
      <LoadingAnimation />
    );
  }

  const tabs = [
    {
      index: 0,
      label: "Cash Flow",
      children: <InvestmentCashFlow data={investmentData?.getInvestment} />,
      tabIcon: <FontAwesomeIcon
        size="lg"
        icon={faSackDollar} />,
      disabled: false,
    },
    {
      index: 1,
      label: "Performance",
      children:
        <InvestmentPerformance
            asset={investmentData?.getInvestment.asset}
            investmentAmount={investmentData?.getInvestment.amount}
        />,
      tabIcon: <FontAwesomeIcon
        size="lg"
        icon={faChartBar} />,
      disabled: false,
    },
    {
      index: 3,
      label: "Statements",
      children: <InvestmentStatements />,
      tabIcon: <FontAwesomeIcon
        size="lg"
        icon={faCalendarDay} />,
      disabled: true,
    },
  ];

  const tooltips = [
    {
      definition: "IRR (Internal Rate of Return): A calculation of the profitability of an investment.  Mathematically, IRR is the discount rate that makes the net present value (NPV) of all cash flows (investment outflow and distribution inflows) equal to zero",
      description: "Targeted IRR",
      value: formatPercentage(investmentData?.getInvestment.asset.targetedIRR!),
    },
    {
      definition: "Targeted Average Cash Yield: An estimate of the annual distributions as a percentage of the original investment. Simply put it is an estimate of the return from an asset which generates income.",
      description: "Targeted Average Cash Yield",
      value: formatPercentage(investmentData?.getInvestment.asset.targetedAverageCashYield!),
    },
    {
      definition: "MoIC (Multiple on Invested Capital):  The sum of realized proceeds and unrealized value divided by the money invested. This illustrates the total return of an investment over the life of the investment.",
      description: "Targeted MoIC",
      value: `${investmentData?.getInvestment.asset.targetedMOIC}x`,
    },
    {
      definition: "Targeted Term: The amount of time remaining in the deal as measured from actual investment date to the investment maturity.",
      description: "Targeted Investment Period",
      value: isPeriodOver24Months(investmentData?.getInvestment.asset.targetedInvestmentPeriod),
    },
  ];

  return (
    <div>
      <div>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            style={{ textDecoration: "none" }}
            className="acc-lightgray"
            color="inherit"
            to="/investor/portfolio"
          >
            Portfolio
          </Link>
          <Typography className="acc-darkblue">{investmentData?.getInvestment.asset.name}</Typography>
        </Breadcrumbs>
      </div>
      <div className="row column-mobile m-top-30">
        <div className="width-80 width-100-mobile width-50-tablet m-right-50 m-right-0-mobile m-bottom-30-mobile">
          <div className="row start column-mobile column-tablet">
            <AccessCard image={investmentData?.getInvestment.asset.coverImageLocation!} />
            <div className="m-left-30 m-top-30-mobile m-left-0-mobile width-80 width-100-mobile">
              <Chip
                label={portfolioInvestmentStatus(investmentData?.getInvestment.asset.assetStatus.name)}
                size="small"
                style={styles.chip} />
              <Typography
                style={{ marginBottom: 5 }}
                className="acc-green bold">
                Investment in
              </Typography>
              <Typography
                variant="h4"
                className="acc-blue bold font-size-mobile-3"
              >
                {investmentData?.getInvestment.name}
              </Typography>
              <br />
              <Typography className="acc-darkgray light font-size-mobile-5">
                {investmentData?.getInvestment.asset.summary}
              </Typography>
              <br />
              <div className="row start stretch width-100 m-0-mobile">
                {tooltips.map(item =>
                  <CustomTooltip
                    key={item.description}
                    title={item.definition}
                    color={COLORS.white}
                    background={COLORS.darkBlueGradient}>
                    <div>
                      <LabeledFigure
                        className="acc-blue"
                        alignment="center"
                        containerStyles={{
                          maxWidth: 170,
                        }}
                        value={item.value}
                        description={item.description}
                      />
                    </div>
                  </CustomTooltip>,
                )}
              </div>
            </div>
          </div>
          <TabView
            tabs={tabs}
          />
        </div>
        <PageCard
          className="width-20 width-100-mobile width-50-tablet m-left-30 m-0-mobile m-0-tablet"
          style={{ background: COLORS.darkBlueGradient, height: "fit-content" }}
        >
          <LabeledFigure
            value={formatCurrency(investmentData?.getInvestment.amount!)}
            description="Invested"
            alignment="center"
            className="column center"
            valueStyles={{ color: COLORS.lightGreen }}
            descriptionStyles={{ color: COLORS.white, fontWeight: 600, fontSize: 16 }}
          />
          <br />
          <Divider
            variant="middle"
            style={{ width: "100%", margin: 0, backgroundColor: COLORS.lightGray }} />
          <br />
          <Typography
            variant="h6"
            className="acc-white bold">
            <FontAwesomeIcon
              icon={faWallet}
              className="acc-lightgreen m-right-10" />
            Invested from
          </Typography>
          <Link to={`/investor/accounts/accountdetails/${investmentData?.getInvestment?.wallet.walletId}`}>
            <Typography
              variant="subtitle1"
              className="acc-lightgreen light"
              style={{ textDecoration: "underline" }}
            >
              {investmentData?.getInvestment.wallet.name}
            </Typography>
          </Link>
          <br />
          <Typography
            variant="h6"
            className="acc-white bold">
            <FontAwesomeIcon
              icon={faCalendarAlt}
              className="acc-lightgreen m-right-10" />
            Date
          </Typography>
          <Typography
            variant="subtitle1"
            className="acc-lightgreen light">
            {moment(Number(investmentData?.getInvestment.committedDate)).format("MM/DD/YYYY")}
          </Typography>
          <div className="m-top-30">
            <Link to={`/investor/portfolio/statements/${match.params.id}`}>
              <Typography
                variant="subtitle2"
                className="acc-green light underlined-link">
                <FontAwesomeIcon
                  icon={faFileAlt}
                  style={{ fontSize: 20 }}
                  className="acc-lightgreen m-right-10" />
                Documents & Statements
              </Typography>
            </Link>
          </div>
          <>
            {investmentData.getInvestment.asset.assetStatus.name === AssetStatus.ACTIVE ?
              <PageCard
                className="width-100 m-top-30"
                style={{ ...styles.invested, background: COLORS.green }}>
                <LabeledFigure
                  className="acc-darkblue font-size-mobile-4"
                  alignment="center"
                  value="$1,256.54"
                  description="Distributions Paid to Date"
                  valueStyles={{ fontSize: 24 }}
                  descriptionStyles={{ fontSize: 12, color: COLORS.darkBlue }}
                />
              </PageCard> : null}
          </>
        </PageCard>
      </div>
    </div >
  );
};

const styles = {
  image: {
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    borderRadius: 16,
  },
  invested: {
    height: 75,
    borderRadius: 16,
    padding: "15px 20px",
  },
  chip: {
    backgroundColor: COLORS.ultralightGreen,
    color: COLORS.darkBlue,
    width: 120,
    marginBottom: 30,
    fontWeight: 500,
  },
};

export default withRouter(InvestmentDetails);