import { useState } from "react";
import { CountryCodes } from "../../../../common/constants";
import { FormPages } from "./umb.consts";
import { UMBYesNoValues } from "./umb.interfaces";

export const useNestedFormState = () => {
  // Determines if certain singular form page values contain subsequent followup questions/form pages depending on a conditional value
  const [nestedCurrentPages, setNestedCurrentPages] = useState<{
    [key: string]: {
      currentPageNumber: number;
      totalNumberOfPages: number;
      nestedPagesVisible: Function;
      nestedValues: any;
    }
  }>({
    [FormPages.IsUSCitizen]: {
      currentPageNumber: 1,
      totalNumberOfPages: 2,
      nestedPagesVisible: (value: string) => value === UMBYesNoValues.NO,
      nestedValues: {
        [1]: "isUSCitizen",
        [2]: "isResidentAlien",
      },
    },
    [FormPages.CountryOfBirth]: {
      currentPageNumber: 1,
      totalNumberOfPages: 2,
      nestedPagesVisible: (value: string) => value !== CountryCodes.UnitedStates,
      nestedValues: {
        [1]: "countryOfBirth",
        [2]: [
          "countryOfCitizenship",
          "yearOfUSCitizenship",
        ],
      },
    },
    [FormPages.IsOfficialGovernmentSenior]: {
      currentPageNumber: 1,
      totalNumberOfPages: 4,
      nestedPagesVisible: (value: string) => value === UMBYesNoValues.YES,
      nestedValues: {
        [1]: "isOfficialGovernmentSenior",
        [2]: "officialsRelationshipToApplicant",
        [3]: "officialsAffiliatedCountry",
        [4]: "officialsJobTitle",
      },
    },
    [FormPages.IsEmployed]: {
      currentPageNumber: 1,
      totalNumberOfPages: 2,
      nestedPagesVisible: (value: string) => value === UMBYesNoValues.YES,
      nestedValues: {
        [1]: "isEmployed",
        [2]: [
          "employmentType",
          "employersName",
          "occupation",
          "annualIncome",
        ],
      },
    },
    [FormPages.IsPrimaryAccountHolder]: {
      currentPageNumber: 1,
      totalNumberOfPages: 1,
      nestedPagesVisible: (value: string) => value === UMBYesNoValues.YES,
      nestedValues: {
        [1]: "isPrimaryAccountHolder",
        // [2]: "federalTaxClassification",
        // [3]: "isTaxNumberOnFormCorrect",
        // [4]: "isNotSubjectToBackupWithholding",
      },
    },
  });

  return { nestedCurrentPages, setNestedCurrentPages };
};