import {
  faHome, faCoins, faWallet, faThLarge, faSignOut, faChevronRight, faChevronLeft, faBars, faUser, faUniversity, faComments, faChartBar, faFileAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import BadgeUnstyled from "@mui/base/BadgeUnstyled";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
  Box, CssBaseline, Divider, styled, Theme, CSSObject, createTheme, ThemeProvider, Toolbar, List,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import React, { useContext, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import AdminLinks from "./AdminLinks";
import { AuthContext, SessionContext } from "../../../context";
import COLORS from "../../colors";
import LogoSmall from "../../../assets/images/illustrations/access_card_white_small.svg";
import Logo from "../../../assets/images/illustrations/access_logo_white.svg";
import AdvisorLogo from "../../../assets/images/illustrations/access_advisor_logo_white.svg";
import { THEME_BREAKPOINTS } from "../../constants";
import { NavPaneContext } from "../../../context";
import { IconButton, useMediaQuery } from "@mui/material";
import { MainListItemButton } from "../NavigationComponents";
import { UserTypes } from "../../types";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { useUserPortfolioInvestments, useUserWallets } from "../../../api/hooks/graphqlHooks";
import CustomTooltip from "../CustomTooltip";
import { Typography } from "@material-ui/core";
import { AuthActions } from "../../../App";
import { logOut } from "../../../api/utils";
import { isNil } from "../../utilities";

const drawerWidth = 260;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.complex,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.complex,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up(768)]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  [theme.breakpoints.down(769)]: {
    width: theme.spacing(0) + 1,
    display: "none",
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));


const overrideTheme = createTheme({
  components: {
    // Name of the component
    MuiDrawer: {
      styleOverrides: {
        // Name of the slot
        paper: {
          // Some CSS
          background: COLORS.darkBlueGradient,
        },
      },
    },
  },
});

// const StyledBadge = styled(BadgeUnstyled)`
// & .MuiBadge-dot {
//   min-width: 8px;
//   width: 8px;
//   height: 8px;
//   background: #1BD0AE;
//   border-radius: 100%;
// }
// & .MuiBadge-anchorOriginTopRightCircular {
//   position: absolute;
//   bottom: 0;
//   right: 0;
// }
// `;

const NavigationPane = () => {

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  const { open, setOpen } = useContext(NavPaneContext);
  const { user } = useContext(SessionContext);
  const userIsAdmin = user?.userType?.name === UserTypes.ADMIN || user?.userType?.name === UserTypes.ASSET_MANAGER;
  const userIsAdvisor = user?.userType?.name === UserTypes.ADVISOR;

  const {
    data: walletData,
  } = useUserWallets();

  const {
    data: getUserPortfolioInvestmentsData,
  } = useUserPortfolioInvestments({
    refetchOnMount: true,
  });

  const showPerformanceTab = getUserPortfolioInvestmentsData?.getUserPortfolioInvestments.map(item => item.investments).flat().length! > 0;
  const showWallet = walletData?.getUserWallets.map(item => item.walletId).flat().length! > 0;

  useEffect(() => {
    if (showPerformanceTab && isNil(localStorage.getItem("showPerformanceTab"))) {
      localStorage.setItem("showPerformanceTab", showPerformanceTab.toString());
    }
    if (showWallet && isNil(localStorage.getItem("showWallet"))) {
      localStorage.setItem("showWallet", showWallet.toString());
    }
  }, [walletData, getUserPortfolioInvestmentsData]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const currentPage = useLocation();
  const dispatch = useContext(AuthContext);

  // Will set menu opened on desktop, but closed default on mobile and will close the menu if navigated to new page
  useEffect(() => {
    if (open && mobileLayout) setOpen(false);
  }, [currentPage, mobileLayout]);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {mobileLayout && (
        <AppBar
          position="fixed"
          open={open}
          style={{ background: COLORS.darkBlueGradient }}>
          <Toolbar
            style={{
              display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%",
            }}
          >
            <img
              style={{ ...(open && { display: "none" }), height: 40 }}
              src={LogoSmall}
              alt="Access"
              onClick={handleDrawerOpen} />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="end"
              sx={{ ...(open && { display: "none" }) }}
            >
              <FontAwesomeIcon icon={faBars} />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <ThemeProvider theme={overrideTheme}>
        <Drawer
          variant="permanent"
          open={open}>
          <div
            className="column stretch m-top-30"
            style={{ height: "100%" }}>
            <div style={{ width: "100%" }}>
              <List sx={{ padding: 0 }}>
                <Link
                  className="column center"
                  to="/"
                  style={{ ...(!open && { opacity: 0 }), transition: "0.2s" }}>
                  <img
                    style={{ height: userIsAdvisor ? 65 : 40 }}
                    src={userIsAdvisor ? AdvisorLogo : Logo}
                    alt="Access" />
                </Link>
                <Link
                  className="column center"
                  to="/"
                  style={{ ...(open && { opacity: 0 }), position: "relative", top: -40 }}>
                  <img
                    style={{ height: 40 }}
                    src={LogoSmall}
                    alt="Access" />
                </Link>
                {userIsAdmin ? (
                  <AdminLinks open={open} />
                )
                  : (
                    <>
                      <MainListItemButton
                        link="/investor/dashboard"
                        icon={faHome}
                        title="Dashboard"
                        alternateLinks={["/"]}
                      />
                      <MainListItemButton
                        link="/investor/invest"
                        icon={faCoins}
                        title="Invest"
                      />
                      <div className="row center">
                        <MainListItemButton
                          link={"/investor/accounts"}
                          icon={faWallet}
                          title={(
                            <>
                              <span
                                style={{ color: currentPage.pathname.includes("accounts") ? COLORS.lightGreen : COLORS.navBarLogo }}
                                className="acc-text">access</span>
                              Wallet
                            </>
                          )}
                          alternateLinks={["/investor/accounts/onboard"]}
                        />
                        {walletData?.getUserWallets.length === 1 &&
                          <CustomTooltip
                            title="Add Account"
                            placement="right"
                            fontSize={11}
                            background={COLORS.greenGradient}
                            color={COLORS.darkBlue}
                          >
                            <Link to="/investor/accounts/onboard">
                              <FontAwesomeIcon
                                icon={faPlusCircle}
                                color={currentPage.pathname.includes("accounts") ? COLORS.green : COLORS.navBarLogo}
                                style={{ marginTop: 10 }} />
                            </Link>
                          </CustomTooltip>}
                      </div>
                      <MainListItemButton
                        link="/investor/wallet"
                        icon={faUniversity}
                        title="Bank Accounts"
                      />
                      <MainListItemButton
                        link="/investor/portfolio"
                        icon={faThLarge}
                        title="Portfolio"
                      />
                      {(showPerformanceTab || localStorage.getItem("showPerformanceTab") == "true") && <MainListItemButton
                        link="/investor/performance"
                        icon={faChartBar}
                        title="Performance"
                      />}
                      {(showWallet || localStorage.getItem("showWallet") == "true") && <MainListItemButton
                        link="/investor/documents"
                        icon={faFileAlt}
                        title="Documents"
                      />}
                    </>
                  )}
              </List>
            </div>
            <div
              className="width-100 center column"
              style={{ flex: 1, justifyContent: "flex-end" }}>
              <div className="row center m-bottom-15">
                {open && <Typography
                  variant="caption"
                  className="acc-green acc-text" >access</Typography>}
                <Typography
                  variant="caption"
                  className={open ? "acc-white" : "acc-lightgray"}>&nbsp;{open && "Version "}{process.env.REACT_APP_VERSION}</Typography>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <Divider sx={{ background: COLORS.navBarLogo }} />
              <div
                className="column stretch width-100"
                style={{ overflowY: "hidden" }}>
                {mobileLayout &&
                  <div className="width-100">
                    <List>
                      <MainListItemButton
                        link={userIsAdmin ? "/admin/profile" : "/investor/profile"}
                        icon={faUser}
                        title="Profile"
                      />
                      <MainListItemButton
                        link="/investor/help"
                        icon={faComments}
                        title="Help"
                      />
                      <MainListItemButton
                        link="/signout"
                        icon={faSignOut}
                        title="Sign Out"
                        onClick={async () => {
                          await logOut();
                          dispatch({
                            type: AuthActions.SIGN_OUT,
                          });
                        }}
                      />
                    </List>
                  </div>
                }
                <div
                  className="width-100"
                  style={{ height: "100%", background: COLORS.darkBlueGradient, overflowX: "hidden" }}>
                  <Toolbar>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      sx={{
                        marginRight: "36px",
                        marginLeft: "0px",
                        paddingTop: "10px",
                        ...(open && { opacity: 0, transition: "0.5s" }),
                      }}
                    >
                      <FontAwesomeIcon
                        color={COLORS.navBarLogo}
                        icon={faChevronRight} />
                    </IconButton>
                    <IconButton
                      onClick={handleDrawerClose}
                      sx={{
                        marginLeft: "25px",
                        paddingTop: "10px",
                        ...(!open && { opacity: 0, transition: "0.5s" }),
                      }}
                    >
                      <FontAwesomeIcon
                        color={COLORS.navBarLogo}
                        icon={faChevronLeft} />
                    </IconButton>
                  </Toolbar>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </ThemeProvider>
    </Box>
  );
};


export default NavigationPane;
