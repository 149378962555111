import { useMutation } from "@apollo/client";
import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik, Form, Field, FormikValues } from "formik";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import { DELETE_FUNDING_REQUEST, UPDATE_FUNDING_REQUEST, CREATE_FUNDING_REQUEST } from "../../../api/query/FundingRequests";
import { isNil } from "../../../common/utilities";
import { EditModeTypes } from "../../../enums";
import CancelConfirmDialogModal from "../../../common/components/CancelConfirmDialogModal";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import COLORS from "../../../common/colors";
import { useFundingRequest, QueryKeys, useWallets, useFundingRequestTypes } from "../../../api/hooks/graphqlHooks";
import { useQueryClient } from "react-query";
import AccInput from "../../../common/components/AccInput";
import { FundingRequestStatus } from "../../../common/types";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";
import PageWrapper from "../../../common/components/PageWrapper";

type FundingRequestDetailDetailParams = {
  id?: string;
};

type FundingRequestDetailDetailProps = RouteComponentProps<FundingRequestDetailDetailParams>;

const FundingRequestForm: React.FC<FundingRequestDetailDetailProps> = withErrorBoundary({}, ({ match, history }: FundingRequestDetailDetailProps) => {

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  const queryClient = useQueryClient();
  const editMode: EditModeTypes = isNil(match.params.id) ? EditModeTypes.ADD : EditModeTypes.EDIT;
  if (editMode === EditModeTypes.ADD) {
    queryClient.removeQueries(QueryKeys.FUNDING_REQUEST);
  }

  const [selectedWallet, setSelectedWallet] = useState<string | null>(null);
  const [isFundingRequestSubmitting, setIsFundingRequestSubmitting] = useState<boolean>(false);

  const {
    data: getFundingRequestData,
    isLoading: getFundingRequestLoading,
    isFetching: getFundingRequestFetching,
    isError: getFundingRequestError,
  } = useFundingRequest(Number(match.params.id), {
    enabled: editMode === EditModeTypes.EDIT,
    refetchOnMount: true,
  });

  const {
    data: getFundingRequestTypesData,
    isLoading: getFundingRequestTypesLoading,
    isFetching: getFundingRequestTypesFetching,
    isError: getFundingRequestTypesError,
  } = useFundingRequestTypes();

  const {
    data: getWalletsData,
    isLoading: getWalletsLoading,
    isFetching: getWalletsFetching,
    isError: getWalletsError,
  } = useWallets();

  const matchedWallet = getWalletsData?.getWallets.find((item) => item.id === selectedWallet);

  const [createFundingRequest] = useMutation(CREATE_FUNDING_REQUEST);
  const [deleteFundingRequest] = useMutation(DELETE_FUNDING_REQUEST);
  const [updateFundingRequest] = useMutation(UPDATE_FUNDING_REQUEST);

  const [cancelDialogVisible, setCancelDialogVisibible] = useState(false);

  const openCancelDialog = () => setCancelDialogVisibible(true);
  const closeCancelDialog = () => setCancelDialogVisibible(false);


  const BLANK_FORM_VALUES = {
    amount: "",
    status: "",
    walletObjectId: "",
    walletName: "",
    walletId: "",
    externalAccountObjectId: "",
    externalAccountName: "",
    externalAccountId: "",
    fundingRequestTypeObjectId: "",
    fundingRequestTypeName: "",
    fundingRequestTypeId: "",
  };

  const initialFormFieldValues = () => (getFundingRequestData?.getFundingRequest ? {
    amount: getFundingRequestData?.getFundingRequest.amount,
    status: getFundingRequestData?.getFundingRequest.status,
    walletObjectId: getFundingRequestData?.getFundingRequest.wallet ? getFundingRequestData?.getFundingRequest.wallet.id : "Wallet does not exist, assign new Wallet",
    walletName: getFundingRequestData?.getFundingRequest?.wallet ? getFundingRequestData?.getFundingRequest?.wallet?.name : <span style={{ color: "red" }}>Wallet does not exist, assign new Wallet</span>,
    walletId: getFundingRequestData?.getFundingRequest.wallet ? getFundingRequestData?.getFundingRequest.wallet.walletId : "Wallet does not exist, assign new Wallet",
    externalAccountObjectId: getFundingRequestData?.getFundingRequest.externalAccount ? getFundingRequestData?.getFundingRequest.externalAccount.id : "External Account does not exist, assign new External Account",
    externalAccountName: getFundingRequestData?.getFundingRequest.externalAccount ? (`${getFundingRequestData?.getFundingRequest.externalAccount.bankName}`) : <span style={{ color: "red" }}>External Account does not exist, assign new External Account</span>,
    externalAccountId: getFundingRequestData?.getFundingRequest.externalAccount ? getFundingRequestData?.getFundingRequest.externalAccount.id : "External Account does not exist, assign new External Account",
    fundingRequestTypeObjectId: getFundingRequestData?.getFundingRequest.fundingRequestType ? getFundingRequestData?.getFundingRequest.fundingRequestType.id : "Funding request type does not exist, assign new Funding request type",
    fundingRequestTypeName: getFundingRequestData?.getFundingRequest.fundingRequestType ? getFundingRequestData?.getFundingRequest.fundingRequestType.name : "Funding request type does not exist, assign new Funding request type",
    fundingRequestTypeId: getFundingRequestData?.getFundingRequest.fundingRequestType ? getFundingRequestData?.getFundingRequest.fundingRequestType.id : "Funding request type does not exist, assign new Funding request type",
  } : BLANK_FORM_VALUES);

  const handleDelete = async (objectId: string | number) => {
    try {
      await deleteFundingRequest({
        variables: {
          id: objectId,
        },
      });
      toast("Funding request deleted successfully!", {
        type: toast.TYPE.SUCCESS,
      });
    } catch (err: any) {
      toast(err.message || err.msg || err.toString() || "There was an error deleting funding request.", {
        type: toast.TYPE.WARNING,
      });
    }
    queryClient.refetchQueries(QueryKeys.FUNDING_REQUESTS);
    history.push("/admin/fundingrequests");
  };

  const submitForm = async (values: FormikValues, { resetForm }: any) => {
    setIsFundingRequestSubmitting(true);
    if (editMode === EditModeTypes.ADD) {
      try {
        await createFundingRequest({
          variables: {
            input: {
              amount: values.amount,
              status: values.status,
              walletObjectId: values.walletObjectId,
              externalAccountObjectId: values.externalAccountObjectId,
              fundingRequestTypeName: values.fundingRequestTypeName,
            },
          },
        });
        toast("Funding request added successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        setIsFundingRequestSubmitting(false);
        queryClient.refetchQueries(QueryKeys.FUNDING_REQUESTS);
        history.push("/admin/fundingrequests");
        resetForm();
      } catch (err: any) {
        setIsFundingRequestSubmitting(false);
        toast(err.message || err.msg || err.toString() || "There was an error adding funding request.", {
          type: toast.TYPE.ERROR,
        });
      }
    } else if (editMode === EditModeTypes.EDIT) {
      try {
        await updateFundingRequest({
          variables: {
            id: getFundingRequestData?.getFundingRequest.id,
            input: {
              amount: values.amount,
              status: values.status,
              walletObjectId: values.walletObjectId,
              externalAccountObjectId: values.externalAccountObjectId,
              fundingRequestTypeName: values.fundingRequestTypeName,
            },
          },
        });
        toast("Funding request updated successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        setIsFundingRequestSubmitting(false);
        queryClient.refetchQueries(QueryKeys.FUNDING_REQUESTS);
        history.push("/admin/fundingrequests");
      } catch (err: any) {
        setIsFundingRequestSubmitting(false);
        toast(err.message || err.msg || err.toString() || "There was an error updating funding request.", {
          type: toast.TYPE.ERROR,
        });
      }
    }
  };

  const pageLoading =
    getFundingRequestLoading ||
    getWalletsLoading ||
    getFundingRequestTypesLoading ||
    getFundingRequestFetching ||
    getWalletsFetching ||
    getFundingRequestTypesFetching ||
    isFundingRequestSubmitting;

  return (
    <PageWrapper
      title={`${editMode === EditModeTypes.ADD ? "Add" : "Update"} Funding request`}
      showLoader={pageLoading}
      showError={getFundingRequestError || getFundingRequestTypesError || getWalletsError}
      errorMessage="Cannot retrieve funding requests"
    >
      <br />
      <div>
        <Formik
          initialValues={initialFormFieldValues()}
          enableReinitialize
          onSubmit={submitForm}
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form>
              <div className="column-reverse-mobile">
                <div className="row stretch center width-100 column-mobile">
                  <Link
                    to="/admin/fundingrequests"
                    className="width-100-mobile">
                    <AccButton
                      color={AccButtonColors.WHITE}
                      label="Cancel"
                      style={{ backgroundColor: COLORS.white }}
                    />
                  </Link>
                  <div className="row width-100-mobile column-mobile">
                    <AccButton
                      color={AccButtonColors.GREEN}
                      type="submit"
                      label={editMode === EditModeTypes.ADD ? "Submit" : "Update"}
                      className="m-top-15-mobile m-bottom-15-mobile"
                    />
                    {editMode === EditModeTypes.EDIT
                      ? (
                        <>
                          <AccButton
                            color={AccButtonColors.RED_OUTLINE}
                            label="Delete"
                            className="delete-button m-left-15 display-none-mobile"
                            type="button"
                            onClick={openCancelDialog}
                          />
                          {mobileLayout
                            && (
                              <AccButton
                                color={AccButtonColors.RED_OUTLINE}
                                label="Delete Funding request"
                                className="delete-button"
                                type="button"
                                width="40%"
                                style={styles.mobileDeleteButton}
                                onClick={openCancelDialog}
                              />
                            )}
                        </>
                      )
                      : null}
                    {getFundingRequestData ? <CancelConfirmDialogModal
                      isVisible={cancelDialogVisible}
                      dialogBody="Are you sure you would like to delete this Funding request?"
                      onClickCancel={closeCancelDialog}
                      onClickDelete={() => handleDelete(getFundingRequestData?.getFundingRequest.id)}
                    /> : null}
                  </div>
                </div>
                <br />
                <div
                  style={styles.border}
                  className="width-60 width-100-mobile">
                  <Field
                    name="amount"
                    type="text"
                    label="Funding request amount"
                    as={AccInput}
                    required
                    currencyMask
                  />
                  <AccInput
                    name="status"
                    value={values.status}
                    label="Funding request status"
                    select
                    onChange={handleChange}
                    required
                  >
                    <MenuItem
                      selected
                      value={values.status}>
                      {values.status ? values.status : <span style={{ color: "red" }}>Funding request status does not exist, assign new status</span>}
                    </MenuItem>
                    {FundingRequestStatus
                      ?.filter(
                        (item) => item.key !== values.status,
                      )
                      .map((item) => (
                        <MenuItem
                          key={item.key}
                          value={item.value}
                        >
                          {item.value}
                        </MenuItem>
                      ))}
                  </AccInput>
                  <Field
                    as={AccInput}
                    name="walletObjectId"
                    value={values.walletObjectId}
                    label="Wallet"
                    select
                    required
                    onChange={(e: any) => {
                      setFieldValue("walletObjectId", e.target.value);
                      setSelectedWallet(e.target.value);
                    }}
                  >
                    <MenuItem
                      selected
                      value={values.walletId}>
                      {values.walletName ? values.walletName : <span style={{ color: "red" }}>Wallet does not exist, assign new Wallet</span>}
                    </MenuItem>
                    {getWalletsData?.getWallets
                      ?.filter(
                        (item) => item.id !== values.walletId,
                      )
                      .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name))
                      .map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Field>
                  <AccInput
                    name="externalAccountObjectId"
                    value={values.externalAccountObjectId}
                    label="External Account"
                    select
                    onChange={handleChange}
                    required
                  >
                    <MenuItem
                      selected
                      value={values.externalAccountId}
                    >
                      {values.externalAccountName ? values.externalAccountName : <span style={{ color: "red" }}>External Account does not exist, assign new External Account</span>}
                    </MenuItem>
                    {matchedWallet?.externalAccounts
                      ?.filter(
                        (item) => item.id !== values.externalAccountId,
                      )
                      .sort((itemA, itemB) => itemA.displayName.localeCompare(itemB.displayName))
                      .map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}>
                          {`${item.bankName} / ${item.displayName} / ${item.lastFour}`}
                        </MenuItem>
                      ))}
                  </AccInput>
                  <AccInput
                    name="fundingRequestTypeName"
                    value={values.fundingRequestTypeName}
                    label="Funding request type"
                    select
                    onChange={handleChange}
                    required
                  >
                    <MenuItem
                      selected
                      value={values.fundingRequestTypeName}>
                      {values.fundingRequestTypeName ? values.fundingRequestTypeName : <span style={{ color: "red" }}>External Account does not exist, assign new External Account</span>}
                    </MenuItem>
                    {getFundingRequestTypesData?.getFundingRequestTypes
                      ?.filter(
                        (item) => item.name !== values.fundingRequestTypeName,
                      )
                      .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name))
                      .map((item) => (
                        <MenuItem
                          key={item.name}
                          value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </AccInput>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </PageWrapper >
  );
});

const styles = {
  border: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    padding: "15px",
  },
  mobileDeleteButton: {
    alignSelf: "flex-start",
    border: "none",
    textAlign: "left",
    padding: 0,
    height: 20,
  },
};

export default withRouter(FundingRequestForm);