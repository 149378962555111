import React from "react";
import { Typography, useMediaQuery } from "@material-ui/core";
import CashFlowSimulationBar from "../../../../../common/components/CashFlowSimulationBar";
import { Asset } from "../../../../../common/types";
import { useCashFlow } from "../../../../../api/hooks/graphqlHooks";
import { isNotNil } from "../../../../../common/utilities";
import COLORS from "../../../../../common/colors";
import { THEME_BREAKPOINTS } from "../../../../../common/constants";
import LoadingAnimation from "../../../../../common/components/LoadingAnimation";

interface Props {
    asset: Asset,
    investmentAmount: Number,
}

const InvestmentPerformance: React.FC<Props> = ({ asset, investmentAmount }) => {
    const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

    const {
        data: getCashFlowData,
        isLoading: getCashFlowLoading,
        isFetching: getCashFlowFetching,
    } = useCashFlow(Number(asset.assetId), {
        enabled: isNotNil(asset.assetId),
        refetchOnMount: true,
    });

  if (getCashFlowFetching || getCashFlowLoading) {
    return (
      <LoadingAnimation />
    );
  }
    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
            }}
        >
            <div
                className="m-top-50"
                style={{
                    ...styles.innerContainer,
                    padding: mobileLayout ? "50px 25px" : "15px 50px",
                    width: "65%",
                    margin: "0",
                    flexDirection: "column",
                }}>
                <Typography
                    className="bold acc-white font-size-mobile-3 width-100-mobile"
                    variant="h5">Performance for <span className="bold acc-green">{asset?.name}</span>
                </Typography>
                <CashFlowSimulationBar
                    targetedFundingDate={asset?.targetedFundingDate.substring(0, 7) || ""}
                    cashFlowModelRows={getCashFlowData?.getCashFlow?.cashFlowInputArray || []}
                    totalInvestmentAmount={asset?.totalInvestmentAmount}
                    investmentAmount={investmentAmount}
                    displayTargetedCashFlow={false}
                    isPerformanceCashFlow={false}
                />
            </div>
        </div>
    );
};

const styles = {
  innerContainer: {
    borderRadius: "16px",
    height: "fit-content",
    alignItems: "center",
    background: COLORS.layeredImageGradient,
    display: "flex",
    justifyContent: "center",
  },
};

export default InvestmentPerformance;