import gql from "graphql-tag";
import { makeGraphqlRequest } from "../../utils";
import { gql as gqlv2 } from "graphql-request";

export interface UserInput {
  password: string,
  email: string,
  firstName: string,
  lastName: string,
  middleName: string,
  phone: number,
  accountId: string,
}

export const CREATE_USER = gql`
  mutation CreateUser($input: UserInput!) {
  createUser(input: $input) {
    user {
      id
      userId
      firstName
      middleName
      lastName
      email
      linkedInUrl
      jobTitle
      phone
      userType {
        userTypeId
        name
      }
      account {
        id
        accountId
        name
      }
    }
    token
  }
}
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($id: String!, $input: UserInput) {
    updateUser(_id: $id, input: $input) {
      id
      firstName
      middleName
      lastName
      email
      linkedInUrl
      jobTitle
      phone
      userType {
        userTypeId
        name
      }
      account {
        accountId
        name
      }
    }
  }
`;


const GET_USER_V2 = gqlv2`
  query GetUser ($userId: Int!) {
    getUser (userId: $userId) {
      id
      firstName
      middleName
      lastName
      email
      userId
      linkedInUrl
      jobTitle
      avatarImageLocation
      status
      phone
      CRDNumber
      firmCRDNumber
      accountObjectId
      userType {
        id
        userTypeId
        name
      }
      account {
        id
        accountId
        name
      }
    }
  }
`;

export const getUser = (userId: number) => makeGraphqlRequest(GET_USER_V2, { userId });

export const GET_USERS_V2 = gqlv2`
  query GetUsers {
    getUsers {
      id
      firstName
      middleName
      lastName
      email
      status
      userId
      linkedInUrl
      jobTitle
      phone
      CRDNumber
      firmCRDNumber
      accountObjectId
      userType {
        userTypeId
        name
      }
      account {
        accountId
        name
        id
      }
    }
  }
`;

export const getUsers = () => makeGraphqlRequest(GET_USERS_V2);