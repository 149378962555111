import React, { useRef } from "react";
import COLORS from "../../colors";
import { KeyboardKeys, useKeyboardPress } from "../../hooks";

interface Props {
  label?: string | JSX.Element;
  color: AccButtonColors;
  width?: number | string;
  height?: number | string;
  onClick?: any;
  onChange?: any;
  disabled?: boolean;
  style?: {};
  className?: string | JSX.Element;
  type?: string;
  id?: string;
  submitOnEnter?: boolean;
  accept?: string;
  name?: string;
  formEncType?: string;
}

interface ColorVariant {
  background?: string;
  className?: string;
  color?: string;
  border?: string;
}

export enum AccButtonColors {
  GREEN = "green",
  BLUE = "blue",
  WHITE = "white",
  GRAY = "gray",
  RED_OUTLINE = "red",
  GREEN_OUTLINE = "transparent"
}

const AccButton: React.FC<Props> = ({ submitOnEnter, ...props }) => {
  const colorVariant: ColorVariant = (() => {
    switch (props.color) {
      case AccButtonColors.GREEN: {
        return {
          background: COLORS.greenGradient,
          className: "green-button",
          color: COLORS.darkBlue,
          border: "none",
        };
      }
      case AccButtonColors.BLUE: {
        return {
          background: COLORS.darkBlue,
          className: "blue-button",
          color: COLORS.white,
          border: "none",
        };
      }
      case AccButtonColors.WHITE: {
        return {
          background: COLORS.white,
          className: "white-button",
          color: COLORS.darkBlue,
          border: "1px solid #1bd0ae",
        };
      }
      case AccButtonColors.GRAY: {
        return {
          background: "rgb(202, 202, 202)",
          className: "gray-button",
          color: COLORS.darkGray,
          border: COLORS.darkGray,
        };
      }
      case AccButtonColors.RED_OUTLINE: {
        return {
          border: "1px solid #ff8585",
          color: COLORS.red,
          className: "delete-button",
        };
      }
      case AccButtonColors.GREEN_OUTLINE: {
        return {
          border: "1px solid #1BD0AE",
          color: COLORS.green,
          background: "transparent",
        };
      }
      default: {
        return {
          background: COLORS.greenGradient,
          className: "green-button",
          color: COLORS.darkBlue,
          border: "none",
        };
      }
    }
  })();

  const buttonRef = useRef<any>(null);

  useKeyboardPress({
    key: KeyboardKeys.ENTER,
    onKeyDown: () => buttonRef?.current?.click(),
  });

  return (
    // @ts-ignore
    <button
      {...props}
      disabled={props.disabled}
      className={`${colorVariant.className} ${props.className}`}
      style={{
        textTransform: "none",
        color: colorVariant.color,
        border: colorVariant.border,
        backgroundColor: colorVariant.background,
        fontWeight: 600,
        borderRadius: 16,
        opacity: 1,
        width: props.width || "100%",
        height: props.height || 45,
        padding: "0px 35px 0px 35px",
        cursor: "pointer",
        ...props.style,
      }}
      ref={submitOnEnter ? buttonRef : null}
    >
      {props.label}
    </button>
  );
};

export default AccButton;
