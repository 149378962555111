import { Typography } from "@material-ui/core";
import React, { CSSProperties } from "react";
import COLORS from "../../colors";

interface Props {
  style?: CSSProperties
}

const AccCopyright: React.FC<Props> = ({ style }) => (
  <Typography
    style={{ color: COLORS.white, margin: "10px auto", width: 320, ...style }}>
    © {new Date().getFullYear()}&nbsp;
    <span style={{ color: COLORS.green }}>Access Alternative Investments, Inc.</span>
  </Typography>
);

export default AccCopyright;
