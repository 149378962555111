import { makeGraphqlRequest } from "../../utils";
import gql from "graphql-tag";
import { gql as gqlv2 } from "graphql-request";
import { AccreditedInvestorRequest } from "../../../common/types";

export const getAccreditedInvestorRequest = (walletObjectId: String) =>
  makeGraphqlRequest(GET_ACCREDITED_INVESTOR_REQUEST_V2, { walletObjectId });

export const UPDATE_ACCREDITED_INVESTOR_REQUEST = gql`
mutation Mutation($id: String!, $input: AccreditedInvestorRequestInput) {
  updateAccreditedInvestorRequest(_id: $id, input: $input) {
    accountObjectId
    accreditationType
    airequestId
    createdAt
    northCapitalAccountId
    northCapitalLinkIds
    notes
    status
    walletObjectId
    updatedAt
  }
}
`;

export const updateAccreditedInvestorRequest =
  (accreditedInvestorRequestPayload: AccreditedInvestorRequest) =>
    makeGraphqlRequest(UPDATE_ACCREDITED_INVESTOR_REQUEST, accreditedInvestorRequestPayload);


const GET_ACCREDITED_INVESTOR_REQUEST_V2 = gqlv2`
query GetAccreditedInvestorRequest($walletObjectId: String!) {
  getAccreditedInvestorRequest(walletObjectId: $walletObjectId) {
    id
    createdAt
    updatedAt
    accreditationType
    status
    airequestId
    northCapitalAccountId
    northCapitalLinkIds
    walletObjectId
    notes
  }
}
`;

export const getAccreditedInvestorRequests = (accountObjectId: String) =>
  makeGraphqlRequest(GET_ACCREDITED_INVESTOR_REQUESTS_V2, { accountObjectId });

const GET_ACCREDITED_INVESTOR_REQUESTS_V2 = gqlv2`
query GetAccreditedInvestorRequests($accountObjectId: String!) {
  getAccreditedInvestorRequests(accountObjectId: $accountObjectId) {
    createdAt
    updatedAt
    status
    airequestId
    northCapitalAccountId
    northCapitalLinkIds
    walletObjectId
  }
}
`;