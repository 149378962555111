import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  border: {
    borderBottom: "2px solid lightgray",
    width: "100%",
  },
  content: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 500,
    fontSize: 16,
    color: "lightgray",
  },
}));

interface DividerProps {
  children: string;
}

const DividerWithText = function ({ children }: DividerProps) {
  const classes = useStyles();
  return (
    <div
      style={{ width: "100%" }}
      className={classes.container}>
      <div className={classes.border} />
      <span
        style={{ whiteSpace: "nowrap" }}
        className={classes.content}>{children}</span>
      <div className={classes.border} />
    </div>
  );
};
export default DividerWithText;
