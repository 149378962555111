import { Account, WalletType, WalletStatus, Asset, AssetClass, AssetPartner, AssetStatus, AssetType, AssetItems, Investment, User, UserType, Vertical, Wallet, UserPortfolioInvestment, UserAccountTotal, ExternalAccount, WalletActivity, Address, AddressType, AccreditedInvestorRequest, DocumentSignature, FundingRequestDetail, FundingRequestType, DealType, PendingOnboardApplication, CommercialEntityType, CashFlow, AccreditationStatus } from "../../common/types";
import { useMutation, UseMutationResult, useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { getAccount, getAccounts } from "../query/Accounts";
import { getWalletType, getWalletTypes } from "../query/WalletTypes";
import { getWalletStatus, getWalletStatuses } from "../query/WalletsStatuses";
import { getUserType, getUserTypes } from "../query/UserTypes";
import { getAssetClass, getAssetClasses } from "../query/AssetClasses";
import { getAssetPartner, getAssetPartners } from "../query/AssetPartners";
import { getAsset, getAssets } from "../query/Assets";
import { getAssetStatus, getAssetStatuses } from "../query/AssetStatuses";
import { getAssetType, getAssetTypes } from "../query/AssetTypes";
import { getAssetItem, getAssetItems } from "../query/AssetItems";
import { createInvestment, CreateInvestmentInput, getInvestment, getInvestments, getUserPortfolioInvestments, updateInvestment, UpdateInvestmentInput } from "../query/Investments";
import { getUser, getUsers } from "../query/Users";
import { getVertical, getVerticals } from "../query/Verticals";
import { getUserWallets, getWallet, getWalletFundings, getWallets, updateWallet, UpdateWalletInput, getUserAccountTotal } from "../query/Wallets";
import { getExternalAccounts } from "../query/ExternalAccounts";
import { getDocumentSignatures } from "../query/DocumentSignatures";
import { getAccreditedInvestorRequest, getAccreditedInvestorRequests } from "../query/AccreditedInvestorRequests";
import { createAddress, CreateAddressInput, getAccountAddresses, getAddress, getAddresses, updateAddress, UpdateAddressInput } from "../query/Addresses";
import { getAddressTypes } from "../query/AddressTypes";
import { getFundingRequest, CreateFundingRequestInput, createFundingRequest, UpdateFundingRequestInput, updateFundingRequest, getFundingRequests } from "../query/FundingRequests";
import { getFundingRequestType, getFundingRequestTypes } from "../query/FundingRequestTypes";
import { getDealType, getDealTypes } from "../query/DealTypes";
import { getPendingAccountOnboardApplications } from "../query/PendingOnboardApplications";
import { getCommercialEntityTypes } from "../query/CommercialEntityTypes";
import { getCashFlow, getCashFlows } from "../query/CashFlows";
import { getAccreditationStatus, getAccreditationStatuses } from "../query/AccreditationStatuses";

export enum QueryKeys {
  ACCOUNT = "account",
  ACCOUNTS = "accounts",
  ASSET = "asset",
  ASSETS = "assets",
  ASSET_CLASS = "assetClass",
  ASSET_CLASSES = "assetClasses",
  ASSET_ITEM = "assetItem",
  ASSET_ITEMS = "assetItems",
  ASSET_PARTNER = "assetPartner",
  ASSET_PARTNERS = "assetPartners",
  ASSET_STATUS = "assetStatus",
  ASSET_STATUSES = "assetStatuses",
  ASSET_TYPE = "assetType",
  ASSET_TYPES = "assetTypes",
  DEAL_TYPE = "dealType",
  DEAL_TYPES = "dealTypes",
  EXTERNAL_ACCOUNTS = "externalAccounts",
  CREATE_INVESTMENT = "createInvestment",
  INVESTMENT = "investment",
  INVESTMENTS = "investments",
  CREATE_FUNDING_REQUEST = "createFundingRequest",
  UPDATE_FUNDING_REQUEST = "updateFundingRequest",
  FUNDING_REQUEST = "fundingRequest",
  FUNDING_REQUESTS = "fundingRequests",
  FUNDING_REQUEST_TYPE = "fundingRequestType",
  FUNDING_REQUEST_TYPES = "fundingRequestTypes",
  USER = "user",
  USER_FOR_ADMIN = "userForAdmin",
  USERS = "users",
  USER_TYPE = "userType",
  USER_TYPES = "userTypes",
  VERTICAL = "vertical",
  VERTICALS = "verticals",
  DOCUMENT_SIGNATURES = "documentSignatures",
  WALLET = "wallet",
  WALLETS = "wallets",
  UPDATE_WALLET = "updateWallet",
  WALLET_TYPE = "walletType",
  WALLET_TYPES = "walletTypes",
  WALLET_STATUS = "walletStatus",
  WALLET_STATUSES = "walletStatuses",
  ADDRESSES = "addresses",
  CREATE_ADDRESS = "createAddress",
  UPDATE_ADDRESS = "updateAddress",
  ADDRESS_TYPES = "addressTypes",
  CASH_FLOW = "cashFlow",
  CASH_FLOWS = "cashFlows",
  // User data query keys
  USER_PORTFOLIO_INVESTMENTS = "userPortfolioInvestments",
  USER_WALLETS = "userWallets",
  WALLET_EXTERNAL_ACCOUNTS = "walletExternalAccounts",
  WALLET_FUNDINGS = "walletFundings",
  ACCOUNT_ADDRESSES = "accountAddresses",
  ADDRESS = "address",
  USER_ACCOUNT_TOTAL = "userAccountTotal",
  ACCREDITED_INVESTOR_REQUEST = "accreditedInvestorRequest",
  ACCREDITED_INVESTOR_REQUESTS = "accreditedInvestorRequests",
  PENDING_ACCOUNT_ONBOARD_APPLICATIONS = "pendingAccountOnboardApplications",
  COMMERCIAL_ENTITY_TYPES = "commercialEntityTypes",
  ACCREDITATION_STATUS = "getAccreditationStatuses",
  ACCREDITATION_STATUSES = "getAccreditationStatuses",
};

// Define default config options here for all queries
const applyConfiguration = (config: UseQueryOptions<any> | undefined): Omit<UseQueryOptions<any>, "queryKey" | "queryFn"> => ({
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  retry: (retryCount, err: any) => {
    if (err?.response?.errors?.length > 0) {
      return false;
    }
    else return retryCount < 2;
  },
  ...(config),
});

// ACCOUNT/S
export const useAccount = <T extends any = Account>(
  accountId: number,
  config?: UseQueryOptions<T, unknown, Account>,
): UseQueryResult<{ getAccount: Account }> =>
  useQuery(QueryKeys.ACCOUNT, () => getAccount(accountId), applyConfiguration(config));

export const useAccounts = <T extends any = Account[]>(
  config?: UseQueryOptions<T, unknown, Account[]>,
): UseQueryResult<{ getAccounts: Account[] }> =>
  useQuery(QueryKeys.ACCOUNTS, getAccounts, applyConfiguration(config));

// WALLET TYPE/S
export const useWalletType = <T extends any = WalletType>(
  walletTypeId: number,
  config?: UseQueryOptions<T, unknown, WalletType>,
): UseQueryResult<{ getWalletType: WalletType }> =>
  useQuery(QueryKeys.WALLET_TYPE, () => getWalletType(walletTypeId), applyConfiguration(config));

export const useWalletTypes = <T extends any = WalletType[]>(
  config?: UseQueryOptions<T, unknown, WalletType[]>,
): UseQueryResult<{ getWalletTypes: WalletType[] }> =>
  useQuery(QueryKeys.WALLET_TYPES, getWalletTypes, applyConfiguration(config));

// WALLET STATUS/ES
export const useWalletStatus = <T extends any = WalletStatus>(
  walletStatusId: number,
  config?: UseQueryOptions<T, unknown, WalletStatus>,
): UseQueryResult<{ getWalletStatus: WalletStatus }> =>
  useQuery(QueryKeys.WALLET_STATUS, () => getWalletStatus(walletStatusId), applyConfiguration(config));

export const useWalletStatuses = <T extends any = WalletStatus[]>(
  config?: UseQueryOptions<T, unknown, WalletStatus[]>,
): UseQueryResult<{ getWalletStatuses: WalletStatus[] }> =>
  useQuery(QueryKeys.WALLET_STATUSES, getWalletStatuses, applyConfiguration(config));

// USER TYPE/S
export const useUserType = <T extends any = UserType>(
  userTypeId: number,
  config?: UseQueryOptions<T, unknown, UserType>,
): UseQueryResult<{ getUserType: UserType }> =>
  useQuery(QueryKeys.USER_TYPE, () => getUserType(userTypeId), applyConfiguration(config));

export const useUserTypes = <T extends any = UserType[]>(
  config?: UseQueryOptions<T, unknown, UserType[]>,
): UseQueryResult<{ getUserTypes: UserType[] }> =>
  useQuery(QueryKeys.USER_TYPES, getUserTypes, applyConfiguration(config));

// ASSET
export const useAsset = <T extends any = Asset>(
  assetId: number,
  config?: UseQueryOptions<T, unknown, Asset>,
): UseQueryResult<{ getAsset: Asset }> =>
  useQuery(QueryKeys.ASSET, () => getAsset(assetId), applyConfiguration(config));

export const useAssets = <T extends any = Asset[]>(
  config?: UseQueryOptions<T, unknown, Asset[]>,
): UseQueryResult<{ getAssets: Asset[] }> =>
  useQuery(QueryKeys.ASSETS, getAssets, applyConfiguration(config));

// ASSET CLASS/ES
export const useAssetClass = <T extends any = AssetClass>(
  assetClassId: number,
  config?: UseQueryOptions<T, unknown, AssetClass>,
): UseQueryResult<{ getAssetClass: AssetClass }> =>
  useQuery(QueryKeys.ASSET_CLASS, () => getAssetClass(assetClassId), applyConfiguration(config));

export const useAssetClasses = <T extends any = AssetClass[]>(
  config?: UseQueryOptions<T, unknown, AssetClass[]>,
): UseQueryResult<{ getAssetClasses: AssetClass[] }> =>
  useQuery(QueryKeys.ASSET_CLASSES, getAssetClasses, applyConfiguration(config));

// ASSET PARTNERS
export const useAssetPartner = <T extends any = AssetPartner>(
  assetPartnerId: number,
  config?: UseQueryOptions<T,
    unknown, AssetPartner>,
): UseQueryResult<{ getAssetPartner: AssetPartner }> =>
  useQuery(QueryKeys.ASSET_PARTNER, () => getAssetPartner(assetPartnerId), applyConfiguration(config));

export const useAssetPartners = <T extends any = AssetPartner[]>(
  config?: UseQueryOptions<T,
    unknown, AssetPartner[]>,
): UseQueryResult<{ getAssetPartners: AssetPartner[] }> =>
  useQuery(QueryKeys.ASSET_PARTNERS, getAssetPartners, applyConfiguration(config));

// ASSET STATUS
export const useAssetStatus = <T extends any = AssetStatus>(
  assetStatusId: number,
  config?: UseQueryOptions<T,
    unknown, AssetStatus>,
): UseQueryResult<{ getAssetStatus: AssetStatus }> =>
  useQuery(QueryKeys.ASSET_STATUS, () => getAssetStatus(assetStatusId), applyConfiguration(config));

export const useAssetStatuses = <T extends any = AssetStatus[]>(
  config?: UseQueryOptions<T,
    unknown, AssetStatus[]>,
): UseQueryResult<{ getAssetStatuses: AssetStatus[] }> =>
  useQuery(QueryKeys.ASSET_STATUSES, getAssetStatuses, applyConfiguration(config));

// ASSET TYPES
export const useAssetType = <T extends any = AssetType>(
  assetTypeId: number,
  config?: UseQueryOptions<T,
    unknown, AssetType>,
): UseQueryResult<{ getAssetType: AssetType }> =>
  useQuery(QueryKeys.ASSET_TYPE, () => getAssetType(assetTypeId), applyConfiguration(config));

export const useAssetTypes = <T extends any = AssetType[]>(
  config?: UseQueryOptions<T,
    unknown, AssetType[]>,
): UseQueryResult<{ getAssetTypes: AssetType[] }> =>
  useQuery(QueryKeys.ASSET_TYPES, getAssetTypes, applyConfiguration(config));

// ASSET ITEMS
export const useAssetItem = <T extends any = AssetItems>(
  assetItemsId: number,
  config?: UseQueryOptions<T,
    unknown, AssetItems>,
): UseQueryResult<{ getAssetItem: AssetItems }> =>
  useQuery(QueryKeys.ASSET_ITEM, () => getAssetItem(assetItemsId), applyConfiguration(config));

export const useAssetItems = <T extends any = AssetItems[]>(
  config?: UseQueryOptions<T,
    unknown, AssetItems[]>,
): UseQueryResult<{ getAssetItems: AssetItems[] }> =>
  useQuery(QueryKeys.ASSET_ITEMS, getAssetItems, applyConfiguration(config));

// INVESTMENTS
export const useInvestment = <T extends any = Investment>(
  investmentId: number,
  config?: UseQueryOptions<T,
    unknown, Investment>,
): UseQueryResult<{ getInvestment: Investment }> =>
  useQuery(QueryKeys.INVESTMENT, () => getInvestment(investmentId), applyConfiguration(config));

export const useCreateInvestment = <T extends any = any>(
  investmentData: CreateInvestmentInput,
  config?: UseQueryOptions<T,
    unknown, any>,
): UseMutationResult<any> =>
  useMutation(QueryKeys.CREATE_INVESTMENT, () => createInvestment(investmentData), applyConfiguration(config));

export const useUpdateInvestment = <T extends any = any>(
  investmentData: UpdateInvestmentInput,
  config?: UseQueryOptions<T,
    unknown, any>,
): UseMutationResult<any> =>
  useMutation(QueryKeys.CREATE_INVESTMENT, () => updateInvestment(investmentData), applyConfiguration(config));

export const useInvestments = <T extends any = Investment[]>(
  config?: UseQueryOptions<T,
    unknown, Investment[]>,
): UseQueryResult<{ getInvestments: Investment[] }> =>
  useQuery(QueryKeys.INVESTMENTS, getInvestments, applyConfiguration(config));

// FUNDING REQUESTS
export const useFundingRequest = <T extends any = FundingRequestDetail>(
  fundingRequestId: number,
  config?: UseQueryOptions<T,
    unknown, FundingRequestDetail>,
): UseQueryResult<{ getFundingRequest: FundingRequestDetail }> =>
  useQuery(QueryKeys.FUNDING_REQUEST, () => getFundingRequest(fundingRequestId), applyConfiguration(config));

export const useFundingRequests = <T extends any = FundingRequestDetail[]>(
  config?: UseQueryOptions<T,
    unknown, FundingRequestDetail[]>,
): UseQueryResult<{ getFundingRequests: FundingRequestDetail[] }> =>
  useQuery(QueryKeys.FUNDING_REQUESTS, getFundingRequests, applyConfiguration(config));

export const useCreateFundingRequest = <T extends any = any>(
  fundingRequestData: CreateFundingRequestInput,
  config?: UseQueryOptions<T,
    unknown, any>,
): UseMutationResult<any> =>
  useMutation(QueryKeys.CREATE_FUNDING_REQUEST, () => createFundingRequest(fundingRequestData), applyConfiguration(config));

export const useUpdateFundingRequest = <T extends any = any>(
  fundingRequestData: UpdateFundingRequestInput,
  config?: UseQueryOptions<T,
    unknown, any>,
): UseMutationResult<any> =>
  useMutation(QueryKeys.UPDATE_FUNDING_REQUEST, () => updateFundingRequest(fundingRequestData), applyConfiguration(config));

// FUNDING REQUEST TYPES

export const useFundingRequestType = <T extends any = FundingRequestType>(
  fundingRequestTypeId: number,
  config?: UseQueryOptions<T,
    unknown, FundingRequestType>,
): UseQueryResult<{ getFundingRequestType: FundingRequestType }> =>
  useQuery(QueryKeys.FUNDING_REQUEST_TYPE, () => getFundingRequestType(fundingRequestTypeId), applyConfiguration(config));

export const useFundingRequestTypes = <T extends any = FundingRequestType[]>(
  config?: UseQueryOptions<T,
    unknown, FundingRequestType[]>,
): UseQueryResult<{ getFundingRequestTypes: FundingRequestType[] }> =>
  useQuery(QueryKeys.FUNDING_REQUEST_TYPES, getFundingRequestTypes, applyConfiguration(config));

// USERS
export const useUser = <T extends any = User>(
  userId: number,
  config?: UseQueryOptions<T,
    unknown, User>,
): UseQueryResult<{ getUser: User }> =>
  useQuery(QueryKeys.USER, () => getUser(userId), applyConfiguration(config));

export const useUserForAdmin = <T extends any = User>(
  userId: number,
  config?: UseQueryOptions<T,
    unknown, User>,
): UseQueryResult<{ getUser: User }> =>
  useQuery(QueryKeys.USER_FOR_ADMIN, () => getUser(userId), applyConfiguration(config));

export const useUsers = <T extends any = User[]>(
  config?: UseQueryOptions<T,
    unknown, User[]>,
): UseQueryResult<{ getUsers: User[] }> =>
  useQuery(QueryKeys.USERS, getUsers, applyConfiguration(config));

// VERTICALS
export const useVertical = <T extends any = Vertical>(
  verticalId: number,
  config?: UseQueryOptions<T,
    unknown, Vertical>,
): UseQueryResult<{ getVertical: Vertical }> =>
  useQuery(QueryKeys.VERTICAL, () => getVertical(verticalId), applyConfiguration(config));

export const useVerticals = <T extends any = Vertical[]>(
  config?: UseQueryOptions<T,
    unknown, Vertical[]>,
): UseQueryResult<{ getVerticals: Vertical[] }> =>
  useQuery(QueryKeys.VERTICALS, getVerticals, applyConfiguration(config));

// WALLETS
export const useWallet = <T extends any = Wallet>(
  walletId: number,
  config?: UseQueryOptions<T,
    unknown, Wallet>,
): UseQueryResult<{ getWallet: Wallet }> =>
  useQuery(QueryKeys.WALLET, () => getWallet(walletId), applyConfiguration(config));

export const useWallets = <T extends any = Wallet[]>(
  config?: UseQueryOptions<T,
    unknown, Wallet[]>,
): UseQueryResult<{ getWallets: Wallet[] }> =>
  useQuery(QueryKeys.WALLETS, getWallets, applyConfiguration(config));

export const useUpdateWallet = <T extends any = any>(
  walletData: UpdateWalletInput,
  config?: UseQueryOptions<T,
    unknown, any>,
): UseMutationResult<any> =>
  useMutation(QueryKeys.UPDATE_WALLET, () => updateWallet(walletData), applyConfiguration(config));

// DEAL TYPES
export const useDealType = <T extends any = DealType>(
  dealTypeId: number,
  config?: UseQueryOptions<T, unknown, DealType>,
): UseQueryResult<{ getDealType: DealType }> =>
  useQuery(QueryKeys.DEAL_TYPE, () => getDealType(dealTypeId), applyConfiguration(config));

export const useDealTypes = <T extends any = DealType[]>(
  config?: UseQueryOptions<T, unknown, DealType[]>,
): UseQueryResult<{ getDealTypes: DealType[] }> =>
  useQuery(QueryKeys.DEAL_TYPES, getDealTypes, applyConfiguration(config));

// ADDRESSES
export const useAddresses = <T extends any = Address[]>(
  accountId: number,
  config?: UseQueryOptions<T,
    unknown, Address[]>,
): UseQueryResult<{ getAddresses: Address[] }> =>
  useQuery(QueryKeys.ADDRESSES, () => getAddresses(accountId), applyConfiguration(config));

export const useAddressTypes = <T extends any = AddressType[]>(
  config?: UseQueryOptions<T,
    unknown, AddressType[]>,
): UseQueryResult<{ getAddressTypes: AddressType[] }> =>
  useQuery(QueryKeys.ADDRESS_TYPES, () => getAddressTypes(), applyConfiguration(config));

export const useCreateAddress = <T extends any = any>(
  addressData: CreateAddressInput,
  config?: UseQueryOptions<T,
    unknown, any>,
): UseMutationResult<any> =>
  useMutation(QueryKeys.CREATE_ADDRESS, () => createAddress(addressData), applyConfiguration(config));

export const useUpdateAddress = <T extends any = any>(
  addressData: UpdateAddressInput,
  config?: UseQueryOptions<T,
    unknown, any>,
): UseMutationResult<any> =>
  useMutation(QueryKeys.UPDATE_ADDRESS, () => updateAddress(addressData), applyConfiguration(config));

// DOCUMENT SIGNATURES
export const useDocumentSignatures = <T extends any = DocumentSignature[]>(
  investmentId: number,
  config?: UseQueryOptions<T,
    unknown, DocumentSignature[]>,
): UseQueryResult<{ getDocumentSignatures: DocumentSignature[] }> =>
  useQuery(QueryKeys.DOCUMENT_SIGNATURES, () => getDocumentSignatures(investmentId), applyConfiguration(config));

// QUERIES for User specific data (non admin)
export const useUserPortfolioInvestments = <T extends any = UserPortfolioInvestment[]>(
  config?: UseQueryOptions<T,
    unknown, UserPortfolioInvestment[]>,
): UseQueryResult<{ getUserPortfolioInvestments: UserPortfolioInvestment[] }> =>
  useQuery(QueryKeys.USER_PORTFOLIO_INVESTMENTS, getUserPortfolioInvestments, applyConfiguration(config));

export const useUserAccountTotal = <T extends any = UserAccountTotal>(
  config?: UseQueryOptions<T,
    unknown, UserAccountTotal>,
): UseQueryResult<{ getUserAccountTotal: UserAccountTotal }> =>
  useQuery(QueryKeys.USER_ACCOUNT_TOTAL, getUserAccountTotal, applyConfiguration(config));

export const useUserWallets = <T extends any = Wallet[]>(
  config?: UseQueryOptions<T,
    unknown, Wallet[]>,
): UseQueryResult<{ getUserWallets: Wallet[] }> =>
  useQuery(QueryKeys.USER_WALLETS, getUserWallets, applyConfiguration(config));

export const useWalletExternalAccounts = <T extends any = ExternalAccount[]>(
  walletId: number,
  config?: UseQueryOptions<T,
    unknown, ExternalAccount[]>,
): UseQueryResult<{ getExternalAccounts: ExternalAccount[] }> =>
  useQuery(QueryKeys.WALLET_EXTERNAL_ACCOUNTS, () => getExternalAccounts(walletId), applyConfiguration(config));

export const useWalletFundings = <T extends any = WalletActivity[]>(
  walletId: string,
  config?: UseQueryOptions<T,
    unknown, WalletActivity[]>,
): UseQueryResult<{ getWalletFundings: WalletActivity[] }> =>
  useQuery(QueryKeys.WALLET_FUNDINGS, () => getWalletFundings(walletId), applyConfiguration(config));

export const useAccountAddresses = <T extends any = Address[]>(
  config?: UseQueryOptions<T,
    unknown, Address[]>,
): UseQueryResult<{ getAccountAddresses: Address[] }> =>
  useQuery(QueryKeys.ACCOUNT_ADDRESSES, () => getAccountAddresses(), applyConfiguration(config));

// ACCREDITATION STATUS/ES
export const useAccreditationStatus = <T extends any = AccreditationStatus>(
  accreditationStatusId: number,
  config?: UseQueryOptions<T, unknown, AccreditationStatus>,
): UseQueryResult<{ getAccreditationStatus: AccreditationStatus }> =>
  useQuery(QueryKeys.ACCREDITATION_STATUS, () => getAccreditationStatus(accreditationStatusId), applyConfiguration(config));

export const useAccreditationStatuses = <T extends any = AccreditationStatus[]>(
  config?: UseQueryOptions<T, unknown, AccreditationStatus[]>,
): UseQueryResult<{ getAccreditationStatuses: AccreditationStatus[] }> =>
  useQuery(QueryKeys.ACCREDITATION_STATUSES, getAccreditationStatuses, applyConfiguration(config));

// Accredited Investor Requests

export const useAccreditedInvestorRequest = <T extends any = AccreditedInvestorRequest>(
  walletObjectId: String,
  config?: UseQueryOptions<T,
    unknown, AccreditedInvestorRequest>,
): UseQueryResult<{ getAccreditedInvestorRequest: AccreditedInvestorRequest }> =>
  useQuery(QueryKeys.ACCREDITED_INVESTOR_REQUEST, () => getAccreditedInvestorRequest(walletObjectId), applyConfiguration(config));

export const useAccreditedInvestorRequests = <T extends any = AccreditedInvestorRequest[]>(
  accountObjectId: String,
  config?: UseQueryOptions<T,
    unknown, AccreditedInvestorRequest[]>,
): UseQueryResult<{ getAccreditedInvestorRequests: AccreditedInvestorRequest[] }> =>
  useQuery(QueryKeys.ACCREDITED_INVESTOR_REQUESTS, () => getAccreditedInvestorRequests(accountObjectId), applyConfiguration(config));

export const useAddress = <T extends any = Address>(
  addressId: number,
  config?: UseQueryOptions<T,
    unknown, Address>,
): UseQueryResult<{ getAddress: Address }> =>
  useQuery(QueryKeys.ADDRESS, () => getAddress(addressId), applyConfiguration(config));

export const useCashFlow = <T extends any = CashFlow>(
  assetId: number,
  config?: UseQueryOptions<T, unknown, CashFlow>,
): UseQueryResult<{ getCashFlow: CashFlow }> =>
  useQuery(QueryKeys.CASH_FLOW, () => getCashFlow(assetId), applyConfiguration(config));

export const useCashFlows = <T extends any = CashFlow[]>(
  config?: UseQueryOptions<T, unknown, CashFlow[]>,
): UseQueryResult<{ getCashFlows: CashFlow[] }> =>
  useQuery(QueryKeys.CASH_FLOWS, getCashFlows, applyConfiguration(config));

export const usePendingAccountOnboardApplications = <T extends any = PendingOnboardApplication[]>(
  config?: UseQueryOptions<T,
    unknown, PendingOnboardApplication[]>,
): UseQueryResult<{ getPendingAccountOnboardApplications: PendingOnboardApplication[] }> =>
  useQuery(QueryKeys.PENDING_ACCOUNT_ONBOARD_APPLICATIONS, () => getPendingAccountOnboardApplications(), applyConfiguration(config));

export type useCommercialEntityTypesResponse = { getCommercialEntityTypes: CommercialEntityType[] };

export const useCommercialEntityTypes = <T extends any = CommercialEntityType[]>(
  config?: UseQueryOptions<T,
    unknown, CommercialEntityType[]>,
): UseQueryResult<useCommercialEntityTypesResponse> =>
  useQuery(QueryKeys.COMMERCIAL_ENTITY_TYPES, () => getCommercialEntityTypes(), applyConfiguration(config));