import React, { useContext } from "react";
import PageCard from "../../common/components/PageCard";
import { Typography } from "@material-ui/core";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import AccButton, { AccButtonColors } from "../../common/components/AccButton";
import { useAccreditedInvestorRequests, usePendingAccountOnboardApplications, useUserWallets } from "../../api/hooks/graphqlHooks";
import { formatCurrency, isNotNil } from "../../common/utilities";
import PageWrapper from "../../common/components/PageWrapper";
import COLORS from "../../common/colors";
import { AccreditedInvestorStatus, WalletStatus } from "../../enums";
import { SessionContext } from "../../context";
import moment from "moment";

interface ButtonProps {
  link: string,
  label: string,
  color: AccButtonColors,
}

const LinkedButton: React.FC<ButtonProps> = ({ link, label, color }) => (

  <Link to={link}>
    <AccButton
      label={label}
      color={color}
      style={styles.button}
    />
  </Link>
);

interface Props {

}

const InvestorAccounts: React.FC<Props> = () => {

  const { data, isFetching, isLoading } = useUserWallets({
    refetchOnMount: true,
  });
  const { data: pendingOnboardApplicationsData, isFetching: pendingOnboardApplicationsFetching } = usePendingAccountOnboardApplications();
  const pendingWallets = pendingOnboardApplicationsData?.getPendingAccountOnboardApplications;
  const { user: sessionUser } = useContext(SessionContext);
  const showClosedWalletData = isNotNil(data?.getUserWallets.filter(item => item?.walletStatus.name === WalletStatus.CLOSED));
  const showOpenWalletData = isNotNil(data?.getUserWallets.filter(item => item?.walletStatus.name === WalletStatus.OPEN));
  const { data: accreditedInvestorData, isFetching: isAccreditationDataFetching, isLoading: isAccreditationDataLoading } =
    useAccreditedInvestorRequests(
      sessionUser?.accountObjectId, {
      refetchOnMount: true,
      enabled: isNotNil(sessionUser?.accountObjectId),
    });

  return (
    <PageWrapper
      title="Investment Accounts"
      subtitle={
        <Typography
          className="acc-darkgray page-subtitle"
          variant="h6"
          style={{ fontWeight: 300 }}>Create and manage all your <span className="acc-text acc-green">access</span> investment accounts</Typography>
      }
      showLoader={isFetching || isLoading || isAccreditationDataFetching || isAccreditationDataLoading || pendingOnboardApplicationsFetching}
    >
      <br />
      <br />
      <div className="width-100 row stretch center">
        {showOpenWalletData && <Typography
          variant="h5"
          className="acc-darkgray bold"
          style={{ position: "absolute" }}>
          List of Accounts
        </Typography>}
        <div
          className="width-100"
          style={{ display: "flex", justifyContent: "flex-end" }}>
          <Link
            to="/investor/accounts/onboard">
            <AccButton
              color={AccButtonColors.GREEN}
              label="Add Account"
            />
          </Link>
        </div>
      </div>
      {showOpenWalletData && <PageCard className="m-top-15">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow >
                {headers.map(item =>
                  <TableCell
                    key={item}
                    className="bold acc-darkblue"
                    align="left">
                    {item}
                  </TableCell>,
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.getUserWallets ? data?.getUserWallets
                .filter(item => item.walletStatus.name === WalletStatus.OPEN)
                .map((wallet) => (
                  <TableRow
                    hover
                    component={Link}
                    to={`/investor/accounts/accountdetails/${wallet.walletId}`}
                    key={wallet.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row">{wallet.name}</TableCell>
                    <TableCell align="left">{wallet.walletType?.name}</TableCell>
                    <TableCell align="left">{isNotNil(wallet.umbAccountId) ? "Verified" :
                      <LinkedButton
                        link="accounts/onboard"
                        label="Complete Setup"
                        color={AccButtonColors.GREEN} />}
                    </TableCell>
                    <TableCell align="left">
                      {/* first wallet will be redirected to /investor/accreditation and gets the walletObjectId 
                                        {/* first wallet will be redirected to /investor/accreditation and gets the walletObjectId 
                                            {/* first wallet will be redirected to /investor/accreditation and gets the walletObjectId 
                                        from the session else we add the walletObjectId as param */}
                      {!accreditedInvestorData?.getAccreditedInvestorRequests.find(
                        accredit => wallet.id === accredit.walletObjectId) ?
                        <LinkedButton
                          link={`/investor/accreditation/${wallet.id}`}
                          label="Complete Accreditation"
                          color={AccButtonColors.BLUE}
                        /> : accreditedInvestorData?.getAccreditedInvestorRequests.map((accreditedIvestorRequest) => {
                          if (accreditedIvestorRequest?.walletObjectId === wallet.id && accreditedIvestorRequest?.status) {
                            if (accreditedIvestorRequest?.status === AccreditedInvestorStatus.NEED_MORE_INFO) {
                              return (
                                <LinkedButton
                                  key={accreditedIvestorRequest.airequestId}
                                  link={`/investor/wallet/needMoreInfo/${wallet.id}`}
                                  label={AccreditedInvestorStatus.NEED_MORE_INFO}
                                  color={AccButtonColors.GREEN}
                                />
                              );
                            } else {
                              return accreditedIvestorRequest?.status;
                            };
                          }
                        })}
                    </TableCell>
                    <TableCell align="left">{isNotNil(wallet.externalAccounts) ? "Connected" :
                      <LinkedButton
                        link={`wallet/connectbankaccount/${wallet.walletId}`}
                        label="Connect Bank Account"
                        color={AccButtonColors.GREEN} />}</TableCell>
                    <TableCell align="left">Active</TableCell>
                    <TableCell
                      align="left"
                      className={wallet.availableAmount > 0 ? "bold" : "light"}
                      style={{
                        color: wallet.availableAmount > 0 ? COLORS.green : COLORS.darkGray,
                      }}>
                      {wallet.availableAmount > 0 ? formatCurrency(wallet.availableAmount) : "-"}
                    </TableCell>
                  </TableRow>
                )) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </PageCard>}
      <br />

      {
        isNotNil(pendingWallets) && (
          <>
            <Typography
              variant="h5"
              className="acc-darkgray bold">
              Pending Accounts
            </Typography>
            <PageCard className="m-top-15">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow >
                      {pendingWalletHeaders.map(item =>
                        <TableCell
                          key={item}
                          className="bold acc-darkblue"
                          align="left">
                          {item}
                        </TableCell>,
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pendingWallets
                      .map((wallet) => (
                        <TableRow
                          key={wallet.entityName}

                          hover
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell
                            component="th"
                            scope="row">{wallet.walletName}</TableCell>
                          <TableCell align="left">{wallet.applicationType}</TableCell>
                          <TableCell align="left">{wallet.onboardStatus}</TableCell>
                          <TableCell align="left">{moment.utc(parseInt(wallet.submissionDate)).format("MM/DD/YYYY")}</TableCell>

                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </PageCard>
          </>
        )
      }

      <br />
      {
        showClosedWalletData &&
        <>
          <Typography
            variant="h5"
            className="acc-darkgray bold">
            Closed Accounts
          </Typography>
          <PageCard className="m-top-15">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow >
                    {headers.map(item =>
                      <TableCell
                        key={item}
                        className="bold acc-darkblue"
                        align="left">
                        {item}
                      </TableCell>,
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.getUserWallets ? data?.getUserWallets
                    .filter(item => item?.walletStatus.name === WalletStatus.CLOSED)
                    .map((wallet) => (
                      <TableRow
                        key={wallet.id}
                        hover
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell
                          component="th"
                          scope="row">{wallet ? wallet.name : "-"}</TableCell>
                        <TableCell align="left">{wallet.walletType.name}</TableCell>
                        <TableCell align="left">Verified</TableCell>
                        <TableCell align="left">Approved</TableCell>
                        <TableCell align="left">Connected</TableCell>
                        <TableCell align="left">{wallet.walletStatus.name}</TableCell>
                        <TableCell align="center">-</TableCell>
                      </TableRow>
                    )) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </PageCard>
        </>
      }
    </PageWrapper >
  );
};

const styles = {
  button: {
    width: "150px",
    padding: "0 5px",
    height: "28px",
    zIndex: 5,
    fontSize: 11,
  },
};

const pendingWalletHeaders = ["Account Nickname", "Account Type", "Account Status", "Date Submitted"];
const headers = ["Account Nickname", "Account Type", "AML/KYC Status", "Accreditation Status", "Bank Account", "Account Status", "Available Balance"];

export default InvestorAccounts;