import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import React from "react";
import { THEME_BREAKPOINTS } from "../../constants";
import useMediaQuery from "@mui/material/useMediaQuery";
import mediumZoom from "medium-zoom";

interface ZoomProps {
  zoom: any;
  src: string;
  srcSet: string;
  alt: string;
  background: string;
  style: React.CSSProperties;
  margin: number;
}

const ImageZoom: React.FC<ZoomProps> = ({ zoom, src, alt, background, srcSet, style, margin }) => {
  const zoomRef = React.useRef(zoom.clone({ background, margin }));

  function attachZoom(image: any) {
    zoomRef.current.attach(image);
  }
  return (
    <ImageListItem>
      <img
        src={src}
        alt={alt}
        ref={attachZoom}
        srcSet={srcSet}
        loading="lazy"
        style={style} />
    </ImageListItem>
  );
};

interface Props {
  imageData?: any;
}
const ImageMasonry: React.FC<Props> = ({ imageData }) => {

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  const zoom = React.useRef(mediumZoom());

  return (
    <Box sx={{ width: "100%", height: "fit-content" }}>
      <ImageList
        variant="masonry"
        cols={mobileLayout ? 2 : 3}
        gap={mobileLayout ? 10 : 16}>
        {imageData.map((item: { img: string, title: string }) => (
          <ImageZoom
            src={item.img}
            alt={item.title}
            zoom={zoom.current}
            background="rgba(0, 0, 0, 0.5)"
            srcSet={item.img}
            style={{ borderRadius: "16px", zIndex: "9999" }}
            margin={50}
            key={item.img}
          />
        ))}
      </ImageList>
    </Box>
  );
};


export default ImageMasonry;
