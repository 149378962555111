import React from "react";
import COLORS from "../../../../common/colors";
import { Link } from "react-router-dom";
import "./style.css";
import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AccButton, { AccButtonColors } from "../../../../common/components/AccButton";
import { useMediaQuery } from "@mui/material";
import { THEME_BREAKPOINTS } from "../../../../common/constants";

interface Props {
  image: string;
  title: string | React.ReactFragment;
  id: number;
  isPreviouslyCommittedInvestment?: boolean;
  committedDate?: string;
}

const PortfolioDocument: React.FC<Props> = ({
  image,
  title,
  id,
  isPreviouslyCommittedInvestment,
  committedDate,
}) => {
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const parsedDate = new Date(Number(committedDate));
  const renderLabelButton = () => (
    isPreviouslyCommittedInvestment ? "View" : "access"
  );

  return (
    <Link
      to={
        `/investor/portfolio/statements/${id}`
      }
      className="row center stretch width-80 width-100-mobile m-bottom-30"
      style={{ ...styles.background }}
    >
      <div
        className={mobileLayout ? "width-40 wrap justify-center" : "image-div-document wrap justify-center"}
        style={{
          display: "flex",
          backgroundImage: `${COLORS.imageGradientOverlay}, 
          url(${image})`,
          ...(styles.image as React.CSSProperties),
        }}
      >
      </div>
      <div
        className="column center"
        style={{ width: "-webkit-fill-available", paddingLeft: mobileLayout ? "" : "20px", paddingBottom: mobileLayout ? "5px" : "" }}
      >
        <div
          className="row center stretch column-mobile width-100 document-title-div">
          <div
            className="m-right-15"
            style={{ marginBottom: mobileLayout ? "" : "15px", width: "auto" }}>
            <div>
              {isPreviouslyCommittedInvestment && (
                <span
                  className="acc-green investmentIn"
                  style={{
                    fontSize: mobileLayout ? "8px" : "9px",
                    fontWeight: 500,
                    height: "10px",
                  }}
                >
                  Documents & Statements for your investment in
                </span>
              )}
            </div>
            <div className="">
              <span
                className="acc-white"
                style={{ fontWeight: 500, fontSize: mobileLayout ? "14px" : "18px" }}>
                {title}
              </span>
            </div>
            {isPreviouslyCommittedInvestment ? (
              <div className="width-100-mobile m-top-10-mobile">

                <div
                  style={{ marginTop: "5px", width: mobileLayout ? "190px" : "" }}
                >
                  <FontAwesomeIcon
                    className="acc-lightgreen"
                    style={{
                      fontSize: 14,
                      marginRight: 5,
                    }}
                    icon={faCalendarAlt}
                  />
                  <span
                    className="acc-white"
                    style={{
                      textAlign: "left",
                      fontSize: mobileLayout ? 8 : 9,
                      lineHeight: "14px",
                    }}
                  >
                    Investment made on{" "}
                    {moment(parsedDate).format("MM/DD/YYYY")}{" "} at {" "} {moment(parsedDate).format("h:mm A")}
                  </span>
                </div>

              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="row start">
        <AccButton
          label={renderLabelButton()}
          className="bold display-none-mobile"
          color={AccButtonColors.GREEN}
          style={{
            cursor: "pointer",
            borderRadius: 16,
            backgroundColor: COLORS.lightGreen,
            fontSize: "12px",
            width: "110px",
            height: "35px",
            borderStyle: "none",
            fontFamily: isPreviouslyCommittedInvestment ? undefined : "Gilroy",
          }}
        />
      </div>
    </Link >
  );
};

const styles = {
  chip: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "120px",
    fontWeight: 500,
  },
  background: {
    background: COLORS.darkBlueGradient,
    borderRadius: 16,
    height: "85px",
    paddingRight: "25px",
  },
  image: {
    //width: "25%",
    height: "100%",
    borderRadius: "16px 0px 0px 16px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
};
export default PortfolioDocument;
