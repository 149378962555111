import React, { Component, ReactElement } from "react";
import COLORS from "../../colors";
import { isNotNil, standardErrorHandle } from "../../utilities";
import PageWrapper from "../PageWrapper";
import BrokenCard from "../../../assets/images/illustrations/access_404_page_illustration.png";
import { Typography } from "@material-ui/core";
import { getApiVersion } from "../../../api/utils";

interface Props {
  customErrorComponent?: ReactElement;
  errorPageHeaderText?: string;
  errorPageSubheaderText?: string;
  isMainPageError?: boolean;
}

interface ErrorBoundaryProps extends Props {
  children: ReactElement;
}

interface MainPageErrorComponentProps {
  errorPageHeaderText?: string;
  errorPageSubheaderText?: string;
}


interface State {
  apiVersion: string | null;
  didError: boolean;
  error: {
    message: string;
    name: string;
    stack: string;
  };
  info: {
    componentStack: string;
  };
}


class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  state = {
    apiVersion: null,
    didError: false,
    error: {
      message: "",
      name: "",
      stack: "",
    },
    info: {
      componentStack: "",
    },
  };

  static getDerivedStateFromError = () => {
    return {
      didError: true,
    };
  };

  async setApiVersion() {
    const apiVersion = await getApiVersion();
    this.setState({
      ...this.state,
      apiVersion,
    });
  }

  componentDidCatch = (error: any, info: any) => {
    standardErrorHandle(error, `[ERROR BOUNDARY EXCEPTION] ${error} ${info?.componentStack}`);
    this.setApiVersion();
    this.setState({
      info,
      error,
    });
  };

  render() {
    const { didError } = this.state;
    const {
      children,
      customErrorComponent,
      errorPageHeaderText,
      errorPageSubheaderText,
      isMainPageError,
    } = this.props;
    if (didError) {
      if (isNotNil(customErrorComponent)) {
        return customErrorComponent;
      }
      if (isMainPageError) {
        return (
          <MainPageErrorComponent
            errorPageHeaderText={errorPageHeaderText}
            errorPageSubheaderText={errorPageSubheaderText}
          />);
      }
      return customErrorComponent != undefined ? (
        customErrorComponent
      ) : (
        <div
          className="column center stretch"
          style={{ ...styles.wrapper, margin: 30 }}>
          <Typography
            variant="h4"
            className="acc-white bold">{errorPageHeaderText ?? this.state.error?.message ?? "Something went wrong"}</Typography>
          <img
            src={BrokenCard}
            style={{ height: "50%" }} />
          <Typography
            variant="h5"
            className="acc-white bold">Please try to refresh page</Typography>
          <Typography
            variant="subtitle2"
            className="acc-white bold"><span className="acc-text">api</span> {this.state.apiVersion}</Typography>
        </div>
      );
    }

    return children;
  }

}


export const MainPageErrorComponent = (props: MainPageErrorComponentProps) => (
  <PageWrapper
    title={props.errorPageHeaderText ?? "Something went wrong"}
    titleClassName="acc-white"
    wrapperClassName="column center stretch"
    wrapperStyle={styles.wrapper}>
    <img
      src={BrokenCard}
      style={{ height: "50%" }} />
    <p className="acc-white bold">{props.errorPageSubheaderText}</p>
  </PageWrapper>
);

export const withErrorBoundary = (errorBoundaryProps: Props = {}, Comp: any) => (props: any) => (
  <ErrorBoundary {...errorBoundaryProps}>
    <Comp {...props} />
  </ErrorBoundary>
);

const styles = {
  wrapper: {
    background: COLORS.darkBlueGradient,
    borderRadius: 16,
    padding: 40,
    height: "85vh",
  },
};