import { Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import PortfolioDocument from "./components/PortfolioDocument";
import { useAssets, useUserAccountTotal, useUserPortfolioInvestments, useUserWallets, useWalletFundings } from "../../api/hooks/graphqlHooks";
import { isNil } from "../../common/utilities";
import { InvestmentStatus, WalletStatus } from "../../enums";
import PageWrapper from "../../common/components/PageWrapper";
import WalletDocument from "./components/WalletDocument";
import { THEME_BREAKPOINTS } from "../../common/constants";


const Documents: React.FC = () => {


  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const { data } = useUserWallets({
    refetchOnMount: true,
  });

  const { isFetching: userAccountTotalsFetching } = useUserAccountTotal();
  const {
    isFetching: walletFundingsFetching,
  } = useWalletFundings("", {
    enabled: false,
  });
  const {
    data: getUserPortfolioInvestmentsData,
    isLoading: userPortfolioInvestmentsLoading,
    isFetching: userPortfolioInvestmentsFetching,
  } = useUserPortfolioInvestments({
    refetchOnMount: true,
  });


  const {
    data: getAssetsData,
    isLoading: assetDataLoading,
    isFetching: assetDataFetching,
  } = useAssets();


  const showLoader = (
    assetDataLoading ||
    !getAssetsData ||
    assetDataFetching ||
    !getUserPortfolioInvestmentsData ||
    userPortfolioInvestmentsLoading ||
    userPortfolioInvestmentsFetching ||
    userAccountTotalsFetching ||
    walletFundingsFetching
  );


  const accountHasNoInvestments = isNil(getUserPortfolioInvestmentsData?.getUserPortfolioInvestments.map(item => item.investments).flat(1));

  const renderWalletDocuments = () => (
    data?.getUserWallets ? data?.getUserWallets
      .filter(item => item.walletStatus.name === WalletStatus.OPEN)
      .map((wallet) =>
        <div
          className={accountHasNoInvestments ? "width-70 width-100-mobile" : "width-100 width-100-mobile"}
          key={wallet.id}>
          <WalletDocument
            key={wallet.id}
            walletId={wallet.walletId}
            name={wallet.name}
            isPreviouslyCommittedInvestment={true}
          />
        </div>,
      ) : null
  );

  return (
    <PageWrapper
      showLoader={showLoader}
      title="Documents"
      subtitle="All your important documents & statements"
    >

      <div
        className={mobileLayout ? "m-top-50" : "row stretch m-top-50"}>
        <div
          className="column start width-100">
          {accountHasNoInvestments
            ? <>
              <div
                className="width-70"
                style={{ marginTop: "20px" }}>
                <Typography
                  variant="h5"
                  className="bold acc-darkblue m-top-15"
                  style={{ fontSize: "24px" }}>
                  Wallets Documents & Statements
                </Typography>
                <br />
                {renderWalletDocuments()}
              </div>
            </>
            :
            <div className="width-100">
              <br />
              <Typography
                variant="h5"
                className="bold acc-darkblue m-top-15"
                style={{ fontSize: mobileLayout ? "19px" : "24px" }}>
                Investments Documents & Statements
              </Typography>
              <br />
              {(getUserPortfolioInvestmentsData?.getUserPortfolioInvestments ?? [])
                .map(item =>
                  item.investments
                    .filter(item => item.status !== InvestmentStatus.ATTEMPTED)
                    .sort((a, b) => new Date(Number(b.createdAt)).valueOf() - new Date(Number(a.createdAt)).valueOf())
                    .map(item =>
                      <div
                        className="width-100 width-100-mobile"
                        key={item.id}>
                        <PortfolioDocument
                          key={item.id}
                          image={item.asset.coverImageLocation}
                          title={item.asset.name}
                          id={item.investmentId}
                          isPreviouslyCommittedInvestment={true}
                          committedDate={item.committedDate}
                        />
                      </div>,
                    ))}
            </div>
          }

        </div>
        {!accountHasNoInvestments &&
          <div
            className={mobileLayout ? "width-100" : "width-70"}
            style={{ marginTop: "20px" }}>
            <Typography
              variant="h5"
              className="bold acc-darkblue m-top-15"
              style={{ fontSize: "22px" }}>
              Wallets Documents & Statements
            </Typography>
            <br />
            {renderWalletDocuments()}
          </div>
        }
      </div >
    </PageWrapper >
  );
};


export default Documents;