import React, { useState, useContext } from "react";
import { MenuItem, Modal, styled, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AccButton, { AccButtonColors } from "../../../../common/components/AccButton";
import COLORS from "../../../../common/colors";
import { ExternalAccount, UserTypes, Wallet } from "../../../../common/types";
import { Formik } from "formik";
import { useWalletExternalAccounts } from "../../../../api/hooks/graphqlHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity } from "@fortawesome/pro-light-svg-icons";
import CancelConfirmDialogModal from "../../../../common/components/CancelConfirmDialogModal";
import { isNil } from "../../../../common/utilities";
import { Link } from "react-router-dom";
import { SessionContext } from "../../../../context";

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  wallet: Wallet;
  handleCloseWallet: (walletObjectId: string, externalAccountObjectId: string) => void;
}

export const CloseAccountModal = ({ visible, setVisible, wallet, handleCloseWallet }: Props) => {

  const { data: externalAccountsData } = useWalletExternalAccounts(wallet.walletId);
  const { user } = useContext(SessionContext);

  const externalAccounts = externalAccountsData?.getExternalAccounts;

  const [isCloseAccountModalVisible, setIsCloseAccountModalVisible] = useState(false);

  return (
    <Formik
      onSubmit={() => { }}
      initialValues={{
        selectedExternalAccount: null,
        amount: 0,
      }}
    >
      {({ setFieldValue, values }) => (
        <>
          <Modal
            open={visible}
            onClose={() => { setVisible(false); }}>
            <Box
              className="width-100-mobile"
              sx={{
                ...styles.modal,
                minHeight: "400px",
                height: "fit-content",
              }}>
              <div style={{ width: "100%", height: 330, marginBottom: "15px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>
                <Typography
                  variant="h6"
                  className="bold acc-white">
                  Select account to transfer funds to upon closing <span className="acc-lightgreen"> {wallet?.name}</span>
                </Typography>
                <Typography
                  variant="subtitle2"
                  className="light acc-white">
                  This will be the account the remaining funds from your account will be transferred to
                </Typography>
                <br />
                {externalAccounts?.length ?
                  <>
                    <StyledSelect
                      onChange={(e: any) => {
                        setFieldValue("selectedExternalAccount", e.target.value);
                      }}
                      style={styles.select}
                      name="selectedExternalAccount"
                      size="medium"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      select
                      value={values.selectedExternalAccount}
                      placeholder="Select Bank Account"
                      label={<div style={styles.inputLabel}>Select Bank Account</div>}
                    >
                      {externalAccounts?.map((item: ExternalAccount) => (
                        <MenuItem
                          disabled={!item.bankName}
                          key={item.id}
                          value={item.id}>
                          <div className="row center width-100">
                            <div className="row center m-right-15">
                              <FontAwesomeIcon
                                className="acc-green m-right-15"
                                size="lg"
                                icon={faUniversity} />
                              <Typography
                                variant="h6"
                                className="acc-gray bold">
                                {item.bankName} - {item.displayName}
                              </Typography>
                            </div>
                            <Typography
                              variant="caption"
                              className="acc-gray light">
                              ****{item.lastFour}
                            </Typography>
                          </div>
                        </MenuItem>
                      ))}
                    </StyledSelect>
                    <AccButton
                      disabled={isNil(values?.selectedExternalAccount)}
                      type="submit"
                      style={{ width: "25%", minWidth: 120 }}
                      color={isNil(values?.selectedExternalAccount) ? AccButtonColors.GREEN_OUTLINE : AccButtonColors.GREEN}
                      label={"Confirm"}
                      onClick={() => setIsCloseAccountModalVisible(true)}
                    />
                  </>
                  :
                  <>
                    <Typography
                      variant="subtitle2"
                      className="light acc-white">
                      You do not have any external accounts connected, please connect bank account first in order to transfer funds
                    </Typography>
                    {user?.userType?.name !== UserTypes.ADMIN ?
                      <Link
                        className="width-100-mobile m-bottom-15-mobile"
                        to={`/investor/wallet/connectbankaccount/${wallet.walletId}`}>
                        <AccButton
                          label="Connect Bank Account"
                          color={AccButtonColors.GREEN}
                          className="m-left-15"
                          style={{ width: "fit-content" }}
                        />
                      </Link>
                      : null}
                  </>
                }
              </div>
            </Box >
          </Modal >
          <CancelConfirmDialogModal
            isVisible={isCloseAccountModalVisible}
            dialogHeader="Are you sure you'd like to close your account?"
            cancelButtonText="No"
            confirmButtonText="Yes"
            onClickCancel={() => setIsCloseAccountModalVisible(false)}
            onClickDelete={() => handleCloseWallet(wallet?.id, values?.selectedExternalAccount!)}
          />
        </>
      )
      }
    </Formik >
  );
};


const StyledSelect = styled(TextField)({
  "& label.Mui-focused": {
    color: COLORS.darkBlue,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.green,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      padding: 0,
      borderRadius: 16,
      boxShadow: "0px 3px 6px #00000029",
    },
    "&.Mui-focused fieldset": {
      borderColor: COLORS.green,
    },
  },
  "& .MuiSelect-outlined": {
    borderRadius: 16,
  },
  "& .MuiOutlinedInput-input:-webkit-autofill": {
    borderRadius: 16,
  },
});

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: COLORS.darkBlueGradient,
    border: "none",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
    width: "45%",
    overflowY: "scroll",
  },
  inputLabel: {
    backgroundColor: "white",
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 16,
  },
  input: {
    background: "white",
    borderRadius: 16,
  },
  select: {
    background: "white",
    borderRadius: 16,
  },
};