import { Chip } from "@material-ui/core";
import React from "react";
import { useAssets } from "../../../api/hooks/graphqlHooks";
import { AssetStatus } from "../../../enums";
import PortfolioInvestment from "../../../screens/Portfolio/components/PortfolioInvestment";
import COLORS from "../../colors";
import { Asset } from "../../types";

const AvailableAssetsCard = () => {

  const { data } = useAssets();

  return (
    <>
      {
        data?.getAssets
          .filter(item => item.assetStatus.name === AssetStatus.AVAILABLE)
          .sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())
          .slice(0, 3)
          .map((item: Asset) => (
            <PortfolioInvestment
              key={item.id}
              image={item.coverImageLocation}
              title={item.name}
              description={item.summary}
              targetedMOIC={item.targetedMOIC}
              projectedIRR={item.targetedIRR}
              projectedDistribution={item.targetedAverageCashYield}
              projectedTerm={item.targetedInvestmentPeriod}
              createdAt={item.createdAt}
              status={
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "105px",
                  }}
                >
                  <Chip
                    label="AVAILABLE"
                    size="small"
                    style={{
                      color: COLORS.darkBlue, ...styles.chip as React.CSSProperties,
                      backgroundColor: COLORS.lightGreen,
                    }}
                  />
                </div>}
              id={item.assetId}
              isPreviouslyCommittedInvestment={false}
              targetedFundingDate={item.targetedFundingDate}
              remainingAmount={item.remainingAmount}
            />
          ))
      }
    </>
  );
};

export default AvailableAssetsCard;

const styles = {
  chip: {
    fontWeight: 500,
    fontSize: "12px",
    width: "90px",
    overflow: "hidden",
  },
};