import React from "react";
import PageCard from "../../../../common/components/PageCard";
import { Typography } from "@material-ui/core";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper } from "@mui/material";
import { useWalletFundings } from "../../../../api/hooks/graphqlHooks";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { formatCurrency, isNil } from "../../../../common/utilities";

const Fundings: React.FC = ({ match }: any) => {

  const {
    data: walletFundingsData,
  } = useWalletFundings(match.params.id, {
    refetchOnMount: true,
  });

  if (isNil(walletFundingsData)) {
    return <>No Fundings here to show</>;
  }
  return (
    <div>
      <PageCard>
        <Typography
          variant="h5"
          className="acc-darkblue bold">
          Account Fundings
        </Typography>
        <br />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">DATE</TableCell>
                <TableCell align="left">DESCRIPTION</TableCell>
                <TableCell align="left">STATUS</TableCell>
                <TableCell align="left">BANK ACCOUNT</TableCell>
                <TableCell align="right">AMOUNT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {walletFundingsData?.getWalletFundings
                .sort((a, b) => new Date(Number(b.createdAt)).valueOf() - new Date(Number(a.createdAt)).valueOf())
                .map((row) => (
                  <TableRow
                    key={row.createdAt}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row">{moment(Number(row.createdAt)).format("ll")}</TableCell>
                    <TableCell align="left">{row.fundingRequestType.name}</TableCell>
                    <TableCell align="left">{row.status}</TableCell>
                    <TableCell
                      align="left">
                      <span className="acc-darkblue bold">{row.externalAccount.bankName}</span>&nbsp;
                      - {row.externalAccount.displayName} - xxxx{row.externalAccount.lastFour}

                    </TableCell>
                    <TableCell align="right">{formatCurrency(row.amount)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PageCard>
    </div>
  );
};


export default withRouter(Fundings);