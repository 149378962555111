import { useRef, RefObject, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useFocus = () => {
  const htmlElRef = useRef(null);
  // @ts-ignore
  const setFocus = () => { htmlElRef.current && htmlElRef.current.focus(); };

  return [htmlElRef, setFocus];
};

export const useClickOutsideHandler = (
  parentRef: RefObject<HTMLDivElement | HTMLButtonElement>, 
  childRef: RefObject<HTMLDivElement | HTMLButtonElement>, 
  setState: (arg: boolean) => void) => {

  const handleClick = (e: any) => {
    if (parentRef.current && !parentRef.current!.contains(e.target) && childRef.current && !childRef.current!.contains(e.target)
    ) {
      setState(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });
};

export const useRouteQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

interface UseKeyboardParams {
  key: string;
  onKeyDown: (e: KeyboardEvent) => void;
}

export enum KeyboardKeys {
  ENTER = "Enter",
}

export const useKeyboardPress = (params: UseKeyboardParams) => {
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === params.key) {
      params.onKeyDown(e);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
};