import React, { useState } from "react";
import { useWalletExternalAccounts } from "../../../../api/hooks/graphqlHooks";
import { ExternalAccount } from "../../../../common/types";
import BankAccountCard from "../../../../common/components/BankAccountCard";
import PageCard from "../../../../common/components/PageCard";
import { Typography } from "@material-ui/core";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper } from "@mui/material";
import { useWalletFundings } from "../../../../api/hooks/graphqlHooks";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { formatCurrency, isNil, isNotNil } from "../../../../common/utilities";
import COLORS from "../../../../common/colors";
import { withErrorBoundary } from "../../../../common/components/ErrorBoundary";
import PageWrapper from "../../../../common/components/PageWrapper";

const ExternalAccounts: React.FC = withErrorBoundary({}, ({ match }: any) => {

  const [showAccountFundings, setShowAccountFundings] = useState<string | null>(null);

  const {
    data: externalAccountsData,
    isFetching: externalAccountsFetching,
    isLoading: externalAccountsLoading,
    isError: externalAccountsError,
  } = useWalletExternalAccounts(parseInt(match.params.id), {
    refetchOnMount: true,
  });

  const {
    data: walletFundingsData,
    isFetching: walletFundingsFetching,
    isLoading: walletFundingsLoading,
    isError: walletFundingsError,
  } = useWalletFundings(match.params.id, {
    refetchOnMount: true,
  });

  const filteredFundings = walletFundingsData?.getWalletFundings?.filter(item => item.externalAccount.externalAccountId === showAccountFundings);

  const pageLoading =
    !externalAccountsData ||
    externalAccountsFetching ||
    externalAccountsLoading ||
    !walletFundingsData ||
    walletFundingsFetching ||
    walletFundingsLoading;


  return (
    <PageWrapper
      showLoader={pageLoading}
      showError={externalAccountsError || walletFundingsError}
      errorMessage="Cannot retrieve external accounts"
    >
      <div className="row stretch column-mobile">
        <div className="width-100">
          <Typography
            variant="h5"
            className="acc-darkblue bold">External accounts connected to wallet</Typography>
          <Typography
            variant="caption"
            className="acc-gray light"
          >Select account to view fundings</Typography>
          <br />
          <br />
          {isNotNil(externalAccountsData?.getExternalAccounts) ? externalAccountsData?.getExternalAccounts?.map((item: ExternalAccount) =>
            <div
              className="row stretch column-mobile"
              key={item.id}>
              <div className="width-50 width-100-mobile">
                <BankAccountCard
                  style={{
                    background: showAccountFundings === item.externalAccountId ? COLORS.darkBlueGradient : "white",
                    color: showAccountFundings === item.externalAccountId ? "white" : "",
                  }}
                  onClick={() => setShowAccountFundings(item.externalAccountId)}
                  key={item.id}
                  deleteButtonVisible={false}
                  data={item} />
              </div>
              {showAccountFundings === item.externalAccountId && isNotNil(externalAccountsData?.getExternalAccounts) ?
                <div className="width-45 width-100-mobile m-left-30 m-0-mobile">
                  {isNotNil(walletFundingsData?.getWalletFundings) ?
                    <PageCard style={{ marginBottom: 20 }}>
                      {isNotNil(showAccountFundings) ?
                        <>{isNil(filteredFundings) ?
                          <Typography>No fundings made with bank account</Typography> :
                          <TableContainer
                            component={Paper}
                            style={{ marginTop: 20 }}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">Date</TableCell>
                                  <TableCell align="right">Amount</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filteredFundings.map((row) =>
                                  <TableRow
                                    key={row.amount}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row">{moment(Number(row.createdAt)).format("ll")}</TableCell>
                                    <TableCell align="right">{formatCurrency(row.amount)}</TableCell>
                                  </TableRow>,
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>}</>
                        : <></>}
                    </PageCard>
                    : <Typography>Wallet hasn&apos;t been funded yet!</Typography>}
                </div> : null}
            </div>,
          ) : <Typography>There&apos;s no external accounts connected to this wallet!</Typography>}
        </div>
      </div>
    </PageWrapper>
  );
});

export default withRouter(ExternalAccounts);