import React from "react";
import { Typography, TextField, MenuItem, useMediaQuery } from "@material-ui/core";
import { styled } from "@mui/material";
import COLORS from "../../../../common/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity } from "@fortawesome/pro-light-svg-icons";
import { Field, useFormikContext } from "formik";
import AccInput from "../../../../common/components/AccInput";
import { makeStyles } from "@material-ui/core/styles";
import { FundingFormValue, ExternalAccount, Wallet, FundingRequestTypes } from "../../../../common/types";
import { THEME_BREAKPOINTS } from "../../../../common/constants";

const useStyles = makeStyles(() => ({
  input: {
    background: "white",
    borderRadius: 16,
  },
  select: {
    background: "white",
    borderRadius: 16,
  },
}));

interface Props {
  externalAccount: Array<ExternalAccount> | undefined;
  walletData: Wallet | undefined;
  fundingRequestType?: FundingRequestTypes;
}

const FundAccount: React.FC<Props> = ({ externalAccount, walletData, fundingRequestType }) => {

  const classes = useStyles();

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const { values, setFieldValue } = useFormikContext<FundingFormValue>();

  return (
    <div className="column center">
      <Typography
        variant="h6"
        className="bold acc-white">
        {
          fundingRequestType === FundingRequestTypes.DEPOSIT ? "Fund Investment Account:" : "Withdraw from account:"
        }
        <span className="acc-lightgreen"> {walletData?.name}</span>
      </Typography>
      <Typography
        variant="subtitle2"
        className="light acc-white">
        {
          fundingRequestType === FundingRequestTypes.DEPOSIT
            ? "If you would like to transfer funds from a different bank account please click on the dropdown below."
            : "If you would like to withdraw funds to a different bank account please click on the dropdown below."
        }
      </Typography>
      <br />
      <StyledSelect
        onChange={(e: any) => {
          setFieldValue("selectedExternalAccount", e.target.value);
        }}
        InputProps={{
          className: classes.select,
        }}
        name="selectedExternalAccount"
        size="medium"
        margin="normal"
        variant="outlined"
        select
        style={{ width: mobileLayout ? "100%" : "80%" }}
        value={values.selectedExternalAccount}
        placeholder="Select Bank Account"
        label={<div style={styles.inputLabel}>Select Bank Account</div>}
      >
        {externalAccount?.map((item: ExternalAccount) => (
          <MenuItem
            disabled={!item.bankName}
            key={item.id}
            value={item.id}>
            <div
              className="row stretch center width-100"
              style={{ justifyContent: "flex-start" }}>
              <div className="row m-right-15">
                <FontAwesomeIcon
                  className="acc-green m-right-15"
                  size="lg"
                  icon={faUniversity}
                  style={{ position: mobileLayout ? "relative" : "initial", top: mobileLayout ? "15px" : "" }} />
                <div
                  className="row stretch"
                  style={{ display: mobileLayout ? "block" : "flex" }}>
                  <Typography
                    variant="subtitle1"
                    className="acc-gray bold">
                    {item.bankName} - {" "}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={mobileLayout ? "acc-gray bold" : "acc-gray bold m-left-10"}>
                    {item.displayName}
                  </Typography>
                </div>
              </div>
              <Typography
                variant="caption"
                className="acc-gray light">
                ****{item.lastFour}
              </Typography>
            </div>
          </MenuItem>
        ))}
      </StyledSelect>
      <br />
      <Typography
        variant="subtitle2"
        className="light acc-white">
        {
          fundingRequestType === FundingRequestTypes.DEPOSIT
            ? <p>Please input amount you would like to transfer to your <span className="acc-text acc-lightgreen">access</span><span className="bold">wallet</span></p>
            : "Please input amount you would like to transfer to your bank account"
        }
      </Typography>
      <Field
        required
        value={values.amount}
        onChange={(e: any) => {
          setFieldValue("amount", e.target.value);
        }}
        name="amount"
        size="small"
        as={AccInput}
        currencyMask
        fullWidth={false}
        InputProps={{
          inputProps: {
            style: {
              textAlign: "right",
            },
          },
          className: classes.input,
        }}
      />
    </div>
  );
};

const styles = {
  inputLabel: {
    backgroundColor: "white",
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 16,
  },
};

const StyledSelect = styled(TextField)({
  "& label.Mui-focused": {
    color: COLORS.darkBlue,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.green,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      padding: 0,
      borderRadius: 16,
      boxShadow: "0px 3px 6px #00000029",
    },
    "&.Mui-focused fieldset": {
      borderColor: COLORS.green,
    },
  },
  "& .MuiSelect-outlined": {
    borderRadius: 16,
  },
  "& .MuiOutlinedInput-input:-webkit-autofill": {
    borderRadius: 16,
  },
});

export default FundAccount;