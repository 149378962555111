// @ts-ignore
import { createContext } from "react";

export const AvatarMenuContext = createContext();
export const NotificationCenterContext = createContext();
export const SessionContext = createContext();
export const AuthContext = createContext();
export const LoadingContext = createContext();
export const NavPaneContext = createContext();
export const SignatureContext = createContext();
export const InvestmentContext = createContext();
