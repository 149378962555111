import { gql as gqlv2 } from "graphql-request";
import { makeGraphqlRequest } from "../../utils";

const GET_ADDRESS_TYPES_V2 = gqlv2`
  query GetAddressTypes {
    getAddressTypes {
     name
     id
     addressTypeId
    }
  }
`;

export const getAddressTypes = () => makeGraphqlRequest(GET_ADDRESS_TYPES_V2);