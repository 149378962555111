import { Typography } from "@material-ui/core";
import React from "react";
import Logo from "../../../../assets/images/illustrations/access_card_regular.svg";
import COLORS from "../../../../common/colors";

interface Props {
  description: string;
  date: string;
  containerStyles?: React.CSSProperties;
  button?: JSX.Element;
}

const NewMessage: React.FC<Props> = ({
  description, date, button,
}) => (
  <div
    className="column start"
    style={{ margin: "20px" }}>
    <div
      className="column center"
      style={{ width: "70%" }}>
      <div className="row end">
        <img
          alt="Logo"
          src={Logo}
          style={{ width: "30px", margin: "20px" }} />
        <br />
        <div className="width-100 center column">
          <Typography
            variant="subtitle1"
            className="acc-lightgray light">
            {date}
          </Typography>
          <div
            className="column start"
            style={{
              width: "100%", height: "fit-content", borderRadius: "16px", background: COLORS.darkBlueGradient, padding: "20px", margin: "20px",
            }}
          >
            <div
              className="column start stretch"
              style={{ width: "100%" }}>
              <Typography
                variant="subtitle2"
                className="acc-white light">
                {description}
              </Typography>
              <br />
              <div className="width-50">
                {button}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default NewMessage;
