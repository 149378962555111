import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import AccButton, { AccButtonColors } from "../../../../../common/components/AccButton";
import { formatCurrency, isNotNil } from "../../../../../common/utilities";
import { useQueryClient } from "react-query";
import { QueryKeys } from "../../../../../api/hooks/graphqlHooks";
import { Asset } from "../../../../../common/types";
import SuccessAnimation from "../../../../../assets/animations/success_4.json";
import lottie from "lottie-web";
import BrokenCard from "../../../../../assets/images/illustrations/access_404_page_illustration.png";
import COLORS from "../../../../../common/colors";
import { emailHrefLinks } from "../../../../../common/constants";

const Summary: React.FC = () => {
    const queryClient = useQueryClient();
    const data: { getAsset?: Asset } | undefined = queryClient.getQueryData(QueryKeys.ASSET);
    const investmentResultData: any | undefined = queryClient.getQueryData(QueryKeys.CREATE_INVESTMENT);
    const animationContainer = React.createRef<HTMLDivElement>();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current as HTMLDivElement,
            renderer: "svg",
            loop: false,
            autoplay: true,
            animationData: SuccessAnimation,
        });
        anim.setSpeed(1);
        return () => anim.destroy();
    }, []);


    return (
        <div className="row center">
            {isNotNil(investmentResultData) ?
                <>
                    <div
                        ref={animationContainer}
                        style={{ width: "40%", height: "550px" }}
                        className="display-none-mobile"
                    ></div>
                    <div
                        className="width-50 column start width-100-mobile"
                        style={{padding: "70px 10px 0px 10px" }}>
                        <Typography
                            variant="h4"
                            className="acc-darkblue bold">
                            Congratulations! <br />
                            You have successfully < br />
                            invested
                            <span
                                className="bold acc-green">
                                &nbsp;
                                {formatCurrency(investmentResultData?.amount!)}</span> in
                        </Typography>
                        <br />
                        <Typography
                            variant="h5"
                            className="acc-darkblue bold">
                        {data?.getAsset?.name}
                        </Typography>
                        <br />
                        <Typography
                            variant="subtitle1"
                            className="acc-darkgray light"
                        >We will notify you as soon as the funding of this opportunity has reached 100%.
                            If you have any questions about your investment please send us an email at
                            <a 
                            href={emailHrefLinks.supportRequest} 
                            className="acc-green"> support@accessinvestments.com</a>
                        </Typography>
                        <Link
                            to="/investor/portfolio"
                            className="width-50 width-100-mobile width-50-tablet m-top-30">
                            <AccButton
                                label="View Your Portfolio"
                                color={AccButtonColors.GREEN}
                            />
                        </Link>
                    </div>
                </>
                :
                <div
                    className="row center stretch width-100 m-top-50"
                    style={styles.errorContainer}>
                    <div className="width-50 column center">
                        <img
                            src={BrokenCard}
                            style={{ height: 400 }}
                        />
                    </div>
                    <div className="width-50">
                        <Typography
                            variant="h5"
                            className="acc-white width-60"
                        >
                            We are sorry for the inconvenience but your investment has failed. Please try again later.
                        </Typography>
                        <br />
                        <Link to="/investor/invest">
                            <AccButton
                                style={{ width: "fit-content" }}
                                label="View Opportunities"
                                color={AccButtonColors.GREEN}
                            />
                        </Link>
                    </div>
                </div>
            }
        </div>
    );
};

const styles = {
    errorContainer: {
        background: COLORS.darkBlueGradient,
        borderRadius: 16,
        padding: 55,
    },
};

export default Summary;