import * as CSS from "csstype";

export type CSSObject = { [key: string]: CSS.Properties };

export type LabelValueInput = {
  label: string;
  value: string;
};

export type Optional<T> = T | null | undefined;

export type PersonName = {
  firstName: string;
  middleName?: string;
  lastName: string;
  prefix?: string;
  suffix?: string;
  honorific?: string;
};

export type Address = {
  id: string;
  addressId: number;
  street1: string;
  street2: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  country: string;
  addressType: AddressType;
  account?: Account;
  displayName: string;
  addressRecipientName: string;
  accountObjectId: string;
  addressTypeObjectId: string;
};

export type PendingOnboardApplication = {
  entityName: string;
  walletName: string;
  accountObjectId: string;
  primaryApplicantUserObjectId: string;
  secondaryApplicantUserObjectId: string;
  primaryApplicant: User;
  secondaryApplicant: User;
  onboardStatus: OnboardStatuses;
  applicationType: string;
  submissionDate: string;
}

export type CommercialEntityType = {
  name: CommercialEntityTypes;
  requiredDocuments: string[];
  commercialEntityTypeId: string
  commercialEntityTypeObjectId: string
}

export type AccreditationStatus = {
  id: string
  name: string,
  accreditationStatusId: number,
}

export type AddressType = {
  id: string;
  addressTypeId: number;
  name: string;
};

export type CashFlow = {
  cashFlowId: number;
  assetObjectId: string;
  cashFlowInputArray: number[];
}

export interface RegistrationFormValue {
  firstName: string;
  middleName: string;
  lastName: string;
  address: Address | null;
  email: string | null;
  phone: string | null;
  companyOfficerName: Partial<PersonName> | null;
  companyOfficerTitle: string | null;
  companyOfficerPhone: string | null;
  businessAddress: Address | null;
  userTypeName: string;
  accountType: string | null;
  verticalInterests: Array<string>;
  incomeOver200k: boolean | null;
  isMarried: boolean | null;
  jointIncomeOver300k: boolean | null;
  netWorthOver1m: boolean | null;
  investmentEntityOver5m: boolean | null;
  entityName: string;
  entityType: string;
  entityCreationDate: string;
  entitynumberOfEmployees: number;
  ein?: string;
  umbCIDResponses: string;
  umbCDDResponses: string;
  umbW9Responses: string;
  umbECNSENTResponses: string;
  CRDNumber: string;
  firmCRDNumber: string;
  birthday: string | null;
};

export interface UserRegistrationRequest {
  email: string;
  firstName: string;
  lastName: string;
  middleName: string;
  accountObjectId?: string;
  userTypeName?: string;
  userTypeObjectId?: string;
  address?: any;
  password?: string;
  phone?: string;
  CRDNumber?: string,
  firmCRDNumber?: string,
}

export interface InvestmentFormValue {
  investmentAmount: null | undefined | number;
  isProfileInfoCorrect: boolean;
  confirmedAccreditedInvestor: boolean;
  confirmedDisqualifyingEvent: boolean;
  confirmedInvestmentProcedures: boolean;
};

export interface FundingFormValue {
  amount: number | null;
  selectedExternalAccount: string | null;
};

export interface AccreditedInvestorRequest {
  id: String;
  createdAt: String;
  updatedAt: String;
  walletObjectId: string;
  status: string;
  accreditationType: string;
  northCapitalLinkIds: string;
  northCapitalAccountId: string;
  airequestId: string,
  notes: string,
}

export type Account = {
  id: string;
  accountId: number;
  name: string;
  status: string;
};

export type WalletType = {
  id: string;
  walletTypeId: number;
  name: string;
};

export type WalletStatus = {
  id: number;
  walletStatusId: number;
  name: string;
};

export type UserType = {
  id: string;
  userTypeId: number;
  name: string;
};

export type DealType = {
  dealTypeId: number;
  id: string;
  name: string;
  taxDocumentType: string;
  generalPartner: string;
  lawFirm: string;
  offeringEntity: string;
  disqualifyingEvent: string;
  requiresAccreditation: boolean;
  helloSignTemplateId: string;
  investmentProceduresDocumentLocation: string;
  accreditationConfirmation?: string;
  disqualifyingEventDocumentLocation: string;
};

export type Asset = {
  id: string;
  assetId: number;
  name: string;
  summary: string;
  tabDescriptions: Tab[];
  targetedIRR: number;
  targetedAverageCashYield: number;
  targetedMOIC: number;
  targetedMaturity: string;
  targetedFundingDate: string;
  targetedInvestmentPeriod: number;
  totalInvestmentAmount: number;
  investmentJurisdiction: string;
  marketType: string;
  remainingAmount: number;
  minimumInvestmentAmount: number;
  netAssetValue: number;
  investmentAmountIncrement: number;
  videoId: string;
  managementCompany: string;
  investmentStructure: string;
  coverImageLocation: string;
  availableAmount: number;
  displayImages: string[];
  assetDocuments: string[];
  ppmDocumentLocation: string;
  advDocumentLocation: string;
  lpaDocumentLocation: string;
  backupInvestmentDocumentLocation: string;
  authorizedInvestors: string[];
  spvAccountNumber: string;
  spvRoutingNumber: string;
  assetType: AssetType;
  dealType: DealType;
  assetPartner: AssetPartner;
  assetStatus: AssetStatus;
  assetManagerUser: User;
  createdAt: string;
};

export type Tab = {
    title: string;
    content: string;
}

export type AssetClass = {
  id: string;
  assetClassId: number;
  name: string;
  vertical: Vertical;
};

export type AssetItems = {
  id: string;
  assetItemId: number;
  name: string;
  assetType: AssetType;
  asset: Asset;
};

export type AssetPartner = {
  id: string;
  assetPartnerId: number;
  name: string;
  description: string;
  title: string;
  coverLogoImageLocation: string;
  website: string;
};

export type AssetStatus = {
  id: string;
  assetStatusId: number;
  name: string;
};

export type AssetType = {
  id: string;
  assetTypeId: number;
  name: string;
  assetClass: AssetClass;
};

export type Investment = {
  id: string;
  investmentId: number;
  name: string;
  amount: number;
  status: string;
  type: string;
  wallet: Wallet;
  asset: Asset;
  createdAt: string;
  committedDate: string;
};

export type User = {
  id: string;
  userId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  password: string;
  phone: number;
  account: Account;
  userType: UserType;
  accountObjectId: string;
  userTypeObjectId: string;
  status: string;
  linkedInUrl?: string;
  avatarImageLocation?: string;
  jobTitle?: string;
  CRDNumber?: string,
  firmCRDNumber?: string,
  createdAt: string,
};

export type Vertical = {
  id: string;
  verticalId: number;
  name: string;
};

export type Wallet = {
  id: string;
  walletId: number;
  name: string;
  account: Account;
  accountHolderFullName: string;
  availableAmount: number;
  walletType: WalletType;
  walletStatus: WalletStatus;
  umbAccountId: string;
  externalAccounts?: ExternalAccount[];
  createdAt: string;
  accountObjectId: string,
  // TODO: Make this required after flushing old data
  primaryUser?: User;
  secondaryUser?: User;
};

export type DocumentSignature = {
  id: string;
  helloSignReferenceId: string;
  userId: number;
  investmentId: number;
  downloadURL: string;
};

export type ExternalAccount = {
  id: string;
  displayName: string;
  lastFour: string;
  bankName: string;
  walletObjectId: string;
  externalAccountId: string;
  externalAccountObjectId: string;
};

export type WalletActivity = {
  amount: number;
  createdAt: string;
  status: string;
  externalAccount: ExternalAccount;
  fundingRequestType: FundingRequestType;
}

export type FundingRequestType = {
  id: string;
  name: string;
  fundingRequestTypeId: number;
}

export type FundingRequestDetail = {
  id: string;
  fundingRequestId: number;
  amount: number;
  status?: string;
  movementId?: number;
  fundingRequestType?: FundingRequestType;
  fundingRequestTypeObjectId?: string;
  fundingRequestTypeName?: string;
  walletObjectId: string;
  wallet?: Wallet;
  externalAccount?: ExternalAccount;
  externalAccountObjectId: string;
}

export type UserPortfolioInvestment = {
  walletId: number;
  walletName: string
  walletObjectId: string
  walletAvailableAmount: number
  walletStatusObjectId: string
  investments: Investment[]
}

export type UserAccountTotal = {
  committedAndActiveInvestmentsCounter: number
  committedAndActiveInvestmentsSum: number
  totalInvestmentsToDateCounter: number
  totalInvestedByAccount: number
  availableBalanceToInvest: number
}

export enum UploadTypes {
  DISPLAY_IMAGES = "DISPLAY_IMAGES",
  COVER_IMAGE = "COVER_IMAGE",
  AVATAR = "AVATAR",
  DOCUMENTS = "DOCUMENTS",
  MEMORANDUM = "MEMORANDUM",
  ADV = "ADV",
  LPA = "LPA",
  DISQUALIFYING_EVENT_DOCUMENT = "DISQUALIFYING_EVENT_DOCUMENT",
  INVESTMENT_PROCEDURES = "INVESTMENT_PROCEDURES",
  BACKUP_INVESTMENT_DOCUMENT = "BACKUP_INVESTMENT_DOCUMENT",
}

export enum AccountBalanceTypes {
  AVAILABLE = "available",
  CURRENT = "current",
  TOTAL_INVESTED = "totalInvested"
}

export enum UserTypes {
  ADMIN = "ADMIN",
  ASSET_MANAGER = "ASSET MANAGER",
  INVESTOR = "INVESTOR",
  ADVISOR = "ADVISOR",
}

export enum AddressTypes {
  PRIMARY = "PRIMARY"
}

export const InvestmentStatus = [
  { key: "ATTEMPTED (Investment)", value: "ATTEMPTED" },
  { key: "COMMITTED (Investment)", value: "COMMITTED" },
  { key: "ACTIVE (Investment)", value: "ACTIVE" },
  { key: "COMPLETED (Investment)", value: "COMPLETED" },
  { key: "PENDING (Distribution)", value: "PENDING" },
  { key: "SUBMITTED (Distribution)", value: "SUBMITTED" },
];

export const InvestmentTypeOptions = [
  { key: "INVESTMENT", value: "INVESTMENT" },
  { key: "DISTRIBUTION", value: "DISTRIBUTION" },
];

export enum InvestmentAmounts {
  MAX_AMOUNT_TO_INVEST = 9999999
}
export enum FundingRequestStatuses {
  PENDING = "PENDING"
}

export enum FundingRequestTypes {
  DEPOSIT = "DEPOSIT",
  WITHDRAWAL = "WITHDRAWAL",
}

export const FundingRequestStatus = [
  { key: "PENDING", value: "PENDING" },
  { key: "COMMITTED", value: "COMMITTED" },
];

export enum CommercialEntityTypes {
  CORPORATION = "Corporation",
  LLC = "Limited Liability Company",
  GENERAL_PARTNERSHIP = "General Partnership",
  PARTNERSHIP = "Limited Partnership / Limited Liability Partnership",
  JOINT_VENTURE = "Joint Venture",
  SOLE = "Sole Proprietorship",
}

export enum InvestmentStatuses {
  ATTEMPTED = "ATTEMPTED",
  COMMITTED = "COMMITTED",
}

export enum GoogleAnalyticsEventTags {
  LOGIN = "login",
};

export enum OnboardStatuses {
  FAILURE = "FAILURE",
  PENDING_REVIEW = "PENDING_REVIEW",
  PENDING_JOINT_APPLICANT = "PENDING_JOINT_APPLICANT",
}
export enum TermsAndConditions {
  SUPPLEMENTAL_AGREEMENTS = "supplementalAgreements",
  PRIVACY_POLICY = "privacyPolicy",
  GENERAL_TERMS = "generalTerms",
}