import { gql as gqlv2 } from "graphql-request";
import { makeGraphqlRequest } from "../../utils";

const GET_FUNDING_REQUEST_TYPE_V2 = gqlv2`
  query GetFundingRequestType($fundingRequestTypeId: Int!){
    getFundingRequestType(fundingRequestTypeId: $fundingRequestTypeId) {
      id
      fundingRequestTypeId
      name
    }
  }
`;

export const getFundingRequestType = (fundingRequestTypeId: number) => makeGraphqlRequest(GET_FUNDING_REQUEST_TYPE_V2, { fundingRequestTypeId });

const GET_FUNDING_REQUEST_TYPES_V2 = gqlv2`
  query GetFundingRequestTypes {
    getFundingRequestTypes {
     name
     id
     fundingRequestTypeId
    }
  }
`;

export const getFundingRequestTypes = () => makeGraphqlRequest(GET_FUNDING_REQUEST_TYPES_V2);