import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";
import { GoogleAnalyticsEventTags } from "../types";

interface GTMDataLayerPushParams {
  event: GoogleAnalyticsEventTags;
  [key: string]: string;
}

class GoogleAnalytics {
  constructor() {
    this.initialize();
  }

  initialize = () => {
    ReactGA.initialize("G-FHT031F5LV");
    TagManager.initialize({
      gtmId: "GTM-K97VTP9",
    });

    "GTM-K97VTP9";
  };

  public pushGTMDataLayerEvent = ({ event, ...args }: GTMDataLayerPushParams) => {
    ReactGA.event({
      action: event,
      label: event,
      category: event,
      ...args,
    });
    TagManager.dataLayer({
      dataLayer: {
        event: event,
        ...args,
      },
    });
  };
}

export default new GoogleAnalytics();