import React from "react";
import COLORS from "../../../../common/colors";
import { Link } from "react-router-dom";
import { faWallet } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AccButton, { AccButtonColors } from "../../../../common/components/AccButton";
import { useMediaQuery } from "@mui/material";
import { THEME_BREAKPOINTS } from "../../../../common/constants";


interface Props {
  name: string | React.ReactFragment;
  isPreviouslyCommittedInvestment?: boolean;
  walletId: number;
}


const WalletDocument: React.FC<Props> = ({
  name,
  isPreviouslyCommittedInvestment,
  walletId,
}) => {
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  const renderLabelButton = () => (
    isPreviouslyCommittedInvestment ? "View" : "access"
  );

  return (
    <Link
      to={
        `/investor/accounts/accountstatements/${walletId}`
      }
      className="row center stretch width-100 m-bottom-30"
      style={{ ...styles.background }}
    >
      <div
        className="center"
        style={{ width: mobileLayout ? "" : "-webkit-fill-available", paddingLeft: "20px" }}
      >
        <div
          className="row center stretch column-mobile width-100"
          style={{ justifyContent: "stretch" }}>
          <div
            className="row">
            <FontAwesomeIcon
              className="acc-lightgreen"
              style={{
                fontSize: 30,
                marginRight: mobileLayout ? 15 : 25,
              }}
              icon={faWallet}
            />
            <div>
              <span
                className="acc-white bold"
                style={{ fontSize: "20px" }}>
                {name}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row start">
        <AccButton
          label={renderLabelButton()}
          className="bold"
          color={AccButtonColors.GREEN}
          style={{
            cursor: "pointer",
            borderRadius: 16,
            backgroundColor: COLORS.lightGreen,
            fontSize: "12px",
            width: mobileLayout ? "100px" : "110px",
            height: "35px",
            borderStyle: "none",
            fontFamily: isPreviouslyCommittedInvestment ? undefined : "Gilroy",
          }}
        />
      </div>
    </Link >
  );
};

const styles = {
  background: {
    background: COLORS.darkBlueGradient,
    borderRadius: 16,
    height: "85px",
    paddingRight: "25px",
  },
};

export default WalletDocument;
