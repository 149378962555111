import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { getSignedDocumentUrl, GetSignedDocumentURLResponse, getTemplateFileUrl, GetTemplateFilesUrlResponse } from "../rest/documents";
import { getArtifact, getListArtifacts, getUMBAccount } from "../rest/umb";

export enum QueryKeys {
  LIST_ARTIFACTS = "listArtifacts",
  ARTIFACT = "artifact",
  UMB_ACCOUNT = "umbAccount",
  HELLO_SIGN_DOCUMENT_URL = "helloSignDocumentUrl",
  HELLO_SIGN_TEMPLATE_FILES_URL = "helloSignTemplateFilesUrl",
};

// Define default config options here for all queries
const applyConfiguration = (config: UseQueryOptions<any> | undefined): Omit<UseQueryOptions<any>, "queryKey" | "queryFn"> => ({
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  ...(config),
});

// UMB
export const useUMBAccount = <T extends any = any>(
  umbAccountId: string,
  config?: UseQueryOptions<T, unknown, any>,
): UseQueryResult<any> =>
  useQuery(QueryKeys.UMB_ACCOUNT, () => getUMBAccount(umbAccountId), applyConfiguration(config));

export const useListArtifacts = <T extends any = any>(
  umbAccountId: string,
  startDate: string,
  endDate: string,
  config?: UseQueryOptions<T, unknown, any>,
): UseQueryResult<any> =>
  useQuery(QueryKeys.LIST_ARTIFACTS, () => getListArtifacts(umbAccountId, startDate, endDate), applyConfiguration(config));

export const useArtifact = <T extends any = any>(
  artifactId: string,
  config?: UseQueryOptions<T, unknown, any>,
): UseQueryResult<any> =>
  useQuery(QueryKeys.ARTIFACT, () => getArtifact(artifactId), applyConfiguration(config));


// HELLO SIGN
export const useHelloSignDocumentUrl = <T extends any = GetSignedDocumentURLResponse>(
  investmentObjectId: string,
  assetObjectId: string,
  userAddress: string,
  config?: UseQueryOptions<T, unknown, GetSignedDocumentURLResponse>,
): UseQueryResult<GetSignedDocumentURLResponse> =>
  useQuery(
    QueryKeys.HELLO_SIGN_DOCUMENT_URL, () => getSignedDocumentUrl(investmentObjectId, assetObjectId, userAddress), applyConfiguration(config),
  );

export const useHelloSignTemplateFilesUrl = <T extends any = GetTemplateFilesUrlResponse>(
  templateId: string,
  config?: UseQueryOptions<T, unknown, GetTemplateFilesUrlResponse>,
): UseQueryResult<GetTemplateFilesUrlResponse> =>
  useQuery(
    QueryKeys.HELLO_SIGN_TEMPLATE_FILES_URL, () => getTemplateFileUrl(templateId), applyConfiguration(config),
  );