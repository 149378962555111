import Divider from "@mui/material/Divider";
import React from "react";
import { Link } from "react-router-dom";
import { useAccounts } from "../../../api/hooks/graphqlHooks";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { DataGridStyles, renderEditCell } from "../Assets";
import { AccountStatus } from "../../../enums";
import CustomTooltip from "../../../common/components/CustomTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import COLORS from "../../../common/colors";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import { useMediaQuery } from "@mui/material";
import PageWrapper from "../../../common/components/PageWrapper";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";


interface Props {

}

const Accounts: React.FC<Props> = withErrorBoundary({}, () => {

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  const { data, isLoading, isFetching, isError } = useAccounts();

  const columns: GridColDef[] = [
    renderEditCell("/admin/accounts/"),
    {
      field: "status", headerName: "Status", width: 100, hide: mobileLayout, renderCell: (item) => {
        return <CustomTooltip
          title={
            item?.row?.status === AccountStatus.OPEN ? (<span>OPEN</span>) : (<span>CLOSED</span>)
          }
          background={COLORS.darkBlue}
          color={COLORS.green}
          placement="right"
          fontSize={16}
          fontWeight={600}>
          <FontAwesomeIcon
            className={item?.row?.status === AccountStatus.OPEN ? "acc-green" : "acc-red"}
            icon={faCircle}
            style={{ marginLeft: 20, fontSize: 12 }} />
        </CustomTooltip>;
      },
    },
    { field: "accountId", headerName: "Account ID", width: 150 },
    { field: "name", headerName: "Name", width: 400 },
  ];

  const rows = data?.getAccounts?.map(row =>
  ({
    ...row,
    name: row.name,
    id: row.accountId,
    status: row.status,
  }),
  );

  return (
    <PageWrapper
      title="Accounts"
      showLoader={isLoading || !data?.getAccounts || isFetching}
      showError={isError}
      errorMessage="Cannot retrieve accounts"
    >
      <div style={{ height: "80vh", width: "100%" }}>
        <div className="row-mobile stretch">
          <div style={{ justifyContent: "flex-start" }}>
          </div>
          <div
            className="row"
            style={{ justifyContent: "flex-end" }}>
            <Link to="/admin/accounts/add">
              <AccButton
                color={AccButtonColors.GREEN}
                label="Add"
              />
            </Link>
          </div>
        </div>
        <br />
        <Divider />
        <br />
        <DataGrid
          rows={rows || []}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            panel: DataGridStyles.panel,
          }}
          sx={DataGridStyles.toolBar}
        />
      </div>
    </PageWrapper>
  );
});

export default Accounts;
