import { standardErrorHandle } from "../../../common/utilities";
import { toast } from "react-toastify";
import { OnboardingRequest } from "./types";
import { handleApiResponse } from "../../utils";
import restClient from "../restClient";

const onboardingProcess = async (account: OnboardingRequest) => {
  try {
    const result = await restClient.put(
      "/north-capital/onboarding-process", account,
    );
    return handleApiResponse(result);
  } catch (err: any) {
    standardErrorHandle(err, "Error on North Capital Onboarding Process");
  }
};


const accreditationVerificationProcess = async (accreditation: FormData) => {
  try {
    const result = await restClient.post(
      "/north-capital/accreditation", accreditation,
    );
    return handleApiResponse(result);
  } catch (err: any) {
    standardErrorHandle(err, "Error on North Capital Accreditation Process");
  }
};

const updateAccreditationVerificationProcess = async (updatedAccreditation: FormData) => {
  try {
    const result = await restClient.put(
      "/north-capital/accreditation", updatedAccreditation,
    );
    if (result.data.success) {
      toast(result.data.msg || "Accreditation Verification Request updated", {
        type: toast.TYPE.SUCCESS,
      });
      return result;
    } else if (result.data.error) {
      toast(result.data.message, {
        type: toast.TYPE.ERROR,
      });
    }
  } catch (err: any) {
    standardErrorHandle(err, "Error updating North Capital Accreditation Process");
  }
};

export { onboardingProcess, accreditationVerificationProcess, updateAccreditationVerificationProcess };