import React from "react";
import { UMBFormValues, UMBIdTypes, UMBYesNoValues } from "../common/umb.interfaces";
import AccInput from "../../../../common/components/AccInput";
import { isNotNil } from "../../../../common/utilities";
import { COUNTRIES, US_STATES } from "../../../../common/constants";
import AccOptionSelect from "../../../../common/components/AccInput/variants/AccOptionSelect";
import { formSelectOptions } from "../common/umb.consts";
import COLORS from "../../../../common/colors";
import { FormControlLabel, MenuItem, Typography } from "@material-ui/core";
import { StyledInput, StyledRadio } from "../components/ThemedComponents";
import { useFormikContext } from "formik";


export const ConsumerIdentificationForm = () => {

  const { values, handleChange, errors, touched } = useFormikContext<UMBFormValues>();

  return (
    <>
      <Typography
        style={{ marginBottom: 30, textAlign: "center" }}
        variant="h5"
        className="acc-white bold">Please select type of Identification to confirm your identity:</Typography>
      <StyledInput
        margin="dense"
        as={AccInput}
        autoFocus
        className="width-100-mobile"
        style={{ width: "30%" }}
        name="identificationType"
        value={values.identificationType}
        onChange={handleChange}
        label="Select Identification Type"
        select
      >
        {formSelectOptions?.identificationType
          ?.filter(item => values.isUSCitizen === UMBYesNoValues.YES ? item.value !== UMBIdTypes.GREEN_CARD : item)
          .map(item =>
            <MenuItem
              key={item.value}
              value={item.value}>
              {item.label}
            </MenuItem>)}
      </StyledInput>
      {isNotNil(values.identificationType) && (
        <div className="row width-100 m-top-30 column-mobile">
          <div className="column end m-right-50 width-50 width-100-mobile m-0-mobile">
            <div className="width-60 end width-100-mobile">
              <Typography
                style={{ marginBottom: 10 }}
                variant="subtitle2"
                className="acc-white bold">
                Please provide the identification expiration date
              </Typography>
              <StyledInput
                margin="dense"
                as={AccInput}
                name="documentExpirationDate"
                type="date"
                value={values.documentExpirationDate}
                onChange={handleChange}
                label="Expiration Date"
                helperText={errors.documentExpirationDate && touched.documentExpirationDate ? errors.documentExpirationDate : null}
                error={errors.documentExpirationDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            {(values.identificationType !== UMBIdTypes.GREEN_CARD) &&
              <div className="width-60 end m-top-30 width-100-mobile">
                <Typography
                  style={{ marginBottom: 10 }}
                  variant="subtitle2"
                  className="acc-white bold">
                  Please provide the identification number
                </Typography>
                <StyledInput
                  margin="dense"
                  as={AccInput}
                  name="identificationNumber"
                  value={values.identificationNumber}
                  onChange={handleChange}
                  label="Identification Number"
                />
              </div>}
            {(
              values.identificationType === UMBIdTypes.DRIVERS_LICENSE ||
              values.identificationType === UMBIdTypes.STATE_ID
            ) && (
                <div className="width-60 end m-top-30 width-100-mobile">
                  <Typography
                    style={{ marginBottom: 10 }}
                    variant="subtitle2"
                    className="acc-white bold">
                    If DL/State issued, please select the issuing state
                  </Typography>
                  <StyledInput
                    margin="dense"
                    as={AccOptionSelect}
                    name="driversLicenseIssuingState"
                    value={values.driversLicenseIssuingState}
                    onChange={handleChange}
                    label="Issuing State"
                    options={US_STATES}
                  />
                </div>
              )}
            {(values.identificationType === UMBIdTypes.PASSPORT) && (
              <div className="width-60 end m-top-30 width-100-mobile">
                <Typography
                  style={{ marginBottom: 10 }}
                  variant="subtitle2"
                  className="acc-white bold">
                  If Passport, please select the issuing country
                </Typography>
                <StyledInput
                  margin="dense"
                  as={AccOptionSelect}
                  name="passportCountry"
                  value={values.passportCountry}
                  onChange={handleChange}
                  label="Passport Issuing Country"
                  options={COUNTRIES}
                />
              </div>
            )}
            {(values.identificationType === UMBIdTypes.GREEN_CARD) && (
              <>
                <div className="width-60 end m-top-30 width-100-mobile">
                  <Typography
                    style={{ marginBottom: 10 }}
                    variant="subtitle2"
                    className="acc-white bold">
                    Please provide USCIS #
                  </Typography>
                  <StyledInput
                    margin="dense"
                    as={AccInput}
                    name="USCISNumber"
                    value={values.USCISNumber}
                    onChange={handleChange}
                    label="USCIS Number"
                  />
                </div>
                <div className="width-60 end m-top-30 width-100-mobile">
                  <Typography
                    style={{ marginBottom: 10 }}
                    variant="subtitle2"
                    className="acc-white bold">
                    Please provide green card category
                  </Typography>
                  <StyledInput
                    margin="dense"
                    as={AccInput}
                    name="greenCardCategory"
                    value={values.greenCardCategory}
                    onChange={handleChange}
                    label="Green Card Category"
                  />
                </div>
              </>
            )}
          </div>
          <div className="column m-left-50 width-50 start width-100-mobile m-left-0-mobile m-top-15-mobile">
            {(values.identificationType !== UMBIdTypes.GREEN_CARD) &&
              <div className="m-bottom-30">
                <Typography
                  style={{ marginBottom: 10 }}
                  variant="subtitle2"
                  className="acc-white bold width-80 width-100-mobile">Does the address on your identification match the address you provided?</Typography>
                {formSelectOptions.yesNoNaOptions.map(option => (
                  <FormControlLabel
                    key={option.value}
                    style={{ color: COLORS.white }}
                    onChange={handleChange}
                    name="doesAddressMatchIdentification"
                    value={option.value}
                    control={<StyledRadio />}
                    label={option.label}
                    checked={values.doesAddressMatchIdentification === option.value} />
                ))}
              </div>}
            {values.doesAddressMatchIdentification === UMBYesNoValues.NO && (
              <div className="width-60 width-100-mobile m-bottom-15">
                <Typography
                  style={{ marginBottom: 10 }}
                  variant="subtitle2"
                  className="acc-white bold width-80 width-100-mobile">Reason for the difference between id and provided address</Typography>
                <StyledInput
                  margin="dense"
                  as={AccInput}
                  name="reasonAddressDoesNotMatchIdentification"
                  value={values.reasonAddressDoesNotMatchIdentification}
                  onChange={handleChange}
                  label="Reason"
                />
              </div>
            )}
            <div>
              <Typography
                style={{ marginBottom: 10 }}
                variant="subtitle2"
                className="acc-white bold width-80 width-100-mobile">Does the name on your identification match the name you provided?</Typography>
              {formSelectOptions.yesNoNaOptions.map(option => (
                <FormControlLabel
                  key={option.value}
                  style={{ color: COLORS.white }}
                  onChange={handleChange}
                  name="doesNameMatchIdentification"
                  value={option.value}
                  control={<StyledRadio />}
                  label={option.label}
                  checked={values.doesNameMatchIdentification === option.value} />
              ))}
            </div>
            {values.doesNameMatchIdentification === UMBYesNoValues.NO && (
              <div className="width-60 width-100-mobile">
                <Typography
                  style={{ marginBottom: 10, marginTop: 20 }}
                  variant="subtitle2"
                  className="acc-white bold width-80 width-100-mobile">Reason for the difference between your legal and identification names</Typography>
                <StyledInput
                  margin="dense"
                  as={AccInput}
                  name="reasonNameDoesNotMatchIdentification"
                  value={values.reasonNameDoesNotMatchIdentification}
                  onChange={handleChange}
                  label="Reason"
                />
              </div>
            )}
            {values.doesNameMatchIdentification === UMBYesNoValues.NO && (
              <div className="width-60 width-100-mobile">
                <Typography
                  style={{ marginBottom: 10, marginTop: 20 }}
                  variant="subtitle2"
                  className="acc-white bold width-80 width-100-mobile">Please enter the name as it is on your identification</Typography>
                <StyledInput
                  margin="dense"
                  as={AccInput}
                  name="nameOnIdentification"
                  value={values.nameOnIdentification}
                  onChange={handleChange}
                  label="Name on ID"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>);
};
