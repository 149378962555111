import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import AccButton, { AccButtonColors } from "../../common/components/AccButton";
import PageWrapper from "../../common/components/PageWrapper";
import { Divider, Typography } from "@material-ui/core";
import { isNil } from "../../common/utilities";
import { QueryKeys, useUserWallets } from "../../api/hooks/graphqlHooks";
import BankAccountCard from "../../common/components/BankAccountCard";
import { WalletStatus } from "../../enums";
import { useQueryClient } from "react-query";
import { useMutation } from "@apollo/client";
import { DELETE_EXTERNAL_ACCOUNT } from "../../api/query/ExternalAccounts";
import { toast } from "react-toastify";
import CancelConfirmDialogModal from "../../common/components/CancelConfirmDialogModal";
import { ExternalAccount } from "../../common/types";
import { withErrorBoundary } from "../../common/components/ErrorBoundary";

type WalletParams = {
};

type WalletProps = RouteComponentProps<WalletParams>;

const Wallet: React.FC = withErrorBoundary({}, ({ history }: WalletProps) => {
  const queryClient = useQueryClient();

  const { data: userWalletsData, isFetching: userWalletsFetching, isLoading: userWalletsLoading } = useUserWallets({
    refetchOnMount: true,
  });
  const [deleteExternalAccount] = useMutation(DELETE_EXTERNAL_ACCOUNT);
  const [externalAccountObjectId, setExternalAccountObjectId] = useState<string | null>(null);
  const [isDeleteExternalAccountModalVisible, setIsDeleteExternalAccountModalVisible] = useState<boolean>(false);
  const openAccountsData = userWalletsData?.getUserWallets?.filter(item => item.walletStatus.name === WalletStatus.OPEN);

  const handleDeleteExternalAccount = async (objectId: string | number) => {
    try {
      await deleteExternalAccount({
        variables: {
          id: objectId,
        },
      });
      toast("External Account deleted successfully!", {
        type: toast.TYPE.SUCCESS,
      });
      queryClient.refetchQueries(QueryKeys.USER_WALLETS);
      setIsDeleteExternalAccountModalVisible(false);
    } catch (err: any) {
      toast(err.message || err.msg || err.toString() || "There was an error deleting external account.", {
        type: toast.TYPE.ERROR,
      });
    }
  };

  useEffect(() => {
    if (window.location.href.includes("wallet?oauth_state_id=")) {
        let walletId = localStorage.getItem("walletId");
        const oauthStateId = window.location.href.slice(window.location.href.indexOf("?"), window.location.href.length);
        history.push(`/investor/wallet/connectbankaccount/${walletId}${oauthStateId}`);
    }
  }, []);

  return (
    <PageWrapper
      title="Bank Accounts"
      subtitle="Connect securely your external bank accounts to fund your wallets."
      showLoader={userWalletsFetching || userWalletsLoading}
    >
      <br />
      <br />
      {isNil(userWalletsData?.getUserWallets) || isNil(openAccountsData) ?
        <>
          <Typography
            variant="h6"
            className="acc-darkblue bold"
          >Before you can connect a bank account, please create an investment account.</Typography>
          <Link to="/investor/accounts/onboard">
            <AccButton
              label="Create Account"
              color={AccButtonColors.GREEN}
              style={{ width: 180 }}
              className="m-top-30"
            /></Link>
        </>
        :
        <div className="column end width-100">
          {openAccountsData.map(item =>
            <>
              <br />
              <div className="width-100">
                <div
                  key={item.id}
                  className="m-bottom-50">
                  <Typography
                    className="acc-darkblue bold"
                    variant="h5">
                    {item.name}&nbsp;
                    {<span
                      className="acc-lightgray light"
                      style={{ fontSize: 18 }}>connected bank accounts</span>}
                  </Typography>
                  <br />
                  <div className="width-70 width-100-mobile">
                    {item.externalAccounts?.length === 0
                      ? <Typography>
                        {isNil(item.umbAccountId)
                          ? "You haven't completed your account setup!"
                          : "You don't have any bank accounts connected to this investment account."}
                      </Typography>
                      : item.externalAccounts?.map((item: ExternalAccount) =>
                        <BankAccountCard
                          deleteButtonVisible
                          key={item.id}
                          data={item}
                          deleteOnClick={() => {
                            setIsDeleteExternalAccountModalVisible(true);
                            setExternalAccountObjectId(item.id);
                          }}
                        />)}
                    <Link
                      to={isNil(item.umbAccountId)
                        ? `accounts/onboard?type=${item.walletType.name}&name=${item.name}`
                        : `wallet/connectbankaccount/${item.walletId}`}>
                      <Typography
                        style={{ marginTop: 10, textDecoration: "underline", cursor: "pointer" }}
                        className="acc-green">
                        {isNil(item.umbAccountId)
                          ? "Complete account setup"
                          : "Connect bank account"}
                      </Typography>
                    </Link>
                    <br />
                    <Divider />
                  </div>
                </div>
              </div>
            </>,
          )}
          <CancelConfirmDialogModal
            isVisible={isDeleteExternalAccountModalVisible}
            dialogHeader="Are you sure you'd like to delete external account?"
            cancelButtonText="No"
            confirmButtonText="Yes"
            onClickCancel={() => setIsDeleteExternalAccountModalVisible(false)}
            onClickDelete={() => handleDeleteExternalAccount(externalAccountObjectId!)}
          />
        </div>
      }
    </PageWrapper>
  );
});

export default withRouter(Wallet);