
export const patriotAct = [
  {
    header: "What this means for you?",
    content: ["The purpose of the USA PATRIOT Act is to deter and punish terrorist acts in the United States and around the world, to enhance law enforcement investigatory tools, and other purposes, some of which include:",
      "• To strengthen U.S. measures to prevent, detect and prosecute international money laundering and financing of terrorism;",
      "• To subject to special scrutiny foreign jurisdictions, foreign financial institutions, and classes of international transactions or types of accounts that are susceptible to criminal abuse;",
      "• To require all appropriate elements of the financial services industry to report potential money laundering;",
      "• To strengthen measures to prevent use of the U.S. financial system for personal gain by corrupt foreign officials and facilitate repatriation of stolen assets to the citizens of countries to whom such assets belong."],
  },
  {
    header: "Customer Identification Program Policy Statement",
    content: ["Important information about procedures for opening a new account",
      "To help the government fight the funding of terrorism and money-laundering activities, Federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account."],
  },
  {
    header: "What this means for you?",
    content: ["When you open an account, we will ask for your name, physical address, date of birth, and other information that will allow us to identify you.",
      "We may also ask to see your driver's license or other identifying documents."],
  },
];

export const W9Instructions = [
  {
    header: "Purpose of Form",
    description: "Form W-9 is required to obtain your correct taxpayer identification number (TIN), which may be your social security number (SSN) or Employer Identification Number (EIN). Any income earned may be reported to you on a Form 1099.",
    link: "https://www.irs.gov/pub/irs-pdf/fw9.pdf",
  },
  {
    header: "U.S. Citizen or U.S. Person",
    description: "For federal tax purposes, you are considered a U.S. person if you are an individual who is a U.S. citizen or U.S. resident alien.",
  },
  {
    header: "FATCA Reporting",
    description: "This deposit account is established and maintained the United States; thus, a FATCA exemption code does not apply.",
  },
];


export const taxStatements = [
  {
    number: 1,
    description: "The number shown on this form is my correct taxpayer identification number; and",
  },
  {
    number: 2,
    description: "I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and",
  },
  {
    number: 3,
    description: "I am a U.S. Citizen or other U.S. person; and",
  },
  {
    number: 4,
    description: "The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct.",
  },
];