import { useMutation } from "@apollo/client";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik, Form, Field, FormikValues } from "formik";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DELETE_USER_TYPE, UPDATE_USER_TYPE, CREATE_USER_TYPE } from "../../../api/query/UserTypes";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import CancelConfirmDialogModal from "../../../common/components/CancelConfirmDialogModal";
import { isNil } from "../../../common/utilities";
import { EditModeTypes } from "../../../enums";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import COLORS from "../../../common/colors";
import { useQueryClient } from "react-query";
import { QueryKeys, useUserType } from "../../../api/hooks/graphqlHooks";
import AccInput from "../../../common/components/AccInput";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";
import PageWrapper from "../../../common/components/PageWrapper";

type UserTypeDetailParams = {
  id?: string;
};

type UserTypeDetailProps = RouteComponentProps<UserTypeDetailParams>;

const UserTypeForm: React.FC<UserTypeDetailProps> = withErrorBoundary({}, ({ match, history }: UserTypeDetailProps) => {
  const queryClient = useQueryClient();
  const editMode: EditModeTypes = isNil(match.params.id) ? EditModeTypes.ADD : EditModeTypes.EDIT;
  if (editMode === EditModeTypes.ADD) {
    queryClient.removeQueries(QueryKeys.USER_TYPE);
  }
  const [cancelDialogVisible, setCancelDialogVisibible] = useState(false);

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const {
    data: getUserTypeData,
    isLoading: getUserTypeLoading,
    isFetching: getUserTypeFetching,
    isError: getUserTypeError,
  } = useUserType(Number(match.params.id), {
    enabled: editMode === EditModeTypes.EDIT,
    refetchOnMount: true,
  });

  const [createUserType] = useMutation(CREATE_USER_TYPE);
  const [updateUserType] = useMutation(UPDATE_USER_TYPE);
  const [deleteUserType] = useMutation(DELETE_USER_TYPE);


  const BLANK_FORM_VALUES = {
    name: "",
  };

  const initialFormFieldValues = () => (getUserTypeData?.getUserType ? {
    name: getUserTypeData?.getUserType.name,
  } : BLANK_FORM_VALUES);

  const openCancelDialog = () => setCancelDialogVisibible(true);
  const closeCancelDialog = () => setCancelDialogVisibible(false);

  const handleDelete = async (objectId: string | number) => {
    try {
      await deleteUserType({
        variables: {
          id: objectId,
        },
      });
      toast("User type deleted successfully!", {
        type: toast.TYPE.SUCCESS,
      });
      queryClient.refetchQueries(QueryKeys.USER_TYPES);
      history.push("/admin/usertypes");
    } catch (err: any) {
      toast(err.message || err.msg || err.toString() || "There was an error deleting user type.", {
        type: toast.TYPE.WARNING,
      });
    }
  };

  const submitForm = async (values: FormikValues, { resetForm }: any) => {
    if (editMode === EditModeTypes.ADD) {
      try {
        await createUserType({
          variables: {
            input: {
              name: values.name,
            },
          },
        });
        toast("User type added successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        queryClient.refetchQueries(QueryKeys.USER_TYPES);
        history.push("/admin/usertypes");
        resetForm();
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error adding user type", {
          type: toast.TYPE.ERROR,
        });
      }
    } else if (editMode === EditModeTypes.EDIT) {
      try {
        await updateUserType({
          variables: {
            id: getUserTypeData?.getUserType.id,
            input: {
              name: values.name,
            },
          },
        });
        toast("User type updated successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        queryClient.refetchQueries(QueryKeys.USER_TYPES);
        history.push("/admin/usertypes");
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error updating user type.", {
          type: toast.TYPE.ERROR,
        });
      }
    }
  };

  return (
    <PageWrapper
      title={`${editMode === EditModeTypes.ADD ? "Add" : "Update"} User Type`}
      showLoader={getUserTypeLoading || getUserTypeFetching}
      showError={getUserTypeError}
      errorMessage="Cannot retrieve user types"
    >
      <br />
      <div>
        <Formik
          initialValues={initialFormFieldValues()}
          enableReinitialize
          onSubmit={submitForm}
        >
          {() => (
            <Form>
              <div className="column-reverse-mobile">
                <div className="row stretch center width-100 column-mobile">
                  <Link
                    to="/admin/usertypes"
                    className="width-100-mobile">
                    <AccButton
                      color={AccButtonColors.WHITE}
                      label="Cancel"
                      style={{ backgroundColor: COLORS.white }}
                    />
                  </Link>
                  <div className="row width-100-mobile column-mobile">
                    <AccButton
                      color={AccButtonColors.GREEN}
                      type="submit"
                      label={editMode === EditModeTypes.ADD ? "Submit" : "Update"}
                      className="m-top-15-mobile m-bottom-15-mobile"
                    />
                    {editMode === EditModeTypes.EDIT
                      ? (
                        <>
                          <AccButton
                            color={AccButtonColors.RED_OUTLINE}
                            label="Delete"
                            className="delete-button m-left-15 display-none-mobile"
                            type="button"
                            onClick={openCancelDialog}
                          />
                          {mobileLayout
                            && (
                              <AccButton
                                color={AccButtonColors.RED_OUTLINE}
                                label="Delete User Type"
                                className="delete-button"
                                type="button"
                                width="40%"
                                style={styles.mobileDeleteButton}
                                onClick={openCancelDialog}
                              />
                            )}
                        </>
                      )
                      : null}
                    {getUserTypeData ? <CancelConfirmDialogModal
                      isVisible={cancelDialogVisible}
                      dialogBody="Are you sure you would like to delete this User Type?"
                      onClickCancel={closeCancelDialog}
                      onClickDelete={() => handleDelete(getUserTypeData?.getUserType.id)}
                    /> : null}
                  </div>
                </div>
                <br />
                <div
                  style={styles.border}
                  className="width-60 width-100-mobile">
                  <Field
                    name="name"
                    type="text"
                    label="User Type Name"
                    as={AccInput}
                    required
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </PageWrapper>
  );
});

const styles = {
  border: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    padding: "15px",
  },
  mobileDeleteButton: {
    alignSelf: "flex-start",
    border: "none",
    textAlign: "left",
    padding: 0,
    height: 20,
  },
};

export default withRouter(UserTypeForm);