import { Divider } from "@material-ui/core";
import React from "react";
import { useUserAccountTotal, useWalletFundings } from "../../../api/hooks/graphqlHooks";
import { formatCurrency, isNil, isNotNil } from "../../utilities";
import { useMediaQuery } from "@mui/material";
import { NUMERICAL_AMOUNTS, THEME_BREAKPOINTS } from "../../constants";
import PageCard from "../PageCard";
import COLORS from "../../colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/pro-solid-svg-icons";
import LabeledFigure from "../LabeledFigure";
import LoadingAnimation from "../LoadingAnimation";

interface Props {
  selectedWalletId?: number | null;
}

const PerformanceBar: React.FC<Props> = ({ selectedWalletId }) => {
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const {
    data: userAccountTotalsData,
    isLoading: userAccountTotalsDataLoading,
    isFetching: userAccountTotalsDataFetching,
  } = useUserAccountTotal({
    enabled: isNotNil(selectedWalletId),
  });


  const accountTotals = userAccountTotalsData?.getUserAccountTotal;

  const {
    data: walletFundingsData,
  } = useWalletFundings(selectedWalletId?.toString() ?? "", {
    enabled: isNotNil(selectedWalletId),
  });

  const selectedWalletTotalFundedToDate = isNotNil(walletFundingsData?.getWalletFundings) ? walletFundingsData?.getWalletFundings
    .reduce((acc, val) => acc + val.amount, 0) : null;


  const currentInvestmentData = [
    { value: accountTotals?.committedAndActiveInvestmentsCounter ?? 0, description: "Commitments and Active Investments", currencyFormat: false },
    { value: accountTotals?.committedAndActiveInvestmentsSum ?? 0, description: "Committed and Actively Invested (at Subscription)", currencyFormat: true },
  ];

  const totalInvestmentData = [
    { value: accountTotals?.totalInvestmentsToDateCounter ?? 0, description: "Total Investments to Date", currencyFormat: false },
    { value: accountTotals?.totalInvestedByAccount ?? 0, description: "Total Invested to Date", currencyFormat: true },
    { value: 0, description: "Total Distributions to Date", currencyFormat: true },
  ];

  if (userAccountTotalsDataLoading || userAccountTotalsDataFetching) {
    return (
      <LoadingAnimation />
    );
  }

  const renderTotalFundedAmount = () => {
    const rawValue = (isNil(selectedWalletId) ? accountTotals?.availableBalanceToInvest : selectedWalletTotalFundedToDate) ?? 0;

    // Display "1.2M" for 1,200,000
    if (rawValue > NUMERICAL_AMOUNTS.ONE_MILLION) {
      return `${formatCurrency(rawValue / NUMERICAL_AMOUNTS.ONE_MILLION)}MM`;
    } else {
      return formatCurrency(rawValue);
    }
  };

  return (
    <PageCard
      className="m-bottom-20"
      style={{ background: COLORS.darkBlueGradient, position: "relative", overflow: "hidden" }}>
      <FontAwesomeIcon
        style={styles.backgroundIcon as React.CSSProperties}
        icon={faCoins} />
      <div className="row center stretch column-mobile">
        <LabeledFigure
          value={renderTotalFundedAmount()}
          description="Available Balance To Invest"
          valueStyles={{ color: COLORS.lightGreen, fontSize: 40 }}
          descriptionStyles={{ color: COLORS.white, fontWeight: 600, fontSize: 16, lineHeight: 1, minWidth: 125 }}
          alignment="center"
          containerClassName="width-100-mobile"
          className="column"
          containerStyles={{ padding: "0px 25px", minWidth: "25%" }}
        />
        <br />
        <div
          className="row start stretch width-100 column-mobile"
        >
          <Divider
            orientation={mobileLayout ? "horizontal" : "vertical"}
            flexItem
            className={mobileLayout ? "m-bottom-15-mobile" : "m-right-15"}
            style={{ backgroundColor: COLORS.white, opacity: 0.6, height: mobileLayout ? 1 : "" }} />
          {[...currentInvestmentData, ...totalInvestmentData].map((item, idx) =>
            <div key={item.description}>
              <LabeledFigure
                key={item.description}
                value={item.currencyFormat === true ? formatCurrency(item.value!, 2) : item.value}
                description={item.description}
                valueStyles={{ color: COLORS.green, fontSize: 40 }}
                descriptionStyles={{ color: COLORS.white, fontWeight: 300, fontSize: 14, textAlign: mobileLayout ? "center" : "left", minWidth: mobileLayout ? undefined : "15%", lineHeight: 1 }}
                containerStyles={{ margin: "5px 15px" }}
                alignment={mobileLayout ? "center" : "start"}
                className="column"
                containerClassName={mobileLayout ? "m-bottom-15-mobile" : "m-right-15"}
              />
              {idx === 1 && (
                <Divider
                  orientation={mobileLayout ? "horizontal" : "vertical"}
                  flexItem
                  className={mobileLayout ? "m-bottom-15-mobile" : "m-right-15"}
                  style={{ backgroundColor: COLORS.white, opacity: 0.6, height: mobileLayout ? 1 : "" }}
                />
              )}
            </div>,
          )}
        </div>
      </div>
    </PageCard>
  );
};

const styles = {
  backgroundIcon: {
    position: "absolute",
    top: "20px",
    left: "20px",
    fontSize: "5em",
    color: "#1bd0ae",
    opacity: "0.25",
  },
};

export default PerformanceBar;