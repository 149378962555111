import { Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Asset, CashFlow, InvestmentAmounts } from "../../../../../common/types";
import { useQueryClient } from "react-query";
import { formatCurrency, isNotNil } from "../../../../../common/utilities";
import { QueryKeys } from "../../../../../api/hooks/graphqlHooks";
import moment from "moment";
import { SignatureContext } from "../../../../../context";
import InputHelperTextWithIcon from "../../../../../common/components/InputHelperTextWithIcon";
import WalletSelector from "../../../../../common/components/WalletSelector";
import { AccButtonColors } from "../../../../../common/components/AccButton";
import CashFlowSimulationBar from "../../../../../common/components/CashFlowSimulationBar";
import CashFlowSimulationInput from "../../../../../common/components/CashFlowSimulationInput";
import PageWrapper from "../../../../../common/components/PageWrapper";

const FirstStep: React.FC = () => {
  const { setNextButtonDisabled, setUserWallet, userWallet, updateInvestmentAmount } = useContext(SignatureContext);
  const [investmentAmount, setInvestmentAmount] = useState(0);
  const queryClient = useQueryClient();
  const getAssetData: { getAsset?: Asset } | undefined = queryClient.getQueryData(QueryKeys.ASSET);
  const getCashFlowData: { getCashFlow?: CashFlow } | undefined = queryClient.getQueryData(QueryKeys.CASH_FLOW);

  if (!getAssetData) return <></>;

  let investmentDate = moment(new Date());
  let targetedFundingDate = moment(new Date(getAssetData?.getAsset?.targetedFundingDate!));

  let timeFrame = [];

  while (investmentDate.isBefore(targetedFundingDate)) {
    timeFrame.push(investmentDate.format("YYYY-MM"));
    investmentDate.add(1, "month");
  }

  //Calculations are not final, they're just to illustrate 
  //we can add dynamic data and show a "projection"
  //when user enters desired investment amount.

  const limitInvestmentAmount = (floatValue: any) => {
    const { value } = floatValue;
    if (value <= (getAssetData?.getAsset?.totalInvestmentAmount! && userWallet?.availableAmount!)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isNotNil(investmentAmount)) {
      updateInvestmentAmount(investmentAmount);
      validateInvestmentAmount(investmentAmount);
    }
  }, [investmentAmount]);

  const validateInvestmentAmount = (amount: number) => {
    if (amount >= getAssetData?.getAsset?.minimumInvestmentAmount! &&
      investmentAmount! % getAssetData?.getAsset?.investmentAmountIncrement! === 0 &&
      investmentAmount! <= getAssetData?.getAsset?.totalInvestmentAmount! &&
      investmentAmount! <= getAssetData?.getAsset?.remainingAmount!
    ) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  };

  return (
    <PageWrapper
      title={""}
      errorMessage="Cannot retrieve Cash Flows"
    >
    <div
        className="row stretch column-reverse-mobile m-bottom-30"
        style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
      <div
        style={{ alignItems: "flex-start", height: "100%" }}
          className="width-100-mobile width-50 m-right-30 m-0-mobile">
        <WalletSelector
          userWallet={userWallet}
          setUserWallet={setUserWallet}
          isFundingWallet
            minimumInvestmentAmount={getAssetData?.getAsset?.minimumInvestmentAmount}
          displayCTAs={true}
        />
        {isNotNil(userWallet) ?
            <div className="m-top-50">
              <CashFlowSimulationInput
                asset={getAssetData?.getAsset}
                limitInvestmentAmount={limitInvestmentAmount}
                setTotalInvestmentAmountInput={setInvestmentAmount}
            />
            <br />
            <br />
            <Typography
              variant="subtitle1"
              className="acc-white bold"
              style={{ marginBottom: 10 }}
            >
              Investment Checklist
            </Typography>
            <InputHelperTextWithIcon
              text={(
                <>
                  Sufficient balance in <span className="acc-text acc-lightgreen">access</span>Wallet
                </>
              )}
                data={userWallet?.availableAmount >= getAssetData?.getAsset?.minimumInvestmentAmount!}
              color={AccButtonColors.WHITE}
            />
            <InputHelperTextWithIcon
              text="Increment of"
              color={AccButtonColors.WHITE}
                value={formatCurrency(getAssetData?.getAsset?.investmentAmountIncrement ?? 0)}
                data={(investmentAmount !== null && investmentAmount! % getAssetData?.getAsset?.investmentAmountIncrement! === 0)}
            />
            <InputHelperTextWithIcon
              text="Above minimum investment amount"
              color={AccButtonColors.WHITE}
                value={formatCurrency(getAssetData?.getAsset?.minimumInvestmentAmount ?? 0)}
                data={investmentAmount! >= getAssetData?.getAsset?.minimumInvestmentAmount!}
            />
            <InputHelperTextWithIcon
              text="Below maximum investment amount"
              color={AccButtonColors.WHITE}
                value={formatCurrency(getAssetData?.getAsset?.totalInvestmentAmount!)}
                data={investmentAmount! <= getAssetData?.getAsset?.totalInvestmentAmount!}
            />
            <InputHelperTextWithIcon
              text="Below or equal to asset's remaining amount"
              color={AccButtonColors.WHITE}
                value={formatCurrency(getAssetData?.getAsset?.remainingAmount!)}
                data={investmentAmount! <= getAssetData?.getAsset?.remainingAmount!}
            />
            <InputHelperTextWithIcon
              text="Maximum investment amount"
              color={AccButtonColors.WHITE}
              value={formatCurrency(InvestmentAmounts.MAX_AMOUNT_TO_INVEST)}
                data={investmentAmount! <= InvestmentAmounts.MAX_AMOUNT_TO_INVEST}
            />
            <br />
            <br />
            </div> : null}
        </div>
        <div className="column start width-50 m-left-30 width-100-mobile m-0-mobile">
          <br />
          <Typography
            className="bold acc-white font-size-mobile-3 width-100-mobile"
            variant="h6">Cash Flow Model for <span className="bold acc-green">{getAssetData?.getAsset?.name}</span>
          </Typography>
          <CashFlowSimulationBar
            targetedFundingDate={getAssetData?.getAsset?.targetedFundingDate.substring(0, 7) || ""}
            cashFlowModelRows={getCashFlowData?.getCashFlow ? getCashFlowData?.getCashFlow?.cashFlowInputArray : []}
            totalInvestmentAmount={getAssetData?.getAsset?.totalInvestmentAmount}
            investmentAmount={investmentAmount}
            displayTargetedCashFlow={false}
            isPerformanceCashFlow={false}
          />
          <br />
          <Typography
            variant="caption"
            className="acc-white light condensed-lineheight"
          >This cash flow model is a projection based on your selected
            investment amount and the assumptions and expected returns anticipated by the
            <span> {getAssetData?.getAsset?.managementCompany}</span> and disclosed in the&nbsp;
            <a
              className="underlined-link"
              href={getAssetData?.getAsset?.ppmDocumentLocation}
              target="_blank"
              rel="noreferrer">Private Placement Memorandum</a>&nbsp;for this Fund.
            There is no guarantee that such assumptions and
            projections will prove to be correct.</Typography>
        </div>
        <br />
      </div>
    </PageWrapper>
  );
};

export default FirstStep;
