import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import React from "react";
import AccButton, { AccButtonColors } from "../AccButton";
import PageCard from "../PageCard";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  title: string;
  caption: string;
  body: string;
  actionLabel: string;
  icon: IconProp;
}

const Notification: React.FC<Props> = ({
  title,
  caption,
  body,
  actionLabel,
  icon,
}) => (
  <PageCard
    style={{
      boxShadow: "-10px 0px 60px #00000014",
      marginBottom: 25,
      padding: 20,
    }}
  >
    <div
      className="column start"
      style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: -5,
          right: -2.5,
        }}
      >
        <FontAwesomeIcon
          style={{ fontSize: 40 }}
          className="acc-green"
          icon={icon}
        />
      </div>
      <div>
        <Typography
          variant="subtitle1"
          className="acc-blue bold"
          style={{ lineHeight: 1, width: "75%" }}
        >
          {title}
        </Typography>
        <br />
      </div>
      {/* Notification Body */}
      <div>
        <Typography
          variant="subtitle2"
          className="acc-darkgray bold"
          style={{ marginBottom: 5 }}
        >
          {caption}
        </Typography>
        <Typography
          variant="body2"
          style={{ lineHeight: 1 }}>
          {body}
        </Typography>
      </div>
      <br />
      {/* Action button */}
      <div className="width-100-mobile">
        <AccButton
          color={AccButtonColors.GREEN}
          label={actionLabel}/>
      </div>
    </div>
  </PageCard>
);

export default Notification;
