import React from "react";
import { Typography } from "@material-ui/core";
import FilesDragAndDrop from "../../../../common/components/FilesDragAndDrop";
import { useFormikContext } from "formik";
import { UMBCommercialFormValues } from "../common/umb.interfaces";
import { QueryKeys, useCommercialEntityTypesResponse } from "../../../../api/hooks/graphqlHooks";
import { useQueryClient } from "react-query";

interface Props {
  uploadedArtifacts: any;
  setUploadedArtifacts: any;
}

export const CommercialArtifactsUpload = ({
  uploadedArtifacts,
  setUploadedArtifacts,
}: Props) => {
  const queryClient = useQueryClient();
  const { values } = useFormikContext<UMBCommercialFormValues>();
  const commercialEntityTypesData: useCommercialEntityTypesResponse | undefined = queryClient.getQueryData(QueryKeys.COMMERCIAL_ENTITY_TYPES);

  const selectedEntity = commercialEntityTypesData?.getCommercialEntityTypes.find(type => type.name === values?.company.entityType);

  return (
    <>
      <Typography
        style={{ marginBottom: 15, textAlign: "center" }}
        variant="h5"
        className="acc-white bold">Upload Commercial Entity Documents</Typography>
      <Typography
        style={{ marginBottom: 30, textAlign: "center" }}
        variant="h5"
        className="acc-green bold">{values?.company?.entityType}</Typography>
      <div
        className="column start"
        style={{ width: 500 }}>
        <ul>
          {selectedEntity?.requiredDocuments?.map(item => (
            <li
              className="acc-white"
              key={item}>
              <Typography >
                {item}
              </Typography>
            </li>
          ))}
        </ul>
      </div>
      <div className="radio-container column center" >
        <FilesDragAndDrop
          multiple
          mainTextClassName="acc-white"
          currentFiles={uploadedArtifacts}
          updateFileList={setUploadedArtifacts} />
      </div>
    </>
  );
};
