import React, { useContext } from "react";
import { faWallet } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled, TextField, Typography } from "@material-ui/core";
import { MenuItem } from "@mui/material";
import { useAccounts, useAccreditedInvestorRequests, useUserWallets } from "../../../api/hooks/graphqlHooks";
import COLORS from "../../colors";
import {
  formatCurrency, isNotNil, isNil, isAccreditationStatusCompleted, isWalletNotAccredited, isWalletStatusPending,
  isWalletNotConnected, isSufficientFunds, walletIsNotAvailable,
} from "../../utilities";
import AccButton, { AccButtonColors } from "../AccButton";
import { Link } from "react-router-dom";
import { WalletStatus } from "../../../enums";
import { SessionContext } from "../../../context";
import { Wallet } from "../../types";

interface ButtonProps {
  link: string,
  label: string,
  color: AccButtonColors,
}

const LinkedButton: React.FC<ButtonProps> = ({ link, label, color }) => (

  <Link to={link}>
    <AccButton
      label={label}
      color={color}
      style={styles.button}
    />
  </Link>
);

interface WalletSelectorProps {
  setUserWallet?: Function,
  userWallet?: Wallet | null,
  isFundingWallet: boolean,
  minimumInvestmentAmount?: number | any,
  displayCTAs: boolean,
}

const WalletSelector = function ({ setUserWallet, userWallet, isFundingWallet, minimumInvestmentAmount, displayCTAs }: WalletSelectorProps) {
  const { user: sessionUser } = useContext(SessionContext);
  const { data: userWalletsData, isFetching: userWalletsFetching, isLoading: userWalletsLoading } = useUserWallets();
  const {
    data: accreditedInvestorData,
    isFetching: accreditedInvestorFetching,
    isLoading: accreditedInvestorLoading,
  } =
    useAccreditedInvestorRequests(
      sessionUser?.accountObjectId, {
      refetchOnMount: true,
      enabled: isNotNil(sessionUser?.accountObjectId),
    });
  const {
    data: getAccountsData,
  } = useAccounts({
    enabled: true,
    refetchOnMount: true,
  });
  const openAccountsData = userWalletsData?.getUserWallets?.filter(item => item.walletStatus.name === WalletStatus.OPEN);
  const isWalletNotAvailable = (wallet: Wallet) => {
    if (accreditedInvestorData && getAccountsData) {
      return walletIsNotAvailable(
        wallet,
        minimumInvestmentAmount,
        accreditedInvestorData?.getAccreditedInvestorRequests,
        getAccountsData?.getAccounts,
        sessionUser.accountObjectId,
      );
    }
  };

  const availableWallets = userWalletsData?.getUserWallets?.filter(item => item.walletStatus.name === WalletStatus.OPEN
          && (!(!item.umbAccountId || (isNil(item.externalAccounts) && isFundingWallet) || (getAccountsData
            && isWalletStatusPending(getAccountsData?.getAccounts, sessionUser.accountObjectId))
            || (accreditedInvestorData && isWalletNotAccredited(item, accreditedInvestorData?.getAccreditedInvestorRequests)))));

  if (userWalletsFetching || userWalletsLoading || accreditedInvestorFetching || accreditedInvestorLoading) {
    return <Typography>LOADING</Typography>;
  }

  return (
    <StyledSelect
      onChange={(e) => setUserWallet ? setUserWallet(userWalletsData?.getUserWallets?.find(wallet => wallet?.walletId?.toString() === e.target.value))
        : null}
      name="investorAccount"
      size="medium"
      margin="none"
      variant="outlined"
      fullWidth
      select
      value={userWallet?.walletId}
      style={styles.input}
      label={
        <div
          className="row center"
          style={{ padding: "5px 10px", backgroundColor: COLORS.white, borderRadius: 16 }}>
          <FontAwesomeIcon
            icon={faWallet}
            color={COLORS.lightGreen}
            size="2x"
            className="m-right-15" />
          <Typography
            className="bold acc-darkgray">
            Select Investment Account
          </Typography>
        </div>
      }
    >
      {isNotNil(userWalletsData?.getUserWallets) && isNotNil(openAccountsData) && availableWallets ?
        availableWallets.map((wallet) => (
          <MenuItem
            key={wallet.id}
            value={wallet.walletId}
            style={{ width: "100%" }}
          >
            <div
              key={wallet.account.id}
              className="row stretch end width-100"
            >
              <Typography
                variant="subtitle1"
                className={(displayCTAs && isWalletNotAvailable(wallet)) || wallet.availableAmount <= 0 ? "acc-lightgray" : "bold acc-darkgray"}>
                {wallet.name}
              </Typography>
              <div className="column end">
                <Typography style={{ fontSize: 8 }}>
                  {isWalletNotAvailable(wallet) ? "" : "Available Balance"}
                </Typography>
                <Typography
                  variant={"subtitle1"}
                  className={(displayCTAs && (wallet.availableAmount <= 0 && isWalletNotAvailable(wallet)))
                    ? "bold acc-darkgray" : "acc-green bold"}>
                  {displayCTAs ?
                    accreditedInvestorData &&
                      !isAccreditationStatusCompleted(wallet, accreditedInvestorData?.getAccreditedInvestorRequests) ?
                      <LinkedButton
                        link={`/investor/accreditation/${wallet.id}`}
                        label="Complete Accreditation"
                        color={AccButtonColors.BLUE}
                      /> :
                      (accreditedInvestorData && isWalletNotAccredited(wallet, accreditedInvestorData?.getAccreditedInvestorRequests) ? "Not Accredited" :
                        (getAccountsData && isWalletStatusPending(getAccountsData?.getAccounts, sessionUser.accountObjectId) ? "Account Status Pending" :
                          (isWalletNotConnected(wallet) ?
                            <LinkedButton
                              link={`/investor/wallet/connectbankaccount/${wallet?.walletId}`}
                              label="Connect Bank Account"
                              color={AccButtonColors.GREEN} /> :
                            (!isSufficientFunds(wallet, minimumInvestmentAmount) ?
                              <LinkedButton
                                link={`/investor/accounts/accountdetails/${wallet.walletId}`}
                                label="Fund Account"
                                color={AccButtonColors.GREEN} /> :
                              formatCurrency(wallet.availableAmount)
                            )
                          )
                        )
                      )
                    : formatCurrency(wallet.availableAmount)}
                </Typography>
              </div>
            </div>
          </MenuItem>
        )) :
        <MenuItem>
          <div className="row stretch center width-100">
            You don&apos;t have an investor account yet.
            <Link to="/investor/accounts/onboard">
              <AccButton
                style={{ height: 30 }}
                color={AccButtonColors.GREEN}
                className="green-button"
                label="Setup Account" />
            </Link>
          </div>
        </MenuItem>
      }
    </StyledSelect>
  );
};
export default WalletSelector;

const styles = {
  input: {
    borderRadius: 16,
    background: "white",
    opacity: 1,
  },
  button: {
    width: "150px",
    padding: "0 5px",
    height: "28px",
    zIndex: 5,
    fontSize: 11,
  },
};

const StyledSelect = styled(TextField)({
  "& label.Mui-focused": {
    color: COLORS.darkBlue,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.green,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      padding: 0,
      borderRadius: 16,
      boxShadow: "0px 3px 6px #00000029",
      height: 80,
    },
    "&.Mui-focused fieldset": {
      borderColor: COLORS.green,
    },
  },
  "& .MuiSelect-outlined": {
    borderRadius: 16,
    height: 40,
  },
  "& .MuiOutlinedInput-input:-webkit-autofill": {
    borderRadius: 16,
    height: 0,
  },
});