import { useMutation } from "@apollo/client";
import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik, Form, Field, FormikValues } from "formik";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import { DELETE_INVESTMENT, UPDATE_INVESTMENT, CREATE_INVESTMENT } from "../../../api/query/Investments";
import { formatCurrency, isNil } from "../../../common/utilities";
import { EditModeTypes } from "../../../enums";
import CancelConfirmDialogModal from "../../../common/components/CancelConfirmDialogModal";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import COLORS from "../../../common/colors";
import { useInvestment, QueryKeys, useAssets, useWallets } from "../../../api/hooks/graphqlHooks";
import { useQueryClient } from "react-query";
import AccInput from "../../../common/components/AccInput";
import { InvestmentStatus as InvestmentStatusTypes, InvestmentAmounts, InvestmentStatuses, InvestmentTypeOptions } from "../../../common/types";
import * as Yup from "yup";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";
import PageWrapper from "../../../common/components/PageWrapper";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";

type InvestmentDetailParams = {
  id?: string;
};

type InvestmentDetailProps = RouteComponentProps<InvestmentDetailParams>;

const InvestmentForm: React.FC<InvestmentDetailProps> = withErrorBoundary({}, ({ match, history }: InvestmentDetailProps) => {
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const queryClient = useQueryClient();
  const editMode: EditModeTypes = isNil(match.params.id) ? EditModeTypes.ADD : EditModeTypes.EDIT;
  if (editMode === EditModeTypes.ADD) {
    queryClient.removeQueries(QueryKeys.INVESTMENT);
  }
  const {
    data: getInvestmentData,
    isLoading: getInvestmentLoading,
    isFetching: getInvestmentFetching,
    isError: getInvestmentError,
  } = useInvestment(Number(match.params.id), {
    enabled: editMode === EditModeTypes.EDIT,
    refetchOnMount: true,
  });
  const [createInvestment] = useMutation(CREATE_INVESTMENT);
  const [deleteInvestment] = useMutation(DELETE_INVESTMENT);
  const [updateInvestment] = useMutation(UPDATE_INVESTMENT);

  const { data: getWalletsData, isLoading: getWalletsLoading, isError: getWalletsError } = useWallets();
  const { data: getAssetsData, isLoading: getAssetsLoading, isError: getAssetsError } = useAssets();

  const [cancelDialogVisible, setCancelDialogVisibible] = useState(false);
  const [selectedAssetObjectId, setSelectedAssetObjectId] = useState<string | null>(null);
  const todaysDate = moment(new Date()).local();

  const openCancelDialog = () => setCancelDialogVisibible(true);
  const closeCancelDialog = () => setCancelDialogVisibible(false);

  const matchedAsset = getAssetsData?.getAssets.find((item) => item.id === selectedAssetObjectId);
  const BLANK_FORM_VALUES = {
    name: "",
    amount: "",
    status: "",
    type: "",
    walletObjectId: "",
    walletName: "",
    walletId: "",
    assetObjectId: "",
    assetName: "",
    assetId: "",
  };

  const InvestmentSchema = Yup.object().shape({
    amount: Yup.number()
      .positive()
      .min(0, "Investment amount must be more than or equal to 0")
      .max(InvestmentAmounts.MAX_AMOUNT_TO_INVEST, `Maximum investment amount is ${formatCurrency(InvestmentAmounts.MAX_AMOUNT_TO_INVEST)}`),
  });

  const initialFormFieldValues = () => (getInvestmentData?.getInvestment ? {
    name: getInvestmentData?.getInvestment.name,
    amount: getInvestmentData?.getInvestment.amount,
    status: getInvestmentData?.getInvestment.status,
    type: getInvestmentData?.getInvestment?.type,
    walletObjectId: getInvestmentData?.getInvestment.wallet ? getInvestmentData?.getInvestment.wallet.id : "Wallet does not exist, assign new Wallet",
    walletName: getInvestmentData?.getInvestment.wallet ? getInvestmentData?.getInvestment.wallet.name : <span style={{ color: "red" }}>Wallet does not exist, assign new Wallet</span>,
    walletId: getInvestmentData?.getInvestment.wallet ? getInvestmentData?.getInvestment.wallet.walletId : "Wallet does not exist, assign new Wallet",
    assetObjectId: getInvestmentData?.getInvestment.asset ? getInvestmentData?.getInvestment.asset.id : "Asset does not exist, assign new Asset",
    assetName: getInvestmentData?.getInvestment.asset ? getInvestmentData?.getInvestment.asset.name : <span style={{ color: "red" }}>Asset does not exist, assign new Asset</span>,
    assetId: getInvestmentData?.getInvestment.asset ? getInvestmentData?.getInvestment.asset.assetId : "Asset does not exist, assign new Asset",
  } : BLANK_FORM_VALUES);

  const handleDelete = async (objectId: string | number) => {
    try {
      await deleteInvestment({
        variables: {
          id: objectId,
        },
      });
      toast("Investment deleted successfully!", {
        type: toast.TYPE.SUCCESS,
      });
    } catch (err: any) {
      toast(err.message || err.msg || err.toString() || "There was an error deleting investment.", {
        type: toast.TYPE.WARNING,
      });
    }
    queryClient.refetchQueries(QueryKeys.INVESTMENTS);
    history.push("/admin/investments");
  };

  const submitForm = async (values: FormikValues, { resetForm }: any) => {
    if (editMode === EditModeTypes.ADD) {
      try {
        await createInvestment({
          variables: {
            input: {
              name: `${matchedAsset?.name} Investment`,
              amount: Number(values.amount),
              status: values.status,
              type: values.type,
              walletObjectId: values.walletObjectId,
              assetObjectId: values.assetObjectId,
              committedDate: values.status === InvestmentStatuses.COMMITTED ? todaysDate : "",
            },
          },
        });
        toast("Investment added successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        queryClient.refetchQueries(QueryKeys.INVESTMENTS);
        history.push("/admin/investments");
        resetForm();
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error adding investment.", {
          type: toast.TYPE.ERROR,
        });
      }
    } else if (editMode === EditModeTypes.EDIT) {
      try {
        await updateInvestment({
          variables: {
            id: getInvestmentData?.getInvestment.id,
            input: {
              name: `${matchedAsset?.name ? matchedAsset?.name : values.assetName} Investment`,
              amount: Number(values.amount),
              status: values.status,
              type: values.type,
              walletObjectId: values.walletObjectId,
              assetObjectId: values.assetObjectId,
              committedDate: getInvestmentData?.getInvestment.committedDate ? getInvestmentData?.getInvestment.committedDate
                : (values.status === InvestmentStatuses.COMMITTED && isNil(values.committedDate)) ? todaysDate : "",
            },
          },
        });
        toast("Investment updated successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        queryClient.refetchQueries(QueryKeys.INVESTMENTS);
        history.push("/admin/investments");
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error updating investment.", {
          type: toast.TYPE.ERROR,
        });
      }
    }
  };

  return (
    <PageWrapper
      title={`${editMode === EditModeTypes.ADD ? "Add" : "Update"} Investment`}
      showLoader={getInvestmentLoading || getInvestmentFetching || getWalletsLoading || getAssetsLoading}
      showError={getInvestmentError || getAssetsError || getWalletsError}
      errorMessage="Cannot retrieve investments"
    >
      <br />
      <div>
        <Formik
          initialValues={initialFormFieldValues()}
          enableReinitialize
          onSubmit={submitForm}
          validationSchema={InvestmentSchema}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form
              style={{ display: mobileLayout ? "flex" : "", flexDirection: mobileLayout ? "column-reverse" : "column" }}>
              <div className="row stretch center width-100 column-mobile">
                <Link
                  to="/admin/investments"
                  className="width-100-mobile">
                  <AccButton
                    color={AccButtonColors.WHITE}
                    label="Cancel"
                    style={{ backgroundColor: COLORS.white }}
                  />
                </Link>
                <div className="row width-100-mobile column-mobile">
                  <AccButton
                    color={AccButtonColors.GREEN}
                    type="submit"
                    label={editMode === EditModeTypes.ADD ? "Submit" : "Update"}
                    className="m-top-15-mobile m-bottom-15-mobile"
                  />
                  {editMode === EditModeTypes.EDIT
                    ? (
                      <>
                        <AccButton
                          color={AccButtonColors.RED_OUTLINE}
                          label="Delete"
                          className="delete-button m-left-15 display-none-mobile"
                          type="button"
                          onClick={openCancelDialog}
                        />
                        {mobileLayout
                          && (
                            <AccButton
                              color={AccButtonColors.RED_OUTLINE}
                              label="Delete Investment"
                              className="delete-button"
                              type="button"
                              width="40%"
                              style={styles.mobileDeleteButton}
                              onClick={openCancelDialog}
                            />
                          )}
                      </>
                    )
                    : null}
                  {getInvestmentData ? <CancelConfirmDialogModal
                    isVisible={cancelDialogVisible}
                    dialogBody="Are you sure you would like to delete this Investment?"
                    onClickCancel={closeCancelDialog}
                    onClickDelete={() => handleDelete(getInvestmentData?.getInvestment.id)}
                  /> : null}
                </div>
              </div>
              <div
                className="column-reverse-mobile m-top-50"
                style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                <div
                  style={styles.border}
                  className="width-60 width-100-mobile m-bottom-15-mobile">
                  <Field
                    name="amount"
                    type="text"
                    label="Investment Amount"
                    as={AccInput}
                    required
                    currencyMask
                    helperText={errors.amount && touched.amount ? errors.amount : null}
                    error={errors.amount}
                  />
                  <AccInput
                    name="status"
                    value={values.status}
                    label="Investment Status"
                    select
                    onChange={handleChange}
                    required
                  >
                    {InvestmentStatusTypes
                      ?.filter(
                        (item) => item.key !== values.status,
                      )
                      .map((item) => (
                        values.walletObjectId == item.value ?
                          <MenuItem
                            selected
                            value={values.status}>
                            {values.status ? values.status : <span style={{ color: "red" }}>Investment status does not exist, assign new status</span>}
                          </MenuItem>
                          : 
                        <MenuItem
                          key={item.key}
                          value={item.value}
                        >
                          {item.value}
                        </MenuItem>
                      ))}
                  </AccInput>
                  <AccInput
                    name="type"
                    value={values.type}
                    label="Investment Type"
                    select
                    onChange={handleChange}
                    required
                  >
                    {InvestmentTypeOptions
                      ?.filter(
                        (item) => item.key !== values.status,
                      )
                      .map((item) => (
                        values.type === item.value ?
                          <MenuItem
                            selected
                            value={values.type}>
                            {values.type ? values.type : <span style={{ color: "red" }}>Investment type does not exist, assign new type</span>}
                          </MenuItem>
                          :
                          <MenuItem
                            key={item.key}
                            value={item.value}
                          >
                            {item.value}
                          </MenuItem>
                      ))}
                  </AccInput>
                  <AccInput
                    name="walletObjectId"
                    value={values.walletObjectId}
                    label="Wallet"
                    select
                    onChange={handleChange}
                    required
                  >
                    <MenuItem
                      selected
                      value={values.walletId}>
                      {values.walletName ? values.walletName : <span style={{ color: "red" }}>Wallet does not exist, assign new Wallet</span>}
                    </MenuItem>
                    {getWalletsData?.getWallets
                      ?.filter(
                        (item) => item.id !== values.walletId,
                      )
                      .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name))
                      .map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </AccInput>
                  <AccInput
                    name="assetObjectId"
                    value={values.assetObjectId}
                    label="Asset"
                    select
                    onChange={(e: any) => {
                      setFieldValue("assetObjectId", e.target.value);
                      setSelectedAssetObjectId(e.target.value);
                    }}
                    required
                  >
                    <MenuItem
                      selected
                      value={values.assetId}>
                      {values.assetName ? values.assetName : <span style={{ color: "red" }}>Asset does not exist, assign new Asset</span>}
                    </MenuItem>
                    {getAssetsData?.getAssets
                      ?.filter(
                        (item) => item.id !== values.assetId,
                      )
                      .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name))
                      .map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </AccInput>
                </div>
                {editMode === EditModeTypes.EDIT ?
                  <div
                    className="m-bottom-15-mobile"
                    style={styles.border}>
                    <div className="column start">
                      <Typography
                        variant="h6"
                        className="acc-black bold">
                        <FontAwesomeIcon
                          icon={faCalendarAlt}
                          className="acc-lightgreen m-right-15" />
                        Investment Creation Date
                      </Typography>
                      <Typography
                        variant="h6"
                        className="acc-black light">
                        {moment(Number(getInvestmentData?.getInvestment.committedDate ? getInvestmentData?.getInvestment.committedDate
                          : getInvestmentData?.getInvestment.createdAt)).format("MM/DD/YYYY")}
                      </Typography>
                    </div>
                  </div>
                  : null}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </PageWrapper>
  );
});

const styles = {
  border: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    padding: "15px",
  },
  mobileDeleteButton: {
    alignSelf: "flex-start",
    border: "none",
    textAlign: "left",
    padding: 0,
    height: 20,
  },
};

export default withRouter(InvestmentForm);
