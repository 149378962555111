import { AxiosResponse } from "axios";
import { GraphQLClient } from "graphql-request";
import moment from "moment";
import { QueryClient } from "react-query";
import { toast } from "react-toastify";
import { config } from "../../common/config";
import { Account, GoogleAnalyticsEventTags, User } from "../../common/types";
import { isNotNil } from "../../common/utilities";
import googleAnalytics from "../../common/utilities/googleAnalytics";
import restClient from "../rest/restClient";


let isTokenRefreshing = false;

export const getToken = () => localStorage.getItem("token");

export const rootQueryClient = new QueryClient();


const graphqlClientV2 = new GraphQLClient(`${config.apiHost}/graphql`);

export const makeGraphqlRequest = async (request: any, params?: any) => {
  try {
    const gqlClientResponse = await graphqlClientV2.request(request, params, {
      Authorization: `Bearer ${getToken()}`,
    });

    return gqlClientResponse;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      try {
        const refreshResponse = await attemptTokenRefresh();
        if (refreshResponse === true) {
          return graphqlClientV2.request(request, params, {
            Authorization: `Bearer ${getToken()}`,
          });
        } else if (refreshResponse === "REFRESH_IN_PROGRESS") {
          console.log("Refreshing session");
        } else {
          localStorage.setItem("sessionExpired", "true");
          logOut();
        }
      } catch (err: any) {
        logOut();
      }
    }
    throw new Error(err);
  }
};

// For use in API calls using Base.controller class
export const handleApiResponse = (result: AxiosResponse, fallbackErrorResponse: string | null = null) => {
  if (result.data && result.data.success) {
    return result.data;
  } else if (!result?.data?.success) {
    toast(result.data.msg || fallbackErrorResponse || "Unknown error occurred", {
      type: toast.TYPE.ERROR,
    });
  }
};

export const getApiVersion = async () => {
  const response = await restClient.get("/");
  return response?.data?.version;

};

export const logIn = (authPayload: { token: string; refreshToken: string; user: User; account: Account }) => {
  localStorage.setItem("token", authPayload.token);
  localStorage.setItem("refreshToken", authPayload.refreshToken);
  localStorage.setItem("user", JSON.stringify(authPayload.user));
  localStorage.setItem("account", JSON.stringify(authPayload.account));
  localStorage.setItem(
    "tokenExpiration",
    JSON.stringify(moment().add(2, "hours").unix()),
  );
  googleAnalytics.pushGTMDataLayerEvent({
    event: GoogleAnalyticsEventTags.LOGIN,
    uid: authPayload?.user?.userId?.toString(),
  });
};

const clearSessionWithRedirect = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("user");
  localStorage.removeItem("account");
  localStorage.removeItem("refreshing");
  localStorage.removeItem("showPerformanceTab");
  localStorage.removeItem("showWallet");
  rootQueryClient.clear();
};

export const logOut = async () => {
  try {
    await restClient.post("/user/logout");
    window.location.href = "/";
    clearSessionWithRedirect();
  } catch (err: any) {
    clearSessionWithRedirect();
  }
};

export const attemptTokenRefresh = async () => {
  if (isTokenRefreshing === true) {
    return "REFRESH_IN_PROGRESS";
  }
  isTokenRefreshing = true;
  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");
  const refreshResponse = await restClient.post("/user/refresh", {
    token,
    refreshToken,
  });
  if (refreshResponse?.data?.success === true && isNotNil(refreshResponse?.data?.data)) {
    const { token, refreshToken } = refreshResponse?.data?.data ?? {};
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
    // localStorage.setItem("refreshing", "false");
    isTokenRefreshing = false;
    return true;
  } else {
    isTokenRefreshing = false;
    return false;
  }
};

export const openPDF = (b64Data: string) => {
  const blob = b64toBlob(b64Data);
  const blobUrl = URL.createObjectURL(blob);
  window.open(blobUrl);
};

const b64toBlob = (b64Data: string, sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: "application/pdf" });
  return blob;
};