import { Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import COLORS from "../../colors";
import { THEME_BREAKPOINTS } from "../../constants";
import "./styles.css";

interface Props {
  image: string;
  vertical?: string;
  containerStyles?: {};
  className?: string;
}

const AccessCard: React.FC<Props> = ({ image, vertical, containerStyles, className }) => {

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  return (
    <div
      className={`card-background ${className}`}
      style={{
        ...containerStyles,
        backgroundImage: `${COLORS.imageGradientOverlay}, url(${image})`,
        height: mobileLayout ? "" : "313px",
        ...styles.image as React.CSSProperties,
      }}>
      <div className="acc-card-chip"></div>
      <Typography
        variant="subtitle2"
        className="acc-card-text acc-white bold">
        {vertical}
      </Typography>
    </div>
  );
};

const styles = {
  image: {
    borderRadius: "16px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
};

export default AccessCard;