import React from "react";
import { MenuItem } from "@material-ui/core";
import AccInput, { AccInputProps } from "..";
import { COUNTRIES } from "../../../constants";

interface AccStateSelectProps extends AccInputProps {
  country: string;
  handleChange: Function;
}

const AccStateSelect = (props: AccStateSelectProps) => {
  return (
    <AccInput
      {...props}
      select
    >
      <MenuItem
        value="US"
      >
        United States
      </MenuItem>
      <hr
        style={{ marginLeft: "30px", marginRight: "30px" }}
      />
      {COUNTRIES.map(item =>
        <MenuItem
          key={item.value}
          value={item.value}
        >
          {item.label}
        </MenuItem>,
      )}
    </AccInput>
  );
};

export default AccStateSelect;