import React from "react";
import { Divider, Typography } from "@material-ui/core";
import "./styles.css";
import COLORS from "../../colors";
import { Link } from "react-router-dom";
import moment from "moment";
import { Asset } from "../../types";
import { AssetStatus } from "../../../enums";
import Chip from "@mui/material/Chip";
import AccessCard from "../AccessCard";
import { formatPercentage, isPeriodOver24Months } from "../../utilities";
import CustomTooltip from "../CustomTooltip";
import InvestmentCountdown from "../InvestmentCountdown";

interface Props {
    item: Asset;
    loading?: boolean;
}

interface ColorTooltipVariant {
    background: string;
    color: string;
    tooltip: string;
}

const InDepthInvestmentOpportunity: React.FC<Props> = ({
    item,
    loading,
}) => {

    const showAdditionalInvestmentData = item.assetStatus.name === AssetStatus.AVAILABLE
        || item.assetStatus.name === AssetStatus.COMING_SOON;

    const fundedPercentage: number | null = loading ? null :
        parseFloat((100 - (item.remainingAmount / item.totalInvestmentAmount!) * 100).toFixed(0));

    const projections = [
        { description: "Targeted IRR", value: formatPercentage(item.targetedIRR) },
        { description: "Targeted Average Cash Yield", value: formatPercentage(item.targetedAverageCashYield) },
        { description: "Targeted MoIC", value: `${item.targetedMOIC}x` },
        // { description: "Targeted Maturity", value: (moment(item.targetedMaturity).format("MMMYY")).toUpperCase() },
        { description: "Targeted Investment Period", value: isPeriodOver24Months(item.targetedInvestmentPeriod) },
    ];

    const assetDetails = [
        { description: item.assetType.assetClass.name, value: "Asset Class" },
        { description: item.assetType.name, value: "Asset Type" },
        { description: item.marketType, value: "Market Type" },
        {
            description: showAdditionalInvestmentData ? moment(item.targetedFundingDate).format("MM/DD/YYYY") : null,
            value: showAdditionalInvestmentData ? "Targeted Funding Date" : null,
        },
    ];

    const colorTooltipVariant: ColorTooltipVariant = {
        background: "",
        color: "",
        tooltip: "",
    };

    switch (item.assetStatus.name) {
        case AssetStatus.COMPLETED: {
            colorTooltipVariant.background = COLORS.ultralightGreen;
            colorTooltipVariant.color = COLORS.darkBlue;
            colorTooltipVariant.tooltip = "";
            break;
        }
        case AssetStatus.ACTIVE: {
            colorTooltipVariant.background = COLORS.lightGreen;
            colorTooltipVariant.color = COLORS.darkBlue;
            colorTooltipVariant.tooltip = "No longer available for investment";
            break;
        }
        case AssetStatus.FULLY_SUBSCRIBED: {
            colorTooltipVariant.background = COLORS.lightTeal;
            colorTooltipVariant.color = COLORS.green;
            colorTooltipVariant.tooltip = "No longer available for investment";
            break;
        }
        case AssetStatus.AVAILABLE: {
            colorTooltipVariant.background = COLORS.green;
            colorTooltipVariant.color = COLORS.darkBlue;
            colorTooltipVariant.tooltip = "Available for investment";
            break;
        }
        case AssetStatus.COMING_SOON: {
            colorTooltipVariant.background = COLORS.lightTeal;
            colorTooltipVariant.color = COLORS.darkBlue;
            colorTooltipVariant.tooltip = "Investment availability coming soon";
            break;
        }
    }

    return (
        <Link
            to={`/investor/invest/opportunity/${item.assetId}`}
            className="row m-bottom-50">
            <AccessCard
                image={item.coverImageLocation}
                vertical={item.assetType.assetClass.vertical.name}
                className="m-right-30" />
            <div
                className="m-right-30 details-box"
                style={{ background: COLORS.darkBlueGradient }}
            >
                <div className="width-100 row stretch center m-bottom-30">
                    <Typography
                        variant="h5"
                        className="acc-white bold asset-title">
                        {item.name}
                    </Typography>
                    <CustomTooltip
                        title={colorTooltipVariant.tooltip}
                        placement="left-end"
                        background={COLORS.white}
                        color={COLORS.darkBlue}
                        fontSize={11}
                    >
                        <Chip
                            label={(item.assetStatus.name)}
                            size="small"
                            style={{
                                backgroundColor: colorTooltipVariant.background,
                                color: colorTooltipVariant.color, ...styles.chip as React.CSSProperties,
                            }} />
                    </CustomTooltip>
                </div>
                <div className="row start stretch width-100 m-bottom-30">
                    {assetDetails.map(item =>
                        <div
                            className="column start"
                            key={item.value}>
                            <Typography className="acc-white bold asset-details">{item.value}</Typography>
                            <Typography className="acc-lightgreen asset-details">{item.description}</Typography>
                        </div>,
                    )}
                </div>
                <Divider
                    variant="middle"
                    style={{ width: "100%", margin: 0, backgroundColor: COLORS.lightGray }} />
                <div className="row start stretch width-100 m-top-30">
                    {projections.map(item =>
                        <div
                            className="column start width-20"
                            style={{ marginRight: 10 }}
                            key={item.description}>
                            <Typography
                                variant="h5"
                                className="acc-white bold projection-details">
                                {item.value}
                            </Typography>
                            <Typography
                                className="acc-white light projection-description">
                                {item.description}
                            </Typography>
                        </div>,
                    )}
                </div>
            </div>
            <div
                className="timer-box row center"
                style={{ background: COLORS.darkBlueGradient }}>
                <div
                    className="width-100 column center">
                    <InvestmentCountdown
                        showLoader={loading ?? false}
                        asset={item}
                        fundedPercentage={fundedPercentage ?? 0}
                        showAdditionalInvestmentData={showAdditionalInvestmentData}
                    />
                </div>
            </div>
        </Link>
    );
};

const styles = {
    image: {
        borderRadius: "16px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        //margin: "0px 30px 0px 0px",
    },
    chip: {
        width: "140px",
        fontWeight: 500,
    },
};

export default InDepthInvestmentOpportunity;