import { useMutation } from "@apollo/client";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik, Form, Field, FormikValues } from "formik";
import React, { useState, ChangeEvent, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DELETE_ASSET_PARTNER, UPDATE_ASSET_PARTNER, CREATE_ASSET_PARTNER } from "../../../api/query/AssetPartners";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import CancelConfirmDialogModal from "../../../common/components/CancelConfirmDialogModal";
import { isNil } from "../../../common/utilities";
import { EditModeTypes } from "../../../enums";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import COLORS from "../../../common/colors";
import { useQueryClient } from "react-query";
import { QueryKeys, useAssetPartner } from "../../../api/hooks/graphqlHooks";
import AccInput from "../../../common/components/AccInput";
import { Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import { faFileImage } from "@fortawesome/pro-regular-svg-icons";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";
import PageWrapper from "../../../common/components/PageWrapper";

type AssetPartnerDetailParams = {
  id?: string;
};

type AssetPartnerDetailProps = RouteComponentProps<AssetPartnerDetailParams>;

const AssetPartnerForm: React.FC<AssetPartnerDetailProps> = withErrorBoundary({}, ({ match, history }: AssetPartnerDetailProps) => {
  const editMode: EditModeTypes = isNil(match.params.id) ? EditModeTypes.ADD : EditModeTypes.EDIT;
  const queryClient = useQueryClient();
  if (editMode === EditModeTypes.ADD) {
    queryClient.removeQueries(QueryKeys.ASSET_PARTNER);
  }
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false);
  const [logoImagePreview, setLogoImagePreview] = useState<null | string | ArrayBuffer>(null);
  const [uploadedLogoImage, setUploadedLogoImage] = useState<any>();
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const {
    data: getAssetPartnerData,
    isLoading: getAssetPartnerLoading,
    isFetching: getAssetPartnerFetching,
    isError: getAssetPartnerError,
  } = useAssetPartner(Number(match.params.id), {
    enabled: editMode === EditModeTypes.EDIT,
    refetchOnMount: true,
  });

  const [createAssetPartner] = useMutation(CREATE_ASSET_PARTNER);
  const [deleteAssetPartner] = useMutation(DELETE_ASSET_PARTNER);
  const [updateAssetPartner] = useMutation(UPDATE_ASSET_PARTNER);

  useEffect(() => {
    const logoImageLocation = getAssetPartnerData?.getAssetPartner?.coverLogoImageLocation;
    if (logoImageLocation) {
      setLogoImagePreview(logoImageLocation);
    }
  }, [getAssetPartnerData]);

  const BLANK_FORM_VALUES = {
    name: "",
    description: "",
    title: "",
    coverLogoImageLocation: "",
    website: "",
  };

  const initialFormFieldValues = () => (getAssetPartnerData?.getAssetPartner ? {
    name: getAssetPartnerData?.getAssetPartner.name,
    description: getAssetPartnerData?.getAssetPartner.description,
    title: getAssetPartnerData?.getAssetPartner.title,
    coverLogoImageLocation: null,
    website: getAssetPartnerData?.getAssetPartner.website,
  } : BLANK_FORM_VALUES);

  const openCancelDialog = () => setCancelDialogVisible(true);
  const closeCancelDialog = () => setCancelDialogVisible(false);

  const handleDelete = async (objectId: string | number) => {
    try {
      await deleteAssetPartner({
        variables: {
          id: objectId,
        },
      });
      toast("Asset partner deleted successfully!", {
        type: toast.TYPE.SUCCESS,
      });
      queryClient.refetchQueries(QueryKeys.ASSET_PARTNERS);
      history.push("/admin/assetpartners");
    } catch (err: any) {
      toast(err.message || err.msg || err.toString() || "There was an error deleting asset partner.", {
        type: toast.TYPE.WARNING,
      });
    }
  };

  const submitForm = async (values: FormikValues, { resetForm }: any) => {
    if (editMode === EditModeTypes.ADD) {
      try {
        await createAssetPartner({
          variables: {
            uploadedLogoImage,
            input: {
              name: values.name,
              description: values.description,
              title: values.title,
              website: values.website,
            },
          },
        });
        toast("Asset partner added successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        resetForm();
        queryClient.refetchQueries(QueryKeys.ASSET_PARTNERS);
        history.push("/admin/assetpartners");
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error adding asset partner.", {
          type: toast.TYPE.ERROR,
        });
      }
    } else if (editMode === EditModeTypes.EDIT) {
      try {
        await updateAssetPartner({
          variables: {
            uploadedLogoImage,
            id: getAssetPartnerData?.getAssetPartner.id,
            input: {
              name: values.name,
              description: values.description,
              title: values.title,
              website: values.website,
            },
          },
        });
        toast("Asset partner updated successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        queryClient.refetchQueries(QueryKeys.ASSET_PARTNERS);
        history.push("/admin/assetpartners");
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error updating asset partner.", {
          type: toast.TYPE.ERROR,
        });
      }
    }
  };

  const setUserAttachImageMessage = () => {
    if (!logoImagePreview) {
      toast("Logo image is required.", {
        type: toast.TYPE.WARNING,
      });
    }
  };

  const handlePictureUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const uploadEvent = e.target as HTMLInputElement;
    if (uploadEvent.files?.length) {
      const uploadedFile = uploadEvent.files[0];
      const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];
      if (uploadedFile && ALLOWED_TYPES.includes(uploadedFile.type)) {
        const reader = new FileReader();
        reader.onloadend = async () => {
          setUploadedLogoImage(uploadedFile);
          setLogoImagePreview(reader.result);
        };
        reader.readAsDataURL(uploadedFile);
      } else {
        toast("File not supported!", {
          type: toast.TYPE.WARNING,
        });
      }
    }
  };

  return (
    <PageWrapper
      title={`${editMode === EditModeTypes.ADD ? "Add" : "Update"} Asset Partner`}
      showLoader={getAssetPartnerLoading || getAssetPartnerFetching}
      showError={getAssetPartnerError}
      errorMessage="Cannot retrieve asset partners"
    >
      <br />
      <div>
        <Formik
          initialValues={initialFormFieldValues()}
          enableReinitialize
          onSubmit={submitForm}
        >
          {() => (
            <Form>
              <div className="column-reverse-mobile">
                <div className="row stretch center width-100 column-mobile">
                  <Link
                    to="/admin/assetpartners"
                    className="width-100-mobile">
                    <AccButton
                      color={AccButtonColors.WHITE}
                      label="Cancel"
                      style={{ backgroundColor: COLORS.white }}
                    />
                  </Link>
                  <div className="row width-100-mobile column-mobile">
                    <AccButton
                      color={AccButtonColors.GREEN}
                      type="submit"
                      label={editMode === EditModeTypes.ADD ? "Submit" : "Update"}
                      className="m-top-15-mobile m-bottom-15-mobile"
                      onClick={() => setUserAttachImageMessage()}
                    />
                    {editMode === EditModeTypes.EDIT
                      ? (
                        <>
                          <AccButton
                            color={AccButtonColors.RED_OUTLINE}
                            label="Delete"
                            className="delete-button m-left-15 display-none-mobile"
                            type="button"
                            onClick={openCancelDialog}
                          />
                          {mobileLayout
                            && (
                              <AccButton
                                color={AccButtonColors.RED_OUTLINE}
                                label="Delete Asset Partner"
                                className="delete-button"
                                type="button"
                                width="40%"
                                style={styles.mobileDeleteButton}
                                onClick={openCancelDialog}
                              />
                            )}
                        </>
                      ) : null}
                    {getAssetPartnerData ? <CancelConfirmDialogModal
                      isVisible={cancelDialogVisible}
                      dialogBody="Are you sure you would like to delete this Asset Partner?"
                      onClickCancel={closeCancelDialog}
                      onClickDelete={() => handleDelete(getAssetPartnerData?.getAssetPartner.id)}
                    /> : null}
                  </div>
                </div>
                <br />
                <div
                  style={styles.border}
                  className="width-60 width-100-mobile">
                  <Field
                    name="name"
                    type="text"
                    label="Asset Partner Name"
                    as={AccInput}
                    required
                  />
                  <Field
                    name="description"
                    type="text"
                    label="Description"
                    as={AccInput}
                    multiline
                    minRows={5}
                    required
                  />
                  <Field
                    name="title"
                    type="text"
                    label="Title"
                    as={AccInput}
                    required
                  />
                  <Field
                    name="website"
                    type="text"
                    label="Website"
                    as={AccInput}
                    required
                  />
                  <div className="width-100 column">
                    <div className="width-100 stretch row center">
                      <Typography variant="subtitle1">Logo</Typography>
                    </div>
                    <div
                      className="column imgPreview m-bottom-30"
                      style={{
                        ...styles.paper,
                        background: logoImagePreview
                          ? `url("${logoImagePreview}") no-repeat center/cover`
                          : "transparent",
                      }}
                    >
                      {!logoImagePreview && (
                        <>
                          <label
                            htmlFor="fileUpload"
                            className="acc-darkblue column center"
                            style={styles.imageInputLabel}
                          >
                            <div className="m-bottom-15">
                              <FontAwesomeIcon
                                size="lg"
                                className="acc-darkblue m-right-15"
                                icon={faFileImage}
                              />
                              Upload Logo Image
                            </div>
                            <Typography className="acc-grey light">Supported file types: png, jpeg, jpg</Typography>
                          </label>
                          <input
                            type="file"
                            className="display-none"
                            accept="image/*"
                            name="file"
                            id="fileUpload"
                            required
                            formEncType="multipart/form-data"
                            onChange={handlePictureUpload}
                          />
                        </>
                      )}
                    </div>
                    {logoImagePreview && (
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        color={COLORS.red}
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          bottom: 275,
                          right: "-48%",
                          zIndex: 3,
                        }}
                        onClick={() => setLogoImagePreview(null)} />
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </PageWrapper>
  );
});

const styles = {
  border: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    padding: "15px",
  },
  imageInputLabel: {
    cursor: "pointer",
    fontSize: "20px",
  },
  paper: {
    width: "100%",
    height: "250px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    justifyContent: "center",
    alignItems: "center",
  },
  mobileDeleteButton: {
    alignSelf: "flex-start",
    border: "none",
    textAlign: "left",
    padding: 0,
    height: 20,
  },
};

export default withRouter(AssetPartnerForm);
