import { makeGraphqlRequest } from "../../utils";
import { gql as gqlv2 } from "graphql-request";
import gql from "graphql-tag";


const GET_EXTERNAL_ACCOUNTS_V2 = gqlv2`
  query GetExternalAccounts($walletId: String!) {
    getExternalAccounts(walletId: $walletId) {
      displayName
      id
      lastFour
      bankName
      externalAccountId
      walletObjectId
    }
  }
`;

export const getExternalAccounts = (walletId: number) => makeGraphqlRequest(GET_EXTERNAL_ACCOUNTS_V2, { walletId: walletId.toString() });

export const DELETE_EXTERNAL_ACCOUNT = gql`
  mutation DeleteExternalAccount($id: String!) {
    deleteExternalAccount(_id: $id) {
      id
      externalAccountId
    }
  }
`;