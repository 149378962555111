import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import AccButton, { AccButtonColors } from "../../../../common/components/AccButton";
import PageCard from "../../../../common/components/PageCard";
import { isNotNil } from "../../../../common/utilities";
import { Box, Stepper, Step, StepLabel, StepContent } from "@mui/material";
import { useHistory } from "react-router-dom";
import COLORS from "../../../../common/colors";
import { Wallet } from "../../../../common/types";
import AccessLogo from "../../../../assets/images/illustrations/access_card_white_small.svg";
import { faCogs } from "@fortawesome/pro-solid-svg-icons";
import "./styles.css";

interface Props {
  walletData: Wallet[];
  walletFundingsData: any;
  accreditedInvestorData: any;
}

const DashboardStepper: React.FC<Props> = ({ walletData, walletFundingsData, accreditedInvestorData }) => {
  const history = useHistory();

  const activeStep: number = useMemo(() => {
    const walletItems = walletData;
    const firstWalletItem = walletItems?.find(item => isNotNil(item?.umbAccountId)) ?? walletItems?.[0];
    if (isNotNil(firstWalletItem?.umbAccountId)) {
      // Technically a wallet cant exist if theres not a UMB account ID anymore
      // if (firstWalletItem.umbAccountId) {
      if (accreditedInvestorData?.getAccreditedInvestorRequests.length! > 0) {
        if (isNotNil(walletFundingsData?.getWalletFundings)) {
          return 4; // active step 4 represents stepper was completed
        } else if (firstWalletItem && isNotNil(firstWalletItem?.externalAccounts)) {
          return 3;
        } else {
          return 2;
        }
      } else return 1;
      // } else return 0;
    } else return 0;

  }, [walletData]);

  const steps = [
    {
      label: activeStep === 0 ? "Finish setting up your investment profile" : "Account setup completed",
      buttonAction: () => history.push("/investor/accounts/onboard"),
      buttonLabel: "Set Up Investment Profile",
    },
    {
      label: activeStep < 2 ? "Accreditation verification" : "Accreditation verification completed",
      buttonAction: () => history.push(`/investor/accreditation/${walletData[0].id}`),
      buttonLabel: "Complete Accreditation",
    },
    {
      label: activeStep < 3 ? "Connect Bank Account" : "Bank Account Connected",
      buttonAction: () => history.push("/investor/wallet"),
      buttonLabel: "Connect Account",
    },
    {
      label: activeStep < 4 ? "Fund Investment Account" : "Investment Account Funded",
      buttonAction: () => history.push("/investor/accounts"),
      buttonLabel: "Fund Account",
    },
  ];

  return (
    <>
      {activeStep === 4 ? null :
        <PageCard
          className="m-bottom-50"
          style={{
            display: "flex",
            background: COLORS.darkBlueGradient,
            position: "relative",
            overflow: "hidden",
            padding: "25px",
          }}>
          <FontAwesomeIcon
            style={styles.backgroundIcon as React.CSSProperties}
            icon={faCogs} />
          <div
            className="row stretch center width-100"
            style={{ position: "relative" }}>
            <Box>
              <Stepper
                activeStep={activeStep}
                orientation="vertical">
                {steps.map(({ label, buttonAction, buttonLabel }) => (
                  <Step
                    key={label}
                    sx={styles.stepLabel}>
                    <StepLabel
                      style={{ fontSize: 18 }}
                    >
                      {label}
                    </StepLabel>
                    <StepContent>
                      <Box sx={{ mb: 0, mt: 1 }}>
                        <AccButton
                          color={AccButtonColors.GREEN}
                          onClick={buttonAction}
                          label={buttonLabel}
                          className="shine-effect"
                        />
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <img
              className="display-none-mobile"
              src={AccessLogo}
              style={{ height: "200px", paddingRight: 40 }} />
          </div>
        </PageCard>}
    </>
  );
};

const styles = {
  backgroundIcon: {
    position: "absolute",
    top: "40px",
    right: "-30px",
    fontSize: "16em",
    color: "#1bd0ae",
    opacity: "0.25",
  },
  nextButton: {
    borderRadius: "16px",
    color: COLORS.darkBlue,
    textTransform: "none",
    height: "45px",
    boxShadow: "none",
    fontWeight: 600,
  },
  stepLabel: {
    "& .MuiStepIcon-root.Mui-active": {
      color: "#1bd0ae !important",
      fontWeight: 600,
      fontSize: "24px !important",
    },
    "& .MuiStepLabel-root .Mui-active": {
      fontWeight: 600,
      fontSize: 18,
      color: COLORS.white,
    },
    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
      fill: COLORS.darkBlue, // circle's number (ACTIVE)
      fontWeight: 600,
      color: COLORS.white,
    },
    "& .MuiStepLabel-root .Mui-completed": {
      fontWeight: 600,
      color: COLORS.white,
    },
    "& .MuiStepIcon-root.Mui-completed": {
      color: COLORS.green,
    },
    "& .MuiStepLabel-labelContainer": {
      color: COLORS.white,
    },
    "& .MuiStepIcon-root": {
      color: COLORS.white,
    },
    "& .MuiStepIcon-text": {
      fill: COLORS.lightGray,
    },
    "& .Mui-disabled": {
      fontSize: "16px",
    },
  },
};

export default DashboardStepper;
