import { Typography } from "@mui/material";
import React from "react";
import COLORS from "../../colors";
import { NUMERICAL_AMOUNTS } from "../../constants";
import { Asset } from "../../types";
import { formatCurrency } from "../../utilities";
import LabeledFigure from "../LabeledFigure";
import ProgressBar from "../ProgressBar";
import Timer from "../Timer";

interface Props {
  asset: Asset;
  fundedPercentage: number;
  showLoader: boolean;
  showAdditionalInvestmentData: boolean;
}

const InvestmentCountdown: React.FC<Props> = ({
  asset,
  fundedPercentage,
  showLoader,
  showAdditionalInvestmentData,
}) => (
  <>
    <Typography
      variant="h3"
      className="acc-lightgreen bold">
      {`${formatCurrency(asset.totalInvestmentAmount / NUMERICAL_AMOUNTS.ONE_MILLION)}MM`}
    </Typography>
    <Typography
      variant="subtitle1"
      className="acc-white bold">
      Total Investment Amount
    </Typography>
    {showAdditionalInvestmentData ?
      <>
        <div
          className="width-100 m-top-30"
        >
          <LabeledFigure
            description="Remaining"
            value={`${formatCurrency(asset.remainingAmount / NUMERICAL_AMOUNTS.ONE_MILLION)}MM`}
            className="acc-white"
            containerStyles={{
              flexDirection: "row",
              justifyContent: "center",
            }}
            alignment="end"
            valueStyles={{ fontSize: 20, marginRight: 10 }}
            descriptionStyles={{ color: COLORS.white }}
          />
          <div className="m-top-15">
            <ProgressBar
              value={fundedPercentage}
              variant="determinate"
            />
          </div>
        </div>
        <div className="column center width-100 m-top-30">
          <Timer
            loading={showLoader}
            endDate={asset.targetedFundingDate}
          />
        </div>
      </> : <></>
    }
  </>
);

export default InvestmentCountdown;
