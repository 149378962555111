import Divider from "@mui/material/Divider";
import React from "react";
import { Link } from "react-router-dom";
import { useWallets } from "../../../api/hooks/graphqlHooks";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { DataGridStyles, renderEditCell } from "../Assets";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import useMediaQuery from "@mui/material/useMediaQuery";
import { formatCurrency } from "../../../common/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { WalletStatus } from "../../../enums";
import CustomTooltip from "../../../common/components/CustomTooltip";
import COLORS from "../../../common/colors";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";
import PageWrapper from "../../../common/components/PageWrapper";

interface Props {

}

const Wallets: React.FC<Props> = withErrorBoundary({}, () => {

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const { data, isLoading, isFetching, isError } = useWallets();

  const columns: GridColDef[] = [
    renderEditCell("/admin/wallets/"),
    {
      field: "walletStatus", headerName: "Status", width: 80, hide: mobileLayout, renderCell: (item) => {

        return <CustomTooltip
          title={
            item?.row?.walletStatus === WalletStatus.OPEN ? (<span>OPEN</span>) : (<span>CLOSED</span>)
          }
          background={COLORS.darkBlue}
          color={COLORS.green}
          placement="right"
          fontSize={16}
          fontWeight={600}>
          <FontAwesomeIcon
            className={item?.row?.walletStatus === WalletStatus.OPEN ? "acc-green" : "acc-red"}
            icon={faCircle}
            style={{ marginLeft: 20, fontSize: 12 }} />
        </CustomTooltip>;
      },
    },
    { field: "name", headerName: "Name", width: 250 },
    { field: "account", headerName: "Account", width: 300, hide: mobileLayout },
    { field: "availableAmount", headerName: "Available amount", width: 250, hide: mobileLayout },
    { field: "walletType", headerName: "Wallet Type", width: 200, hide: mobileLayout },
  ];

  const rows = data?.getWallets?.map(row =>
  ({
    ...row,
    name: row.name,
    account: row.account?.name,
    availableAmount: formatCurrency(row.availableAmount),
    walletType: row.walletType?.name,
    walletStatus: row.walletStatus?.name,
    id: row.walletId,
  }),
  );

  return (
    <PageWrapper
      title="Wallets"
      showLoader={isLoading || isFetching}
      showError={isError}
      errorMessage="Cannot retrieve wallets"
    >
      <div style={{ height: "80vh", width: "100%" }}>
        <div className="row-mobile stretch">
          <div style={{ justifyContent: "flex-start" }}>
          </div>
          <div
            className="row"
            style={{ justifyContent: "flex-end" }}>
            <Link to="/admin/wallets/add">
              <AccButton
                color={AccButtonColors.GREEN}
                label="Add"
              />
            </Link>
          </div>
        </div>
        <br />
        <Divider />
        <br />
        <DataGrid
          rows={rows || []}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            panel: DataGridStyles.panel,
          }}
          sx={DataGridStyles.toolBar}
        />
      </div>
    </PageWrapper>
  );
});

export default Wallets;
