import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import AvatarMenu from "../common/components/AvatarMenu";
import NavigationPane from "../common/components/NavigationPane";
import NotificationCenter from "../common/components/NotificationCenter";
import { SessionContext } from "../context";
import Login from "../screens/Auth/Login";
import Registration from "../screens/Registration";
import moment from "moment";
import { THEME_BREAKPOINTS } from "../common/constants";
import { NavPaneContext } from "../context";
import { InvestorRoutes } from "./InvestorRoutes";
import { AdminRoutes } from "./AdminRoutes";
import { UserTypes } from "../common/types";
import ForgotPassword from "../screens/ForgotPassword";
import ResetPassword from "../screens/ResetPassword";
import EmailVerification from "../screens/Profile/ResetEmail/EmailVerification";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const ApplicationContainer: React.FC<Props> = ({ children }) => {
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const { open, setOpen } = useContext(NavPaneContext);

  return (
    <>
      <NotificationCenter />
      <AvatarMenu />
      <div
        style={{ display: mobileLayout ? "" : "flex" }}
      >
        <NavigationPane />
        <div
          className={open ? "overlay" : ""}
          style={{ display: mobileLayout ? "block" : "none" }}
          onClick={() => setOpen(false)}
        ></div>
        <div
          className="m-top-100-mobile m-top-75"
          style={{
            marginLeft: "5%", marginRight: "5%", marginBottom: "20vh", width: "90%", minWidth: mobileLayout ? undefined : 1200,
          }}
        >
          {children}
        </div>
      </div>
    </>
  );
};

interface RouterProps {
  notificationCenterVisible: boolean;
  avatarMenuVisible: boolean;
}

const AppRouter: React.FC<RouterProps> = () => {
  const { user } = useContext(SessionContext);
  const monthsSincePasswordUpdated = moment().diff(user?.pwdSetDate, "months");

  const userIsAdmin = user?.userType?.name === UserTypes.ADMIN || user?.userType?.name === UserTypes.ASSET_MANAGER;

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route
          exact
          path="/registration">
          <Registration />
        </Route>
        <Route
          exact
          path="/verification/:token">
          <EmailVerification />
        </Route>
        <Route
          exact
          path="/forgotPassword">
          <ForgotPassword />
        </Route>
        <Route
          exact
          path="/resetPassword">
          <ResetPassword />
        </Route>
        {!(user?.email || localStorage.getItem("user")) ? (
          <Route path="/">
            <Login />
          </Route>
        ) : (
          (!(user?.email || localStorage.getItem("user"))
            && user.pwdSetDate || monthsSincePasswordUpdated <= 6
          ) ? (
            <ApplicationContainer>
              {userIsAdmin ? <AdminRoutes /> : <InvestorRoutes />}
            </ApplicationContainer>
          ) : (
            <Route
              exact
              path="*">
              <ResetPassword />
            </Route>
          )
        )}
      </Switch>
    </Router>
  );
};

// Workaround in order to use navigation context inside router (AppRouter is unable to utilize useLocation hook)
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default AppRouter;
