import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  text: string;
  link: string;
  icon: IconProp;
  containerStyle?: React.CSSProperties;
  linkStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
}

export interface TitleLinkInput {
  title: string;
  link: any;
}

const ClickableLinkWithIcon: React.FC<Props> = ({
  text,
  link,
  icon,
  containerStyle,
  linkStyle,
  iconStyle,
}) => {
  return (
    <div
      style={containerStyle}
      className="row acc-lightgray light m-bottom-15">
      <FontAwesomeIcon
        size="lg"
        style={iconStyle}
        className="acc-red m-right-15"
        icon={icon} />
      <Typography style={{ textDecoration: "underline" }}>
        <a
          style={linkStyle}
          target="_blank"
          href={link}
          rel="noreferrer">
          {text}
        </a>
      </Typography>
    </div>
  );
};

export default ClickableLinkWithIcon;
