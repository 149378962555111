import { faTrashAlt, faUniversity } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import React from "react";
import COLORS from "../../colors";
import { ExternalAccount } from "../../types";
import PageCard from "../PageCard";

interface Props {
  data: ExternalAccount;
  deleteButtonVisible?: boolean;
  onClick?: () => void;
  style?: {};
  deleteOnClick?: () => void;
}


const BankAccountCard: React.FC<Props> = ({ data, deleteButtonVisible, onClick, style, deleteOnClick }) => {

  return (
    <PageCard
      onClick={onClick}
      key={data.id}
      style={{ ...style, padding: 20, cursor: onClick ? "pointer" : "" }}
      className="row stretch center m-bottom-15 width-100">
      <div
        style={{ width: deleteButtonVisible ? "60%" : "100%" }}
        className="row stretch center">
        <div className="row center">
          <FontAwesomeIcon
            className="acc-green m-right-15"
            size="lg"
            icon={faUniversity} />
          <Typography
            variant="subtitle1"
            className="acc-gray bold">
            {data.bankName}
          </Typography>
          <Typography
            variant="subtitle1"
            className="acc-gray display-none-mobile"
            style={{ marginLeft: 15 }}>
            {data.displayName}
          </Typography>
        </div>
        <Typography
          variant="caption"
          className="acc-gray light">
          xxxx{data.lastFour}
        </Typography>
      </div>
      <div
        className="width-20 column end width-30-mobile"
        style={{ display: deleteButtonVisible ? "" : "none", cursor: "pointer" }}
      >
        <Typography
          variant="subtitle2"
          className="acc-gray"
          onClick={deleteOnClick}
        >
          Remove
          <FontAwesomeIcon
            icon={faTrashAlt}
            color={COLORS.red}
            className="m-left-15" />
        </Typography>
      </div>
    </PageCard>
  );
};

export default BankAccountCard;