import gql from "graphql-tag";
import { gql as gqlv2 } from "graphql-request";
import { makeGraphqlRequest } from "../../utils";


export const CREATE_ASSET = gql`
    mutation CreateAsset(
      $input: AssetInput!, 
      $uploadedCoverImage: Upload, 
      $uploadedDisplayImages: [Upload], 
      $uploadedDocuments: [Upload],
      $uploadedPpm: Upload, 
      $uploadedAdv: Upload,
      $uploadedLpa: Upload,
      $uploadedBackupInvestmentDocument: Upload
      ) {
        createAsset(
          input: $input, 
          uploadedCoverImage: $uploadedCoverImage, 
          uploadedDisplayImages: $uploadedDisplayImages, 
          uploadedDocuments: $uploadedDocuments,
          uploadedPpm: $uploadedPpm, 
          uploadedAdv: $uploadedAdv,
          uploadedLpa: $uploadedLpa,
          uploadedBackupInvestmentDocument: $uploadedBackupInvestmentDocument,
          ) {
          id
          assetId
          name
          summary
        }
      }
  `;

export const UPDATE_ASSET = gql`
  mutation UpdateAsset(
    $id: String!, 
    $input: AssetInput!, 
    $uploadedCoverImage: Upload, 
    $uploadedDisplayImages: [Upload], 
    $existingDisplayImages: [String], 
    $uploadedDocuments: [Upload],
    $existingDocuments: [String],
    $uploadedPpm: Upload,
    $uploadedAdv: Upload,
    $uploadedLpa: Upload,
    $uploadedBackupInvestmentDocument: Upload,
    ) {
    updateAsset(
      _id: $id, 
      input: $input, 
      uploadedCoverImage: $uploadedCoverImage,
      uploadedDisplayImages: $uploadedDisplayImages, 
      existingDisplayImages: $existingDisplayImages, 
      uploadedDocuments: $uploadedDocuments,
      existingDocuments: $existingDocuments,
      uploadedPpm: $uploadedPpm,
      uploadedAdv: $uploadedAdv,
      uploadedLpa: $uploadedLpa,
      uploadedBackupInvestmentDocument: $uploadedBackupInvestmentDocument,
      ) {
      id
      name
      summary
      tabDescriptions {
        title
        content
      }
      targetedIRR
      targetedAverageCashYield
      targetedMOIC
      targetedMaturity
      targetedFundingDate
      targetedInvestmentPeriod
      totalInvestmentAmount
      investmentJurisdiction
      marketType
      remainingAmount
      minimumInvestmentAmount
      netAssetValue
      investmentAmountIncrement
      videoId
      managementCompany
      investmentStructure
      authorizedInvestors
      spvAccountNumber
      spvRoutingNumber
      coverImageLocation
      assetType {
        name
      }
      assetPartner
      {
        name
      }
      assetStatus{
        name
      }
      assetManagerUser {
        firstName
        lastName
        email
        jobTitle
        linkedInUrl
        avatarImageLocation
      }
      dealType {
        id
        dealTypeId
        name
        taxDocumentType
        generalPartner
        lawFirm
        offeringEntity
        requiresAccreditation
        accreditationConfirmation
        disqualifyingEvent
        helloSignTemplateId
        disqualifyingEventDocumentLocation
      }
    }
  }
`;

export const DELETE_ASSET = gql`
  mutation DeleteAsset($id: String!) {
    deleteAsset(_id: $id) {
      id
      assetId
    }
  }
`;

export const UPDATE_ASSET_STATUS_TO_AVILABLE = gql`
  mutation updateAssetStatusToAvailable($id: String!) {
    updateAssetStatusToAvailable(_id: $id) {
      id      
    }
  }
`;


const GET_ASSET_V2 = gqlv2`
  query GetAsset ($assetId: Int!){
    getAsset(assetId: $assetId) {
      assetId
      id
      name
      summary
      tabDescriptions {
        title
        content
      }
      targetedIRR
      targetedAverageCashYield
      targetedMOIC
      targetedMaturity
      targetedFundingDate
      targetedInvestmentPeriod
      totalInvestmentAmount
      investmentJurisdiction
      marketType
      remainingAmount
      minimumInvestmentAmount
      netAssetValue
      investmentAmountIncrement
      videoId
      managementCompany
      investmentStructure
      coverImageLocation
      assetDocuments
      displayImages
      ppmDocumentLocation
      spvAccountNumber
      spvRoutingNumber
      advDocumentLocation
      lpaDocumentLocation
      backupInvestmentDocumentLocation
      authorizedInvestors
      createdAt
      assetType {
        name
        id
       assetClass {
          name
          id
          vertical {
            name
            id
          }
        }
      }
      assetPartner
      {
        name
        id
        description
        title
        coverLogoImageLocation
        website
      }
      assetStatus{
        name
        id
      }
      assetManagerUser {
        id
        firstName
        lastName
        email
        jobTitle
        linkedInUrl
        avatarImageLocation
      }
      dealType {
        id
        dealTypeId
        name
        taxDocumentType
        generalPartner
        lawFirm
        offeringEntity
        requiresAccreditation
        accreditationConfirmation
        disqualifyingEvent
        helloSignTemplateId
        disqualifyingEventDocumentLocation
        investmentProceduresDocumentLocation
      }
    }
  }
`;
export const getAsset = (assetId: number) => makeGraphqlRequest(GET_ASSET_V2, { assetId });


const GET_ASSETS_V2 = gqlv2`
  query GetAssets {
    getAssets {
      assetId
      id
      name
      summary
      targetedIRR
      targetedAverageCashYield
      targetedMOIC
      targetedMaturity
      targetedFundingDate
      targetedInvestmentPeriod
      totalInvestmentAmount
      investmentJurisdiction
      marketType
      remainingAmount
      minimumInvestmentAmount
      netAssetValue
      spvAccountNumber
      spvRoutingNumber
      investmentAmountIncrement
      videoId
      managementCompany
      investmentStructure
      coverImageLocation
      backupInvestmentDocumentLocation
      createdAt
      assetType {
        name
        id
        assetClass {
          name
          id
          vertical {
            name
            id
          }
        }
      }
      assetPartner {
        name
        description
        title
        coverLogoImageLocation
        website
      }
      assetStatus {
        name
      }
      dealType {
        id
        dealTypeId
        name
        taxDocumentType
        generalPartner
        lawFirm
        offeringEntity
        requiresAccreditation
        accreditationConfirmation
        disqualifyingEvent
        helloSignTemplateId
        disqualifyingEventDocumentLocation
        investmentProceduresDocumentLocation
      }
    }
  }
`;

export const getAssets = () => makeGraphqlRequest(GET_ASSETS_V2);
