import LinearProgress from "@material-ui/core/LinearProgress";
import { createStyles, withStyles } from "@material-ui/core/styles";
import COLORS from "../../colors";

const ProgressBar = withStyles(() => createStyles({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: COLORS.white,
  },
  bar: {
    borderRadius: 5,
    background: COLORS.lightGreen,
  },
}))(LinearProgress);

export default ProgressBar;
