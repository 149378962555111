import { useMutation } from "@apollo/client";
import { faCheck, faFilePdf, faImages, faTrash } from "@fortawesome/pro-light-svg-icons";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { faFileImage } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, InputLabel, OutlinedInput, Select, Typography } from "@material-ui/core";
import { Box, Tab, Tabs } from "@mui/material";
import { MenuItem } from "@mui/material";
import { ListSubheader } from "@mui/material";
import { Formik, Form, Field, FormikValues } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { RouteComponentProps, withRouter } from "react-router";
import { DELETE_ASSET, UPDATE_ASSET, CREATE_ASSET, UPDATE_ASSET_STATUS_TO_AVILABLE } from "../../../api/query/Assets";
import { AssetStatus as AssetStatuses, EditModeTypes } from "../../../enums";
import { formatCurrency, isNil, isNotNil } from "../../../common/utilities";
import CancelConfirmDialogModal from "../../../common/components/CancelConfirmDialogModal";
import ClickableLinkWithIcon, { TitleLinkInput } from "../../../common/components/ClickableLinkWithIcon";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import "./EditorToolbar/TextEditor.css";
import "react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";
import { useMediaQuery } from "@mui/material";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import COLORS from "../../../common/colors";
import { Asset, AssetType, AssetPartner, AssetStatus, UploadTypes, User, DealType } from "../../../common/types";
import { ImageList } from "@mui/material";
import { ImageListItem } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { QueryKeys, useAsset, useAssetTypes, useAssetPartners, useAssetStatuses, useUsers, useDealTypes, useCashFlow } from "../../../api/hooks/graphqlHooks";
import { useQueryClient } from "react-query";
import AccInput from "../../../common/components/AccInput";
import AssetTabViewForm from "./AssetTabViewForm";
import * as Yup from "yup";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";
import PageWrapper from "../../../common/components/PageWrapper";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DataGridStyles } from "./index";
import { CREATE_CASHFLOW, UPDATE_CASHFLOW } from "../../../api/query/CashFlows";
import CashFlowModal from "../../../common/components/CashFlowModal";

type AssetDetailParams = {
  id?: string;
};

type AssetsFormFieldValues = Asset & {
  assetTypeName: string,
  assetTypeId: string,
  assetTypeObjectId: string,
  dealTypeName: string,
  dealTypeId: string,
  dealTypeObjectId: string
  assetPartnerName: string,
  assetPartnerId: string,
  assetPartnerObjectId: string,
  assetStatusName: string,
  assetStatusId: string,
  assetStatusObjectId: string,
  assetManagerUserFirstName: string;
  assetManagerUserLastName: string;
  assetManagerUserFullName: string;
  assetManagerUserId: string;
  assetManagerUserObjectId: string;
}

type AssetDetailProps = RouteComponentProps<AssetDetailParams>;

const AssetForm: React.FC<AssetDetailProps> = withErrorBoundary({}, ({ match, history }: AssetDetailProps) => {

  const [tabValue, setTabValue] = React.useState(0);
  const [isCodeView, setIsCodeView] = React.useState<boolean>(false);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  function formatInput(inputField: any) {
    const numericValue = Number(inputField.value);
    let finalInput;
    if (!isNaN(numericValue)) {
      finalInput = formatCurrency(numericValue);
    } else {
        finalInput = inputField.value;
    }
    return {
        ...inputField.row,
        input: finalInput,
    };
  }

  const columns: GridColDef[] = [
    { field: "month", headerName: "Month", width: 174, hide: false },
    {
      field: "input",
      headerName: "Input",
      width: 174,
      hide: false,
      editable: true,
      align: "left",
      headerAlign: "left",
      valueSetter: formatInput,
      type: "number",
    },
  ];

  const handleDataChange = (e: any) => {
    if (isNotNil(e) && Object.keys(e)?.length !== 0) {
      const newRows = [...rows];
      for (const key in e) {
        if (isNotNil(formattedRows)) {
          const dataObjectToUpdate = { ...newRows[Number(Object.keys(e))], input: Number(e[key.toString()].input.value) ?? 0 };
          newRows[Number(Object.keys(e))] = dataObjectToUpdate;
        }
      }
      setRows(newRows);
    }
  };

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  const queryClient = useQueryClient();

  const editMode: EditModeTypes = isNil(match.params.id) ? EditModeTypes.ADD : EditModeTypes.EDIT;
  if (editMode === EditModeTypes.ADD) {
    queryClient.removeQueries(QueryKeys.ASSET);
  }

  const {
    data: getAssetPartnersData,
    isLoading: getAssetPartnersLoading,
    isFetching: getAssetPartnersFetching,
    isError: getAssetPartnerError,
  } = useAssetPartners();

  const {
    data: getAssetTypeData,
    isLoading: getAssetTypesLoading,
    isFetching: getAssetTypesFetching,
    isError: getAssetTypeError,
  } = useAssetTypes();

  const {
    data: getDealTypesData,
    isLoading: getDealTypesLoading,
    isFetching: getDealTypesFetching,
    isError: getDealTypesError,
  } = useDealTypes();

  const {
    data: getAssetStatusesData,
    isLoading: getAssetStatusesLoading,
    isFetching: getAssetStatusesFetching,
    isError: getAssetStatusesError,
  } = useAssetStatuses();

  const {
    data: getUsersData,
    isLoading: getUsersLoading,
    isFetching: getUsersFetching,
    isError: getUsersError,
  } = useUsers();


  const {
    data: getAssetData,
    isLoading: getAssetLoading,
    isFetching: getAssetFetching,
    isError: getAssetError,
  } = useAsset(Number(match.params.id), {
    enabled: editMode === EditModeTypes.EDIT,
    refetchOnMount: true,
  });

  const {
    data: getCashFlowData,
    isLoading: getCashFlowLoading,
    isFetching: getCashFlowFetching,
    isError: getCashFlowError,
  } = useCashFlow(Number(match.params.id), {
    enabled: editMode === EditModeTypes.EDIT,
    refetchOnMount: true,
  });

  const [rows, setRows] = React.useState<Array<Object>>([]);

  const dynamicTabs = getAssetData?.getAsset.tabDescriptions.map((tab, index) => ({
    value: index,
    label: tab.title,
    editorFieldName: `${tab.title.toLowerCase()}Description`,
    placeholder: `${tab.title} Description*`,
  }));

  const [tabs, setTabs] = useState(dynamicTabs || []);

  const [newTabName, setNewTabName] = useState("");
  const [isAddingNewTab, setIsAddingNewTab] = useState(false);

  const showNewTabInput = () => {
    setIsAddingNewTab(true);
  };

  const hideNewTabInput = () => {
    setIsAddingNewTab(false);
  };

  const handleAddTab = () => {
    const newTabValue = tabs.length;
    const newTab = {
        value: newTabValue,
        label: newTabName,
        editorFieldName: `${newTabName.toLowerCase()}Descriptoin`,
        placeholder: `${newTabName} Descriptoin*`,
    };
    setTabs([...tabs, newTab]);
    setNewTabName("");
    setIsAddingNewTab(false);
  };

  const handleNewTabNameChange = (event: any) => {
    setNewTabName(event.target.value);
  };

  const handleDeleteTab = (tabIndex: number) => {
    const newTabs = tabs.filter((_, index) => index !== tabIndex);
    setTabs(newTabs);
    if(tabValue >= tabIndex) {
        setTabValue(prevValue => prevValue > 0 ? prevValue - 1 : 0);
    }
  };

  useEffect(() => {
    if (getAssetData && getCashFlowData) {
      const targetedInvestmentPeriod = getAssetData?.getAsset?.targetedInvestmentPeriod;
      let copyRows = [];
      for (let i = 0; i < Number(targetedInvestmentPeriod); i++) {
        copyRows.push({
          id: i,
          month: `Month ${i}`,
          input: getCashFlowData?.getCashFlow.cashFlowInputArray[i] || 0,
        });
      }
      setRows(copyRows);

      const tabDescriptions = getAssetData.getAsset.tabDescriptions;
      if (tabDescriptions && tabDescriptions.length > 0) {
        const updatedTabs = tabDescriptions.map((tab, index) => ({
          value: index,
          label: tab.title,
          editorFieldName: `${tab.title.toLowerCase()}Description`,
          placeholder: `${tab.title} Description*`,
        }));
        setTabs(updatedTabs);
      }
    }
  }, [getAssetData, getCashFlowData]);

  let formattedRows: Array<Object> = [];
  for (let i = 0; i < rows.length; i++) {
    formattedRows.push({
      id: i,
      month: `Month ${i}`,
      input: formatCurrency(Object.values(rows[i])[2]),
    });
  }

  const pageLoading =
    getAssetLoading ||
    getAssetFetching ||
    getAssetPartnersLoading ||
    getAssetPartnersFetching ||
    getAssetTypesLoading ||
    getAssetTypesFetching ||
    getDealTypesLoading ||
    getDealTypesFetching ||
    getAssetStatusesLoading ||
    getAssetStatusesFetching ||
    getUsersLoading ||
    getUsersFetching ||
    getCashFlowLoading ||
    getCashFlowFetching
    ;

  const [createAsset] = useMutation(CREATE_ASSET);
  const [updateAsset] = useMutation(UPDATE_ASSET);
  const [updateAssetStatusToAvailable] = useMutation(UPDATE_ASSET_STATUS_TO_AVILABLE);
  const [deleteAsset] = useMutation(DELETE_ASSET);
  const [createCashFlow] = useMutation(CREATE_CASHFLOW);
  const [updateCashFlow] = useMutation(UPDATE_CASHFLOW);


  const fetchedDealTypes: Array<DealType> | undefined = getDealTypesData?.getDealTypes;
  const fetchedAssetTypes: Array<AssetType> | undefined = getAssetTypeData?.getAssetTypes;
  const fetchedAssetPartners: Array<AssetPartner> | undefined = getAssetPartnersData?.getAssetPartners;
  const fetchedAssetStatuses: Array<AssetStatus> | undefined = getAssetStatusesData?.getAssetStatuses;
  const fetchedUsers: Array<User> | undefined = getUsersData?.getUsers;
  const [cancelDialogVisible, setCancelDialogVisibible] = useState(false);

  const openCancelDialog = () => setCancelDialogVisibible(true);
  const closeCancelDialog = () => setCancelDialogVisibible(false);

  const [coverImagePreview, setCoverImagePreview] = useState<null | string | ArrayBuffer>(null);

  const [selectedFiles, setSelectedFiles] = useState<Array<TitleLinkInput>>([]);
  const [rawFiles, setRawFiles] = useState([]);

  const [ppmPreview, setPpmPreview] = useState<TitleLinkInput | null>(null);
  const [uploadedPpm, setUploadedPpm] = useState<any>();

  const [advPreview, setAdvPreview] = useState<TitleLinkInput | null>(null);
  const [uploadedAdv, setUploadedAdv] = useState<any>();

  const [lpaPreview, setLpaPreview] = useState<TitleLinkInput | null>(null);
  const [uploadedLpa, setUploadedLpa] = useState<any>();

  const [backupInvestmentDocumentPreview, setBackupInvestmentDocumentPreview] = useState<TitleLinkInput | null>(null);
  const [uploadedBackupInvestmentDocument, setBackupInvestmentDocument] = useState<any>();

  const [selectedImages, setSelectedImages] = useState<Array<TitleLinkInput>>([]);
  const [rawDisplayImages, setRawDisplayImages] = useState([]);
  const [existingDisplayImages, setExistingDisplayImages] = useState<Array<string>>([]);

  const [uploadedCoverImage, setUploadedCoverImage] = useState<any>();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const BLANK_FORM_VALUES = { requiresAccreditation: false } as Partial<AssetsFormFieldValues>;

  const AssetSchema = Yup.object().shape({
    targetedIRR: Yup.number()
      .positive()
      .min(0, "Targeted IRR must be more than or equal to 0")
      .max(100, "Targeted IRR must be less than or equal to 100"),
    targetedAverageCashYield: Yup.number()
      .positive()
      .min(0, "Targeted Average Cash Yield must be more than or equal to 0")
      .max(100, "Targeted Average Cash Yield must be less than or equal to 100"),
    targetedMOIC: Yup.number()
      .positive()
      .min(0, "Targeted MoIC must be more than or equal to 0")
      .max(100, "Targeted MoIC must be less than or equal to 100"),
    targetedInvestmentPeriod: Yup.number()
      .positive()
      .integer()
      .min(1, "Targeted Investment Period must be more than or equal to 1"),
    totalInvestmentAmount: Yup.number()
      .positive()
      .integer()
      .min(1, "Total investment amount must be higher than 0")
      .transform((value) => (isNaN(value) ? 0 : value)),
      targetedMaturity: Yup.date()
      .min(new Date(), "The date cannot be in the past"),
      targetedFundingDate: Yup.date()
      .min(new Date(), "The date cannot be in the past"),
    minimumInvestmentAmount: Yup.number()
      .positive()
      .integer()
      .transform((value) => (isNaN(value) ? 0 : value))
      .min(1, "Minimum investment amount must be higher than 0")
      .max(Yup.ref("totalInvestmentAmount"), "Minimum investment amount cannot be higher than total investment amount"),
  });

  // Retrieves initial form values for Formik, can be different for edit/create states
  const initialFormFieldValues = () => {
    if (!getAssetData?.getAsset) return BLANK_FORM_VALUES;
    const { assetType, dealType, assetPartner, assetStatus, assetManagerUser, ...initialFormData } = getAssetData?.getAsset ?? {};
    return {
      ...initialFormData,
      coverImageLocation: null,
      ppmDocumentLocation: null,
      advDocumentLocation: null,
      lpaDocumentLocation: null,
      backupInvestmentDocumentLocation: null,
      assetTypeObjectId: assetType.id ?? "ERROR",
      assetTypeName: assetType.name ?? "ERROR",
      assetTypeId: assetType.id ?? "ERROR",
      dealTypeObjectId: dealType.id ?? "ERROR",
      dealTypeName: dealType.name ?? "ERROR",
      dealTypeId: dealType.id ?? "ERROR",
      assetPartnerObjectId: assetPartner.id ?? "ERROR",
      assetPartnerName: assetPartner.name ?? "ERROR",
      assetPartnerId: assetPartner.id ?? "ERROR",
      assetStatusObjectId: assetStatus.id ?? "ERROR",
      assetStatusName: assetStatus.name ?? "ERROR",
      assetStatusId: assetStatus.id ?? "ERROR",
      assetManagerUserObjectId: assetManagerUser ? assetManagerUser.id : "SELECT A USER",
      assetManagerUserId: assetManagerUser ? assetManagerUser.id : "SELECT A USER",
      assetManagerUserFullName: assetManagerUser ? assetManagerUser.firstName + " " + assetManagerUser.lastName : "SELECT A USER",
    };
  };

  const handleUpdateAssetStatus = async (objectId: string) => {
    if (getAssetData?.getAsset?.assetStatus.name === AssetStatuses.ARCHIVED) {
      try {
        await updateAssetStatusToAvailable({
          variables: {
            id: getAssetData?.getAsset.id,
          },
        });
        toast("Asset un-archived successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        queryClient.refetchQueries(QueryKeys.ASSETS);
        history.push("/admin/assets");
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error making asset available", {
          type: toast.TYPE.WARNING,
        });
      }
    } else {
      try {
        await deleteAsset({
          variables: {
            id: objectId,
          },
        });
        toast("Asset archived successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        queryClient.refetchQueries(QueryKeys.ASSETS);
        history.push("/admin/assets");
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error deleting asset", {
          type: toast.TYPE.WARNING,
        });
      }
    }
  };
  const submitForm = async (values: FormikValues) => {
    if (isCodeView) {
      return toast("Exit code view to update asset.", {
        type: toast.TYPE.WARNING,
      });
    }
    const newTabs = tabs.map(tab => ({
        title: tab.label,
        content: values[tab.editorFieldName] ?? "",
    }));

    if (editMode === EditModeTypes.ADD) {
      try {
        setIsSubmitting(true);
        const assetCreated = await createAsset({
          variables: {
            uploadedCoverImage,
            uploadedPpm,
            uploadedAdv,
            uploadedLpa,
            uploadedBackupInvestmentDocument,
            uploadedDisplayImages: rawDisplayImages,
            uploadedDocuments: rawFiles,
            input: {
              name: values.name,
              summary: values.summary,
              tabDescriptions: newTabs,
              targetedIRR: values.targetedIRR,
              targetedAverageCashYield: values.targetedAverageCashYield,
              targetedMOIC: values.targetedMOIC,
              targetedMaturity: values.targetedMaturity,
              targetedFundingDate: values.targetedFundingDate,
              targetedInvestmentPeriod: values.targetedInvestmentPeriod,
              totalInvestmentAmount: values.totalInvestmentAmount,
              minimumInvestmentAmount: values.minimumInvestmentAmount,
              netAssetValue: values.netAssetValue,
              investmentAmountIncrement: values.investmentAmountIncrement,
              investmentJurisdiction: values.investmentJurisdiction,
              marketType: values.marketType,
              videoId: values.videoId,
              managementCompany: values.managementCompany,
              investmentStructure: values.investmentStructure,
              authorizedInvestors: values.authorizedInvestors,
              spvRoutingNumber: values.spvRoutingNumber,
              spvAccountNumber: values.spvAccountNumber,
              coverImageLocation: "NOT NEEDED ANYMORE IN ARGUMENTS, TO REMOVE IN FUTURE",
              assetTypeObjectId: values.assetTypeObjectId,
              dealTypeObjectId: values.dealTypeObjectId,
              assetPartnerObjectId: values.assetPartnerObjectId,
              assetStatusObjectId: values.assetStatusObjectId,
              assetManagerUserObjectId: values.assetManagerUserObjectId,
            },
          },
        });
        if (assetCreated) {
          let newCashFlowArray = [];
          for (let i = 0; i < values.targetedInvestmentPeriod; i++) {
            newCashFlowArray.push(0);
          }
          await createCashFlow({
            variables: {
              input: {
                assetObjectId: assetCreated.data.createAsset.id,
                cashFlowInputArray: newCashFlowArray,
              },
            },
          });
        }
        setIsSubmitting(false);
        toast("Asset added successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        queryClient.refetchQueries(QueryKeys.ASSETS);
        queryClient.refetchQueries(QueryKeys.CASH_FLOW);
        history.push("/admin/assets");
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error adding asset", {
          type: toast.TYPE.ERROR,
        });
      }
    } else if (editMode === EditModeTypes.EDIT) {
      try {
        await updateAsset({
          variables: {
            uploadedCoverImage,
            uploadedPpm,
            uploadedAdv,
            uploadedLpa,
            uploadedBackupInvestmentDocument,
            existingDisplayImages,
            uploadedDisplayImages: rawDisplayImages,
            uploadedDocuments: rawFiles,
            id: getAssetData?.getAsset.id,
            input: {
              name: values.name,
              summary: values.summary,
              tabDescriptions: newTabs,
              targetedIRR: values.targetedIRR,
              targetedAverageCashYield: values.targetedAverageCashYield,
              targetedMOIC: values.targetedMOIC,
              targetedMaturity: values.targetedMaturity,
              targetedFundingDate: values.targetedFundingDate,
              targetedInvestmentPeriod: values.targetedInvestmentPeriod,
              totalInvestmentAmount: values.totalInvestmentAmount,
              minimumInvestmentAmount: values.minimumInvestmentAmount,
              netAssetValue: values.netAssetValue,
              investmentAmountIncrement: values.investmentAmountIncrement,
              investmentJurisdiction: values.investmentJurisdiction,
              marketType: values.marketType,
              videoId: values.videoId,
              spvRoutingNumber: values.spvRoutingNumber,
              spvAccountNumber: values.spvAccountNumber,
              managementCompany: values.managementCompany,
              investmentStructure: values.investmentStructure,
              authorizedInvestors: values.authorizedInvestors,
              coverImageLocation: "NOT NEEDED ANYMORE IN ARGUMENTS, TO REMOVE IN FUTURE",
              assetTypeObjectId: values.assetTypeObjectId,
              dealTypeObjectId: values.dealTypeObjectId,
              assetPartnerObjectId: values.assetPartnerObjectId,
              assetStatusObjectId: values.assetStatusObjectId,
              assetManagerUserObjectId: values.assetManagerUserObjectId,
            },
          },
        });
        if (isNotNil(getAssetData?.getAsset?.id)) {
          await updateCashFlow({
            variables: {
              cashFlowId: getCashFlowData?.getCashFlow.cashFlowId,
              input: {
                cashFlowInputArray: rows.map(val => Object.values(val)[2]),
              },
            },
          });
        }
        toast("Asset updated successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        queryClient.refetchQueries(QueryKeys.ASSET);
        queryClient.refetchQueries(QueryKeys.CASH_FLOW);
        //*Requested by Flo - do not redirect to asset list after updating!!!
        //history.push("/admin/assets");
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error updating asset", {
          type: toast.TYPE.ERROR,
        });
      }
    }
  };

  const setUserMessage = () => {
    if (!coverImagePreview) {
      toast("Cover image is required.", {
        type: toast.TYPE.WARNING,
      });
    }
  };

  const handlePictureUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const uploadEvent = e.target as HTMLInputElement;
    if (uploadEvent.files?.length) {
      const uploadedFile = uploadEvent.files[0];
      const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];
      if (uploadedFile && ALLOWED_TYPES.includes(uploadedFile.type)) {
        const reader = new FileReader();
        reader.onloadend = async () => {
          setUploadedCoverImage(uploadedFile);
          setCoverImagePreview(reader.result);
        };
        reader.readAsDataURL(uploadedFile);
      } else {
        toast("File not supported!", {
          type: toast.TYPE.WARNING,
        });
      }
    }
  };

  const handleSingleFileUpload = async (e: ChangeEvent<HTMLInputElement>, uploadType: UploadTypes) => {
    const uploadEvent = e.target as HTMLInputElement;
    if (uploadEvent.files?.length) {
      const filePreview = {
        title: uploadEvent.files[0].name,
        link: URL.createObjectURL(uploadEvent.files[0]),
      };
      const uploadedFile = uploadEvent.files[0];
      const reader = new FileReader();

      reader.onloadend = async () => {
        switch (uploadType) {
          case UploadTypes.MEMORANDUM:
            setPpmPreview(filePreview);
            setUploadedPpm(uploadedFile);
            break;
          case UploadTypes.ADV:
            setAdvPreview(filePreview);
            setUploadedAdv(uploadedFile);
            break;
          case UploadTypes.LPA:
            setLpaPreview(filePreview);
            setUploadedLpa(uploadedFile);
            break;
          case UploadTypes.BACKUP_INVESTMENT_DOCUMENT:
            setBackupInvestmentDocumentPreview(filePreview);
            setBackupInvestmentDocument(uploadedFile);
            break;
          default:
            break;
        }
      };
      reader.readAsDataURL(uploadedFile);
    }
  };

  const handleMultipleFileChange = (e: ChangeEvent<HTMLInputElement>, uploadType: UploadTypes) => {
    const uploadEvent = e.target as HTMLInputElement;
    const uploadArray: Array<TitleLinkInput> = [];
    const rawFiles: any = [];

    if (uploadEvent.files?.length) {
      for (let i = 0; i < uploadEvent.files.length; i++) {
        rawFiles.push(uploadEvent.files[i]);
        uploadArray.push({
          title: uploadEvent.files[i].name,
          link: URL.createObjectURL(uploadEvent.files[i]),
        });
      }

      switch (uploadType) {
        case UploadTypes.DOCUMENTS:
          setSelectedFiles((prevFiles: any) => prevFiles.concat(uploadArray));
          setRawFiles((prevFiles: any) => prevFiles.concat(rawFiles));
          break;
        case UploadTypes.DISPLAY_IMAGES:
          setSelectedImages((prevFiles: any) => prevFiles.concat(uploadArray));
          setRawDisplayImages((prevFiles: any) => prevFiles.concat(rawFiles));
          break;
        default:
          break;
      }
    }
  };

  const renderFiles = (uploadedFiles: TitleLinkInput[]) => uploadedFiles.map((item, index) => (
    <div
      key={item.link}
      className="row center stretch m-top-15">
      <ClickableLinkWithIcon
        containerStyle={{
          marginBottom: 0,
        }}
        text={item.title}
        link={item.link}
        icon={faFilePdf}
      />
      <button
        style={{ ...styles.button, cursor: "pointer" }}
        onClick={(e) => {
          e.preventDefault(); deleteFile(index);
        }
        }>
        <FontAwesomeIcon
          size="1x"
          className="acc-red"
          icon={faTrash}
        />
      </button>
    </div>
  ));

  const renderSingleFile = (uploadedFile: TitleLinkInput, uploadType: UploadTypes) => {
    return (
      <>
        {isNotNil(uploadedFile) ?
          <div
            className="row center stretch m-top-15">
            <ClickableLinkWithIcon
              containerStyle={{
                marginBottom: 0,
              }}
              text={uploadedFile?.title}
              link={uploadedFile?.link}
              icon={faFilePdf}
            />
            <button
              style={{ ...styles.button, cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                switch (uploadType) {
                  case UploadTypes.MEMORANDUM:
                    setUploadedPpm(null);
                    setPpmPreview(null);
                    break;
                  case UploadTypes.ADV:
                    setUploadedAdv(null);
                    setAdvPreview(null);
                    break;
                  case UploadTypes.LPA:
                    setUploadedLpa(null);
                    setLpaPreview(null);
                    break;
                  case UploadTypes.BACKUP_INVESTMENT_DOCUMENT:
                    setBackupInvestmentDocumentPreview(null);
                    setBackupInvestmentDocument(null);
                    break;
                  default:
                    break;
                }
              }
              }>
              <FontAwesomeIcon
                size="1x"
                className="acc-red"
                icon={faTrash}
              />
            </button>
          </div> : null}
      </>
    );
  };

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;
    const items = Array.from(selectedImages);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSelectedImages(items);
  }

  const renderImages = (uploadedImages: any[]) => {
    return (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="images">
          {(provided) => (
            <ImageList
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{ width: "100%", height: "fit-content", marginBottom: 0 }}
              variant="masonry"
              cols={3}
              gap={8}>
              {uploadedImages.map((item, index) => {
                return (
                  <Draggable
                    key={item.link + (Math.random() * 100000)}
                    draggableId={item.title}
                    index={index}>
                    {(provided) => (
                      <ImageListItem
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}>
                        <img
                          src={item.link}
                          srcSet={item.link}
                          alt={item.title}
                          style={{ borderRadius: "10px", zIndex: 3 }}
                          loading="lazy"
                        />
                        <FontAwesomeIcon
                          size="1x"
                          className="acc-red"
                          icon={faTimesCircle}
                          style={{ cursor: "pointer", position: "absolute", zIndex: 2, top: 5, right: 5 }}
                          onClick={(e) => {
                            e.preventDefault();
                            deleteImage(index, item);
                          }}
                        />
                      </ImageListItem>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ImageList>
          )}
        </Droppable>
      </DragDropContext>
    );
  };


  const deleteFile = (fileIndex: number) => {
    const filteredFiles = selectedFiles.filter((item: TitleLinkInput, index: number) => index !== fileIndex);
    const filteredRawFiles = rawFiles.filter((item: any, index: number) => index !== fileIndex);
    setSelectedFiles(filteredFiles);
    setRawFiles(filteredRawFiles);
  };

  const deleteImage = (imageIndex: number, item: TitleLinkInput) => {
    const filteredImages = selectedImages.filter((item: TitleLinkInput, index: number) => index !== imageIndex);
    const filteredRawImages = rawDisplayImages.filter((item: any, index: number) => index !== imageIndex);
    setSelectedImages(filteredImages);
    setRawDisplayImages(filteredRawImages);
    const filteredOutExistingImages = [...existingDisplayImages.filter(existingImage => existingImage !== item.link)];
    setExistingDisplayImages(filteredOutExistingImages);
  };

  const renderAssetTypeCategory = (categoryName: string, values: any) => {
    if (!fetchedAssetTypes) return;
    const items = fetchedAssetTypes
      .filter(
        (item) => item.id !== values.assetTypeId && item.assetClass.vertical.name === categoryName,
      )
      .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name))
      .map((item) => {
        return (
          <MenuItem
            key={item.id}
            value={item.id}>
            {item.name}
          </MenuItem>
        );
      });
    return [<ListSubheader
      key={categoryName}
      sx={{ color: COLORS.darkBlue, paddingLeft: "30px", fontSize: "1rem" }}>{categoryName}</ListSubheader>, items];
  };

  useEffect(() => {
    const coverImageLocation = getAssetData?.getAsset?.coverImageLocation;
    if (coverImageLocation) {
      setCoverImagePreview(coverImageLocation);
    }
    const currentDisplayImages = getAssetData?.getAsset?.displayImages;
    if (currentDisplayImages) {
      const displayImageMap: TitleLinkInput[] = currentDisplayImages.map((imageUrl: string) => ({ link: imageUrl, title: "Existing image" }));
      setSelectedImages(displayImageMap);
      setExistingDisplayImages(currentDisplayImages);
    }
    const currentADVDocument = getAssetData?.getAsset?.advDocumentLocation;
    if (currentADVDocument) {
      const fileName = decodeURIComponent(currentADVDocument.split("/adv/")[1]);
      const displayADV: TitleLinkInput = { link: currentADVDocument, title: fileName };
      setAdvPreview(displayADV);
    }
    const currentPPMDocument = getAssetData?.getAsset?.ppmDocumentLocation;
    if (currentPPMDocument) {
      const fileName = decodeURIComponent(currentPPMDocument.split("/ppm/")[1]);
      const displayPPM: TitleLinkInput = { link: currentPPMDocument, title: fileName };
      setPpmPreview(displayPPM);
    }
    const currentLPADocument = getAssetData?.getAsset?.lpaDocumentLocation;
    if (currentLPADocument) {
      const fileName = decodeURIComponent(currentLPADocument.split("/lpa/")[1]);
      const displayLPA: TitleLinkInput = { link: currentLPADocument, title: fileName };
      setLpaPreview(displayLPA);
    }
    const currentBackupInvestmentDocument = getAssetData?.getAsset?.backupInvestmentDocumentLocation;
    if (currentBackupInvestmentDocument) {
      const fileName = decodeURIComponent(currentBackupInvestmentDocument.split("/backupInvestmentDocument/")[1]);
      const displayCurrentBackupInvestmentDocument: TitleLinkInput = { link: currentBackupInvestmentDocument, title: fileName };
      setBackupInvestmentDocumentPreview(displayCurrentBackupInvestmentDocument);
    }
  }, [getAssetData]);

  return (
    <PageWrapper
      title={`${editMode === EditModeTypes.ADD ? "Add" : "Update"} Asset`}
      showLoader={pageLoading || isSubmitting}
      showError={getAssetPartnerError || getAssetTypeError || getDealTypesError
        || getAssetStatusesError || getUsersError || getAssetError || getCashFlowError}
      errorMessage="Cannot retrieve assets"
    >
      <br />
      <div>
        <Formik
          initialValues={initialFormFieldValues()}
          enableReinitialize
          onSubmit={submitForm}
          validationSchema={AssetSchema}
        >
          {({ values, handleChange }) => (
            <Form>
              <div className="column column-reverse-mobile">
                <div className="row stretch center width-100 column-mobile">
                  <Link
                    to="/admin/assets"
                    className="width-100-mobile">
                    <AccButton
                      color={AccButtonColors.WHITE}
                      label="Cancel"
                      style={{ backgroundColor: COLORS.white }}
                    />
                  </Link>
                  <div className="row width-100-mobile column-mobile">
                    <AccButton
                      color={AccButtonColors.GREEN}
                      type="submit"
                      label={editMode === EditModeTypes.ADD ? "Submit" : "Update"}
                      className="m-top-15-mobile m-bottom-15-mobile"
                      onClick={() => setUserMessage()}
                    />
                    {editMode === EditModeTypes.EDIT
                      ? (
                        <>
                          <AccButton
                            color={getAssetData?.getAsset.assetStatus.name == "Archived" ? AccButtonColors.GREEN_OUTLINE : AccButtonColors.RED_OUTLINE}
                            label={getAssetData?.getAsset.assetStatus.name == "Archived" ? "Un-Archive" : "Archive"}
                            className="delete-button m-left-15 display-none-mobile"
                            type="button"
                            onClick={openCancelDialog}
                          />
                          {mobileLayout
                            && (
                              <AccButton
                              color={getAssetData?.getAsset.assetStatus.name == "Archived" ? AccButtonColors.GREEN_OUTLINE : AccButtonColors.RED_OUTLINE}
                              label={getAssetData?.getAsset.assetStatus.name == "Archived" ? "Un-Archive  Asset" : "Archive Asset"}
                                className="delete-button"
                                type="button"
                                width="40%"
                                style={styles.mobileDeleteButton}
                                onClick={openCancelDialog}
                              />
                            )}
                        </>
                      ) : null}
                    {isNotNil(getAssetData) ? <CancelConfirmDialogModal
                      isVisible={cancelDialogVisible}
                      confirmButtonColor={getAssetData?.getAsset.assetStatus.name == "Archived" ? AccButtonColors.GREEN_OUTLINE : AccButtonColors.RED_OUTLINE}
                      confirmButtonText={getAssetData?.getAsset.assetStatus.name == "Archived" ? "Un-Archive" : "Archive"}
                      dialogBody={`Are you sure you would like to ${getAssetData?.getAsset.assetStatus.name == "Archived" ? " un-archive " : " archive "} this Asset?`}
                      onClickCancel={closeCancelDialog}
                      onClickDelete={() => handleUpdateAssetStatus(getAssetData.getAsset.id)}
                    /> : null}
                  </div>
                </div>
                <div className="start row stretch width-100 column-mobile m-top-30">
                  <div className="column start width-60 width-100-mobile">
                    <div
                      className="width-100"
                      style={styles.border}>
                      <Field
                        name="name"
                        type="input"
                        label="Asset Name"
                        as={AccInput}
                        required
                      />
                      <Field
                        name="summary"
                        type="text"
                        label="Summary"
                        as={AccInput}
                        multiline
                        minRows={5}
                        required
                      />
                      <Box sx={{ width: "100%", marginTop: "30px" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            variant="fullWidth"
                            value={tabValue}
                            onChange={handleTabChange}
                            TabIndicatorProps={{
                              style: {
                                backgroundColor: COLORS.green,
                              },
                            }}
                            sx={{
                              "& .MuiTab-root.Mui-selected": {
                                color: COLORS.darkBlue,
                                backgroundColor: COLORS.lightTeal,
                                transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                              },
                            }}
                          >
                          {tabs.map((item, index) => (
                            <Tab
                                disableRipple
                                key={item.label}
                                label={
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    {item.label}
                                    <FontAwesomeIcon
                                    icon={faTrash}
                                    className="acc-red"
                                    style={{ marginLeft: "30px", cursor: "pointer" }}
                                    onClick={() => handleDeleteTab(index)}
                                    />
                                </div>
                                }
                                value={item.value}
                            />
                          ))}
                          </Tabs>
                          {isAddingNewTab && (
                            <div>
                            <AccInput
                                name="tabName"
                                type="text"
                                value={newTabName}
                                onChange={handleNewTabNameChange}
                                placeholder="Type new tab name"
                            />
                                <div className="row m-top-15 width-100-mobile column-mobile">
                                    <AccButton
                                        className="m-right-15 m-0-mobile"
                                        label={"Add"}
                                        onClick={handleAddTab}
                                        color={AccButtonColors.GREEN}
                                    />
                                    <AccButton
                                        className="m-right-15 m-0-mobile"
                                        label={"Cancel"}
                                        onClick={hideNewTabInput}
                                        color={AccButtonColors.RED_OUTLINE}
                                    />
                                </div>
                            </div>
                          )}
                          {!isAddingNewTab
                            && (
                            <AccButton
                              className={"m-top-15"}
                              color={AccButtonColors.BLUE}
                              label={"+ Add Tab"}
                              onClick={showNewTabInput}
                            />)
                          }
                        </Box>
                        <AssetTabViewForm
                          tabValue={tabValue}
                          setIsCodeView={setIsCodeView}
                          tabs={tabs}
                        />
                      </Box>
                    </div>
                  </div>
                  <div
                    className="column start width-35 width-100-mobile m-top-30-mobile"
                    style={styles.border}
                  >
                    <AccInput
                      name="assetTypeObjectId"
                      value={values.assetTypeObjectId}
                      label="Asset Type"
                      select
                      required
                      onChange={handleChange}
                    >
                      <MenuItem
                        selected
                        value={values.assetTypeId}>
                        {values.assetTypeName ?? <span style={{ color: COLORS.lightGray }}>Select an asset type</span>}
                        {isNotNil(values.assetTypeName) && <FontAwesomeIcon
                          icon={faCheck}
                          className="acc-green m-left-15" />}
                      </MenuItem>
                      {fetchedAssetTypes ? fetchedAssetTypes
                        .map((item) => item.assetClass.vertical.name)
                        .filter((item, index, array) => array.indexOf(item) === index)
                        .map((item) => renderAssetTypeCategory(item, values)) : null}
                    </AccInput>
                    <AccInput
                      value={values.assetPartnerObjectId}
                      name="assetPartnerObjectId"
                      label="Asset Partner"
                      select
                      required
                      onChange={handleChange}
                    >
                      <MenuItem
                        selected
                        value={values.assetPartnerId}>
                        {values.assetPartnerName ?? <span style={{ color: COLORS.lightGray }}>Select an asset partner</span>}
                        {isNotNil(values.assetPartnerName) && <FontAwesomeIcon
                          icon={faCheck}
                          className="acc-green m-left-15" />}
                      </MenuItem>
                      {fetchedAssetPartners ? fetchedAssetPartners
                        ?.filter(
                          (item) => item.id !== values.assetPartnerId,
                        )
                        .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name))
                        .map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}>
                            {item.name}
                          </MenuItem>
                        )) : null}
                    </AccInput>
                    <AccInput
                      value={values.assetStatusObjectId}
                      name="assetStatusObjectId"
                      label="Asset Status"
                      select
                      required
                      onChange={handleChange}
                    >
                      <MenuItem
                        selected
                        value={values.assetStatusId}>
                        {values.assetStatusName ?? <span style={{ color: COLORS.lightGray }}>Select an Asset Status</span>}
                        {isNotNil(values.assetStatusName) && <FontAwesomeIcon
                          icon={faCheck}
                          className="acc-green m-left-15" />}
                      </MenuItem>
                      {fetchedAssetStatuses ? fetchedAssetStatuses
                        ?.filter(
                          (item) => item.id !== values.assetStatusId,
                        )
                        .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name))
                        .map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}>
                            {item.name}
                          </MenuItem>
                        )) : null}
                    </AccInput>
                    <AccInput
                      value={values.assetManagerUserObjectId}
                      name="assetManagerUserObjectId"
                      label="Asset Manager"
                      select
                      required
                      onChange={handleChange}
                    >
                      <MenuItem
                        selected
                        value={values.assetManagerUserId}>
                        {values.assetManagerUserFullName ?? <span style={{ color: COLORS.lightGray }}>Select Asset Manager</span>}
                        {isNotNil(values.assetManagerUserFullName) && <FontAwesomeIcon
                          icon={faCheck}
                          className="acc-green m-left-15" />}
                      </MenuItem>
                      {fetchedUsers ? fetchedUsers
                        ?.filter(
                          (item) => item.id !== values.assetManagerUserId && item?.userType?.name === "ASSET MANAGER",
                        )
                        .sort((itemA, itemB) => itemA.firstName.localeCompare(itemB.firstName))
                        .map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}>
                            {item.firstName} {item.lastName}
                          </MenuItem>
                        )) : null}
                    </AccInput>
                    <AccInput
                      value={values.dealTypeObjectId}
                      name="dealTypeObjectId"
                      label="Deal Type"
                      select
                      required
                      onChange={handleChange}
                    >
                      <MenuItem
                        selected
                        value={values.dealTypeId}>
                        {values.dealTypeName ?? <span style={{ color: COLORS.lightGray }}>Select a Deal Type</span>}
                        {isNotNil(values.dealTypeName) && <FontAwesomeIcon
                          icon={faCheck}
                          className="acc-green m-left-15" />}
                      </MenuItem>
                      {fetchedDealTypes ? fetchedDealTypes
                        ?.filter(
                          (item) => item.id !== values.dealTypeId,
                        )
                        .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name))
                        .map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}>
                            {item.name}
                          </MenuItem>
                        )) : null}
                    </AccInput>
                    <br />
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel
                        style={{ marginLeft: 15 }}
                        id="multiple-label">
                        Authorized Investors*
                      </InputLabel>
                      <Select
                        labelId="multiple-label"
                        value={values.authorizedInvestors || []}
                        name="authorizedInvestors"
                        multiple
                        required
                        input={<OutlinedInput label="Authorized Investors" />}
                        onChange={handleChange}>
                        {authorizedInvestors.map((item) => {
                          return (
                            <MenuItem
                              key={item}
                              value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <br />
                    <div className="width-100 column">
                      <div className="width-100 stretch row center">
                        <Typography variant="subtitle1">Cover</Typography>
                      </div>
                      <div
                        className="column imgPreview m-bottom-30"
                        style={{
                          ...styles.paper,
                          background: coverImagePreview
                            ? `url("${coverImagePreview}") no-repeat center/cover`
                            : "transparent",
                        }}
                      >
                        {!coverImagePreview && (
                          <>
                            <label
                              htmlFor="fileUpload"
                              className="acc-darkblue"
                              style={styles.imageInputLabel}
                            >
                              <div className="row m-bottom-15"><FontAwesomeIcon
                                size="lg"
                                className="acc-darkblue m-right-15"
                                icon={faFileImage}
                              />
                                Upload Cover Image
                              </div>
                              <Typography className="acc-grey light">Supported file types: png, jpeg, jpg</Typography>
                            </label>
                            <input
                              type="file"
                              className="display-none"
                              accept="image/*"
                              name="file"
                              id="fileUpload"
                              required
                              formEncType="multipart/form-data"
                              onChange={handlePictureUpload}
                            />
                          </>
                        )}
                        {coverImagePreview && (
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            color={COLORS.red}
                            style={{
                              cursor: "pointer",
                              position: "relative",
                              top: -110,
                              right: "-46%",
                              zIndex: 3,
                            }}
                            onClick={() => setCoverImagePreview(null)} />
                        )}
                      </div>
                    </div>
                    <div
                      className="width-100"
                      style={styles.file}>
                      <label
                        htmlFor="multipleFileUpload"
                        style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          size="lg"
                          className="acc-darkblue m-right-15"
                          icon={faFilePdf}
                        />
                        {" "}
                        Upload Documents
                      </label>
                      <input
                        type="file"
                        className="display-none"
                        accept="application/pdf,application/vnd.ms-excel"
                        // accept="image/*"
                        id="multipleFileUpload"
                        multiple
                        onChange={(e) => handleMultipleFileChange(e, UploadTypes.DOCUMENTS)}
                      />
                      {renderFiles(selectedFiles)}
                    </div>
                    <div
                      className="width-100 m-top-30"
                      style={styles.file}>
                      <label
                        htmlFor="memorandumUpload"
                        style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          size="lg"
                          className="acc-darkblue m-right-15"
                          icon={faFilePdf}
                        />
                        {" "}
                        Upload PPM
                      </label>
                      <input
                        type="file"
                        className="display-none"
                        accept="application/pdf,application/vnd.ms-excel"
                        id="memorandumUpload"
                        onChange={(e) => handleSingleFileUpload(e, UploadTypes.MEMORANDUM)}
                      />
                      {renderSingleFile(ppmPreview!, UploadTypes.MEMORANDUM)}
                    </div>
                    <div
                      className="width-100 m-top-30"
                      style={styles.file}>
                      <label
                        htmlFor="ADVUpload"
                        style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          size="lg"
                          className="acc-darkblue m-right-15"
                          icon={faFilePdf}
                        />
                        {" "}
                        Upload ADV
                      </label>
                      <input
                        type="file"
                        className="display-none"
                        accept="application/pdf,application/vnd.ms-excel"
                        id="ADVUpload"
                        onChange={(e) => handleSingleFileUpload(e, UploadTypes.ADV)}
                      />
                      {renderSingleFile(advPreview!, UploadTypes.ADV)}
                    </div>
                    <div
                      className="width-100 m-top-30"
                      style={styles.file}>
                      <label
                        htmlFor="LPAUpload"
                        style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          size="lg"
                          className="acc-darkblue m-right-15"
                          icon={faFilePdf}
                        />
                        {" "}
                        Upload LPA
                      </label>
                      <input
                        type="file"
                        className="display-none"
                        accept="application/pdf,application/vnd.ms-excel"
                        id="LPAUpload"
                        onChange={(e) => handleSingleFileUpload(e, UploadTypes.LPA)}
                      />
                      {renderSingleFile(lpaPreview!, UploadTypes.LPA)}
                    </div>
                    <div
                      className="width-100 m-top-30"
                      style={styles.file}>
                      <label
                        htmlFor="backupInvestmentDocument"
                        style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          size="lg"
                          className="acc-darkblue m-right-15"
                          icon={faFilePdf}
                        />
                        {" "}
                        Upload backup investment document
                      </label>
                      <input
                        type="file"
                        className="display-none"
                        accept="application/pdf,application/vnd.ms-excel"
                        id="backupInvestmentDocument"
                        onChange={(e) => handleSingleFileUpload(e, UploadTypes.BACKUP_INVESTMENT_DOCUMENT)}
                      />
                      {renderSingleFile(backupInvestmentDocumentPreview!, UploadTypes.BACKUP_INVESTMENT_DOCUMENT)}
                    </div>
                    <div
                      className="width-100 m-top-30"
                      style={styles.file}>
                      <label
                        htmlFor="multipleImageUpload"
                        style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          size="lg"
                          className="acc-darkblue m-right-15"
                          icon={faImages}
                        />
                        {" "}
                        Upload Images
                      </label>
                      <input
                        type="file"
                        className="display-none"
                        accept="image/*"
                        id="multipleImageUpload"
                        multiple
                        onChange={(e) => handleMultipleFileChange(e, UploadTypes.DISPLAY_IMAGES)}
                      />
                      {renderImages(selectedImages)}
                    </div>
                  </div>
                </div>
                {editMode === EditModeTypes.EDIT
                  ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: "650px",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{ width: "350px" }}
                      >
                        <Typography
                          className="bold font-size-mobile-3 m-top-50 width-100-mobile"
                          variant="h4">Cash Flow Model</Typography>
                        <br />
                        <DataGrid
                          rows={formattedRows || []}
                          columns={columns}
                          editMode="row"
                          onEditRowsModelChange={(e) => handleDataChange(e)}
                          componentsProps={{
                            panel: DataGridStyles.panel,
                          }}
                          sx={DataGridStyles.toolBar}
                        />
                      </div>
                      <CashFlowModal
                        asset={getAssetData?.getAsset}
                        cashFlowModelRows={rows}
                        cashFlow={getCashFlowData?.getCashFlow}
                      />
                    </div>
                  )
                  : null}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </PageWrapper >
  );
});

const authorizedInvestors = ["Accredited", "Non-Accredited", "Institutional", "Qualified Purchaser"];

const styles = {
  border: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    padding: "15px",
  },
  paper: {
    width: "100%",
    height: "250px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    justifyContent: "center",
    alignItems: "center",
  },
  imageInputLabel: {
    cursor: "pointer",
    fontSize: "20px",
  },
  button: {
    backgroundColor: "transparent",
    border: "none",
  },
  file: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    padding: "15px",
  },
  mobileDeleteButton: {
    alignSelf: "flex-start",
    border: "none",
    textAlign: "left",
    padding: 0,
    height: 20,
  },
  input: {
    padding: "0px 10px 0px 10px",
    border: "1px solid #1bd0ae",
    height: "35px",
    width: "100%",
  },
  checkbox: {
    color: COLORS.green,
    "&.Mui-checked": {
      color: COLORS.green,
    },
  },
};

export default withRouter(AssetForm);
