import gql from "graphql-tag";
import { gql as gqlv2 } from "graphql-request";
import { makeGraphqlRequest } from "../../utils";

export const CREATE_WALLET_TYPE = gql`
  mutation CreateWalletType($input: WalletTypeInput!) {
    createWalletType(input: $input) {
      name
    }
  }
`;

export const UPDATE_WALLET_TYPE = gql`
  mutation UpdateWalletType($id: String!, $input: WalletTypeInput!) {
    updateWalletType(_id: $id, input: $input) {
      id
      name
    }
  }
`;

export const DELETE_WALLET_TYPE = gql`
  mutation DeleteWalletType($id: String!) {
    deleteWalletType(_id: $id) {
      id
      walletTypeId
    }
  }
`;

const GET_WALLET_TYPE_V2 = gqlv2`
  query GetWalletType ($walletTypeId: Int!){
    getWalletType(walletTypeId: $walletTypeId) {
      id
      walletTypeId
      name
    }
  }
`;

export const getWalletType = (walletTypeId: number) => makeGraphqlRequest(GET_WALLET_TYPE_V2, { walletTypeId });

const GET_WALLET_TYPES_V2 = gqlv2`
  query GetWalletTypes {
    getWalletTypes {
      id
      walletTypeId
      name
    }
  }
`;

export const getWalletTypes = () => makeGraphqlRequest(GET_WALLET_TYPES_V2);