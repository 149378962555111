import { styled } from "@material-ui/core";
import { Tooltip, tooltipClasses, TooltipProps, Zoom } from "@mui/material";
import React from "react";

interface Props {
    title: string | JSX.Element;
    children: JSX.Element;
    color: string;
    background: string;
    fontSize?: string | number;
    fontWeight?: number;
    placement?: "bottom" | "left" | "right" | "top" | "left-end";
}

const CustomTooltip: React.FC<Props> = ({ title, children, color, background, placement, fontSize, fontWeight }) => {

    const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip
            {...props}
            classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            background: background,
            color: color,
            boxShadow: theme.shadows[1],
            fontSize: fontSize ? fontSize : 16,
            fontWeight: fontWeight ? fontWeight : 600,
        },
    }));

    return (
        <StyledTooltip
            placement={placement}
            title={title}
            arrow
            TransitionComponent={Zoom}
        >
            <div>
                {children}
            </div>
        </StyledTooltip>
    );
};

export default CustomTooltip;