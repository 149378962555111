import {
  faWallet, faChevronDown, faChevronRight, faIdBadge, faLayerGroup, faMoneyBill, faPlane, faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import React, { useContext, useState } from "react";
import { SessionContext } from "../../../../context";
import COLORS from "../../../colors";
import { UserTypes } from "../../../types";
import { NestedListItemButton, MainListItemButton } from "../../NavigationComponents";
import { Link } from "react-router-dom";

interface Props {
  open: boolean;
}

const AdminLinks: React.FC<Props> = ({ open }) => {
  const [assetMenuExpanded, setAssetMenuExpanded] = useState(false);
  const [userMenuExpanded, setUserMenuExpanded] = useState(false);
  const [walletMenuExpanded, setWalletMenuExpanded] = useState(false);
  const { user } = useContext(SessionContext);

  const handleAssetClick = () => {
    setAssetMenuExpanded(!assetMenuExpanded);
    setWalletMenuExpanded(false);
    setUserMenuExpanded(false);
  };

  const handleWalletClick = () => {
    setWalletMenuExpanded(!walletMenuExpanded);
    setAssetMenuExpanded(false);
    setUserMenuExpanded(false);
  };

  const handleUserClick = () => {
    setUserMenuExpanded(!userMenuExpanded);
    setAssetMenuExpanded(false);
    setWalletMenuExpanded(false);
  };

  const handleCloseAllDropdownMenus = () => {
    setUserMenuExpanded(false);
    setAssetMenuExpanded(false);
    setWalletMenuExpanded(false);
  };

  return (
    <div>
      <List
        sx={styles.adminLink}
        component="nav"
        aria-labelledby="assets-verticals"
        subheader={(
          <ListSubheader
            disableSticky
            className="light"
            component="div"
            id="assets-verticals"
            sx={{
              ...(!open && styles.listSubheaderClosed), ...styles.listSubheader,
            }}
          >
            Assets & Verticals
          </ListSubheader>
        )}
      >
        <Link to="/admin/assets">
          <ListItemButton
            disableRipple
            onClick={handleAssetClick}
            sx={styles.listButton}>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faPlane}
                style={{ ...styles.headerIcon, color: assetMenuExpanded ? COLORS.green : COLORS.navBarLogo }} />
            </ListItemIcon>
            <ListItemText
              primary="Assets"
              style={{ color: assetMenuExpanded ? COLORS.white : COLORS.navBarLogo }} />
            {assetMenuExpanded ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />}
          </ListItemButton>
        </Link>
        <Collapse
          in={assetMenuExpanded}
          timeout="auto"
          unmountOnExit>
          <List
            component="div"
            disablePadding>
            <NestedListItemButton
              title="Asset List"
              link="/admin/assets"
            />
            <NestedListItemButton
              title="Asset Classes"
              link="/admin/assetclasses"
            />
            <NestedListItemButton
              title="Asset Items"
              link="/admin/assetitems"
            />
            <NestedListItemButton
              title="Asset Partners"
              link="/admin/assetpartners"
            />
            <NestedListItemButton
              title="Asset Statuses"
              link="/admin/assetstatuses"
            />
            <NestedListItemButton
              title="Asset Types"
              link="/admin/assettypes"
            />
            <NestedListItemButton
              title="Deal Types"
              link="/admin/dealtypes"
            />
          </List >
        </Collapse >
        <MainListItemButton
          link="/admin/verticals"
          icon={faLayerGroup}
          title="Verticals"
          onClick={handleCloseAllDropdownMenus}
        />
      </List>
      {user?.userType?.name === UserTypes.ADMIN && <List
        sx={styles.adminLink}
        component="nav"
        aria-labelledby="wallets-investments"
        subheader={(
          <ListSubheader
            className="light"
            component="div"
            id="wallets-investments"
            sx={{
              ...(!open && styles.listSubheaderClosed), ...styles.listSubheader,
            }}
          >
            Wallets & Investments
          </ListSubheader>
        )}
      >
        <MainListItemButton
          link="/admin/investments"
          icon={faMoneyBill}
          title="Investments"
          onClick={handleCloseAllDropdownMenus}
        />
        <Link to="/admin/wallets">
          <ListItemButton
            disableRipple
            onClick={handleWalletClick}
            sx={styles.listButton}>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faWallet}
                style={{ ...styles.headerIcon, color: walletMenuExpanded ? COLORS.green : COLORS.navBarLogo }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Wallets"
              style={{ color: walletMenuExpanded ? COLORS.white : COLORS.navBarLogo }}
            />
            {walletMenuExpanded
              ? <FontAwesomeIcon icon={faChevronDown} />
              : <FontAwesomeIcon icon={faChevronRight} />
            }
          </ListItemButton>
        </Link>
        <Collapse
          in={walletMenuExpanded}
          timeout="auto"
          unmountOnExit>
          <List
            component="div"
            disablePadding>
            <NestedListItemButton
              title="Wallet List"
              link="/admin/wallets"
            />
            <NestedListItemButton
              title="Wallet Types"
              link="/admin/wallettypes"
            />
            <NestedListItemButton
              title="Funding Requests"
              link="/admin/fundingrequests"
            />
          </List>
        </Collapse>
      </List>}
      {user?.userType?.name === UserTypes.ADMIN && <List
        sx={styles.adminLink}
        component="nav"
        aria-labelledby="accounts-users"
        subheader={(
          <ListSubheader
            className="light"
            component="div"
            id="accounts-users"
            sx={{
              ...(!open && styles.listSubheaderClosed), ...styles.listSubheader,
            }}
          >
            Accounts & Users
          </ListSubheader>
        )}
      >
        <MainListItemButton
          link="/admin/accounts"
          icon={faIdBadge}
          title="Accounts"
          onClick={handleCloseAllDropdownMenus}
        />
        <Link to="/admin/users">
          <ListItemButton
            disableRipple
            onClick={handleUserClick}
            sx={styles.listButton}>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faUsers}
                style={{ ...styles.headerIcon, color: userMenuExpanded ? COLORS.green : COLORS.navBarLogo }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Users"
              style={{ color: userMenuExpanded ? COLORS.white : COLORS.navBarLogo }}
            />
            {userMenuExpanded
              ? <FontAwesomeIcon icon={faChevronDown} />
              : <FontAwesomeIcon icon={faChevronRight} />
            }
          </ListItemButton>
        </Link>
        <Collapse
          in={userMenuExpanded}
          timeout="auto"
          unmountOnExit>
          <List
            component="div"
            disablePadding>
            <NestedListItemButton
              title="User List"
              link="/admin/users"
            />
            <NestedListItemButton
              title="User Types"
              link="/admin/usertypes"
            />
          </List>
        </Collapse>
      </List>}
    </div >
  );
};

const styles: any = {
  navIcon: {
    marginRight: 0,
    fontSize: 20,
    width: 25,
  },
  headerIcon: {
    marginRight: 30,
    marginLeft: 8,
    fontSize: 20,
    width: 25,
  },
  adminLink: {
    width: "100%",
    bgcolor: "transparent",
    color: COLORS.navBarLogo,
  },
  listSubheader: {
    bgcolor: "transparent",
    color: COLORS.navBarLogo,
    textTransform: "uppercase",
    fontSize: "0.75rem",
  },
  listSubheaderClosed: {
    opacity: 0,
    transition: "0.5s",
  },
  listButton: {
    paddingBottom: 0,
  },
};

export default AdminLinks;
