import gql from "graphql-tag";
import { makeGraphqlRequest } from "../../utils";
import { gql as gqlv2 } from "graphql-request";

export const CREATE_ASSET_ITEM = gql`
  mutation CreateAssetItem($input: AssetItemInput!) {
    createAssetItem(input: $input) {
      name
      asset {
        assetId
        name
      }
    }
  }
`;

export const UPDATE_ASSET_ITEM = gql`
  mutation UpdateAssetItem($id: String!, $input: AssetItemInput!) {
    updateAssetItem(_id: $id, input: $input) {
      id
      name
      asset {
        assetId
        name
      }
    }
  }
`;

export const DELETE_ASSET_ITEM = gql`
  mutation DeleteAssetItem($id: String!) {
    deleteAssetItem(_id: $id) {
      id
      assetItemId
    }
  }
`;

const GET_ASSET_ITEM_V2 = gqlv2`
  query GetAssetItem ($assetItemId: Int!) {
    getAssetItem(assetItemId: $assetItemId) {
      id
      assetItemId
      name
      asset {
        id
        assetId
        name
      }
    }
  }
`;

export const getAssetItem = (assetItemId: number) => makeGraphqlRequest(GET_ASSET_ITEM_V2, { assetItemId });

const GET_ASSET_ITEMS_V2 = gqlv2`
  query GetAssetItems {
    getAssetItems {
      id
      assetItemId
      name
      asset {
        assetId
        name
      }
    }
  }
`;

export const getAssetItems = () => makeGraphqlRequest(GET_ASSET_ITEMS_V2);