import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { UserPortfolioInvestment } from "../../../../common/types";
import { formatCurrency, isNotNil } from "../../../../common/utilities";
import moment from "moment";
import AccButton, { AccButtonColors } from "../../../../common/components/AccButton";
import { Link } from "react-router-dom";
import PageCard from "../../../../common/components/PageCard";

interface Props {
  data: UserPortfolioInvestment[] | undefined;
}


const Investments: React.FC<Props> = ({ data }) => {

  return (
    <div className="width-100 width-100-mobile width-100-tablet m-right-50 m-0-mobile m-0-tablet">
      {isNotNil(data) ?
        <>
          <PageCard>
            <Typography
              variant="h5"
              className="acc-darkblue bold">
              Account Investments
            </Typography>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">DATE</TableCell>
                    <TableCell align="left">DESCRIPTION</TableCell>
                    <TableCell align="left">STATUS</TableCell>
                    <TableCell align="right">AMOUNT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.[0].investments
                    .sort((a, b) => new Date(Number(b.createdAt)).valueOf() - new Date(Number(a.createdAt)).valueOf())
                    .map((row) => (
                      <TableRow
                        key={row.investmentId}
                      >
                        <TableCell
                          component="th"
                          scope="row">{moment(Number(row.createdAt)).format("ll")}</TableCell>
                        <TableCell align="left">Committed to investing in {row.asset.name}</TableCell>
                        <TableCell align="left">{row.status}</TableCell>
                        <TableCell align="right">{formatCurrency(row.amount)}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </PageCard>
        </>
        :
        <Link to="/investor/invest">
          <AccButton
            label="Discover Opportunities"
            color={AccButtonColors.GREEN}
            className="green-button"
            style={{ width: "fit-content" }}
          />
        </Link>
      }
    </div>
  );
};

export default Investments;