import React from "react";
import { Typography } from "@material-ui/core";
import PageCard from "../../../../../common/components/PageCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faDownload } from "@fortawesome/pro-light-svg-icons";
import COLORS from "../../../../../common/colors";

interface Props {
  timePeriod: string;
}

const StatementCard: React.FC<Props> = ({ timePeriod }) => {

  return (
    <PageCard
      className="width-80 row center stretch width-100-mobile">
      <div className="width-60 row center">
        <FontAwesomeIcon
          size="lg"
          icon={faCalendar}
          color={COLORS.green}
          className="m-right-15" />
        <Typography
          variant="subtitle1"
          className="acc-darkblue bold">{timePeriod}</Typography>
      </div>
      <div className="width-20 column end">
        <Typography
          variant="subtitle2"
          className="acc-gray">
          Download
          <FontAwesomeIcon
            icon={faDownload}
            color={COLORS.green}
            className="m-left-15" />
        </Typography>
      </div>
    </PageCard>
  );
};

const InvestmentStatements: React.FC = () => {

  return (
    <div>
      <StatementCard
        timePeriod="15 June - 14 July" />
      <br />
      <StatementCard
        timePeriod="15 May - 14 June" />
      <br />
      <StatementCard
        timePeriod="15 April - 14 May" />
    </div>
  );
};

export default InvestmentStatements;