import { toast } from "react-toastify";
import { openPDF } from "../../utils";
import restClient from "../restClient";

const downloadTCs = async (
  documentId: string,
) => {
  try {
    toast("Downloading document, please wait", {
      type: toast.TYPE.INFO,
    });
    const termsResponse = await restClient.get("/download/terms-and-conditions", {
      params: {
        document: documentId,
      },
    });
    if (termsResponse.data?.success) {
      return openPDF(termsResponse.data.data);
    } else if (termsResponse?.data?.success === false) {
      toast(termsResponse.data.msg || "There was a problem downloading document.", {
        type: toast.TYPE.ERROR,
      });
    }
  } catch (err: any) {
    console.log(err);
    toast(err.message || err.msg || err.toString() || "Error downloading TCs!", {
      type: toast.TYPE.ERROR,
    });
  }
};

const downloadDocument = async (
  location: string,
) => {
  try {
    toast("Downloading document, please wait", {
      type: toast.TYPE.INFO,
    });
    const termsResponse = await restClient.get("/download/document", {
      params: {
        location,
      },
    });
    if (termsResponse.data?.success) {
      return openPDF(termsResponse.data.data);
    } else if (termsResponse?.data?.success === false) {
      toast(termsResponse.data.msg || "There was a problem downloading document.", {
        type: toast.TYPE.ERROR,
      });
    }
  } catch (err: any) {
    console.log(err);
    toast(err.message || err.msg || err.toString() || "Error downloading TCs!", {
      type: toast.TYPE.ERROR,
    });
  }
};

export {
  downloadTCs,
  downloadDocument,
};