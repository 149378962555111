import gql from "graphql-tag";
import { makeGraphqlRequest } from "../../utils";
import { gql as gqlv2 } from "graphql-request";

export const CREATE_ASSET_PARTNER = gql`
  mutation CreateAssetPartner(
    $input: AssetPartnerInput!
    $uploadedLogoImage: Upload
  ) {
    createAssetPartner(
      input: $input
      uploadedLogoImage: $uploadedLogoImage, 
    ) {
      name
      description
      title
      coverLogoImageLocation
      website
    }
  }
`;

export const UPDATE_ASSET_PARTNER = gql`
  mutation UpdateAssetPartner(
    $id: String!
    $input: AssetPartnerInput!
    $uploadedLogoImage: Upload
  ) {
    updateAssetPartner(
      _id: $id
      input: $input
      uploadedLogoImage: $uploadedLogoImage
    ) {
      id
      name
      description
      title
      coverLogoImageLocation
      website
    }
  }
`;

export const DELETE_ASSET_PARTNER = gql`
  mutation DeleteAssetPartner($id: String!) {
    deleteAssetPartner(_id: $id) {
      id
      assetPartnerId
    }
  }
`;

const GET_ASSET_PARTNER_V2 = gqlv2`
  query GetAssetPartner ($assetPartnerId: Int!){
    getAssetPartner(assetPartnerId: $assetPartnerId) {
      id
      assetPartnerId
      name
      description
      title
      coverLogoImageLocation
      website
    }
  }
`;

export const getAssetPartner = (assetPartnerId: number) => makeGraphqlRequest(GET_ASSET_PARTNER_V2, { assetPartnerId });

const GET_ASSET_PARTNERS_V2 = gqlv2`
  query GetAssetPartners {
    getAssetPartners {
      id
      assetPartnerId
      name
      description
      title
      coverLogoImageLocation
      website
    }
  }
`;

export const getAssetPartners = () => makeGraphqlRequest(GET_ASSET_PARTNERS_V2);
