import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { Link } from "react-router-dom";
import { useAssetItems } from "../../../api/hooks/graphqlHooks";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import LoadingAnimation from "../../../common/components/LoadingAnimation";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { DataGridStyles, renderEditCell } from "../Assets";
import PageWrapper from "../../../common/components/PageWrapper";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";

interface Props {

}

const AssetItem: React.FC<Props> = withErrorBoundary({}, () => {
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const { data, isLoading, isFetching, isError } = useAssetItems();

  if (isLoading || isFetching) {
    return (
      <LoadingAnimation />
    );
  }

  const columns: GridColDef[] = [
    renderEditCell("/admin/assetitems/"),
    { field: "name", headerName: "Name", width: 300 },
    { field: "asset", headerName: "Asset", width: 200, hide: mobileLayout },
  ];

  const rows = data?.getAssetItems?.map(row =>
  ({
    ...row,
    name: row.name,
    asset: row.asset.name,
    id: row.assetItemId,
  }),
  );

  return (
    <PageWrapper
      title="Asset Items"
      showLoader={isLoading || isFetching}
      showError={isError}
      errorMessage="Cannot retrieve asset items"
    >
      <div style={{ height: "80vh", width: "100%" }}>
        <div className="row-mobile stretch">
          <div style={{ justifyContent: "flex-start" }}>
          </div>
          <div
            className="row"
            style={{ justifyContent: "flex-end" }}>
            <Link to="/admin/assetitems/add">
              <AccButton
                color={AccButtonColors.GREEN}
                label="Add"
              />
            </Link>
          </div>
        </div>
        <br />
        <Divider />
        <br />
        <DataGrid
          rows={rows || []}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            panel: DataGridStyles.panel,
          }}
          sx={DataGridStyles.toolBar}
        />
      </div>
    </PageWrapper>
  );
});

export default AssetItem;
