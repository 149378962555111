
import React from "react";
import AccInput, { AccMaskedInputTypes } from "../../../../common/components/AccInput";
import { COUNTRIES, US_STATES } from "../../../../common/constants";
import AccOptionSelect from "../../../../common/components/AccInput/variants/AccOptionSelect";
import { Typography } from "@material-ui/core";
import { StyledInput } from "../components/ThemedComponents";
import { useFormikContext } from "formik";
import { UMBCommercialFormValues } from "../common/umb.interfaces";
import DividerWithText from "../../../../common/components/DividerWithText";

interface Props {
  beneficialOwnerIndex?: number;
}

export const CommercialPersonalInformation = ({ beneficialOwnerIndex }: Props) => {
  const { values, handleChange } = useFormikContext<UMBCommercialFormValues>();

  // Hard not to repeat this code with the mixed name formik field as string and the value as javascript notated object
  if (beneficialOwnerIndex != null) {
    console.log("******values?.beneficialOwners?.[beneficialOwnerIndex]", beneficialOwnerIndex, values?.beneficialOwners?.[beneficialOwnerIndex]);
    return (
      <div className="start width-100 width-100-mobile">
        <Typography
          style={{ marginBottom: 30, textAlign: "left" }}
          variant="h5"
          className="acc-white bold">Beneficial Owner #{beneficialOwnerIndex}</Typography>
        <div className="row width-80 column-mobile width-100-mobile">
          <div className="column start m-right-50 width-50 width-100-mobile m-right-0-mobile m-bottom-15-mobile">
            <Typography
              variant="subtitle1"
              className="acc-white bold m-bottom-10">Personal Information</Typography>
            <StyledInput
              margin="dense"
              as={AccInput}
              name={`beneficialOwners[${beneficialOwnerIndex}].firstName`}
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.firstName}
              onChange={handleChange}
              label="First Name"

            />
            <StyledInput
              margin="dense"
              as={AccInput}
              name={`beneficialOwners[${beneficialOwnerIndex}].middleName`}
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.middleName}
              onChange={handleChange}
              label="Middle Name"
            />
            <StyledInput
              margin="dense"
              as={AccInput}
              name={`beneficialOwners[${beneficialOwnerIndex}].lastName`}
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.lastName}
              onChange={handleChange}
              label="Last Name"
            />
            <StyledInput
              margin="dense"
              as={AccInput}
              name={`beneficialOwners[${beneficialOwnerIndex}].dateOfBirth`}
              type="date"
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.dateOfBirth}
              onChange={handleChange}
              label="Date of Birth"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <StyledInput
              margin="dense"
              as={AccOptionSelect}
              name={`beneficialOwners[${beneficialOwnerIndex}].countryOfBirth`}
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.countryOfBirth}
              onChange={handleChange}
              label="Country of Birth"
              options={COUNTRIES}
            />
            <StyledInput
              margin="dense"
              as={AccInput}
              name={`beneficialOwners[${beneficialOwnerIndex}].ssn`}
              maskedInput
              mask={AccMaskedInputTypes.SSN}
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.ssn}
              onChange={handleChange}
              label="Social Security Number"
            />
            <Typography
              style={{ marginTop: 30, textAlign: "center" }}
              variant="subtitle1"
              className="acc-white bold m-bottom-10">Contact Information</Typography>
            <StyledInput
              margin="dense"
              as={AccInput}
              name={`beneficialOwners[${beneficialOwnerIndex}].email`}
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.email}
              onChange={handleChange}
              label="Email Address"
            />
            <StyledInput
              margin="dense"
              as={AccInput}
              name={`beneficialOwners[${beneficialOwnerIndex}].phone`}
              maskedInput
              mask={AccMaskedInputTypes.US_PHONE_NUMBER}
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.phone}
              onChange={
                handleChange
              }
              label="Phone Number"
            />
          </div>
          <div className="column start m-right-50 width-50 width-100-mobile m-0-mobile">
            <Typography
              variant="subtitle1"
              className="acc-white bold start m-bottom-10">Home Address</Typography>
            <StyledInput
              as={AccInput}
              margin="dense"
              name={`beneficialOwners[${beneficialOwnerIndex}].address.line1`}
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.address?.line1}
              onChange={handleChange}
              label="Address Line 1"
            />
            <StyledInput
              as={AccInput}
              margin="dense"
              name={`beneficialOwners[${beneficialOwnerIndex}].address.line2`}
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.address?.line2}
              onChange={handleChange}
              label="Address Line 2"
            />
            <StyledInput
              as={AccInput}
              margin="dense"
              name={`beneficialOwners[${beneficialOwnerIndex}].address.city`}
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.address?.city}
              onChange={handleChange}
              label="City"
            />
            <StyledInput
              as={AccOptionSelect}
              margin="dense"
              name={`beneficialOwners[${beneficialOwnerIndex}].address.state`}
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.address?.state}
              onChange={handleChange}
              label="State"
              options={US_STATES}
            />
            <StyledInput
              as={AccInput}
              margin="dense"
              name={`beneficialOwners[${beneficialOwnerIndex}].address.postalCode`}
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.address?.postalCode}
              onChange={handleChange}
              label="Zip Code"
            />
            <StyledInput
              as={AccOptionSelect}
              margin="dense"
              name={`beneficialOwners[${beneficialOwnerIndex}].address.country`}
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.address?.country}
              onChange={handleChange}
              label="Country of Residence"
              options={COUNTRIES}
            />
          </div>
          <div className="column start width-50 width-100-mobile m-0-mobile">
            <Typography
              variant="subtitle1"
              className="acc-white bold start m-bottom-15">Identification</Typography>
            <Typography
              variant="subtitle2"
              className="acc-white start"><i>For U.S. Citizens Only</i></Typography>
            <StyledInput
              as={AccInput}
              margin="dense"
              name={`beneficialOwners[${beneficialOwnerIndex}].identification.driversLicenseNumber`}
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.identification?.driversLicenseNumber}
              onChange={handleChange}
              label="Driver's License Number"
            />
            <StyledInput
              as={AccOptionSelect}
              margin="dense"
              name={`beneficialOwners[${beneficialOwnerIndex}].identification.driversLicenseIssuingState`}
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.identification?.driversLicenseIssuingState}
              onChange={handleChange}
              label="Driver's License State of Issuance"
              options={US_STATES}
            />
            <StyledInput
              as={AccInput}
              margin="dense"
              type="date"
              name={`beneficialOwners[${beneficialOwnerIndex}].identification.driversLicenseExpirationDate`}
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.identification?.driversLicenseExpirationDate}
              onChange={handleChange}
              className="m-bottom-15"
              label="Driver's License Expiration Date"
            />
            <DividerWithText>or</DividerWithText>
            <Typography
              variant="subtitle2"
              className="acc-white start m-top-15"><i>For Non-U.S. Citizens Only</i></Typography>
            <StyledInput
              as={AccInput}
              margin="dense"
              name={`beneficialOwners[${beneficialOwnerIndex}].identification.passportNumber`}
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.identification?.passportNumber}
              onChange={handleChange}
              label="Passport Number"
            />
            <StyledInput
              as={AccOptionSelect}
              margin="dense"
              name={`beneficialOwners[${beneficialOwnerIndex}].identification.passportIssuingCountry`}
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.identification?.passportIssuingCountry}
              onChange={handleChange}
              label="Passport Country of Issuance"
              options={COUNTRIES}
            />
            <StyledInput
              as={AccInput}
              margin="dense"
              type="date"
              name={`beneficialOwners[${beneficialOwnerIndex}].identification.passportExpirationDate`}
              value={values?.beneficialOwners?.[beneficialOwnerIndex]?.identification?.passportExpirationDate}
              onChange={handleChange}
              label="Passport Expiration Date"
            />
          </div>
        </div>
      </div>);
  }


  return (
    <div className="start width-100 width-100-mobile">
      <Typography
        style={{ marginBottom: 30, textAlign: "left" }}
        variant="h5"
        className="acc-white bold">Please provide your personal information</Typography>
      <div className="row width-80 column-mobile width-100-mobile">
        <div className="column start m-right-50 width-50 width-100-mobile m-right-0-mobile m-bottom-15-mobile">
          <Typography
            variant="subtitle1"
            className="acc-white bold m-bottom-10">Personal Information</Typography>
          <StyledInput
            margin="dense"
            as={AccInput}
            name="applicant.firstName"
            value={values?.applicant?.firstName}
            onChange={handleChange}
            label="First Name"
          />
          <StyledInput
            margin="dense"
            as={AccInput}
            name="applicant.middleName"
            value={values?.applicant?.middleName}
            onChange={handleChange}
            label="Middle Name"
          />
          <StyledInput
            margin="dense"
            as={AccInput}
            name="applicant.lastName"
            value={values?.applicant?.lastName}
            onChange={handleChange}
            label="Last Name"
          />
          <StyledInput
            margin="dense"
            as={AccInput}
            name="applicant.dateOfBirth"
            type="date"
            value={values?.applicant?.dateOfBirth}
            onChange={handleChange}
            label="Date of Birth"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <StyledInput
            margin="dense"
            as={AccOptionSelect}
            name="applicant.countryOfBirth"
            value={values?.applicant?.countryOfBirth}
            onChange={handleChange}
            label="Country of Birth"
            options={COUNTRIES}
          />
          <StyledInput
            margin="dense"
            as={AccInput}
            name="applicant.ssn"
            maskedInput
            mask={AccMaskedInputTypes.SSN}
            value={values?.applicant?.ssn}
            onChange={handleChange}
            label="Social Security Number"
          />
          <Typography
            style={{ marginTop: 30, textAlign: "center" }}
            variant="subtitle1"
            className="acc-white bold m-bottom-10">Contact Information</Typography>
          <StyledInput
            margin="dense"
            as={AccInput}
            name="applicant.email"
            value={values?.applicant?.email}
            onChange={handleChange}
            label="Email Address"
          />
          <StyledInput
            margin="dense"
            as={AccInput}
            name="applicant.phone"
            maskedInput
            mask={AccMaskedInputTypes.US_PHONE_NUMBER}
            value={values?.applicant?.phone}
            onChange={
              handleChange
            }
            label="Phone Number"
          />
        </div>
        <div className="column start m-right-50 width-50 width-100-mobile m-0-mobile">
          <Typography
            variant="subtitle1"
            className="acc-white bold start m-bottom-10">Home Address</Typography>
          <StyledInput
            as={AccInput}
            margin="dense"
            name="applicant.address.line1"
            value={values?.applicant?.address?.line1}
            onChange={handleChange}
            label="Address Line 1"
          />
          <StyledInput
            as={AccInput}
            margin="dense"
            name="applicant.address.line2"
            value={values?.applicant?.address?.line2}
            onChange={handleChange}
            label="Address Line 2"
          />
          <StyledInput
            as={AccInput}
            margin="dense"
            name="applicant.address.city"
            value={values?.applicant?.address?.city}
            onChange={handleChange}
            label="City"
          />
          <StyledInput
            as={AccOptionSelect}
            margin="dense"
            name="applicant.address.state"
            value={values?.applicant?.address?.state}
            onChange={handleChange}
            label="State"
            options={US_STATES}
          />
          <StyledInput
            as={AccInput}
            margin="dense"
            name="applicant.address.postalCode"
            value={values?.applicant?.address?.postalCode}
            onChange={handleChange}
            label="Zip Code"
          />
          <StyledInput
            as={AccOptionSelect}
            margin="dense"
            name="applicant.address.country"
            value={values?.applicant?.address?.country}
            onChange={handleChange}
            label="Country of Residence"
            options={COUNTRIES}
          />
        </div>
        <div className="column start width-50 width-100-mobile m-0-mobile">
          <Typography
            variant="subtitle1"
            className="acc-white bold start m-bottom-15">Identification</Typography>
          <Typography
            variant="subtitle2"
            className="acc-white start"><i>For U.S. Citizens Only</i></Typography>
          <StyledInput
            as={AccInput}
            margin="dense"
            name="applicant.identification.driversLicenseNumber"
            value={values?.applicant?.identification?.driversLicenseNumber}
            onChange={handleChange}
            label="Driver's License Number"
          />
          <StyledInput
            as={AccOptionSelect}
            margin="dense"
            name="applicant.identification.driversLicenseIssuingState"
            value={values?.applicant?.identification?.driversLicenseIssuingState}
            onChange={handleChange}
            label="Driver's License State of Issuance"
            options={US_STATES}
          />
          <StyledInput
            as={AccInput}
            margin="dense"
            type="date"
            name="applicant.identification.driversLicenseExpirationDate"
            value={values?.applicant?.identification?.driversLicenseExpirationDate}
            onChange={handleChange}
            className="m-bottom-15"
            label="Driver's License Expiration Date"
          />
          <DividerWithText>or</DividerWithText>
          <Typography
            variant="subtitle2"
            className="acc-white start m-top-15"><i>For Non-U.S. Citizens Only</i></Typography>
          <StyledInput
            as={AccInput}
            margin="dense"
            name="applicant.identification.passportNumber"
            value={values?.applicant?.identification?.passportNumber}
            onChange={handleChange}
            label="Passport Number"
          />
          <StyledInput
            as={AccOptionSelect}
            margin="dense"
            name="applicant.identification.passportIssuingCountry"
            value={values?.applicant?.identification?.passportIssuingCountry}
            onChange={handleChange}
            label="Passport Country of Issuance"
            options={COUNTRIES}
          />
          <StyledInput
            as={AccInput}
            margin="dense"
            type="date"
            name="applicant.identification.passportExpirationDate"
            value={values?.applicant?.identification?.passportExpirationDate}
            onChange={handleChange}
            label="Passport Expiration Date"
          />
        </div>
      </div>
    </div>);
};
