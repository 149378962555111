import React from "react";
import { Link } from "react-router-dom";
import { useAssets } from "../../../api/hooks/graphqlHooks";
import COLORS from "../../../common/colors";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-light-svg-icons";
import { formatCurrency } from "../../../common/utilities";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import useMediaQuery from "@mui/material/useMediaQuery";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import PageWrapper from "../../../common/components/PageWrapper";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";

interface Props {

}

const Assets: React.FC<Props> = withErrorBoundary({}, () => {

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const { data, isLoading, isFetching, isError } = useAssets();

  const columns: GridColDef[] = [
    renderEditCell("/admin/assets/"),
    { field: "name", headerName: "Name", width: 300 },
    { field: "assetType", headerName: "Asset Type", width: 250, hide: mobileLayout },
    { field: "totalInvestmentAmount", headerName: "Total Investment Amount", width: 200, hide: mobileLayout },
    { field: "assetStatus", headerName: "Asset Status", width: 250, hide: mobileLayout },
    { field: "targetedInvestmentPeriod", headerName: "Targeted Investment Period", width: 200, hide: mobileLayout },
  ];

  const rows = data?.getAssets?.map(row =>
  ({
    ...row,
    name: row.name,
    assetType: row.assetType.name,
    totalInvestmentAmount: formatCurrency(row.totalInvestmentAmount),
    assetStatus: row.assetStatus.name,
    targetedInvestmentPeriod: `${row.targetedInvestmentPeriod} months`,
    id: row.assetId,
  }),
  );

  return (
    <PageWrapper
      title="Assets"
      showLoader={isLoading || !data?.getAssets || isFetching}
      showError={isError}
      errorMessage="Cannot retrieve assets"
    >
      <div style={{ height: "80vh", width: "100%" }}>
        <div className="row-mobile stretch m-bottom-30">
          <div style={{ justifyContent: "flex-start" }}>
          </div>
          <div
            className="row"
            style={{ justifyContent: "flex-end" }}>
            <Link to="/admin/assets/add">
              <AccButton
                color={AccButtonColors.GREEN}
                label="Add"
              />
            </Link>
          </div>
        </div>
        <DataGrid
          rows={rows || []}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            panel: DataGridStyles.panel,
          }}
          sx={DataGridStyles.toolBar}
        />
      </div>
    </PageWrapper>
  );
});

export { Assets, DataGridStyles, renderEditCell };

const renderEditCell = (editPageLink: string) => ({
  field: "Edit",
  filterable: false,
  renderCell: (cellValues: any) => {
    return <Link to={`${editPageLink}${cellValues.row.id}`}>
      <FontAwesomeIcon
        icon={faEdit}
        className="acc-darkgray m-left-15" />
    </Link>;
  },
});

const DataGridStyles = {
  panel: {
    sx: {
      "& .MuiTypography-root": {
        color: COLORS.darkBlue,
      },
      "& label.Mui-focused": {
        color: COLORS.darkBlue,
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: COLORS.green,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        color: COLORS.green,
      },
      "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
        backgroundColor: COLORS.lightGreen,
      },
      "& .MuiButton-root": {
        color: COLORS.darkBlue,
      },
    },
  },
  toolBar: {
    "& .MuiButton-root": {
      color: COLORS.darkGray,
    },
  },
};