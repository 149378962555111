import { useMutation } from "@apollo/client";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik, Form, Field, FormikValues } from "formik";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DELETE_ASSET_TYPE, UPDATE_ASSET_TYPE, CREATE_ASSET_TYPE } from "../../../api/query/AssetTypes";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import { isNil } from "../../../common/utilities";
import { EditModeTypes } from "../../../enums";
import CancelConfirmDialogModal from "../../../common/components/CancelConfirmDialogModal";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import COLORS from "../../../common/colors";
import { QueryKeys, useAssetType, useAssetClasses } from "../../../api/hooks/graphqlHooks";
import { useQueryClient } from "react-query";
import AccInput from "../../../common/components/AccInput";
import MenuItem from "@mui/material/MenuItem";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";
import PageWrapper from "../../../common/components/PageWrapper";

type AssetTypeDetailParams = {
  id?: string;
};

type AssetTypeDetailProps = RouteComponentProps<AssetTypeDetailParams>;

const AssetTypeForm: React.FC<AssetTypeDetailProps> = withErrorBoundary({}, ({ match, history }: AssetTypeDetailProps) => {
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  const queryClient = useQueryClient();

  const editMode: EditModeTypes = isNil(match.params.id) ? EditModeTypes.ADD : EditModeTypes.EDIT;
  if (editMode === EditModeTypes.ADD) {
    queryClient.removeQueries(QueryKeys.ASSET_TYPE);
  }
  const {
    data: getAssetTypeData,
    isLoading: getAssetTypeLoading,
    isFetching: getAssetsFetching,
    isError: getAssetTypeError,
  } = useAssetType(Number(match.params.id), {
    enabled: editMode === EditModeTypes.EDIT,
    refetchOnMount: true,
  });
  const [createAssetType] = useMutation(CREATE_ASSET_TYPE);
  const [deleteAssetType] = useMutation(DELETE_ASSET_TYPE);
  const [updateAssetType] = useMutation(UPDATE_ASSET_TYPE);

  const {
    data: getAssetClassesData,
    isLoading: getAssetClassesLoading,
    isFetching: getAssetClassesFetching,
    isError: getAssetClassesError,
  } = useAssetClasses({
    enabled: true,
    refetchOnMount: true,
  });

  const [cancelDialogVisible, setCancelDialogVisibible] = useState(false);

  const openCancelDialog = () => setCancelDialogVisibible(true);
  const closeCancelDialog = () => setCancelDialogVisibible(false);

  const BLANK_FORM_VALUES = {
    name: "",
    assetClassObjectId: "",
    assetClassName: "",
    assetClassId: "",
  };
  const initialFormFieldValues = () => (getAssetTypeData?.getAssetType ? {
    name: getAssetTypeData?.getAssetType.name,
    assetClassObjectId: getAssetTypeData?.getAssetType.assetClass ? getAssetTypeData?.getAssetType.assetClass.id : "Asset Class does not exist, assign new Asset Class",
    assetClassName: getAssetTypeData?.getAssetType.assetClass ? getAssetTypeData?.getAssetType.assetClass.name : <span style={{ color: "red" }}>Asset Class does not exist, assign new Asset Class</span>,
    assetClassId: getAssetTypeData?.getAssetType.assetClass ? getAssetTypeData?.getAssetType.assetClass.id : "Asset Class does not exist, assign new Asset Class",
  } : BLANK_FORM_VALUES);

  const handleDelete = async (objectId: string | number) => {
    try {
      await deleteAssetType({
        variables: {
          id: objectId,
        },
      });
      toast("Asset type deleted successfully!", {
        type: toast.TYPE.SUCCESS,
      });
      queryClient.refetchQueries(QueryKeys.ASSET_TYPES);
      history.push("/admin/assettypes");
    } catch (err: any) {
      toast(err.message || err.msg || err.toString() || "There was an error deleting asset type.", {
        type: toast.TYPE.WARNING,
      });
    }
  };

  const submitForm = async (values: FormikValues, { resetForm }: any) => {
    if (editMode === EditModeTypes.ADD) {
      try {
        await createAssetType({
          variables: {
            input: {
              name: values.name,
              assetClassObjectId: values.assetClassObjectId,
            },
          },
        });
        toast("Asset type added successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        resetForm();
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error adding asset type.", {
          type: toast.TYPE.ERROR,
        });
      }
    } else if (editMode === EditModeTypes.EDIT) {
      try {
        await updateAssetType({
          variables: {
            id: getAssetTypeData?.getAssetType.id,
            input: {
              name: values.name,
              assetClassObjectId: values.assetClassObjectId,
            },
          },
        });
        toast("Asset type updated successfully!", {
          type: toast.TYPE.SUCCESS,
        });
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error updating asset type.", {
          type: toast.TYPE.ERROR,
        });
      }
    }
    queryClient.refetchQueries(QueryKeys.ASSET_TYPES);
    history.push("/admin/assettypes");
  };


  return (
    <PageWrapper
      title={`${editMode === EditModeTypes.ADD ? "Add" : "Update"} Asset Type`}
      showLoader={getAssetTypeLoading || getAssetsFetching || getAssetClassesLoading || getAssetClassesFetching}
      showError={getAssetTypeError || getAssetClassesError}
      errorMessage="Cannot retrieve asset types"
    >
      <br />
      <div>
        <Formik
          initialValues={initialFormFieldValues()}
          enableReinitialize
          onSubmit={submitForm}
        >
          {({ values, handleChange }) => (
            <Form>
              <div className="column-reverse-mobile">
                <div className="row stretch center width-100 column-mobile">
                  <Link
                    to="/admin/assettypes"
                    className="width-100-mobile">
                    <AccButton
                      color={AccButtonColors.WHITE}
                      label="Cancel"
                      style={{ backgroundColor: COLORS.white }}
                    />
                  </Link>
                  <div className="row width-100-mobile column-mobile">
                    <AccButton
                      color={AccButtonColors.GREEN}
                      type="submit"
                      label={editMode === EditModeTypes.ADD ? "Submit" : "Update"}
                      className="m-top-15-mobile m-bottom-15-mobile"
                    />
                    {editMode === EditModeTypes.EDIT
                      ? (
                        <>
                          <AccButton
                            color={AccButtonColors.RED_OUTLINE}
                            label="Delete"
                            className="delete-button m-left-15 display-none-mobile"
                            type="button"
                            onClick={openCancelDialog}
                          />
                          {mobileLayout
                            && (
                              <AccButton
                                color={AccButtonColors.RED_OUTLINE}
                                label="Delete Asset Type"
                                className="delete-button"
                                type="button"
                                width="40%"
                                style={styles.mobileDeleteButton}
                                onClick={openCancelDialog}
                              />
                            )}
                        </>
                      )
                      : null}
                    {getAssetTypeData ? <CancelConfirmDialogModal
                      isVisible={cancelDialogVisible}
                      dialogBody="Are you sure you would like to delete this Asset Type?"
                      onClickCancel={closeCancelDialog}
                      onClickDelete={() => handleDelete(getAssetTypeData?.getAssetType.id)}
                    /> : null}
                  </div>
                </div>
                <br />
                <div
                  className="width-60 width-100-mobile"
                  style={styles.border}>
                  <Field
                    name="name"
                    type="text"
                    label="Asset Type Name"
                    as={AccInput}
                    required
                  />
                  <AccInput
                    name="assetClassObjectId"
                    value={values.assetClassObjectId}
                    label="Asset Class"
                    select
                    onChange={handleChange}
                    required
                  >
                    <MenuItem
                      selected
                      value={values.assetClassId}>
                      {values.assetClassName ? values.assetClassName : <span style={{ color: "red" }}>Asset Class does not exist, assign new Asset Class</span>}
                    </MenuItem>
                    {getAssetClassesData?.getAssetClasses
                      ?.filter(
                        (item) => item.id !== values.assetClassId,
                      )
                      .sort((itemA: { name: string }, itemB: { name: string }) => itemA.name.localeCompare(itemB.name))
                      .map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </AccInput>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </PageWrapper>
  );
});

const styles = {
  border: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    padding: "15px",
  },
  mobileDeleteButton: {
    alignSelf: "flex-start",
    border: "none",
    textAlign: "left",
    padding: 0,
    height: 20,
  },
};

export default withRouter(AssetTypeForm);
