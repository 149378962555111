import React from "react";
import { UMBFormValues, UMBYesNoValues } from "../common/umb.interfaces";
import AccInput, { AccMaskedInputTypes } from "../../../../common/components/AccInput";
import { COUNTRIES, US_STATES } from "../../../../common/constants";
import AccOptionSelect from "../../../../common/components/AccInput/variants/AccOptionSelect";
import { Typography } from "@material-ui/core";
import { StyledW9Input } from "../components/ThemedComponents";
import { useFormikContext } from "formik";
import { taxStatements, W9Instructions } from "../common/umb.data";
import { Checkbox } from "@mui/material";
import moment from "moment";
import COLORS from "../../../../common/colors";

interface Props {
  walletType: string;
}

export const ConsumerW9Form = ({ walletType }: Props) => {

  const { values, handleChange, setFieldValue } = useFormikContext<UMBFormValues>();
  const today = moment(new Date()).format("MM/DD/YYYY");

  return (
    <div className="row column-mobile">
      <div
        className="width-70 width-100-mobile"
        style={{ padding: 30, background: "white", marginBottom: "2px" }}>
        <div
          className="m-bottom-30 row stretch"
          style={{ borderBottom: "2px solid black" }}>
          <div
            className="row end width-20"
            style={{ borderRight: "2px solid black", paddingBottom: 15 }}>
            <Typography
              style={{ marginBottom: 3 }}
              className=" font-size-mobile-6">Form</Typography>&nbsp;
            <Typography
              className="bold font-size-mobile-5"
              variant="h4">W-9</Typography></div>
          <div className="width-70 m-bottom-15 width-60-mobile">
            <Typography
              style={{ textAlign: "center" }}
              variant="h6"
              className="bold condensed-lineheight font-size-mobile-5">Request or Taxpayer <br /> Identification Number and Certification</Typography>
          </div>
          <div
            className=" width-20"
            style={{ borderLeft: "2px solid black" }}></div>
        </div>
        <div className="row column-mobile">
          <div className="column start m-right-30 width-40 width-100-mobile m-right-0-mobile m-bottom-15-mobile">
            <Typography
              style={{ marginBottom: 10 }}
              variant="subtitle1"
              className="bold">Name</Typography>
            <StyledW9Input
              margin="dense"
              as={AccInput}
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              label="First Name"
              InputProps={{
                readOnly: true,
              }}
            />
            <StyledW9Input
              margin="dense"
              as={AccInput}
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              label="Last Name"
              InputProps={{
                readOnly: true,
              }}
            />
            <br />
            <Typography
              style={{ marginBottom: 10 }}
              variant="subtitle1"
              className="bold">Tax classification</Typography>
            <Typography
              style={{ textTransform: "capitalize" }}
              variant="subtitle2"
              className="acc-green">{walletType?.toLowerCase()}</Typography>
            <br />
            <Typography
              style={{ marginBottom: 10 }}
              variant="subtitle1"
              className="bold">Address</Typography>
            <StyledW9Input
              as={AccInput}
              margin="dense"
              name="address"
              value={values.address}
              onChange={handleChange}
              label="Address Line 1"
              InputProps={{
                readOnly: true,
              }}
            />
            <StyledW9Input
              as={AccInput}
              margin="dense"
              name="address2"
              value={values.address2}
              onChange={handleChange}
              label="Address Line 2"
              InputProps={{
                readOnly: true,
              }}
            />
            <StyledW9Input
              as={AccInput}
              margin="dense"
              name="city"
              value={values.city}
              onChange={handleChange}
              label="City"
              InputProps={{
                readOnly: true,
              }}
            />
            <StyledW9Input
              as={AccOptionSelect}
              margin="dense"
              name="state"
              value={values.state}
              onChange={handleChange}
              label="State"
              options={US_STATES}
              InputProps={{
                readOnly: true,
              }}
            />
            <StyledW9Input
              as={AccInput}
              margin="dense"
              name="postalCode"
              value={values.postalCode}
              onChange={handleChange}
              label="Zip Code"
              InputProps={{
                readOnly: true,
              }}
            />
            <StyledW9Input
              as={AccOptionSelect}
              margin="dense"
              name="country"
              value={values.country}
              onChange={handleChange}
              label="Country of Residence"
              options={COUNTRIES}
              InputProps={{
                readOnly: true,
              }}
            />
          </div>
          <div className="column start width-60 m-left-30 m-0-mobile width-100-mobile">
            <Typography
              style={{ marginBottom: 10 }}
              variant="subtitle1"
              className="bold">Taxpayer Identification Number (TIN)</Typography>
            <StyledW9Input
              margin="dense"
              as={AccInput}
              name="ssn"
              maskedInput
              mask={AccMaskedInputTypes.SSN}
              value={values.ssn}
              onChange={handleChange}
              label="Social Security Number"
              style={{ width: "70%" }}
              className="width-100-mobile"
              InputProps={{
                readOnly: true,
              }}
            />
            <br />
            <Typography
              style={{ marginBottom: 10 }}
              variant="subtitle2"
              className="bold">Under penalties of perjury, I certify that:</Typography>
            {taxStatements.map(item =>
              <>
                <Typography
                  className="condensed-lineheight"
                  variant="caption">
                  <span className="bold">{item.number}.</span> {item.description}</Typography>
                <br />
              </>,
            )}
            <br />
            <Typography
              className="condensed-lineheight"
              variant="caption"
              style={{ fontStyle: "italic" }}>The Internal Revenue Service does not require your consent to any provision of this document other than the certifications required to avoid backup withholding.</Typography>
            <div className="m-top-30 row column-mobile">
              <div className="row start width-70 m-right-30 width-100-mobile m-right-0-mobile">
                <Checkbox
                  name="areTaxStatementsAccurate"
                  checked={values.areTaxStatementsAccurate === UMBYesNoValues.YES}
                  onClick={() => {
                    if (values.areTaxStatementsAccurate === UMBYesNoValues.YES) {
                      setFieldValue("areTaxStatementsAccurate", UMBYesNoValues.NO);
                    } else {
                      setFieldValue("areTaxStatementsAccurate", UMBYesNoValues.YES);
                    }
                  }}
                  style={styles.checkbox}
                />
                <Typography
                  className="condensed-lineheight"
                  variant="caption"
                >Do you certify under penalties of perjury that the tax certification statements above are accurate and true?</Typography>
              </div>
              <div className="row-mobile m-top-15-mobile width-100-mobile center">
                <Typography className="acc-darkgray bold">Date&nbsp;</Typography>
                <Typography
                  className="acc-green light"
                  variant="subtitle2">{today}</Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="column start width-30 m-left-50 width-100-mobile m-left-0-mobile m-top-30-mobile">
        {W9Instructions.map(item =>
          <>
            <Typography
              style={{ marginBottom: 15 }}
              variant="h5"
              className="acc-white bold">{item.header}</Typography>
            <Typography
              variant="caption"
              className="acc-white light condensed-lineheight"
            >{item.description}</Typography>
            {item.link && <Typography
              variant="caption"
              className="acc-green light underlined-link">
              <a
                href={item.link}
                target="_blank"
                rel="noreferrer">W-9 instructions</a>
            </Typography>}
            <br />
            <br />
          </>,
        )}
      </div>
    </div>);
};

const styles = {
  checkbox: {
    color: COLORS.green,
    "&.Mui-checked": {
      color: COLORS.green,
    },
    padding: "0px 10px 0px 0px",
  },
};
