import gql from "graphql-tag";
import { gql as gqlv2 } from "graphql-request";
import { makeGraphqlRequest } from "../../utils";

export const CREATE_WALLET_STATUS = gql`
  mutation CreateWalletStatus($input: WalletStatusInput!) {
    createWalletStatus(input: $input) {
      name
    }
  }
`;

export const UPDATE_WALLET_STATUS = gql`
  mutation UpdateWalletStatus($id: String!, $input: WalletStatusInput!) {
    updateWalletStatus(_id: $id, input: $input) {
      id
      name
    }
  }
`;

export const DELETE_WALLET_STATUS = gql`
  mutation DeleteWalletStatus($id: String!) {
    deleteWalletStatus(_id: $id) {
      id
      walletStatusId
    }
  }
`;

const GET_WALLET_STATUS_V2 = gqlv2`
  query GetWalletStatus ($walletStatusId: Int!){
    getWalletStatus(walletStatusId: $walletStatusId) {
      id
      walletStatusId
      name
    }
  }
`;

export const getWalletStatus = (walletStatusId: number) => makeGraphqlRequest(GET_WALLET_STATUS_V2, { walletStatusId });

const GET_WALLET_STATUSES_V2 = gqlv2`
  query GetWalletStatuses {
    getWalletStatuses {
      id
      walletStatusId
      name
    }
  }
`;

export const getWalletStatuses = () => makeGraphqlRequest(GET_WALLET_STATUSES_V2);