
import React from "react";
import AccInput, { AccMaskedInputTypes } from "../../../../common/components/AccInput";
import { COUNTRIES, US_STATES } from "../../../../common/constants";
import AccOptionSelect from "../../../../common/components/AccInput/variants/AccOptionSelect";
import { Typography } from "@material-ui/core";
import { StyledInput } from "../components/ThemedComponents";
import { useFormikContext } from "formik";
import { UMBFormValues } from "../common/umb.interfaces";


export const VerifyConsumerPersonalInformation = () => {

  const { values, handleChange } = useFormikContext<UMBFormValues>();

  return (
    <>
      <Typography
        style={{ marginBottom: 30, textAlign: "center" }}
        variant="h5"
        className="acc-white bold">Verify your information</Typography>
      <div className="row width-60 column-mobile width-100-mobile">
        <div className="column m-right-30 width-50 width-100-mobile m-right-0-mobile m-bottom-15-mobile">
          <Typography
            variant="subtitle1"
            className="acc-white bold">Personal Information</Typography>
          <StyledInput
            margin="dense"
            as={AccInput}
            name="firstName"
            value={values?.firstName}
            onChange={handleChange}
            label="First Name"
          />
          <StyledInput
            margin="dense"
            as={AccInput}
            name="middleName"
            value={values?.middleName}
            onChange={handleChange}
            label="Middle Name"
          />
          <StyledInput
            margin="dense"
            as={AccInput}
            name="lastName"
            value={values?.lastName}
            onChange={handleChange}
            label="Last Name"
          />
          <StyledInput
            margin="dense"
            as={AccInput}
            name="dateOfBirth"
            type="date"
            value={values?.dateOfBirth}
            onChange={handleChange}
            label="Date of Birth"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <StyledInput
            margin="dense"
            as={AccOptionSelect}
            name="countryOfBirth"
            value={values?.countryOfBirth}
            onChange={handleChange}
            label="Country of Birth"
            options={COUNTRIES}
          />
          <StyledInput
            margin="dense"
            as={AccInput}
            name="ssn"
            maskedInput
            mask={AccMaskedInputTypes.SSN}
            value={values?.ssn}
            onChange={handleChange}
            label="Social Security Number"
          />
        </div>
        <div className="column m-left-30 width-50 width-100-mobile m-0-mobile">
          <Typography
            variant="subtitle1"
            className="acc-white bold">Address</Typography>
          <StyledInput
            as={AccInput}
            margin="dense"
            name="address"
            value={values?.address}
            onChange={handleChange}
            label="Address Line 1"
          />
          <StyledInput
            as={AccInput}
            margin="dense"
            name="address2"
            value={values?.address2}
            onChange={handleChange}
            label="Address Line 2"
          />
          <StyledInput
            as={AccInput}
            margin="dense"
            name="city"
            value={values?.city}
            onChange={handleChange}
            label="City"
          />
          <StyledInput
            as={AccOptionSelect}
            margin="dense"
            name="state"
            value={values?.state}
            onChange={handleChange}
            label="State"
            options={US_STATES}
          />
          <StyledInput
            as={AccInput}
            margin="dense"
            name="postalCode"
            value={values?.postalCode}
            onChange={handleChange}
            label="Zip Code"
          />
          <StyledInput
            as={AccOptionSelect}
            margin="dense"
            name="country"
            value={values?.country}
            onChange={handleChange}
            label="Country of Residence"
            options={COUNTRIES}
          />
        </div>
      </div>
      <div className="width-60 width-100-mobile">
        <Typography
          style={{ marginTop: 30, textAlign: "center" }}
          variant="subtitle1"
          className="acc-white bold">Contact Information</Typography>
        <div className="row column-mobile">
          <StyledInput
            margin="dense"
            as={AccInput}
            className="m-right-30 m-right-0-mobile"
            name="email"
            value={values?.email}
            onChange={handleChange}
            label="Email Address"
          />
          <StyledInput
            margin="dense"
            as={AccInput}
            className="m-left-30 m-left-0-mobile"
            name="phone"
            maskedInput
            mask={AccMaskedInputTypes.US_PHONE_NUMBER}
            value={values?.phone}
            onChange={
              handleChange
            }
            label="Phone Number"
          />
        </div>
      </div>
    </>);
};
