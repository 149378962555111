import React, { useEffect, useContext, useState } from "react";
import { SignatureContext } from "../../../../../context";
import { Typography } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import COLORS from "../../../../../common/colors";
import { formatCurrency, isPeriodOver24Months } from "../../../../../common/utilities";
import { useQueryClient } from "react-query";
import { QueryKeys } from "../../../../../api/hooks/graphqlHooks";
import { Asset } from "../../../../../common/types";
import CancelConfirmDialogModal from "../../../../../common/components/CancelConfirmDialogModal";
import { AccButtonColors } from "../../../../../common/components/AccButton";
import CustomTooltip from "../../../../../common/components/CustomTooltip";

interface Props {
  showEditInvestmentModal: boolean;
  setShowEditInvestmentModal: (arg: boolean) => void;
}

const ThirdStep: React.FC<Props> = ({ showEditInvestmentModal, setShowEditInvestmentModal }) => {

  const { setSignatureData, setNextButtonDisabled, setActiveStep } = useContext(SignatureContext);
  const [checkedTerms, setCheckedTerms] = useState<boolean>(false);
  const [checkedLpa, setCheckedLpa] = useState<boolean>(false);
  const [checkedAgreement, setCheckedAgreement] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const data: { getAsset?: Asset } | undefined = queryClient.getQueryData(QueryKeys.ASSET);
  const investmentData: any | undefined = queryClient.getQueryData(QueryKeys.CREATE_INVESTMENT);
  // Why is this needed?
  useEffect(() => {
    setSignatureData("");
  }, []);

  const checkCheckBoxState = () => {
    if (checkedLpa && checkedTerms && checkedAgreement) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  };

  //Check checkBox on change of checkedTerms or checkedLpa state
  useEffect(() => {
    checkCheckBoxState();
  }, [checkedTerms, checkedLpa, checkedAgreement]);

  const handleBack = async () => {
    setActiveStep(0);
    setShowEditInvestmentModal(false);
  };

  const assetDetails = [
    { value: data?.getAsset?.assetType.assetClass.vertical.name, description: "Vertical" },
    { value: data?.getAsset?.assetType.assetClass.name, description: "Asset Class" },
    { value: data?.getAsset?.assetType.name, description: "Asset Type" },
    { value: data?.getAsset?.investmentJurisdiction, description: "Jurisdiction" },
    { value: data?.getAsset?.marketType, description: "Market Type" },
  ];

  const projections = [
    {
      value: `${data?.getAsset?.targetedIRR}%`,
      description: "Targeted IRR",
      definition: "IRR (Internal Rate of Return): A calculation of the profitability of an investment.  Mathematically, IRR is the discount rate that makes the net present value (NPV) of all cash flows (investment outflow and distribution inflows) equal to zero",
    },
    {
      value: `${data?.getAsset?.targetedAverageCashYield}%`,
      description: "Targeted Average Cash Yield",
      definition: "Targeted Average Cash Yield: An estimate of the annual distributions as a percentage of the original investment. Simply put it is an estimate of the return from an asset which generates income.",
    },
    {
      value: `${data?.getAsset?.targetedMOIC}x`,
      description: "Targeted MoIC",
      definition: "MoIC (Multiple on Invested Capital):  The sum of realized proceeds and unrealized value divided by the money invested. This illustrates the total return of an investment over the life of the investment.",
    },
    {
      value: isPeriodOver24Months(data?.getAsset?.targetedInvestmentPeriod!),
      description: "Targeted Investment Period",
      definition: "Targeted Investment Period: The amount of time remaining in the deal as measured from actual investment date to the investment maturity.",
    },
    {
      value: "Transaction Fees",
      description: <a
        className="acc-green underlined-link"
        href={data?.getAsset?.advDocumentLocation}
        target="_blank"
        rel="noreferrer">See form ADV</a>,
      definition: "",
    },
  ];

  return (
    <div>
      <CancelConfirmDialogModal
        isVisible={showEditInvestmentModal}
        onClickCancel={() => setShowEditInvestmentModal(false)}
        onClickDelete={() => handleBack()}
        dialogHeader="If you go back you will have to sign subscription documents again!"
        cancelButtonText="No"
        confirmButtonText="Yes"
        confirmButtonColor={AccButtonColors.GREEN}
        cancelButtonColor={AccButtonColors.BLUE}
        confirmButtonClassName="green-button"
      />
      <div
        className="column start">
        <Typography
          variant="h4"
          className="acc-white bold font-size-mobile-1">
          Investment Summary for
          <span className="acc-green bold">&nbsp;{data?.getAsset?.name}</span>
        </Typography>
        <div
          className="row m-top-50 width-100 column-reverse-mobile"
          style={{ minHeight: "400px" }}>
          <div className="row start stretch width-50 width-100-mobile">
            <div
              className="column stretch start width-50"
              style={styles.projections}>
              {assetDetails.map(item =>
                <div
                  className="column start m-bottom-10-mobile"
                  key={item.value}>
                  <Typography className="acc-white bold font-size-mobile-4">{item.description}</Typography>
                  <Typography className="acc-lightgreen font-size-mobile-5">{item.value}</Typography>
                </div>,
              )}
            </div>
            <div
              className="column stretch start width-50"
              style={styles.projections}>
              {projections.map(item =>
                <CustomTooltip
                  key={item.value}
                  title={item.definition}
                  placement="right"
                  background={COLORS.white}
                  color={COLORS.darkBlue}
                  fontSize={11}>
                  <div
                    className="column start m-bottom-10-mobile">
                    <Typography
                      variant="h5"
                      className="acc-white bold projection-details font-size-mobile-4">
                      {item.value}
                    </Typography>
                    <Typography
                      className="acc-white light projection-description font-size-mobile-5">
                      {item.description}
                    </Typography>
                  </div>
                </CustomTooltip>,
              )}
            </div>
          </div>
          <div className="column start width-50 width-100-mobile m-bottom-30-mobile">
            <Typography
              variant="h6"
              className="acc-white bold">
              Investment Amount
            </Typography>
            <Typography
              style={{ padding: "15px 0px" }}
              variant="h2"
              className="acc-green bold">
              {formatCurrency(investmentData?.amount!)}
            </Typography>
            <Typography
              onClick={() => setShowEditInvestmentModal(true)}
              variant="caption"
              className="acc-red"
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >Edit investment amount</Typography>
            <div className="row start m-top-30">
              <Checkbox
                checked={checkedTerms}
                onChange={() => {
                  setCheckedTerms(!checkedTerms);
                  checkCheckBoxState();
                }}
                disableRipple
                sx={styles.checkbox} />
              <Typography
                variant="caption"
                className="acc-white light">
                By accepting the&nbsp;
                <span style={{ textDecoration: "underline" }}>
                  Terms & Conditions
                </span>
                , you agree to subscribe
              </Typography>
            </div>
            <div className="row start m-top-30">
              <Checkbox
                checked={checkedLpa}
                onChange={() => {
                  setCheckedLpa(!checkedLpa);
                  checkCheckBoxState();
                }}
                disableRipple
                sx={styles.checkbox} />
              <Typography
                variant="caption"
                className="acc-white light">
                <span>
                  Please read the Limited Partnership Agreement (the LPA)
                  <span style={{ textDecoration: "underline" }}>
                    <a
                      href={data?.getAsset?.lpaDocumentLocation}
                      target="_blank"
                      rel="noreferrer"> READ LPA
                    </a>
                  </span>
                  , and confirm your agreement by clicking.
                  The LPA is being signed by the General Partner on your behalf pursuant to the Power of Attorney.
                </span>
              </Typography>
            </div>
            <div className="row start m-top-30">
              <Checkbox
                checked={checkedAgreement}
                onChange={() => {
                  setCheckedAgreement(!checkedAgreement);
                  checkCheckBoxState();
                }}
                disableRipple
                sx={styles.checkbox} />
              <Typography
                variant="caption"
                className="acc-white light">
                <span>
                  Subscriber elects that Section 4(q) of the Subscription Agreement not apply to the Subscriber.
                  <br />
                  Note: If the Subscriber checks this box, and the form of Partnership Agreement is revised after the
                  Subscriber has submitted its subscription materials and before the Subscriber&apos;s subscription has been accepted,
                  the Subscriber&apos;s subscription may be rejected unless the Subscriber gives written notice to the General Partner
                  approving the change in the Partnership Agreement.
                </span>
              </Typography>
            </div>
          </div>
        </div>
        <br />
        <Typography
          style={{ fontSize: 8 }}
          className="acc-white light">
          *Targeted Yields, MoIC multiples and Time Lines are based on assumptions and projections made by the&nbsp;
          <span> {data?.getAsset?.managementCompany}</span>&nbsp;and are disclosed in <br />
          the <a
            className="acc-green underlined-link"
            href={data?.getAsset?.ppmDocumentLocation}
            target="_blank"
            rel="noreferrer">Private Placement Memorandum</a> for this Fund. There is no guarantee that such assumptions and projections will prove to be correct.
        </Typography>
      </div>
    </div >
  );
};

const styles = {
  checkbox: {
    padding: "0px 10px 0px 0px",
    color: "#00EEC1",
    "& .MuiSvgIcon-root": {
      fontSize: 25,
    },
    "&.Mui-checked": {
      color: "#00EEC1",
    },
    "&.MuiIcon-text": {
      fill: "blue",
    },
  },
  projections: {
    height: "100%",
    borderLeft: "1px solid #919197",
    paddingLeft: 30,
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  },
};

export default ThirdStep;
