import { useMutation } from "@apollo/client";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik, Form, FormikValues } from "formik";
import React, { useState, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CLOSE_WALLET, UPDATE_WALLET, CREATE_WALLET } from "../../../api/query/Wallets";
import { UPDATE_ACCREDITED_INVESTOR_REQUEST } from "../../../api/query/AccreditedInvestorRequests";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import { isNil, isNotNil } from "../../../common/utilities";
import { EditModeTypes } from "../../../enums";
import "../../../styles/index.css";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import COLORS from "../../../common/colors";
import { useWallet, QueryKeys, useAccreditedInvestorRequest } from "../../../api/hooks/graphqlHooks";
import { useQueryClient } from "react-query";
import UmbRequests from "./UmbRequests";
import ExternalAccounts from "./ExternalAccounts";
import WalletFields from "./WalletFields";
import AccreditationInfo from "./AccreditationInfo";
import TabView from "../../../common/components/TabView";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";
import PageWrapper from "../../../common/components/PageWrapper";
import { CloseAccountModal } from "../../InvestorAccounts/AccountDetails/components/CloseAccountModal";

type WalletDetailParams = {
  id?: string;
};
type WalletDetailProps = RouteComponentProps<WalletDetailParams>;

const WalletForm: React.FC<WalletDetailProps> = withErrorBoundary({}, ({ match, history }: WalletDetailProps) => {

  const [isCloseAccountModalVisible, setIsCloseAccountModalVisible] = useState<boolean>(false);
  const [closeWalletSubmitting, setCloseWalletSubmitting] = useState<boolean>(false);
  const [currTab, setCurrTab] = useState(0);
  const [newAccreditationStatus, setNewAccreditationStatus] = useState("");
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  const queryClient = useQueryClient();
  const editMode: EditModeTypes = isNil(match.params.id) ? EditModeTypes.ADD : EditModeTypes.EDIT;
  if (editMode === EditModeTypes.ADD) {
    queryClient.removeQueries(QueryKeys.WALLET);
    queryClient.removeQueries(QueryKeys.ACCREDITED_INVESTOR_REQUEST);
    queryClient.removeQueries(QueryKeys.ACCREDITATION_STATUSES);
  }
  const {
    data: getWalletData,
    isError: getWalletError,
  } = useWallet(Number(match.params.id), {
    enabled: editMode === EditModeTypes.EDIT,
    refetchOnMount: true,
  });

  const {
    data: accreditedInvestorData,
    isLoading: accreditedInvestorLoading,
    isFetching: accreditedInvestorFetching,
    isError: getAccreditationDataError,
  } = useAccreditedInvestorRequest(
    getWalletData?.getWallet?.id!, {
    refetchOnMount: true,
    enabled: isNotNil(getWalletData?.getWallet?.id),
  });

  const selectedWallet = getWalletData?.getWallet!;

  const [createWallet] = useMutation(CREATE_WALLET);
  const [closeWallet] = useMutation(CLOSE_WALLET);
  const [updateWallet] = useMutation(UPDATE_WALLET);
  const [updateAccreditatedInvestorRequest] = useMutation(UPDATE_ACCREDITED_INVESTOR_REQUEST);

  //Remove queries from previous wallet external accounts, and show external accounts for newly selected wallet
  useEffect(() => {
    queryClient.removeQueries(QueryKeys.WALLET_EXTERNAL_ACCOUNTS);
  }, [selectedWallet]);

  const BLANK_FORM_VALUES = {
    name: "",
    availableAmount: "",
    accountObjectId: "",
    accountName: "",
    accountId: "",
    accountHolderFullName: "",
    umbAccountId: "",
    walletTypeObjectId: "",
    walletTypeName: "",
    walletTypeId: "",
    walletStatusObjectId: "",
    walletStatusName: "",
    walletStatusId: "",
    createdAt: "",
    primaryUserObjectId: "",
    primaryUserFullName: "",
    primaryUserId: "",
  };

  const initialFormFieldValues = () => (getWalletData?.getWallet ? {
    name: getWalletData?.getWallet.name,
    availableAmount: getWalletData?.getWallet.availableAmount,
    accountHolderFullName: getWalletData?.getWallet.accountHolderFullName,
    umbAccountId: getWalletData?.getWallet.umbAccountId,
    accountObjectId: getWalletData?.getWallet.account ? getWalletData?.getWallet.account.id : "Account does not exist, assign new Account",
    accountName: getWalletData?.getWallet.account ? getWalletData?.getWallet.account.name : <span style={{ color: "red" }}>Account does not exist, assign new Account</span>,
    accountId: getWalletData?.getWallet.account ? getWalletData?.getWallet.account.id : "Account does not exist, assign new Account",
    walletTypeObjectId: getWalletData?.getWallet.walletType ? getWalletData?.getWallet.walletType.id : "Wallet Type does not exist, assign new Wallet Type",
    walletTypeName: getWalletData?.getWallet.walletType ? getWalletData?.getWallet.walletType.name : <span style={{ color: "red" }}>Wallet Type does not exist, assign new Wallet Type</span>,
    walletTypeId: getWalletData?.getWallet.walletType ? getWalletData?.getWallet.walletType.id : "Wallet Type does not exist, assign new Wallet Type",
    walletStatusObjectId: getWalletData?.getWallet.walletStatus ? getWalletData?.getWallet.walletStatus.id : "Wallet Status does not exist, assign new Wallet Status",
    walletStatusName: getWalletData?.getWallet.walletStatus ? getWalletData?.getWallet.walletStatus.name : <span style={{ color: "red" }}>Wallet Status does not exist, assign new Wallet Status</span>,
    walletStatusId: getWalletData?.getWallet.walletStatus ? getWalletData?.getWallet.walletStatus.id : "Wallet Status does not exist, assign new Wallet Status",
    createdAt: getWalletData?.getWallet.createdAt ? getWalletData?.getWallet.createdAt : "Wallet date of creation does not exist",
    primaryUserObjectId: getWalletData?.getWallet.primaryUser ? getWalletData?.getWallet.primaryUser.id : "Primary user does not exist, assign primary user",
    primaryUserFullName: getWalletData?.getWallet.primaryUser ? getWalletData?.getWallet.primaryUser.firstName + " " + getWalletData?.getWallet.primaryUser.lastName
      : <span style={{ color: "red" }}>Primary user does not exist, assign primary user</span>,
    primaryUserId: getWalletData?.getWallet.primaryUser ? getWalletData?.getWallet.primaryUser.id : "Primary user does not exist, assign primary user",
  } : BLANK_FORM_VALUES);

  const handleCloseWallet = async (walletObjectId: string | number, externalAccountObjectId: string) => {
    setCloseWalletSubmitting(true);
    try {
      await closeWallet({
        variables: {
          id: walletObjectId,
          input: {
            externalAccountObjectId,
          },
        },
      });
      toast("Wallet closed successfully!", {
        type: toast.TYPE.SUCCESS,
      });
      queryClient.refetchQueries(QueryKeys.WALLETS);
      history.push("/admin/wallets");
    } catch (err: any) {
      toast(err.message || err.msg || err.toString() || "There was an error closing wallet.", {
        type: toast.TYPE.WARNING,
      });
    }
    setIsCloseAccountModalVisible(false);
    setCloseWalletSubmitting(false);
  };

  const submitForm = async (values: FormikValues, { resetForm }: any) => {
    if (editMode === EditModeTypes.ADD) {
      try {
        await createWallet({
          variables: {
            input: {
              name: values.name,
              availableAmount: values.availableAmount,
              accountHolderFullName: values.accountHolderFullName,
              umbAccountId: values.umbAccountId,
              accountObjectId: values.accountObjectId,
              walletTypeObjectId: values.walletTypeObjectId,
              walletStatusObjectId: values.walletStatusObjectId,
              primaryUserObjectId: values.primaryUserObjectId,
            },
          },
        });
        toast("Wallet added successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        queryClient.refetchQueries(QueryKeys.WALLETS);
        history.push("/admin/wallets");
        resetForm();
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error adding wallet.", {
          type: toast.TYPE.ERROR,
        });
      }
    } else if (editMode === EditModeTypes.EDIT) {
      try {
        if (currTab === 0) {
          await updateWallet({
            variables: {
              id: getWalletData?.getWallet.id,
              input: {
                name: values.name,
                availableAmount: values.availableAmount,
                accountObjectId: values.accountObjectId,
                accountHolderFullName: values.accountHolderFullName,
                umbAccountId: values.umbAccountId,
                walletTypeObjectId: values.walletTypeObjectId,
                walletStatusObjectId: values.walletStatusObjectId,
                primaryUserObjectId: values.primaryUserObjectId,
              },
            },
          });
          toast("Wallet updated successfully!", {
            type: toast.TYPE.SUCCESS,
          });
          queryClient.refetchQueries(QueryKeys.WALLETS);
        } else if (currTab === 2) {
          await updateAccreditatedInvestorRequest({
            variables: {
              id: accreditedInvestorData?.getAccreditedInvestorRequest.id,
              input: {
                status: newAccreditationStatus,
                walletObjectId: accreditedInvestorData?.getAccreditedInvestorRequest.walletObjectId,
              },
            },
          });
        }
        history.push("/admin/wallets");
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error updating wallet.", {
          type: toast.TYPE.ERROR,
        });
      }
    }
  };

  const tabs = [
    {
      index: 0,
      label: "Wallet Info",
      children: <WalletFields />,
      disabled: false,
    },
    {
      index: 1,
      label: "UMB Requests",
      children: <UmbRequests />,
      disabled: editMode === EditModeTypes.ADD ? true : false,
    },
    {
      index: 2,
      label: "Accreditation Info",
      children:
        <AccreditationInfo
          setNewAccreditationStatus={setNewAccreditationStatus}
          accreditedInvestorData={accreditedInvestorData}
          accreditedInvestorLoading={accreditedInvestorLoading}
          accreditedInvestorFetching={accreditedInvestorFetching}
        />,
      disabled: editMode === EditModeTypes.ADD ? true : false,
    },
    {
      index: 3,
      label: "External Accounts",
      children: <ExternalAccounts />,
      disabled: editMode === EditModeTypes.ADD ? true : false,
    },
  ];

  return (
    <PageWrapper
      title={`${editMode === EditModeTypes.ADD ? "Add" : "Update"} Wallet`}
      showLoader={closeWalletSubmitting}
      showError={getWalletError || getAccreditationDataError}
      errorMessage="Cannot retrieve wallets"
    >
      <br />
      <div>
        <Formik
          initialValues={initialFormFieldValues()}
          enableReinitialize
          onSubmit={submitForm}
        >
          {() => (
            <Form>
              <div className="column-reverse-mobile">
                <div className="row stretch center width-100 column-mobile">
                  <Link
                    to="/admin/wallets"
                    className="width-100-mobile">
                    <AccButton
                      color={AccButtonColors.WHITE}
                      label="Cancel"
                      style={{ backgroundColor: COLORS.white }}
                    />
                  </Link>
                  <div className="row width-100-mobile column-mobile">
                    <AccButton
                      color={AccButtonColors.GREEN}
                      type="submit"
                      label={editMode === EditModeTypes.ADD ? "Submit" : "Update"}
                      className="m-top-15-mobile m-bottom-15-mobile"
                    />
                    {editMode === EditModeTypes.EDIT
                      ? (
                        <>
                          <AccButton
                            color={AccButtonColors.WHITE}
                            label="Close"
                            className="delete-button m-left-15 display-none-mobile"
                            type="button"
                            onClick={() => setIsCloseAccountModalVisible(true)}
                          />
                          {mobileLayout
                            && (
                              <AccButton
                                color={AccButtonColors.RED_OUTLINE}
                                label="Close Wallet"
                                className="delete-button"
                                type="button"
                                width="40%"
                                style={styles.mobileDeleteButton}
                                onClick={() => setIsCloseAccountModalVisible(true)}
                              />
                            )}
                        </>
                      )
                      : null}
                    {getWalletData ?
                      <>
                        <CloseAccountModal
                          visible={isCloseAccountModalVisible}
                          setVisible={setIsCloseAccountModalVisible}
                          wallet={selectedWallet}
                          handleCloseWallet={handleCloseWallet}
                        />
                      </>
                      : null}
                  </div>
                </div>
                <br />
                <TabView
                  tabs={tabs}
                  setTabValue={setCurrTab}
                  tabValue={currTab}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </PageWrapper>
  );
});

const styles = {
  border: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    padding: "15px",
  },
  mobileDeleteButton: {
    alignSelf: "flex-start",
    border: "none",
    textAlign: "left",
    padding: 0,
    height: 20,
  },
};

export default withRouter(WalletForm);
