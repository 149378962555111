import { useMutation } from "@apollo/client";
import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik, Form, Field, FormikValues } from "formik";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DELETE_ASSET_CLASS, UPDATE_ASSET_CLASS, CREATE_ASSET_CLASS } from "../../../api/query/AssetClasses";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import { isNil } from "../../../common/utilities";
import { EditModeTypes } from "../../../enums";
import CancelConfirmDialogModal from "../../../common/components/CancelConfirmDialogModal";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import COLORS from "../../../common/colors";
import { useVerticals, useAssetClass, QueryKeys } from "../../../api/hooks/graphqlHooks";
import { useQueryClient } from "react-query";
import { AssetClass } from "../../../common/types";
import AccInput from "../../../common/components/AccInput";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";
import PageWrapper from "../../../common/components/PageWrapper";

type AssetClassDetailParams = {
  id?: string;
};

type AssetClassDetailProps = RouteComponentProps<AssetClassDetailParams>;

const AssetClassForm: React.FC<AssetClassDetailProps> = withErrorBoundary({}, ({ match, history }: AssetClassDetailProps) => {
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const queryClient = useQueryClient();
  const editMode: EditModeTypes = isNil(match.params.id) ? EditModeTypes.ADD : EditModeTypes.EDIT;
  if (editMode === EditModeTypes.ADD) {
    queryClient.removeQueries(QueryKeys.ASSET_CLASS);
  }
  const {
    data: getAssetClassData,
    isLoading: getAssetClassLoading,
    isFetching: getAssetClassFetching,
    isError: getAssetClassError,
  } = useAssetClass(Number(match.params.id), {
    enabled: editMode === EditModeTypes.EDIT,
    refetchOnMount: true,
  });

  const [createAssetClass] = useMutation(CREATE_ASSET_CLASS);

  const [deleteAssetClass] = useMutation(DELETE_ASSET_CLASS);
  const [updateAssetClass] = useMutation(UPDATE_ASSET_CLASS);
  const {
    data: getVerticalsData,
    isLoading: getVerticalsLoading,
    isFetching: getVerticalsFetching,
    isError: getVerticalsError,
  } = useVerticals({
    enabled: true,
    refetchOnMount: true,
  });

  const [cancelDialogVisible, setCancelDialogVisibible] = useState(false);

  const openCancelDialog = () => setCancelDialogVisibible(true);
  const closeCancelDialog = () => setCancelDialogVisibible(false);

  const BLANK_FORM_VALUES = {
    name: "",
    verticalObjectId: "",
    verticalName: "",
    verticalId: "",
  };

  const initialFormFieldValues = () => {
    if (isNil(getAssetClassData)) return BLANK_FORM_VALUES;
    const { vertical, ...initialFormValues }: AssetClass = getAssetClassData?.getAssetClass ?? {};
    return {
      ...initialFormValues,
      verticalObjectId: vertical.id,
      verticalName: vertical?.name,
      verticalId: vertical?.id,
    };
  };

  const handleDelete = async (objectId: string | number) => {
    try {
      await deleteAssetClass({
        variables: {
          id: objectId,
        },
      });
      toast("Asset class deleted successfully!", {
        type: toast.TYPE.SUCCESS,
      });
      queryClient.refetchQueries(QueryKeys.ASSET_CLASSES);
      history.push("/admin/assetclasses");
    } catch (err: any) {
      toast(err.message || err.msg || err.toString() || "There was an error deleting asset class.", {
        type: toast.TYPE.WARNING,
      });
    }
  };

  const submitForm = async (values: FormikValues, { resetForm }: any) => {
    if (editMode === EditModeTypes.ADD) {
      try {
        await createAssetClass({
          variables: {
            input: {
              name: values.name,
              verticalObjectId: values.verticalObjectId,
            },
          },
        });
        toast("Asset class added successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        queryClient.refetchQueries(QueryKeys.ASSET_CLASSES);
        history.push("/admin/assetclasses");
        resetForm();
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error adding asset class.", {
          type: toast.TYPE.ERROR,
        });
      }
    } else if (editMode === EditModeTypes.EDIT) {
      try {
        await updateAssetClass({
          variables: {
            id: getAssetClassData?.getAssetClass.id,
            input: {
              name: values.name,
              verticalObjectId: values.verticalObjectId,
            },
          },
        });
        toast("Asset class updated successfully!", {
          type: toast.TYPE.SUCCESS,
        });
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error updating asset class.", {
          type: toast.TYPE.ERROR,
        });
      }
    }
    queryClient.refetchQueries(QueryKeys.ASSET_CLASSES);
    history.push("/admin/assetclasses");
  };

  return (
    <PageWrapper
      title={`${editMode === EditModeTypes.ADD ? "Add" : "Update"} Asset Class`}
      showLoader={getVerticalsLoading || getVerticalsFetching || getAssetClassLoading || getAssetClassFetching}
      showError={getAssetClassError || getVerticalsError}
      errorMessage="Cannot retrieve asset classes"
    >
      <br />
      <div>
        <Formik
          initialValues={initialFormFieldValues()}
          enableReinitialize
          onSubmit={submitForm}
        >
          {({ values, handleChange }) => (
            <Form>
              <div className="column-reverse-mobile">
                <div className="row stretch center width-100 column-mobile">
                  <Link
                    to="/admin/assetclasses"
                    className="width-100-mobile">
                    <AccButton
                      color={AccButtonColors.WHITE}
                      label="Cancel"
                      style={{ backgroundColor: COLORS.white }}
                    />
                  </Link>
                  <div className="row width-100-mobile column-mobile">
                    <AccButton
                      color={AccButtonColors.GREEN}
                      type="submit"
                      label={editMode === EditModeTypes.ADD ? "Submit" : "Update"}
                      className="m-top-15-mobile m-bottom-15-mobile"
                    />
                    {editMode === EditModeTypes.EDIT
                      ? (
                        <>
                          <AccButton
                            color={AccButtonColors.WHITE}
                            label="Delete"
                            className="delete-button m-left-15 display-none-mobile"
                            type="button"
                            onClick={openCancelDialog}
                          />
                          {mobileLayout
                            && (
                              <AccButton
                                color={AccButtonColors.WHITE}
                                label="Delete Asset Class"
                                className="delete-button"
                                type="button"
                                width="40%"
                                style={styles.mobileDeleteButton}
                                onClick={openCancelDialog}
                              />
                            )}
                        </>
                      )
                      : null}
                    {getAssetClassData ? <CancelConfirmDialogModal
                      isVisible={cancelDialogVisible}
                      dialogBody="Are you sure you would like to delete this Asset Class?"
                      onClickCancel={closeCancelDialog}
                      onClickDelete={() => handleDelete(getAssetClassData?.getAssetClass.id)}
                    /> : null}
                  </div>
                </div>
                <br />
                <div
                  style={styles.border}
                  className="width-60 width-100-mobile">
                  <Field
                    name="name"
                    type="text"
                    label="Asset Class Name"
                    as={AccInput}
                    required
                  />
                  <AccInput
                    name="verticalObjectId"
                    value={values.verticalObjectId}
                    label="Vertical"
                    select
                    onChange={handleChange}
                    required
                  >
                    <MenuItem
                      selected
                      value={values.verticalId}>
                      {values.verticalName ? values.verticalName : <span style={{ color: "red" }}>Vertical does not exist, assign new Vertical</span>}
                    </MenuItem>
                    {getVerticalsData?.getVerticals
                      ?.filter(
                        (item) => item.id !== values.verticalId,
                      )
                      .sort((itemA: { name: string }, itemB: { name: string }) => itemA.name.localeCompare(itemB.name))
                      .map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </AccInput>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </PageWrapper>
  );
});

const styles = {
  border: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    padding: "15px",
  },
  mobileDeleteButton: {
    alignSelf: "flex-start",
    border: "none",
    textAlign: "left",
    padding: 0,
    height: 20,
  },
};

export default withRouter(AssetClassForm);
