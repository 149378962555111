import React from "react";
import { Typography } from "@material-ui/core";
import { commercialPatriotAct } from "../common/umb.data.commercial";


export const CommercialPatriotAct = () => {

  return (
    <div className="column width-100">
      <Typography
        style={{ marginBottom: 20, textTransform: "uppercase" }}
        variant="h4"
        className="acc-lightgreen bold"
      >USA Patriot Act</Typography>
      <Typography
        style={{ marginBottom: 20 }}
        variant="caption"
        className="acc-white condensed-lineheight">The official title of the USA PATRIOT Act is “Uniting and Strengthening America by Providing<br />
        Appropriate Tools Required to Intercept and Obstruct Terrorism (USA PATRIOT) Act of 2001.”</Typography>
      <br />
      <div
        className="row column-mobile"
        style={{ justifyContent: "center", textAlign: "center" }}>
        <div
          className="column width-100-mobile"
          style={{ width: "45%" }}>
          {commercialPatriotAct.map(item =>
            <>
              <Typography
                style={{ marginBottom: 15, lineHeight: "25px" }}
                variant="h6"
                className="acc-white bold">{item.header}</Typography>
              {item.content.map(item =>
                <Typography
                  key={item}
                  variant="caption"
                  className="acc-white condensed-lineheight"
                  style={{ marginBottom: 10 }}>{item}</Typography>,
              )}
              <br />
            </>,
          )}
        </div>
      </div>
    </div>
  );
};