import { useMutation } from "@apollo/client";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik, Form, Field, FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DELETE_ACCOUNT, UPDATE_ACCOUNT, CREATE_ACCOUNT } from "../../../api/query/Accounts";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import { isNil, isNotNil } from "../../../common/utilities";
import { AccountStatus, EditModeTypes } from "../../../enums";
import CancelConfirmDialogModal from "../../../common/components/CancelConfirmDialogModal";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import COLORS from "../../../common/colors";
import { useQueryClient } from "react-query";
import { Account } from "../../../common/types";
import { QueryKeys, useAccount, useAddresses } from "../../../api/hooks/graphqlHooks";
import AccInput from "../../../common/components/AccInput";
import { Typography } from "@material-ui/core";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper } from "@mui/material";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";
import PageWrapper from "../../../common/components/PageWrapper";

type AccountDetailParams = {
  id?: string;
};

type AccountDetailProps = RouteComponentProps<AccountDetailParams>;

const AccountForm: React.FC<AccountDetailProps> = withErrorBoundary({}, ({ match, history }: AccountDetailProps) => {
  const queryClient = useQueryClient();
  const editMode: EditModeTypes = isNil(match.params.id) ? EditModeTypes.ADD : EditModeTypes.EDIT;
  if (editMode === EditModeTypes.ADD) {
    queryClient.removeQueries(QueryKeys.ACCOUNT);
  }

  const [cancelDialogVisible, setCancelDialogVisibible] = useState(false);

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const {
    data: getAccountData,
    isLoading: getAccountLoading,
    isFetching: getAccountFetching,
    isError: getAccountError,
  } = useAccount(Number(match.params.id), {
    enabled: editMode === EditModeTypes.EDIT,
    refetchOnMount: true,
  });

  const {
    data: getAddressesData,
    isLoading: getAddressesLoading,
    isFetching: getAddressesFetching,
    isError: getAddressesError,
  } = useAddresses(EditModeTypes.EDIT && Number(match.params.id), {
    enabled: editMode === EditModeTypes.EDIT,
    refetchOnMount: true,
  });

  const [createAccount] = useMutation(CREATE_ACCOUNT);
  const [deleteAccount] = useMutation(DELETE_ACCOUNT);
  const [updateAccount] = useMutation(UPDATE_ACCOUNT);


  const BLANK_FORM_VALUES = {
    name: "",
  };

  const initialFormFieldValues = () => {
    if (isNil(getAccountData)) return BLANK_FORM_VALUES;
    const { ...initialFormValues }: Account = getAccountData?.getAccount ?? {};
    return {
      ...initialFormValues,
    };
  };

  const openCancelDialog = () => setCancelDialogVisibible(true);
  const closeCancelDialog = () => setCancelDialogVisibible(false);

  const isAccountStatusOpen = getAccountData?.getAccount.status === AccountStatus.OPEN;

  const handleAccountStatus = async (objectId: string | number, action: string) => {
    try {
      if (action === AccountStatus.OPEN) {
      await deleteAccount({
        variables: {
          id: objectId,
        },
      });
      queryClient.refetchQueries(QueryKeys.ACCOUNTS);
      toast("Account closed successfully!", {
        type: toast.TYPE.SUCCESS,
      });
      } else if (action === AccountStatus.CLOSED) {
        await updateAccount({
          variables: {
            id: objectId,
            input: {
              status: AccountStatus.OPEN,
            },
          },
        });
        queryClient.refetchQueries(QueryKeys.ACCOUNTS);
        toast("Account opened successfully!", {
          type: toast.TYPE.SUCCESS,
        });
      }
      history.push("/admin/accounts");
    } catch (err: any) {
      toast(err.message || err.msg || err.toString() || "There was an error deleting account", {
        type: toast.TYPE.WARNING,
      });
    }
  };

  const submitForm = async (values: FormikValues, { resetForm }: any) => {
    if (editMode === EditModeTypes.ADD) {
      try {
        await createAccount({
          variables: {
            input: {
              name: values.name,
            },
          },
        });
        queryClient.refetchQueries(QueryKeys.ACCOUNTS);
        toast("Account added successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        history.push("/admin/accounts");
        resetForm();
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error adding account", {
          type: toast.TYPE.ERROR,
        });
      }
    } else if (editMode === EditModeTypes.EDIT) {
      try {
        await updateAccount({
          variables: {
            id: getAccountData?.getAccount.id,
            input: {
              name: values.name,
            },
          },
        });
        queryClient.refetchQueries(QueryKeys.ACCOUNTS);
        toast("Account updated successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        history.push("/admin/accounts");
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error updating account.", {
          type: toast.TYPE.ERROR,
        });
      }
    }
  };

  useEffect(() => {
    queryClient.removeQueries(QueryKeys.ADDRESSES);
  }, []);

  return (
    <PageWrapper
      title={`${editMode === EditModeTypes.ADD ? "Add" : "Update"} Account`}
      showLoader={getAccountLoading || getAccountFetching || getAddressesLoading || getAddressesFetching}
      showError={getAccountError || getAddressesError}
      errorMessage="Cannot retrieve accounts"
    >
      <br />
      <Formik
        initialValues={initialFormFieldValues()}
        enableReinitialize
        onSubmit={submitForm}
      >
        {() => (
          <Form>
            <div className="column-reverse-mobile">
              <div className="row stretch center width-100 column-mobile">
                <Link
                  to="/admin/accounts"
                  className="width-100-mobile">
                  <AccButton
                    color={AccButtonColors.WHITE}
                    label="Cancel"
                    style={{ backgroundColor: COLORS.white }}
                  />
                </Link>
                <div className="row width-100-mobile column-mobile">
                  <AccButton
                    color={AccButtonColors.GREEN}
                    type="submit"
                    label={editMode === EditModeTypes.ADD ? "Submit" : "Update"}
                    className="m-top-15-mobile m-bottom-15-mobile"
                  />
                  {editMode === EditModeTypes.EDIT
                    ? (
                      <>
                        <AccButton
                          color={isAccountStatusOpen ? AccButtonColors.RED_OUTLINE : AccButtonColors.GREEN_OUTLINE}
                          label={isAccountStatusOpen ? "Close" : "Open"}
                          className="delete-button m-left-15 display-none-mobile"
                          type="button"
                          onClick={openCancelDialog}
                        />
                        {mobileLayout
                          && (
                            <AccButton
                              color={isAccountStatusOpen ? AccButtonColors.RED_OUTLINE : AccButtonColors.GREEN_OUTLINE}
                              label={`${isAccountStatusOpen ? "Close " : "Open "} Account`}
                              className="delete-button"
                              type="button"
                              width="40%"
                              style={styles.mobileDeleteButton}
                              onClick={openCancelDialog}
                            />
                          )}
                      </>
                    )
                    : null}
                  {getAccountData ? <CancelConfirmDialogModal
                    isVisible={cancelDialogVisible}
                    confirmButtonColor={isAccountStatusOpen ? AccButtonColors.RED_OUTLINE : AccButtonColors.GREEN_OUTLINE}
                    confirmButtonText={isAccountStatusOpen ? "Close" : "Open"}
                    dialogBody={`Are you sure you would like to ${isAccountStatusOpen ? "close" : "open"} this Account?`}
                    onClickCancel={closeCancelDialog}
                    onClickDelete={() => handleAccountStatus(getAccountData?.getAccount.id, getAccountData?.getAccount.status)}
                  /> : null}
                </div>
              </div>
              <br />
              <div
                style={styles.border}
                className="width-60 width-100-mobile m-bottom-30 m-right-30 m-0-mobile">
                <Field
                  name="name"
                  type="text"
                  label="Account Name"
                  as={AccInput}
                  required
                />
              </div>
              {editMode === EditModeTypes.EDIT ?
                <div className="width-60 width-100-mobile m-bottom-30">
                  <Typography
                    className="acc-darkblue bold"
                    variant="h6">
                    Account Address
                  </Typography>
                  <br />
                  {isNotNil(getAddressesData?.getAddresses) ?
                    <TableContainer
                      component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Street</TableCell>
                            <TableCell align="right">Street 2</TableCell>
                            <TableCell align="right">City</TableCell>
                            <TableCell align="right">Zip Code</TableCell>
                            <TableCell align="right">State</TableCell>
                            <TableCell align="right">Country</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {getAddressesData?.getAddresses?.map(item =>
                            <TableRow
                              hover
                              key={item.addressId}
                              component={Link}
                              to={`/admin/address/${item.addressId}`}
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                              <TableCell
                                component="th"
                                scope="row">
                                {item.street1}
                              </TableCell>
                              <TableCell align="right">{item.street2}</TableCell>
                              <TableCell align="right">{item.city}</TableCell>
                              <TableCell align="right">{item.postalCode}</TableCell>
                              <TableCell align="right">{item.stateProvince}</TableCell>
                              <TableCell align="right">{item.country}</TableCell>
                            </TableRow>)}
                        </TableBody>
                      </Table>
                    </TableContainer> : null}
                  <Link to={`/admin/address/add/${match.params.id}`}>
                    <AccButton
                      color={AccButtonColors.GREEN}
                      label="Add Address"
                      style={{ width: 200, marginTop: 30 }}
                    />
                  </Link>
                </div>
                : null}
            </div>
          </Form>
        )}
      </Formik>
    </PageWrapper>
  );
});

const styles = {
  border: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    padding: "15px",
  },
  mobileDeleteButton: {
    alignSelf: "flex-start",
    border: "none",
    textAlign: "left",
    padding: 0,
    height: 20,
  },
};

export default withRouter(AccountForm);
