import React from "react";
import COLORS from "../../colors";
import { formatCurrency } from "../../utilities";
import { Bar } from "react-chartjs-2";
import { useMediaQuery } from "@mui/material";
import { THEME_BREAKPOINTS } from "../../constants";


interface CashFlowSimulationBarParams {
  targetedFundingDate: string,
  cashFlowModelRows: any,
  totalInvestmentAmount: any,
  investmentAmount: any,
  displayTargetedCashFlow: boolean,
  isPerformanceCashFlow: boolean,
}

const CashFlowSimulationBar: React.FC<CashFlowSimulationBarParams> = ({
  targetedFundingDate,
  cashFlowModelRows,
  totalInvestmentAmount,
  investmentAmount,
  displayTargetedCashFlow,
  isPerformanceCashFlow,
}) => {

  const dataLabels: Array<String> = [];
  let monthlyGrossCashFlow: Array<String> = [];
  let cumulativeMonthlyGrossCashFlow: Array<Number> = [];
  let initialInvestment: Array<Number> = [];

  targetedFundingDate = targetedFundingDate.substring(0, 7);
  const date = new Date(targetedFundingDate!);
  let month = date.getUTCMonth() + 1;
  let year = date.getUTCFullYear();

  for (let i = 0; i < cashFlowModelRows.length; i++) {
    if (month > 12) {
      month = 1;
      year += 1;
    }
    dataLabels.push(`${year}-${month < 10 ? `0${month}` : month}`);
    month++;
  };

  // Calculates Individual Investor % Ownership
  const individualInvestorPercentageOwnership = investmentAmount * 100 / totalInvestmentAmount;

  monthlyGrossCashFlow = cashFlowModelRows.map((val: Number) => ((typeof val === "number" ? val : Number(Object.values(val)[2])) * (individualInvestorPercentageOwnership * 0.01)).toString());
  // Calculates Cumulative Monthly Cash Flow & Monthly Gross Cash Flow
  let sumOfCumulativeMonthlyGrossCashFlow = 0;
  cashFlowModelRows.map((val: any) => {
    initialInvestment.push(investmentAmount);
    sumOfCumulativeMonthlyGrossCashFlow += (typeof val === "number" ? val : Number(Object.values(val)[2])) * (individualInvestorPercentageOwnership * 0.01);
    cumulativeMonthlyGrossCashFlow.push(Number(sumOfCumulativeMonthlyGrossCashFlow.toFixed(2)));
  });

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const barDataSets: any = [
    {
      label: "Initial Investment",
      data: initialInvestment,
      borderColor: COLORS.mediumCyan,
      backgroundColor: "transparent",
      barThickness: 5,
      type: "line",
      order: 0,
      tension: 0.1,
    },
  ];

  if (isPerformanceCashFlow) {
    // TODO: update data arrays in the future
    barDataSets.push(
      {
        label: "Monthly Gross Cash Flow ",
        data: [],
        type: "bar",
        backgroundColor: COLORS.greenTransparent,
        borderColor: COLORS.white,
        barThickness: 20,
        order: 1,
      },
      {
        label: "Cumulative Monthly Gross Cash Flow",
        data: [],
        borderColor: COLORS.lightGreen,
        backgroundColor: "transparent",
        barThickness: 5,
        type: "line",
        order: 2,
        tension: 0,
      },
    );
    if (displayTargetedCashFlow) {
      barDataSets.push(
        {
          label: "Monthly Gross Targeted Cash Flow",
          data: monthlyGrossCashFlow,
          type: "bar",
          backgroundColor: COLORS.yellowTransparent,
          borderColor: COLORS.white,
          barThickness: 20,
          order: 3,
        },
        {
          label: "Cumulative Gross Targeted Cash Flow",
          data: cumulativeMonthlyGrossCashFlow,
          borderColor: COLORS.yellow,
          backgroundColor: "transparent",
          barThickness: 5,
          type: "line",
          order: 4,
          tension: 0,
        });
    }
  } else {
    barDataSets.push(
      {
        label: "Monthly Gross Cash Flow ",
        data: monthlyGrossCashFlow,
        type: "bar",
        backgroundColor: COLORS.greenTransparent,
        borderColor: COLORS.white,
        barThickness: 20,
        order: 1,
      },
      {
        label: "Cumulative Monthly Gross Cash Flow",
        data: cumulativeMonthlyGrossCashFlow,
        borderColor: COLORS.lightGreen,
        backgroundColor: "transparent",
        barThickness: 5,
        type: "line",
        order: 2,
        tension: 0,
      },
    );
  }

  return (
    <Bar
      width={100}
      height={60}
      options={{
        maintainAspectRatio: true,
        tooltips: {
          callbacks: {
            label: function (tooltip: any) {
              return formatCurrency(tooltip.value);
            },
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: COLORS.white,
              callback: function (value: number) {
                return formatCurrency(value);
              },
            },
            gridLines: {
              color: COLORS.lightBlueGradient,
              zeroLineColor: COLORS.grayBlue,
            },
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: COLORS.white,
              minRotation: 45,
            },
            gridLines: {
              color: COLORS.grayBlue,
            },
          }],
        },
        legend: {
          align: "middle",
          color: "rgb(255, 99, 132)",
          display: mobileLayout ? false : true,
          labels: {
            paddingBelowLegends: true,
            paddingAfterLegends: true,
          },
        },
      }}
      data={{
        labels: dataLabels,
        datasets: barDataSets,
      }}
    />
  );
};

export default CashFlowSimulationBar;