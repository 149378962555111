import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { Link } from "react-router-dom";
import { useInvestments } from "../../../api/hooks/graphqlHooks";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { DataGridStyles, renderEditCell } from "../Assets";
import { formatCurrency } from "../../../common/utilities";
import PageWrapper from "../../../common/components/PageWrapper";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";

interface Props {

}

const Investment: React.FC<Props> = withErrorBoundary({}, () => {

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const { data, isLoading, isFetching, isError } = useInvestments();

  const columns: GridColDef[] = [
    renderEditCell("/admin/investments/"),
    { field: "name", headerName: "Name", width: 400 },
    { field: "wallet", headerName: "Wallet", width: 200, hide: mobileLayout },
    { field: "amount", headerName: "Amount", width: 200, hide: mobileLayout },
    { field: "status", headerName: "Status", width: 200, hide: mobileLayout },
    { field: "asset", headerName: "Asset", width: 300, hide: mobileLayout },
    { field: "type", headerName: "Type", width: 200, hide: mobileLayout },
  ];

  const rows = data?.getInvestments?.map(row =>
  ({
    ...row,
    name: row.name,
    wallet: row.wallet ? row.wallet.name : "WALLET",
    amount: formatCurrency(row.amount),
    status: row.status,
    asset: row.asset.name,
    id: row.investmentId,
    type: row.type,
  }),
  );

  return (
    <PageWrapper
      title="Investments"
      showLoader={isLoading || isFetching}
      showError={isError}
      errorMessage="Cannot retrieve investments"
    >
      <div style={{ height: "80vh", width: "100%" }}>
        <div className="row-mobile stretch">
          <div style={{ justifyContent: "flex-start" }}>
          </div>
          <div
            className="row width-100"
            style={{ justifyContent: "flex-end" }}>
            <Link to="/admin/investments/add">
              <AccButton
                color={AccButtonColors.GREEN}
                label="Add"
              />
            </Link>
          </div>
        </div>
        <br />
        <Divider />
        <br />
        <DataGrid
          rows={rows || []}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            panel: DataGridStyles.panel,
          }}
          sx={DataGridStyles.toolBar}
        />
      </div>
    </PageWrapper>
  );
});

export default Investment;
