import { useMutation } from "@apollo/client";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik, Form, Field, FormikValues } from "formik";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DELETE_ASSET_STATUS, UPDATE_ASSET_STATUS, CREATE_ASSET_STATUS } from "../../../api/query/AssetStatuses";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import CancelConfirmDialogModal from "../../../common/components/CancelConfirmDialogModal";
import { isNil } from "../../../common/utilities";
import { EditModeTypes } from "../../../enums";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import COLORS from "../../../common/colors";
import { QueryKeys, useAssetStatus } from "../../../api/hooks/graphqlHooks";
import { useQueryClient } from "react-query";
import AccInput from "../../../common/components/AccInput";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";
import PageWrapper from "../../../common/components/PageWrapper";

type AssetStatusDetailParams = {
  id?: string;
};

type AssetStatusDetailProps = RouteComponentProps<AssetStatusDetailParams>;

const AssetStatusForm: React.FC<AssetStatusDetailProps> = withErrorBoundary({}, ({ match, history }: AssetStatusDetailProps) => {
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const queryClient = useQueryClient();
  const editMode: EditModeTypes = isNil(match.params.id) ? EditModeTypes.ADD : EditModeTypes.EDIT;
  if (editMode === EditModeTypes.ADD) {
    queryClient.removeQueries(QueryKeys.ASSET_STATUS);
  }
  const {
    data: getAssetStatusData,
    isLoading: getAssetStatusLoading,
    isFetching: getAssetStatusFetching,
    isError: getAssetStatusError,
  } = useAssetStatus(Number(match.params.id), {
    enabled: editMode === EditModeTypes.EDIT,
    refetchOnMount: true,
  });
  const [createAssetStatus] = useMutation(CREATE_ASSET_STATUS);
  const [deleteAssetStatus] = useMutation(DELETE_ASSET_STATUS);
  const [updateAssetStatus] = useMutation(UPDATE_ASSET_STATUS);

  const [cancelDialogVisible, setCancelDialogVisibible] = useState(false);

  const openCancelDialog = () => setCancelDialogVisibible(true);
  const closeCancelDialog = () => setCancelDialogVisibible(false);

  const BLANK_FORM_VALUES = {
    name: "",
  };

  const initialFormFieldValues = () => (getAssetStatusData?.getAssetStatus ? {
    name: getAssetStatusData?.getAssetStatus.name,
  } : BLANK_FORM_VALUES);

  const handleDelete = async (objectId: string | number) => {
    try {
      await deleteAssetStatus({
        variables: {
          id: objectId,
        },
      });
      toast("Asset status deleted successfully!", {
        type: toast.TYPE.SUCCESS,
      });
      queryClient.refetchQueries(QueryKeys.ASSET_STATUSES);
      history.push("/admin/assetstatuses");
    } catch (err: any) {
      toast(err.message || err.msg || err.toString() || "There was an error deleting Asset status.", {
        type: toast.TYPE.WARNING,
      });
    }
  };

  const submitForm = async (values: FormikValues, { resetForm }: any) => {
    if (editMode === EditModeTypes.ADD) {
      try {
        await createAssetStatus({
          variables: {
            input: {
              name: values.name,
            },
          },
        });
        toast("Asset Status added successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        resetForm();
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error adding Asset Status.", {
          type: toast.TYPE.ERROR,
        });
      }
    } else if (editMode === EditModeTypes.EDIT) {
      try {
        await updateAssetStatus({
          variables: {
            id: getAssetStatusData?.getAssetStatus.id,
            input: {
              name: values.name,
            },
          },
        });
        toast("Asset status updated successfully!", {
          type: toast.TYPE.SUCCESS,
        });
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error updating asset status.", {
          type: toast.TYPE.ERROR,
        });
      }
    }
    queryClient.refetchQueries(QueryKeys.ASSET_STATUSES);
    history.push("/admin/assetstatuses");
  };

  return (
    <PageWrapper
      title={`${editMode === EditModeTypes.ADD ? "Add" : "Update"} Asset Status`}
      showLoader={getAssetStatusLoading || getAssetStatusFetching}
      showError={getAssetStatusError}
      errorMessage="Cannot retrieve asset statuses"
    >
      <br />
      <div>
        <Formik
          initialValues={initialFormFieldValues()}
          enableReinitialize
          onSubmit={submitForm}
        >
          {() => (
            <Form>
              <div className="column-reverse-mobile">
                <div className="row stretch center width-100 column-mobile">
                  <Link
                    to="/admin/assetstatuses"
                    className="width-100-mobile">
                    <AccButton
                      color={AccButtonColors.WHITE}
                      label="Cancel"
                      style={{ backgroundColor: COLORS.white }}
                    />
                  </Link>
                  <div className="row width-100-mobile column-mobile">
                    <AccButton
                      color={AccButtonColors.GREEN}
                      type="submit"
                      label={editMode === EditModeTypes.ADD ? "Submit" : "Update"}
                      className="m-top-15-mobile m-bottom-15-mobile"
                    />
                    {editMode === EditModeTypes.EDIT
                      ? (
                        <>
                          <AccButton
                            color={AccButtonColors.RED_OUTLINE}
                            label="Delete"
                            className="delete-button m-left-15 display-none-mobile"
                            type="button"
                            onClick={openCancelDialog}
                          />
                          {mobileLayout
                            && (
                              <AccButton
                                color={AccButtonColors.RED_OUTLINE}
                                label="Delete Asset Status"
                                className="delete-button"
                                type="button"
                                width="40%"
                                style={styles.mobileDeleteButton}
                                onClick={openCancelDialog}
                              />
                            )}
                        </>
                      )
                      : null}
                    {getAssetStatusData ? <CancelConfirmDialogModal
                      isVisible={cancelDialogVisible}
                      dialogBody="Are you sure you would like to delete this Asset Status?"
                      onClickCancel={closeCancelDialog}
                      onClickDelete={() => handleDelete(getAssetStatusData?.getAssetStatus.id)}
                    /> : null}
                  </div>
                </div>
                <br />
                <div
                  style={styles.border}
                  className="width-60 width-100-mobile">
                  <Field
                    name="name"
                    type="text"
                    label="Asset Status Name"
                    as={AccInput}
                    required
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </PageWrapper >
  );
});

const styles = {
  border: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    padding: "15px",
  },
  mobileDeleteButton: {
    alignSelf: "flex-start",
    border: "none",
    textAlign: "left",
    padding: 0,
    height: 20,
  },
};

export default withRouter(AssetStatusForm);
