import {
  useFormikContext,
} from "formik";
import React, { useContext } from "react";
import { RegistrationContext } from "..";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import RadioSelectCard from "../../../common/components/RadioSelectCard";
import { LabelValueInput, RegistrationFormValue } from "../../../common/types";
import { AccountTypes, UserTypes } from "../../../enums/RegistrationEnums";

interface Props {

}

const AccountType: React.FC<Props> = () => {
  const { values, setFieldValue } = useFormikContext<RegistrationFormValue>();

  // @ts-ignore
  const { nextPage } = useContext(RegistrationContext);

  const options = [
    {
      label: "I am an Investor",
      value: AccountTypes.INDIVIDUAL,
    },
    {
      label: "I am a Financial Advisor",
      value: AccountTypes.ENTITY,
    },
  ];

  return (
    <div
      className="column center stretch m-top-50"
    >
      <div className="radio-container width-60">
        {options.map((item: LabelValueInput) => (
          <RadioSelectCard
            key={item.label}
            onClick={() => {
              setFieldValue("accountType", item.value);
              setFieldValue("userTypeName", item.value === AccountTypes.INDIVIDUAL ? UserTypes.INVESTOR : UserTypes.ADVISOR);
            }}
            selected={values.accountType === item.value}
            label={item.label}
            cardStyle={{ maxWidth: 500, margin: "auto", marginBottom: 35 }}
          />
        ))}
      </div>
      <div className="m-top-30 width-100-mobile column center">
        <AccButton
          color={values.accountType ? AccButtonColors.GREEN : AccButtonColors.WHITE}
          disabled={!values.accountType}
          label="Next"
          onClick={() => nextPage()}
          style={{ width: 150 }}
        />
      </div>
    </div>
  );
};

export default AccountType;
