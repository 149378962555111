import { toast } from "react-toastify";
import { Account, AccreditedInvestorRequest, Optional } from "../types";
import moment from "moment";
import sanitize from "sanitize-html";
import { AccreditationStatus } from "../../enums";
import restClient from "../../api/rest/restClient";
import { NUMERICAL_AMOUNTS } from "../constants";

export const isNil = <T extends any | string | number | any[]>(val: Optional<T>): val is undefined | null => {
  if (val == null) {
    return true;
  }
  if (typeof val === "string") {
    return val === "";
  }
  if (typeof val === "number") {
    return val === 0;
  }
  if (Array.isArray(val)) {
    return val.length === 0;
  }
  return false;
};

export const isNotNil = <T extends any | string | number | any[]>(val: Optional<T>): val is T => !isNil(val);

export const standardErrorHandle = (err: any, customMessage?: string) => {
  const errorMessage = err.response?.data?.msg || err.msg || err.msg || err.toString();
  restClient.post("/log", {
    level: "error",
    message: `[REACT-APP] ${customMessage} - ${errorMessage}`,
  });
  console.log(err.response);
  toast(errorMessage || customMessage, { type: toast.TYPE.ERROR });
};

export const formatCurrency: (
  number: number,
  decimalPlaces?: number,
  currency?: string,
) => string = (number, decimalPlaces, currency) => {
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency || "USD",
    maximumFractionDigits: decimalPlaces != null && number > NUMERICAL_AMOUNTS.ONE_MILLION ? 0 : 2,
    minimumFractionDigits: decimalPlaces != null && number > NUMERICAL_AMOUNTS.ONE_MILLION ? 0 : 2,
  }).format(number);
};

export const formatPercentage: (
  number: number,
) => string = (number) => {
  return Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 2,
  }).format(number / 100);
};

export const hasMonthPassedFromDate = (date: string) => moment().isBefore(moment(new Date(date)).add(1, "month"));

export const sanitizedHTML = (html: string) => sanitize(html);

export const over100OrLessThan18YearsAgo = (date: string) => moment(date).isBefore(new Date(new Date().setFullYear(1900, 1, 1))) ||
  moment().diff(moment(date, "YYYY-MM-DD"), "years") < 18;

export const isPeriodOver24Months = (period: number) => {
  if (period <= 24) {
    return `${period} months`;
  } else if (period > 24) {
    if (period % 12 === 0) {
      return `${Math.round(period / 12)} years`;
    } else {
      return `${(period / 12).toFixed(1)} years`;
    }
  }
};

export const validatePhoneNumber = (input_str: string) => {
  var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  // A US phone number cannot start with 0 or 1
  return re.test(input_str) && !(input_str.startsWith("(0") || input_str.startsWith("(1"));
};


export const isAccreditationStatusCompleted = (wallet: any, accreditationArr: Array<AccreditedInvestorRequest>) => {
  const foundAccreditation = accreditationArr.find((ac: any) => ac.walletObjectId === wallet.id);
  return foundAccreditation?.status ? true : false;
};

export const isWalletNotAccredited = (wallet: any, accreditationArr: Array<AccreditedInvestorRequest>) => {
  const foundAccreditation = accreditationArr.find((ac: any) => ac.walletObjectId === wallet.id);
  return foundAccreditation?.status === AccreditationStatus.REJECTED;
};

export const isWalletStatusPending = (accountsArr: Array<Account>, accountObjectId: string) => {
  const foundAccount = accountsArr.find((account) => account.id === accountObjectId);
  return foundAccount?.status === AccreditationStatus.PENDING;
};

export const isWalletNotConnected = (wallet: any) => isNil(wallet.externalAccounts) ? true : false;

export const isSufficientFunds = (wallet: any, minimumInvestmentAmount: number) => {
  return minimumInvestmentAmount < wallet.availableAmount;
};

export const walletIsNotAvailable = (
  wallet: any,
  minimumInvestmentAmount: number,
  accreditationArr: Array<AccreditedInvestorRequest>,
  accountsArr: Array<Account>,
  accountObjectId: string) => {
  return !isAccreditationStatusCompleted(wallet, accreditationArr)
    || isWalletNotAccredited(wallet, accreditationArr)
    || isWalletNotConnected(wallet)
    || !isSufficientFunds(wallet, minimumInvestmentAmount)
    || isWalletStatusPending(accountsArr, accountObjectId);
};

export const validateEmail = (input_str: string) => {
  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // A US phone number cannot start with 0 or 1
  return re.test(input_str);
};

export const toSentenceCase = function (str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const checkCityInputHasNoDigits = function (city: string) {
  return /[0-9]/.test(city);
};
  
export const convertFromCreatedAtDateToRegDateString = (date: string) => {
  return new Date(Number(date) * 1000);
};