import { Typography } from "@material-ui/core";
import React from "react";
import { sanitizedHTML } from "../../../../../common/utilities";

interface Props {
    content: string;
};

const AssetTab: React.FC<Props> = ({content}) => {

    return (
        <div>
            {content ?
                <Typography
                    variant="subtitle1"
                    className="acc-darkgray font-size-mobile-5"
                    style={styles.typography}
                    dangerouslySetInnerHTML={{ __html: sanitizedHTML(content) }} />
                : null}
        </div>
    );
};

const styles = {
    typography: {
        fontWeight: 300,
        fontSize: "1.1rem",
        lineHeight: 1.2,
    },
};


export default AssetTab;