import { makeGraphqlRequest } from "../../utils";
import { gql as gqlv2 } from "graphql-request";

export const GET_PENDING_ACCOUNT_ONBOARD_APPLICATIONS_V2 = gqlv2`
  query getPendingAccountOnboardApplications {
    getPendingAccountOnboardApplications {
      entityName
      walletName
      accountObjectId
      primaryApplicantUserObjectId
      secondaryApplicantUserObjectId
      onboardStatus
      applicationType
      submissionDate
      primaryApplicant {
        email
        firstName
      }
      secondaryApplicant {
        email
        firstName
      }
    }
  }
`;

export const getPendingAccountOnboardApplications = () => makeGraphqlRequest(GET_PENDING_ACCOUNT_ONBOARD_APPLICATIONS_V2);
