import React from "react";
import { Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import { UMBCommercialFormValues, UMBYesNoValues } from "../common/umb.interfaces";
import { formSelectOptions } from "../common/umb.consts";
import { FormControlLabel } from "@mui/material";
import { StyledInput, StyledRadio } from "./ThemedComponents";
import COLORS from "../../../../common/colors";
import AccInput from "../../../../common/components/AccInput";

export const CommercialDueDilligence = () => {
  const { values, handleChange } = useFormikContext<UMBCommercialFormValues>();

  const renderSelectRadios = (name: string) => {
    return formSelectOptions.yesNoOptions.map((option) => (
      <FormControlLabel
        key={option.value}
        style={{ color: COLORS.white }}
        onChange={handleChange}
        name={"dueDilligence." + name}
        value={option.value}
        control={<StyledRadio />}
        label={option.label}
        // @ts-ignore
        checked={values.dueDilligence?.[name] === option.value}
      />
    ));
  };

  const LabelTypography = (props: any) => (
    <Typography
      align="right"
      style={{ marginTop: 7.5, fontWeight: "bold" }}
      className="acc-white m-right-30">
      {props?.children}
    </Typography>
  );

  const ConditionalLabelTypography = (props: any) => (
    <Typography
      align="right"
      className="acc-white">
      {props?.children}
    </Typography>
  );


  // // hasOrProcessesATMTransactions
  // // hasOverseasOffice
  // overseasOfficeLocation;
  // // controlledByNonUSPerson
  // foreignOwnershipDescription;
  // businessHeadquarters;
  // // hasPEPOwnerOrController
  // ownerOrControllerPEPDescription;
  // // engagesInImportExportActivity
  // anticpatedImportExportGoodsInvolved;
  // expectedImportExportTradeDollarAmount;
  // anticipatedImportExportCountries;
  // majorImportExportCustomersOrSuppliers;
  // // receivesOrSendsInfoForBets
  // isThirdPartyProcessorOrOriginator;
  // processorOrOriginatorPaymentNatureDescription;


  return (
    <div className="end column width-100 width-100-mobile">
      <Typography
        style={{ marginBottom: 30, textAlign: "left", alignSelf: "flex-start" }}
        variant="h5"
        className="acc-white bold"
      >
        Commercial Entity Due Diligence Information
      </Typography>
      <div className="end column width-80 column-mobile width-100-mobile">
        <div className="row start m-bottom-15">
          <LabelTypography>
            Does Entity operate a Money Services Business (MSB)?
          </LabelTypography>
          {renderSelectRadios("operatesMoneyServicesBusiness")}
        </div>
        <div className="row start m-bottom-15">
          <LabelTypography>
            Does Entity have a privately-owned ATM on premises and/or facilitate
            the processing of ATM transactions?
          </LabelTypography>
          {renderSelectRadios("hasOrProcessesATMTransactions")}
        </div>
        <div className="row start m-bottom-15">
          <div>
            <LabelTypography>
              Does Entity have any overseas offices?
            </LabelTypography>
            {values?.dueDilligence?.hasOverseasOffice === UMBYesNoValues.YES && (
              <div className="m-right-30">
                <ConditionalLabelTypography>
                  If yes, provide the location(s):
                </ConditionalLabelTypography>
                <StyledInput
                  as={AccInput}
                  margin="dense"
                  name="dueDilligence.overseasOfficeLocation"
                  value={values?.dueDilligence?.overseasOfficeLocation}
                  onChange={handleChange}
                  className="m-top-15 m-bottom-30"
                  label="Overseas Office Location(s)"
                />
              </div>
            )}
          </div>
          {renderSelectRadios("hasOverseasOffice")}
        </div>
        <div className="row start m-bottom-15">
          <div>
            <LabelTypography>
              Is Entity owned or controlled by a non-U.S. person or entity?
            </LabelTypography>
            {values?.dueDilligence?.controlledByNonUSPerson === UMBYesNoValues.YES && (
              <div className="m-right-30">
                <ConditionalLabelTypography>
                  If yes, complete the following:
                </ConditionalLabelTypography>
                <StyledInput
                  as={AccInput}
                  margin="dense"
                  name="dueDilligence.foreignOwnershipDescription"
                  value={values?.dueDilligence?.foreignOwnershipDescription}
                  onChange={handleChange}
                  className="m-top-15"
                  style={{ minWidth: 700 }}
                  label="Describe Foreign Ownership/Control (include party and country - i.e., President - Canadian)"
                />
                <StyledInput
                  as={AccInput}
                  margin="dense"
                  name="dueDilligence.businessHeadquarters"
                  value={values?.dueDilligence?.businessHeadquarters}
                  onChange={handleChange}
                  className="m-top-15 m-bottom-30"
                  style={{ minWidth: 700 }}
                  label="Business Headquarters (if outside U.S.)"
                />
              </div>

            )}
          </div>
          {renderSelectRadios("controlledByNonUSPerson")}
        </div>
        <div className="row start m-bottom-15">
          <div>
            <LabelTypography>
              Do any owners/controllers meet the definition of a Politically
              Exposed Person (PEP), which is a Senior Foreign Political Figure,
              including his/her immediate family and close associates?
            </LabelTypography>
            {values?.dueDilligence?.hasPEPOwnerOrController === UMBYesNoValues.YES && (
              <div className="m-right-30">
                <ConditionalLabelTypography>
                  If yes, complete the following:
                </ConditionalLabelTypography>
                <StyledInput
                  as={AccInput}
                  margin="dense"
                  name="dueDilligence.ownerOrControllerPEPDescription"
                  value={values?.dueDilligence?.ownerOrControllerPEPDescription}
                  onChange={handleChange}
                  className="m-top-15"
                  style={{ minWidth: 700 }}
                  label="Explain Politically Exposed Person status of owners/controllers"
                />
              </div>
            )}
          </div>
          {renderSelectRadios("hasPEPOwnerOrController")}
        </div>
        <div className="row start m-bottom-15">
          <div>
            <LabelTypography>
              Does Entity expect to be engaged in import/export activities?
            </LabelTypography>
            {values?.dueDilligence?.engagesInImportExportActivity === UMBYesNoValues.YES && (
              <div className="m-right-30">
                <ConditionalLabelTypography>
                  If yes, complete the following:
                </ConditionalLabelTypography>
                <StyledInput
                  as={AccInput}
                  margin="dense"
                  name="dueDilligence.anticpatedImportExportGoodsInvolved"
                  value={values?.dueDilligence?.anticpatedImportExportGoodsInvolved}
                  onChange={handleChange}
                  className="m-top-15"
                  style={{ minWidth: 700 }}
                  label="Describe anticipated goods involved"
                />
                <StyledInput
                  as={AccInput}
                  margin="dense"
                  name="dueDilligence.expectedImportExportTradeDollarAmount"
                  value={values?.dueDilligence?.expectedImportExportTradeDollarAmount}
                  onChange={handleChange}
                  className="m-top-15"
                  style={{ minWidth: 700 }}
                  label="Describe the expected dollar amount of import/export trade activities per month"
                />
                <StyledInput
                  as={AccInput}
                  InputLabelProps={{
                    color: "red",
                  }}
                  margin="dense"
                  name="dueDilligence.anticipatedImportExportCountries"
                  value={values?.dueDilligence?.anticipatedImportExportCountries}
                  onChange={handleChange}
                  className="m-top-15"
                  style={{ minWidth: 700 }}
                  label="List anticipated foreign countries in which trades will occur"
                />
                <StyledInput
                  as={AccInput}
                  margin="dense"
                  name="dueDilligence.majorImportExportCustomersOrSuppliers"
                  value={values?.dueDilligence?.majorImportExportCustomersOrSuppliers}
                  onChange={handleChange}
                  className="m-top-15"
                  style={{ minWidth: 700 }}
                  label="List major customers/suppliers"
                />
              </div>
            )}
          </div>
          {renderSelectRadios("engagesInImportExportActivity")}
        </div>
        <div className="row start m-bottom-15">
          <LabelTypography>
            Does Entity knowingly use the Internet to receive or send
            information that could be used to place bets or facilitate in any
            way the placing of bets?
          </LabelTypography>
          {renderSelectRadios("receivesOrSendsInfoForBets")}
        </div>
        <div className="row start m-bottom-15">
          <div>
            <LabelTypography>
              Is Entity a third party payment processor/originator?
            </LabelTypography>
            {values?.dueDilligence?.isThirdPartyProcessorOrOriginator === UMBYesNoValues.YES && (
              <div className="m-right-30">
                <ConditionalLabelTypography>
                  If yes, complete the following:
                </ConditionalLabelTypography>
                <StyledInput
                  as={AccInput}
                  margin="dense"
                  name="dueDilligence.processorOrOriginatorPaymentNatureDescription"
                  value={values?.dueDilligence?.processorOrOriginatorPaymentNatureDescription}
                  onChange={handleChange}
                  className="m-top-15"
                  style={{ minWidth: 700 }}
                  label="Describe the nature of the payment processing/origination"
                />
              </div>
            )}
          </div>
          {renderSelectRadios("isThirdPartyProcessorOrOriginator")}
        </div>
      </div>
    </div>
  );
};
