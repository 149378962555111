import gql from "graphql-tag";
import { makeGraphqlRequest } from "../../utils";
import { gql as gqlv2 } from "graphql-request";

export const CREATE_WALLET = gql`
  mutation CreateWallet($input: WalletInput!) {
    createWallet(input: $input) {
      name
      availableAmount
      account {
        accountId
        name
      }
      walletType {
        walletTypeId
        name
      }
      walletStatus {
        walletStatusId
        name
        id
      }
      primaryUser {
        firstName
        lastName
        email
        id
      }
    }
  }
`;

export const UPDATE_WALLET = gql`
  mutation UpdateWallet($id: String!, $input: WalletInput!) {
    updateWallet(_id: $id, input: $input) {
      id
      name
      availableAmount
      account {
        accountId
        name
      }
      walletType {
        walletTypeId
        name
      }
      walletStatus {
        walletStatusId
        name
        id
      }
      primaryUser {
        firstName
        lastName
        email
        id
      }
    }
  }
`;

export const CLOSE_WALLET = gql`
  mutation CloseWallet($id: String!, $input: CloseAccountInput!) {
    closeWallet(_id: $id, input: $input) {
      id
      walletId
    }
  }
`;

export const GET_WALLET_V2 = gqlv2`
  query GetWallet($walletId: Int!) {
    getWallet(walletId: $walletId) {
      id
      walletId
      name
      availableAmount
      createdAt
      accountHolderFullName
      umbAccountId
      account {
        id
        accountId
        name
      }
      walletType {
        id
        walletTypeId
        name
      }
      walletStatus {
        walletStatusId
        name
        id
      }  
      externalAccounts {
        bankName
        displayName
        lastFour
        externalAccountId
        walletObjectId
        id
      }
      primaryUser {
        firstName
        lastName
        email
        id
      }
      secondaryUser {
        firstName
        lastName
        email
        id
      }
    }
  }
`;

export interface UpdateWalletInput {
  id: string;
  input: {
    name?: string;
    walletStatusObjectId?: string;
  };
}

export const updateWallet = (walletPayload: UpdateWalletInput) =>
  makeGraphqlRequest(UPDATE_WALLET, walletPayload);

export const getWallet = (walletId: number) =>
  makeGraphqlRequest(GET_WALLET_V2, { walletId });

export const GET_WALLETS_V2 = gqlv2`
  query GetWallets {
    getWallets {
      id
      walletId
      name
      availableAmount
      accountHolderFullName
      umbAccountId
      account {
        id
        accountId
        name
      }
      walletType {
         id
         walletTypeId
         name
      }
      walletStatus {
        walletStatusId
        name
        id
      }
      externalAccounts {
        bankName
        displayName
        lastFour
        externalAccountId
        walletObjectId
        id
      }
    }
  }
`;
export const getWallets = () => makeGraphqlRequest(GET_WALLETS_V2);

export const GET_USER_WALLETS_V2 = gqlv2`
  query GetUserWallets {
    getUserWallets {
      id
      walletId
      name
      availableAmount
      accountObjectId
      accountHolderFullName
      umbAccountId
      walletTypeObjectId
      walletType {
        walletTypeId
        name
        id
      }
      walletStatus {
        walletStatusId
        name
        id
      }
      account {
        accountId
        name
        id
      }
      externalAccounts {
        id
        bankName
        displayName
        lastFour
        externalAccountId
        walletObjectId
        id
      }
    }
  }
`;

export const getUserWallets = () => makeGraphqlRequest(GET_USER_WALLETS_V2);

export const GET_WALLET_FUNDINGS_V2 = gqlv2`
  query GetWalletFundings($walletId: String!) {
    getWalletFundings(walletId: $walletId) {
      status
      amount
      createdAt
      externalAccount {
        externalAccountId
        walletObjectId
        bankName
        lastFour
        displayName
        id
      }
      fundingRequestType {
        name
      }
    }
  }
`;

export const getWalletFundings = (walletId: string) =>
  makeGraphqlRequest(GET_WALLET_FUNDINGS_V2, { walletId: walletId });

export const GET_USER_ACCOUNT_TOTAL_V2 = gqlv2`
query GetUserAccountTotal {
  getUserAccountTotal {
    committedAndActiveInvestmentsCounter
    committedAndActiveInvestmentsSum
    totalInvestmentsToDateCounter
    totalInvestedByAccount
    availableBalanceToInvest
  }
}
`;

export const getUserAccountTotal = () => makeGraphqlRequest(GET_USER_ACCOUNT_TOTAL_V2);
