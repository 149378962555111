import { Formik, FormikProps } from "formik";
import React, { createContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import COLORS from "../../common/colors";
import AccButton, { AccButtonColors } from "../../common/components/AccButton";
import IdentityForm from "./pages/IdentityForm";
import Logo from "../../assets/images/illustrations/access_logo_white.svg";
// import IncomeCheck from "./pages/IncomeCheck";
// import VerticalInterest from "./pages/VerticalInterest";
import { userRegistration } from "../../api/rest/user/index";
import "./registration.css";
import { standardErrorHandle, validatePhoneNumber } from "../../common/utilities";
import AccountType from "./pages/AccountType";
import { PublicFooter } from "../../common/components/PublicFooter";
import * as Yup from "yup";
import { RegistrationFormValue, UserRegistrationRequest } from "../../common/types";
import { AccountTypes } from "../../enums/RegistrationEnums";
import { toast } from "react-toastify";
import { useMediaQuery } from "@mui/material";
import { THEME_BREAKPOINTS } from "../../common/constants";
import AccCopyright from "../../common/components/AccCopyright";
import AccessLogo from "../../assets/images/illustrations/access_card_3d_dark.svg";

// @ts-ignore
export const RegistrationContext = createContext();

const Registration: React.FC = () => {
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [navigationAction, setNavigationAction] = useState<"back" | "forward" | null>(null);
  const [userType, setUserType] = useState("investor");
  // const [createUser] = useMutation(CREATE_USER);
  const [loading, setLoading] = useState<boolean>(false);
  const formRef = useRef<FormikProps<Partial<RegistrationFormValue>>>(null);
  const RegistrationSchema = Yup.object().shape({
    phone: Yup.string().length(14, "Invalid phone number"),
  });

  const nextPage = () => {
    setNavigationAction("forward");
    setCurrentPage(currentPage + 1);
  };
  const previousPage = () => {
    setNavigationAction("back");
    setCurrentPage(currentPage - 1);
  };

  const submitUserRegistration = async (values: any) => {
    let registrationBody: UserRegistrationRequest;
    try {
      if (!validatePhoneNumber(values.phone)) {
        toast("Phone number is not valid", {
          type: toast.TYPE.ERROR,
        });
        return;
      }
      setLoading(true);
      if (values.CRDNumber && values.firmCRDNumber) {
        registrationBody = {
          email: values.email,
          middleName: values.middleName ?? "",
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          address: values.address,
          userTypeName: values.userTypeName,
          CRDNumber: values.CRDNumber,
          firmCRDNumber: values.firmCRDNumber,
        };
        setUserType("advisor");
      } else {
        registrationBody = {
          email: values.email,
          firstName: values.firstName,
          middleName: values.middleName ?? "",
          lastName: values.lastName,
          phone: values.phone,
          address: values.address,
          userTypeName: values.userTypeName,
        };
      }
      await userRegistration(registrationBody)
        .then((res) => {
          if (res?.success === true) {
            setLoading(false);
            nextPage();
            return res;
          } else {
            setLoading(false);
          }
        });
    } catch (err: any) {
      setLoading(false);
      standardErrorHandle(err, "Error submitting registration");
    }
  };

  return (
    <>
      <div style={{ background: "transparent linear-gradient(360deg, #001227 0%, #05254B 100%) 0% 0% no-repeat padding-box", paddingTop: mobileLayout ? 50 : 150 }}>
        <RegistrationContext.Provider value={{ nextPage, previousPage, navigationAction, loading, setLoading }}>
          {/* <PublicNavbar /> */}
          {(() => {
            switch (currentPage) {
              case 1:
              // case 2:
              // case 3:
              default:
                return (
                  <div
                    className="column center"
                    style={{ justifyContent: "center" }}>
                    <img
                      className="center"
                      src={Logo}
                      style={{ margin: "auto" }}
                    />
                    <Typography
                      variant="h4"
                      align="center"
                      style={{
                        margin: "15px", color: COLORS.white, fontWeight: 600,
                      }}
                    >
                      Create an
                      {" "}
                      <span className="acc-text">access</span>
                      {" "}
                      investment profile
                    </Typography>
                    <Typography
                      align="center"
                      variant="subtitle2"
                      style={{
                        margin: "10px", color: COLORS.white, fontWeight: 300, maxWidth: 750,
                      }}
                    >
                      In order to participate in our alternative investment offerings,
                      you will be required to create an investment profile with us,
                      satisfy accreditation verification and create your <span className="acc-text acc-green">access</span><b>Wallet</b>&nbsp;
                      Account by taking the steps we will identify for you.
                    </Typography>
                    <Typography
                      align="center"
                      variant="subtitle2"
                      style={{
                        color: COLORS.white, fontWeight: 300, maxWidth: 750,
                      }}
                    >
                      Please proceed with the steps that follow.
                    </Typography>
                  </div>
                );
              case 2:
                return (
                  <div
                    className="width-90-mobile width-60"
                    style={{
                      margin: "auto", marginBottom: 50, height: "80%",
                    }}
                  >
                    <Typography
                      variant="h4"
                      align="center"
                      style={{
                        margin: "auto", marginBottom: 25, color: COLORS.white, fontWeight: 600,
                      }}
                    >
                      <span className="acc-text acc-green">access</span> the Future of Alternative Investments
                    </Typography>
                    <Typography
                      className="light"
                      variant="subtitle2"
                      align="center"
                      style={{ color: COLORS.white }}>
                      {`Participate in the fastest growing investment category 
                    and upgrade your investment strategy by adding previously 
                    inaccessible, institutional-quality investments in transportation, 
                    infrastructure, real estate and other sectors to your 
                  ${formRef.current?.values.accountType === AccountTypes.INDIVIDUAL ? "portfolio" : "client's portfolio"}`}
                    </Typography>

                  </div>
                );
              case 3:
                return (
                  <div
                    style={{
                      display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: 100,
                    }}
                    className="m-top-50"
                  >
                    <img
                      src={AccessLogo}
                      style={{ height: 225, marginBlock: 25 }}
                    />
                    <Typography
                      className="width-40 width-100-mobile"
                      variant="h4"
                      align="center"
                      style={{ margin: "25px auto", color: COLORS.white, fontWeight: 600 }}>
                      Congratulations!
                      You have taken the first steps to creating your
                      {" "}
                      <span className="acc-text acc-green">access</span>
                      {" "}
                      {userType} profile.
                    </Typography>
                    <Typography
                      className="width-40 width-100-mobile"
                      align="center"
                      style={{
                        margin: "25px auto", color: COLORS.white, fontWeight: 300, lineHeight: 1.2, fontSize: 16, marginBottom: 25,
                      }}
                    >
                      After you have signed in using your email address and your temporary password,
                      you will be able to create your own password and complete the process of opening your access investment profile.

                    </Typography>
                    <Link to="/">
                      <AccButton
                        className="m-top-30"
                        color={AccButtonColors.GREEN}
                        label="Sign In"
                      />
                    </Link>
                  </div>
                );
            }
          })()}
          <Formik
            validationSchema={RegistrationSchema}
            initialValues={{
              accountType: "",
              verticalInterests: [],
              incomeOver200k: null,
              isMarried: null,
              jointIncomeOver300k: null,
              netWorthOver1m: null,
              investmentEntityOver5m: null,
              firstName: "",
              lastName: "",
              address: null,
              CRDNumber: "",
              firmCRDNumber: "",
              birthday: "",
              phone: "",
            }}
            onSubmit={(values) => {
              submitUserRegistration(values);
            }}
            innerRef={formRef}
          >
            <div id="registration-container">
              {(() => {
                switch (currentPage) {
                  case 1:
                    return <AccountType />;
                  // return <UMBQuestionSets />;

                  // case 2:
                  //   return <VerticalInterest />;
                  // case 3:
                  //   return <IncomeCheck />;
                  case 2:
                    return <IdentityForm />;
                  default:
                  // return <AccountType />
                }
              })()}
              <AccCopyright style={{ marginTop: 100 }} />
            </div>
          </Formik>
          <PublicFooter />
        </RegistrationContext.Provider>
      </div>
    </>
  );
};

export default Registration;
