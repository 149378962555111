import { Divider, FormControlLabel, Radio, RadioGroup, styled, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import COLORS from "../../common/colors";
import LabeledFigure from "../../common/components/LabeledFigure";
import PageTitle from "../../common/components/PageTitle";
import { Checkbox, MenuItem, useMediaQuery } from "@mui/material";
import { useCashFlows, useUserPortfolioInvestments, useWalletStatuses } from "../../api/hooks/graphqlHooks";
import { InvestmentStatus, WalletStatus } from "../../enums";
import { formatCurrency, formatPercentage, isNil, isNotNil, isPeriodOver24Months } from "../../common/utilities";
import moment from "moment";
import AccInput from "../../common/components/AccInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingAnimation from "../../common/components/LoadingAnimation";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { THEME_BREAKPOINTS } from "../../common/constants";
import { faArrowAltRight } from "@fortawesome/pro-solid-svg-icons";
import { CashFlow, Investment } from "../../common/types";
import CashFlowSimulationBar from "../../common/components/CashFlowSimulationBar";

interface Props {

}

const Performance: React.FC<Props> = () => {

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const [selectedWallet, setSelectedWallet] = useState<number | null>(null);
  const [investmentsToShow, setInvestmentsToShow] = useState<Array<Investment> | null>([]);
  const [allInvestmentsFilterIsOn, setAllInvestmentsFilterIsOn] = useState<boolean>(true);
  const [allActiveFilterIsOn, setAllActiveFilterIsOn] = useState<boolean>(false);
  const [allCompletedFilterIsOn, setAllCompletedFilterIsOn] = useState<boolean>(false);
  const [selectedInvestment, setSelectedInvestment] = useState<Partial<Investment> | null>(null);
  const [displayTargetedCashFlow, setDisplayTargetedCashFlow] = useState<boolean>(false);
  const [cashFlowToShow, setCashFlowToShow] = useState<CashFlow>();
  const [investmentTablePageSize, setInvestmentTablePageSize] = useState<number>(25);

  const {
    data: getUserPortfolioInvestmentsData,
    isLoading: userPortfolioInvestmentsLoading,
    isFetching: userPortfolioInvestmentsFetching,
  } = useUserPortfolioInvestments({
    refetchOnMount: true,
  });

  const {
    data: getCashFlows,
    isLoading: cashFlowsLoading,
    isFetching: cashFlowsFetching,
  } = useCashFlows({
    refetchOnMount: true,
  });

  const {
    data: getWalletStatusesData,
  } = useWalletStatuses({
    refetchOnMount: true,
  });

  const closedWalletData = getWalletStatusesData?.getWalletStatuses.find((item: any) => item.name === WalletStatus.CLOSED);


  useEffect(() => {
    filterInvestmentsByStatus();
  }, [selectedWallet]);


  const amountInvested = investmentsToShow?.map((item: any) => item.amount);
  //We use the slice method to show only 10 investments on the chart
  const amountSliced = amountInvested?.slice(0, 10);

  const investedAssets = investmentsToShow?.map(item => item.asset.name);
  //We use the slice method to show only the first 10 investments on the chart
  const assetsSliced = investedAssets?.slice(0, 10);

  const totalInvested = isNotNil(amountInvested) ? amountInvested?.reduce((itemA, itemB) => itemA + itemB) : null;

  // did not added to utility since it will most likely be used only here
  const findCashFlowToShow = (assetObjectId: string) => getCashFlows?.getCashFlows.find((cashFlow) => cashFlow.assetObjectId === assetObjectId);

  const renderArrowCell = () => ({
    field: "",
    width: 0,
    renderCell: () => {
      return <>
        <FontAwesomeIcon
          icon={faArrowAltRight}
          size="2x"
          className="acc-green m-left-10"
          style={{ fontSize: 25 }} />
      </>;
    },
  });

  const investmentTableColumns: GridColDef[] = [
    renderArrowCell(),
    { field: "name", headerName: "INVESTMENT", width: 150 },
    { field: "amount", headerName: "INVESTED", width: 120 },
    { field: "nav", headerName: "NAV*", width: 100 },
    { field: "portfolioAllocation", headerName: "PORTFOLIO ALLOCATION", width: 100 },
    { field: "distributions", headerName: "DISTRIBUTONS", width: 80 },
    { field: "targetedIRR", headerName: "TARGETED IRR", width: 80 },
    { field: "targetedMOIC", headerName: "TARGETED MoIC", width: 80 },
    { field: "targetedAverageCashYield", headerName: "TARGETED AVERAGE CASH YIELD", width: 120 },
    { field: "targetedInvestmentPeriod", headerName: "Target Invest. Period", width: 100 },
  ];

  const investmentTableRows = investmentsToShow?.map(row =>
  ({
    ...row,
    name: row.asset.name,
    amount: formatCurrency(row.amount, 2),
    nav: formatCurrency(0, 2),
    portfolioAllocation: formatCurrency(0, 2),
    distributions: formatCurrency(0, 2),
    targetedIRR: formatPercentage(row.asset.targetedIRR),
    targetedAverageCashYield: formatPercentage(row.asset.targetedAverageCashYield),
    targetedMOIC: `${row.asset.targetedMOIC}x`,
    targetedMaturity: moment(row.asset.targetedMaturity).format("YYYY-MM-DD"),
    targetedInvestmentPeriod: isPeriodOver24Months(row.asset.targetedInvestmentPeriod),
    id: row.investmentId,
  }),
  );

  const filterInvestmentsByStatus = (investmentStatus?: string) => {
    setAllInvestmentsFilterIsOn(false);
    setAllActiveFilterIsOn(false);
    setAllCompletedFilterIsOn(false);
    let filteredInvestments: Array<Investment> = [];
    getUserPortfolioInvestmentsData?.getUserPortfolioInvestments?.map((item) => {
      if (selectedWallet === item.walletId) {
        //If it is not null InvestmentStatus, shows the investment we selected, if it is null, it shows all investments.
        if (isNotNil(investmentStatus)) {
          filteredInvestments = item.investments.filter(item => item.status === investmentStatus);
        } else {
          filteredInvestments = item.investments;
          setAllInvestmentsFilterIsOn(true);
        }
        //If the selected wallet is null and if the InvestmentStatus is not null, "show all wallets' filtered investments by status".
      } else if (isNil(selectedWallet)) {
        if (isNotNil(investmentStatus)) {
          item.investments.map(item => {
            if (item.status === investmentStatus) {
              filteredInvestments.push(item);
            }
          });
          //If not, show all investments
        } else {
          item.investments.map(item => filteredInvestments.push(item));
          setAllInvestmentsFilterIsOn(true);
        }
      }
      if (investmentStatus === InvestmentStatus.COMMITTED) {
        setAllActiveFilterIsOn(true);
      } else if (investmentStatus === InvestmentStatus.COMPLETED) {
        setAllCompletedFilterIsOn(true);
      }
    });
    setInvestmentsToShow(filteredInvestments);
  };


  const CustomNoRowsOverlay = () => (
    <span style={{ display: "flex", alignSelf: "center", color: COLORS.white, position: "absolute", marginTop: "160px" }}>
      You don&apos;t have any investments yet, please visit the Invest page to get started.
    </span>
  );

  if (userPortfolioInvestmentsLoading || userPortfolioInvestmentsFetching
    || cashFlowsLoading || cashFlowsFetching) {

    return <LoadingAnimation />;
  }

  return (
    <div>
      <PageTitle
        title={<b>Performance</b>}
        subtitle="Measure your investments performance"
      />
      <br />
      <br />
      <div
        className="row stretch column-mobile"
        style={{ display: "flex", justifyContent: "left" }}>
        <StyledSelect
          className="width-100-mobile m-bottom-30"
          style={{ width: "30%", display: "flex", alignSelf: "start" }}
          value={selectedWallet}
          name=""
          label="Select Investment Account"
          select
          onChange={e => {
            setSelectedWallet(e.target.value);
            filterInvestmentsByStatus();
          }}>
          <MenuItem>
            All Wallets
          </MenuItem>
          {getUserPortfolioInvestmentsData?.getUserPortfolioInvestments.map(item =>
            <MenuItem
              key={item.walletObjectId}
              value={item.walletId}>
              {closedWalletData?.id.toString() === item.walletStatusObjectId ? `${item.walletName} (Closed)` : item.walletName}
            </MenuItem>,
          )}
        </StyledSelect>
        <div className={mobileLayout ? "width-100-mobile m-bottom-30-mobile" : "width-100-mobile row"}>
          <Typography
            variant="h5"
            className="acc-blue bold"
            style={{ display: "flex", alignSelf: "center", position: "relative", left: mobileLayout ? "" : "30px", fontSize: "30px" }}>Filter Investments</Typography>
          <RadioGroup
            row
            style={{ position: mobileLayout ? "initial" : "relative", left: "60px" }}
          >
            <FormControlLabel
              value="all"
              onClick={() => filterInvestmentsByStatus()}
              control={<Radio />}
              label="All"
              checked={allInvestmentsFilterIsOn}
            />
            <FormControlLabel
              value="active"
              onClick={() => filterInvestmentsByStatus(InvestmentStatus.COMMITTED)}
              control={<Radio />}
              label="Active"
              checked={allActiveFilterIsOn}
            />
            <FormControlLabel
              value="completed"
              onClick={() => filterInvestmentsByStatus(InvestmentStatus.COMPLETED)}
              control={<Radio />}
              label="Completed"
              checked={allCompletedFilterIsOn}
            />
          </RadioGroup>
        </div>
      </div>
      <div
        className="row stretch column-mobile"
        style={{ height: "800px", paddingBottom: 0, marginBottom: selectedInvestment ? "200px" : "0" }}>
        <div
          className="column width-30 width-100-mobile"
          style={{ background: COLORS.darkBlueGradient, borderRadius: 16, padding: 25 }}>
          <Typography
            variant="h5"
            className="acc-white bold"
            style={{ display: "flex", alignSelf: "flex-start" }}>Portfolio allocation</Typography>
          <br />
          <div
            className="m-bottom-30 column center width-30"
            style={{ width: 350 }}>
            <Pie
              width={350}
              height={350}
              options={{
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                  display: true,
                  position: "bottom",
                  align: "center",
                  labels: {
                    boxWidth: 10,
                    boxHeight: 15,
                    usePointStyle: true,
                    padding: 10,
                  },
                  animation: {
                    animateScale: true,
                    animateRotate: true,
                  },
                },
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem: any, data: any) {
                      let allData = data.datasets[tooltipItem.datasetIndex].data;
                      let tooltipLabel = data.labels[tooltipItem.index];
                      let tooltipData = allData[tooltipItem.index];
                      return tooltipLabel + ": " + formatCurrency(tooltipData, 2);
                    },
                  },
                },
              }}
              data={{
                labels: assetsSliced ?? [],
                datasets: [{
                  data: amountSliced ?? [],
                  backgroundColor: [COLORS.yellow, COLORS.red, COLORS.ultradarkGreen, COLORS.green,
                  COLORS.darkGray, COLORS.lightGray, COLORS.lightGreen, COLORS.lightTeal, COLORS.grayBlue, COLORS.lightBlue],
                  borderWidth: 2,
                  borderColor: COLORS.darkBlue,
                }],
              }}
            />
          </div>
          <Divider
            orientation="horizontal"
            style={{ background: COLORS.lightGray, width: "100%" }} />
          <div className="column start width-100 column-mobile m-top-30">
            <LabeledFigure
              className="acc-green"
              value={Number(investmentsToShow?.length)}
              valueStyles={{ fontSize: 30 }}
              description="Investments"
              alignment={mobileLayout ? "center" : "start"}
              containerClassName="m-bottom-15"
            />
            <LabeledFigure
              className="acc-green"
              value={formatCurrency(totalInvested!, 2)}
              valueStyles={{ fontSize: 30 }}
              description="Total Invested"
              alignment={mobileLayout ? "center" : "start"}
              containerClassName="m-bottom-15"
            />
            <LabeledFigure
              className="acc-green"
              value="$0.00"
              valueStyles={{ fontSize: 30 }}
              description="Total Distributions"
              alignment={mobileLayout ? "center" : "start"}
              containerClassName="m-bottom-15"
            />
            <LabeledFigure 
              className="acc-green"
              value="$0.00"
              valueStyles={{ fontSize: 30 }}
              description="Total NAV"
              alignment={mobileLayout ? "center" : "start"}
              containerClassName="m-bottom-15"
            />
          </div>
        </div>
        <div 
          className="width-70 width-100-mobile m-top-30-mobile"
          style={{position: "relative", left: mobileLayout ? "" : "30px"}}>
          <div style={{ background: COLORS.darkBlueGradient, borderRadius: 16, padding: 25}}>
            <Typography
              style={{ marginBottom: 15 }}
              variant="h4"
              className="acc-white bold column-mobile">Investments {<span
                className="acc-lightgray light"
                style={{ fontSize: 20 }}>
                Select an investment from the list below to view cash flow</span>}
            </Typography>
            <div style={{ ...styles.tableContainer, height: `${investmentTablePageSize}rem` }}>
              <DataGrid
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
                rows={investmentTableRows || []}
                columns={investmentTableColumns}
                pageSize={investmentTablePageSize}
                onPageSizeChange={(newPageSize) =>
                  setInvestmentTablePageSize(newPageSize)
                }
                rowsPerPageOptions={[25, 50, 100]}
                componentsProps={{
                  panel: DataGridStyles.panel,
                }}
                onCellClick={(e) => {
                  setSelectedInvestment({
                    id: e.row.id,
                    investmentId: e.row.investmentId,
                    name: e.row.name,
                    amount: e.row.amount,
                    status: e.row.status,
                    type: e.row.type,
                    asset: e.row.asset,
                    createdAt: e.row.createdAt,
                    committedDate: e.row.commitedDate,
                  });
                  setCashFlowToShow(findCashFlowToShow(e.row.asset.id));
                }}
                style={{ border: "none" }}
                sx={DataGridStyles.separator}
              />
            </div>
          </div>
          {selectedInvestment ?
            <div
              className="m-top-50 column end stretch"
              style={{ ...styles.innerContainer, padding: mobileLayout ? "50px 25px" : "15px 50px"}}>
              <div
                style={{
                  display: mobileLayout ? "" : "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  marginBottom: mobileLayout ? "40px" : "",
                }}
              >
                <Typography
                  className="acc-white bold"
                  style={{ display: mobileLayout ? "" : "flex", marginBottom: 10, fontSize: 22 }}
                >
                  {/* We need to use the slice method to not have a line break if the name is long */}
                  <> Cash Flow for<span className="acc-green bold">&nbsp;{selectedInvestment?.name?.slice(0, 20)}&nbsp;</span>Investment</>
                </Typography>
                <div
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Checkbox
                    checked={displayTargetedCashFlow}
                    onChange={(e: any) => {
                      setDisplayTargetedCashFlow(e.target.checked);
                    }}
                    sx={styles.checkbox}
                  />
                  <Typography
                    className="acc-white bold"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Display Targeted Cash Flow
                  </Typography>
                </div>
              </div>
              <CashFlowSimulationBar
                targetedFundingDate={selectedInvestment?.asset?.targetedFundingDate ? selectedInvestment?.asset?.targetedFundingDate : ""}
                cashFlowModelRows={cashFlowToShow?.cashFlowInputArray ?? []}
                totalInvestmentAmount={selectedInvestment ? selectedInvestment.asset?.totalInvestmentAmount : ""}
                investmentAmount={Number(selectedInvestment?.amount?.toString().replace("$", "").replace(",", ""))}
                displayTargetedCashFlow={displayTargetedCashFlow}
                isPerformanceCashFlow={true}
              />
            </div> : <></>
          }
        </div>
      </div>
    </div>
  );
};

const DataGridStyles = {
  panel: {
    sx: {
      "& .MuiTypography-root": {
        color: COLORS.green,
      },
      "& label.Mui-focused": {
        color: COLORS.darkBlue,
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: COLORS.green,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        color: COLORS.green,
      },
      "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
        backgroundColor: COLORS.lightGreen,
      },
      "& .MuiButton-root": {
        color: COLORS.darkBlue,
      },
    },
  },
  separator: {
    "& chartjs-render-monitor": {
      height: 0,
    },
    "& .svg-inline--fa": {
      display: "none",
    },
    "& .MuiButtonBase-root": {
      color: COLORS.green,
    },
    "& .MuiTablePagination-displayedRows": {
      color: COLORS.lightGray,
    },
    "& .MuiSvgIcon-root": {
      color: COLORS.green,
    },
    "& .MuiTablePagination-select": {
      color: COLORS.lightGray,
    },
    "& .MuiDataGrid-row": {
      color: COLORS.lightGray,
    },
    "& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: COLORS.white,
    },
    "& .Mui-selected": {
      color: COLORS.green,
    },
    "& .Mui-selected .svg-inline--fa": {
      display: "block",
    },
    "& .MuiDataGrid-row:hover": {
      color: COLORS.green,
    },
    "& .MuiDataGrid-columnSeparator": {
      visibility: "hidden",
    },
  },
  toolBar: {
    "& .MuiButton-root": {
      color: COLORS.green,
      width: "95px",
    },
  },
};

const styles = {
  backgroundColor: [
    COLORS.lightBlue,
    COLORS.darkBlue,
    COLORS.green,
    COLORS.mediumBlue,
    COLORS.lightTeal,
    COLORS.blue,
    COLORS.ultradarkGreen,
    COLORS.ultralightGreen,
    COLORS.lightGreen,
  ],
  tableContainer: {
    //height: "40vh",
    width: "100%",
  },
  innerContainer: {
    borderRadius: "16px",
    height: "fit-content",
    alignItems: "center",
    width: "100%",
    background: COLORS.layeredImageGradient,
    display: "flex",
  },
  checkbox: {
    color: COLORS.yellow,
    "&.Mui-checked": {
      color: COLORS.yellow,
    },
    padding: "0px 10px 0px 0px",
  },
};

const StyledSelect = styled(AccInput)({
  "& label.Mui-focused": {
    color: COLORS.darkBlue,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.green,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      padding: 0,
      borderRadius: 16,
      boxShadow: "0px 3px 6px #00000029",
    },
    "&.Mui-focused fieldset": {
      borderColor: COLORS.green,
    },
  },
  "& .MuiSelect-outlined": {
    borderRadius: 16,
  },
});
export default Performance;
