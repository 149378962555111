import React, {
  useEffect, useMemo, useReducer, useState,
} from "react";
import { ToastContainer } from "react-toastify";
import AppRouter from "./AppRouter";
import {
  NotificationCenterContext, SessionContext, AuthContext, LoadingContext, NavPaneContext,
} from "./context";
import { ThemeProvider, createTheme } from "@mui/material";
import COLORS from "./common/colors";
import { THEME_BREAKPOINTS } from "../../client/src/common/constants";
import useMediaQuery from "@mui/material/useMediaQuery";
import { withErrorBoundary } from "./common/components/ErrorBoundary";

export const AuthActions = {
  RESTORE_TOKEN: "RESTORE_TOKEN",
  SIGN_IN: "SIGN_IN",
  MFA_VERIFIED: "MFA_VERIFIED",
  SIGN_OUT: "SIGN_OUT",
  REFRESH_TOKEN: "REFRESH_TOKEN",
};

const App = withErrorBoundary({}, () => {
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  const [notificationCenterVisible, setNotificationCenterVisible] = useState(false);
  const [open, setOpen] = useState(mobileLayout ? false : true);
  const [globalAppLoading, setGlobalAppLoading] = useState(false);


  const [session, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case AuthActions.RESTORE_TOKEN:
          return {
            ...prevState,
            token: action.token,
            isLoading: false,
            user: action.user,
            account: action.account,
          };
        case AuthActions.REFRESH_TOKEN:
          return {
            ...prevState,
            token: action.token,
            isLoading: false,
            user: action.user,
            account: action.account,
          };
        case AuthActions.SIGN_IN:
          localStorage.setItem("sessionExpired", "false");
          return {
            ...prevState,
            isSignout: false,
            token: action.token,
            user: action.user,
            account: action.account,
          };
        case AuthActions.MFA_VERIFIED:
          return {
            ...prevState,
            mfaSessionId: action.mfaSessionId,
            mfaExpiresAt: action.mfaExpiresAt,
          };
        case AuthActions.SIGN_OUT:
          return {
            ...prevState,
            isSignout: true,
            token: null,
            user: null,
            mfaSessionId: null,
          };
        default: return {};
      }
    },
    {
      isLoading: true,
      isSignout: false,
      token: null,
      user: null,
      mfaSessionId: null,
    },
  );

  useEffect(() => {
    const checkToken = async () => {
      try {
        let user;
        let account;
        let userToken;
        user = JSON.parse(localStorage.getItem("user"));
        account = JSON.parse(localStorage.getItem("account"));
        userToken = localStorage.getItem("token");
        dispatch({
          type: "RESTORE_TOKEN",
          token: userToken,
          user,
          account,
        });
      } catch (err) {
        console.log("**Token refetch error", err);
        throw new Error(err);
      }
    };

    checkToken();
  }, []);

  const notificationMenuProps = useMemo(() => ({ setNotificationCenterVisible, notificationCenterVisible }), []);

  const theme = createTheme({
    components: {
      // Name of the component
      MuiDrawer: {
        styleOverrides: {
          // Name of the slot
          paper: {
            // Some CSS
            background: COLORS.darkBlueGradient,
          },
        },
      },
    },
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 600,
        laptop: 1200,
        desktop: 1536,
      },
    },
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 600,
        laptop: 1200,
        desktop: 1536,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <NotificationCenterContext.Provider value={notificationMenuProps}>
        <AuthContext.Provider value={dispatch}>
          <SessionContext.Provider value={session}>
            <LoadingContext.Provider
              value={{ globalAppLoading, setGlobalAppLoading }}
            >
              <NavPaneContext.Provider value={{ open, setOpen }}>
                <AppRouter />
              </NavPaneContext.Provider>
            </LoadingContext.Provider>
          </SessionContext.Provider>
        </AuthContext.Provider>
        <ToastContainer />
      </NotificationCenterContext.Provider>
    </ThemeProvider>
  );
});

export default App;
