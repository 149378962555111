import { Typography } from "@material-ui/core";
import React from "react";
import COLORS from "../../../../common/colors";

interface Props {
  title: string | JSX.Element;
  description: string;
  date: string | JSX.Element;
  containerStyles?: React.CSSProperties;
  image: string;
}

const ActivityNotification: React.FC<Props> = ({
  title, description, date, image,
}) => {
  const [whiteBox, setWhiteBox] = React.useState(true);

  const changeBoxColor = () => {
    setWhiteBox(false);
  };

  return (
    <div
      className="row m-bottom-30"
      style={
        whiteBox ? styles.whiteBox : styles.blueBox
      }
      onClick={changeBoxColor}
    >
      <img
        alt="Pharrel"
        src={image}
        style={{
          width: 100, height: "100%", borderRadius: "16px 0px 0px 16px", objectFit: "cover",
        }}
      />
      <div
        className="column start"
        style={{ width: "100%", padding: "20px" }}>
        <div
          className="row stretch"
          style={{ width: "100%" }}>
          <div
            className={`${whiteBox ? "acc-darkblue" : "acc-white"} font-size-mobile-5`}
            style={{ margin: 0 }}>
            {title}
          </div>
          <Typography
            variant="subtitle2"
            className={`acc-lightgray ultralight${whiteBox && "acc-darkblue"}`}>
            {date}
          </Typography>
        </div>
        <br />
        <p
          className={whiteBox ? "acc-darkgray light font-size-mobile-5" : "acc-white light font-size-mobile-5"}
          style={{ margin: 0 }}>
          {description}
        </p>
      </div>
    </div>
  );
};

const styles = {
  whiteBox: {
    width: "100%",
    height: "fitContent",
    borderRadius: "16px",
    background: COLORS.white,
    border: "1px solid #1bd0ae",
    fontColor: "red",
  },
  blueBox: {
    width: "100%",
    height: "fitContent",
    borderRadius: "16px",
    background: COLORS.darkBlueGradient,
  },
};

export default ActivityNotification;
