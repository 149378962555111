import { toast } from "react-toastify";
import restClient from "../restClient";

const sendEmail = async (
  description: string,
  subject: string,
  requestType: string,
) => {
  try {
    const emailResponse = await restClient.post("/customer-support/send-email", {
      description: description,
      subject: subject,
      requestType: requestType,
    }, {
    });
    if (emailResponse.data?.success) {
      toast("Email was sent successfully!", {
        type: toast.TYPE.SUCCESS,
      });
      return emailResponse.data?.data;
    } else if (emailResponse?.data?.success === false) {
      toast(emailResponse.data.msg || "There was a problem sending email.", {
        type: toast.TYPE.ERROR,
      });
    }
  } catch (err: any) {
    console.log(err);
    toast(err.message || err.msg || err.toString() || "Error sending email!", {
      type: toast.TYPE.ERROR,
    });
  }
};

export {
  sendEmail,
};