import { faPen, faPencil, faTimes, faUserAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Divider, IconButton, InputAdornment, Modal, Typography } from "@material-ui/core";
import { Field, Form, Formik, FormikValues } from "formik";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import AccInput, { AccMaskedInputTypes } from "../../common/components/AccInput";
import { SessionContext, AuthContext } from "../../context";
import { mfaRequest, userUpdate } from "../../api/rest/user";
import { toast } from "react-toastify";
import AccButton, { AccButtonColors } from "../../common/components/AccButton";
import COLORS from "../../common/colors";
import { QueryKeys, useUserForAdmin } from "../../api/hooks/graphqlHooks";
import { isNil, isNotNil } from "../../common/utilities";
import { UserTypes } from "../../common/types";
import { Box } from "@mui/system";
import ResetEmail from "./ResetEmail";
import ResetPassword from "./ResetPassword";
import ResetPhoneNumber from "./ResetPhoneNumber";
import { MFARequestTypes } from "../../enums";
import { useQueryClient } from "react-query";
import { AuthActions } from "../../App";
import PageWrapper from "../../common/components/PageWrapper";

interface EditIconProps {
  onClick?: (arg: any) => void;
}

const EditIcon: React.FC<EditIconProps> = ({ onClick }) => {

  return (
    <InputAdornment
      position="end">
      <IconButton
        onClick={onClick}
        size="small"
        disableRipple
        disableFocusRipple
        edge="end">
        <FontAwesomeIcon
          icon={faPen}
          color={COLORS.green} />
      </IconButton>
    </InputAdornment>
  );
};

const Profile: React.FC = () => {

  const dispatch = useContext(AuthContext);
  const queryClient = useQueryClient();

  const [avatarImageError, setAvatarImageError] = useState(false);
  const [avatarImagePreview, setAvatarImagePreview] = useState<null | string | ArrayBuffer>(null);
  const [uploadedAvatarImage, setUploadedAvatarImage] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);
  const [showResetPhoneNumber, setShowResetPhoneNumber] = useState<boolean>(false);
  const [showResetEmail, setShowResetEmail] = useState<boolean>(false);
  const [openMessageModal, setOpenMessageModal] = useState<boolean>(true);
  const { user: sessionUser } = useContext(SessionContext);

  const userIsAdmin = sessionUser?.userType?.name === UserTypes.ADMIN || sessionUser?.userType?.name === UserTypes.ASSET_MANAGER;

  const {
    data: getUserForAdminData,
    isLoading: getUserForAdminLoading,
    isFetching: getUserForAdminFetching,
    isError: getUserForAdminError,
  } = useUserForAdmin(sessionUser?.userId, {
    enabled: isNotNil(sessionUser?.userId),
    refetchOnMount: true,
  });

  useEffect(() => {
    if (isNotNil(getUserForAdminData?.getUser?.avatarImageLocation)) {
      setAvatarImagePreview(getUserForAdminData?.getUser?.avatarImageLocation ?? "");
    }
    if (isNil(getUserForAdminData?.getUser?.avatarImageLocation)) {
      setAvatarImagePreview("");
    }
  }, [getUserForAdminData]);

  const initialFormFieldValues = () => ({
    firstName: getUserForAdminData?.getUser.firstName,
    middleName: getUserForAdminData?.getUser.middleName,
    lastName: getUserForAdminData?.getUser.lastName,
    email: getUserForAdminData?.getUser.email,
    linkedInUrl: getUserForAdminData?.getUser.linkedInUrl,
    jobTitle: getUserForAdminData?.getUser.jobTitle,
    avatarImageLocation: getUserForAdminData?.getUser.avatarImageLocation,
    password: getUserForAdminData?.getUser.password,
    phone: getUserForAdminData?.getUser.phone,
  });

  const submitForm = async (values: FormikValues) => {
    try {
      const formData = new FormData();
      formData.append("userId", sessionUser?.userId);
      formData.append("firstName", values.firstName);
      formData.append("middleName", values.middleName);
      formData.append("lastName", values.lastName);
      formData.append("email", values.email);
      formData.append("linkedInUrl", values.linkedInUrl);
      formData.append("jobTitle", values.jobTitle);
      formData.append("file", uploadedAvatarImage);
      const userUpdateResponse = await userUpdate(formData);
      if (isNotNil(userUpdateResponse)) {
        queryClient.refetchQueries(QueryKeys.USER);
        localStorage.setItem("user", JSON.stringify(userUpdateResponse?.data?.values?.user));
        localStorage.setItem("account", JSON.stringify(userUpdateResponse?.data?.values?.account));
        localStorage.setItem("token", userUpdateResponse?.data?.values?.token);
        localStorage.setItem("refreshToken", userUpdateResponse?.data?.values?.refreshToken);
        dispatch({
          type: AuthActions.REFRESH_TOKEN,
          token: userUpdateResponse?.data.values.token,
          user: userUpdateResponse?.data.values.user,
          account: userUpdateResponse?.data.values.account,
        });
        // temp time out to refresh user profile
        setTimeout(() => window.location.reload(), 3000);
      }
    } catch (err: any) {
      setLoading(false);
      toast(err.message || err.msg || err.toString() || "There was an error updating user.", {
        type: toast.TYPE.ERROR,
      });
    }
    queryClient.refetchQueries(QueryKeys.USER);
  };

  const handlePictureUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    setAvatarImageError(false);
    const uploadEvent = e.target as HTMLInputElement;
    if (uploadEvent.files?.length) {
      const uploadedFile = uploadEvent.files[0];
      const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];
      if (uploadedFile && ALLOWED_TYPES.includes(uploadedFile.type)) {
        const reader = new FileReader();
        reader.onloadend = async () => {
          setUploadedAvatarImage(uploadedFile);
          setAvatarImagePreview(reader.result);
        };
        reader.readAsDataURL(uploadedFile);
      } else {
        setAvatarImageError(true);
      }
    }
  };

  if (avatarImageError) {
    toast("File not supported!", {
      type: toast.TYPE.WARNING,
    });
  }

  return (
    <PageWrapper
      title="My Profile"
      showLoader={loading || getUserForAdminLoading || getUserForAdminFetching}
      showError={getUserForAdminError}
      errorMessage="Cannot retrieve user"
    >
      <div>
      <Formik
        initialValues={initialFormFieldValues()}
        enableReinitialize
        onSubmit={submitForm}
      >
        {() => (
          <Form>
            <div className="width-100 m-top-50 m-bottom-50 row center stretch column-mobile">
              <div>
                <Avatar
                  style={{
                    width: "175px",
                    height: "175px",
                    background: avatarImagePreview
                      ? `url("${avatarImagePreview}") no-repeat center/cover`
                      : "",
                  }}
                  variant="circular">
                  {avatarImagePreview ? null :
                    <FontAwesomeIcon
                      size="3x"
                      icon={faUserAlt}
                      className="acc-white" />}
                  <input
                    type="file"
                    className="display-none"
                    accept="image/*"
                    name="avatarImageLocation"
                    id="fileUpload"
                    formEncType="multipart/form-data"
                    onChange={handlePictureUpload}
                  />
                </Avatar>
                {avatarImagePreview ?
                  <FontAwesomeIcon
                    size="2x"
                    color={COLORS.darkBlue}
                    icon={faTimes}
                    style={{ height: "22px", ...styles.editIcon as React.CSSProperties }}
                    onClick={() => setAvatarImagePreview(null)}
                  /> :
                  <label
                    htmlFor="fileUpload"
                    style={styles.imageInputLabel}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      color={COLORS.darkBlue}
                      icon={faPencil}
                      style={styles.editIcon as React.CSSProperties}
                    />
                  </label>}
              </div>
              <div className="width-80 width-100-mobile m-top-30-mobile">
                <Typography
                  variant="h4"
                  className="acc-darkblue bold font-size-mobile-1 text-align-center"
                >
                  {getUserForAdminData?.getUser.firstName}&nbsp;
                  {getUserForAdminData?.getUser.middleName ? `${getUserForAdminData?.getUser.middleName} ` : null}
                  {getUserForAdminData?.getUser.lastName}
                </Typography>
                <br />
                <Typography
                  variant="h6"
                  className="light text-align-center"
                >
                  {getUserForAdminData?.getUser.email} {userIsAdmin ? `- ${getUserForAdminData?.getUser.userType.name}` : null}
                </Typography>
                {userIsAdmin && <Typography
                  variant="subtitle1"
                  className="light text-align-center">
                  {getUserForAdminData?.getUser.jobTitle}
                </Typography>}
              </div>
            </div>
            <Divider />
            <div className="width-100 m-top-50 row column-mobile">
              <div
                className="width-50 width-100-mobile m-right-50 m-right-0-mobile">
                <Typography
                  variant="h5"
                  className="acc-darkblue bold">
                  Personal Information
                </Typography>
                <br />
                <Field
                  name="firstName"
                  type="text"
                  label="First Name"
                  as={AccInput}
                  required
                />
                <Field
                  name="middleName"
                  type="text"
                  label="Middle Name"
                  as={AccInput}
                />
                <Field
                  name="lastName"
                  type="text"
                  label="Last Name"
                  as={AccInput}
                  required
                />
                {userIsAdmin &&
                  <>
                    <Field
                      name="email"
                      type="text"
                      label="Email"
                      as={AccInput}
                      required
                    />
                    <Field
                      name="jobTitle"
                      type="text"
                      label="Title"
                      as={AccInput}
                    />
                    <Field
                      name="linkedInUrl"
                      type="text"
                      label="LinkedIn Url"
                      as={AccInput}
                    />
                  </>}
              </div>
              {!userIsAdmin && <div className="width-50 width-100-mobile m-top-30-mobile">
                <Typography
                  variant="h5"
                  className="acc-darkblue bold">
                  Security
                </Typography>
                <br />
                <>
                  <Field
                    name="email"
                    type="text"
                    label="Email"
                    as={AccInput}
                    InputProps={{
                      readOnly: true,
                      endAdornment: <EditIcon
                        onClick={() => {
                          setShowResetEmail(true);
                          setOpenMessageModal(true);
                        }
                        }
                      />,
                    }}
                  />
                  {openMessageModal ?
                    <Modal
                      open={showResetEmail}
                      onClose={() => setShowResetEmail(false)}
                    >
                      <Box
                        sx={styles.modal}
                        className="width-90-mobile">
                        <ResetEmail
                          showResetEmail={showResetEmail}
                          openMessageModal={openMessageModal}
                          setOpenMessageModal={setOpenMessageModal}
                        />
                      </Box>
                    </Modal>
                    : null}
                  <Field
                    name="password"
                    type="password"
                    label="Password"
                    value="*********"
                    as={AccInput}
                    InputProps={{
                      readOnly: true,
                      endAdornment: <EditIcon onClick={() => setShowResetPassword(true)} />,
                    }}
                  />
                  <Modal
                    open={showResetPassword}
                    onClose={() => setShowResetPassword(false)}
                  >
                    <Box
                      sx={styles.modal}
                      className="width-90-mobile">
                      <ResetPassword setShowResetPassword={setShowResetPassword} />
                    </Box>
                  </Modal>
                  <Field
                    name="phone"
                    type="text"
                    label="Phone Number"
                    maskedInput
                    mask={AccMaskedInputTypes.US_PHONE_NUMBER}
                    as={AccInput}
                    InputProps={{
                      readOnly: true,
                      endAdornment: <EditIcon
                        onClick={() => {
                          setShowResetPhoneNumber(true);
                          mfaRequest(MFARequestTypes.EMAIL);
                        }
                        }
                      />,
                    }}
                  />
                  <Modal
                    open={showResetPhoneNumber}
                    onClose={() => setShowResetPhoneNumber(false)}
                  >
                    <Box
                      sx={styles.modal}
                      className="width-90-mobile">
                      <ResetPhoneNumber setShowResetPhoneNumber={setShowResetPhoneNumber} />
                    </Box>
                  </Modal>
                </>
              </div>}
            </div>
            <br />
            <AccButton
              type="submit"
              color={AccButtonColors.GREEN}
              label="Save"
              style={{ width: "150px" }}
            />
          </Form>
        )}
      </Formik>
    </div>
    </PageWrapper>
  );
};

const styles = {
  editIcon: {
    position: "absolute",
    border: "1px",
    borderRadius: "50%",
    padding: "6px",
    background: COLORS.greenGradient,
    marginTop: "-45px",
    marginLeft: "145px",
    cursor: "pointer",
  },
  imageInputLabel: {
    cursor: "pointer",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: COLORS.darkBlueGradient,
    border: "none",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
    width: "45%",
    overflowY: "scroll",
    // minHeight: "500px",
    height: "fit-content",
  },
};

export default Profile;
