import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import PageCard from "../../../common/components/PageCard";
import { Typography, Divider, Breadcrumbs, Modal } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faUniversity, faInfoCircle, faCoins, faMoneyBillWave } from "@fortawesome/pro-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import { Box, MobileStepper, useMediaQuery } from "@mui/material";
import COLORS from "../../../common/colors";
import LabeledFigure from "../../../common/components/LabeledFigure";
import Investments from "./Investments";
import Fundings from "./Fundings";
import BankAccounts from "./BankAccounts";
import { QueryKeys, useAccreditedInvestorRequests, useUserPortfolioInvestments, useUserWallets, useWallet, useWalletExternalAccounts } from "../../../api/hooks/graphqlHooks";
import { formatCurrency, isNil, isNotNil } from "../../../common/utilities";
import PageWrapper from "../../../common/components/PageWrapper";
import { createFundingRequest, CreateFundingRequestInput } from "../../../api/query/FundingRequests";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import { FundingRequestDetail, FundingRequestTypes, InvestmentAmounts } from "../../../common/types";
import { Formik, FormikProps } from "formik";
import FundAccount from "../BankAccountFunding/FundAccount";
import VerifyFundingAccount from "../BankAccountFunding/VerifyFundingAccount";
import FundingConfirmation from "../BankAccountFunding/FundingConfirmation";
import { FundingFormValue } from "../../../common/types";
import LoadingAnimation from "../../../common/components/LoadingAnimation";
import TabView from "../../../common/components/TabView";
import { SessionContext } from "../../../context";
import moment from "moment";
import FDICLogo from "../../../assets/images/illustrations/fdic-logo.svg";
import { useHistory } from "react-router";
import Movements from "./Movements";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import { CLOSE_WALLET } from "../../../api/query/Wallets";
import { useMutation as UseMutation } from "@apollo/client";
import CustomTooltip from "../../../common/components/CustomTooltip";
import { useUMBAccount } from "../../../api/hooks/restHooks";
import { WalletTypes } from "../../../enums";
import { CloseAccountModal } from "./components/CloseAccountModal";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";

type AccountDetailsParams = {
  id: string;
};

type AccountDetailsProps = RouteComponentProps<AccountDetailsParams>;

const AccountDetails: React.FC<AccountDetailsProps> = withErrorBoundary({}, ({ match }: any) => {

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  const { user } = useContext(SessionContext);
  const [isFundingRequestSubmitting, setIsFundingRequestSubmitting] = useState<boolean>(false);
  const [isCloseAccountModalVisible, setIsCloseAccountModalVisible] = useState<boolean>(false);
  const [openFundAccountModal, setOpenFundAccountModal] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState(0);
  const [closeWalletSubmitting, setCloseWalletSubmitting] = useState<boolean>(false);
  const [fundingRequestType, setFundingRequestType] = useState<FundingRequestTypes>();
  const isWithdrawal = fundingRequestType === FundingRequestTypes.WITHDRAWAL;
  const isDeposit = fundingRequestType === FundingRequestTypes.DEPOSIT;
  const [fundingRequestDetails, setFundingRequestDetails] = useState<{
    success: boolean;
    details: FundingRequestDetail | null;
  }>({
    success: false,
    details: null,
  });
  const { data: accreditedInvestorData } =
    useAccreditedInvestorRequests(
      user?.accountObjectId, {
      refetchOnMount: true,
      enabled: isNotNil(user?.accountObjectId),
    });

  const queryClient = useQueryClient();
  const history = useHistory();
  const [closeWallet] = UseMutation(CLOSE_WALLET);
  const { data: userWalletData } = useUserWallets();

  const {
    data: walletData,
    isFetching: walletFetching,
    isLoading: walletLoading,
  } = useWallet(parseInt(match.params.id), {
    refetchOnMount: true,
    enabled: true,
  });

  const {
    data: umbAccountData,
    isFetching: umbAccountDataFetching,
    isLoading: umbAccountDataLoading,
  } = useUMBAccount(walletData?.getWallet?.umbAccountId!, {
    enabled: isNotNil(walletData?.getWallet?.umbAccountId),
  });

  const selectedWallet = walletData?.getWallet;

  const handleNext = async () => {
    if (activeStep === 0 && isNil(formRef.current?.values?.selectedExternalAccount) && isDeposit) {
      return toast("Select an account to fund from", {
        type: toast.TYPE.WARNING,
      });
    }
    if (activeStep === 0 && isNil(formRef.current?.values?.selectedExternalAccount) && isWithdrawal) {
      return toast("Select an account to withdraw from", {
        type: toast.TYPE.WARNING,
      });
    }
    if (activeStep === 0 && isNil(formRef.current?.values?.amount) && isDeposit) {
      return toast("Select an amount to fund", {
        type: toast.TYPE.WARNING,
      });
    }
    if (activeStep === 0 && isNil(formRef.current?.values?.amount) && isWithdrawal) {
      return toast("Select an amount to withdraw", {
        type: toast.TYPE.WARNING,
      });
    }
    if (activeStep === 0 && isWithdrawal &&
      (formRef.current?.values?.amount! > walletData?.getWallet.availableAmount!)) {
      return toast(`Maximum amount to be withdrawn is ${formatCurrency(walletData?.getWallet.availableAmount!)}`, {
        type: toast.TYPE.WARNING,
      });
    }
    if (activeStep === 0 && (formRef.current?.values?.amount! > InvestmentAmounts.MAX_AMOUNT_TO_INVEST)) {
      return toast(`Maximum funding amount is ${formatCurrency(InvestmentAmounts.MAX_AMOUNT_TO_INVEST)}`, {
        type: toast.TYPE.WARNING,
      });
    }
    if (activeStep === 1) {
      setIsFundingRequestSubmitting(true);
      try {
        if (isNil(formRef.current?.values.amount) || formRef.current?.values.amount == null) {
          return toast("No amount entered", {
            type: toast.TYPE.WARNING,
          });
        }
        //Used mutateAsync instead of mutate to get a promise which will resolve on success or throw on an error. 
        await saveFundingRequestMutation.mutateAsync({
          amount: formRef.current?.values.amount,
          fundingRequestTypeName: isDeposit ? FundingRequestTypes.DEPOSIT : FundingRequestTypes.WITHDRAWAL,
          externalAccountObjectId: formRef.current?.values.selectedExternalAccount!,
          walletObjectId: walletData?.getWallet.id,
        });
      } catch (err: any) {
        toast(isDeposit ? "There was an error funding investor account."
          : "There was an error withdrawing investor account.", {
          type: toast.TYPE.ERROR,
        },
        );
      };
      setIsFundingRequestSubmitting(false);
    };
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleCloseWallet = async (walletObjectId: string, externalAccountObjectId: string) => {
    setCloseWalletSubmitting(true);
    try {
      const closeWalletResponse = await closeWallet({
        variables: {
          id: walletObjectId,
          input: {
            externalAccountObjectId,
          },
        },
      });
      console.log("*****closeWalletResponse", closeWalletResponse);
      setCloseWalletSubmitting(false);
      toast("Account closed successfully!", {
        type: toast.TYPE.SUCCESS,
      });
      queryClient.refetchQueries(QueryKeys.WALLETS);
      history.push("/investor/accounts");
    } catch (err: any) {
      setIsCloseAccountModalVisible(false);
      setCloseWalletSubmitting(false);
      toast(err.message || err.msg || err.toString() || "There was an error closing account.", {
        type: toast.TYPE.ERROR,
      });
    }
  };

  const formRef = useRef<FormikProps<Partial<FundingFormValue>>>(null);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const toggleFundAccountModal = () => {
    if (activeStep === 0 || activeStep === 1) {
      setActiveStep(0);
      formRef.current?.resetForm();
    }
    if (activeStep === 2) {
      setActiveStep(0);
      setOpenFundAccountModal(false);
      setFundingRequestDetails({
        success: false,
        details: null,
      });
      formRef.current?.resetForm();
    }
    else {
      setOpenFundAccountModal(!openFundAccountModal);
    }
  };

  const saveFundingRequestMutation = useMutation((input: CreateFundingRequestInput) => createFundingRequest(input));

  const {
    data: externalAccountsData,
    isFetching: externalAccountsFetching,
    isLoading: externalAccountsLoading,
  } = useWalletExternalAccounts(parseInt(match.params.id), {
    refetchOnMount: true,
  });

  const {
    data: getUserPortfolioInvestmentsData,
  } = useUserPortfolioInvestments();

  const walletInvestments = getUserPortfolioInvestmentsData?.getUserPortfolioInvestments
    .filter(item => item.walletObjectId === walletData?.getWallet.id);

  const totalInvestedToDate = isNotNil(walletInvestments?.[0]?.investments)
    ? walletInvestments?.[0].investments?.map(item => item.amount).reduce((num1, num2) => num1 + num2)
    : null;

  useEffect(() => {
    if (saveFundingRequestMutation.isSuccess === true) {
      queryClient.invalidateQueries([QueryKeys.WALLET_FUNDINGS]);
      queryClient.refetchQueries([QueryKeys.WALLET_FUNDINGS]);
      setFundingRequestDetails({
        success: true,
        details: saveFundingRequestMutation.data.createFundingRequest,
      });
    }
  }, [saveFundingRequestMutation.isSuccess]);

  const fundingFieldInitialFormValues = {
    amount: null,
    selectedExternalAccount: null,
  } as Partial<FundingFormValue>;

  const steps = [
    {
      component: <FundAccount
        externalAccount={externalAccountsData?.getExternalAccounts}
        walletData={walletData?.getWallet}
        fundingRequestType={fundingRequestType}
      />,
    },
    {
      component: <VerifyFundingAccount
        externalAccount={externalAccountsData?.getExternalAccounts}
        walletData={walletData?.getWallet}
        fundingRequestType={fundingRequestType}
      />,
    },
    {
      component: <FundingConfirmation
        fundingRequestDetails={fundingRequestDetails.details}
        fundingRequestType={fundingRequestType}
      />,
    },
  ];

  const maxSteps = steps.length;

  const tabs = [
    {
      index: 0,
      label: "Fundings",
      children: <Fundings />,
      tabIcon: <FontAwesomeIcon
        size="lg"
        icon={faCoins} />,
    },
    {
      index: 1,
      label: "Investments",
      children: <Investments data={walletInvestments} />,
      tabIcon: <FontAwesomeIcon
        size="lg"
        icon={faMoneyBillWave} />,
      disabled: isNil(externalAccountsData?.getExternalAccounts),
    },
    {
      index: 2,
      label: "Movements",
      children: <Movements />,
      tabIcon: <FontAwesomeIcon
        size="lg"
        icon={faList} />,
    },
    {
      index: 3,
      label: "Bank Accounts",
      children: <BankAccounts walletId={match.params.id} />,
      tabIcon: <FontAwesomeIcon
        size="lg"
        icon={faUniversity} />,
    },
    // {
    //     index: 4,
    //     label: "Performance",
    //     children: <InvestmentAccountPerformance />,
    //     tabIcon: <FontAwesomeIcon
    //         size="lg"
    //         icon={faChartBar} />,
    //     disabled: true,
    // },
  ];

  if (isNil(walletData?.getWallet)) {
    return <></>;
  }

  if (externalAccountsLoading || !externalAccountsData || externalAccountsFetching || closeWalletSubmitting
    || umbAccountDataLoading || umbAccountDataFetching
  ) {
    return (
      <LoadingAnimation />
    );
  }

  if (isNil(selectedWallet)) {
    throw new Error("Could not load selected wallet");
  };

  return (
    <PageWrapper
      showLoader={
        walletFetching ||
        walletLoading ||
        externalAccountsFetching ||
        externalAccountsLoading ||
        closeWalletSubmitting ||
        umbAccountDataLoading ||
        umbAccountDataFetching
      }
    >
      <Formik
        innerRef={formRef}
        initialValues={fundingFieldInitialFormValues}
        onSubmit={(data, { resetForm }) => {
          console.log(data);
          resetForm();
        }}
      >
        {() => (
          <>
            <div
              style={{ display: userWalletData?.getUserWallets.length === 1 ? "none" : "" }}
              className="m-bottom-30">
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link
                  style={{ textDecoration: "none" }}
                  className="acc-lightgray"
                  color="inherit"
                  to="/investor/accounts"
                >
                  Investment Accounts
                </Link>
                <Typography className="acc-darkblue">{walletData?.getWallet.name}</Typography>
              </Breadcrumbs>
            </div>
            <div className="row m-top-50 column-mobile">
              <div className="width-70 m-right-50 width-100-mobile m-right-0-mobile m-bottom-30-mobile">
                <Typography
                  variant="h4"
                  className="bold acc-darkblue"
                >{walletData?.getWallet.name}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className="acc-darkgray light"
                  style={{ textTransform: "capitalize" }}
                >Account Type: {walletData?.getWallet.walletType.name.toLowerCase()}
                </Typography>
                <div className="row m-top-50 width-100 column-mobile">
                  {isNotNil(externalAccountsData?.getExternalAccounts) ?
                    <div
                      style={{ minWidth: mobileLayout ? "100%" : "160px" }}
                      className="width-20 row stretch m-bottom-15-mobile m-top-15-mobile">
                      <AccButton
                        label="Fund Account"
                        color={AccButtonColors.GREEN}
                        onClick={() => {
                          if (selectedWallet?.externalAccounts?.length === 0) {
                            toast.warning("Connect a bank account to fund your investor account!");
                          } else {
                            toggleFundAccountModal();
                            setFundingRequestType(FundingRequestTypes.DEPOSIT);
                          }
                        }}
                      />
                    </div> :
                    <Link
                      className="width-100-mobile m-bottom-15-mobile"
                      to={`/investor/wallet/connectbankaccount/${walletData?.getWallet.walletId}`}>
                      <AccButton
                        label="Connect Bank Account"
                        color={AccButtonColors.GREEN}
                        className="m-left-15"
                        style={{ width: "fit-content" }}
                      />
                    </Link>
                  }
                  {!accreditedInvestorData?.getAccreditedInvestorRequests.find(
                    accredit => selectedWallet?.id === accredit.walletObjectId) ?
                    <Link
                      className="width-100-mobile m-bottom-15-mobile"
                      to={`/investor/accreditation/${selectedWallet?.id}`}>
                      <AccButton
                        label="Complete Accreditation"
                        color={AccButtonColors.BLUE}
                        className="m-left-15  m-0-mobile width-100-mobile"
                        style={{ width: "fit-content" }}
                      />
                    </Link> : accreditedInvestorData?.getAccreditedInvestorRequests.map((accreditedIvestorRequest) => {
                      if (accreditedIvestorRequest?.walletObjectId === selectedWallet?.id && accreditedIvestorRequest?.status) {
                        if (accreditedIvestorRequest?.status === "Need More Info") {
                          return (
                            <Link
                              className="width-100-mobile m-bottom-15-mobile"
                              to={`/investor/wallet/needMoreInfo/${selectedWallet?.id}`}>
                              <AccButton
                                label="Need More Info"
                                color={AccButtonColors.BLUE}
                                className="m-left-15 m-0-mobile width-100-mobile"
                                style={{ width: "fit-content" }}
                              />
                            </Link>
                          );
                        }
                      }
                    })}
                </div>
                <TabView tabs={tabs} />
              </div>
              <PageCard
                className="width-30 m-left-30 width-100-mobile m-0-mobile"
                style={{ background: COLORS.darkBlueGradient, height: "fit-content" }}
              >
                <LabeledFigure
                  value={formatCurrency(selectedWallet?.availableAmount!, 2)}
                  description="Available Balance to Invest"
                  className="column font-size-mobile-1"
                  alignment="center"
                  containerClassName="m-bottom-30"
                  valueStyles={{ color: COLORS.lightGreen, fontSize: 38 }}
                  descriptionStyles={{ color: COLORS.white, fontSize: 20, fontWeight: 600 }}
                />
                <Divider style={{ background: COLORS.navBarLogo }} />
                <div className="row start stretch m-top-30">
                  <div className="column start width-50">
                    <LabeledFigure
                      value={formatCurrency(totalInvestedToDate!, 2)}
                      description="Total Invested To Date"
                      className="colum"
                      alignment="start"
                      valueStyles={{ color: COLORS.green, fontSize: 28 }}
                      descriptionStyles={{ color: COLORS.lightGray, textAlign: "left" }}
                      containerStyles={{ marginBottom: 30 }}
                    />
                    <LabeledFigure
                      value="$0.00"
                      description="Total Distributed To Date"
                      className="colum"
                      alignment="start"
                      valueStyles={{ color: COLORS.green, fontSize: 28 }}
                      descriptionStyles={{ color: COLORS.lightGray, textAlign: "left" }}
                      containerStyles={{ marginBottom: 30 }}
                    />
                  </div>
                  <div className="column start width-50 m-left-30">
                    <LabeledFigure
                      value="$0.00"
                      description="Total Committed To Date"
                      className="colum"
                      alignment="start"
                      valueStyles={{ color: COLORS.green, fontSize: 28 }}
                      descriptionStyles={{ color: COLORS.lightGray, textAlign: "left" }}
                      containerStyles={{ marginBottom: 30 }}
                    />
                    <span style={{ fontSize: 9, color: COLORS.white, marginBottom: 5, width: 150 }}>
                      Banking services are provided by UMB Bank, N.A., Member FDIC
                    </span>
                    <CustomTooltip
                      title={(<span>*FDIC insured for <br />up to $100,000,000</span>)}
                      background={COLORS.greenGradient}
                      color={COLORS.darkBlue}
                      fontSize={16}
                      fontWeight={600}>
                      <img
                        src={FDICLogo}
                        style={{ height: mobileLayout ? 30 : 35 }}
                      />
                    </CustomTooltip>
                  </div>
                </div>
                <Divider style={{ background: COLORS.navBarLogo }} />
                <div className="column start m-top-30">
                  <div className="column start">
                    <Typography
                      variant="h6"
                      className="acc-white bold">
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        className="acc-lightgreen m-right-15" />
                      Account Creation Date
                    </Typography>
                    <Typography
                      variant="h6"
                      className="acc-white light">
                      {moment(Number(selectedWallet?.createdAt)).format("MM/DD/YYYY")}
                    </Typography>
                  </div>
                  <div className="column start m-top-30">
                    <Typography className="acc-white bold">Account Type</Typography>
                    <Typography
                      className="acc-lightgreen"
                      style={{ textTransform: "capitalize" }}>
                      {selectedWallet?.walletType.name.toLowerCase()}
                    </Typography>
                  </div>
                  <div className="start m-top-30">
                    {isNotNil(umbAccountData?.Interest?.AnnualPercentageYield) ? (<>
                      <Typography
                        className="acc-white bold"
                        style={{ display: "flex" }}>APY&nbsp;
                        <CustomTooltip
                          title="Annual Percentage Yield"
                          background={COLORS.greenGradient}
                          fontSize={12}
                          color={COLORS.darkBlue}
                          placement="top">
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            color={COLORS.lightGray}
                            style={{ fontSize: 10 }} />
                        </CustomTooltip>&nbsp;
                      </Typography>
                      <span className="light acc-green">Earning {(umbAccountData?.Interest?.AnnualPercentageYield).toFixed(2)}% interest</span>
                    </>) : <></>}
                  </div>
                  <div className="column start m-top-30">
                    <Typography className="acc-white bold">Account Holder</Typography>
                    <Typography
                      className="acc-lightgreen">
                      {selectedWallet?.accountHolderFullName}
                    </Typography>
                    {selectedWallet?.walletType?.name !== WalletTypes.INDIVIDUAL ?
                      <>
                        <Typography
                          className="acc-white bold m-top-15"
                        >Primary User</Typography>
                        <Typography
                          className="acc-lightgreen">
                          {selectedWallet?.primaryUser?.firstName} {selectedWallet?.primaryUser?.lastName}
                        </Typography>
                      </>
                      : null
                    }
                    {selectedWallet?.walletType?.name === WalletTypes.JOINT && (
                      <>
                        <Typography className="acc-white bold m-top-15">Secondary User</Typography>
                        <Typography
                          className="acc-lightgreen">
                          {selectedWallet?.secondaryUser?.firstName} {selectedWallet?.secondaryUser?.lastName}
                        </Typography>
                      </>
                    )}
                  </div>
                  <>
                    {accreditedInvestorData?.getAccreditedInvestorRequests.find(
                      accredit => selectedWallet.id === accredit.walletObjectId) ?
                      <>
                        <Typography className="acc-white bold m-top-30">Accreditation Status</Typography>
                        <Typography
                          className="acc-lightgreen">
                          {accreditedInvestorData?.getAccreditedInvestorRequests.map((accreditedIvestorRequest) => {
                            if (accreditedIvestorRequest?.walletObjectId === selectedWallet.id && accreditedIvestorRequest?.status) {
                              return accreditedIvestorRequest?.status;
                            }
                          })}
                        </Typography>
                      </>
                      : null};
                  </>
                  <br />
                  <br />
                  <Link to={`/investor/accounts/accountstatements/${match.params.id}`}>
                    <Typography
                      variant="subtitle2"
                      className="acc-green light underlined-link">
                      Account Statements
                    </Typography>
                  </Link>
                  <br />
                  <Typography
                    onClick={() => {
                      if (selectedWallet?.externalAccounts?.length === 0) {
                        toast.warning("Connect a bank account to withdraw funds!");
                      } else {
                        toggleFundAccountModal();
                        setFundingRequestType(FundingRequestTypes.WITHDRAWAL);
                      }
                    }}
                    variant="subtitle2"
                    className="acc-green light underlined-link">
                    Withdraw
                  </Typography>
                  <br />
                  <Typography
                    onClick={() => setIsCloseAccountModalVisible(true)}
                    variant="subtitle2"
                    className="acc-red light underlined-link">
                    Close Account
                  </Typography>
                  <CloseAccountModal
                    visible={isCloseAccountModalVisible}
                    setVisible={setIsCloseAccountModalVisible}
                    wallet={selectedWallet}
                    handleCloseWallet={handleCloseWallet}
                  />
                  {/* <CancelConfirmDialogModal
                  isVisible={isCloseAccountModalVisible}
                  dialogHeader="Are you sure you'd like to close your account?"
                  cancelButtonText="No"
                  confirmButtonText="Yes"
                  onClickCancel={() => setIsCloseAccountModalVisible(false)}
                  onClickDelete={() => handleCloseWallet(selectedWallet?.id!)}
                /> */}
                </div>
              </PageCard>
            </div>
            <Modal
              open={openFundAccountModal}
              onClose={toggleFundAccountModal}>
              <Box
                className="width-90-mobile"
                sx={{
                  ...styles.modal,
                  minHeight: "400px",
                  height: "fit-content",
                }}>
                <div>
                  <Box sx={{ width: "100%", height: mobileLayout ? "60vh" : undefined, marginBottom: "15px", overflowY: mobileLayout ? "scroll" : undefined }}>
                    {isFundingRequestSubmitting ? <LoadingAnimation containerStyles={{ marginTop: "30px" }} /> : steps[activeStep].component}
                  </Box>
                  <MobileStepper
                    style={{
                      backgroundColor: "transparent",
                      color: "transparent",
                      display: activeStep === 2 || isFundingRequestSubmitting ? "none" : "flex",
                      flexFlow: mobileLayout ? "wrap" : "",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                    variant="text"
                    steps={maxSteps}
                    activeStep={activeStep}
                    nextButton={
                      <AccButton
                        type="submit"
                        style={{
                          width: mobileLayout ? "80%" : "25%", fontSize: "15px", position: "relative",
                          right: activeStep === 0 ? "15px" : "",
                        }}
                        color={AccButtonColors.GREEN}
                        label={activeStep === 1 ? "Confirm" : "Next"}
                        onClick={handleNext}
                      />
                    }
                    backButton={
                      <AccButton
                        style={{
                          width: mobileLayout ? "80%" : "25%", backgroundColor: "white", display: activeStep === 0 ? "none" : "",
                          fontSize: "15px", position: mobileLayout ? "relative" : "relative", left: mobileLayout ? "17px" : "", bottom: mobileLayout ? "10px" : "",
                        }}
                        label="Back"
                        color={AccButtonColors.WHITE}
                        onClick={handleBack}
                        disabled={activeStep === 0} />
                    }
                  />
                </div>
              </Box >
            </Modal >
          </>
        )}
      </Formik>
    </PageWrapper >
  );
});

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: COLORS.darkBlueGradient,
    border: "none",
    borderRadius: "16px",
    boxShadow: 24,
    padding: "30px",
    width: "45%",
  },
};

export default withRouter(AccountDetails);