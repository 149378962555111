import { Modal, Box, Typography } from "@mui/material";
import React from "react";
import AccButton, { AccButtonColors } from "../AccButton";
import COLORS from "../../colors";

interface Props {
  isVisible: boolean,
  onClickCancel: any,
  onClickDelete: any,
  dialogBody?: string,
  dialogHeader?: string,
  cancelButtonText?: string,
  confirmButtonText?: string,
  confirmButtonColor?: AccButtonColors,
  cancelButtonColor?: AccButtonColors;
  confirmButtonClassName?: string;
}

const CancelConfirmDialogModal: React.FC<Props> = ({
  isVisible,
  dialogBody,
  dialogHeader,
  onClickCancel,
  onClickDelete,
  cancelButtonText,
  confirmButtonText,
  confirmButtonColor,
  cancelButtonColor,
  confirmButtonClassName,

}) => (
  <Modal
    open={isVisible}
  >
    <Box
      className="width-20 width-90-mobile width-40-tablet"
      sx={styles.modal}>
      <Typography
        variant="h6"
        component="h2">
        {dialogHeader}
      </Typography>
      <Typography sx={{ mt: 2 }}>
        {dialogBody}
      </Typography>
      <br />
      <div className="row width-100 stretch column-mobile">
        <AccButton
          color={cancelButtonColor || AccButtonColors.WHITE}
          label={cancelButtonText || "Cancel"}
          style={{ backgroundColor: COLORS.white }}
          onClick={onClickCancel}
          className="m-right-15 m-0-mobile"
        />
        <AccButton
          color={confirmButtonColor || AccButtonColors.RED_OUTLINE}
          label={confirmButtonText || "Delete"}
          className={`${confirmButtonClassName || "delete-button"} m-top-15-mobile`}
          onClick={onClickDelete}
        />
      </div>
    </Box>
  </Modal>
);

const styles = {
  modal: {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  },
};

export default CancelConfirmDialogModal;
