import gql from "graphql-tag";
import { gql as gqlv2 } from "graphql-request";
import { makeGraphqlRequest } from "../../utils";

export const CREATE_USER_TYPE = gql`
  mutation CreateUserType($input: UserTypeInput!) {
    createUserType(input: $input) {
      name
    }
  }
`;

export const UPDATE_USER_TYPE = gql`
  mutation UpdateUserType($id: String!, $input: UserTypeInput!) {
    updateUserType(_id: $id, input: $input) {
      id
      name
    }
  }
`;

export const DELETE_USER_TYPE = gql`
  mutation DeleteUserType($id: String!) {
    deleteUserType(_id: $id) {
      id
      userTypeId
    }
  }
`;

const GET_USER_TYPE_V2 = gqlv2`
  query GetUserType ($userTypeId: Int!){
    getUserType(userTypeId: $userTypeId) {
      id
      userTypeId
      name
    }
  }
`;

export const getUserType = (userTypeId: number) => makeGraphqlRequest(GET_USER_TYPE_V2, { userTypeId });

const GET_USER_TYPES_V2 = gqlv2`
  query GetUserTypes {
    getUserTypes {
      id
      userTypeId
      name
    }
  }
`;

export const getUserTypes = () => makeGraphqlRequest(GET_USER_TYPES_V2);