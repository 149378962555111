import React, { useEffect, useState } from "react";
import { Breadcrumbs, Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { isNotNil } from "../../../../common/utilities";
import { QueryKeys, useWallet } from "../../../../api/hooks/graphqlHooks";
import LoadingAnimation from "../../../../common/components/LoadingAnimation";
import PageCard from "../../../../common/components/PageCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faDownload } from "@fortawesome/pro-light-svg-icons";
import COLORS from "../../../../common/colors";
import { useListArtifacts } from "../../../../api/hooks/restHooks";
import moment from "moment";
import { getArtifact } from "../../../../api/rest/umb";
import { toast } from "react-toastify";
import { ArtifactList } from "../../../../api/rest/umb/types";
import { useQueryClient } from "react-query";

interface Props {
  postedDate: string;
  onClick: (arg: any) => void;
}

const StatementCard: React.FC<Props> = ({ postedDate, onClick }) => {

  return (
    <PageCard
      className="width-50 row center stretch width-100-mobile m-bottom-30">
      <div className="width-60 row center">
        <FontAwesomeIcon
          size="lg"
          icon={faCalendar}
          color={COLORS.green}
          className="m-right-15" />
        <Typography
          variant="subtitle1"
          className="acc-darkblue bold">{moment(postedDate).format("MM/DD/YYYY")}</Typography>
      </div>
      <div className="width-35 column end">
        <Typography
          style={{ cursor: "pointer" }}
          onClick={onClick}
          variant="subtitle2"
          className="acc-gray">
          Download
          <FontAwesomeIcon
            icon={faDownload}
            color={COLORS.green}
            className="m-left-15" />
        </Typography>
      </div>
    </PageCard>
  );
};

type AccountStatementsParams = {
  id: string;
};

type AccountStatementsProps = RouteComponentProps<AccountStatementsParams>;

const AccountStatements: React.FC<AccountStatementsProps> = ({ match }) => {

  const [statementDownloading, setStatementDownloading] = useState(false);

  const queryClient = useQueryClient();

  const {
    data: walletData,
    isFetching: walletFetching,
    isLoading: walletLoading,
  } = useWallet(parseInt(match.params.id), {
    enabled: isNotNil(match.params.id),
  });

  useEffect(() => {
    queryClient.removeQueries(QueryKeys.WALLET);
  }, []);

  //Date format should always be "YYYY-MM-DD" to match the UMB request date format!
  const endDate = moment(new Date()).format("YYYY-MM-DD");
  const startDate = moment(Number(walletData?.getWallet?.createdAt)).format("YYYY-MM-DD");

  const {
    data: listArtifactsData,
    isFetching: listArtifactsFetching,
    isLoading: listArtifactsLoading,
  } = useListArtifacts(walletData?.getWallet?.umbAccountId!, startDate, endDate, {
    refetchOnMount: true,
    enabled: isNotNil(walletData?.getWallet?.umbAccountId),
  });

  const downloadPDF = (pdf: string, pdfName: string) => {

    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = pdfName;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const handleDownload = async (artifactId: string) => {
    setStatementDownloading(true);
    try {
      await getArtifact(artifactId)
        .then((res) => {
          downloadPDF(res?.Artifact?.ArtifactContent, res?.Artifact?.ArtifactName);
          setStatementDownloading(false);
        });
    } catch (err: any) {
      setStatementDownloading(false);
      toast(err.message || err.msg || err.toString() || "There was an error downloading statement!", {
        type: toast.TYPE.WARNING,
      });
    }
  };

  if (walletFetching || walletLoading || listArtifactsFetching || listArtifactsLoading || statementDownloading) {
    return (
      <LoadingAnimation />
    );
  }

  return (
    <div>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link
          style={{ textDecoration: "none" }}
          className="acc-lightgray"
          color="inherit"
          to={`/investor/accounts/accountdetails/${match.params.id}`}
        >
          {walletData?.getWallet.name}
        </Link>
        <Typography className="acc-darkblue">Statements</Typography>
      </Breadcrumbs>
      <br />
      <br />
      <Typography
        className="acc-darkblue bold"
        variant="h5">
        Statements&nbsp;
      </Typography>
      <br />
      <br />
      {listArtifactsData?.NumberOfRecords === 0 ?
        <Typography>No statements to show!</Typography> :
        <>
          {listArtifactsData?.ArtifactList?.map((item: ArtifactList) =>
            <StatementCard
              key={item.ArtifactId.Id}
              postedDate={item.PostedDate}
              onClick={() => handleDownload(item.ArtifactId.Id)}
            />,
          )}
        </>}
    </div>
  );
};

export default withRouter(AccountStatements);