import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faStopwatch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useState, useEffect } from "react";
import COLORS from "../../colors";
import LabeledFigure from "../LabeledFigure";
import moment from "moment";

export interface TimerProps {
  description?: string;
  descriptionStyle?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
  containerStyles?: React.CSSProperties;
  loading?: boolean;
  endDate?: string | number | Date;
  iconSize?: SizeProp;
}

const Timer: React.FC<TimerProps> = ({
  loading, endDate, descriptionStyle, valueStyle, iconSize,
}) => {
  const calculateTimeLeft = () => {
    const blankTimer = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
    if (loading) return blankTimer;
    const today: any = moment(new Date()).local();
    const endingDate: any = moment(endDate);
    const remainingTime = endingDate - today;

    let timeLeft;
    if (remainingTime > 0) {
      timeLeft = {
        days: Math.floor(remainingTime / (1000 * 60 * 60 * 24)),
        hours: Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((remainingTime % (1000 * 60)) / 1000),
      };
    } else {
      return blankTimer;
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <div
      className="row  center m-top-30-mobile">
      <FontAwesomeIcon
        size={iconSize ? iconSize : "2x"}
        icon={faStopwatch}
        className="acc-white"
      />
      <LabeledFigure
        value={timeLeft.days < 10 ? `0${timeLeft.days}` : timeLeft.days}
        valueStyles={{
          lineHeight: 1,
          ...valueStyle,
        }}
        description="DAYS"
        descriptionStyles={{
          lineHeight: 1,
          fontSize: 8,
          color: COLORS.white,
          ...descriptionStyle,
        }}
        containerStyles={{ marginLeft: 8 }}
        className="acc-green"
        alignment="center"
      />
      <LabeledFigure
        value={timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}
        valueStyles={{
          lineHeight: 1,
          ...valueStyle,
        }}
        description="HOURS"
        descriptionStyles={{
          lineHeight: 1,
          fontSize: 8,
          color: COLORS.white,
          ...descriptionStyle,
        }}
        containerStyles={{ marginLeft: 5 }}
        className="acc-green"
        alignment="start"
      />
      <LabeledFigure
        value={timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}
        valueStyles={{
          lineHeight: 1,
          ...valueStyle,
        }}
        description="MINUTES"
        descriptionStyles={{
          lineHeight: 1,
          fontSize: 8,
          color: COLORS.white,
          ...descriptionStyle,
        }}
        containerStyles={{ marginLeft: 5 }}
        className="acc-green"
        alignment="start"
      />
      <LabeledFigure
        value={timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}
        valueStyles={{
          lineHeight: 1,
          ...valueStyle,
        }}
        description="SECONDS"
        descriptionStyles={{
          lineHeight: 1,
          fontSize: 8,
          color: COLORS.white,
          ...descriptionStyle,
        }}
        containerStyles={{ marginLeft: 5 }}
        className="acc-green"
        alignment="start"
      />
    </div>
  );
};

export default memo(Timer);
