import React from "react";
import Bridge from "../../assets/images/investments/bridge.jpg";
import Building from "../../assets/images/investments/building.jpg";
import Lakers from "../../assets/images/investments/lakers.jpg";
import Lobby from "../../assets/images/investments/lobby.jpg";
import Pharrel from "../../assets/images/investments/pharrel.jpg";
import Plane from "../../assets/images/investments/plane.jpg";
import AccButton, { AccButtonColors } from "../../common/components/AccButton";
import PageTitle from "../../common/components/PageTitle";
import ActivityNotification from "./components/ActivityNotification";
import NewMessage from "./components/NewMessage";

interface Props {

}

const Activity: React.FC<Props> = () => (
  <div>
    <PageTitle
      title={<b>Messages</b>}
      subtitle={(
        <>
          Stay up to date with everything
          <span className="acc-text">access</span>
          {" "}
          has to offer
        </>
      )}
    />
    <br />
    <br />
    <div className="row stretch">
      <div
        className="column start width-40 width-100-mobile"
        style={{ height: "800px", overflowY: "scroll" }}>
        <ActivityNotification
          title={(
            <h4 style={{ margin: 0 }}>
              Pharrel Williams
              <span className="acc-text">access</span>
              Bond
            </h4>
          )}
          date="Nov 23"
          description="Don't miss the opportunity to invest in Pharrel Williams' upcoming world tour!"
          image={Pharrel}
        />
        <ActivityNotification
          title="Boeing 737-700"
          date="Nov 22"
          description="You're almost there! Finish the last step of investment for Boeing 737-700."
          image={Plane}
        />
        <ActivityNotification
          title="Great China Bridge"
          date="Nov 20"
          description="Exciting news! The release date for Great China Bridge has been posted."
          image={Bridge}
        />
        <ActivityNotification
          title="Modern Berlin Hotel"
          date="Nov 2"
          description="Modern Berlin Hotel is almost fully funded. Go to Invest to take advantage of this opportunity."
          image={Lobby}
        />
        <ActivityNotification
          title="Beautiful Home"
          date="Oct 20"
          description="Congrats! Investment in Beautiful Home was successful!"
          image={Building}
        />
        <ActivityNotification
          title="The Lakers"
          date="Oct 15"
          description="Exciting news! The release date for The Lakers has been posted."
          image={Lakers}
        />
      </div>
      <div
        className="column start display-none-mobile"
        style={{
          width: "55%", height: "800px", overflowY: "scroll", boxShadow: "-10px 0px 60px #00000014", borderRadius: "16px",
        }}
      >
        <NewMessage
          date="Nov 23"
          description="Invest in individual or thematic offerings across multiple asset classes with varying yields and terms."
          button={(
            <AccButton
              label="View Opportunities"
              color={AccButtonColors.GREEN}
            />
          )}
        />
        <br />
        <NewMessage
          date="Nov 23"
          description="Invest in 3 or 6 month opportunities with interest rates typically higher than money markets and most CDs."
        />
        <br />
        <NewMessage
          date="Nov 23"
          description="Get unparalleled access to alternative investments once only reserved for the 1%.
                Multiple asset classes that can power your portfolio diversification."
          button={(
            <AccButton
              label="Explore"
              color={AccButtonColors.GREEN}
            />
          )}
        />
        <br />
        <NewMessage
          date="Nov 23"
          description="Invest in a single solution, the Access Fund, that provides access to multiple alternative asset classes and pays as consistent quarterly distribution."
        />
        <br />
        <NewMessage
          date="Oct 20"
          description="Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore. Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore."
        />
        <br />
        <NewMessage
          date="Oct 20"
          description="Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore. Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore."
        />
        <br />
        <NewMessage
          date="Oct 20"
          description="Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore. Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore."
        />
        <br />
        <NewMessage
          date="Oct 20"
          description="Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore. Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore."
        />
      </div>
    </div>

  </div>
);

export default Activity;
