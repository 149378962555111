import gql from "graphql-tag";
import { makeGraphqlRequest } from "../../utils";
import { gql as gqlv2 } from "graphql-request";

export const CREATE_ASSET_CLASS = gql`
  mutation CreateAssetClass($input: AssetClassInput!) {
    createAssetClass(input: $input) {
      name
      vertical {
        verticalId
        name
      }
    }
  }
`;

export const UPDATE_ASSET_CLASS = gql`
  mutation UpdateAssetClass($id: String!, $input: AssetClassInput!) {
    updateAssetClass(_id: $id, input: $input) {
      id
      name
      vertical {
        verticalId
        name
      }
    }
  }
`;

export const DELETE_ASSET_CLASS = gql`
  mutation DeleteAssetClass($id: String!) {
    deleteAssetClass(_id: $id) {
      id
      assetClassId
    }
  }
`;

const GET_ASSET_CLASS_V2 = gqlv2`
query GetAssetClass($assetClassId: Int!) {
  getAssetClass(assetClassId: $assetClassId) {
    id
    assetClassId
    name
    vertical {
      id
      verticalId
      name
    }
  }
}
`;

export const getAssetClass = (assetClassId: number) => makeGraphqlRequest(GET_ASSET_CLASS_V2, { assetClassId });

const GET_ASSET_CLASSES_V2 = gqlv2`
query GetAssetClasses {
  getAssetClasses {
    id
    assetClassId
    name
    vertical {
      verticalId
      name
    }
  }
}
`;

export const getAssetClasses = () => makeGraphqlRequest(GET_ASSET_CLASSES_V2);
