import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik, Form, Field, FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import { isNil, validatePhoneNumber } from "../../../common/utilities";
import { EditModeTypes } from "../../../enums";
import CancelConfirmDialogModal from "../../../common/components/CancelConfirmDialogModal";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import COLORS from "../../../common/colors";
import { userRegistration, userUpdate, userDeactivate, userActivate } from "../../../api/rest/user/index";
import { useUserForAdmin, QueryKeys, useAccounts, useUserTypes } from "../../../api/hooks/graphqlHooks";
import { useQueryClient } from "react-query";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import UMB from "./UMB";
import Typography from "@mui/material/Typography";
import AccInput, { AccMaskedInputTypes } from "../../../common/components/AccInput";
import { UserStatuses, UserTypes } from "../../../enums/RegistrationEnums";
import PageWrapper from "../../../common/components/PageWrapper";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";
import { UserTypes as userTypes } from "../../../common/types";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {

  return (
    <div>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

type UserDetailParams = {
  id?: string;
};

type UserDetailProps = RouteComponentProps<UserDetailParams>;

const UserForm: React.FC<UserDetailProps> = withErrorBoundary({}, ({ match, history }: UserDetailProps) => {
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const queryClient = useQueryClient();
  const editMode: EditModeTypes = isNil(match.params.id) ? EditModeTypes.ADD : EditModeTypes.EDIT;
  if (editMode === EditModeTypes.ADD) {
    queryClient.removeQueries(QueryKeys.USER_FOR_ADMIN);
  }
  const {
    data: getUserForAdminData,
    isLoading: getUserForAdminLoading,
    isFetching: getUserForAdminFetching,
    isError: getUserForAdminError,
  } = useUserForAdmin(Number(match.params.id), {
    enabled: editMode === EditModeTypes.EDIT,
    refetchOnMount: true,
  });
  const { data: getAccountsData, isLoading: getAccountsLoading, isFetching: getAccountsFetching, isError: getAccountsAdmin } = useAccounts();
  const { data: getUserTypesData, isLoading: getUserTypesLoading, isFetching: getUserTypesFetching, isError: getUserTypesAdmin } = useUserTypes();

  const [cancelDialogVisible, setCancelDialogVisibible] = useState(false);

  const openCancelDialog = () => setCancelDialogVisibible(true);
  const closeCancelDialog = () => setCancelDialogVisibible(false);

  const BLANK_FORM_VALUES = {
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    password: "",
    linkedInUrl: "",
    jobTitle: "",
    phone: "",
    accountObjectId: "",
    accountName: "",
    accountId: "",
    userTypeObjectId: "",
    userTypeName: "",
    userTypeId: "",
    CRDNumber: "",
    firmCRDNumber: "",
  };

  const initialFormFieldValues = () => (getUserForAdminData?.getUser ? {
    firstName: getUserForAdminData?.getUser.firstName,
    middleName: getUserForAdminData?.getUser.middleName,
    lastName: getUserForAdminData?.getUser.lastName,
    email: getUserForAdminData?.getUser.email,
    linkedInUrl: getUserForAdminData?.getUser.linkedInUrl,
    jobTitle: getUserForAdminData?.getUser.jobTitle,
    phone: getUserForAdminData?.getUser.phone,
    accountObjectId: getUserForAdminData?.getUser.account ? getUserForAdminData?.getUser.account.id : "Account does not exist, assign new Account",
    accountName: getUserForAdminData?.getUser.account ? getUserForAdminData?.getUser.account.name : <span style={{ color: "red" }}>Account does not exist, assign new Account</span>,
    accountId: getUserForAdminData?.getUser.account ? getUserForAdminData?.getUser.account.id : "Account does not exist, assign new Account",
    userTypeObjectId: getUserForAdminData?.getUser.userType ? getUserForAdminData?.getUser.userType.id : "User Type does not exist, assign new User Type",
    userTypeName: getUserForAdminData?.getUser.userType ? getUserForAdminData?.getUser.userType.name : "User Type does not exist, assign new User Type",
    userTypeId: getUserForAdminData?.getUser.userType ? getUserForAdminData?.getUser.userType.id : "User Type does not exist, assign new User Type",
    CRDNumber: getUserForAdminData?.getUser.userType.name === UserTypes.ADVISOR ? getUserForAdminData?.getUser?.CRDNumber : "Enter CRD number",
    firmCRDNumber: getUserForAdminData?.getUser.userType.name === UserTypes.ADVISOR ? getUserForAdminData?.getUser?.firmCRDNumber : "Enter firm CRD number",
  } : BLANK_FORM_VALUES);

  const handleActivation = async (objectId: number, activate: boolean) => {
    try {
      if (activate) {
        await userActivate(objectId);
      } else {
        await userDeactivate(objectId);
      }
      queryClient.refetchQueries(QueryKeys.USERS);
      history.push("/admin/users");
    } catch (err: any) {
      toast(err.message || err.msg || err.toString() || "There was an error updating user status.", {
        type: toast.TYPE.WARNING,
      });
    }
  };

  const handlePhoneFieldValidation = (values: FormikValues) => {
    if (isNil(values.phone)) {
      toast("Enter a phone number", {
        type: toast.TYPE.WARNING,
      });
    }
  };

  const submitForm = async (values: FormikValues, { resetForm }: any) => {
    if (getUserForAdminData?.getUser.phone !== values.phone && !validatePhoneNumber(values.phone)) {
      toast("Phone number is not valid", {
        type: toast.TYPE.ERROR,
      });
      return;
    }
    if (editMode === EditModeTypes.ADD) {
      try {
        await userRegistration({
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          phone: values.phone,
          accountObjectId: values.accountObjectId,
          userTypeObjectId: values.userTypeObjectId,
          userTypeName: values.userTypeName,
          CRDNumber: values.CRDNumber,
          firmCRDNumber: values.firmCRDNumber,
        }).then((res) => {
          if (res?.success === true) {
            resetForm();
            queryClient.refetchQueries(QueryKeys.USERS);
            history.push("/admin/users");
            toast("User created successfully!", {
              type: toast.TYPE.SUCCESS,
            });
          }
        });
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error adding user.", {
          type: toast.TYPE.ERROR,
        });
      }
    } else if (editMode === EditModeTypes.EDIT) {
      try {
        await userUpdate(
          {
            userId: getUserForAdminData?.getUser.userId,
            firstName: values.firstName,
            middleName: values.middleName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            linkedInUrl: values.linkedInUrl,
            jobTitle: values.jobTitle,
            phone: typeof values.phone === "string" ? parseInt(values.phone?.toString()?.replace(/[^0-9]/g, "")) : values.phone,
            accountObjectId: values.accountObjectId,
            userTypeName: values.userTypeName,
            userTypeObjectId: values.userTypeObjectId,
            CRDNumber: values.CRDNumber,
            firmCRDNumber: values.firmCRDNumber,
          }).then((res) => {
            if (res?.success === true) {
              resetForm();
              queryClient.refetchQueries(QueryKeys.USERS);
              history.push("/admin/users");
            }
          });
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error updating user.", {
          type: toast.TYPE.ERROR,
        });
      }
    }
  };

  useEffect(() => {
  }, []);

  const pageLoading =
    getUserForAdminLoading ||
    getAccountsLoading ||
    getUserForAdminFetching ||
    getAccountsFetching ||
    getUserTypesLoading ||
    getUserTypesFetching;

  return (
    <PageWrapper
      title={`${editMode === EditModeTypes.ADD ? "Add" : "Update"} User`}
      showLoader={pageLoading}
      showError={getUserForAdminError || getAccountsAdmin || getUserTypesAdmin}
      errorMessage="Cannot retrieve users"
    >
      <br />
      <div>
        <Formik
          initialValues={initialFormFieldValues()}
          enableReinitialize
          onSubmit={submitForm}
        >
          {({ values, handleChange }) => (
            <Form>
              <div className="column-reverse-mobile">
                <div className="row stretch center width-100 column-mobile">
                  <Link
                    to="/admin/users"
                    className="width-100-mobile">
                    <AccButton
                      color={AccButtonColors.WHITE}
                      label="Cancel"
                      style={{ backgroundColor: COLORS.white }}
                    />
                  </Link>
                  <div className="row width-100-mobile column-mobile">
                    <AccButton
                      color={AccButtonColors.GREEN}
                      type="submit"
                      label={editMode === EditModeTypes.ADD ? "Submit" : "Update"}
                      className="m-top-15-mobile m-bottom-15-mobile"
                      onClick={() => handlePhoneFieldValidation(values)}
                    />
                    {editMode === EditModeTypes.EDIT
                      ? (
                        <>
                          <AccButton
                            color={AccButtonColors.RED_OUTLINE}
                            label={getUserForAdminData?.getUser.status === UserStatuses.INACTIVE ? "Activate" : "Deactivate"}
                            className="activate-button m-left-15 display-none-mobile"
                            type="button"
                            onClick={openCancelDialog}
                          />
                          {mobileLayout === true
                            && (
                              <AccButton
                                color={AccButtonColors.RED_OUTLINE}
                                label={getUserForAdminData?.getUser.status === UserStatuses.INACTIVE ? "Activate" : "Deactivate"}
                                className="activate-user-button"
                                type="button"
                                width="40%"
                                style={styles.mobileDeactivateButton}
                                onClick={openCancelDialog}
                              />
                            )}
                        </>
                      )
                      : null}
                    {getUserForAdminData ? <CancelConfirmDialogModal
                      confirmButtonText={getUserForAdminData?.getUser.status === UserStatuses.INACTIVE ? "Activate" : "Deactivate"}
                      isVisible={cancelDialogVisible}
                      dialogBody="Are you sure you want to update the status of this User?"
                      onClickCancel={closeCancelDialog}
                      onClickDelete={() =>
                        handleActivation(getUserForAdminData?.getUser.userId, getUserForAdminData?.getUser.status === UserStatuses.INACTIVE)}
                    /> : null}
                  </div>
                </div>
                <br />
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      aria-label="basic tabs example"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: COLORS.darkBlue,
                        },
                      }}
                      sx={{
                        "& .MuiTab-root.Mui-selected": {
                          color: COLORS.darkBlue,
                        },
                      }}
                    >
                      <Tab
                        disableRipple
                        label="Users"
                        value={0}
                      />
                      <Tab
                        disableRipple
                        label="UMB"
                        value={1}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel
                    value={tabValue}
                    index={0}>
                    <div
                      style={styles.border}
                      className="width-60 width-100-mobile">
                      <Field
                        name="firstName"
                        type="text"
                        label="First Name"
                        as={AccInput}
                        required
                      />
                      <Field
                        name="middleName"
                        type="text"
                        label="Middle Name"
                        as={AccInput}
                      />
                      <Field
                        name="lastName"
                        type="text"
                        label="Last Name"
                        as={AccInput}
                        required
                      />
                      <Field
                        name="email"
                        type="text"
                        label="Email"
                        as={AccInput}
                        required
                      />
                      {editMode === EditModeTypes.ADD ?
                        <Field
                          name="password"
                          type="text"
                          label="Password"
                          as={AccInput}
                          required
                        />
                        :
                        <Field
                          name="password"
                          type="text"
                          label="Password"
                          as={AccInput}
                        />
                      }
                      {getUserTypesData?.getUserTypes?.find((item) => item.id === values.userTypeObjectId)?.name === userTypes.ASSET_MANAGER ? 
                      <Field
                      name="linkedInUrl"
                      type="text"
                      label="LinkedIn URL"
                      as={AccInput}
                      required/>
                      : <Field
                      name="linkedInUrl"
                      type="text"
                      label="LinkedIn URL"
                      as={AccInput}
                      />}
                      <Field
                        name="jobTitle"
                        type="text"
                        label="Job title"
                        as={AccInput}
                      // required
                      />
                      <AccInput
                        name="phone"
                        maskedInput
                        mask={AccMaskedInputTypes.US_PHONE_NUMBER}
                        value={values.phone}
                        onChange={handleChange}
                        label="Phone Number"
                        required
                      />
                      <AccInput
                        name="accountObjectId"
                        value={values.accountObjectId}
                        label="Account"
                        select
                        onChange={handleChange}
                        required
                      >
                        <MenuItem
                          selected
                          value={values.accountId}>
                          {values.accountName ? values.accountName : <span style={{ color: "red" }}>Account does not exist, assign new Account</span>}
                        </MenuItem>
                        {getAccountsData?.getAccounts
                          ?.filter(
                            (item) => item.id !== values.accountId,
                          )
                          .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name))
                          .map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </AccInput>
                      <AccInput
                        name="userTypeObjectId"
                        value={values.userTypeObjectId}
                        label="User Type"
                        select
                        onChange={handleChange}
                        required
                      >
                        <MenuItem
                          selected
                          value={values.userTypeId}>
                          {values.userTypeName ? values.userTypeName : <span style={{ color: "red" }}>User Type does not exist, assign new User Type</span>}
                        </MenuItem>
                        {getUserTypesData?.getUserTypes
                          ?.filter(
                            (item) => item.id !== values.userTypeId,
                          )
                          .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name))
                          .map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </AccInput>
                      {getUserTypesData?.getUserTypes?.find((item) => item.id === values.userTypeObjectId)?.name === UserTypes.ADVISOR ?
                        <>
                          <Field
                            name="CRDNumber"
                            type="text"
                            label="CRD #"
                            as={AccInput}
                            required
                          />
                          <Field
                            name="firmCRDNumber"
                            type="text"
                            label="Firm CRD #"
                            as={AccInput}
                            required
                          />
                        </>
                        : null}
                    </div>
                  </TabPanel>
                  <TabPanel
                    value={tabValue}
                    index={1}>
                    <UMB />
                  </TabPanel>
                </Box>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </PageWrapper >
  );
});

const styles = {
  border: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    padding: "15px",
  },
  mobileDeactivateButton: {
    alignSelf: "flex-start",
    border: "none",
    textAlign: "left",
    padding: 0,
    height: 20,
  },
};

export default withRouter(UserForm);
