import {
  faBell, faCoins, faEye,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "@material-ui/core";
import React, { useContext } from "react";
import { NotificationCenterContext } from "../../../context";
import COLORS from "../../colors";
import Notification from "../Notification";

interface Props {

}

const NotificationCenter: React.FC<Props> = () => {
  // @ts-ignore
  const { setNotificationCenterVisible, notificationCenterVisible } = useContext(NotificationCenterContext);
  return (
    <>
      <div
        onClick={(e) => {
          e.preventDefault();
          setNotificationCenterVisible(!notificationCenterVisible);
        }}
        style={{
          position: "fixed",
          top: 15,
          right: 25,
          padding: 15,
          border: `1.5px solid ${COLORS.darkBlue}`,
          borderRadius: "50%",
          cursor: "pointer",
          zIndex: 5,
          background: COLORS.white,
          display: "none",
        }}
      >
        <Badge
          className="notification-center"
          variant="dot"
          color="secondary"
          badgeContent=""
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <FontAwesomeIcon
            className="notification-center"
            style={{ fontSize: 30 }}
            icon={faBell} />
        </Badge>
      </div>
      {notificationCenterVisible && (
        <div
          style={{
            position: "fixed",
            right: "60px",
            top: "55px",
            height: "75vh",
            width: "300px",
            // background: "black",
            zIndex: 2,
          }}
        >
          <Notification
            title="This investment requires your attention"
            caption="Boeing 747-8 Operated by Lufthansa"
            body="Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore."
            icon={faEye}
            actionLabel="Take Action"
          />
          <Notification
            title="Your access Account is funded, now what?"
            caption="access now!"
            body="Now it is time to start discovering all the amazing assets Access has to offer. Great opportunities await you!"
            icon={faCoins}
            actionLabel="Invest"
          />
          <Notification
            title="A bank account requires your attention"
            caption="Wells Fargo Checking | xxxx7888"
            body="Your bank account is no longer synced. You have to enter your bank credentials and verify the account."
            icon={faEye}
            actionLabel="Take Action"
          />
        </div>
      )}
    </>
  );
};

export default NotificationCenter;
