import { Typography } from "@material-ui/core";
import { Checkbox, styled, useMediaQuery } from "@mui/material";
import { Field, useFormikContext } from "formik";
import React, { useContext } from "react";
import { useQueryClient } from "react-query";
import { QueryKeys } from "../../../../../api/hooks/graphqlHooks";
import COLORS from "../../../../../common/colors";
import AccInput, { AccMaskedInputTypes } from "../../../../../common/components/AccInput";
import AccOptionSelect from "../../../../../common/components/AccInput/variants/AccOptionSelect";
import { COUNTRIES, THEME_BREAKPOINTS, US_STATES } from "../../../../../common/constants";
import { Asset, InvestmentFormValue } from "../../../../../common/types";
import { SessionContext } from "../../../../../context";

interface Props {
  address: {
    street1: string;
    city: string;
    postalCode: string;
    state: string;
    country: string;
  };
}

const ProfileInformation: React.FC<Props> = ({ address }) => {

  const { user } = useContext(SessionContext);
  const { values, setFieldValue } = useFormikContext<InvestmentFormValue>();
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  const queryClient = useQueryClient();
  const assetData: { getAsset?: Asset } | undefined = queryClient.getQueryData(QueryKeys.ASSET);
  const ifRequiresAccreditation = assetData?.getAsset?.dealType.requiresAccreditation === true;


  const initialValues = {
    firstName: user?.firstName,
    middleName: user?.middleName,
    lastName: user?.lastName,
    address: address.street1,
    address2: "",
    city: address.city,
    state: address.state,
    country: address.country,
    postalCode: address.country,
    phone: user?.phone,
    email: user?.email,
    ssn: "123456789",
    dateOfBirth: "1985-12-23",
    countryOfBirth: "US",
  };

  return (
    <div
      className="column start stretch">
      <Typography
        variant="h5"
        className="acc-white bold">
        Please Review Your Profile Information</Typography>
      <Typography
        variant="caption"
        className="acc-white">If you believe this information is incorrect, please contact <span className="acc-green">support@acessinvestments.com</span></Typography>
      <>
        <div className="row stretch width-100 m-top-50 column-mobile">
          <div className="row width-60 m-right-50 width-100-mobile m-0-mobile column-mobile">
            <div
              className="column start width-50 m-right-50 stretch width-100-mobile m-0-mobile"
              style={{ height: "fit-content", minHeight: 480 }}>
              <div>
                <Typography
                  style={{ marginBottom: 10 }}
                  variant="subtitle1"
                  className="acc-white bold">Personal Information</Typography>
                <StyledInput
                  margin="dense"
                  as={AccInput}
                  name="firstName"
                  value={initialValues.firstName}
                  label="First Name"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <StyledInput
                  margin="dense"
                  as={AccInput}
                  name="middleName"
                  value={initialValues.middleName}
                  label="Middle Name"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <StyledInput
                  margin="dense"
                  as={AccInput}
                  name="lastName"
                  value={initialValues.lastName}
                  label="Last Name"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <StyledInput
                  margin="dense"
                  as={AccInput}
                  name="dateOfBirth"
                  type="date"
                  value={initialValues.dateOfBirth}
                  label="Date of Birth"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <StyledInput
                  margin="dense"
                  as={AccOptionSelect}
                  name="countryOfBirth"
                  value={initialValues.countryOfBirth}
                  label="Country of Birth"
                  options={COUNTRIES}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                {/* Hidden until we have ssn encryption storage */}
                {/* <StyledInput
                  margin="dense"
                  as={AccInput}
                  name="ssn"
                  maskedInput
                  mask={AccMaskedInputTypes.SSN}
                  onChange={((e: any) => setSocialSecurityNumber(e.target.value))}
                  value={socialSecurityNumber}
                  label="Social Security Number"
                  InputProps={{
                    readOnly: true,
                  }}
                /> */}
              </div>
              <div className="width-100 m-top-30-mobile">
                <Typography
                  style={{ marginBottom: 10 }}
                  variant="subtitle1"
                  className="acc-white bold">Contact Information</Typography>
                <StyledInput
                  margin="dense"
                  as={AccInput}
                  name="email"
                  value={initialValues.email}
                  label="Email Address"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                {mobileLayout && <StyledInput
                  margin="dense"
                  as={AccInput}
                  name="phone"
                  maskedInput
                  mask={AccMaskedInputTypes.US_PHONE_NUMBER}
                  value={initialValues.phone}
                  label="Phone Number"
                  InputProps={{
                    readOnly: true,
                  }}
                />}
              </div>
            </div>
            <div
              className="column start width-50 stretch m-top-30-mobile width-100-mobile"
              style={{ height: "fit-content", minHeight: 480 }}>
              <div>
                <Typography
                  style={{ marginBottom: 10 }}
                  variant="subtitle1"
                  className="acc-white bold">Address</Typography>
                <StyledInput
                  as={AccInput}
                  margin="dense"
                  name="address"
                  value={initialValues.address}
                  label="Address Line 1"
                  autofocus
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <StyledInput
                  as={AccInput}
                  margin="dense"
                  name="address2"
                  value={initialValues.address2}
                  label="Address Line 2"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <StyledInput
                  as={AccInput}
                  margin="dense"
                  name="city"
                  value={initialValues.city}
                  label="City"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <StyledInput
                  as={AccOptionSelect}
                  margin="dense"
                  name="state"
                  value={initialValues.state}
                  label="State"
                  options={US_STATES}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <StyledInput
                  as={AccInput}
                  margin="dense"
                  name="postalCode"
                  value={initialValues.postalCode}
                  label="Zip Code"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <StyledInput
                  as={AccOptionSelect}
                  margin="dense"
                  name="country"
                  value={initialValues.country}
                  label="Country of Residence"
                  options={COUNTRIES}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <StyledInput
                className="display-none-mobile"
                margin="dense"
                as={AccInput}
                name="phone"
                maskedInput
                mask={AccMaskedInputTypes.US_PHONE_NUMBER}
                value={initialValues.phone}
                label="Phone Number"
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>
          </div>
          <div className="column start width-40 m-left-30 width-100-mobile m-0-mobile">
            <Typography
              style={{ marginBottom: 30 }}
              variant="subtitle1"
              className="acc-white bold condensed-lineheight">In order to continue the investment process, please confirm the following statements:
            </Typography>
            <div className="row start-row">
              <Checkbox
                name="isProfileInfoCorrect"
                checked={values.isProfileInfoCorrect}
                onChange={(e) => {
                  setFieldValue("isProfileInfoCorrect", e.target.checked);
                }}
                style={styles.checkbox}
              />
              <Typography
                className="acc-white light"
                variant="subtitle1"
              >I confirm that my Profile Information is correct.</Typography>
            </div>
            {ifRequiresAccreditation &&
              <div className="row start m-top-30">
                <Checkbox
                  name="confirmedAccreditedInvestor"
                  checked={values.confirmedAccreditedInvestor}
                  onChange={(e) => {
                    setFieldValue("confirmedAccreditedInvestor", e.target.checked);
                  }}
                  disableRipple
                  sx={styles.checkbox} />
                <Typography
                  variant="subtitle1"
                  className="acc-white light">
                  <span>
                    I confirm that I am an &quot;accredited investor&quot; as that term is defined in&nbsp;
                    <span style={{ textDecoration: "underline", color: COLORS.green }}>
                      <a
                        href="https://www.ecfr.gov/current/title-17/chapter-II/part-230#230.501"
                        target="_blank"
                        rel="noreferrer">Rule 501 of regulation D
                      </a>
                    </span>
                    &nbsp;promulgated under the Securities Act.
                  </span>
                </Typography>
              </div>
            }
            <div className="row start m-top-30">
              <Checkbox
                name="confirmedDisqualifyingEvent"
                checked={values.confirmedDisqualifyingEvent}
                onChange={(e) => {
                  setFieldValue("confirmedDisqualifyingEvent", e.target.checked);
                }}
                style={styles.checkbox}
              />
              <Typography
                className="acc-white light"
                variant="subtitle1"
              >
                <span>
                  By selecting this box you confirm that you have not been subject to any Regulation D Rule 506(d)
                  &quot;disqualifying event&quot; and are not subject to any proceeding or event that could result in any such
                  disqualifying event. &quot;Disqualifying event&quot; is defined&nbsp;
                  <span style={{ textDecoration: "underline", color: COLORS.green }}>
                    <a
                      href={assetData?.getAsset?.dealType.disqualifyingEventDocumentLocation}
                      target="_blank"
                      rel="noreferrer">here
                    </a>
                  </span>
                </span>
                <span>.</span>
              </Typography>
            </div>
            <div className="row start m-top-30">
              <Checkbox
                name="confirmedInvestmentProcedures"
                checked={values.confirmedInvestmentProcedures}
                onChange={(e) => {
                  setFieldValue("confirmedInvestmentProcedures", e.target.checked);
                }}
                style={styles.checkbox}
              />
              <Typography
                className="acc-white light"
                variant="subtitle1"
              >
                <span>
                  I agree to the&nbsp;
                  <span
                    className="acc-text"
                    style={{ color: COLORS.green }}
                  >access&nbsp;
                  </span>
                  <span style={{ textDecoration: "underline", color: COLORS.green }}>
                    <a
                      href={assetData?.getAsset?.dealType.investmentProceduresDocumentLocation}
                      target="_blank"
                      rel="noreferrer">Investment Procedures
                    </a>
                  </span>
                </span>
              </Typography>
            </div>
          </div>
        </div>
      </>
    </div >
  );
};

const styles = {
  checkbox: {
    color: COLORS.green,
    "&.Mui-checked": {
      color: COLORS.green,
    },
    padding: "0px 10px 0px 0px",
  },
};

const StyledInput = styled(Field)({
  "& .MuiInputBase-root": {
    color: COLORS.white,
  },
  "& label.Mui-focused": {
    color: COLORS.green,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.white,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: COLORS.white,
      color: COLORS.white,
    },
    "&.Mui-focused fieldset": {
      borderColor: COLORS.white,
      color: COLORS.white,
    },
    "&:hover fieldset": {
      borderColor: COLORS.white,
    },
    "& .MuiInputLabel-outlined": {
      color: COLORS.red,
    },
    "& .MuiSelect-iconOutlined": {
      color: COLORS.white,
    },
  },
  "& .MuiFormLabel-root": {
    color: COLORS.green,
  },
});

export default ProfileInformation;