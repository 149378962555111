import gql from "graphql-tag";
import { gql as gqlv2 } from "graphql-request";
import { makeGraphqlRequest } from "../../utils";

export const CREATE_ACCOUNT = gql`
  mutation CreateAccount($input: AccountInput!) {
    createAccount(input: $input) {
      name
      accountId
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($id: String!, $input: AccountInput!) {
    updateAccount(_id: $id, input: $input) {
      id
      name
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($id: String!) {
    deleteAccount(_id: $id) {
      id
      accountId
    }
  }
`;

const GET_ACCOUNT_V2 = gqlv2`
    query GetAccount($accountId: Int!) {
      getAccount(accountId: $accountId) {
        id
        accountId
        name
        status
      }
    }
`;

export const getAccount = (accountId: number) => makeGraphqlRequest(GET_ACCOUNT_V2, { accountId });

const GET_ACCOUNTS_V2 = gqlv2`
  query GetAccounts {
    getAccounts {
      id
      accountId
      name
      status
    }
  }
`;

export const getAccounts = () => makeGraphqlRequest(GET_ACCOUNTS_V2);
