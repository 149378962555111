import { MenuItem, styled, Typography, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PortfolioInvestment from "./components/PortfolioInvestment";
import { QueryKeys, useAssets, useUserAccountTotal, useUserPortfolioInvestments, useWalletFundings, useWalletStatuses } from "../../api/hooks/graphqlHooks";
import { formatCurrency, isNil } from "../../common/utilities";
import { AssetStatus, InvestmentStatus, WalletStatus } from "../../enums";
import AccInput from "../../common/components/AccInput";
import { Investment, UserPortfolioInvestment } from "../../common/types";
import { useQueryClient } from "react-query";
import PerformanceBar from "../../common/components/PerformanceBar";
import PageWrapper from "../../common/components/PageWrapper";
import COLORS from "../../common/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { THEME_BREAKPOINTS } from "../../common/constants";
import AvailableAssetsCard from "../../common/components/AvailableAssetsCard";


const Portfolio: React.FC = () => {
  const [selectedWallet, setSelectedWallet] = useState<number | null>(null);
  const queryClient = useQueryClient();

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const { isFetching: userAccountTotalsFetching } = useUserAccountTotal();
  const {
    isFetching: walletFundingsFetching,
  } = useWalletFundings("", {
    enabled: false,
  });
  const {
    data: getUserPortfolioInvestmentsData,
    isLoading: userPortfolioInvestmentsLoading,
    isFetching: userPortfolioInvestmentsFetching,
  } = useUserPortfolioInvestments({
    refetchOnMount: true,
  });

  const {
    data: data,
    isLoading: assetDataLoading,
    isFetching: assetDataFetching,
  } = useAssets();

  const {
    data: getWalletStatusesData,
    isLoading: getWalletStatusesLoading,
    isFetching: getWalletStatusesFetching,
  } = useWalletStatuses({
    refetchOnMount: true,
  });

  const closedWalletData = getWalletStatusesData?.getWalletStatuses.find((item: any) => item.name === WalletStatus.CLOSED);

  useEffect(() => {
    queryClient.refetchQueries([QueryKeys.WALLET_FUNDINGS]);
    queryClient.removeQueries([QueryKeys.WALLET_FUNDINGS]);
    queryClient.refetchQueries([QueryKeys.USER_ACCOUNT_TOTAL]);
  }, [selectedWallet]);

  const showLoader = (
    assetDataLoading ||
    !data ||
    assetDataFetching ||
    !getUserPortfolioInvestmentsData ||
    userPortfolioInvestmentsLoading ||
    userPortfolioInvestmentsFetching ||
    getWalletStatusesLoading ||
    getWalletStatusesFetching ||
    userAccountTotalsFetching ||
    walletFundingsFetching
  );

  const portfolioInvestmentStatus = (assetStatus: string) => {
    if (assetStatus === AssetStatus.AVAILABLE || assetStatus === AssetStatus.FULLY_SUBSCRIBED) {
      return InvestmentStatus.COMMITTED;
    } else if (assetStatus === AssetStatus.COMPLETED) {
      return InvestmentStatus.COMPLETED;
    }
  };

  const filterInvestmentsByWallet = (data: UserPortfolioInvestment[]) => data?.filter(item => {
    if (item.walletId === selectedWallet || !selectedWallet) {
      return item;
    }
  });

  const accountNotSetup = isNil(getUserPortfolioInvestmentsData?.getUserPortfolioInvestments);
  const accountHasNoInvestments = isNil(getUserPortfolioInvestmentsData?.getUserPortfolioInvestments.map(item => item.investments).flat(1));

  const filteredInvestments = filterInvestmentsByWallet(getUserPortfolioInvestmentsData?.getUserPortfolioInvestments ?? []);

  const renderInvestmentItem = (item: Investment) => (
    <div
      className="width-80 width-100-mobile"
      key={item.id}>
      <PortfolioInvestment
        key={item.id}
        image={item.asset.coverImageLocation}
        title={item.asset.name}
        description={item.asset.summary}
        invested={formatCurrency(item.amount, 2)}
        distributions="$1,398.65"
        targetedMOIC={item.asset.targetedMOIC}
        projectedIRR={item.asset.targetedIRR}
        projectedDistribution={item.asset.targetedAverageCashYield}
        projectedTerm={item.asset.targetedInvestmentPeriod}
        status={portfolioInvestmentStatus(item.asset.assetStatus.name)}
        id={item.investmentId}
        isPreviouslyCommittedInvestment={true}
        targetedFundingDate={item.asset.targetedFundingDate}
        remainingAmount={item.asset.remainingAmount}
        createdAt={item.createdAt}
        committedDate={item.committedDate}
      />
    </div>
  );

  return (
    <PageWrapper
      showLoader={showLoader}
      title="Portfolio"
      subtitle={accountNotSetup || accountHasNoInvestments
        ? "Build a fixed-income portfolio* spread across multiple asset classes with a single investment."
        : "To view your investments, please select an Investment Account"
      }
    >
      <div>
        <div className="m-top-30">
          {accountNotSetup || accountHasNoInvestments
            ? <>
              <div
                className="row"
                style={{ marginLeft: "5px" }}>
                <FontAwesomeIcon
                  color={COLORS.green}
                  icon={faInfoCircle}
                  style={{ fontSize: 13, position: "relative", right: "5px", top: mobileLayout ? "5px" : "10px" }} />
                <Typography
                  variant="h6"
                  className="bold acc-darkblue font-size-mobile-4">
                  Your Portfolio is currently empty as you haven&apos;t made any investments yet. To start investing,
                  please visit the Invest page visible in the menu or browse available opportunities below.
                </Typography>
              </div>
              <br />
              <br />
              <div className="row m-top-30 column-mobile">
                <div className="width-70 m-right-50 width-100-mobile m-top-30-mobile m-0-mobile">
                  <Typography
                    variant="h5"
                    className="bold acc-darkblue">
                    New Available Opportunities*
                  </Typography>
                  <br />
                  <div className="column-mobile">
                    <AvailableAssetsCard />
                  </div>
                </div>
              </div>
            </>
            :
            <div className="width-100">
              <PerformanceBar selectedWalletId={selectedWallet} />
              <StyledSelect
                className="width-100-mobile m-bottom-30"
                style={{ width: "80%" }}
                value={selectedWallet}
                name=""
                label="Please select an Investment Account"
                select
                onChange={(e: any) => setSelectedWallet(Number(e.target.value))}
              >
                <MenuItem
                  // @ts-ignore
                  value={null}
                >
                  All Wallets
                </MenuItem>
                {getUserPortfolioInvestmentsData?.getUserPortfolioInvestments.map(item =>
                  <MenuItem
                    key={item.walletObjectId}
                    value={item.walletId}>
                    {closedWalletData?.id.toString() === item.walletStatusObjectId ? `${item.walletName} (Closed)` : item.walletName}
                  </MenuItem>,
                )}
              </StyledSelect>
              <br />
              <Typography
                variant="h5"
                className="bold acc-darkblue m-top-15"
                style={{ fontSize: "30px" }}>
                Commitments
              </Typography>
              <br />
              {
                isNil(selectedWallet) ?
                  (filteredInvestments
                    .map(item => item.investments)
                    .flat()
                    .filter(item => item.status !== InvestmentStatus.ATTEMPTED)
                    .sort((a, b) => new Date(Number(b.createdAt)).valueOf() - new Date(Number(a.createdAt)).valueOf())
                    .map(item => renderInvestmentItem(item)))
                  :
                  (filteredInvestments
                    .map(item =>
                      isNil(item.investments) && selectedWallet === item.walletId ?
                        <Typography>You haven&apos;t made any investments using this Investment Account!</Typography> :
                        item.investments
                          .filter(item => item.status !== InvestmentStatus.ATTEMPTED)
                          .sort((a, b) => new Date(Number(b.createdAt)).valueOf() - new Date(Number(a.createdAt)).valueOf())
                          .map(item => renderInvestmentItem(item)),
                    ))}
            </div>
          }
        </div>
      </div>
      <div
        className="row width-70 width-100-mobile">
        <span
          style={{ marginTop: 40, fontWeight: 300, fontSize: 10 }}>
          *Investment advisory services are provided by Access Capital Management LLC,
          an investment advisor registered (or expected to be registered) with the Securities and Exchange Commission and
          loan and other non-securities related investment services are provided by Access Key Management LLC.
        </span>
      </div>
    </PageWrapper>
  );
};


export default Portfolio;

const StyledSelect = styled(AccInput)({
  "& label.Mui-focused": {
    color: COLORS.darkBlue,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.green,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      padding: 0,
      borderRadius: 16,
      boxShadow: "0px 3px 6px #00000029",
    },
    "&.Mui-focused fieldset": {
      borderColor: COLORS.green,
    },
  },
  "& .MuiSelect-outlined": {
    borderRadius: 16,
  },
});
