import { Field } from "formik";
import COLORS from "../../../../common/colors";
import { Radio } from "@material-ui/core";
import { styled } from "@mui/material";

export const StyledRadio = styled(Radio)({
  color: COLORS.white,
  "&.Mui-checked": {
    color: COLORS.green,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 22,
  },
});

export const StyledInput = styled(Field)({
  "&::placeholder": { // <----- Add this.
    opacity: 0,
  },
  "& .MuiInputBase-root": {
    color: COLORS.white,
  },
  "& label.Mui-focused": {
    color: COLORS.green,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.white,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: COLORS.white,
      color: COLORS.white,
    },
    "&.Mui-focused fieldset": {
      borderColor: COLORS.white,
      color: COLORS.white,
    },
    "&:hover fieldset": {
      borderColor: COLORS.white,
    },
    "& .MuiInputLabel-outlined": {
      color: COLORS.green,
    },
    "& .MuiSelect-iconOutlined": {
      color: COLORS.white,
    },
  },
  "& .MuiFormLabel-root": {
    color: COLORS.green,
  },
});

export const StyledW9Input = styled(Field)({
  "& label.Mui-focused": {
    color: COLORS.darkGray,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.white,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: COLORS.darkGray,
    },
    "&.Mui-focused fieldset": {
      borderColor: COLORS.darkGray,
      color: COLORS.darkGray,
    },
  },
});