import gql from "graphql-tag";
import { makeGraphqlRequest } from "../../utils";
import { gql as gqlv2 } from "graphql-request";

export const CREATE_FUNDING_REQUEST = gql`
  mutation CreateFundingRequest($input: FundingRequestInput!) {
    createFundingRequest(input: $input) {
      amount
      status
      wallet {
        id
        walletId
        name
        availableAmount
        account {
          name
        }
      }
      fundingRequestType {
        id
        name
      }
      externalAccount {
        id
        lastFour
        bankName
        externalAccountId
        walletObjectId
        displayName
      }
    }
  }
`;

const CREATE_FUNDING_REQUEST_V2 = gqlv2`
  mutation CreateFundingRequest(
    $amount: Float!,
    $fundingRequestTypeName: String!,
    $walletObjectId: String!,
    $externalAccountObjectId: String!
  ) {
    createFundingRequest(input: {
      amount: $amount,
      fundingRequestTypeName: $fundingRequestTypeName,
      walletObjectId: $walletObjectId,
      externalAccountObjectId: $externalAccountObjectId
    }) {
      amount
      wallet {
        walletId
        name
        availableAmount
        account {
          name
        }
      }
      fundingRequestType {
        name
      }
      externalAccount {
        lastFour
        bankName
        externalAccountId
        walletObjectId
        displayName
      }
    }
  }
`;

export interface CreateFundingRequestInput {
  amount: number;
  status?: string;
  walletId?: string;
  walletObjectId?: string;
  fundingRequestTypeObjectId?: string;
  fundingRequestTypeName?: string;
  externalAccountObjectId?: string;
  fundingRequestType?: string;
  movementId?: number;
}

export const createFundingRequest = ({
  amount,
  walletObjectId,
  externalAccountObjectId,
  fundingRequestTypeName,
}: CreateFundingRequestInput) => makeGraphqlRequest(CREATE_FUNDING_REQUEST_V2, {
  amount,
  walletObjectId,
  externalAccountObjectId,
  fundingRequestTypeName,
});


export const UPDATE_FUNDING_REQUEST = gql`
  mutation UpdateFundingRequest($id: String!, $input: FundingRequestInput!) {
    updateFundingRequest(_id: $id, input: $input) {
      id
      amount
      wallet {
        walletId
        name
        availableAmount
        account {
          name
        }
      }
      fundingRequestType {
        name
      }
      externalAccount {
        lastFour
        bankName
        externalAccountId
        walletObjectId
        displayName
      }
    }
  }
`;

const UPDATE_FUNDING_REQUEST_V2 = gqlv2`
  mutation UpdateFundingRequest($id: String!, $input: FundingRequestInput!) {
    updateFundingRequest(_id: $id, input: $input) {
      id
      amount
      wallet {
        walletId
        name
        availableAmount
        account {
          name
        }
      }
      fundingRequestType {
        name
      }
      externalAccount {
        lastFour
        bankName
        externalAccountId
        walletObjectId
        displayName
      }
    }
  }
`;

export interface UpdateFundingRequestInput {
  amount: number;
  movementId?: number;
  status?: string;
  fundingRequestType?: string;
  walletId?: string;
  externalAccountId?: string;
  walletObjectId?: string;
  fundingRequestTypeObjectId?: string;
  fundingRequestTypeName?: string;
  externalAccountObjectId?: string;
}

export const updateFundingRequest = ({
  amount,
  fundingRequestType,
  fundingRequestTypeName,
  walletId,
  externalAccountId,
}: UpdateFundingRequestInput) => makeGraphqlRequest(UPDATE_FUNDING_REQUEST_V2, {
  amount,
  fundingRequestType,
  fundingRequestTypeName,
  walletId,
  externalAccountId,
});


export const DELETE_FUNDING_REQUEST = gql`
  mutation DeleteFundingRequest($id: String!) {
    deleteFundingRequest(_id: $id) {
      id
      fundingRequestId
    }
  }
`;

export const GET_FUNDING_REQUEST_V2 = gqlv2`
  query GetFundingRequest($fundingRequestId: Int!) {
    getFundingRequest(fundingRequestId: $fundingRequestId) {
      id
      amount
      status
      movementId
      fundingRequestId
      wallet {
        id
        walletId
        name
        availableAmount
        account {
          name
        }
      }
      fundingRequestType {
        id
        name
      }
      externalAccount {
        id
        lastFour
        bankName
        externalAccountId
        walletObjectId
        displayName
      }
    }
  }
`;

export const getFundingRequest = (fundingRequestId: number) =>
  makeGraphqlRequest(GET_FUNDING_REQUEST_V2, { fundingRequestId });

export const GET_FUNDING_REQUESTS_V2 = gqlv2`
  query GetFundingRequests {
    getFundingRequests {
      id
      amount
      status
      movementId
      fundingRequestId
      wallet {
        id
        walletId
        name
        availableAmount
        account {
          name
        }
      }
      fundingRequestType {
        id
        name
      }
      externalAccount {
        id
        lastFour
        bankName
        externalAccountId
        walletObjectId
        displayName
      }
    }
  }
`;

export const getFundingRequests = () => makeGraphqlRequest(GET_FUNDING_REQUESTS_V2);
