import { Typography } from "@material-ui/core";
import React from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";

interface Props {
  value: string | number | null | undefined;
  description: string | number | JSX.Element;
  className: string;
  containerClassName?: string;
  alignment: "start" | "center" | "end";
  containerStyles?: React.CSSProperties;
  inverted?: boolean;
  descriptionStyles?: React.CSSProperties;
  valueStyles?: React.CSSProperties;
  withIcon?: boolean;
}

const LabeledFigure: React.FC<Props> = ({
  value, description, className, containerClassName, alignment, containerStyles, inverted, descriptionStyles, valueStyles, withIcon,
}) => (
  <div
    className={`column${inverted ? "-reverse" : ""} ${alignment} ${containerClassName || ""}`}
    style={{ ...containerStyles }}>
    <Typography
      className={`labeled-figure-value ${className}`}
      variant="h4"
      style={{ fontWeight: "bold", ...valueStyles }}
    >
      {value}
    </Typography>
    <Typography
      className="acc-lightgray"
      variant="caption"
      style={{ textAlign: "center", fontWeight: 300, ...descriptionStyles }}
    >
      {description}
      {withIcon && <FontAwesomeIcon
        icon={faInfoCircle}
        className="acc-blue"
        style={{ marginBottom: 5, marginLeft: 3, fontSize: 10 }}
      />}
    </Typography>
  </div>
);

export default LabeledFigure;
