import React, { useState, useEffect, useContext } from "react";
import { Typography } from "@material-ui/core";
import COLORS from "../../common/colors";
import FilesDragAndDrop from "../../common/components/FilesDragAndDrop";
import { updateAccreditationVerificationProcess } from "../../api/rest/northCapital";
import { standardErrorHandle } from "../../common/utilities";
import PageTitle from "../../common/components/PageTitle";
import AccButton, { AccButtonColors } from "../../common/components/AccButton";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useAccreditedInvestorRequest } from "../../api/hooks/graphqlHooks";
import PageCard from "../../common/components/PageCard";
import LoadingAnimation from "../../common/components/LoadingAnimation";
import Checkbox from "@mui/material/Checkbox";
import { SessionContext } from "../../context";

type NeedMoreInfoParams = {
  id: string;
};

type NeedMoreInfoProps = RouteComponentProps<NeedMoreInfoParams>;

const NeedMoreInfo: React.FC<NeedMoreInfoProps> = ({ match, history }) => {

  const {
    data: accreditedInvestorRequestData,
  } = useAccreditedInvestorRequest((match.params.id), {
    refetchOnMount: true,
    enabled: true,
  });
  const [checkedEConsent, setCheckedEConsent] = useState<boolean>(false);
  const [file, setFile] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFileAttached, setIsFileAttached] = useState<boolean>(false);
  const { user: sessionUser } = useContext(SessionContext);

  useEffect(() => {
    (file?.name && file?.name.length !== 0) ? setIsFileAttached(true) : setIsFileAttached(false);
  }, [file]);

  const redirectToWallets = async () => {
    history.push("/investor/accounts");
  };

  const submitAccreditationUpdate = async () => {
    setIsSubmitting(true);
    try {
      var data = new FormData();
      data.append("file", file);
      data.append("accountId", accreditedInvestorRequestData?.getAccreditedInvestorRequest?.northCapitalAccountId!);
      data.append("documentTitle", file.name);
      data.append("aiMethod", "Upload");
      data.append("airequestId", accreditedInvestorRequestData?.getAccreditedInvestorRequest?.airequestId!);
      data.append("aiRequestStatus", "New Info Added");
      data.append("user", sessionUser);
      const updateResponse = await updateAccreditationVerificationProcess(data);
      if (updateResponse?.data.success) {
        redirectToWallets();
      }
    } catch (err) {
      standardErrorHandle(err, "Error submitting onboard");
    }
    setIsSubmitting(false);
  };

  if (isSubmitting) {
    return <LoadingAnimation containerStyles={{ marginTop: "100px", height: "500px" }} />;
  }
  return (
    <div>
      <PageTitle
        title="Update Accreditation Verification Request"
        subtitle="You need to be an accredited investor to be able to invest on the access platform"
      />
      <br />
      <div className="width-100 m-top-30 stretch">
        <div className="width-60">
          <Typography
            variant="caption"
            className="acc-gray">
            Due to securities regulations, we are required to verify your accredited investor status.
            Please upload the following document(s) to complete the verification:
          </Typography>
          <PageCard
            className="width-100 m-bottom-15">
            <Typography>
              {`${accreditedInvestorRequestData?.getAccreditedInvestorRequest?.notes || "Required document not specified"}`}
            </Typography>
          </PageCard>
        </div>
        <div className="column width-60">
          <div className="width-100">
            <FilesDragAndDrop
              updateFileList={setFile} />
          </div>
        </div>
      </div>
      <div
        className="row start m-top-30"
        style={{ justifyContent: "flex-end" }}
      >
      </div>
    <div
        className="row start m-top-30"
        style={{ padding: 25 }}
    >
        <Checkbox
          checked={checkedEConsent}
            onChange={() => {
              setCheckedEConsent(!checkedEConsent);
            }}
            disableRipple
            sx={styles.checkbox} />
        <Typography
            variant="subtitle2"
            className="font-size-mobile-5">
            Do you agree to eConsent to the terms & conditions, privacy policy,
            triparty, agreements and electronic records?
        </Typography>
    </div>
      <div
        style={styles.buttonContainer as React.CSSProperties}>
        <AccButton
          color={AccButtonColors.GREEN}
          label="Update Accreditation"
          className={isFileAttached && checkedEConsent ? "" : "no-pointer"}
          onClick={() => submitAccreditationUpdate()}
          style={styles.nextButton}
        />
      </div>
    </div>
  );
};

const styles = {
  radioButton: {
    color: "#1BD0AE",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: 30,
  },
  checkbox: {
    padding: "0px 10px 0px 0px",
    color: COLORS.lightGray,
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
    "&.Mui-checked": {
      color: "#00EEC1",
    },
    "&.MuiIcon-text": {
      fill: "blue",
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: "30px",
  },
  backButton: {
    padding: "5px 25px 5px 25px",
    borderRadius: "16px",
    color: COLORS.white,
    textTransform: "none",
  },
  nextButton: {
    width: "fit-content",
    marginLeft: 15,
    border: "1px solid #ffffff80",
  },
};

export default withRouter(NeedMoreInfo);