import { styled, Typography, useMediaQuery } from "@material-ui/core";
import { Field, Form, Formik, FormikValues } from "formik";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import COLORS from "../../../common/colors";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import AccInput from "../../../common/components/AccInput";
import { isNil } from "../../../common/utilities";
import { checkEmailCode, resetPhone, validateMfaCode } from "../../../api/rest/user";
import { AuthContext, SessionContext } from "../../../context";
import LoadingAnimation from "../../../common/components/LoadingAnimation";
import { QueryKeys } from "../../../api/hooks/graphqlHooks";
import { useQueryClient } from "react-query";
import { AuthActions } from "../../../App";
import { THEME_BREAKPOINTS } from "../../../common/constants";

interface Props {
  setShowResetPhoneNumber: (arg: boolean) => void;
}

const ResetPhoneNumber: React.FC<Props> = ({ setShowResetPhoneNumber }) => {

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  const { user: sessionUser } = useContext(SessionContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState();
  const [checkingPhoneCode, setCheckingPhoneCode] = useState(false);
  const queryClient = useQueryClient();
  const dispatch = useContext(AuthContext);
  const [mfaRequestStatus, setMfaRequestStatus] = useState({
    error: false,
    sent: false,
    loading: false,
  });

  const validateMfaCodeRequest = async (mfaCodeInput: any) => {
    setMfaRequestStatus({
      ...mfaRequestStatus,
      loading: true,
    });
    const mfaResponse = await validateMfaCode(mfaCodeInput);
    setMfaRequestStatus({
      ...mfaRequestStatus,
      loading: false,
    });
    if (mfaResponse.data?.verified === true) {
      const { expiresAt, sessionId } = mfaResponse.data.session;
      dispatch({
        type: AuthActions.MFA_VERIFIED,
        mfaSessionId: sessionId,
        mfaExpiresAt: expiresAt,
      });
      setMfaRequestStatus({
        error: false,
        sent: true,
        loading: false,
      });
      return true;
    } else {
      setMfaRequestStatus({
        error: true,
        sent: false,
        loading: false,
      });
    }
    setMfaRequestStatus({
      ...mfaRequestStatus,
      loading: false,
    });
  };

  const checkNewPhoneNumber = async (values: FormikValues) => {
    setIsSubmitting(true);
    try {
      const response = await checkEmailCode(sessionUser?.email, values.emailCode, values.newPhoneNumber);
      if (response?.data?.success === true) {
        setCheckingPhoneCode(true);
        setNewPhoneNumber(values.newPhoneNumber);
      } else {
        toast(
          response?.data.msg || "There was an error updating user password.", {
          type: toast.TYPE.ERROR,
        });
      }
    } catch (err: any) {
      toast(
        err.message || err.msg || err.toString() || "There was an error updating user password.", {
        type: toast.TYPE.ERROR,
      });
    }
    setIsSubmitting(false);
  };

  const resetPhoneNumber = async (values: FormikValues) => {
    setIsSubmitting(true);
    try {
      const validateMfa = await validateMfaCodeRequest(values.phoneCode.toUpperCase());
      if (validateMfa) {
        const response = await resetPhone(sessionUser, newPhoneNumber!);
        if (response?.data?.success === true) {
          setIsSubmitting(false);
          setShowResetPhoneNumber(false);
          queryClient.refetchQueries([QueryKeys.USER_FOR_ADMIN]);
        }
      }
    } catch (err: any) {
      console.log(err);
    }
    setIsSubmitting(false);
  };

  if (isSubmitting) {
    return <LoadingAnimation containerStyles={{ marginTop: "0px", height: "600px" }} />;
  }

    return (
      !checkingPhoneCode ?
        < Formik
          initialValues={{
            emailCode: "",
            newPhoneNumber: "",
          }
          }
          onSubmit={checkNewPhoneNumber}
        >
          {({ values }) => (
            <Form
              style={{ padding: mobileLayout ? "" : "100px" }} 
            >
              <div>
                <Typography
                  variant="h4"
                  className="acc-white bold">Update your phone number</Typography>
                <br />
                <Typography
                  variant="subtitle1"
                  className="acc-white">Type the code sent to your email</Typography>
                <StyledInput
                  as={AccInput}
                  margin="normal"
                  fullWidth
                  name="emailCode"
                  placeholder="Email Code"
                  type="text"
                />
                <Typography
                  variant="subtitle1"
                  className="acc-white m-top-15">Type your new phone number</Typography>
                <StyledInput
                  as={AccInput}
                  fullWidth
                  type="text"
                  name="newPhoneNumber"
                  placeholder="New Phone Number"
                />
              </div>
              <div className="width-100 column end">
                <AccButton
                  type="submit"
                  width="fit-content"
                  style={{
                    width: "fit-content",
                    pointerEvents: isNil(values.emailCode && values.newPhoneNumber) ? "none" : "",
                  }}
                  className="m-top-30"
                  label="Submit New Phone Number"
                  color={isNil(values.emailCode && values.newPhoneNumber)
                    ? AccButtonColors.GREEN_OUTLINE : AccButtonColors.GREEN} />
              </div>
            </Form>
          )}
        </Formik >
        : <Formik
          initialValues={{
            phoneCode: "",
          }}
          onSubmit={resetPhoneNumber}
        >
          {({ values }) => (
            <Form
              style={{ padding: "100px", height: "600px" }}
            >
              <div>
                <Typography
                  variant="h4"
                  className="acc-white bold">Update your phone number</Typography>
                <br />
                <Typography
                  variant="subtitle1"
                  className="acc-white">Type the code sent to your new phone number</Typography>
                <StyledInput
                  as={AccInput}
                  margin="normal"
                  fullWidth
                  name="phoneCode"
                  placeholder="Phone Code"
                  type="text"
                />
              </div>
              <div className="width-100 column end">
                <AccButton
                  type="submit"
                  width="fit-content"
                  style={{
                    pointerEvents: isNil(values.phoneCode) ? "none" : "",
                  }}
                  className="m-top-30"
                  label="Submit Code"
                  color={isNil(values.phoneCode)
                    ? AccButtonColors.GREEN_OUTLINE : AccButtonColors.GREEN} />
              </div>
            </Form>
          )}
        </Formik >);
};


const StyledInput = styled(Field)({
  "& .MuiInputBase-root": {
    color: COLORS.white,
  },
  "& label.Mui-focused": {
    color: COLORS.green,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.green,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: COLORS.white,
      color: COLORS.white,
    },
    "&.Mui-focused fieldset": {
      borderColor: COLORS.white,
      color: COLORS.white,
    },
    "&:hover fieldset": {
      borderColor: COLORS.white,
    },
    "& .MuiInputLabel-outlined": {
      color: COLORS.green,
    },
    "& .MuiSelect-iconOutlined": {
      color: COLORS.white,
    },
  },
  "& .MuiFormLabel-root": {
    color: COLORS.green,
  },
  "& .MuiOutlinedInput-input:-webkit-autofill": {
    "-webkit-box-shadow": " 0 0 0 30px #021b38 inset",
    "-webkit-text-fill-color": "#fff",
  },
});

export default ResetPhoneNumber;