import { config } from "../../../common/config";
import { Account, PendingOnboardApplication, User, Wallet } from "../../../common/types";
import { standardErrorHandle } from "../../../common/utilities";
import { UMBCommercialFormValues, UMBFormValues } from "../../../screens/Onboard/umb/common/umb.interfaces";
import restClient from "../restClient";

async function getUMBAccount(umbAccountId: string): Promise<any> {
  try {
    const umbAccountResponse = await restClient.get(`${config.apiHost}/umb/account?accountId=${umbAccountId}`, {
    });
    if (umbAccountResponse?.data?.success === true) {
      return umbAccountResponse.data.data;
    } else if (umbAccountResponse?.data?.success === false) {
      console.log("Error getting UMB account!");
    }

  } catch (err: any) {
    standardErrorHandle(err, "Error getting account balances");
  }
}

async function getListArtifacts(
  umbAccountId: string,
  startDate: string,
  endDate: string,
): Promise<any> {
  try {
    const response = await restClient({
      url: `${config.apiHost}/umb/statements`,
      method: "GET",
      params: {
        umbAccountId: `${umbAccountId}`,
        startDate: `${startDate}`,
        endDate: `${endDate}`,
      },
    });
    if (response.data?.success === true) {
      return response.data?.data;
    } else if (response?.data?.success === false) {
      throw new Error(response?.data?.message);
    }
  } catch (err: any) {
    standardErrorHandle(err, "Error getting list artifacts!");
  }
}

async function getArtifact(artifactId: string): Promise<any> {
  try {
    const response = await restClient.get(`${config.apiHost}/umb/statement?id=${artifactId}`, {
    });
    if (response.data?.success) {
      return response.data?.data;
    } else if (response?.data?.success === false) {
      throw new Error(response?.data?.message);
    }

  } catch (err: any) {
    standardErrorHandle(err, "Error getting artifact!");
  }
}

async function commercialOnboard(formValues: UMBCommercialFormValues): Promise<any> {
  try {
    const formData = new FormData();
    for (let i = 0; i < formValues.files.length; i++) {
      formData.append("files", formValues.files[i]);
    }
    formData.append("payload", JSON.stringify(formValues));
    const onboardResponse = await restClient({
      url: `${config.apiHost}/umb/commercial-onboard`,
      method: "POST",
      data: formData,
    });

    return onboardResponse;
  } catch (err: any) {
    standardErrorHandle(err, "Error getting artifact!");
  }
}

export interface JointApplicantInfo {
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  email: string;
}

async function onboardUMBCustomer({
  questionSetAnswers,
  walletName,
  walletType,
  existingEntity,
  jointApplicantInfo,
}: {
  questionSetAnswers: Partial<UMBFormValues>,
  walletName?: string | null,
  walletType?: string | null,
  existingEntity?: string | null,
  jointApplicantInfo?: JointApplicantInfo,
}): Promise<{
  success: boolean; data: {
    onboardResponse?: {
      newWallet: Wallet;
    };
    account?: Account;
    token?: string;
    entityName?: string;
    newJointUser?: User;
    newJointApplication?: PendingOnboardApplication;
    pendingOnboardApplication?: PendingOnboardApplication;
  }
} | undefined> {
  try {
    const onboardCustomerResponse = await restClient.post("/umb/validate-set", {
      questionSetAnswers,
      walletName,
      walletType,
      existingEntity,
      jointApplicantInfo,
    });
    return onboardCustomerResponse.data;

  } catch (err: any) {
    standardErrorHandle(err, "Error getting account balances");
    throw new Error(err);
  }
}


export {
  getUMBAccount,
  onboardUMBCustomer,
  getListArtifacts,
  getArtifact,
  commercialOnboard,
};
