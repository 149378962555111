/*eslint-disable */
export enum AccountTypes {
  INDIVIDUAL = "INDIVIDUAL",
  ENTITY = "ENTITY"
}

export enum UserTypes {
  INVESTOR = "INVESTOR",
  ADVISOR = "ADVISOR",
}
export enum VerticalTypes {
  REAL_ESTATE = 'REAL_ESTATE',
  TRANSPORTATION = 'TRANSPORTATION',
  SPORTS_ENTERTAINMENT = 'SPORTS_ENTERTAINMENT',
  INFRASTRUCTURE = 'INFRASTRUCTURE'
}

export enum YesNo {
  YES = 'YES',
  NO = 'NO'
}

export enum IncomeQuestions {
  INCOME_OVER_200K = 'incomeOver200k',
  IS_MARRIED = 'isMarried',
  NET_WORTH_OVER_1M = 'netWorthOver1m',
  INVESTMENT_ENTITY_OVER_5M = 'investmentEntityOver5m',
  JOINT_INCOME_OVER_300K = 'jointIncomeOver300k'
}

export enum UMBQuestionSetDisplayNames {
  CIP = 'CIP',
  CDD = 'CDD',
  W9 = 'W9',
  ECNSENT = 'ECONSENT'
}

export enum UserStatuses {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}