import { useMutation } from "@apollo/client";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik, Form, Field, FormikValues } from "formik";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DELETE_VERTICAL, UPDATE_VERTICAL, CREATE_VERTICAL } from "../../../api/query/Verticals";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import CancelConfirmDialogModal from "../../../common/components/CancelConfirmDialogModal";
import { isNil } from "../../../common/utilities";
import { EditModeTypes } from "../../../enums";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import COLORS from "../../../common/colors";
import { useVertical, QueryKeys } from "../../../api/hooks/graphqlHooks";
import { useQueryClient } from "react-query";
import AccInput from "../../../common/components/AccInput";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";
import PageWrapper from "../../../common/components/PageWrapper";

type VerticalDetailParams = {
  id?: string;
};

type VerticalDetailProps = RouteComponentProps<VerticalDetailParams>;

const VerticalForm: React.FC<VerticalDetailProps> = withErrorBoundary({}, ({ match, history }: VerticalDetailProps) => {
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const queryClient = useQueryClient();
  const editMode: EditModeTypes = isNil(match.params.id) ? EditModeTypes.ADD : EditModeTypes.EDIT;
  if (editMode === EditModeTypes.ADD) {
    queryClient.removeQueries(QueryKeys.VERTICAL);
  }
  const {
    data: getVerticalData,
    isLoading: getVerticalLoading,
    isError: getVerticalError,
  } = useVertical(Number(match.params.id), {
    enabled: editMode === EditModeTypes.EDIT,
    refetchOnMount: true,
  });

  const [createVertical] = useMutation(CREATE_VERTICAL);
  const [deleteVertical] = useMutation(DELETE_VERTICAL);
  const [updateVertical] = useMutation(UPDATE_VERTICAL);

  const [cancelDialogVisible, setCancelDialogVisibible] = useState(false);

  const openCancelDialog = () => setCancelDialogVisibible(true);
  const closeCancelDialog = () => setCancelDialogVisibible(false);

  const BLANK_FORM_VALUES = {
    name: "",
  };

  const initialFormFieldValues = () => (getVerticalData?.getVertical ? {
    name: getVerticalData?.getVertical.name,
  } : BLANK_FORM_VALUES);

  const handleDelete = async (objectId: string | number) => {
    try {
      await deleteVertical({
        variables: {
          id: objectId,
        },
      });
      toast("Vertical deleted successfully!", {
        type: toast.TYPE.SUCCESS,
      });
    } catch (err: any) {
      toast(err.message || err.msg || err.toString() || "There was an error deleting vertical.", {
        type: toast.TYPE.WARNING,
      });
    }
    queryClient.refetchQueries(QueryKeys.VERTICALS);
    history.push("/admin/verticals");
  };

  const submitForm = async (values: FormikValues, { resetForm }: any) => {
    if (editMode === EditModeTypes.ADD) {
      try {
        await createVertical({
          variables: {
            input: {
              name: values.name,
            },
          },
        });
        toast("Vertical added successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        resetForm();
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error adding vertical.", {
          type: toast.TYPE.ERROR,
        });
      }
    } else if (editMode === EditModeTypes.EDIT) {
      try {
        await updateVertical({
          variables: {
            id: getVerticalData?.getVertical.id,
            input: {
              name: values.name,
            },
          },
        });
        toast("Vertical updated successfully!", {
          type: toast.TYPE.SUCCESS,
        });
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error updating vertical.", {
          type: toast.TYPE.ERROR,
        });
      }
    }
    queryClient.refetchQueries(QueryKeys.VERTICALS);
    history.push("/admin/verticals");
  };

  return (
    <PageWrapper
      title={`${editMode === EditModeTypes.ADD ? "Add" : "Update"} Vertical`}
      showLoader={getVerticalLoading}
      showError={getVerticalError}
      errorMessage="Cannot retrieve verticals"
    >
      <br />
      <div>
        <Formik
          initialValues={initialFormFieldValues()}
          enableReinitialize
          onSubmit={submitForm}
        >
          {/*eslint-disable */}
          <Form>
            <div className="column-reverse-mobile">
              <div className="row stretch center width-100 column-mobile">
                <Link to="/admin/verticals" className="width-100-mobile">
                  <AccButton
                    color={AccButtonColors.WHITE}
                    label="Cancel"
                    style={{ backgroundColor: COLORS.white }}
                  />
                </Link>
                <div className="row width-100-mobile column-mobile">
                  <AccButton
                    color={AccButtonColors.GREEN}
                    type="submit"
                    label={editMode === EditModeTypes.ADD ? 'Submit' : 'Update'}
                    className="m-top-15-mobile m-bottom-15-mobile"
                  />
                  {editMode === EditModeTypes.EDIT
                    ? (
                      <>
                        <AccButton
                          color={AccButtonColors.RED_OUTLINE}
                          label="Delete"
                          className="delete-button m-left-15 display-none-mobile"
                          type="button"
                          onClick={openCancelDialog}
                        />
                        {mobileLayout
                          && (
                            <AccButton
                              color={AccButtonColors.RED_OUTLINE}
                              label="Delete Vertical"
                              className="delete-button"
                              type="button"
                              width="40%"
                              style={styles.mobileDeleteButton}
                              onClick={openCancelDialog}
                            />
                          )}
                      </>
                    )
                    : null}
                  {getVerticalData ? <CancelConfirmDialogModal
                    isVisible={cancelDialogVisible}
                    dialogBody="Are you sure you would like to delete this Vertical?"
                    onClickCancel={closeCancelDialog}
                    onClickDelete={() => handleDelete(getVerticalData?.getVertical.id)}
                  /> : null}
                </div>
              </div>
              <br />
              <div style={styles.border} className="width-60 width-100-mobile">
                <Field
                  name="name"
                  type="text"
                  label="Vertical Name"
                  as={AccInput}
                  required
                />
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </PageWrapper>
  );
});

const styles = {
  border: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '16px',
    padding: '15px',
  },
  mobileDeleteButton: {
    alignSelf: 'flex-start',
    border: 'none',
    textAlign: 'left',
    padding: 0,
    height: 20,
  },
};

export default withRouter(VerticalForm);
