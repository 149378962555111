import React, { useState } from "react";
import COLORS from "../../colors";
import Divider from "@mui/material/Divider";
import { Typography } from "@material-ui/core";
import useMediaQuery from "@mui/material/useMediaQuery";
import { THEME_BREAKPOINTS } from "../../constants";
import Collapse from "@mui/material/Collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";

interface IconTextProps {
  icon: React.ReactNode;
  text: string;
  onClick?: () => void;
  style?: {};
  textStyle?: {},
  className?: string;
}

const IconText: React.FC<IconTextProps> = ({ icon, text, onClick, style, textStyle, className }) => {

  return (
    <div
      onClick={onClick}
      style={style}>
      <Typography
        className={className}
        style={textStyle}>
        {text}
      </Typography>
      {icon}
    </div>
  );
};

interface Props {

}

const PublicFooter: React.FC<Props> = () => {

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const [disclaimerCollapsed, setDisclaimerCollapsed] = useState<boolean>(mobileLayout ? true : false);

  const handleCollapse = () => {
    setDisclaimerCollapsed(!disclaimerCollapsed);
  };

  return (
    <div
      style={{ ...styles.container, textAlign: mobileLayout ? "center" : "left" }}
    >
      <Divider sx={styles.divider} />
      <div>
        <Typography
          variant="subtitle2"
          className="acc-white">
          Disclaimer
        </Typography>
        <>
          <Collapse
            in={mobileLayout ? disclaimerCollapsed : !disclaimerCollapsed}
            collapsedSize={disclaimerCollapsed ? "100%" : "140px"}>
            {disclaimerText.map(item => (
              <Typography
                key={item.line}
                style={styles.text as React.CSSProperties}
                className="acc-white light">
                {item.text}
              </Typography>
            ))}
          </Collapse>
          {mobileLayout &&
            <IconText
              icon={<FontAwesomeIcon
                icon={disclaimerCollapsed ? faMinus : faPlus}
                style={{ color: COLORS.green, cursor: "pointer" }} />}
              text="Read full disclaimer"
              onClick={handleCollapse}
              textStyle={styles.fullDisclaimer as React.CSSProperties}
              style={{ marginTop: 15 }}
            />}
        </>
      </div>
    </div>
  );
};

const styles = {
  container: {
    marginTop: 50,
    padding: "70px 13%",
    // background: COLORS.darkBlueGradient,
  },
  divider: {
    border: "solid 0.85px #F5F5F5",
    backgroundColor: "#F5F5F5",
    margin: "50px 0px",
  },
  text: {
    fontSize: "12px",
    margin: "15px 0px",
    textAlign: "justify",
  },
  copyright: {
    margin: "50px auto",
    textAlign: "center",
    fontWeight: 500,
  },
  logo: {
    height: 40,
  },
  fullDisclaimer: {
    fontSize: "10px",
    color: COLORS.white,
  },
};

const disclaimerText = [
  {
    line: 1,
    text: "THE SECURITIES OFFERED HEREBY HAVE NOT BEEN REGISTERED UNDER THE 1933 ACT, OR THE SECURITIES LAWS OF ANY STATE AND ARE BEING OFFERED IN RELIANCE UPON CERTAIN EXEMPTIONS FROM REGISTRATION UNDER SUCH LAWS. SUCH EXEMPTIONS IMPOSE SUBSTANTIAL RESTRICTIONS ON THE SUBSEQUENT TRANSFER OF SECURITIES SUCH THAT AN INVESTOR HEREIN MAY NOT SUBSEQUENTLY RESELL THE SECURITIES OFFERED HEREIN UNLESS THE SECURITIES ARE SUBSEQUENTLY REGISTERED UNDER APPLICABLE FEDERAL AND STATE SECURITIES LAWS OR AN EXEMPTION FROM SUCH REGISTRATION IS AVAILABLE.",
  },
  {
    line: 2,
    text: "NEITHER THE UNITED STATES SECURITIES AND EXCHANGE COMMISSION (“SEC”) NOR ANY STATE SECURITIES ADMINISTRATOR HAS APPROVED OR DISAPPROVED THE SECURITIES OFFERED HEREIN NOR HAS THE SEC OR ANY STATE SECURITIES ADMINISTRATOR PASSED UPON THE ADEQUACY OR ACCURACY OF THE DISCLOSURES CONTAINED IN ANY OFFERING MATERIALS OR THE MERITS OF AN INVESTMENT IN THE SECURITIES OFFERED THEREBY. ANY REPRESENTATION TO THE CONTRARY IS A CRIMINAL OFFENSE.",
  },
  {
    line: 3,
    text: "THE SECURITIES OFFERED HEREBY ARE HIGHLY SPECULATIVE, AND AN INVESTMENT IN ANY OF OUR OFFERINGS INVOLVES A HIGH DEGREE OF RISK. AN INVESTOR MUST BEAR THE ECONOMIC RISK OF INVESTMENT IN THE SECURITIES OFFERED HEREIN. EACH PROSPECTIVE INVESTOR SHOULD CONSULT HIS/HER OWN ATTORNEY, BUSINESS ADVISER, OR TAX ADVISER CONCERNING LEGAL, BUSINESS, TAX AND RELATED MATTERS RELATING TO AN INVESTMENT.",
  },
  {
    line: 4,
    text: "THE INVESTMENT OFFERINGS CONTAINED HEREIN ARE DIRECTED TO ACCREDITED INVESTORS ONLY.",
  },
  {
    line: 5,
    text: "ANY INVESTMENTS PRESENTED HEREBY DO NOT CONSTITUTE AN OFFER TO SELL OR A SOLICITATION OF AN OFFER TO BUY ANY OF THE SECURITIES OFFERED HEREIN. ALL SUCH INVESTMENTS ARE SUBJECT TO THE OFFERING MATERIALS TO BE MADE AVAILABLE TO INTERESTED INVESTORS. NO SUCH INVESTMENTS WILL BE MADE AVAILABLE TO ANY INVESTOR IN ANY STATE OR OTHER JURISDICTION IN WHICH SUCH AN OFFER OR SOLICITATION IS UNLAWFUL OR UNAUTHORIZED.",
  },
  {
    line: 6,
    text: "ANY SECURITIES ARE OFFERED SUBJECT TO PRIOR SALE. WE RESERVE THE RIGHT, IN OUR DISCRETION, TO WITHDRAW OR MODIFY ANY OFFERING WITHOUT PRIOR NOTICE OR TO REJECT ANY SUBSCRIPTION IN WHOLE OR IN PART OR TO ALLOT TO ANY PROSPECTIVE INVESTOR A LESSER NUMBER OF OFFERED SECURITIES THAN SOUGHT TO BE PURCHASED BY SUCH INVESTOR.",
  },
  {
    line: 7,
    text: "ALL SECURITIES OFFERED HEREBY WILL BE SUBJECT TO A CONFIDENTIAL PRESENTATION, WHICH IS SUBMITTED ON A CONFIDENTIAL BASIS SOLELY IN CONSIDERATION OF THE PURCHASE OF THE SECURITIES DESCRIBED THEREIN IN A PRIVATE PLACEMENT. THE ACCEPTANCE OF A CONFIDENTIAL PRESENTATION CONSTITUTES AN AGREEMENT ON THE PART OF THE RECIPIENT THEREOF AND THE RECIPIENT’S REPRESENTATIVES TO MAINTAIN THE CONFIDENTIALITY OF THE INFORMATION CONTAINED THEREIN. ANY REPRODUCTION OF A CONFIDENTIAL PRESENTATION, IN WHOLE OR IN PART, OR ANY DISCLOSURE OF ITS CONTENTS, IN WHOLE OR IN PART, WITHOUT THE PRIOR WRITTEN CONSENT OF ACCESS IS PROHIBITED. THE USE OF ANY CONFIDENTIAL PRESENTATION FOR ANY PURPOSE OTHER THAN AN INVESTMENT IN THE SECURITIES DESCRIBED THEREIN IS NOT AUTHORIZED AND IS PROHIBITED.",
  },
];

export { PublicFooter, IconText };