import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { Link } from "react-router-dom";
import { useUsers } from "../../../api/hooks/graphqlHooks";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import { THEME_BREAKPOINTS } from "../../../common/constants";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { DataGridStyles, renderEditCell } from "../Assets";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";
import PageWrapper from "../../../common/components/PageWrapper";
import { UserStatuses } from "../../../enums/RegistrationEnums";
import CustomTooltip from "../../../common/components/CustomTooltip";
import COLORS from "../../../common/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";

interface Props {

}

const Users: React.FC<Props> = withErrorBoundary({}, () => {
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const { data, isLoading, isFetching, isError } = useUsers();

  const columns: GridColDef[] = [
    renderEditCell("/admin/users/"),
    {
      field: "status", headerName: "Status", width: 100, hide: mobileLayout, renderCell: (item) => {

        return <CustomTooltip
          title={
            item?.row?.status === UserStatuses.ACTIVE ? (<span>ACTIVE</span>) : (<span>INACTIVE</span>)
          }
          background={COLORS.darkBlue}
          color={COLORS.green}
          placement="right"
          fontSize={16}
          fontWeight={600}>
          <FontAwesomeIcon
            className={item?.row?.status === UserStatuses.ACTIVE ? "acc-green" : "acc-red"}
            icon={faCircle}
            style={{ marginLeft: 20, fontSize: 12 }} />
        </CustomTooltip>;
      },
    },
    { field: "userId", headerName: "User ID", width: 150, hide: mobileLayout },
    { field: "firstName", headerName: "First Name", width: mobileLayout ? 120 : 200 },
    { field: "lastName", headerName: "Last Name", width: mobileLayout ? 120 : 200 },
    { field: "email", headerName: "Email", width: 300, hide: mobileLayout },
    { field: "account", headerName: "Account", width: 300, hide: mobileLayout },
    { field: "userType", headerName: "User Type", width: 200, hide: mobileLayout },
  ];

  const rows = data?.getUsers?.map(row =>
  ({
    ...row,
    firstName: row.firstName,
    lastName: row.lastName,
    email: row.email,
    account: row.account?.name,
    userType: row.userType.name,
    id: row.userId,
    status: row.status,
  }),
  );

  return (
    <PageWrapper
      title="Users"
      showLoader={isLoading || isFetching}
      showError={isError}
      errorMessage="Cannot retrieve users"
    >
      <div style={{ height: "80vh", width: "100%" }}>
        <div className="row-mobile stretch">
          <div style={{ justifyContent: "flex-start" }}>
          </div>
          <div
            className="row width-100"
            style={{ justifyContent: "flex-end" }}>
            <Link to="/admin/users/add">
              <AccButton
                color={AccButtonColors.GREEN}
                label="Add"
              />
            </Link>
          </div>
        </div>
        <br />
        <Divider />
        <br />
        <DataGrid
          rows={rows || []}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            panel: DataGridStyles.panel,
          }}
          sx={DataGridStyles.toolBar}
        />
      </div>
    </PageWrapper>

  );
});

export default Users;
