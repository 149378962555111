import Divider from "@mui/material/Divider";
import React from "react";
import { Link } from "react-router-dom";
import { useAssetStatuses } from "../../../api/hooks/graphqlHooks";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { DataGridStyles, renderEditCell } from "../Assets";
import PageWrapper from "../../../common/components/PageWrapper";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";

interface Props {

}

const AssetStatus: React.FC<Props> = withErrorBoundary({}, () => {
  const { data, isLoading, isFetching, isError } = useAssetStatuses();

  const columns: GridColDef[] = [
    renderEditCell("/admin/assetstatuses/"),
    { field: "name", headerName: "Name", width: 400 },
  ];

  const rows = data?.getAssetStatuses?.map(row =>
  ({
    ...row,
    name: row.name,
    id: row.assetStatusId,
  }),
  );


  return (
    <PageWrapper
      title="Asset Statuses"
      showLoader={isLoading || isFetching}
      showError={isError}
      errorMessage="Cannot retrieve asset statuses"
    >
      <div style={{ height: "80vh", width: "100%" }}>
        <div className="row-mobile stretch">
          <div style={{ justifyContent: "flex-start" }}>
          </div>
          <div
            className="row"
            style={{ justifyContent: "flex-end" }}>
            <Link to="/admin/assetstatuses/add">
              <AccButton
                color={AccButtonColors.GREEN}
                label="Add"
              />
            </Link>
          </div>
        </div>
        <br />
        <Divider />
        <br />
        <DataGrid
          rows={rows || []}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            panel: DataGridStyles.panel,
          }}
          sx={DataGridStyles.toolBar}
        />
      </div>
    </PageWrapper>
  );
});

export default AssetStatus;
