import { Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import COLORS from "../../../../common/colors";
import PageCard from "../../../../common/components/PageCard";
import { THEME_BREAKPOINTS } from "../../../../common/constants";
import { UserPortfolioInvestment, Investment, Vertical } from "../../../../common/types";
import { isNil } from "../../../../common/utilities";

interface Props {
  data: any;
}

const PortfolioAllocations: React.FC<Props> = ({ data }) => {

  //We get an array of all user investments and an array of all user investments verticals
  const portfolioInvestments = data?.getUserPortfolioInvestments?.map((item: UserPortfolioInvestment) => item?.investments).flat(1);
  const portfolioVerticals = portfolioInvestments?.map((item: Investment) => item?.asset?.assetType?.assetClass?.vertical)
    .map((item: Vertical) => item?.name);
  //creates an object with keys(verticals) and values(number of investments made into that particular vertical)
  const reducedVerticals = portfolioVerticals?.reduce((cnt: any, cur: number) => (cnt[cur] = cnt[cur] + 1 || 1, cnt), {});
  const verticalTitles = Object.keys(reducedVerticals);
  //calculates the part of each vertical in the whole and converts it into percentage, which then is passed into the pie chart
  const varticalPercentageValues: Array<string> = [];
  verticalTitles?.forEach((key) => varticalPercentageValues.push(((reducedVerticals[key] / portfolioVerticals.length) * 100).toFixed()));
  const verticalsTitlesValues = Object.entries(reducedVerticals);
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  return (
    <>
      {isNil(portfolioInvestments) || varticalPercentageValues.length < 2 ? null :
        <PageCard
          className="width-100 m-bottom-30"
          style={{
            padding: mobileLayout ? 20 : 25,
            background: COLORS.greenCornerGradient,
            borderRadius: 16,
            height: 335,
            minWidth: mobileLayout ? "" : "360px",
          }}>
          <>
            <Typography
              variant="h6"
              className="acc-white bold"
              style={{ display: "flex", justifyContent: "center" }}>
              Portfolio Allocation
            </Typography>
            <div className={mobileLayout ? "m-top-10 center width-100" : "m-top-30 row center width-100"}>
              <div style={{ position: "relative", left: 0, width: mobileLayout ? "" : 170, height: 170 }}>
                <Doughnut
                  options={{
                    cutoutPercentage: 0,
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                      display: false,
                    },
                    animation: {
                      animateScale: true,
                      animateRotate: true,
                    },
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItem: any, data: any) {
                          let allData = data.datasets[tooltipItem.datasetIndex].data;
                          let tooltipLabel = data.labels[tooltipItem.index];
                          let tooltipData = allData[tooltipItem.index];
                          return tooltipLabel + ": " + tooltipData + "%";
                        },
                      },
                    },
                  }}
                  data={{
                    labels: verticalTitles ?? [],
                    datasets: [
                      {
                        label: "# of Votes",
                        data: varticalPercentageValues ?? [],
                        backgroundColor: ["#32F0C2", "#007AA7", "#003069", "#B9FDED", "#269983"],
                        borderWidth: 2,
                        borderColor: COLORS.darkBlue,
                      },
                    ],
                  }}
                />
              </div>
              <div className={mobileLayout ? "column m-top-10 width-100" : "column start width-60 m-left-15"}>
                {verticalsTitlesValues?.map((item: any) =>
                  <Typography
                    key={item}
                    className="acc-white bold"
                    variant="caption"
                    style={{ marginBottom: 10 }}>
                    {item[0]} <span className="acc-green">{((item[1] / portfolioVerticals.length) * 100).toFixed()}%</span>
                  </Typography>,
                )}</div>
            </div>
          </>
        </PageCard>}
    </>
  );
};

export default PortfolioAllocations;