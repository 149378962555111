import React, { useRef, useEffect, useContext, useState } from "react";
import COLORS from "../../../../../common/colors";
import HelloSign from "hellosign-embedded";
import { getAllTemplates, GetSignedDocumentURLResponse, uploadBackupInvestmentDocument } from "../../../../../api/rest/documents";
import { SignatureContext } from "../../../../../context";
import { isNil } from "../../../../../common/utilities";
import { toast } from "react-toastify";
import { Typography, Divider } from "@material-ui/core";
import { useQueryClient } from "react-query";
import { QueryKeys as GQLQueryKeys } from "../../../../../api/hooks/graphqlHooks";
import { QueryKeys as RESTQueryKeys } from "../../../../../api/hooks/restHooks";
import { Asset } from "../../../../../common/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/pro-light-svg-icons";
import AccButton, { AccButtonColors } from "../../../../../common/components/AccButton";
import FilesDragAndDrop from "../../../../../common/components/FilesDragAndDrop";
import LoadingAnimation from "../../../../../common/components/LoadingAnimation";
import CancelConfirmDialogModal from "../../../../../common/components/CancelConfirmDialogModal";

interface Props {
  showEditInvestmentModal: boolean;
  setShowEditInvestmentModal: (arg: boolean) => void;
}
import useMediaQuery from "@mui/material/useMediaQuery";
import { THEME_BREAKPOINTS } from "../../../../../common/constants";

const SecondStep: React.FC<Props> = ({ showEditInvestmentModal, setShowEditInvestmentModal }) => {
  const { setSignatureData, setNextButtonDisabled, setActiveStep } = useContext(SignatureContext);
  const [documentList, setDocumentList] = useState<string[]>();
  const [file, setFile] = useState<any>();
  const [documentSignatureResponse, setDocumentSignatureResponse] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const embedded = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const assetData: { getAsset?: Asset } | undefined = queryClient.getQueryData(GQLQueryKeys.ASSET);
  const helloSignDocumentData: GetSignedDocumentURLResponse | undefined = queryClient.getQueryData(RESTQueryKeys.HELLO_SIGN_DOCUMENT_URL);
  const laptopLayout = useMediaQuery(THEME_BREAKPOINTS.laptop);

  async function getListOfDocuments() {
    try {
      setIsLoading(true);
      const listOfTemplates = await getAllTemplates();
      if (isNil(listOfTemplates)) {
        return toast("Error getting list of templates!");
      }
      const matchedTemplate = listOfTemplates.find((item) => item.template_id === assetData?.getAsset?.dealType?.helloSignTemplateId);
      const documentsFromMatchedTemplate = matchedTemplate?.documents.map((item) => item.name);
      setDocumentList(documentsFromMatchedTemplate);
      setIsLoading(false);
      await handleSign();
    } catch (err: any) {
      setIsLoading(false);
    }
  }

  async function handleSign() {
    const client = new HelloSign();
    // if (isNil(helloSignDocumentData?.signUrl)) {
    //   return toast("Error retrieving document URL!");
    // }
    client.open(helloSignDocumentData?.signUrl!, {
      testMode: true,
      skipDomainVerification: true,
      clientId: helloSignDocumentData?.clientId,
      container: embedded.current as HTMLDivElement,
    });
    client.once("sign", (data) => {
      let res = data.signatureId;
      setSignatureData(res);
      setDocumentSignatureResponse(res);
      //Commented out for now, not sure if we are going to need it in future
      // setNextButtonDisabled(false);
    });
  }

  const submitDocument = async () => {
    try {
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const uploadResponse = await uploadBackupInvestmentDocument(formData, assetData?.getAsset?.id!);
        if (uploadResponse?.data.success === true && documentSignatureResponse === undefined) {
          setNextButtonDisabled(false);
        }
        return uploadResponse;
      }
    } catch (err: any) {
      toast(err.message || err.msg || err.toString() || "Error uploading a document", {
        type: toast.TYPE.ERROR,
      });
    }
  };

  function downloadDocument(fileURL: string, fileName: string) {
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = fileURL;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  useEffect(() => {
    getListOfDocuments();
  }, []);

  const handleBack = async () => {
    setActiveStep(0);
    setShowEditInvestmentModal(false);
  };


  if (isLoading === true) {
    return <LoadingAnimation containerStyles={{ marginTop: "13%", marginRight: "42%" }} />;
  }

  return (
    <>
      <CancelConfirmDialogModal
        isVisible={showEditInvestmentModal}
        onClickCancel={() => setShowEditInvestmentModal(false)}
        onClickDelete={() => handleBack()}
        dialogHeader="If you go back you will have to enter investment amount again!"
        cancelButtonText="No"
        confirmButtonText="Yes"
        confirmButtonColor={AccButtonColors.GREEN}
        cancelButtonColor={AccButtonColors.BLUE}
        confirmButtonClassName="green-button"
      />
      <div
        className="row column-mobile width-100"
        style={styles.mainContainer}
      >
        <div className="width-40 m-right-30 width-100-mobile m-0-mobile">
          <Typography
            variant="h5"
            className="acc-white bold"
          >
            Subscription Documents for
            <span className="acc-green bold">&nbsp;{assetData?.getAsset?.name}</span>
          </Typography>
          <br />
          <Divider
            variant="middle"
            style={{ width: "95%", margin: 0, backgroundColor: COLORS.lightGray }} />
          <br />
          <br />
          <Typography
            variant="h5"
            className="acc-white bold"
          >
            Document Overview
          </Typography>
          <br />
          {helloSignDocumentData && documentList ? documentList?.map(item =>
            <div
              key={item}
              className="row center m-bottom-15">
              <FontAwesomeIcon
                size="lg"
                className="m-right-15"
                icon={faFilePdf}
                color={COLORS.red}
              />
              <Typography className="acc-white ultralight" >
                {item}
              </Typography>
            </div>,
          ) :
            <div>
              <h3
                className="acc-white ultralight"
              >Please download and sign the document:</h3>
              <br />
              <div
                className="row center m-bottom-15">
                <FontAwesomeIcon
                  size="lg"
                  className="m-right-15"
                  icon={faFilePdf}
                  color={COLORS.red}
                />
                <Typography className="acc-white ultralight" >
                  {assetData?.getAsset?.name}
                </Typography>
              </div>
              <br />
              <AccButton
                color={AccButtonColors.GREEN}
                label="Download document"
                style={{ width: "40%" }}
                onClick={() => downloadDocument(assetData?.getAsset?.backupInvestmentDocumentLocation!, "file")}
              />
            </div>
          }
        </div>
        {helloSignDocumentData && documentList ?
          <div
            className="width-60 width-100-mobile m-top-30-mobile"
            ref={embedded}
            style={{
              ...styles.embeddedDoc, position: laptopLayout ? "initial" : "absolute",
              paddingLeft: laptopLayout ? "" : "310px", paddingRight: laptopLayout ? "" : "40px",
            }}
          ></div>
          :
          <div
            className="column width-50"
          >
            <Typography
              style={{ color: COLORS.white, fontWeight: 600, fontSize: 27 }}>
              Upload signed document
            </Typography>
            <br />
            <Typography
              variant="caption"
              style={{ color: COLORS.white, fontSize: 15 }}
            >
              Please upload signed document to complete the investment
            </Typography>
            <div className="width-100">
              <FilesDragAndDrop
                updateFileList={setFile}
                mainTextClassName="acc-white"
              />
            </div>
            <div
              style={styles.buttonContainer as React.CSSProperties}>
              <AccButton
                color={AccButtonColors.GREEN}
                label="Submit Document"
                className={file ? "" : "no-pointer"}
                type="file"
                accept="document/*"
                name="file"
                id="fileUpload"
                formEncType="multipart/form-data"
                onClick={submitDocument}
                style={styles.nextButton}
              />
            </div>
          </div>
        }
      </div>
    </ >
  );
};

const styles = {
  signButton: {
    padding: "5px 25px 5px 25px",
    borderRadius: "16px",
    color: COLORS.blue,
  },
  embeddedDoc: {
    height: 690,
    right: "105px",
    paddingBottom: "1px",
  },
  mainContainer: {
    width: "100%",
    height: "fit-content",
    minHeight: "580px",
  },
  nextButton: {
    width: "fit-content",
    marginLeft: 15,
    border: "1px solid #ffffff80",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: "30px",
  },
};

export default SecondStep;