import React from "react";
import {
  Route,
} from "react-router-dom";
import Activity from "../screens/Activity";
import InvestmentSteps from "../screens/Invest/pages/InvestmentSteps";
import Portfolio from "../screens/Portfolio";
import Performance from "../screens/Performance";
import Documents from "../screens/Documents";
import Wallet from "../screens/Wallet";
import Dashboard from "../screens/Dashboard";
import Invest from "../screens/Invest";
import OpportunityDetails from "../screens/Invest/pages/OpportunityDetails";
import InvestmentDetails from "../screens/Portfolio/components/InvestmentDetails";
import InvestmentDocuments from "../screens/Portfolio/components/InvestmentDetails/InvestmentDocuments";
import Help from "../screens/Help";
import InvestorAccounts from "../screens/InvestorAccounts";
import AccountDetails from "../screens/InvestorAccounts/AccountDetails";
import Accreditation from "../screens/Onboard/Accreditation";
import ConnectBankAccount from "../screens/Wallet/ConnectBankAccount";
import Profile from "../screens/Profile";
import NeedMoreInfo from "../screens/NeedMoreInfo";
import AccountStatements from "../screens/InvestorAccounts/AccountDetails/AccountStatements";
import { UMBJointOnboard } from "../screens/Onboard/umb/UMBJointOnboard";
import { UMBOnboard } from "../screens/Onboard/umb/UMBOnboard";
import { UMBCommercialOnboard } from "../screens/Onboard/umb/UMBCommercialOnboard";

export const InvestorRoutes = () => {
  return (
    <>
      <Route
        exact
        path={["/", "/investor/dashboard"]}>
        <Dashboard />
      </Route>
      <Route
        exact
        path="/investor">
        <Dashboard />
      </Route>
      <Route
        exact
        path="/investor/onboard">
        <UMBOnboard />
      </Route>
      <Route
        exact
        path="/investor/joint-onboard">
        <UMBJointOnboard />
      </Route>
      <Route
        exact
        path="/investor/commercial-onboard">
        <UMBCommercialOnboard />
      </Route>
      <Route
        exact
        path={["/investor/accreditation", "/investor/accreditation/:id"]}>
        <Accreditation />
      </Route>
      <Route
        exact
        path="/investor/invest">
        <Invest />
      </Route>
      <Route
        exact
        path="/investor/invest/*">
        <Route
          exact
          path="/investor/invest/opportunity/:id">
          <OpportunityDetails />
        </Route>
        <Route
          exact
          path="/investor/invest/steps">
          <InvestmentSteps />
        </Route>
      </Route>
      <Route
        exact
        path="/investor/wallet">
        <Wallet />
      </Route>
      <Route
        exact
        path="/investor/wallet/connectbankaccount/:id">
        <ConnectBankAccount />
      </Route>
      <Route
        exact
        path="/investor/wallet/needMoreInfo/:id">
        <NeedMoreInfo />
      </Route>
      <Route
        exact
        path="/investor/portfolio">
        <Portfolio />
      </Route>
      <Route
        exact
        path="/investor/portfolio/*">
        <Route
          exact
          path="/investor/portfolio/investment/:id">
          <InvestmentDetails />
        </Route>
        <Route
          exact
          path="/investor/portfolio/statements/:id">
          <InvestmentDocuments />
        </Route>
      </Route>
      <Route
        exact
        path="/investor/performance">
        <Performance />
      </Route>
      <Route
        exact
        path="/investor/documents">
        <Documents />
      </Route>
      <Route
        exact
        path="/investor/activity">
        <Activity />
      </Route>
      <Route
        exact
        path="/investor/profile">
        <Profile />
      </Route>
      <Route
        exact
        path="/investor/help">
        <Help />
      </Route>
      <Route
        exact
        path="/investor/accounts">
        <InvestorAccounts />
      </Route>
      <Route
        exact
        path="/investor/accounts/*">
        <Route
          exact
          path="/investor/accounts/onboard">
          <UMBOnboard />
        </Route>
        <Route
          exact
          path="/investor/accounts/joint-onboard">
          <UMBJointOnboard />
        </Route>
        <Route
          exact
          path="/investor/accounts/accountstatements/:id">
          <AccountStatements />
        </Route>
        <Route
          exact
          path="/investor/accounts/accountdetails/:id">
          <AccountDetails />
        </Route>
      </Route>
    </>
  );
};