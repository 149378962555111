import React from "react";

interface Props {
  children: JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
  onClick?: () => void;
  className?: string;
}

const PageCard: React.FC<Props> = ({ children, style, ...props }) => (
  <div
    style={{
      background: "#FFF",
      boxShadow: "0px 0px 60px #00000014",
      borderRadius: 16,
      padding: 25,
      ...style,
    }}
    {...props}
  >
    {children}
  </div>
);

export default PageCard;
