import { faComments, faSignOut, faUser } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useContext, useState, createRef } from "react";
import { Link } from "react-router-dom";
import COLORS from "../../colors";
import { THEME_BREAKPOINTS } from "../../constants";
import { AuthContext } from "../../../context";
import { useClickOutsideHandler } from "../../hooks";
import Grow from "@mui/material/Grow";
import { SessionContext } from "../../../context";
import { UserTypes } from "../../types";
import { useUser } from "../../../api/hooks/graphqlHooks";
import { isNotNil } from "../../utilities";
import { AuthActions } from "../../../App";
import { logOut } from "../../../api/utils";

interface Props {
  link: string;
  icon: FontAwesomeIconProps["icon"];
  title: string;
  onClick?: () => void;
  className?: string;
}

const NavigationItem: React.FC<Props> = ({ link, icon, title, onClick, className }) => {

  return (
    <div
      onClick={onClick}
      style={styles.logOut}
      className={className}
    >
      <Link
        to={link}
        className="row width-100">
        <FontAwesomeIcon
          color={COLORS.green}
          icon={icon}
          style={{ ...styles.navIcon }} />
        <Typography style={{ color: COLORS.darkBlue, fontWeight: 300, marginLeft: 30 }}>{title}</Typography>
      </Link>
    </div>
  );
};

const AvatarMenu: React.FC = () => {

  const { user: sessionUser } = useContext(SessionContext);
  const {
    data: getUserData,
  } = useUser(parseInt(sessionUser?.userId), {
    enabled: isNotNil(sessionUser?.userId),
    refetchOnMount: true,
  });

  const userIsAdmin = sessionUser?.userType?.name === UserTypes.ADMIN || sessionUser?.userType?.name === UserTypes.ASSET_MANAGER;

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

  const avatarRef = createRef<HTMLDivElement>();
  const menuRef = createRef<HTMLDivElement>();

  const dispatch = useContext(AuthContext);

  const [avatarMenuVisible, setAvatarMenuVisible] = useState<boolean>(false);

  const toggleAvatarMenu = () => {
    setAvatarMenuVisible(!avatarMenuVisible);
  };

  useClickOutsideHandler(menuRef, avatarRef, setAvatarMenuVisible);

  return (
    <>
      {!mobileLayout
        && (
          <div
            onClick={toggleAvatarMenu}
            ref={avatarRef}
            style={styles.avatarContainer as React.CSSProperties}
          >
            <Avatar
              className="blue-button"
              variant="circular"
              src={getUserData?.getUser?.avatarImageLocation}
            >
            </Avatar>
          </div>
        )}
      {avatarMenuVisible && (
        <Grow in={avatarMenuVisible}>
          <div
            style={styles.dropDown as React.CSSProperties}
            ref={menuRef}>
            {userIsAdmin ?
              <NavigationItem
                onClick={toggleAvatarMenu}
                link="/admin/profile"
                icon={faUser}
                title="Profile"
                className="m-bottom-15" />
              :
              <>
                <NavigationItem
                  onClick={toggleAvatarMenu}
                  link="/investor/profile"
                  icon={faUser}
                  title="Profile"
                  className="m-bottom-15" />
                <NavigationItem
                  onClick={toggleAvatarMenu}
                  link="/investor/help"
                  icon={faComments}
                  title="Help"
                  className="m-bottom-15" />
              </>
            }
            <NavigationItem
              onClick={async () => {
                await logOut();
                dispatch({ type: AuthActions.SIGN_OUT });
              }}
              link="/"
              icon={faSignOut}
              title="Sign Out"
            />
          </div>
        </Grow>
      )}
    </>
  );
};

const styles = {
  navIcon: {
    marginRight: 0,
    fontSize: 20,
    width: 25,
  },
  dropDown: {
    right: "50px",
    top: "45px",
    height: "fitContent",
    width: "200px",
    zIndex: 2,
    backgroundColor: "white",
    borderRadius: "16px",
    boxShadow: "0px 0px 60px #00000014",
    padding: "20px 0 20px 0",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
  },
  avatarContainer: {
    position: "fixed",
    top: 10,
    right: 15,
    padding: 10,
    cursor: "pointer",
    zIndex: 5,
  },
  logOut: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 15,
    width: "100%",
    zIndex: 10,
    cursor: "pointer",
  },
};

export default AvatarMenu;