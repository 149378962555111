import { faLink, faUserAstronaut, faEnvelope, faFilm } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumbs, Chip, Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import LoadingAnimation from "../../../../common/components/LoadingAnimation";
import PageCard from "../../../../common/components/PageCard";
import OpportunityDetailsBox from "./OpportunityDetailsBox";
import { useAsset } from "../../../../api/hooks/graphqlHooks";
import { Divider } from "@mui/material";
import COLORS from "../../../../common/colors";
import Deal from "./Deal";
import AssetTab from "./Asset";
import AccButton, { AccButtonColors } from "../../../../common/components/AccButton";
import "./styles.css";
import Paul from "../../../../assets/images/investments/Paul_Oechsli.png";
import { AssetStatus } from "../../../../enums";
import TabView from "../../../../common/components/TabView";
import AccessCard from "../../../../common/components/AccessCard";
import { isNotNil } from "../../../../common/utilities";
import { toast } from "react-toastify";
import InvestmentCountdown from "../../../../common/components/InvestmentCountdown";

type AssetDetailParams = {
  id: string;
};

type AssetDetailProps = RouteComponentProps<AssetDetailParams>;

const OpportunityDetails: React.FC<AssetDetailProps> = ({ match }) => {

  const [partnerDescriptionCollapsed, setPartnerDescriptionCollapsed] = useState<boolean>(true);
  const [tabValue, setTabValue] = useState(0);
  const [autoplayEnabled, setAutoplayEnabled] = useState(false);

  const handleScrollDownToVideo = () => {
    const videoRef = document.getElementById("asset-video");
    if (videoRef) {
      videoRef.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleCollapse = () => {
    setPartnerDescriptionCollapsed(!partnerDescriptionCollapsed);
  };

  const { data: assetData, isLoading: assetLoading, isFetching: assetFetching } = useAsset(Number(match.params.id), { refetchOnMount: true });
  const pageLoading = assetLoading || assetFetching || !assetData?.getAsset;
  const doesUserHaveAccreditedWallet = true;
  const tabs = assetData?.getAsset.tabDescriptions.map((assetDescription, i) => {
    return {
      index: i,
      label: assetDescription.title,
      children: <AssetTab content={assetDescription.content} />,
    };
  }) || [];

  const showAdditionalInvestmentData = assetData?.getAsset?.assetStatus.name === AssetStatus.AVAILABLE;


  const fundedPercentage: number | null = pageLoading ? null :
    parseFloat((100 - (assetData?.getAsset.remainingAmount / assetData?.getAsset.totalInvestmentAmount) * 100).toFixed(0));

  if (pageLoading) {
    return (
      <LoadingAnimation />
    );
  }

  return (
    <div>
      <div>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            style={{ textDecoration: "none" }}
            className="acc-lightgray"
            color="inherit"
            to="/investor/invest"
          >
            Invest
          </Link>
          <Typography className="acc-green">{assetData?.getAsset.name}</Typography>
        </Breadcrumbs>
      </div>
      <div
        id="page-container"
        className="row start stretch column-mobile">
        <div
          id="left-section"
          className="width-65 width-100-mobile width-50-tablet m-right-50 m-0-mobile m-0-tablet m-0-mid">
          <div
className="row start column-mobile m-top-30"
style={{ marginBottom: 25 }}>
            <AccessCard
              image={assetData?.getAsset.coverImageLocation}
              vertical={assetData?.getAsset.assetType.assetClass.vertical.name}
              className="m-right-30 m-0-mobile"
            />
            <div className="column start width-100-mobile width-70 m-top-30-mobile">
              <Typography
                variant="h4"
                className="acc-blue bold font-size-mobile-1"
                style={{ marginBottom: 30 }}
              >
                {assetData?.getAsset.name}
              </Typography>
              <Typography
                variant="subtitle1"
                className="acc-darkgray font-size-mobile-5"
                style={{ marginBottom: 30 }}>
                {assetData?.getAsset.summary}
              </Typography>
              <div className="row width-100-mobile">
                {isNotNil(assetData?.getAsset?.videoId) ? <AccButton
                  id="video-button"
                  color={AccButtonColors.BLUE}
                  label={(
                    <>
                      <FontAwesomeIcon
                        icon={faFilm}
                        size="lg" />&nbsp;&nbsp;
                      <span>Play Video</span>
                    </>
                  )}
                  style={{ marginRight: 15 }}
                  onClick={() => {
                    handleScrollDownToVideo();
                    setAutoplayEnabled(true);
                  }}
                /> : null}
                {showAdditionalInvestmentData ?
                  !assetData?.getAsset?.dealType.requiresAccreditation ||
                    (assetData?.getAsset?.dealType.requiresAccreditation && doesUserHaveAccreditedWallet) ?
                    <Link
                      to="/investor/invest/steps">
                      <AccButton
                        style={styles.accessButton}
                        color={AccButtonColors.GREEN}
                        label="access"
                      />
                    </Link> :
                    <AccButton
                      style={styles.accessButton}
                      color={AccButtonColors.GREEN}
                      label="access"
                      onClick={() => toast("In order to invest in you need to complete accreditation verification", {
                        type: toast.TYPE.ERROR,
                      })}
                    />
                  : null}
              </div>
            </div>
          </div>
          <Deal
            data={assetData?.getAsset!}
            autoplayEnabled={autoplayEnabled}
          />
          <TabView
            tabs={tabs}
            tabValue={tabValue}
            setTabValue={setTabValue}
          />
        </div>
        <div
          id="right-section"
          className="column center width-30 width-100-mobile width-40-tablet width-35-mid m-left-50 m-0-tablet m-0-mobile"
        >
          <div
            style={{ background: COLORS.darkBlueGradient, borderRadius: 16, padding: 25 }}
            className="m-top-30 width-100">
            <div
              className="width-100 column center m-bottom-30">
              <InvestmentCountdown
                showLoader={pageLoading}
                asset={assetData?.getAsset}
                fundedPercentage={fundedPercentage ?? 0}
                showAdditionalInvestmentData={showAdditionalInvestmentData}
              />
            </div>
            <Divider
              variant="middle"
              style={{ width: "100%", margin: 0, backgroundColor: COLORS.lightGray }} />
            <div className="m-top-30 m-bottom-30">
              <Typography
                variant="h6"
                className="acc-white bold">
                Vertical Partner
              </Typography>
              <br />
              <a
                target="_blank"
                rel="noreferrer"
                href={assetData?.getAsset.assetPartner.website}>
                <img
                  src={assetData?.getAsset.assetPartner.coverLogoImageLocation}
                  style={{ maxHeight: 45 }} />
              </a>
              <br />
              <br />
              <Typography
                variant="h6"
                className="acc-white bold">
                {assetData?.getAsset.assetPartner.name}
              </Typography>
              <Typography
                variant="subtitle2"
                className={`acc-white light ${partnerDescriptionCollapsed ? "asset-summary" : ""}`}>
                {assetData?.getAsset.assetPartner.description}
              </Typography>
              <Typography
                variant="subtitle2"
                className="acc-white light"
                style={styles.collapse}
                onClick={handleCollapse}
              >
                {partnerDescriptionCollapsed ? "Read more" : "Read less"}</Typography>
            </div>
            <Divider
              variant="middle"
              style={{ width: "100%", margin: 0, backgroundColor: COLORS.lightGray }} />
            <div className="m-top-30 m-bottom-30">
              <Typography
                variant="h6"
                className="acc-white bold">
                Authorized Investors
              </Typography>
              {isNotNil(assetData?.getAsset.authorizedInvestors) ? assetData?.getAsset?.authorizedInvestors.map(
                item =>
                  <Chip
                    key={item}
                    label={item}
                    style={styles.chip} />) : null}
            </div>
            <Divider
              variant="middle"
              style={{ width: "100%", margin: 0, backgroundColor: COLORS.lightGray }} />
            <div className="width-100 width-100-mobile m-bottom-30 m-top-30">
              <OpportunityDetailsBox
                closingDate={assetData?.getAsset.targetedFundingDate}
                vertical={assetData?.getAsset.assetType.assetClass.vertical.name}
                assetType={assetData?.getAsset.assetType.name}
                assetClass={assetData?.getAsset.assetType.assetClass.name}
                investmentJurisdiction={assetData?.getAsset.investmentJurisdiction}
                creditType={assetData?.getAsset.marketType}
                assetStatus={assetData?.getAsset.assetStatus.name}
                taxDocument={assetData?.getAsset?.dealType.taxDocumentType}
                investmentStructure={assetData?.getAsset.investmentStructure}
              />
            </div>
          </div>
          <PageCard className="center column width-100 width-100-mobile m-top-30">
            <div className="width-100 row start stretch">
              <Typography
                variant="subtitle1"
                className="acc-blue bold"
              >
                Asset Manager
              </Typography>
              <FontAwesomeIcon
                style={{ fontSize: 40 }}
                className="acc-green"
                icon={faUserAstronaut}
              />
            </div>
            <div className="row center m-top-15 width-100">
              <img
                style={{
                  width: 60,
                  objectFit: "cover",
                  borderRadius: 50,
                  height: 60,
                  marginBottom: 10,
                }}
                alt="Asset manager avatar"
                src={assetData?.getAsset.assetManagerUser.avatarImageLocation || Paul}
              />
              <div className="column start m-left-15">
                <Typography
                  variant="subtitle2"
                  className="acc-darkgray bold"
                  style={{ marginBottom: 5 }}
                >
                  {assetData?.getAsset.assetManagerUser.firstName} {assetData?.getAsset.assetManagerUser.lastName}
                </Typography>
                <Typography
                  variant="body2"
                  className="acc-darkgray light"
                  style={{ lineHeight: 1, fontSize: 12 }}
                >
                  {assetData?.getAsset.assetManagerUser.jobTitle}
                </Typography>
              </div>
            </div>
            <div className="row center m-top-15 width-100 ">
              <Typography
                variant="subtitle2"
                className="acc-blue bold"
                style={{ lineHeight: 1, width: "100%", fontSize: 12}}> 
                <a
                  href={assetData?.getAsset?.assetManagerUser?.linkedInUrl}
                  target="_blank"
                  rel="noreferrer"
                  style={{display: "flex", alignItems: "center"}}
                >
                 <FontAwesomeIcon
                  style={{ fontSize: 20 }}
                  className="acc-blue m-right-15"
                  icon={faLink}/>
                  LinkedIn Profile
                </a>
              </Typography>
              <Typography
                variant="subtitle2"
                className="acc-blue bold"
                style={{ lineHeight: 1, width: "100%", fontSize: 12}}>
                <a
                  href={`mailto:${assetData?.getAsset?.assetManagerUser?.email}`}
                  type="email"
                  target="_blank"
                  rel="noreferrer"
                  style={{display: "flex", alignItems: "center"}}>
                   <FontAwesomeIcon
                  style={{ fontSize: 20 }}
                  className="acc-blue m-right-15"
                  icon={faEnvelope}/> 
                  Contact
                </a>
              </Typography>
            </div>
          </PageCard>
        </div>
      </div>
    </div >
  );
};

const styles = {
  border: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    padding: "25px",
  },
  image: {
    borderRadius: "16px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    zIndex: -2,
  },
  collapse: {
    textDecoration: "underline",
    cursor: "pointer",
    width: "fit-content",
  },
  accessButton: {
    fontFamily: "Gilroy",
    paddingLeft: 45,
    paddingRight: 45,
    fontWeight: 100,
    fontSize: 16,
    width: 150,
  },
  chip: {
    backgroundColor: COLORS.lightGreen,
    color: COLORS.darkBlue,
    fontWeight: 500,
    marginRight: 15,
    marginTop: 15,
    height: 28,
  },
};

export default withRouter(OpportunityDetails);