import React from "react";
import { Typography, useMediaQuery } from "@material-ui/core";
import COLORS from "../../../../common/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity } from "@fortawesome/pro-light-svg-icons";
import { formatCurrency } from "../../../../common/utilities";
import { useFormikContext } from "formik";
import { FundingFormValue, ExternalAccount, Wallet, FundingRequestTypes } from "../../../../common/types";
import { THEME_BREAKPOINTS } from "../../../../common/constants";

interface Props {
  externalAccount: Array<ExternalAccount> | undefined;
  walletData: Wallet | undefined;
  fundingRequestType?: string;
}

const VerifyFundingAccount: React.FC<Props> = ({ externalAccount, walletData, fundingRequestType }) => {

  const { values } = useFormikContext<FundingFormValue>();
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);


  return (
    <div
      className="column center"
      style={{ position: "relative", bottom: mobileLayout ? "" : "10px" }}>
      <Typography
        variant="subtitle2"
        className="light acc-white">
        Please verify the information below and confirm transfer.
      </Typography>
      < br />
      <Typography
        variant="h6"
        className="bold acc-white">
        {
          fundingRequestType === FundingRequestTypes.DEPOSIT
            ? <p>I want to transfer: <span className="acc-lightgreen">{formatCurrency(values.amount!)}</span> from</p>
            : <p>I want to withdraw: <span className="acc-lightgreen">{formatCurrency(values.amount!)}</span> to</p>
        }
      </Typography>
      <div
        className="row center stretch  m-top-15 width-100-mobile"
        style={styles.bankAccount}>
        {externalAccount
          ?.filter((item: ExternalAccount) => item.id === values.selectedExternalAccount)
          .map((item: ExternalAccount) =>
            <div
              key={item.id}
              className="row stretch center width-100"
              style={{ justifyContent: "flex-start" }}>
              <div className="row m-right-15">
                <FontAwesomeIcon
                  className="acc-green m-right-15"
                  size="lg"
                  icon={faUniversity}
                  style={{ position: mobileLayout ? "relative" : "initial", top: mobileLayout ? "15px" : "" }} />
                <Typography
                  variant="subtitle1"
                  className="acc-gray bold">
                  {item.bankName} - {item.displayName}
                </Typography>
              </div>
              <Typography
                variant="caption"
                className="acc-gray light">
                ****{item.lastFour}
              </Typography>
            </div>,
          )}
      </div>
      <br />
      <Typography
        variant="h6"
        className="bold acc-white">
        {
          fundingRequestType === FundingRequestTypes.DEPOSIT
            ? "to Investment Account:"
            : "from Investment Account:"
        }
      </Typography>
      <Typography
        variant="h6"
        className="acc-lightgreen">
        {walletData?.name}
      </Typography>
    </div>
  );
};

const styles = {
  bankAccount: {
    padding: 20,
    backgroundColor: COLORS.white,
    borderRadius: 16,
    height: 70,
  },
};

export default VerifyFundingAccount;