import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import "./styles/mobileStyles.css";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  ApolloLink,
  Observable,
} from "@apollo/client";
import App from "./App";
import { config } from "./common/config";
import { createUploadLink } from "apollo-upload-client";
import {
  QueryClientProvider,
} from "react-query";
import { attemptTokenRefresh, getToken, rootQueryClient } from "./api/utils";
import { onError } from "@apollo/client/link/error";

const authMiddleware = new ApolloLink((operation, forward) => {

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${getToken()}`,
    },
  }));

  return forward(operation);
});

const refreshTokenMiddleware = onError(({ graphQLErrors, networkError, operation, forward }) => {
  // User access token has expired
  if (graphQLErrors && graphQLErrors[0].message === "Unauthorized" || networkError?.response?.status === 401) {
    // We assume we have both tokens needed to run the async request
    // Let's refresh token through async request
    return new Observable(async observer => {
      try {
        const refreshResponse = await attemptTokenRefresh();
        if (refreshResponse === true) {
          operation.setContext(({ headers = {} }) => ({
            headers: {
              // Re-add old headers
              ...headers,
              // Switch out old access token for new one
              authorization: `Bearer ${getToken()}` || null,
            },
          }));
        } else {
          console.log("Still refreshing");
        } const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        };
        // Retry last failed request
        forward(operation).subscribe(subscriber);
      } catch (error) {
        // No refresh or client token available, we force user to login
        observer.error(error);
      }
    });
  }
});


const client = new ApolloClient({
  link: from([authMiddleware, refreshTokenMiddleware, createUploadLink({ uri: `${config.apiHost}/graphql` })]),
  cache: new InMemoryCache(),
  credentials: "include",
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});


ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={rootQueryClient}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
