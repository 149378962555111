import React, { useContext, useEffect, useState } from "react";
import { FormControlLabel, Radio, RadioGroup, Typography, useMediaQuery } from "@material-ui/core";
import PageCard from "../../../common/components/PageCard";
import COLORS from "../../../common/colors";
import FilesDragAndDrop from "../../../common/components/FilesDragAndDrop";
import { accreditationVerificationProcess, onboardingProcess } from "../../../api/rest/northCapital";
import { isNil, standardErrorHandle } from "../../../common/utilities";
import PageTitle from "../../../common/components/PageTitle";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import { Assets, IncomeVerification, LicenseHolder, ProfessionalLetter, RequiredDocuments } from "./AccreditationTips";
import { QueryKeys, useAccountAddresses, useUserWallets, useWalletTypes } from "../../../api/hooks/graphqlHooks";
import { SessionContext } from "../../../context";
import { Link, useParams } from "react-router-dom";
import BrokenCard from "../../../assets/images/illustrations/access_404_page_illustration.png";
import AccessLogo from "../../../assets/images/illustrations/access_card_regular.svg";
import LoadingAnimation from "../../../common/components/LoadingAnimation";
import { useQueryClient } from "react-query";
import { WalletTypes } from "../../../enums";
import Checkbox from "@mui/material/Checkbox";
import { emailHrefLinks, THEME_BREAKPOINTS } from "../../../common/constants";

const Accreditation: React.FC = () => {

    const [detailsVisible, setDetailsVisible] = useState<boolean>(false);
    const [checkedEConsent, setCheckedEConsent] = useState<boolean>(false);
    const [selectedAccType, setSelectedAccType] = useState<string>("");
    const [accreditationSuccess, setAccreditationSuccess] = useState<boolean>(false);
    const [responseWasCompleted, setResponseWasCompleted] = useState<boolean>(false);
    const [isFileAttached, setIsFileAttached] = useState<boolean>(false);
    const { user } = useContext(SessionContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [file, setFile] = useState<any>();
    const { data: addressData } = useAccountAddresses();
    const { data: walletsData } = useUserWallets();
    const firstWallet = walletsData?.getUserWallets?.[0];
    const walletIdByParams = useParams<{ id: string }>();
    const { data: walletTypeData } = useWalletTypes();
    const queryClient = useQueryClient();
    const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
    let currWallet: any;
    let typeOfCurrValue;
    walletsData?.getUserWallets.map((wall) => {
      if (wall.id === walletIdByParams.id) {
        currWallet = wall;
      }
    });
    walletTypeData?.getWalletTypes.map((walletType) => {
      if (walletType.id === currWallet?.walletTypeObjectId) {
        typeOfCurrValue = walletType.name;
      }
    });

    typeOfCurrValue = isNil(typeOfCurrValue) ? firstWallet?.walletType.name : typeOfCurrValue;

    useEffect(() => {
      (file?.name && file?.name.length !== 0) ? setIsFileAttached(true) : setIsFileAttached(false);
    }, [file]);

    const account = {
        accountRegistration: "Account Name",
        // account type should be Individual, Commercial or Joint
        type: typeOfCurrValue ?? "PARTY",
        domesticYN: "domestic_account",
        streetAddress1: addressData?.getAccountAddresses?.[0]?.street1 ? addressData?.getAccountAddresses?.[0]?.street1 : "123 NoName Street",
        city: addressData?.getAccountAddresses?.[0]?.city ? addressData?.getAccountAddresses?.[0]?.city : "Miami",
        state: addressData?.getAccountAddresses?.[0]?.stateProvince ? addressData?.getAccountAddresses?.[0]?.stateProvince : "Florida",
        zip: addressData?.getAccountAddresses?.[0]?.postalCode ? addressData?.getAccountAddresses?.[0]?.postalCode: "33139",
        country: addressData?.getAccountAddresses?.[0]?.country ? addressData?.getAccountAddresses?.[0]?.country : "US",
        phone: user?.phone ? user?.phone : "(178)671-8893",
        KYCstatus: "Pending",
        AMLstatus: "Pending",
        AccreditedStatus: "Pending",
        Allow: "Pending",
        associatedAC: "no",
        syndicate: "yes",
        approvalStatus: "Pending",
    };

    const accountType: any = {
        domicile: "U.S. citizen",
        primCountry: addressData?.getAccountAddresses?.[0]?.country ? addressData?.getAccountAddresses?.[0]?.country : "US",
        primAddress1: addressData?.getAccountAddresses?.[0]?.street1,
        primCity: addressData?.getAccountAddresses?.[0]?.city,
        primState: addressData?.getAccountAddresses?.[0]?.stateProvince,
        primZip: addressData?.getAccountAddresses?.[0]?.postalCode,
        emailAddress: user?.email,
        phone: user?.phone,
        KYCstatus: "Pending",
        AMLstatus: "Pending",
        virtualStatus: "ACTIVE",
    };

    if (typeOfCurrValue === WalletTypes.INDIVIDUAL || typeOfCurrValue === WalletTypes.JOINT) {
        accountType.firstName = user?.firstName;
        accountType.lastName = user?.lastName;
        // TODO: Update date of birth
        accountType.dob = "01-14-1989";
        accountType.associatedPerson = "Yes";
    } else if (typeOfCurrValue === WalletTypes.COMMERCIAL_ENTITY) {
        accountType.entityName = "newEntity";
        accountType.entityType = "Revocable Trust";
        accountType.entityDesc = "This is a new Entity";
        accountType.ownersAI = "yes";
        accountType.createdIpAddress = "104.12.71.166";
    };

    const walletObjectId = walletIdByParams.id ?? firstWallet?.id!;
    const accountObjectId = firstWallet?.accountObjectId!;

    const submitAccreditation = async () => {
        setIsSubmitting(true);
        try {
          const accreditationOnboarding = await onboardingProcess({
                account,
                accountType,
                walletObjectId,
                accountObjectId,
            });
          let accreditationVerification;
          if (accreditationOnboarding) {
                var data = new FormData();
                data.append("file", file);
                data.append("accountId", accreditationOnboarding.data.onboardingProcessResponse.onboardingProcessResponse.accountId);
                data.append("documentTitle", file.name);
                data.append("aiMethod", "Upload");
                data.append("firstName", user?.firstName);
                data.append("walletName", currWallet.name);
                data.append("email", user?.email);
                accreditationVerification = await accreditationVerificationProcess(data);
            };
          setResponseWasCompleted(true);
          if (accreditationVerification && accreditationOnboarding) {
            setAccreditationSuccess(true);
          }
        } catch (err) {
            setIsSubmitting(false);
            standardErrorHandle(err, "Error submitting onboard");
        }
        queryClient.refetchQueries(QueryKeys.ACCREDITED_INVESTOR_REQUESTS);
        setIsSubmitting(false);
    };

    if (isSubmitting) {
        return <LoadingAnimation />;
    }

  const currentTypes = typeOfCurrValue === WalletTypes.COMMERCIAL_ENTITY ? commertialEntityAccreditationTypes : accreditationTypes;
  const currentTips = typeOfCurrValue === WalletTypes.COMMERCIAL_ENTITY ? requiredDocuments : usefulTips;

  return !responseWasCompleted ? (
        <div>
            <PageTitle
                title="Accreditation Verification"
                subtitle="You need to be an accredited investor to be able to invest on the access platform"
            />
            <br />
              <div className={mobileLayout ? "stretch column width-100 m-top-30 row" : "width-100 m-top-30 row"}>
                <div className="width-100">
                    <Typography
                        variant="caption"
                        className="acc-gray">
                        {typeOfCurrValue === WalletTypes.COMMERCIAL_ENTITY ?
                          <div className="width-80">
                            <b>All documents and information listed are required.</b>
                            <ul>
                              <li> We perform full KYC/AML checks on all equity owners with a 20% or greater stake in the entity. </li>
                              <li>We do not take ID cards or driver’s licenses for foreign investors. Passports are required for
                                all foreign investors.</li>
                              <li>All documents must be provided in English.</li>
                              <li>Each entity’s documents may be written differently; additional information may
                                be required at the discretion of the reviewing agent.</li>
                              <li>Addresses must be the ultimate beneficial owner’s legal, residential address. P.O. boxes are not permitted.</li>
                              <li>Multi-layered entities: If an entity is owned or managed by another entity, you must collect that entity’s documents
                                as well. We must have appropriate documents for each layer, until we arrive at the ultimate beneficial owners</li>
                            </ul>
                          </div> : <div
                          style={{width: mobileLayout ? "320px" : "480px"}}>
                          &quot;Due to securities regulations, we are required to verify your accredited investor status. 
                          Please upload any of the following documents to complete the verification:&quot;

                          </div>}
                    </Typography>
                    <RadioGroup
                        style={styles.radioGroup as React.CSSProperties}
                        defaultValue={selectedAccType}
                        className="width-100"
                        name="radio-buttons-group"
                        onChange={(e) => setSelectedAccType(e.target.value)}
                        value={selectedAccType}
                    >
                        {currentTypes.map(item => (
                            <PageCard
                                style={{ padding: 0, border: selectedAccType === item.value ? "1px solid #1BD0AE" : "" }}
                                key={item.value}
                                className={mobileLayout ? "width-100 m-bottom-15" : "width-80 m-bottom-15"}>
                                <FormControlLabel
                                    style={{ padding: 25 }}
                                    className="width-100"
                                    onClick={() => setDetailsVisible(true)}
                                    value={item.value}
                                    control={<Radio style={styles.radioButton} />}
                                    label={item.accType} />
                            </PageCard>
                        ))}
                    </RadioGroup>
                </div>
                {detailsVisible && (
                    <div 
                        className="column width-100">
                        <div 
                        className="m-bottom-15 width-100"
                        style={{marginTop: mobileLayout ? "20px" : ""}}>
                            <PageCard
                                style={{ background: COLORS.darkBlueGradient }}>
                                <Typography
                                    variant="h6"
                                    className="acc-white bold">
                                    {typeOfCurrValue === WalletTypes.COMMERCIAL_ENTITY ? "Required Documents" : "Useful Tips"}
                                </Typography>
                                <>
                                    {currentTips.map(item => {
                                        return selectedAccType === item.value ?
                                            <div
                                                key={item.value}>
                                                <Typography
                                                    variant="subtitle2"
                                                    className="acc-white light">
                                                    {item.description}
                                                </Typography>
                                                <br />
                                                {item.tips}
                                            </div>
                                            : null;
                                    })}
                                </>
                            </PageCard>
                        </div>
                        <div 
                        className="width-100"
                        style={{marginTop: mobileLayout ? "20px" : ""}}>
                            <FilesDragAndDrop
                                updateFileList={setFile} />
                        </div>
                        <div
                            className="row start m-top-30"
                            style={{ padding: 25 }}
                        >
                            <Checkbox
                                checked={checkedEConsent}
                                onChange={() => {
                                  setCheckedEConsent(!checkedEConsent);
                                }}
                                disableRipple
                                sx={styles.checkbox} />
                            <Typography
                                variant="subtitle2"
                                className="font-size-mobile-5">
                                By clicking this box, the investor represents and warrants that the accreditation documentation submitted
                                correctly and accurately reflects the investor’s financial condition as of the date hereof.
                            </Typography>
                        </div>
                    </div>
                )}
            </div>
            <div
                style={{...styles.buttonContainer as React.CSSProperties, justifyContent: mobileLayout ? "center" : "flex-end"}}>
                <AccButton
                color={AccButtonColors.GREEN}
                label="Submit Accreditation"
                className={isFileAttached && checkedEConsent ? "" : "no-pointer"}
                onClick={() => submitAccreditation()}
                style={styles.nextButton}
                />
            </div>
        </div>
  ) : accreditationSuccess ? (
            <div className="row center">
            <>
                <div className="width-40 column center">
                <img
                    src={AccessLogo}
                    style={{ height: 260 }}
                />
                </div>
                <div
                className="width-45 column start"
                style={{ padding: "160px 100px 150px 15px" }}>
                <Typography
                    variant="h4"
                    className="acc-darkblue bold">
                    Congratulations! <br />
                    You have successfully < br />
                    submitted an accreditation request.
                </Typography>
                <br />
                <Typography
                    variant="subtitle1"
                    className="acc-darkgray light"
                    >We will notify you as soon as your accreditation status updates.
                    If you have any questions about your accreditation please send us an email at
                    <a 
                    href={emailHrefLinks.supportRequest} 
                    className="acc-green"> support@accessinvestments.com</a>
                    </Typography>
                    <Link
                    to="/investor/dashboard"
                    className="width-40 width-100-mobile width-50-tablet m-top-30">
                    <AccButton
                        label="Return"
                        color={AccButtonColors.GREEN}
                    />
                    </Link>
                    </div>
                </>
            </div>
        ) : (
            <div
                className="row center stretch width-100 m-top-50"
                style={styles.errorContainer}
            >
                <div className="width-50 column center">
                    <img
                        src={BrokenCard}
                        style={{ height: 400 }}
                    />
                </div>
                <div className="width-50">
                    <Typography
                        variant="h5"
                        className="acc-white width-60"
                    >
                        We are sorry for the inconvenience but your accreditation request has failed. Please try again later.
                    </Typography>
                    <br />
                    <Link to="/investor/accounts">
                        <AccButton
                            style={{ width: "fit-content" }}
                            label="Return"
                            color={AccButtonColors.GREEN}
                        />
                    </Link>
                </div>
            </div>
        );
};

const accreditationTypes = [
    { accType: "Income verification: individual or joint", value: "1" },
    { accType: "Assets: above $1M", value: "2" },
    { accType: "Professional Letter", value: "3" },
    { accType: "License holder: Series 7, 65, and 82", value: "4" },
];

const commertialEntityAccreditationTypes = [
    { accType: "Limited Liability Company (LLC)", value: "1" },
    { accType: "Corporation", value: "2" },
    { accType: "Limited Partnership / General Partnership", value: "3" },
    { accType: "Trust (revocable and irrevocable)", value: "4" },
];

const usefulTips = [
    {
        description: "Please provide W2’s, 1099’s or tax returns for the past two years to show your individual income exceeds $200,000 (or joint income exceeds $300,000) for each year.",
        value: "1",
        tips: <IncomeVerification />,
    },
    {
        description: "Please provide recent account statements or third party appraisals (must be dated within the last 3 months) that show the value of your assets exceed $1,000,000 excluding your primary residence.",
        value: "2",
        tips: <Assets />,
    },
    {
        description: "Professional Letter (must be dated within the last 3 months): provide an official written communication from a licensed CPA, attorney, investment advisor or registered broker-dealer, stating that the professional service provider has a reasonable belief that you are an Accredited Investor.",
        value: "3",
        tips: <ProfessionalLetter />,
    },
    {
        description: "License holder: please provide documents indicating if any of the following apply: Holder in good standing of the Series 7, 65, or 82 licenses.",
        value: "4",
        tips: <LicenseHolder />,
    },
];

const requiredDocuments = [
  {
    description: "",
    value: "1",
    tips: RequiredDocuments("limitedLiabilityCompany"),
  },
  {
    description: "",
    value: "2",
    tips: RequiredDocuments("corporation"),
  },
  {
    description: "",
    value: "3",
    tips: RequiredDocuments("limitedPartnership"),
  },
  {
    description: "",
    value: "4",
    tips: RequiredDocuments("trust"),
  },
];

const styles = {
    radioButton: {
        color: "#1BD0AE",
    },
    radioGroup: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginTop: 30,
    },
    checkbox: {
        padding: "0px 10px 0px 0px",
        color: COLORS.lightGray,
        "& .MuiSvgIcon-root": {
            fontSize: 20,
        },
        "&.Mui-checked": {
            color: "#00EEC1",
        },
        "&.MuiIcon-text": {
            fill: "blue",
        },
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        marginTop: "30px",
    },
    backButton: {
        padding: "5px 25px 5px 25px",
        borderRadius: "16px",
        color: COLORS.white,
        textTransform: "none",
    },
    nextButton: {
        width: "fit-content",
        marginLeft: 15,
        border: "1px solid #ffffff80",
    },
    errorContainer: {
        background: COLORS.darkBlueGradient,
        borderRadius: 16,
        padding: 55,
    },
};

export default Accreditation;