import { Typography } from "@material-ui/core";
import React from "react";

const IncomeVerification = () => {

    return (
        <>
            <Typography
                variant="subtitle2"
                className="acc-white bold">
                Requirements:
            </Typography>
            <>{requirements.map(item =>
                <Typography
                    key={item}
                    variant="subtitle2"
                    className="acc-white light">
                    {item}
                </Typography>)}</>
            <br />
            <Typography
                variant="subtitle2"
                className="acc-white bold">
                Acceptable tax documents:
            </Typography>
            <>{taxDocuments.map(item =>
                <Typography
                    key={item}
                    variant="subtitle2"
                    className="acc-white light">
                    {item}
                </Typography>)}</>
        </>
    );
};

const Assets = () => {

    return (
        <>
            <Typography
                variant="subtitle2"
                className="acc-white bold">
                Acceptable documents:
            </Typography>
            <br />
            <Typography
                variant="subtitle2"
                className="acc-white bold">
                Assets:
            </Typography>
            <>{assets.map(item =>
                <Typography
                    key={item}
                    variant="subtitle2"
                    className="acc-white light">
                    {item}
                </Typography>)}</>
            <br />
            <Typography
                variant="subtitle2"
                className="acc-white bold">
                Liabilities:
            </Typography>
            <>{crd.map(item =>
                <Typography
                    key={item}
                    variant="subtitle2"
                    className="acc-white light">
                    {item}
                </Typography>)}</>
        </>
    );
};

const ProfessionalLetter = () => {

    return (
        <>
            <Typography
                variant="subtitle2"
                className="acc-white bold">
                Requirements:
            </Typography>
            <Typography
                variant="subtitle2"
                className="acc-white light">
                • Provide a verification document from a third party dated within the past three months, and we will review it.
            </Typography>
        </>
    );
};

const LicenseHolder = () => {

    return (
        <>
            <Typography
                variant="subtitle2"
                className="acc-white bold">
                How to find your CRD number:
            </Typography>
            <>{liabilities.map(item =>
                <Typography
                    key={item}
                    variant="subtitle2"
                    className="acc-white light">
                    {item}
                </Typography>)}</>
        </>
    );
};

const RequiredDocuments = (typeOfValue: string) => {
    let arrToMap;
    if (typeOfValue === "limitedLiabilityCompany") {
        arrToMap = limitedLiabilityCompany;
    } else if (typeOfValue === "corporation") {
        arrToMap = corporation;
    } else if (typeOfValue === "limitedPartnership") {
        arrToMap = limitedPartnership;
    } else {
        arrToMap = trust;
    }
    return (
        <>
            <>{arrToMap.map(item =>
                <Typography
                    key={item[0]}
                    variant="subtitle2"
                    className="acc-white light">
                    {console.log(item && item.startsWith("• ") )}
                    {item.startsWith("• ") ? item : <>&emsp; {item}</>}
                </Typography>)}
            </>
        </>
    );
};

const limitedLiabilityCompany = ["• Certificate of formation", "• Articles of organization", "• Operating agreement ", "• Members’ list and authorized signers’ list (if not included in the documents)", "- For each member with a 20% or greater equity stake, as well as for the designated control person, we require their full legal name, DOB, SSN, and residential address.", "- For all U.S. individuals that fail KYC or AML, a driver’s license or passport is required.", "- A passport is required for all foreign individuals."];
const corporation = ["• By-laws ", "• Certificate of formation ", "• Articles of incorporation ", "• Shareholders’ list and authorized signers’ list (if not included in the documents) ", "- For each member with a 20% or greater equity stake, as well as for the designated control person, we require their full legal name, DOB, SSN and residential address. ", "- For all U.S. individuals that fail KYC or AML, a driver’s license or passport is required. ", "- A passport is required for all foreign individuals."];
const limitedPartnership = ["• Certificate of formation • Partnership agreement ", "• LP list and general partner(s) list (if not included in the documents)", "• Members’ list and authorized signers’ list (if not included in the documents) ", "- For the GP and for LPs with a 20% or greater equity stake, we require their full legal name, DOB, SSN and residential address. ", "- For all U.S. individuals that fail KYC or AML a driver’s license or passport is required. ", "- A passport is required for all foreign individuals."];
const trust = ["• The full trust document or a certification of trust.", "• For irrevocable trusts, their EIN. ", "• List of all trustees, grantors (if still living), and protectors, including their full legal name, DOB, SSN, and residential address. ", "- For all U.S. individuals that fail KYC or AML, a driver’s license or passport is required. ", "- A passport is required for all foreign individuals, as they will automatically fail KYC."];
const requirements = ["• Individual: $200,000", "• Joint: $300,000"];
const taxDocuments = ["• 1040", "• W-2", "• K-1", "• 1099"];
const assets = ["• Bank, brokerage, or retirement account statements dated within the past three months", "• Proof of investment property ownership (such as a deed or tax bill) along with fair market valuation or appraisal", "• Proof of business ownership (Operating Agreement and tax returns, or valuation of a business/shares owned)"];
const liabilities = ["• Current credit report dated within the past three months", "• Please upload a full credit report. We need to see all liabilities listed. We do NOT need to see a credit score."];
const crd = ["• Visit Brokercheck on the FINRA website", "• Search the database with your name", "• Please screenshot or print the web page displaying your CRD number and upload the document below"];

export { IncomeVerification, Assets, ProfessionalLetter, LicenseHolder, RequiredDocuments };