import gql from "graphql-tag";
import { makeGraphqlRequest } from "../../utils";
import { gql as gqlv2 } from "graphql-request";

export const CREATE_ACCREDITATION_STATUS = gql`
mutation Mutation($input: AccreditationStatusInput!) {
  createAccreditationStatus(input: $input) {
    id
    accreditationStatusId
    name
  }
}
`;

export const UPDATE_ACCREDITATION_STATUS = gql`
mutation Mutation($id: String!, $input: AccreditationStatusInput) {
  updateAccreditationStatus(_id: $id, input: $input) {
    accreditationStatusId
    id
    name
  }
}
`;

const GET_ACCREDITATION_STATUS_V2 = gqlv2`
query Query($accreditationStatusId: String!) {
  getAccreditationStatus(accreditationStatusId: $accreditationStatusId) {
    accreditationStatusId
    id
    name
  }
}
`;

export const getAccreditationStatus = (accreditationStatusId: number) => makeGraphqlRequest(GET_ACCREDITATION_STATUS_V2, { accreditationStatusId });

const GET_ACCREDITATION_STATUSES_V2 = gqlv2`
query Query {
  getAccreditationStatuses {
    id
    name
    accreditationStatusId
  }
}
`;

export const getAccreditationStatuses = () => makeGraphqlRequest(GET_ACCREDITATION_STATUSES_V2);
