import React from "react";
import { MenuItem } from "@material-ui/core";
import AccInput, { AccInputProps } from "..";
import { LabelValueInput } from "../../../types";

interface AccOptionSelectProps extends AccInputProps {
  options: LabelValueInput[];
}

const AccOptionSelect = (props: AccOptionSelectProps) => {
  return (
    <AccInput
      {...props}
      select
    >
      {props.options?.map((item) => (
        <MenuItem
          key={item.value}
          selected
          value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </AccInput>
  );
};

export default AccOptionSelect;