import React from "react";
import PageCard from "../../../../common/components/PageCard";
import { Typography } from "@material-ui/core";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper } from "@mui/material";
// import moment from "moment";
// import { formatCurrency } from "../../../../common/utilities";

const Movements: React.FC = () => {

    return (
        <div>
            <PageCard>
                <Typography
                    variant="h5"
                    className="acc-darkblue bold">
                    Account Movements
                </Typography>
                <br />
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">Description</TableCell>
                                <TableCell align="right">Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* <TableRow
                                    key={row.movementId}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row">{moment(row.dateCreated).format("ll")}</TableCell>
                                    <TableCell align="left">{row.movementType}</TableCell>
                                    <TableCell align="right">{formatCurrency(row.amount)}</TableCell>
                                </TableRow> */}
                        </TableBody>
                    </Table>
                </TableContainer>
            </PageCard>
        </div>
    );
};


export default Movements;