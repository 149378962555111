import { OutlinedInput, Typography } from "@material-ui/core";
import React from "react";
import { toast } from "react-toastify";
import COLORS from "../../common/colors";
import AccButton, { AccButtonColors } from "../../common/components/AccButton";
import "../../styles/login.css";
import { forgotPassword } from "../../api/rest/user/index";
import { RouteComponentProps, withRouter } from "react-router";
import Logo from "../../assets/images/illustrations/access_logo_regular.svg";
import { Link } from "react-router-dom";
import { isNotNil } from "../../common/utilities";
import * as Yup from "yup";

type PasswordUpdateProps = RouteComponentProps;

const PasswordUpdate: React.FC<PasswordUpdateProps> = ({ history }) => {
  const [values, setValues] = React.useState({
    email: "",
  });

  const ForgotPasswordValueSchema = Yup.object().shape({
    email: Yup.string().email("Please eneter a valid email!").max(255),
  });

  const handleChange =
    (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const submitEmail = async () => {
    try {
      ForgotPasswordValueSchema.validate(values)
        .then(async () => {
          const response = await forgotPassword(values.email);
          if (response?.success) {
            history.push("/resetPassword");
          }
        })
        .catch(function (err: any) {
          toast(
            err.errors[0] ||
            "Validation error",
            {
              type: toast.TYPE.WARNING,
            },
          );
        });
    } catch (err: any) {
      toast(
        err.message ||
        err.msg ||
        err.toString() ||
        "There was an error updating user password.",
        {
          type: toast.TYPE.ERROR,
        },
      );
    };
  };

  const getYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div style={styles.background as React.CSSProperties}>
      <div style={styles.container as React.CSSProperties}>
        <Link to="/">
          <img
            style={{ width: "100%" }}
            src={Logo}
            alt=""
          />
        </Link>
        <div>
          <Typography
            className="font-size-mobile-3"
            variant="h5"
            style={styles.text as React.CSSProperties}
          >
            Password Reset
          </Typography>
          <Typography
            className="light"
            variant="subtitle1"
            align="center"
            style={styles.text as React.CSSProperties}
          >
            Enter the email address that you used to register. We will send you an email
            with a temporary code to reset your password and access your account.
          </Typography>
        </div>
        {/* </Link> */}
        <div className="width-100">
          <OutlinedInput
            fullWidth
            placeholder="Email"
            name="email"
            onChange={handleChange("email")}
            style={styles.input}
          />
        </div>
        <AccButton
          style={{ pointerEvents: isNotNil(values.email) ? "" : "none" }}
          onClick={() => submitEmail()}
          color={isNotNil(values.email)
            ? AccButtonColors.GREEN
            : AccButtonColors.WHITE
          }
          label="Send Code"
        />
      </div>
      <Typography style={{ color: COLORS.white }}>
        © {getYear()}&nbsp;
        <span style={{ color: COLORS.green }}>
          Access Alternative Investments, Inc.
        </span>
      </Typography>
    </div>
  );
};

const styles = {
  background: {
    background:
      "transparent linear-gradient(360deg, #001227 0%, #05254b 100%) 0% 0% no-repeat padding-box",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: COLORS.darkBlue,
    fontWeight: "bold",
    textAlign: "center",
    margin: "10px",
  },
  container: {
    backgroundColor: "white",
    margin: "30px",
    minWidth: 210,
    maxWidth: 400,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    padding: "40px 55px 30px 55px",
    height: 500,
    borderRadius: 16,
    boxShadow: "0px 0px 60px #00000066",
  },
  input: {
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: 16,
    opacity: 1,
    width: "100%",
    marginBottom: 25,
  },
  secondaryButton: {
    border: "1px solid #4399A8",
    background: "white",
    marginTop: "15px",
  },
  checkbox: {
    color: COLORS.darkBlue,
    "&.Mui-checked": {
      color: COLORS.darkBlue,
    },
  },
};

export default withRouter(PasswordUpdate);
