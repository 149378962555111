const COLORS = {
  blue: "#223e60",
  darkBlue: "#05254B",
  lightBlue: "#008BC8",
  mediumBlue: "#00B6E3",
  grayBlue: "#476a79",
  mediumCyan: "#007AA7",
  green: "#1BD0AE",
  lightGreen: "#00EEC1",
  greenGradient: "transparent linear-gradient(270deg, #1cd0ae 0%, #00eec1 100%) 0% 0% no-repeat padding-box",
  greenTransparent: "rgb(0, 238, 193, 0.4)",
  white: "#FFFFFF",
  red: "#FF8585",
  darkGray: "#404040",
  lightGray: "#919197",
  darkBlueGradient: "transparent linear-gradient(360deg, #001227 0%, #05254B 100%) 0% 0% no-repeat padding-box",
  lightBlueGradient: "transparent linear-gradient(180deg, #05254B 0%, #194374 100%) 0% 0% no-repeat padding-box",
  navBarLogo: "rgba(255, 255, 255, 0.6)",
  greyTableHover: "rgba(245, 245, 245)",
  yellow: "#FAFA87",
  yellowTransparent: "#F4CA4059",
  lightTeal: "#edfffc",
  ultralightGreen: "#B9FDED",
  ultradarkGreen: "#269983",
  imageGradientOverlay: "linear-gradient(180deg, rgba(246,246,246,0) 60%, rgba(5,37,75,1) 100%)",
  layeredImageGradient: "linear-gradient(100deg, rgba(0,18,39,1) 0%, rgba(2,25,52,1) 57%, rgba(5,37,75,0.7637648809523809) 100%)",
  greenCornerGradient: "linear-gradient(40deg, rgba(0,18,39,1) 32%, rgba(5,37,75,1) 62%, rgba(8,61,89,1) 75%, rgba(12,94,108,1) 85%, rgba(17,135,132,1) 92%, rgba(27,208,174,1) 100%)",
};

export default COLORS;
