import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem, Typography } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { withRouter } from "react-router-dom";
import { QueryKeys, useAccounts, useUsers, useWalletStatuses, useWalletTypes } from "../../../../api/hooks/graphqlHooks";
import AccInput from "../../../../common/components/AccInput";
import { Wallet } from "../../../../common/types";
import { isNil } from "../../../../common/utilities";
import { EditModeTypes } from "../../../../enums";
import { withErrorBoundary } from "../../../../common/components/ErrorBoundary";
import PageWrapper from "../../../../common/components/PageWrapper";

type WalletsFormFieldValues = Wallet & {
  walletTypeObjectId: string,
  walletTypeName: string,
  walletTypeId: string,
  walletStatusObjectId: string,
  walletStatusName: string,
  walletStatusId: string,
  createdAt: string,
  primaryUserObjectId: string,
  primaryUserFullName: string,
  primaryUserId: string,
}

const WalletFields: React.FC = withErrorBoundary({}, ({ match }: any) => {

  const { values, setFieldValue } = useFormikContext<WalletsFormFieldValues>();
  const [selectedAccount, setSelectedAccount] = useState<string | null>(values.accountObjectId);
  const queryClient = useQueryClient();
  const editMode: EditModeTypes = isNil(match.params.id) ? EditModeTypes.ADD : EditModeTypes.EDIT;
  if (editMode === EditModeTypes.ADD) {
    queryClient.removeQueries(QueryKeys.WALLET);
  }

  const {
    data: getAccountsData,
    isLoading: getAccountsLoading,
    isFetching: getAccountsFetching,
    isError: getAccountsError,
  } = useAccounts({
    refetchOnMount: true,
  });

  const {
    data: getUsersData,
    isLoading: getUsersLoading,
    isFetching: getUsersFetching,
    isError: getUsersError,
  } = useUsers({
    refetchOnMount: true,
  });

  const usersAttachedToSelectedAccount = getUsersData?.getUsers.filter((item) => item.accountObjectId === selectedAccount);
  const {
    data: getWalletTypesData,
    isLoading: getWalletTypesLoading,
    isFetching: getWalletTypesFetching,
    isError: getWalletTypesError,
  } = useWalletTypes({
    refetchOnMount: true,
  });
  const {
    data: getWalletStatusesData,
    isLoading: getWalletStatusesLoading,
    isFetching: getWalletStatusesFetching,
    isError: getWalletStatusesError,
  } = useWalletStatuses({
    refetchOnMount: true,
  });

  useEffect(() => {
    setSelectedAccount(values.accountObjectId);
  }, [values.accountObjectId]);

  const pageLoading =
    getAccountsLoading ||
    getWalletTypesLoading ||
    getWalletStatusesLoading ||
    getWalletTypesFetching ||
    getWalletStatusesFetching ||
    getAccountsFetching ||
    getUsersLoading ||
    getUsersFetching;

  return (
    <PageWrapper
      showLoader={pageLoading}
      showError={getAccountsError || getWalletTypesError || getWalletStatusesError || getUsersError}
      errorMessage="Cannot retrieve wallets"
    >
      <div
        style={styles.wrapper}>
        <div
          style={styles.border}
          className="width-60 width-100-mobile">
          <Field
            name="name"
            type="text"
            label="Wallet Name"
            as={AccInput}
            required
          />
          <Field
            name="availableAmount"
            type="text"
            label="Available amount"
            as={AccInput}
            required
            currencyMask
          />
          <Field
            name="accountHolderFullName"
            type="text"
            label="Account Holder Full Name"
            as={AccInput}
            required
          />
          <Field
            name="umbAccountId"
            type="text"
            label="UMB Account Id"
            as={AccInput}
            required
          />
          <Field
            as={AccInput}
            name="accountObjectId"
            label="Account"
            select
            required
            onChange={(e: any) => {
              setFieldValue("accountObjectId", e.target.value);
              setSelectedAccount(e.target.value);
            }}
          >
            <MenuItem
              selected
              value={values?.account?.id}>
              {values?.account?.name ? values?.account?.name : <span style={{ color: "red" }}>Account does not exist, assign new Account</span>}
            </MenuItem>
            {getAccountsData?.getAccounts
              ?.filter(
                (item) => item.id !== values?.account?.id,
              )
              .sort((itemA: { name: string }, itemB: { name: string }) => itemA.name.localeCompare(itemB.name))
              .map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </Field>
          <Field
            label="Primary User"
            select
            name="primaryUserObjectId"
            value={values?.primaryUserObjectId}
            required
            as={AccInput}>
            <MenuItem
              selected
              value={values?.primaryUserObjectId}>
              {values.primaryUserFullName ? values.primaryUserFullName : <span style={{ color: "red" }}>Primary user does not exist, assign primary user</span>}
            </MenuItem>
            {usersAttachedToSelectedAccount
              ?.filter(
                (item) => item.id !== values?.primaryUser?.id,
              )
              .sort((itemA: { firstName: string }, itemB: { firstName: string }) => itemA.firstName.localeCompare(itemB.firstName))
              .map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}>
                  {`${item.firstName} ${item.lastName}`}
                </MenuItem>
              ))}
          </Field>
          <Field
            name="walletTypeObjectId"
            label="Wallet Type"
            select
            required
            as={AccInput}
          >
            <MenuItem
              selected
              value={values?.walletType?.id}>
              {values?.walletType?.name}
            </MenuItem>
            {getWalletTypesData?.getWalletTypes
              ?.filter(
                (item) => item.id !== values?.walletType?.id,
              )
              .sort((itemA: { name: string }, itemB: { name: string }) => itemA.name.localeCompare(itemB.name))
              .map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </Field>
          <Field
            name="walletStatusObjectId"
            label="Wallet Status"
            select
            required
            as={AccInput}
          >
            <MenuItem
              selected
              value={values?.walletStatus?.id}>
              {values?.walletStatus?.name ? values?.walletStatus?.name : <span style={{ color: "red" }}>Wallet Status does not exist, assign new Wallet Status</span>}
            </MenuItem>
            {getWalletStatusesData?.getWalletStatuses
              ?.filter(
                (item) => item.id !== values?.walletStatus?.id,
              )
              .sort((itemA: { name: string }, itemB: { name: string }) => itemA.name.localeCompare(itemB.name))
              .map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </Field>
        </div>
        {editMode === EditModeTypes.EDIT ?
          <div
            style={styles.border}>
            <div className="column start">
              <Typography
                variant="h6"
                className="acc-black bold">
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className="acc-lightgreen m-right-15" />
                Wallet Creation Date
              </Typography>
              <Typography
                variant="h6"
                className="acc-black light">
                {moment(Number(values?.createdAt)).format("MM/DD/YYYY")}
              </Typography>
            </div>
          </div> : null}
      </div>
    </PageWrapper>
  );
});

const styles = {
  border: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    padding: "15px",
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-start",
  },
};

export default withRouter(WalletFields);