import { CommercialEntityTypes } from "../../../../common/types";

export interface UMBFormValues {
  walletType?: UMBApplicationTypes;
  accountName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  address: string;
  address2?: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  phone: string;
  ssn: string;
  dateOfBirth: string;
  countryOfBirth: string;
  yearOfUSCitizenship?: string;
  isUSCitizen: UMBYesNoValues;
  isUSCitizenW9: UMBYesNoValues;
  countryOfCitizenship?: string;
  isResidentAlien?: UMBYesNoValues;
  federalTaxClassification: UMBTaxClassifications | string;
  isTaxNumberOnFormCorrect: UMBYesNoValues;
  isNotSubjectToBackupWithholding?: UMBYesNoValues;
  notifiedByIRSSubjectToBackupWithholding?: UMBYesNoValues;
  doesFatcaCodeIndicateExempt: UMBYesNoValues;
  isPrimaryAccountHolder: UMBYesNoValues;
  areTaxStatementsAccurate: UMBYesNoValues;
  isOfficialGovernmentSenior: UMBYesNoValues;
  officialsRelationshipToApplicant?: string;
  officialsAffiliatedCountry?: string;
  officialsJobTitle?: string;
  identificationType: UMBIdTypes;
  driversLicenseIssuingState: string;
  passportCountry?: string;
  greenCardCategory?: string;
  USCISNumber: string;
  identificationNumber: string;
  documentExpirationDate: string;
  doesAddressMatchIdentification: UMBYesNoValues;
  doesNameMatchIdentification: UMBYesNoValues;
  reasonNameDoesNotMatchIdentification?: string;
  reasonAddressDoesNotMatchIdentification?: string;
  nameOnIdentification?: string;
  employmentType: UMBEmploymentTypes;
  isEmployed: UMBYesNoValues;
  employersName: string;
  interestTier: string;
  email: string;
  hasUploadedAllDocuments: UMBYesNoValues;
  occupation: string;
  kbaNumber: string;
  annualIncome: UMBIncomeTiers;
  econsentVersion: string;
  doesAgreeToEconsentTerms: UMBYesNoValues;
  jointApplicantFirstName?: string;
  jointApplicantMiddleName?: string;
  jointApplicantLastName?: string;
  jointApplicantEmail?: string;
  jointApplicantPhone?: string;

}

export enum UMBEmploymentTypes {
  FULL_TIME = "FULL_TIME",
  PART_TIME = "PART_TIME",
  RETIRED = "RETIRED",
  NOT_EMPLOYED = "NOT_EMPLOYED",
}

export enum UMBIdTypes {
  DRIVERS_LICENSE = "DRIVERS_LICENSE",
  STATE_ID = "STATE_ID",
  US_MILITARY_ID = "US_MILITARY_ID",
  PASSPORT = "PASSPORT",
  GREEN_CARD = "GREEN_CARD",
  VISA = "VISA",
}

export enum UMBYesNoValues {
  YES = "YES",
  NO = "NO",
  NOT_APPLICABLE = "NOT_APPLICABLE",
}
export const UMBCommercialYesNoValues = {
  YES: true,
  NO: false,
  NOT_APPLICABLE: undefined,
};
export enum UMBTaxClassifications {
  INDIVIDUAL = "INDIVIDUAL",
  TRUST = "TRUST",
  LLC = "LLC",
}

export enum UMBIncomeTiers {
  UNDER_100K = "UNDER_100K",
  BETWEEN_100K_300K = "BETWEEN_100K_300K",
  BETWEEN_300K_500K = "BETWEEN_300K_500K",
  ABOVE_500K = "ABOVE_500K",
}

export enum UMBApplicationTypes {
  INDIVIDUAL = "INDIVIDUAL",
  ENTITY = "COMMERCIAL ENTITY",
  JOINT = "JOINT",
  TRUST = "TRUST",
}

export interface UMBCommercialIdentification {
  driversLicenseNumber?: string;
  driversLicenseIssuingState?: string;
  driversLicenseExpirationDate?: string;
  passportNumber?: string;
  passportIssuingCountry?: string;
  passportExpirationDate?: string;
}

export interface UMBCommercialAddress {
  line1: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

export interface UMBBeneficialOwner {
  firstName: string;
  middleName: string;
  lastName: string;
  address: UMBCommercialAddress;
  phone: string;
  email: string;
  ssn: string;
  dateOfBirth: string;
  countryOfBirth: string;
  yearOfUSCitizenship: string;
  identification: UMBCommercialIdentification;
};

export interface UMBAuthorizedSigner {
  firstName: string;
  lastName: string;
  email: string;
  title: string;
}

export interface UMBCommercialFormValues {
  files: any[];
  doesConsentToElectronicRecords: string;
  applicant: {
    isAuthorizedManager: string;
    doesApplicantOwn25PercentOrMore: string;
    firstName: string;
    middleName: string;
    lastName: string;
    address: UMBCommercialAddress;
    phone: string;
    email: string;
    ssn: string;
    dateOfBirth: string;
    countryOfBirth: string;
    yearOfUSCitizenship: string;
    isUSCitizen: string;
    countryOfCitizenship: string;
    isResidentAlien: string;
    identification: UMBCommercialIdentification;
    driversLicenseNumber?: string;
    driversLicenseIssuingState?: string;
    driversLicenseExpirationDate?: string;
    passportNumber?: string;
    passportIssuingCountry?: string;
    passportExpirationDate?: string;
  };
  beneficialOwners: Array<UMBBeneficialOwner | null>;
  applicantIsBeneficialOwner: string;
  authorizedSignersWithACHAuthority: Array<UMBAuthorizedSigner | null>;
  company: {
    legalEntityName: string;
    dba: string;
    taxIdNumber: string;
    entityType: CommercialEntityTypes | "";
    organizationState: string;
    yearFounded: string;
    businessClassification: string;
    primaryBusinessActivity: string;
    address: UMBCommercialAddress;
    mailingAddress: UMBCommercialAddress;
    mailingAddressDifferentFromPrimary: string;
  };
  dueDilligence: {
    operatesMoneyServicesBusiness: string;
    hasOrProcessesATMTransactions: string;
    hasOverseasOffice: string;
    overseasOfficeLocation: string;
    controlledByNonUSPerson: string;
    foreignOwnershipDescription: string;
    businessHeadquarters: string;
    hasPEPOwnerOrController: string;
    ownerOrControllerPEPDescription: string;
    engagesInImportExportActivity: string;
    anticpatedImportExportGoodsInvolved: string;
    expectedImportExportTradeDollarAmount: string;
    anticipatedImportExportCountries: string;
    majorImportExportCustomersOrSuppliers: string;
    receivesOrSendsInfoForBets: string;
    isThirdPartyProcessorOrOriginator: string;
    processorOrOriginatorPaymentNatureDescription: string;
  };
}