import React, { useEffect, useState } from "react";
import { QueryKeys, useAccreditationStatuses } from "../../../../api/hooks/graphqlHooks";
import { Table, TableRow, TableCell, TableBody, TableHead } from "@mui/material";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { convertFromCreatedAtDateToRegDateString, isNotNil } from "../../../../common/utilities";
import { MenuItem, Typography } from "@material-ui/core";
import AccInput from "../../../../common/components/AccInput";
import { useQueryClient } from "react-query";
import PageWrapper from "../../../../common/components/PageWrapper";

interface IProps extends RouteComponentProps<any> {
  setNewAccreditationStatus?: any;
  accreditedInvestorData?: any;
  accreditedInvestorLoading: any;
  accreditedInvestorFetching: any;
}

const AccreditationInfo: React.FC<IProps> = ({
  setNewAccreditationStatus,
  accreditedInvestorData,
}: IProps) => {
  const queryClient = useQueryClient();
  const {
    data: useAccreditationStatusesData,
    isLoading: useAccreditationStatusesLoading,
    isFetching: useAccreditationStatusesFetching,
    isError: useAccreditationStatusesError,
  } = useAccreditationStatuses({
    refetchOnMount: true,
  });

  const [accreditationStatus, setAccreditationStatus] = useState(null);

  useEffect(() => {
    queryClient.removeQueries(QueryKeys.ACCREDITED_INVESTOR_REQUEST);
    queryClient.removeQueries(QueryKeys.ACCREDITATION_STATUSES);
  }, [useAccreditationStatusesData]);

  let accreditationCreationDate;
  let accreditationLastUpdateDate;

  if (isNotNil(accreditedInvestorData?.getAccreditedInvestorRequest)) {
    accreditationCreationDate = convertFromCreatedAtDateToRegDateString(accreditedInvestorData?.getAccreditedInvestorRequest.createdAt);
    accreditationLastUpdateDate = new Date(Number(accreditedInvestorData?.getAccreditedInvestorRequest.updatedAt) * 1000);
  }

  const handleChange = (e: any) => {
    setAccreditationStatus(e.target.value);
    setNewAccreditationStatus(useAccreditationStatusesData?.getAccreditationStatuses.find((status: any) => status.id === e.target.value)?.name);
  };

  return (
    <PageWrapper
      title={""}
      showLoader={
        useAccreditationStatusesLoading
        || useAccreditationStatusesFetching
        && isNotNil(accreditationLastUpdateDate) && isNotNil(accreditationCreationDate)
      }
      showError={useAccreditationStatusesError}
      errorMessage="Cannot retrieve accreditation info"
    >
    <div className="row column-mobile">
      <div className="width-100">{
          // isNotNil(accreditedInvestorData?.getAccreditedInvestorRequest)
          isNotNil(useAccreditationStatusesData?.getAccreditationStatuses)
            && isNotNil(accreditationLastUpdateDate) && isNotNil(accreditationCreationDate)
            ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">STATUS</TableCell>
                <TableCell align="center">TYPE</TableCell>
                <TableCell align="center">CREATED</TableCell>
                <TableCell align="center">LAST UPDATED</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                  <TableCell align="center">
                    <AccInput
                      name="accreditationStatusObjectId"
                      label="Status"
                      select
                      required
                      onChange={handleChange}
                      value={accreditationStatus ?? useAccreditationStatusesData?.getAccreditationStatuses.find(
                        (status: any) => status.name === accreditedInvestorData?.getAccreditedInvestorRequest.status)?.id}
                    >
                      <MenuItem
                        selected
                      >
                      </MenuItem>
                      {useAccreditationStatusesData?.getAccreditationStatuses
                        ?.map((item: any) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </AccInput>
                  </TableCell>
                <TableCell align="center">{accreditedInvestorData?.getAccreditedInvestorRequest.accreditationType}</TableCell>
                <TableCell align="center">{accreditationCreationDate.toString()}</TableCell>
                <TableCell align="center">{accreditationLastUpdateDate.toString()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : <Typography>There&apos;s no accreditation made for this wallet!</Typography>
      }
      </div >
    </div >
    </PageWrapper>
  );
};

export default withRouter(AccreditationInfo);