import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { useQueryClient } from "react-query";
import { QueryKeys } from "../../../../api/hooks/graphqlHooks";
import { ExternalAccount } from "../../../../common/types";
import BankAccountCard from "../../../../common/components/BankAccountCard";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { DELETE_EXTERNAL_ACCOUNT } from "../../../../api/query/ExternalAccounts";
import CancelConfirmDialogModal from "../../../../common/components/CancelConfirmDialogModal";

interface WalletSelectorParams {
  walletId: number;
}

const BankAccounts: React.FC<WalletSelectorParams> = ({ walletId }) => {
  const queryClient = useQueryClient();
  const externalAccounts: any = queryClient.getQueryData(QueryKeys.WALLET_EXTERNAL_ACCOUNTS);
  const [deleteExternalAccount] = useMutation(DELETE_EXTERNAL_ACCOUNT);
  const [selectedExternalAccountToDelete, setSelectedExternalAccountToDelete] = useState<string | null>(null);
  const [isDeleteExternalAccountModalVisible, setIsDeleteExternalAccountModalVisible] = useState<boolean>(false);

  const handleDeleteExternalAccount = async (objectId: string | number) => {
    try {
      await deleteExternalAccount({
        variables: {
          id: objectId,
        },
      });
      toast("External Account deleted successfully!", {
        type: toast.TYPE.SUCCESS,
      });
      queryClient.refetchQueries(QueryKeys.WALLET_EXTERNAL_ACCOUNTS);
    } catch (err: any) {
      setIsDeleteExternalAccountModalVisible(false);
      toast(err.message || err.msg || err.toString() || "There was an error deleting external account.", {
        type: toast.TYPE.ERROR,
      });
    }
  };


  return (
    <div>
      {externalAccounts.getExternalAccounts.map((item: ExternalAccount) =>
        <BankAccountCard
          deleteButtonVisible
          key={item.id}
          data={item}
          deleteOnClick={() => {
            setIsDeleteExternalAccountModalVisible(true);
            setSelectedExternalAccountToDelete(item.id);
          }}
        />,
      )}
      <CancelConfirmDialogModal
        isVisible={isDeleteExternalAccountModalVisible}
        dialogHeader="Are you sure you'd like to delete external account?"
        cancelButtonText="No"
        confirmButtonText="Yes"
        onClickCancel={() => setIsDeleteExternalAccountModalVisible(false)}
        onClickDelete={() => handleDeleteExternalAccount(selectedExternalAccountToDelete!)}
      />
      <Link to={`/investor/wallet/connectbankaccount/${walletId}`}>
        <Typography
          className="acc-lightgreen"
          style={{ textDecoration: "underline" }}>
          Connect bank account
        </Typography>
      </Link>
    </div>
  );
};

export default BankAccounts;