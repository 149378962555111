import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import React, { useRef, useState, useEffect } from "react";
import COLORS from "../../../../common/colors";
import FirstStep from "./FirstStep";
import { THEME_BREAKPOINTS } from "../../../../common/constants";
import useMediaQuery from "@mui/material/useMediaQuery";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import { toast } from "react-toastify";
import { SignatureContext } from "../../../../context";
import { Formik, FormikProps } from "formik";
import { Asset, InvestmentFormValue, InvestmentAmounts } from "../../../../common/types";
import { useQueryClient } from "react-query";
import Summary from "./Summary";
import { formatCurrency, isNotNil } from "../../../../common/utilities";
import { QueryKeys, useUpdateInvestment, useCreateInvestment, useUserWallets, useAccountAddresses, useCashFlow } from "../../../../api/hooks/graphqlHooks";
import { InvestmentStatus, InvestmentType } from "../../../../enums";
import { useHelloSignDocumentUrl, useHelloSignTemplateFilesUrl, useUMBAccount } from "../../../../api/hooks/restHooks";
import ProfileInformation from "./ProfileInformation";
import LoadingAnimation from "../../../../common/components/LoadingAnimation";
import AccButton, { AccButtonColors } from "../../../../common/components/AccButton";
import moment from "moment";
import { QueryKeys as RESTQueryKeys } from "../../../../api/hooks/restHooks";


export default function InvestmentForm() {

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  const [activeStep, setActiveStep] = useState(0);
  const [signatureData, setSignatureData] = useState<string>("");
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true);
  const [userWallet, setUserWallet] = useState<any | undefined>();
  const [templateId, setTemplateId] = useState<any | undefined>("");
  const [investmentId, setInvestmentId] = useState<any | undefined>("");
  const [showEditInvestmentModal, setShowEditInvestmentModal] = useState<boolean>(false);
  const [investmentAmount, setInvestmentAmount] = useState<any | undefined>();
  const queryClient = useQueryClient();
  const formRef = useRef<FormikProps<Partial<InvestmentFormValue>>>(null);
  const { isLoading: walletsDataLoading } = useUserWallets();
  const assetData: { getAsset?: Asset } | undefined = queryClient.getQueryData(QueryKeys.ASSET);
  const investmentData: any | undefined = queryClient.getQueryData(QueryKeys.CREATE_INVESTMENT);
  const { data: addressData } = useAccountAddresses();
  const {
    data: umbAccountData,
  } = useUMBAccount(userWallet?.umbAccountId ?? "", {
    enabled: isNotNil(userWallet?.umbAccountId),
  });
  const ifRequiresAccreditation = assetData?.getAsset?.dealType.requiresAccreditation === true;
  const investmentCommittedDate: any = moment(new Date()).local();

  const accountAddress = {
    street1: umbAccountData?.AccountAddress?.AddressLine1 ?? addressData?.getAccountAddresses[0]?.street1,
    city: umbAccountData?.AccountAddress?.AddressCity ?? addressData?.getAccountAddresses?.[0]?.city,
    postalCode: umbAccountData?.AccountAddress?.AddressPostalCode ?? addressData?.getAccountAddresses?.[0]?.postalCode,
    state: umbAccountData?.AccountAddress?.AddressState ?? addressData?.getAccountAddresses?.[0]?.stateProvince,
    country: umbAccountData?.AccountAddress?.AddressCountry ?? addressData?.getAccountAddresses?.[0]?.country,
  };

  const userAddress =
    `${accountAddress.street1}, ${accountAddress.city}, ${accountAddress.postalCode}, ${accountAddress.state}, ${accountAddress.country}`;

  const {
    isLoading: createInvestmentLoading,
    mutate: createInvestmentMutate,
  } = useCreateInvestment({
    input: {
      name: `${assetData?.getAsset?.name} Investment`,
      amount: investmentAmount,
      status: InvestmentStatus.ATTEMPTED,
      type: InvestmentType.INVESTMENT,
      assetObjectId: assetData?.getAsset?.id!,
      walletObjectId: userWallet?.id,
    },
  }, {
    enabled: isNotNil(assetData?.getAsset?.id),
    onSuccess: data => {
      queryClient.setQueryData(QueryKeys.CREATE_INVESTMENT, data?.createInvestment);
    },
  });

  const {
    data: getCashFlowData,
    isLoading: getCashFlowLoading,
    isFetching: getCashFlowFetching,
  } = useCashFlow(Number(assetData?.getAsset?.assetId), {
    enabled: isNotNil(assetData?.getAsset),
    refetchOnMount: true,
  });

  const updateInvestmentAmount = (data: number): void => {
    setInvestmentAmount(data);
  };

  const {
    refetch: fetchHelloSignDocumentUrl,
  } = useHelloSignDocumentUrl(investmentData?.id!, assetData?.getAsset?.id!, userAddress!, {
    enabled:
      isNotNil(investmentData?.id) && isNotNil(assetData?.getAsset?.id) && isNotNil(userAddress),
  });

  //Endpoint to force user to scroll through the document before signing
  const {
    data: getHelloSignTemplateFilesUrlData,
  } = useHelloSignTemplateFilesUrl(assetData?.getAsset?.dealType?.helloSignTemplateId!, {
    enabled:
      isNotNil(assetData?.getAsset?.dealType?.helloSignTemplateId),
    refetchOnMount: true,
  });
  //Delete before merge
  console.log("getHelloSignTemplateFilesUrlData", getHelloSignTemplateFilesUrlData);
  const {
    isLoading: updateInvestmentLoading,
    mutate: updateInvestmentMutate,
  } = useUpdateInvestment({
    id: investmentData?.id,
    input: {
      amount: investmentAmount,
      status: InvestmentStatus.COMMITTED,
      assetObjectId: assetData?.getAsset?.id!,
      walletObjectId: userWallet?.id,
      committedDate: investmentCommittedDate,
    },
  }, {
    enabled: isNotNil(assetData?.getAsset?.id),
    onSuccess: data => {
      queryClient.setQueryData(QueryKeys.CREATE_INVESTMENT, data?.updateInvestment);
      queryClient.refetchQueries(QueryKeys.USER_WALLETS);
      queryClient.refetchQueries(QueryKeys.USER_PORTFOLIO_INVESTMENTS);
      queryClient.refetchQueries(QueryKeys.ASSETS);
    },
    onError: (err: any) => {
      return toast(err.message || err.msg || err.toString() || "There was an error updating investment.", {
        type: toast.TYPE.ERROR,
      });
    },
  });

  if (!assetData) {
    window.location.href = "/investor/invest";
  }

  //Redirect to next step after signing documents
  useEffect(() => {
    if (activeStep === 2 && isNotNil(signatureData)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setNextButtonDisabled(true);
    }
  }, [activeStep, signatureData]);

  const handleNext = async () => {
    if (activeStep === 0) {
      try {
        createInvestmentMutate({
          input: {
            name: `${assetData?.getAsset?.name} Investment`,
            amount: investmentAmount,
            status: InvestmentStatus.ATTEMPTED,
            assetObjectId: assetData?.getAsset?.id!,
            walletObjectId: userWallet?.id,
          },
        });
        //Remove previous queries and regenerate SignatureRequestId
        queryClient.removeQueries(RESTQueryKeys.HELLO_SIGN_DOCUMENT_URL);
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error adding investment.", {
          type: toast.TYPE.ERROR,
        },
        );
      }
    } else if (activeStep === 2) {
      fetchHelloSignDocumentUrl();
    } else if (activeStep === 3) {
      try {
        setInvestmentAmount(investmentAmount);
        updateInvestmentMutate(null);
        queryClient.refetchQueries(QueryKeys.WALLETS);
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error updating investment.", {
          type: toast.TYPE.ERROR,
        });
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setNextButtonDisabled(true);

  };
  const handleBack = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setNextButtonDisabled(false);
  };

  const validateInvestmentData = (values: number | undefined | null) => {
    if (!values || (activeStep === 0 && values < assetData?.getAsset?.minimumInvestmentAmount!)) {
      toast.warning(`Minimum investment required: ${formatCurrency(assetData?.getAsset?.minimumInvestmentAmount ?? 0)}`);
    }
    else if (activeStep === 0 && values % assetData?.getAsset?.investmentAmountIncrement! !== 0) {
      toast.warning(`Investment should be divisible by ${formatCurrency(assetData?.getAsset?.investmentAmountIncrement ?? 0)}`);
    }
    // else if (!signatureData.length && activeStep === 2) {
    //   toast.warning("You must sign document to proceed to next step!");
    // }
    else if (activeStep === 0 && typeof userWallet === "undefined") {
      toast.error("You don't have funds in your accessWallet");
      setNextButtonDisabled(true);
    }
    else if (activeStep === 0 && userWallet.availableAmount < formRef?.current?.values?.investmentAmount!) {
      toast.warning("Your investment amount is higher than your accessWallet Balance");
      setNextButtonDisabled(true);
    }
    else if (activeStep === 0 && formRef?.current?.values?.investmentAmount! > assetData?.getAsset?.remainingAmount!) {
      toast.warning("You cannot invest more than remaining investment amount");
      setNextButtonDisabled(true);
    }
    else if (activeStep === 0 && formRef?.current?.values?.investmentAmount! > InvestmentAmounts.MAX_AMOUNT_TO_INVEST) {
      toast.warning("You cannot invest more then maximum investment amount");
      setNextButtonDisabled(true);
    } else {
      handleNext();
    }
  };

  const investmentFieldInitialFormValues = {
    investmentAmount: null,
  } as Partial<InvestmentFormValue>;

  if (!assetData) return <></>;

  if (updateInvestmentLoading || createInvestmentLoading || getCashFlowLoading || getCashFlowFetching) {
    return <LoadingAnimation />;
  }

  const steps = [
    {
      title: "Investment Amount",
      description: <FirstStep />,
    },
    {
      title: "Profile Information",
      description: <ProfileInformation address={accountAddress} />,
    },
    {
      title: "Investment Documents",
      description: <SecondStep
        showEditInvestmentModal={showEditInvestmentModal}
        setShowEditInvestmentModal={setShowEditInvestmentModal}
      />,
    },
    {
      title: "Investment Review",
      description: <ThirdStep
        showEditInvestmentModal={showEditInvestmentModal}
        setShowEditInvestmentModal={setShowEditInvestmentModal}
      />,
    },
  ];

  const checkCheckBoxValues = (values: Partial<InvestmentFormValue>) => {
    if (activeStep === 1 && values.isProfileInfoCorrect && values.confirmedDisqualifyingEvent && values.confirmedInvestmentProcedures) {
      if (ifRequiresAccreditation) {
        return values.confirmedAccreditedInvestor;
      } else return true;
    } else {
      return false;
    }
  };

  return (
    <SignatureContext.Provider
      value={{
        signatureURL: signatureData,
        setSignatureData,
        templateId,
        setTemplateId,
        setNextButtonDisabled,
        updateInvestmentAmount,
        setUserWallet,
        userWallet,
        walletsDataLoading,
        investmentId,
        setInvestmentId,
        setActiveStep,
        getCashFlowData,
      }}
    >
      <Formik
        innerRef={formRef}
        initialValues={investmentFieldInitialFormValues}
        onSubmit={() => {
        }}
      >
        {({ values }) => (
          < Box sx={{ width: "100%" }}>
            <Stepper
              alternativeLabel={mobileLayout ? true : false}
              activeStep={activeStep}
              sx={{
                ...styles.stepLabel,
                display: activeStep === steps.length ? "none" : "",
              }}
            >
              {steps.map((label) => (
                <Step key={label.title}>
                  <StepLabel>{label.title}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <div style={styles.container}>
                  <Summary />
                </div>
              ) : (
                <>
                  <Box
                    style={{
                      backgroundImage: `url(${assetData?.getAsset?.coverImageLocation})`,
                      ...styles.imageContainer,
                    }}
                  >
                    <div
                      className="m-top-50 column end stretch"
                      style={{ ...styles.innerContainer, padding: mobileLayout ? "50px 25px" : "35px 50px" }}>
                      {steps[activeStep].description}
                      <Box sx={{ display: "flex", flexDirection: "row", height: 45, marginTop: "30px", width: "100%" }}>
                        {activeStep === 0 ? (
                          ""
                        ) : (
                          <AccButton
                            width="100px"
                            label="Back"
                            color={AccButtonColors.WHITE}
                            disabled={activeStep === 0}
                            className={activeStep === 2 ? "width-100-mobile" : ""}
                            onClick={() => {
                              if (activeStep === 3 || activeStep === 2) {
                                setShowEditInvestmentModal(true);
                              } else {
                                handleBack();
                                setNextButtonDisabled(true);
                              }
                            }}
                          />
                        )}
                        <Box sx={{ flex: "1 1 auto" }} />
                        {activeStep === 2 ? null :
                          <AccButton
                            width="fit-content"
                            label={activeStep === 3 ? "Confirm Investment" : "Next"}
                            color={(nextButtonDisabled && !checkCheckBoxValues(values)) ? AccButtonColors.GREEN_OUTLINE : AccButtonColors.GREEN}
                            disabled={nextButtonDisabled && !checkCheckBoxValues(values)}
                            onClick={() => {
                              if (!assetData?.getAsset || !values) return;
                              else if (values) {
                                validateInvestmentData(investmentAmount);
                              };
                            }} />
                        }
                      </Box>
                    </div>
                  </Box>
                </>
              )}
            </div>
          </Box>
        )}
      </Formik>
    </SignatureContext.Provider >
  );
}


const styles = {
  container: {
    margin: "50px 0 0 0",
    height: "75vh",
  },
  imageContainer: {
    width: "100%",
    backgroundPosition: "right center",
    backgroundSize: "60% 100%",
    backgroundRepeat: "no-repeat",
    borderRadius: 16,
  },
  innerContainer: {
    borderRadius: "16px",
    height: "fit-content",
    minHeight: "700px",
    background: COLORS.layeredImageGradient,
    display: "flex",
  },
  stepLabel: {
    "& .MuiStepIcon-root.Mui-active": {
      color: COLORS.green,
      fontWeight: 600,
      fontSize: "24px !important",
    },
    "& .MuiStepLabel-root .Mui-active": {
      fontWeight: 600,
      fontSize: 18,
    },
    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
      fill: COLORS.darkBlue, // circle's number (ACTIVE)
      fontWeight: 600,
      color: COLORS.darkGray,
    },
    "& .MuiStepLabel-root .Mui-completed": {
      fontWeight: 600,
      color: COLORS.darkGray,
    },
    "& .MuiStepIcon-root.Mui-completed": {
      color: COLORS.darkBlue,
    },
  },
};
