import React, { useEffect, useState } from "react";
import PageCard from "../../../common/components/PageCard";
import { Divider, Typography } from "@material-ui/core";
import { QueryKeys, useWallet, useWalletExternalAccounts } from "../../../api/hooks/graphqlHooks";
import PageWrapper from "../../../common/components/PageWrapper";
import { isNil, isNotNil } from "../../../common/utilities";
import { useQueryClient } from "react-query";
import WalletSelector from "../../../common/components/WalletSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity } from "@fortawesome/pro-light-svg-icons";
import PlaidLink from "../../../common/components/PlaidLink";
import { RouteComponentProps, withRouter } from "react-router";

interface WalletSelectorParams {
  id?: string;
}

type WalletSelectorDetailProps = RouteComponentProps<WalletSelectorParams>;

const ConnectBankAccount: React.FC<WalletSelectorDetailProps> = function ({ match }) {
  const queryClient = useQueryClient();

  const {
    data: walletData,
    isFetching: walletFetching,
    isLoading: walletLoading,
  } = useWallet(Number(match.params.id), {
    refetchOnMount: true,
  });

  const [selectedWallet, setSelectedWallet] = useState(walletData?.getWallet ?? null);

  const {
    data: externalAccountsData,
    isFetching: externalAccountsFetching,
    isLoading: externalAccountsLoading,
    refetch: refetchExternalAccounts,
  } = useWalletExternalAccounts(walletData?.getWallet.walletId ?? 0, {
    enabled: isNotNil(walletData),
    refetchOnMount: true,
  });

  useEffect(() => {
    if (isNil(walletData)) {
      return;
    }
    queryClient.removeQueries(QueryKeys.WALLET_EXTERNAL_ACCOUNTS);
    queryClient.invalidateQueries(QueryKeys.WALLET_EXTERNAL_ACCOUNTS);
    refetchExternalAccounts();
    return function cleanup() {
      queryClient.removeQueries(QueryKeys.WALLET_EXTERNAL_ACCOUNTS);
      queryClient.invalidateQueries(QueryKeys.WALLET_EXTERNAL_ACCOUNTS);
    };
  }, [walletData]);

  return (
    <PageWrapper
      title="Connect Bank Account"
      subtitle="Connect securely your external bank accounts to fund your wallets."
      showLoader={externalAccountsFetching || externalAccountsLoading || walletLoading || walletFetching}
    >
      <PageCard className="width-50 m-top-30 width-100-mobile">
        <Typography
          className="acc-gray"
          style={{ marginBottom: 30 }}>
          Please select the investment account you would like to connect your bank account to:
        </Typography>
        <WalletSelector
          userWallet={selectedWallet}
          isFundingWallet={false}
          displayCTAs={false}
          setUserWallet={setSelectedWallet}
        />
        <div className="m-top-30">
          {isNotNil(walletData) &&
            <>
              <Typography>
                {externalAccountsData?.getExternalAccounts.length! > 0 ? "Currently connected bank accounts:" :
                  "You don't have connected bank accounts!"
                }</Typography>
              {externalAccountsData?.getExternalAccounts.map(item => (
                <>
                  <div
                    key={item.id}
                    className="row stretch center width-100 m-top-30">
                    <div className="row center m-right-15">
                      <FontAwesomeIcon
                        className="acc-green m-right-15"
                        size="1x"
                        icon={faUniversity} />
                      <Typography
                        variant="subtitle1"
                        className="acc-gray bold">
                        {item.bankName} - {item.displayName}
                      </Typography>
                    </div>
                    <Typography
                      variant="caption"
                      className="acc-gray light">
                      ****{item.lastFour}
                    </Typography>
                  </div>
                  <Divider />
                </>
              ))}
            </>
          }
        </div>
        <div className="row center m-top-30">
          {isNotNil(walletData) &&
            <PlaidLink walletId={walletData?.getWallet.walletId} />
          }
        </div>
      </PageCard>
    </PageWrapper>
  );
};


export default withRouter(ConnectBankAccount);
