import React, { useContext, useState } from "react";
import { Formik } from "formik";
import { UMBEmploymentTypes, UMBFormValues, UMBIdTypes, UMBIncomeTiers, UMBYesNoValues } from "./common/umb.interfaces";
import { LabelValueInput } from "../../../common/types";
import RadioSelectCard from "../../../common/components/RadioSelectCard";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import AccInput, { AccMaskedInputTypes } from "../../../common/components/AccInput";
import { isNil, isNotNil, over100OrLessThan18YearsAgo, validatePhoneNumber } from "../../../common/utilities";
import { COUNTRIES, CountryCodes } from "../../../common/constants";
import AccOptionSelect from "../../../common/components/AccInput/variants/AccOptionSelect";
import { onboardUMBCustomer } from "../../../api/rest/umb";
import { QueryKeys, useAccountAddresses, usePendingAccountOnboardApplications } from "../../../api/hooks/graphqlHooks";
import { SessionContext } from "../../../context";
import { useQueryClient } from "react-query";
import { secondaryJointFormPageOrder, FormPages, formSelectOptions, UMBFormValueSchema } from "./common/umb.consts";
import LoadingAnimation from "../../../common/components/LoadingAnimation";
import COLORS from "../../../common/colors";
import { Checkbox, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationSquare, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { WalletTypes } from "../../../enums";
import { useRouteQuery } from "../../../common/hooks";
import { PatriotAct } from "./components/PatriotAct";
import { ConsumerW9Form } from "./components/ConsumerW9Form";
import { ConsumerIdentificationForm } from "./components/ConsumerIdentificationForm";
import { VerifyConsumerPersonalInformation } from "./components/VerifyConsumerPersonalInformation";
import { MFARequest } from "./components/MFARequest";
import { StyledInput } from "./components/ThemedComponents";
import { useNestedFormState } from "./common/useNestedFormState";
import PageWrapper from "../../../common/components/PageWrapper";
import CustomTooltip from "../../../common/components/CustomTooltip";

export const UMBJointOnboard = () => {
  const routeQuery = useRouteQuery();
  const [currentPage, setCurrentPage] = useState<FormPages>(secondaryJointFormPageOrder[0]);
  const [happyPathEnabled, setHappyPathEnabled] = useState(false);
  const [accessTermsChecked, setAccessTermsChecked] = useState(happyPathEnabled ? true : false);
  const [onboardResponse, setOnboardResponse] = useState("");
  const [onboardSubmitting, setOnboardSubmitting] = useState(false);
  const { user, mfaSessionId } = useContext(SessionContext);
  const queryClient = useQueryClient();
  const { data: addressData } = useAccountAddresses();
  const { data: pendingApplicationData } = usePendingAccountOnboardApplications();
  const { nestedCurrentPages, setNestedCurrentPages } = useNestedFormState();

  const currentPendingApplication =
    pendingApplicationData?.getPendingAccountOnboardApplications?.find(item => item.secondaryApplicantUserObjectId === user?.id);


  const existingEntity = routeQuery.get("existingEntity");

  // Non-UMB Form values:
  const walletType = WalletTypes.JOINT;
  const accountName = isNotNil(currentPendingApplication) ? currentPendingApplication?.walletName : "Joint Wallet";
  const initialValues: Partial<UMBFormValues> = {
    firstName: happyPathEnabled ? "APRIL" : user?.firstName,
    middleName: happyPathEnabled ? "" : user?.middleName,
    lastName: happyPathEnabled ? "DAWKINS" : user?.lastName,
    address: happyPathEnabled ? "4587 Biscay Street" : addressData?.getAccountAddresses?.[0]?.street1,
    address2: happyPathEnabled ? "" : addressData?.getAccountAddresses?.[0]?.street2,
    city: happyPathEnabled ? "Denver" : addressData?.getAccountAddresses?.[0]?.city,
    state: happyPathEnabled ? "CO" : addressData?.getAccountAddresses?.[0]?.stateProvince,
    country: happyPathEnabled ? "US" : addressData?.getAccountAddresses?.[0]?.country,
    postalCode: happyPathEnabled ? "80249" : addressData?.getAccountAddresses?.[0]?.postalCode,
    phone: happyPathEnabled ? "5635293344" : user?.phone,
    email: happyPathEnabled ? "parker@test.com" : user?.email,
    ssn: happyPathEnabled ? "498457210" : undefined,
    dateOfBirth: happyPathEnabled ? "1955-08-31" : undefined,
    countryOfBirth: happyPathEnabled ? "US" : undefined,
    yearOfUSCitizenship: undefined,
    isUSCitizen: happyPathEnabled ? UMBYesNoValues.YES : undefined,
    countryOfCitizenship: undefined,
    isResidentAlien: undefined,
    federalTaxClassification: WalletTypes.INDIVIDUAL, // Question to UMB -- Why will this fail if its JOINT?
    isTaxNumberOnFormCorrect: UMBYesNoValues.YES,
    isNotSubjectToBackupWithholding: UMBYesNoValues.YES,
    notifiedByIRSSubjectToBackupWithholding: happyPathEnabled ? UMBYesNoValues.NO : undefined,
    doesFatcaCodeIndicateExempt: UMBYesNoValues.YES,
    isPrimaryAccountHolder: happyPathEnabled ? UMBYesNoValues.YES : undefined,
    areTaxStatementsAccurate: happyPathEnabled ? UMBYesNoValues.YES : UMBYesNoValues.NO,
    isOfficialGovernmentSenior: happyPathEnabled ? UMBYesNoValues.NO : undefined,
    officialsRelationshipToApplicant: undefined,
    officialsAffiliatedCountry: undefined,
    officialsJobTitle: undefined,
    identificationType: happyPathEnabled ? UMBIdTypes.DRIVERS_LICENSE : undefined,
    driversLicenseIssuingState: happyPathEnabled ? "CO" : undefined,
    passportCountry: undefined,
    greenCardCategory: undefined,
    USCISNumber: undefined,
    identificationNumber: happyPathEnabled ? "C160-202-239-392" : undefined,
    documentExpirationDate: happyPathEnabled ? "2025-05-25" : undefined,
    doesAddressMatchIdentification: happyPathEnabled ? UMBYesNoValues.YES : undefined,
    doesNameMatchIdentification: happyPathEnabled ? UMBYesNoValues.YES : undefined,
    reasonNameDoesNotMatchIdentification: undefined,
    reasonAddressDoesNotMatchIdentification: undefined,
    nameOnIdentification: undefined,
    employmentType: happyPathEnabled ? UMBEmploymentTypes.FULL_TIME : undefined,
    isEmployed: happyPathEnabled ? UMBYesNoValues.YES : undefined,
    employersName: happyPathEnabled ? "AAA" : undefined,
    hasUploadedAllDocuments: undefined,
    occupation: happyPathEnabled ? "AAA" : undefined,
    kbaNumber: mfaSessionId,
    interestTier: "1",
    annualIncome: happyPathEnabled ? UMBIncomeTiers.UNDER_100K : undefined,
    // willIssueDebitCard: happyPathEnabled ? UMBYesNoValues.NO : undefined,
    // willUtilizeCheckWriting: happyPathEnabled ? UMBYesNoValues.NO : undefined,
    econsentVersion: "1.0",
    doesAgreeToEconsentTerms: UMBYesNoValues.YES,
    // doesConsentToElectronicRecords: happyPathEnabled ? UMBYesNoValues.YES : UMBYesNoValues.NO,
  };

  const isForwardDisabled = (values: Partial<UMBFormValues>, errors: any) => {
    if (errors[currentPage]) {
      return true;
    }
    // Weird behavior for date objects with 1-2 digit year values
    if (currentPage === FormPages.DateOfBirth) {
      if (over100OrLessThan18YearsAgo(values.dateOfBirth ?? "")) {
        return true;
      }
    }
    if (
      currentPage === FormPages.VerifyInitialInformation
    ) {
      return (
        (isNil(values.firstName) || errors.firstName) ||
        (isNil(values.lastName) || errors.lastName) ||
        (isNil(values.dateOfBirth) || errors.dateOfBirth || over100OrLessThan18YearsAgo(values.dateOfBirth)) ||
        (isNil(values.address) || errors.address) ||
        (isNil(values.city) || errors.city) ||
        (isNil(values.state) || errors.state || values.state === "Test") ||
        (isNil(values.country) || errors.country) ||
        (isNil(values.countryOfBirth) || errors.countryOfBirth) ||
        (isNil(values.ssn) || errors.ssn) ||
        (isNil(values.phone) || errors.phone || !validatePhoneNumber(values.phone.toString())) ||
        (isNil(values.email) || errors.email) ||
        (isNil(values.country) || errors.country)
      );
    }
    if (currentPage === FormPages.IdentificationType) {
      return (
        (isNil(values.documentExpirationDate) || errors.documentExpirationDate) ||
        isNil(values.doesNameMatchIdentification) ||
        ((
          values.identificationType === UMBIdTypes.DRIVERS_LICENSE ||
          values.identificationType === UMBIdTypes.STATE_ID
        ) ?
          isNil(values.driversLicenseIssuingState) ||
          isNil(values.identificationNumber) ||
          isNil(values.doesAddressMatchIdentification) :
          false
        ) ||
        ((
          values.identificationType === UMBIdTypes.PASSPORT
        ) ?
          isNil(values.passportCountry) ||
          isNil(values.identificationNumber) ||
          isNil(values.doesAddressMatchIdentification) :
          false
        ) ||
        ((
          values.identificationType === UMBIdTypes.GREEN_CARD
        ) ?
          isNil(values.greenCardCategory) || isNil(values.USCISNumber) :
          false
        ) ||
        ((
          values.identificationType === UMBIdTypes.US_MILITARY_ID
        ) ?
          isNil(values.identificationNumber) :
          false
        ) ||
        (values.doesAddressMatchIdentification === UMBYesNoValues.NO ?
          isNil(values.reasonAddressDoesNotMatchIdentification) :
          false
        ) ||
        (values.doesNameMatchIdentification === UMBYesNoValues.NO ?
          isNil(values.reasonNameDoesNotMatchIdentification) || isNil(values.nameOnIdentification) :
          false
        )
      );
    }

    if (currentPage === FormPages.WalletType) return isNil(walletType);
    if (currentPage === FormPages.AccountName) return isNil(accountName);
    if (currentPage === FormPages.USAPatriotAct) return false;
    if (currentPage === FormPages.AreTaxStatementsAccurate) {
      return values.areTaxStatementsAccurate === UMBYesNoValues.NO || isNil(values.areTaxStatementsAccurate);
    }
    if (currentPage === FormPages.IsPrimaryAccountHolder) {
      return values.isPrimaryAccountHolder === UMBYesNoValues.NO || isNil(values.isPrimaryAccountHolder);
    }
    if (currentPage === FormPages.NotifiedByIRSSubjectToBackupWithholding) {
      return values.notifiedByIRSSubjectToBackupWithholding === UMBYesNoValues.YES || isNil(values.notifiedByIRSSubjectToBackupWithholding);
    }
    if (currentPage === FormPages.SubmitScreen) {
      return !accessTermsChecked;
    }
    if (currentPage === FormPages.IsUSCitizen && nestedCurrentPages[FormPages.IsUSCitizen].currentPageNumber === 2) {
      return values.isResidentAlien === UMBYesNoValues.NO || isNil(values.isResidentAlien);
    }
    if (currentPage === FormPages.CountryOfBirth && nestedCurrentPages[FormPages.CountryOfBirth].currentPageNumber === 2) {
      return isNil(values.countryOfCitizenship) && isNil(values.yearOfUSCitizenship) || errors.yearOfUSCitizenship;
    }

    // Nested pages:
    if (nestedCurrentPages[currentPage]) {
      const { nestedValues, currentPageNumber } = nestedCurrentPages[currentPage];
      if (typeof nestedValues[currentPageNumber] === "string") {
        // Pages with one value
        // @ts-ignore
        return errors[nestedValues[currentPageNumber]] || isNil(values[nestedValues[currentPageNumber]]);
      } else {
        // Pages with multiple values
        // @ts-ignore
        return nestedValues[currentPageNumber].some((value: string) => isNil(values[value]));
      }
    }

    // Default check if form value name is currentPage name
    // @ts-ignore
    return isNil(values?.[currentPage]);
  };

  const submitOnboard = async (values: Partial<UMBFormValues>) => {
    setOnboardSubmitting(true);
    const submitValues = { ...values };
    submitValues.isUSCitizenW9 = submitValues.isUSCitizen;
    submitValues.ssn = submitValues.ssn?.replaceAll("-", "");
    if (submitValues.isUSCitizen === UMBYesNoValues.YES) {
      delete submitValues.countryOfCitizenship;
      delete submitValues.yearOfUSCitizenship;
    }
    // submitValues.notifiedByIRSSubjectToBackupWithholding = submitValues.isNotSubjectToBackupWithholding;
    delete submitValues.address2;
    const response = await onboardUMBCustomer({
      questionSetAnswers: submitValues,
      walletName: accountName,
      walletType,
      existingEntity,
    });
    setOnboardResponse(JSON.stringify(response?.data?.onboardResponse ?? response?.data, null, 2));
    if (isNotNil(response?.data?.onboardResponse)) {
      setOnboardSubmitting(false);
      queryClient.invalidateQueries([QueryKeys.USER_WALLETS]);
      queryClient.refetchQueries([QueryKeys.USER_WALLETS]);
      window.location.href = "/investor/accounts";
    }
    setOnboardSubmitting(false);
  };


  if (onboardSubmitting) {
    return <LoadingAnimation />;
  }
  console.log("****CURRENT", currentPage);
  return (
    <PageWrapper
      showLoader={onboardSubmitting}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={UMBFormValueSchema}
        onSubmit={submitOnboard}
      >
        {({ values, handleChange, setFieldValue, errors, touched }) => (
          <div
            className="column center stretch"
            style={{ background: COLORS.darkBlueGradient, borderRadius: 16, height: "fit-content", minHeight: 750, padding: 35 }}>
            <AccButton
              disabled={happyPathEnabled}
              color={happyPathEnabled ? AccButtonColors.WHITE : AccButtonColors.GREEN}
              onClick={() => setHappyPathEnabled(true)}
              label={happyPathEnabled ? "Happy path enabled" : "Enable happy path"}
              style={{ width: 200, marginBottom: 50 }}
            />
            <div className="width-100 column center m-bottom-50">
              {currentPage === FormPages.MFALogin && (
                <MFARequest onSuccess={() => setCurrentPage(FormPages.USAPatriotAct)} />
              )}
              {currentPage === FormPages.USAPatriotAct && (
                <PatriotAct />
              )}
              {currentPage === FormPages.DateOfBirth && (
                <>
                  <Typography
                    style={{ marginBottom: 30, textAlign: "center" }}
                    variant="h5"
                    className="acc-white bold">What is your date of birth?</Typography>
                  <StyledInput
                    autoFocus
                    as={AccInput}
                    type="date"
                    className="width-50 width-100-mobile"
                    name="dateOfBirth"
                    value={values.dateOfBirth}
                    onChange={handleChange}
                    label="Date of Birth"
                    helperText={errors.dateOfBirth}
                    error={errors.dateOfBirth}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </>
              )}
              {currentPage === FormPages.IsUSCitizen && (
                <>
                  {nestedCurrentPages[FormPages.IsUSCitizen].currentPageNumber === 1 && (
                    <>
                      <Typography
                        style={{ marginBottom: 30, textAlign: "center" }}
                        variant="h5"
                        className="acc-white bold">Are you a US citizen?</Typography>
                      <div className="radio-container column center">
                        {formSelectOptions.yesNoOptions.map((item: LabelValueInput) => (
                          <RadioSelectCard
                            key={item.label}
                            onClick={() => setFieldValue("isUSCitizen", item.value)}
                            selected={values.isUSCitizen === item.value}
                            label={item.label}
                            cardStyle={{ marginBottom: 35, width: "80%" }}
                            className="width-100-mobile"
                          />
                        ))}
                      </div>
                    </>
                  )}
                  {nestedCurrentPages[FormPages.IsUSCitizen].currentPageNumber === 2 && (
                    <>
                      <Typography
                        style={{ marginBottom: 30, textAlign: "center", display: "flex" }}
                        variant="h5"
                        className="acc-white bold">Are you a Resident alien?&nbsp;
                        <CustomTooltip
                          title={
                            <>
                              <span className="bold">Resident Alien:</span>
                              <span> You are a resident alien of the United States for tax purposes if you are not a U.S.
                                citizen but you meet either the green card test or the substantial presence test for the calendar year.</span>
                            </>
                          }
                          background={COLORS.white}
                          color={COLORS.blue}
                          placement="right"
                          fontSize={12}
                          fontWeight={500}>
                          <FontAwesomeIcon
                            color={COLORS.green}
                            icon={faInfoCircle}
                            style={{ fontSize: 15 }} />
                        </CustomTooltip>&nbsp;
                      </Typography>
                      {values.isResidentAlien === UMBYesNoValues.NO &&
                        <div
                          className="m-bottom-30 row center width-35 width-100-mobile"
                          style={{ background: COLORS.red, padding: 20, borderRadius: 16 }}>
                          <FontAwesomeIcon
                            icon={faExclamationSquare}
                            color={COLORS.darkBlue}
                            size="lg"
                            className="m-right-15" />
                          <Typography
                            variant="caption"
                            className="acc-darkblue bold condensed-lineheight">Unfortunately we cannot open an account if you are not a resident alien.</Typography>
                        </div>
                      }
                      <div className="radio-container column center">
                        {formSelectOptions.yesNoOptions.map((item: LabelValueInput) => (
                          <RadioSelectCard
                            key={item.label}
                            onClick={() => setFieldValue("isResidentAlien", item.value)}
                            selected={values.isResidentAlien === item.value}
                            label={item.label}
                            cardStyle={{ marginBottom: 35, width: "80%" }}
                            className="width-100-mobile"
                          />
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
              {currentPage === FormPages.CountryOfBirth && (
                <>
                  {nestedCurrentPages[FormPages.CountryOfBirth].currentPageNumber === 1 && (
                    <>
                      <Typography
                        style={{ marginBottom: 30, textAlign: "center" }}
                        variant="h5"
                        className="acc-white bold">Please provide the country you were born in.</Typography>
                      <StyledInput
                        as={AccOptionSelect}
                        autoFocus
                        className="width-50 width-100-mobile"
                        options={COUNTRIES}
                        name="countryOfBirth"
                        value={values.countryOfBirth}
                        onChange={handleChange}
                        label="Select Country"
                      />
                    </>
                  )}
                  {nestedCurrentPages[FormPages.CountryOfBirth].currentPageNumber === 2 && (
                    <>
                      {values.countryOfBirth !== CountryCodes.UnitedStates && values.isUSCitizen === UMBYesNoValues.YES &&
                        <>
                          <Typography
                            style={{ marginBottom: 30, textAlign: "center" }}
                            variant="h5"
                            className="acc-white bold">What year did you become a U.S. citizen?</Typography>
                          <StyledInput
                            as={AccInput}
                            autoFocus
                            className="width-50 width-100-mobile"
                            type="number"
                            name="yearOfUSCitizenship"
                            value={values.yearOfUSCitizenship}
                            onChange={handleChange}
                            label="Year of Citizenship"
                            helperText={errors.yearOfUSCitizenship && touched.yearOfUSCitizenship ? errors.yearOfUSCitizenship : null}
                            error={errors.yearOfUSCitizenship}
                          />
                        </>
                      }
                      {values.countryOfBirth !== CountryCodes.UnitedStates &&
                        values.isUSCitizen === UMBYesNoValues.NO &&
                        values.isResidentAlien === UMBYesNoValues.YES &&
                        <>
                          <Typography
                            style={{ marginBottom: 30, textAlign: "center" }}
                            variant="h5"
                            className="acc-white bold">Please provide your country of citizenship:</Typography>
                          <StyledInput
                            as={AccOptionSelect}
                            autoFocus
                            className="width-50 width-100-mobile"
                            name="countryOfCitizenship"
                            value={values.countryOfCitizenship}
                            onChange={handleChange}
                            label="Country"
                            options={COUNTRIES}
                          />
                        </>}
                    </>
                  )}
                </>
              )}
              {currentPage === FormPages.SSN && (
                <>
                  <Typography
                    style={{ marginBottom: 30, textAlign: "center" }}
                    variant="h5"
                    className="acc-white bold">Please provide your social security number.</Typography>
                  <StyledInput
                    as={AccInput}
                    autoFocus
                    maskedInput
                    mask={AccMaskedInputTypes.SSN}
                    className="width-50 width-100-mobile"
                    name="ssn"
                    value={values.ssn}
                    onChange={handleChange}
                    label="Social Security Number"
                    helperText={errors.ssn && touched.ssn ? errors.ssn : null}
                    error={errors.ssn}
                  />
                </>
              )}
              {currentPage === FormPages.VerifyInitialInformation && (
                <VerifyConsumerPersonalInformation />
              )}
              {currentPage === FormPages.IsOfficialGovernmentSenior && (
                <>
                  {nestedCurrentPages[FormPages.IsOfficialGovernmentSenior].currentPageNumber === 1 && (
                    <>
                      <Typography
                        style={{ marginBottom: 30, width: "80%", textAlign: "center" }}
                        variant="h5"
                        className="acc-white bold width-100-mobile">
                        Are you or is anyone in your immediate family a senior level
                        government official or politician in a national office, foreign or domestic?
                      </Typography>
                      <div className="radio-container column center">
                        {formSelectOptions.yesNoOptions.map((item: LabelValueInput) => (
                          <RadioSelectCard
                            key={item.label}
                            onClick={() => setFieldValue("isOfficialGovernmentSenior", item.value)}
                            selected={values.isOfficialGovernmentSenior === item.value}
                            label={item.label}
                            cardStyle={{ marginBottom: 35, width: "80%" }}
                            className="width-100-mobile"
                          />
                        ))}
                      </div>
                    </>
                  )}
                  {nestedCurrentPages[FormPages.IsOfficialGovernmentSenior].currentPageNumber === 2 && (
                    <>
                      <Typography
                        style={{ marginBottom: 30, textAlign: "center" }}
                        variant="h5"
                        className="acc-white bold">
                        Please provide the individual/government official&apos;s relation to you.
                      </Typography>
                      <StyledInput
                        as={AccInput}
                        autoFocus
                        className="width-50 width-100-mobile"
                        name="officialsRelationshipToApplicant"
                        value={values.officialsRelationshipToApplicant}
                        onChange={handleChange}
                        label={isNotNil(values.officialsRelationshipToApplicant) ? "Relationship" : "Relationship (Parent, spouse, child etc.)"}
                      />
                    </>
                  )}
                  {nestedCurrentPages[FormPages.IsOfficialGovernmentSenior].currentPageNumber === 3 && (
                    <>
                      <Typography
                        style={{ marginBottom: 30, textAlign: "center" }}
                        variant="h5"
                        className="acc-white bold">
                        Please select the country where you or this family member serves in an official or political role.
                      </Typography>
                      <StyledInput
                        as={AccOptionSelect}
                        autoFocus
                        className="width-50 width-100-mobile"
                        name="officialsAffiliatedCountry"
                        value={values.officialsAffiliatedCountry}
                        onChange={handleChange}
                        label="Country"
                        options={COUNTRIES}
                      />
                    </>
                  )}
                  {nestedCurrentPages[FormPages.IsOfficialGovernmentSenior].currentPageNumber === 4 && (
                    <>
                      <Typography
                        style={{ marginBottom: 30, textAlign: "center" }}
                        variant="h5"
                        className="acc-white bold">
                        Please provide your official title/role or the individual&apos;s official title/role.
                      </Typography>
                      <StyledInput
                        as={AccInput}
                        autoFocus
                        className="width-50 width-100-mobile"
                        name="officialsJobTitle"
                        value={values.officialsJobTitle}
                        onChange={handleChange}
                        label="Title/Role"
                      />
                    </>
                  )}
                </>
              )}
              {currentPage === FormPages.IdentificationType && (
                <ConsumerIdentificationForm />
              )}
              {currentPage === FormPages.IsEmployed && (
                <>
                  {nestedCurrentPages.isEmployed.currentPageNumber === 1 && (
                    <>
                      <Typography
                        style={{ marginBottom: 30, textAlign: "center" }}
                        variant="h5"
                        className="acc-white bold">Are you employed?</Typography>
                      <div className="radio-container column center">
                        {formSelectOptions.yesNoOptions.map((item: LabelValueInput) => (
                          <RadioSelectCard
                            key={item.label}
                            onClick={() => setFieldValue("isEmployed", item.value)}
                            selected={values.isEmployed === item.value}
                            label={item.label}
                            cardStyle={{ marginBottom: 35, width: "80%" }}
                            className="width-100-mobile"
                          />
                        ))}
                      </div>
                    </>
                  )}
                  {nestedCurrentPages.isEmployed.currentPageNumber === 2 && (
                    <>
                      <Typography
                        style={{ marginBottom: 30, textAlign: "center" }}
                        variant="h5"
                        className="acc-white bold">Employment</Typography>
                      <div className="row width-70 width-100-mobile column-mobile">
                        <div className="width-50 column start m-right-50 width-100-mobile m-right-0-mobile m-bottom-15-mobile">
                          <Typography
                            style={{ marginBottom: 10 }}
                            variant="subtitle2"
                            className="acc-white bold">How are you employed?</Typography>
                          <StyledInput
                            margin="dense"
                            as={AccOptionSelect}
                            autoFocus
                            name="employmentType"
                            value={values.employmentType}
                            onChange={handleChange}
                            label="Employment Type"
                            select
                            options={formSelectOptions["employmentType"]}
                          />
                          <Typography
                            style={{ marginBottom: 10, marginTop: 20 }}
                            variant="subtitle2"
                            className="acc-white bold">What is your occupation?</Typography>
                          <StyledInput
                            margin="dense"
                            as={AccInput}
                            name="occupation"
                            value={values.occupation}
                            onChange={handleChange}
                            label="Occupation"
                          />
                        </div>
                        <div className="width-50 column start m-left-50 width-100-mobile m-0-mobile">
                          <Typography
                            style={{ marginBottom: 10 }}
                            variant="subtitle2"
                            className="acc-white bold">What is your employer&apos;s name?</Typography>
                          <StyledInput
                            margin="dense"
                            as={AccInput}
                            name="employersName"
                            value={values.employersName}
                            onChange={handleChange}
                            label="Employer Name"
                          />
                          <Typography
                            style={{ marginBottom: 10, marginTop: 20 }}
                            variant="subtitle2"
                            className="acc-white bold">What is your annual income?</Typography>
                          <StyledInput
                            margin="dense"
                            as={AccOptionSelect}
                            name="annualIncome"
                            value={values.annualIncome}
                            onChange={handleChange}
                            label="Annual Income"
                            select
                            options={formSelectOptions.annualIncome}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              {currentPage === FormPages.IsPrimaryAccountHolder && (
                <>
                  {nestedCurrentPages.isPrimaryAccountHolder.currentPageNumber === 1 && (
                    <>
                      <Typography
                        style={{ marginBottom: 30, textAlign: "center" }}
                        variant="h5"
                        className="acc-white bold">Are you the primary Account Holder?</Typography>
                      {values.isPrimaryAccountHolder === UMBYesNoValues.NO &&
                        <div
                          className="m-bottom-30 row center width-35 width-100-mobile"
                          style={{ background: COLORS.red, padding: 20, borderRadius: 16 }}>
                          <FontAwesomeIcon
                            icon={faExclamationSquare}
                            color={COLORS.darkBlue}
                            size="lg"
                            className="m-right-15" />
                          <Typography
                            variant="caption"
                            className="acc-darkblue bold condensed-lineheight">Unfortunately we cannot open an account if you are not the primary account holder.</Typography>
                        </div>
                      }
                      <div className="radio-container column center">
                        {formSelectOptions.yesNoOptions.map((item: LabelValueInput) => (
                          <RadioSelectCard
                            key={item.label}
                            onClick={() => setFieldValue("isPrimaryAccountHolder", item.value)}
                            selected={values.isPrimaryAccountHolder === item.value}
                            label={item.label}
                            cardStyle={{ marginBottom: 35, width: "80%" }}
                            className="width-100-mobile"
                          />
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
              {currentPage === FormPages.NotifiedByIRSSubjectToBackupWithholding && (
                <>
                  <Typography
                    style={{ marginBottom: 10, textAlign: "center" }}
                    variant="h5"
                    className="acc-white bold width-80 width-100-mobile">Have you been notified by the IRS that you’re currently subject to backup withholding because you failed to report all interest and dividends on your tax return?</Typography><br />
                  {values.notifiedByIRSSubjectToBackupWithholding === UMBYesNoValues.YES &&
                    <div
                      className="m-bottom-30 row center width-35 width-100-mobile"
                      style={{ background: COLORS.red, padding: 20, borderRadius: 16 }}>
                      <FontAwesomeIcon
                        icon={faExclamationSquare}
                        color={COLORS.darkBlue}
                        size="lg"
                        className="m-right-15" />
                      <Typography
                        variant="caption"
                        className="acc-darkblue bold condensed-lineheight">Unfortunately we cannot open an account while you are subject to backup withholding.</Typography>
                    </div>
                  }
                  <div className="radio-container column center">
                    {formSelectOptions.yesNoOptions.map((item: LabelValueInput) => (
                      <RadioSelectCard
                        key={item.label}
                        onClick={() => setFieldValue("notifiedByIRSSubjectToBackupWithholding", item.value)}
                        selected={values.notifiedByIRSSubjectToBackupWithholding === item.value}
                        label={item.label}
                        cardStyle={{ marginBottom: 35, width: "80%" }}
                        className="width-100-mobile"
                      />
                    ))}
                  </div>
                </>
              )}
              {currentPage === FormPages.AreTaxStatementsAccurate && (
                <ConsumerW9Form walletType={walletType!} />
              )}
              {currentPage === FormPages.SubmitScreen && (
                <div className="width-100 column center">
                  <Typography
                    style={{ marginBottom: 30, textAlign: "center" }}
                    variant="h5"
                    className="acc-white bold"><span className="acc-text acc-lightgreen">access</span>Wallet Account Confirmation</Typography>
                  <div className="row start-row">
                    <Checkbox
                      checked={accessTermsChecked}
                      onClick={() => setAccessTermsChecked(!accessTermsChecked)}
                      style={styles.checkbox}
                    />
                    <Typography
                      style={{ fontSize: 16, lineHeight: "20px" }}
                      className="acc-white"
                      variant="subtitle1">
                      By clicking this box, you agree to the terms and conditions of the {" "}
                      <a
                        className="acc-green underlined-link"
                        href="#">
                        <span
                          className="acc-text"
                        >access</span>Wallet Account Customer Agreement</a>, the {" "}
                      <a
                        className="acc-lightgreen underlined-link"
                        href="#">UMB Privacy Notice</a> and the {" "}
                      <a
                        className="acc-lightgreen underlined-link"
                        href="#">UMB Consent to Electronic Signatures and Records</a>,
                      and the creation of your <span className="acc-green acc-text">access</span><span className="bold acc-text">Wallet</span> bank account with UMB Bank, N.A.
                    </Typography>
                  </div>
                  <AccButton
                    label="Confirm"
                    color={isForwardDisabled(values, errors) ? AccButtonColors.GREEN_OUTLINE : AccButtonColors.GREEN}
                    onClick={() => submitOnboard(values)}
                    style={{ marginTop: 30, width: 150 }} />
                  <p style={{ width: 500, color: COLORS.white }}>{onboardResponse}</p>
                </div>
              )}
            </div>
            <div className="width-100 column end">
              {(currentPage !== FormPages.SubmitScreen && currentPage !== FormPages.MFALogin) && (
                <div
                  className="row width-30 column-reverse-mobile width-100-mobile"
                  style={{ justifyContent: "flex-end" }}>
                  {currentPage !== FormPages.WalletType && (<AccButton
                    color={AccButtonColors.WHITE}
                    className="white-button width-100-mobile m-top-15-mobile"
                    style={{ width: 130 }}
                    // disabled={!values.walletType}
                    label="Back"
                    onClick={() => {
                      if (Object.keys(nestedCurrentPages).includes(currentPage)) {
                        const { nestedPagesVisible, currentPageNumber } = nestedCurrentPages[currentPage];
                        // @ts-ignore
                        if (nestedPagesVisible(values[currentPage])) {
                          if (currentPageNumber > 1) {
                            return setNestedCurrentPages({
                              ...nestedCurrentPages,
                              [currentPage]: {
                                ...nestedCurrentPages[currentPage],
                                currentPageNumber: currentPageNumber - 1,
                              },
                            });
                          }
                        } else if (currentPageNumber > 1) {
                          setNestedCurrentPages({
                            ...nestedCurrentPages,
                            [currentPage]: {
                              ...nestedCurrentPages[currentPage],
                              currentPageNumber: 1,
                            },
                          });
                        }
                      }
                      const prevIndex = secondaryJointFormPageOrder[secondaryJointFormPageOrder.indexOf(currentPage) - 1];
                      setCurrentPage(prevIndex);
                    }}
                  />)}
                  <AccButton
                    style={{ width: 130 }}
                    submitOnEnter
                    color={isForwardDisabled(values, errors) ? AccButtonColors.GREEN_OUTLINE : AccButtonColors.GREEN}
                    className="m-left-15 width-100-mobile m-0-mobile"
                    // @ts-ignore
                    disabled={isForwardDisabled(values, errors)}
                    label="Next"
                    onClick={() => {
                      if (Object.keys(nestedCurrentPages).includes(currentPage)) {
                        const { nestedPagesVisible, currentPageNumber, totalNumberOfPages } = nestedCurrentPages[currentPage];
                        // @ts-ignore
                        if (nestedPagesVisible(values[currentPage])) {
                          if (currentPageNumber < totalNumberOfPages) {
                            return setNestedCurrentPages({
                              ...nestedCurrentPages,
                              [currentPage]: {
                                ...nestedCurrentPages[currentPage],
                                currentPageNumber: currentPageNumber + 1,
                              },
                            });
                          }
                        }
                      }
                      const nextIndex = secondaryJointFormPageOrder[secondaryJointFormPageOrder.indexOf(currentPage) + 1];
                      setCurrentPage(nextIndex);
                    }}
                  />
                </div>)}
            </div>
          </div>
        )
        }
      </Formik>
    </PageWrapper>
  );
};

const styles = {
  checkbox: {
    color: COLORS.green,
    "&.Mui-checked": {
      color: COLORS.green,
    },
    padding: "0px 10px 0px 0px",
  },
};
