import axios from "axios";
import { config } from "../../common/config";
import { attemptTokenRefresh, logOut } from "../utils";

const restClient = axios.create({
  baseURL: config.apiHost,
});


restClient.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers = {
        "Authorization": "Bearer " + token,
      };
    }
    return config;
  },
  error => Promise.reject(error),
);

restClient.interceptors.response.use(
  response => response,
  async function (error) {
    const originalRequest = error.config;
    if (
      error?.response?.status === 401 &&
      originalRequest.url.includes("/refresh")
    ) {
      // router.push('/login')
      return Promise.reject(error);
    }

    if (error?.response?.status === 401) {
      try {
        const refreshResponse = await attemptTokenRefresh();
        if (refreshResponse === true) {
          return restClient(originalRequest);
        } else if (refreshResponse === "REFRESH_IN_PROGRESS") {
          console.log("Refreshing session");
        } else {
          logOut();
        }
      } catch (err: any) {
        logOut();
      }
    }
  },

);

export default restClient;