import gql from "graphql-tag";
import { gql as gqlv2 } from "graphql-request";
import { makeGraphqlRequest } from "../../utils";

export const CREATE_CASHFLOW = gql`
mutation Mutation($input: CashFlowInput!) {
  createCashFlow(input: $input) {
    cashFlowInputArray
  }
}`;

export const UPDATE_CASHFLOW = gql`
mutation Mutation(
    $cashFlowId: Float!, 
    $input: CashFlowInput!,
) {
  updateCashFlow(
    cashFlowId: $cashFlowId
    input: $input
) {
    assetObjectId
    cashFlowInputArray
  }
}
`;

export const DELETE_CASHFLOW = gql`
mutation DeleteCashFlow($id: String!) {
  deleteCashFlow(_id: $id) {
    assetObjectId
    cashFlowId
    cashFlowInputArray
  }
}
`;

const GET_CASHFLOW_V2 = gqlv2`
query Query($assetId: Int!) {
  getCashFlow(assetId: $assetId) {
    id
    assetObjectId
    cashFlowId
    cashFlowInputArray
  }
}
`;

export const getCashFlow: any = (assetId: number) => makeGraphqlRequest(GET_CASHFLOW_V2, { assetId });

const GET_CASHFLOWS_V2 = gqlv2`
query Query {
  getCashFlows {
    id
    assetObjectId
    cashFlowId
    cashFlowInputArray
  }
}
`;

export const getCashFlows = () => makeGraphqlRequest(GET_CASHFLOWS_V2);