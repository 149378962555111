import gql from "graphql-tag";
import { gql as gqlv2 } from "graphql-request";
import { makeGraphqlRequest } from "../../utils";


export const CREATE_DEAL_TYPE = gql`
    mutation CreateDealType(
      $input: DealTypeInput!, 
      $uploadedDisqualifyingEvent: Upload,
      $uploadedInvestmentProcedures: Upload,
      ) {
        createDealType(
          input: $input, 
          uploadedDisqualifyingEvent: $uploadedDisqualifyingEvent,
          uploadedInvestmentProcedures: $uploadedInvestmentProcedures,
          ) {
          id
          dealTypeId
          name
          taxDocumentType
          generalPartner
          lawFirm
          offeringEntity
          helloSignTemplateId
          requiresAccreditation
          disqualifyingEvent
          disqualifyingEventDocumentLocation
          investmentProceduresDocumentLocation
        }
      }
  `;

export const UPDATE_DEAL_TYPE = gql`
  mutation UpdateDealType(
    $id: String!, 
    $input: DealTypeInput!, 
    $uploadedInvestmentProcedures: Upload,
    $uploadedDisqualifyingEvent: Upload,
    ) {
    updateDealType(
      _id: $id, 
      input: $input, 
      uploadedInvestmentProcedures: $uploadedInvestmentProcedures,
      uploadedDisqualifyingEvent: $uploadedDisqualifyingEvent,
      ) {
      id
      dealTypeId
      name
      taxDocumentType
      generalPartner
      lawFirm
      offeringEntity
      helloSignTemplateId
      requiresAccreditation
      disqualifyingEvent
      disqualifyingEventDocumentLocation
      investmentProceduresDocumentLocation
    }
  }
`;

export const DELETE_DEAL_TYPE = gql`
  mutation DeleteDealType($id: String!) {
    deleteDealType(_id: $id) {
      id
      dealTypeId
    }
  }
`;

const GET_DEAL_TYPE_V2 = gqlv2`
  query GetDealType ($dealTypeId: Int!){
    getDealType(dealTypeId: $dealTypeId) {
      dealTypeId
      id
      name
      taxDocumentType
      generalPartner
      lawFirm
      offeringEntity
      helloSignTemplateId
      requiresAccreditation
      accreditationConfirmation
      disqualifyingEvent
      disqualifyingEventDocumentLocation
      investmentProceduresDocumentLocation
    }
  }
`;
export const getDealType = (dealTypeId: number) => makeGraphqlRequest(GET_DEAL_TYPE_V2, { dealTypeId });


const GET_DEAL_TYPES_V2 = gqlv2`
  query GetDealTypes {
    getDealTypes {
      dealTypeId
      id
      name
      taxDocumentType
      generalPartner
      lawFirm
      offeringEntity
      requiresAccreditation
      accreditationConfirmation
      disqualifyingEvent
      helloSignTemplateId
      disqualifyingEventDocumentLocation
      investmentProceduresDocumentLocation
    }
  }
`;

export const getDealTypes = () => makeGraphqlRequest(GET_DEAL_TYPES_V2);