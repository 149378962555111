import { Typography } from "@material-ui/core";
import React from "react";
import { isNotNil } from "../../utilities";
import { withErrorBoundary } from "../ErrorBoundary";
import LoadingAnimation from "../LoadingAnimation";
import "./styles.css";

interface Props {
  title?: string | React.ReactFragment;
  subtitle?: string | React.ReactFragment;
  titleClassName?: string;
  titleStyle?: React.CSSProperties;
  showLoader?: boolean;
  showError?: boolean;
  errorMessage?: string;
  wrapperStyle?: React.CSSProperties;
  wrapperClassName?: string;
  children?: any;
}

const PageWrapper = withErrorBoundary({}, ({
  title,
  subtitle,
  titleClassName,
  titleStyle,
  showLoader,
  showError,
  errorMessage,
  wrapperStyle,
  wrapperClassName,
  ...props
}: Props) => {

  if (showLoader === true) {
    return <LoadingAnimation />;
  }

  if (showError) {
    throw new Error(errorMessage || "Something went wrong");
  }

  return (
    <div
      className={wrapperClassName}
      style={wrapperStyle ? { ...wrapperStyle } : {}}>
      {isNotNil(title) && (<div
        className={titleClassName}
        style={{ ...titleStyle }}>
        <Typography
          className={`${titleClassName ? titleClassName : "acc-darkblue"} page-title`}
          variant="h4"
          style={{ marginBottom: 10, fontWeight: 700 }}>{title}</Typography>
        {isNotNil(subtitle) && (<Typography
          className="acc-darkgray page-subtitle"
          variant="h6"
          style={{ fontWeight: 300 }}>{subtitle}</Typography>)}
      </div>)}
      {props.children}
    </div>
  );
});

export default PageWrapper;
