import { toast } from "react-toastify";
import { emailHrefLinks, USER_ERROR_CODES } from "../../../common/constants";
import { UserRegistrationRequest } from "../../../common/types";
import { isNotNil, standardErrorHandle } from "../../../common/utilities";
import { handleApiResponse } from "../../utils";
import restClient from "../restClient";

const userLogin = async (user: object) => {
  try {
    const result = await restClient.post("/user/login", user);
    if (result.data && isNotNil(result.data.token)) {
      return result;
    } else if (result.data.error) {
      if (result.data.code === USER_ERROR_CODES.INACTIVE) {
        toast(result.data.message, {
          type: toast.TYPE.INFO,
          onClick: () => document.location = emailHrefLinks.inactiveUserIssue,
        });
      } else
        toast(result.data.message, {
          type: toast.TYPE.INFO,
        });

    }
  } catch (err: any) {
    standardErrorHandle(err, "Error logging in");
  }
};

const mfaRequest = async (requestType: string) => {
  try {
    const result = await restClient.post("/user/mfa-request", {
      requestType,
    });
    return handleApiResponse(result);
  } catch (err: any) {
    standardErrorHandle(err, "Error requesting MFA");
  }
};

const validateMfaCode = async (code: string) => {
  try {
    const result = await restClient.post("/user/mfa-confirm", {
      code,
    });
    return handleApiResponse(result);
  } catch (err: any) {
    standardErrorHandle(err, "Error validating MFA code");
  }
};


const userRegistration = async (registrationBody: UserRegistrationRequest) => {
  try {
    const result = await restClient.post("/user/signup", registrationBody);
    if (result?.data?.success === true) {
      toast("User created successfully.", {
        type: toast.TYPE.SUCCESS,
      });
    }
    return handleApiResponse(result);
  } catch (err: any) {
    toast(err.message || err?.msg || err.toString(), { type: toast.TYPE.ERROR });
  }
};

const userUpdate = async (user: object) => {
  try {
    const result = await restClient.patch("/user/", user);
    if (result?.data?.success === true) {
      toast("User updated successfully!", {
        type: toast.TYPE.SUCCESS,
      });
    }
    console.log("****RES", result?.data?.data?.values);
    return handleApiResponse(result);
  } catch (err: any) {
    return standardErrorHandle(err);
  }
};

const userPasswordUpdate = async (userRequest: object) => {
  try {
    const result = await restClient.patch(
      "/user/update-password",
      userRequest,
    );
    if (result?.data?.success === true) {
      toast("User password updated successfully!", {
        type: toast.TYPE.SUCCESS,
      });
    } else if (result?.data?.success === false) {
      toast(result.data.msg || "There was an error updating user password.", {
        type: toast.TYPE.ERROR,
      });
    }
    return result;
  } catch (err: any) {
    return standardErrorHandle(err);
  }
};

const userActivate = async (userId: number) => {
  try {
    const result = await restClient.patch("/user/activate-user",
      { userId: userId },
    );
    if (result?.data?.success === true) {
      toast("User activated successfully!", {
        type: toast.TYPE.SUCCESS,
      });
    }
    return handleApiResponse(result);
  } catch (err: any) {
    return standardErrorHandle(err);
  }
};

const userDeactivate = async (userId: number) => {
  try {
    const result = await restClient.patch("/user/deactivate-user",
      { userId: userId },
    );
    if (result?.data?.success === true) {
      toast("User deactivated successfully!", {
        type: toast.TYPE.SUCCESS,
      });
    }
    return handleApiResponse(result);
  } catch (err: any) {
    return standardErrorHandle(err);
  }
};

const forgotPassword = async (userEmail: string) => {
  try {
    const result = await restClient.post("/user/forgot-password", {
      email: userEmail,
    });
    return handleApiResponse(result);
  } catch (err: any) {
    return standardErrorHandle(err);
  }
};

const resetPassword = async (
  code: string,
  email: string,
  newPassword: string,
) => {
  try {
    const result = await restClient.post("/user/reset-password", {
      code: code,
      email: email,
      newPassword: newPassword,
    });
    if (result?.data?.success === true) {
      toast("User password updated successfully!", {
        type: toast.TYPE.SUCCESS,
      });
    }
    return handleApiResponse(result);
  } catch (err: any) {
    return standardErrorHandle(err);
  }
};

const checkEmailCode = async (email: string, code: string, newPhoneNumber: string) => {
  try {
    const result = await restClient.post("/user/code-phone",
      {
        email,
        code,
        newPhoneNumber,
      });
    return result;
  } catch (err: any) {
    return standardErrorHandle(err);
  }
};

const resetPhone = async (user: object, newPhoneNumber: string) => {
  try {
    const result = await restClient.post("/user/reset-phone",
      {
        user,
        newPhoneNumber,
      });
    if (result?.data?.success === true) {
      toast("User phone number updated successfully!", {
        type: toast.TYPE.SUCCESS,
      });
    } else if (result?.data?.success === false) {
      toast(result.data.msg || "There was an error updating user user phone number.", {
        type: toast.TYPE.ERROR,
      });
    }
    return result;
  } catch (err: any) {
    return standardErrorHandle(err);
  }
};

const checkIfEmailExists = async (
  userEmail: string,
  newEmail: string,
) => {
  try {
    const result = await restClient.post("/user/check-email", {
      userEmail: userEmail,
      newEmail: newEmail,
    });
    if (result?.data?.success === false) {
      toast(result.data.msg || "There was an error checking email address.", {
        type: toast.TYPE.ERROR,
      });
    }
    return result;
  } catch (err: any) {
    return standardErrorHandle(err);
  }
};

const verifyEmail = async (
  token: string,
) => {
  try {
    const result = await restClient.get(`/user/verify-email/${token}`);
    return result;
  } catch (err: any) {
    return standardErrorHandle(err);
  }
};

export {
  userLogin,
  mfaRequest,
  validateMfaCode,
  userRegistration,
  userUpdate,
  userPasswordUpdate,
  userActivate,
  userDeactivate,
  forgotPassword,
  resetPassword,
  checkIfEmailExists,
  verifyEmail,
  checkEmailCode,
  resetPhone,
};
