import React from "react";
import { useLocation, Link } from "react-router-dom";
import { MenuItem, Typography } from "@material-ui/core";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import COLORS from "../../colors";
import { isNotNil } from "../../utilities";

interface Props {
    icon?: React.ReactNode;
    title: string | JSX.Element;
    link: string;
    onClick?: () => void;
    alternateLinks?: string[];
}

const NavigationItem: React.FC<Props> = ({
    icon, title, link, onClick, alternateLinks,
}) => {
    const currentPage = useLocation();
    return (
        <MenuItem
            disableRipple
            component={Link}
            to={link}
            style={{ paddingLeft: "22px", marginBottom: 5 }}
            onClick={onClick}>
            <div
                style={{
                    display: "flex", alignItems: "center", marginLeft: 15,
                }}>
                {icon}
                <Typography
                    style={{
                        color: currentPage.pathname.match(link) || alternateLinks?.includes(currentPage.pathname)
                            ? (COLORS.white) : COLORS.navBarLogo, fontWeight: 300, marginLeft: 30,
                    }}>
                    {title}
                </Typography>
            </div>
        </MenuItem>
    );
};

interface ListItemProps {
    title: string | JSX.Element,
    link: string,
    icon?: FontAwesomeIconProps["icon"],
    alternateLinks?: string[],
    onClick?: () => void,
}

const NestedListItemButton = ({ title, link, onClick, alternateLinks }: ListItemProps) => {
    return (
        <ListItemButton
            onClick={onClick}
            disableRipple
            sx={{ pl: 1.5, paddingTop: 0, paddingBottom: 0, "& .MuiMenuItem-root": { minHeight: 0 } }}>
            <NavigationItem
                title={title}
                link={link}
                alternateLinks={alternateLinks}
            />
        </ListItemButton>
    );
};

const MainListItemButton = ({ title, link, icon, alternateLinks, onClick }: ListItemProps) => {
    const currentPage = useLocation();
    return (
        <Link to={link}>
            <ListItemButton
                onClick={onClick}
                disableRipple
                sx={styles.listButton}>
                <ListItemIcon >
                    {isNotNil(icon) && <FontAwesomeIcon
                        icon={icon}
                        style={{
                            ...styles.headerIcon,
                            color: currentPage.pathname.match(link) || alternateLinks?.includes(currentPage.pathname)
                                ? (COLORS.green) : COLORS.navBarLogo,
                        }}
                    />}
                </ListItemIcon>
                <ListItemText
                    primary={title}
                    style={{
                        color: currentPage.pathname.match(link) || alternateLinks?.includes(currentPage.pathname)
                            ? COLORS.white : COLORS.navBarLogo,
                    }} />
            </ListItemButton>
        </Link>
    );
};

const styles = {
    headerIcon: {
        marginRight: 30,
        marginLeft: 8,
        fontSize: 20,
        width: 25,
    },
    listButton: {
        paddingBottom: 0,
    },
};

export { NavigationItem, NestedListItemButton, MainListItemButton };