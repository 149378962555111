import React from "react";

interface Props {

}

const UMB: React.FC<Props> = () => (
    <div>
        UMB
    </div>
);

export default UMB;