import React, { useState, ChangeEvent } from "react";
import { Typography } from "@material-ui/core";
import ClickableLinkWithIcon, { TitleLinkInput } from "../../../common/components/ClickableLinkWithIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faFilePdf } from "@fortawesome/pro-light-svg-icons";
import "./index.css";

interface Props {
  currentFiles?: File[] | FileList;
  updateFileList?: (arg: File | File[] | TitleLinkInput | FileList | TitleLinkInput[]) => void | undefined;
  mainTextClassName?: string;
  syncFileArray?: (arg: any[]) => void;
  multiple?: boolean;
}


const FilesDragAndDrop: React.FC<Props> = ({ currentFiles, updateFileList, mainTextClassName, syncFileArray, multiple,
}) => {
  const wrapperRef = React.createRef<HTMLDivElement>();
  const [fileList, setFileList] = useState<Array<TitleLinkInput>>([]);
  const onDragEnter = () => wrapperRef.current!.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current!.classList.remove("dragover");
  const onDrop = () => wrapperRef.current!.classList.remove("dragover");

  const fileRemove = (file: TitleLinkInput) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    const updatedBufferFiles = Array.from(currentFiles ?? []).filter(function (s) {
      return s.name !== file.title;
    });
    updateFileList?.(updatedBufferFiles);
    syncFileArray?.(updatedList);
  };

  const onFileDrop = (e: ChangeEvent<HTMLInputElement>) => {
    const uploadEvent = e.target as HTMLInputElement;
    const uploadArray: Array<TitleLinkInput> = [];
    if (uploadEvent.files?.length) {
      for (let i = 0; i < uploadEvent.files.length; i++) {
        uploadArray.push({
          title: uploadEvent.files[i].name,
          link: URL.createObjectURL(uploadEvent.files[i]),
        });
      }
      syncFileArray?.(uploadArray);
      setFileList(uploadArray);
      updateFileList?.(multiple ? uploadEvent?.files : uploadEvent?.files[0]);
    };
  };

  return (
    <>
      <div
        ref={wrapperRef}
        className="drop-file-input"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div
          className="width-100 dashed-border"
          style={styles.dragDrop as React.CSSProperties}>
          <Typography
            variant="h6"
            className={`${mainTextClassName ? mainTextClassName : "acc-darkblue"} bold`}
          >
            Upload Documents
          </Typography>
          <Typography
            variant="subtitle2"
            className={`${mainTextClassName ? mainTextClassName : "acc-gray"} light`}
          >
            Drag and drop your files here or <span className="acc-green">click here to select from a folder</span>
          </Typography>
          <br />
          <Typography
            variant="subtitle2"
            className={`${mainTextClassName ? mainTextClassName : "acc-gray"} light`}
          >Note: You can select multiple files. You can only upload PDF, JPEG, JPG and PNG
          </Typography>
        </div>
        <input
          type="file"
          multiple={multiple}
          onChange={onFileDrop}
          onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
            const element = event.target as HTMLInputElement;
            element.value = "";
          }}
          accept="application/pdf, image/*" />
      </div>
      {
        fileList.length > 0 ? (
          <div>
            {fileList.map((item: TitleLinkInput) => (
              <div
                key={item.link}
                className="row center stretch m-top-15 m-bottom-15">
                <ClickableLinkWithIcon
                  text={item.title}
                  link={item.link}
                  icon={faFilePdf}
                />
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    fileRemove(item);
                  }
                  }>
                  <FontAwesomeIcon
                    size="1x"
                    className="acc-red"
                    icon={faTrash}
                  />
                </button>
              </div>
            ))}
          </div>
        ) : null
      }
    </>
  );
};

const styles = {
  dragDrop: {
    padding: "25px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
};

export default FilesDragAndDrop;