import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { RouteComponentProps, useLocation, withRouter } from "react-router";
import { QueryKeys, useAccount, useAccounts, useAddress, useAddressTypes } from "../../../api/hooks/graphqlHooks";
import { isNil, isNotNil } from "../../../common/utilities";
import { EditModeTypes } from "../../../enums";
import useMediaQuery from "@mui/material/useMediaQuery";
import { COUNTRIES, THEME_BREAKPOINTS, US_STATES } from "../../../common/constants";
import { useMutation } from "@apollo/client";
import { CREATE_ADDRESS, DELETE_ADDRESS, UPDATE_ADDRESS } from "../../../api/query/Addresses";
import { Address } from "../../../common/types";
import { toast } from "react-toastify";
import { Field, Form, Formik, FormikValues } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import COLORS from "../../../common/colors";
import CancelConfirmDialogModal from "../../../common/components/CancelConfirmDialogModal";
import AccInput from "../../../common/components/AccInput";
import { MenuItem, TextField } from "@material-ui/core";
import AccOptionSelect from "../../../common/components/AccInput/variants/AccOptionSelect";
import { withErrorBoundary } from "../../../common/components/ErrorBoundary";
import PageWrapper from "../../../common/components/PageWrapper";

type AddressDetailParams = {
  id?: string;
};

type AddressDetailProps = RouteComponentProps<AddressDetailParams>;
const AddressForm: React.FC<AddressDetailProps> = withErrorBoundary({}, ({ match, history }: AddressDetailProps) => {
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  const currentPage = useLocation();
  const [cancelDialogVisible, setCancelDialogVisibible] = useState(false);
  const queryClient = useQueryClient();
  const editMode: EditModeTypes = currentPage.pathname.match("/admin/address/add") ? EditModeTypes.ADD : EditModeTypes.EDIT;
  if (editMode === EditModeTypes.ADD) {
    queryClient.removeQueries(QueryKeys.ADDRESS);
  }

  const {
    data: getAddressData,
    isLoading: getAddressLoading,
    isFetching: getAddressFetching,
    isError: getAddressError,
  } = useAddress(EditModeTypes.EDIT && Number(match.params.id), {
    enabled: editMode === EditModeTypes.EDIT,
    refetchOnMount: true,
  });

  const {
    isLoading: getAccountsLoading,
    isFetching: getAccountsFetching,
    isError: getAccountsError,
  } = useAccounts({
    enabled: true,
    refetchOnMount: true,
  });

  const {
    data: getAddressTypesData,
    isLoading: getAddressTypesLoading,
    isFetching: getAddressTypesFetching,
    isError: getAddressTypesError,
  } = useAddressTypes({
    enabled: true,
    refetchOnMount: true,
  });

  const {
    data: getAccountData,
    isLoading: getAccountLoading,
    isFetching: getAccountFetching,
    isError: getAccountError,
  } = useAccount(Number(getAddressData?.getAddress?.account?.accountId), {
    enabled: isNotNil(getAddressData?.getAddress?.account?.accountId) && editMode === EditModeTypes.EDIT,
    refetchOnMount: true,
  });

  const [createAddress] = useMutation(CREATE_ADDRESS);
  const [deleteAddress] = useMutation(DELETE_ADDRESS);
  const [updateAddress] = useMutation(UPDATE_ADDRESS);

  const AddressSchema = Yup.object().shape({
    displayName: Yup.string().required(),
    addressRecipientName: Yup.string().required(),
    street1: Yup.string().required(),
    street2: Yup.string(),
    city: Yup.string().matches(/^[A-Za-z]+$/, "No numbers are allowed in City field").required(),
    postalCode: Yup.number().positive().integer().required(),
    country: Yup.string().required(),
    accountObjectId: Yup.string().required(),
    accountName: Yup.string().required(),
    accountId: Yup.string().required(),
    addressTypeObjectId: Yup.string().required(),
  });

  const BLANK_FORM_VALUES = {
    displayName: "",
    addressRecipientName: "",
    street1: "",
    street2: "",
    city: "",
    stateProvince: "",
    postalCode: "",
    country: "",
    accountObjectId: getAddressData ?? getAccountData?.getAccount?.id,
    accountName: getAddressData ?? getAccountData?.getAccount?.name,
    accountId: getAddressData ?? getAccountData?.getAccount?.accountId,
    addressTypeObjectId: "",
    addressTypeName: "",
    addressTypeId: "",
  };

  const initialFormFieldValues = () => {
    if (isNil(getAddressData)) return BLANK_FORM_VALUES;
    const { account, addressType, ...initialFormValues }: Address = getAddressData.getAddress ?? {};
    return {
      ...initialFormValues,
      addressTypeObjectId: addressType?.id ?? "ERROR",
      addressTypeName: addressType.name ?? "ERROR",
      addressTypeId: addressType.addressTypeId ?? "ERROR",
      accountObjectId: account?.id ?? "ERROR",
      accountName: account?.name ?? "ERROR",
      accountId: account?.accountId ?? "ERROR",
    };
  };

  const openCancelDialog = () => setCancelDialogVisibible(true);
  const closeCancelDialog = () => setCancelDialogVisibible(false);

  const handleDelete = async (objectId: string | number) => {
    try {
      await deleteAddress({
        variables: {
          id: objectId,
        },
      });
      queryClient.refetchQueries(QueryKeys.ADDRESS);
      toast("Address deleted successfully!", {
        type: toast.TYPE.SUCCESS,
      });
      history.push("/admin/accounts");
    } catch (err: any) {
      toast(err.message || err.msg || err.toString() || "There was an error deleting address", {
        type: toast.TYPE.WARNING,
      });
    }
  };
  const submitForm = async (values: FormikValues, { resetForm }: any) => {
    AddressSchema.validate(values);
    if (editMode === EditModeTypes.ADD) {
      try {
        await createAddress({
          variables: {
            input: {
              displayName: values?.displayName,
              addressRecipientName: values?.addressRecipientName,
              street1: values?.street1,
              street2: values?.street2,
              city: values?.city,
              stateProvince: values?.stateProvince,
              postalCode: values?.postalCode,
              country: values?.country,
              accountObjectId: values?.accountObjectId,
              addressTypeObjectId: values?.addressTypeObjectId,
            },
          },
        });
        toast("Account address added successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        queryClient.refetchQueries(QueryKeys.ADDRESS);
        history.push("/admin/accounts");
        resetForm();
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error adding address", {
          type: toast.TYPE.ERROR,
        });
      }
    } else if (editMode === EditModeTypes.EDIT) {
      try {
        // if (checkCityInputHasNoDigits(values?.city)) {
        //   toast("City input can't have numbers.", {
        //     type: toast.TYPE.ERROR,
        //   });
        // } else {
          await updateAddress(
            {
              variables: {
                id: getAddressData?.getAddress.id,
                input: {
                  displayName: values?.displayName,
                  addressRecipientName: values?.addressRecipientName,
                  street1: values?.street1,
                  street2: values?.street2,
                  city: values?.city,
                  stateProvince: values?.stateProvince,
                  postalCode: values?.postalCode,
                  country: values?.country,
                  accountObjectId: values?.accountObjectId,
                  addressTypeObjectId: values?.addressTypeObjectId,
                },
              },
            },
          );
          toast("Address updated successfully!", {
            type: toast.TYPE.SUCCESS,
          });
          queryClient.refetchQueries(QueryKeys.ADDRESS);
          history.push("/admin/accounts");
        // }
      } catch (err: any) {
        toast(err.message || err.msg || err.toString() || "There was an error updating address.", {
          type: toast.TYPE.ERROR,
        });
      }
    }
  };

  const pageLoading =
    getAddressLoading ||
    getAddressFetching ||
    getAccountsLoading ||
    getAccountsFetching ||
    getAddressTypesLoading ||
    getAddressTypesFetching ||
    getAccountLoading ||
    getAccountFetching;

  return (
    <PageWrapper
      title={`${editMode === EditModeTypes.ADD ? "Add" : "Update"} Address`}
      showLoader={pageLoading}
      showError={getAddressError || getAccountsError || getAddressTypesError || getAccountError}
      errorMessage="Cannot retrieve addresses"
    >
      <br />
      <div>
        <Formik
          initialValues={initialFormFieldValues()}
          enableReinitialize
          onSubmit={submitForm}
          validationSchema={AddressSchema}
        >
          {({ values, handleChange }) => (
            <Form>
              <div className="column-reverse-mobile">
                <div className="row stretch center width-100 column-mobile">
                  <Link
                    to="/admin/accounts"
                    className="width-100-mobile">
                    <AccButton
                      color={AccButtonColors.WHITE}
                      label="Cancel"
                      style={{ backgroundColor: COLORS.white }}
                    />
                  </Link>
                  <div className="row width-100-mobile column-mobile">
                    <AccButton
                      color={AccButtonColors.GREEN}
                      type="submit"
                      label={editMode === EditModeTypes.ADD ? "Submit" : "Update"}
                      className="m-top-15-mobile m-bottom-15-mobile"
                    />
                    {editMode === EditModeTypes.EDIT
                      ? (
                        <>
                          <AccButton
                            color={AccButtonColors.RED_OUTLINE}
                            label="Delete"
                            className="delete-button m-left-15 display-none-mobile"
                            type="button"
                            onClick={openCancelDialog}
                          />
                          {mobileLayout === true
                            && (
                              <AccButton
                                color={AccButtonColors.RED_OUTLINE}
                                label="Delete"
                                className="deactivate-user-button"
                                type="button"
                                width="40%"
                                style={styles.mobileDeactivateButton}
                                onClick={openCancelDialog}
                              />
                            )}
                        </>
                      )
                      : null}
                    {getAddressData ? <CancelConfirmDialogModal
                      isVisible={cancelDialogVisible}
                      dialogBody="Are you sure you would like to delete this Address?"
                      onClickCancel={closeCancelDialog}
                      onClickDelete={() => handleDelete(getAddressData?.getAddress.id)}
                    /> : null}
                  </div>
                </div>
                <br />
                <div
                  style={styles.border}
                  className="width-60 width-100-mobile">
                  <Field
                    name="displayName"
                    type="text"
                    label="Display Name"
                    as={AccInput}
                  />
                  <Field
                    name="addressRecipientName"
                    type="text"
                    label="Address Recipient Name"
                    as={AccInput}
                  />
                  <Field
                    name="street1"
                    type="text"
                    label="Address 1"
                    as={AccInput}
                    required
                  />
                  <Field
                    name="street2"
                    type="text"
                    label="Address 2"
                    as={AccInput}
                  />
                  <Field
                    name="city"
                    type="text"
                    label="City"
                    as={AccInput}
                    required
                  />
                  <Field
                    name="stateProvince"
                    type="text"
                    label="State"
                    as={AccOptionSelect}
                    options={US_STATES}
                    required
                  />
                  <Field
                    name="postalCode"
                    type="text"
                    label="Zip Code"
                    as={AccInput}
                    required
                  />
                  <Field
                    name="country"
                    type="text"
                    label="Country"
                    as={AccOptionSelect}
                    options={COUNTRIES}
                    required
                  />
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="accountName"
                    type="text"
                    label="Account"
                    value={values.accountName}
                  />
                  <AccInput
                    name="addressTypeObjectId"
                    value={values.addressTypeObjectId}
                    label="Address Type"
                    select
                    onChange={handleChange}
                    required
                  >
                    <MenuItem
                      selected
                      value={values?.addressTypeId}>
                      {values?.addressTypeName}
                    </MenuItem>
                    {getAddressTypesData?.getAddressTypes
                      ?.filter(
                        (item) => item.id !== values.addressTypeId,
                      )
                      .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name))
                      .map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </AccInput>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </PageWrapper>
  );
});

const styles = {
  border: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    padding: "15px",
  },
  mobileDeactivateButton: {
    alignSelf: "flex-start",
    border: "none",
    textAlign: "left",
    padding: 0,
    height: 20,
  },
};

export default withRouter(AddressForm);