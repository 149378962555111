
import React from "react";
import AccInput from "../../../../common/components/AccInput";
import { Typography } from "@material-ui/core";
import { StyledInput } from "../components/ThemedComponents";
import { useFormikContext } from "formik";
import { UMBCommercialFormValues } from "../common/umb.interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import COLORS from "../../../../common/colors";

interface Props {
  numberOfAuthorizedSigners: any;
  setNumberOfAuthorizedSigners: any;
}

export const ACHAuthorizedSigners = ({
  numberOfAuthorizedSigners,
  setNumberOfAuthorizedSigners,
}: Props) => {

  const { values, handleChange, setFieldValue } = useFormikContext<UMBCommercialFormValues>();

  const renderNewSignerForm = (index: number) => {
    return (
      <div className="row center m-bottom-15 anchor-element">
        {index !== 0 && index === (numberOfAuthorizedSigners - 1) && (
          <div
            className="implicit-link"
            onClick={() => {
              setFieldValue(`authorizedSignersWithACHAuthority[${index}]`, null);
              setNumberOfAuthorizedSigners(numberOfAuthorizedSigners - 1);
            }}
            style={{
              position: "absolute",
              left: -30,
            }}
          >
            <FontAwesomeIcon
              color={COLORS.green}
              icon={faMinusCircle} />
          </div>
        )}
        <StyledInput
          margin="dense"
          as={AccInput}
          name={`authorizedSignersWithACHAuthority[${index}].firstName`}
          value={values?.authorizedSignersWithACHAuthority?.[index]?.firstName}
          onChange={handleChange}
          className="m-right-15"
          label="Legal First Name"
        // disabled={index === 0}
        />
        <StyledInput
          margin="dense"
          as={AccInput}
          name={`authorizedSignersWithACHAuthority[${index}].lastName`}
          value={values?.authorizedSignersWithACHAuthority?.[index]?.lastName}
          onChange={handleChange}
          className="m-right-15"
          label="Legal Last Name"
        // disabled={index === 0}
        />
        <StyledInput
          margin="dense"
          as={AccInput}
          name={`authorizedSignersWithACHAuthority[${index}].email`}
          value={values?.authorizedSignersWithACHAuthority?.[index]?.email}
          onChange={handleChange}
          className="m-right-15"
          label="Email Address"
        // disabled={index === 0}
        />
        <StyledInput
          margin="dense"
          as={AccInput}
          name={`authorizedSignersWithACHAuthority[${index}].title`}
          value={values?.authorizedSignersWithACHAuthority?.[index]?.title}
          onChange={handleChange}
          label="Title or Position"
        // disabled={index === 0}
        />
      </div>
    );
  };

  return (
    <div className="column center width-60">
      <Typography
        style={{ marginBottom: 30, textAlign: "center" }}
        variant="h5"
        className="acc-white bold">
        Please list any duly authorized signers below
        with authority to establish and maintain deposit
        accounts and initiate ACH Transfers on behalf of the entity.
      </Typography>
      {Array(numberOfAuthorizedSigners).fill("").map((signerFormNumber, idx) => renderNewSignerForm(idx))}
      <div
        className="row center implicit-link m-top-30"
        onClick={() => setNumberOfAuthorizedSigners(numberOfAuthorizedSigners + 1)}>
        <FontAwesomeIcon
          size="lg"
          color={COLORS.green}
          icon={faPlusCircle} />
        <Typography className="acc-green m-left-15">
          Add additional authorized signer
        </Typography>
      </div>
    </div>
  );
};
