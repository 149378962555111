import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FormHelperText, Typography } from "@material-ui/core";


interface HelperTextProps {
    text: string | JSX.Element;
    value?: number | string;
    data: boolean;
    color?: string;
    variant?: any;
}

const InputHelperTextWithIcon: React.FC<HelperTextProps> = ({ text, value, data, color, variant }) => {

    return (
        <FormHelperText>
            <Typography
                className={`acc-${color} light`}
                variant={variant ? variant : "subtitle2"}
            >{text} {value} {data ?
                <FontAwesomeIcon
                    icon={faCheck}
                    className="acc-green" /> :
                <FontAwesomeIcon
                    icon={faTimes}
                    className="acc-red" />}
            </Typography>
        </FormHelperText>
    );
};

export default InputHelperTextWithIcon;