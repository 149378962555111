import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { QueryKeys } from "../../../api/hooks/graphqlHooks";
import { getPlaidLinkToken, setPlaidToken } from "../../../api/rest/externalAccounts";
import COLORS from "../../colors";
import { isNil } from "../../utilities";

interface Props {
  walletId: number;
}

const PlaidLink = ({ walletId }: Props) => {
  const [linkToken, setLinkToken] = useState<string | null>(null);
  // creates a Link token
  const generateToken = React.useCallback(async () => {
    const linktokenResponse = await getPlaidLinkToken();
    if (isNil(linktokenResponse)) {
      return toast("Error connecting bank account", {
        type: toast.TYPE.ERROR,
      });
    } else {
        setLinkToken(linktokenResponse);
        localStorage.setItem("link_token", linktokenResponse);
        localStorage.setItem("walletId", walletId.toString());
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes("?oauth_state_id=")) {
      const linkToken = localStorage.getItem("link_token");
      setLinkToken(linkToken);
    } else if (linkToken == null) {
      generateToken();
    }
  }, [linkToken]);

  return linkToken != null ? (
    <Link
      walletId={walletId}
      linkToken={linkToken}
    />) : <></>;
};

interface LinkProps {
  linkToken: string | null;
  walletId: number;
}

const Link: React.FC<LinkProps> = ({ linkToken, walletId }: LinkProps) => {
  const queryClient = useQueryClient();
  const onSuccess = React.useCallback(async (
    public_token,
    // metadata,
  ) => {
    // send public_token to server
    const plaidTokenResponse = await setPlaidToken({ publicToken: public_token, walletId });
    queryClient.refetchQueries(QueryKeys.WALLET_EXTERNAL_ACCOUNTS);
    // Handle response ...
    if (isNil(plaidTokenResponse)) {
      return toast("Error connecting bank account", {
        type: toast.TYPE.ERROR,
      });
    }
  }, []);

  let isOauth = false;

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken!,
    onSuccess,
  };

  // configuring the received redirect URI for OAuth
  if (window.location.href.includes("?oauth_state_id=")) {
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (isOauth && ready) {
      open();
    }
  }, [isOauth, ready, open]);

  return (
    <>
      <Button
        variant="contained"
        onClick={() => open()}
        disabled={!ready}
        className="green-button"
        style={styles.nextButton as React.CSSProperties}
      >
        Connect Bank Account
      </Button>
      {/* <Typography
        className="bold acc-darkblue"
        style={{ textDecoration: "underline", marginLeft: 15 }}>
        or use your account and routing numbers.
      </Typography> */}
    </>
  );
};
export default PlaidLink;

const styles = {
  nextButton: {
    borderRadius: "16px",
    color: COLORS.darkBlue,
    textTransform: "none",
    height: "45px",
    boxShadow: "none",
    fontWeight: 600,
    "&.MuiButtonBase-root:hover": {
      bgcolor: "transparent",
    },
  },
};