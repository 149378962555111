import React, { useEffect, useState } from "react";
import ImageMasonry from "../../../../../common/components/ImageMasonry";
import LabeledFigure from "../../../../../common/components/LabeledFigure";
import COLORS from "../../../../../common/colors";
import { Asset } from "../../../../../common/types";
import { formatPercentage, isPeriodOver24Months } from "../../../../../common/utilities";
import CustomTooltip from "../../../../../common/components/CustomTooltip";

interface Props {
  data: Asset;
  autoplayEnabled: boolean;
}

const Deal: React.FC<Props> = ({ data, autoplayEnabled }) => {

  const [displayImages, setDisplayImages] = useState([{
    img: "",
    title: null,
  }]);

  useEffect(() => {
    if (data?.displayImages) {
      const imageMap = data?.displayImages.map((imageUrl: string) => ({
        img: imageUrl,
        title: null,
      }));
      setDisplayImages(imageMap);
    }
  }, [data]);

  const tooltips = [
    {
      definition: "IRR (Internal Rate of Return): A calculation of the profitability of an investment.  Mathematically, IRR is the discount rate that makes the net present value (NPV) of all cash flows (investment outflow and distribution inflows) equal to zero",
      description: "Targeted IRR",
      value: formatPercentage(data?.targetedIRR),
    },
    {
      definition: "Targeted Average Cash Yield: An estimate of the annual distributions as a percentage of the original investment. Simply put it is an estimate of the return from an asset which generates income.",
      description: "Targeted Average Cash Yield",
      value: formatPercentage(data?.targetedAverageCashYield),
    },
    {
      definition: "MoIC (Multiple on Invested Capital):  The sum of realized proceeds and unrealized value divided by the money invested. This illustrates the total return of an investment over the life of the investment.",
      description: "Targeted MoIC",
      value: `${data?.targetedMOIC}x`,
    },
    // {
    //     definition: "Targeted Maturity: The scheduled or anticipated end date of the investment. For example, this would likely be the lease expiration date of an asset.",
    //     description: "Targeted Maturity",
    //     value: `${moment(data?.targetedMaturity).format("MMMYY").toUpperCase()}`,
    // },
    {
      definition: "Targeted Term: The amount of time remaining in the deal as measured from actual investment date to the investment maturity.",
      description: "Targeted Investment Period",
      value: isPeriodOver24Months(data?.targetedInvestmentPeriod),
    },
  ];

  return (
    <div>
      <div className="row stretch width-100 m-bottom-30">
        {tooltips.map(item =>
          <CustomTooltip
            key={item.description}
            title={item.definition}
            background={COLORS.darkBlueGradient}
            color={COLORS.white}
            fontSize={11}>
            <div>
              <LabeledFigure
                value={item.value}
                description={item.description}
                className="acc-blue"
                alignment="center"
              />
            </div>
          </CustomTooltip>,
        )}
      </div>
      <div id="investment-summary">
        {data?.videoId ?
          <div className="width-100 m-top-15 m-bottom-30">
            <iframe
              id="asset-video"
              className="vimeo-video"
              src={`https://player.vimeo.com/video/${data?.videoId}${autoplayEnabled ? "?autoplay=1" : ""}`}
              width="100%"
              height="100%"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
          </div>
          : null}
        {displayImages ? <ImageMasonry
          imageData={displayImages}
        /> : null}
      </div>
    </div>
  );
};


export default Deal;