// import {
//   useMutation,
// } from "@apollo/client";
import {
  IconButton, InputAdornment, OutlinedInput, Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
// import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../../assets/images/illustrations/access_logo_regular.svg";
import COLORS from "../../../common/colors";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import DividerWithText from "../../../common/components/DividerWithText";
import { AuthContext, SessionContext } from "../../../context";
import "../../../styles/login.css";
import { userLogin } from "../../../api/rest/user/index";
import { isNotNil, standardErrorHandle } from "../../../common/utilities";
import { AuthActions } from "../../../App";
import { logIn } from "../../../api/utils";
import AccCopyright from "../../../common/components/AccCopyright";

const Login = () => {
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const dispatch = useContext(AuthContext);
  const { user } = useContext(SessionContext);

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
  };

  const sessionExpired = false;

  const login = async () => {
    if (!values.email || !values.password) {
      return toast("Please enter an email and password", {
        type: toast.TYPE.INFO,
      });
    }
    try {
      const loginResponse = await userLogin(values);
      const authPayload = loginResponse?.data;
      if (authPayload) {
        logIn(authPayload);
        dispatch({
          type: AuthActions.SIGN_IN,
          ...authPayload,
        });
      }
    } catch (err: any) {
      standardErrorHandle(err, "Error logging in");
    }
  };

  if (user?.email) {
    return <Redirect to="/" />;
  }

  return (
    <div style={styles.background as React.CSSProperties}>
      <div style={styles.container as React.CSSProperties}>
        <a href="/">
          <img
            style={{ width: "100%", paddingBottom: sessionExpired ? 0 : 30 }}
            src={Logo}
            alt="" />
        </a>
        {sessionExpired && <Typography className="acc-blue">Your session has expired</Typography>}
        <div className="width-100 m-bottom-15">
          <OutlinedInput
            fullWidth
            placeholder="Email Address"
            name="email"
            onChange={handleChange("email")}
            style={styles.input}
          />
          <OutlinedInput
            fullWidth
            onChange={handleChange("password")}
            style={styles.input}
            name="password"
            type={values.showPassword ? "text" : "password"}
            placeholder="Password"
            endAdornment={(
              <InputAdornment position="end">
                {/* @ts-ignore */}
                <IconButton
                  disableRipple
                  disableFocusRipple
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )}
          />
          <Link
            className="font-size-mobile-5"
            to="/forgotPassword"
            style={{ color: COLORS.darkBlue }}>Forgot password?</Link>
          {/* <FormControlLabel
            sx={{ color: COLORS.lightGray }}
            control={<Checkbox
              defaultChecked
              sx={styles.checkbox} />}
            label="Remember this device" /> */}
        </div>
        <AccButton
          submitOnEnter
          id="signInButton"
          onClick={() => login()}
          color={isNotNil(values.email && values.password)
            ? AccButtonColors.GREEN
            : AccButtonColors.WHITE}
          label="Sign In" />
        <div className="width-100 m-top-30">
          <DividerWithText>
            create your account today
          </DividerWithText>
          <Link to="/registration" >
            <AccButton
              color={AccButtonColors.BLUE}
              label="Sign Up"
              className="m-top-30"
            />
          </Link>
        </div>
        <div className="center column m-top-30">
          {/* <Typography
            className="font-size-mobile-5"
            style={{ color: COLORS.lightGray }}>
            Need help?&nbsp;
            <span style={{ color: COLORS.green, textDecoration: "underline" }}><a href="#">Visit our Help Center.</a></span>
          </Typography> */}
          {/* <Typography
            className="font-size-mobile-5"
            style={{ color: COLORS.lightGray }}>
            Or&nbsp;
            <span style={{ color: COLORS.green, textDecoration: "underline" }}><a href="/registration">Sign Up</a></span>
          </Typography> */}
        </div>
      </div>
      <AccCopyright />
    </div>
  );
};

const styles = {
  background: {
    background: "transparent linear-gradient(360deg, #001227 0%, #05254b 100%) 0% 0% no-repeat padding-box",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    backgroundColor: "white",
    margin: "30px",
    minWidth: 210,
    maxWidth: 400,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    padding: "40px 55px 30px 55px",
    height: 650,
    borderRadius: 16,
    boxShadow: "0px 0px 60px #00000066",
  },
  input: {
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: 16,
    opacity: 1,
    width: "100%",
    marginBottom: 25,
  },
  checkbox: {
    color: COLORS.darkBlue,
    "&.Mui-checked": {
      color: COLORS.darkBlue,
    },
  },
};

export default Login;
