import { styled, Typography, useMediaQuery } from "@material-ui/core";
import { Field, Formik, FormikValues } from "formik";
import React, { useContext, useEffect, useState } from "react";
import COLORS from "../../../common/colors";
import AccButton, { AccButtonColors } from "../../../common/components/AccButton";
import AccInput, { AccMaskedInputTypes } from "../../../common/components/AccInput";
import { isNil, validateEmail } from "../../../common/utilities";
import { checkIfEmailExists, mfaRequest, validateMfaCode } from "../../../api/rest/user";
import { AuthContext, SessionContext } from "../../../context";
import LoadingAnimation from "../../../common/components/LoadingAnimation";
import { AuthActions } from "../../../App";
import * as Yup from "yup";
import { MFARequestTypes } from "../../../enums";
import { THEME_BREAKPOINTS } from "../../../common/constants";

interface Props {
  showResetEmail: boolean;
  openMessageModal: boolean;
  setOpenMessageModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResetEmail: React.FC<Props> = ({ showResetEmail, openMessageModal, setOpenMessageModal }) => {

  useEffect(() => {
    if (showResetEmail === true) {
      mfaLoginRequest();
    }
  }, [showResetEmail]);
  const toggleMessageModal = () => {
    setOpenMessageModal(!openMessageModal);
  };

  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  const { user } = useContext(SessionContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checkingEmailSent, setCheckingEmailSent] = useState(false);
  const dispatch = useContext(AuthContext);
  const ResetEmailValueSchema = Yup.object().shape({
    newEmail: Yup.string().email("Please enter a valid email!").max(255).required(),
    mfaCode: Yup.number().test("length", "Code must be six numbers",
      (val) => val?.toString().length === 6).transform((value) => (isNaN(value) ? 0 : value)),
  });
  const [mfaRequestStatus, setMfaRequestStatus] = useState({
    error: false,
    sent: false,
    loading: false,
  });
  const mfaLoginRequest = async () => {
    setMfaRequestStatus({
      ...mfaRequestStatus,
      loading: true,
    });
    try {
      const mfaResponse = await mfaRequest(MFARequestTypes.SMS);
      setMfaRequestStatus({
        ...mfaRequestStatus,
        loading: false,
      });
      if (mfaResponse?.data?.sent === true) {
        setMfaRequestStatus({
          ...mfaRequestStatus,
          error: false,
          sent: true,
          loading: false,
        });
      }
    } catch (err) {
      setMfaRequestStatus({
        ...mfaRequestStatus,
        error: true,
        sent: false,
        loading: false,
      });
      console.log("MFA Error", err);
    }
  };

  const validateMfaCodeRequest = async (mfaCodeInput: any) => {
    setMfaRequestStatus({
      ...mfaRequestStatus,
      loading: true,
    });
    const mfaResponse = await validateMfaCode(mfaCodeInput);
    setMfaRequestStatus({
      ...mfaRequestStatus,
      loading: false,
    });
    if (mfaResponse.data?.verified === true) {
      const { expiresAt, sessionId } = mfaResponse.data.session;
      dispatch({
        type: AuthActions.MFA_VERIFIED,
        mfaSessionId: sessionId,
        mfaExpiresAt: expiresAt,
      });
      setMfaRequestStatus({
        error: false,
        sent: true,
        loading: false,
      });
      return true;
    } else {
      setMfaRequestStatus({
        error: true,
        sent: false,
        loading: false,
      });
    }
    setMfaRequestStatus({
      ...mfaRequestStatus,
      loading: false,
    });
  };

  const checkIfCurrentEmailExists = async (values: FormikValues) => {
    setIsSubmitting(true);
    try {
      const validateMfa = await validateMfaCodeRequest(values.mfaCode.toUpperCase());
      const response = await checkIfEmailExists(user?.email, values?.newEmail);
      if (response?.data && validateMfa) {
        // setShowResetEmail(false);
        setCheckingEmailSent(true);
      }
    } catch (err: any) {
      setIsSubmitting(false);
      return err;
    }
    setIsSubmitting(false);
  };

  if (isSubmitting) {
    return <LoadingAnimation containerStyles={{ marginTop: "50px", height: "500px" }} />;
  }
  return (
    <Formik
      initialValues={{
        mfaCode: "",
        newEmail: "",
      }}
      validationSchema={ResetEmailValueSchema}
      onSubmit={checkIfCurrentEmailExists}
    >
      {({ values, handleSubmit }) => (
        <>
          {!checkingEmailSent ? (
            <div
              style={{ padding: mobileLayout ? "" : "100px" }}
            >
              <div>
                <Typography
                  variant="h4"
                  className="acc-white bold">Update your email address</Typography>
                <br />
                <Typography
                  variant="subtitle1"
                  className="acc-white">Type the code sent to your phone number</Typography>
                <StyledInput
                  as={AccInput}
                  fullWidth
                  name="mfaCode"
                  maskedInput
                  mask={AccMaskedInputTypes.MFA_CODE}
                  placeholder="Phone Code"
                />
                <Typography
                  variant="subtitle1"
                  className="acc-white m-top-15">Type your new email address</Typography>
                <StyledInput
                  as={AccInput}
                  fullWidth
                  type="text"
                  name="newEmail"
                  placeholder="New Email Address"
                />
              </div>
              <div className={mobileLayout ? "width-100 column" : "width-100 column end"}>
                <AccButton
                  onClick={handleSubmit}
                  width="fit-content"
                  style={{
                    width: "fit-content",
                    pointerEvents: isNil(values.mfaCode && values.newEmail) ? "none" : "",
                  }}
                  className="m-top-30"
                  label="Submit New Email Address"
                  color={(values.mfaCode.length === 6 && validateEmail(values.newEmail))
                    ? AccButtonColors.GREEN : AccButtonColors.GREEN_OUTLINE} />
              </div>
            </div>
          ) : openMessageModal ? (
            <div>
              <div
                className="width-100-mobile"
                style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: 90, marginBottom: 120 }}>
                <Typography
                  variant="h4"
                  className="acc-white bold"
                  style={{ textAlign: "center" }}>Verify your new email address</Typography>
                <br />
                <Typography
                  variant="subtitle1"
                  className="acc-white"
                >A verification email has been sent to your new email address.</Typography>
                <AccButton
                  width={mobileLayout ? "40%" : "20%"}
                  color={AccButtonColors.GREEN}
                  label="Close"
                  onClick={toggleMessageModal}
                  style={{ backgroundColor: COLORS.white, top: "30px", position: "relative" }}
                />
              </div>
            </div>
          ) : null}
        </>
      )}
    </Formik>
  );
};


const StyledInput = styled(Field)({
  "& .MuiInputBase-root": {
    color: COLORS.white,
  },
  "& label.Mui-focused": {
    color: COLORS.green,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.green,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: COLORS.white,
      color: COLORS.white,
    },
    "&.Mui-focused fieldset": {
      borderColor: COLORS.white,
      color: COLORS.white,
    },
    "&:hover fieldset": {
      borderColor: COLORS.white,
    },
    "& .MuiInputLabel-outlined": {
      color: COLORS.green,
    },
    "& .MuiSelect-iconOutlined": {
      color: COLORS.white,
    },
  },
  "& .MuiFormLabel-root": {
    color: COLORS.green,
  },
  "& .MuiOutlinedInput-input:-webkit-autofill": {
    "-webkit-box-shadow": " 0 0 0 30px #021b38 inset",
    "-webkit-text-fill-color": "#fff",
  },
});

export default ResetEmail;