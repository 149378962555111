import { makeGraphqlRequest } from "../../utils";
import { gql as gqlv2 } from "graphql-request";


export const GET_DOCUMENT_SIGNATURES_V2 = gqlv2`
query GetDocumentSignatures($investmentId: Int!) {
    getDocumentSignatures(investmentId: $investmentId) {
        helloSignReferenceId
        userId
        downloadURL
        investmentId
    }
  }
`;


export const getDocumentSignatures = (investmentId: number) => makeGraphqlRequest(GET_DOCUMENT_SIGNATURES_V2, { investmentId });