import { gql as gqlv2 } from "graphql-request";
import { makeGraphqlRequest } from "../../utils";
import gql from "graphql-tag";

export const CREATE_ADDRESS = gql`
  mutation CreateAddress($input: AddressInput!) {
    createAddress(input: $input) {
      displayName
      addressRecipientName
      street1
      street2
      city
      stateProvince
      postalCode
      country
      addressType {
        name
      }
      account {
        id
        accountId
        name
      }
    }
  }
`;

export const CREATE_ADDRESS_V2 = gqlv2`
  mutation CreateAddress($input: AddressInput!) {
    createAddress(input: $input) {
      displayName
      addressRecipientName
      street1
      street2
      city
      stateProvince
      postalCode
      country
      addressType {
        name
      }
      account {
        id
        accountId
        name
      }
    }
  }
`;

export interface CreateAddressInput {
  input: {
    displayName: string;
    addressRecipientName?: string;
    street1: string;
    street2: string;
    city: string;
    stateProvince: string;
    postalCode: string;
    country: string;
    accountObjectId: string;
    addressTypeObjectId: string;
  }
}

export const createAddress = (addressPayload: CreateAddressInput) => makeGraphqlRequest(CREATE_ADDRESS_V2, addressPayload);

export const UPDATE_ADDRESS = gql`
  mutation UpdateAddress($id: String!, $input: AddressInput!) {
    updateAddress(_id: $id, input: $input) {
      id
      displayName
      addressRecipientName
      street1
      street2
      city
      stateProvince
      postalCode
      country
      addressType {
        name
      }
      account {
        id
        accountId
        name
      }
    }
  }
`;

export const UPDATE_ADDRESS_V2 = gqlv2`
  mutation UpdateAddress($id: String!, $input: AddressInput!) {
    updateAddress(_id: $id, input: $input) {
      id
      displayName
      addressRecipientName
      street1
      street2
      city
      stateProvince
      postalCode
      country
      addressType {
        name
      }
      account {
        id
        accountId
        name
      }
    }
  }
`;

export interface UpdateAddressInput {
  id: string | undefined;
  input: {
    displayName: string;
    addressRecipientName: string;
    street1: string;
    street2: string;
    city: string;
    stateProvince: string;
    postalCode: string;
    country: string;
    accountObjectId: string;
    addressTypeObjectId: string;
  }
}

export const updateAddress = (addressPayload: UpdateAddressInput) => makeGraphqlRequest(UPDATE_ADDRESS_V2, addressPayload);

export const DELETE_ADDRESS = gql`
  mutation DeleteAddress($id: String!) {
    deleteAddress(_id: $id) {
      id
      displayName
      addressRecipientName
      street1
      street2
      city
      stateProvince
      postalCode
      country
      addressType {
        name
      }
      account {
        id
        accountId
        name
      }
    }
  }
`;

const GET_ACCOUNT_ADDRESSES_V2 = gqlv2`
  query GetAccountAddresses {
    getAccountAddresses {
      street1
      street2
      city
      stateProvince
      postalCode
      country
      addressRecipientName
      addressType {
        name
      }
    }
  }
`;

export const getAccountAddresses = () => makeGraphqlRequest(GET_ACCOUNT_ADDRESSES_V2);

const GET_ADDRESSES_V2 = gqlv2`
  query GetAddresses($accountId: Int!) {
    getAddresses(accountId: $accountId) {
      id
      addressId
      street1
      street2
      city
      stateProvince
      postalCode
      country
      addressRecipientName
      accountObjectId
      displayName
      addressType {
        name
      }
    }
  }
`;

export const getAddresses = (accountId: number) => makeGraphqlRequest(GET_ADDRESSES_V2, { accountId: accountId });

const GET_ADDRESS_V2 = gqlv2`
  query GetAddress($addressId: Int!) {
    getAddress(addressId: $addressId) {
      id
      addressId
      displayName
      street1
      street2
      city
      stateProvince
      postalCode
      country
      addressRecipientName
      accountObjectId
      addressType {
        name
        id
      }
      account {
        name
        id
        accountId
      }
    }
  }
`;

export const getAddress = (addressId: number) => makeGraphqlRequest(GET_ADDRESS_V2, { addressId: addressId });
