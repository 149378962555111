import React, { useContext, useEffect } from "react";
import { SessionContext } from "../../context";
import DashboardStepper from "./components/DashboardStepper";
import { OnboardStatuses } from "../../common/types";
import { Typography } from "@material-ui/core";
import {
  QueryKeys, useAccreditedInvestorRequests, useAssets, usePendingAccountOnboardApplications, useUserAccountTotal,
  useUserPortfolioInvestments, useUserWallets, useWalletFundings,
} from "../../api/hooks/graphqlHooks";
import { isNil, isNotNil } from "../../common/utilities";
import PageWrapper from "../../common/components/PageWrapper";
import { withErrorBoundary } from "../../common/components/ErrorBoundary";
import { emailHrefLinks } from "../../common/constants";
import AccButton, { AccButtonColors } from "../../common/components/AccButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/pro-light-svg-icons";
import PageCard from "../../common/components/PageCard";
import COLORS from "../../common/colors";
import { faExclamation, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import PortfolioAllocations from "./components/PortfolioAllocation";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import moment from "moment";
import PerformanceBar from "../../common/components/PerformanceBar";
import AvailableAssetsCard from "../../common/components/AvailableAssetsCard";


const Dashboard = withErrorBoundary(
  {
    isMainPageError: true,
    errorPageHeaderText: "Something went wrong",
    errorPageSubheaderText: "Please try to refresh page",
  },
  () => {
    const { user } = useContext(SessionContext);
    const { isLoading, isFetching } = useAssets();
    const {
      data: walletData,
      isLoading: walletDataLoading,
      isFetching: walletDataFetching,
    } = useUserWallets();
    const { data: accreditedInvestorData } = useAccreditedInvestorRequests(user?.accountObjectId, {
      enabled: isNotNil(user?.accountObjectId),
    });
    const {
      data: userAccountTotalsData,
      isLoading: userAccountTotalsDataLoading,
      isFetching: userAccountTotalsDataFetching,
    } = useUserAccountTotal({
      refetchOnMount: false,
    });
    const {
      data: getUserPortfolioInvestmentsData,
    } = useUserPortfolioInvestments({
      refetchOnMount: false,
    });

    const {
      data: pendingAccountJointApplicationsData,
    } = usePendingAccountOnboardApplications();

    const submittedPendingJointApplicationsAwaitingAction = pendingAccountJointApplicationsData?.getPendingAccountOnboardApplications
      ?.filter(item =>
        item.applicationType === "JOINT" &&
        item.onboardStatus === OnboardStatuses.PENDING_JOINT_APPLICANT &&
        item.primaryApplicantUserObjectId === user?.id,
      );

    const actionablePendingJointApplications = pendingAccountJointApplicationsData?.getPendingAccountOnboardApplications
      ?.filter(item => item.applicationType === "JOINT" && item.secondaryApplicantUserObjectId === user?.id);

    const firstWalletItemId = walletData?.getUserWallets.find(item => item)?.walletId;
    const {
      data: walletFundingsData,
    } = useWalletFundings(firstWalletItemId?.toString()!, {
      enabled: isNotNil(firstWalletItemId),
    });

    const queryClient = useQueryClient();

    const noUserInvestmentsToShow = isNil(getUserPortfolioInvestmentsData?.getUserPortfolioInvestments);
    const isStepperComplete = walletFundingsData?.getWalletFundings.length! > 0;

    //refetches the account totals/portfolio investments when user creates funding request/investment
    useEffect(() => {
      queryClient.refetchQueries([QueryKeys.USER_ACCOUNT_TOTAL]);
      queryClient.refetchQueries([QueryKeys.USER_PORTFOLIO_INVESTMENTS]);
    }, [userAccountTotalsData]);

    return (
      <PageWrapper
        title={(
          <>
            <b>Welcome to</b>&nbsp;<span className="acc-text">access</span>,&nbsp;<b>{user?.firstName}</b>
          </>
        )}
        subtitle="Let's catch you up to date, shall we?"
        showLoader={
          isLoading || isFetching || walletDataLoading || walletDataFetching ||
          userAccountTotalsDataLoading || userAccountTotalsDataFetching
        }
      >
        {actionablePendingJointApplications &&
          actionablePendingJointApplications?.length > 0 && (

            <PageCard
              className="m-top-50"
              style={{ background: COLORS.darkBlueGradient, position: "relative", overflow: "hidden" }}
            >
              {actionablePendingJointApplications?.map(item => {
                if (item.onboardStatus === OnboardStatuses.FAILURE) {
                  return (
                    <div className="row center">
                      <FontAwesomeIcon
                        size="lg"
                        className="m-right-15"
                        icon={faExclamation}
                        color={COLORS.green} />
                      <Typography className="acc-white">The <span className="acc-green acc-text">access</span> joint account application started by {item.primaryApplicant.firstName} on {moment(new Date(Number(item.submissionDate))).format("MM/DD/YYYY HH:MM")} has resulted in failure, please contact support for further information.
                      </Typography>
                    </div>
                  );
                }
                return (
                  <Link
                    key={item.accountObjectId}
                    to={`/investor/accounts/joint-onboard?existingEntity=${item.entityName}`}>
                    <div className="row center">
                      <FontAwesomeIcon
                        size="lg"
                        className="m-right-15"
                        icon={faExclamation}
                        color={COLORS.green} />
                      <Typography className="acc-white">An <span className="acc-green acc-text">access</span> joint account application has been started by {item.primaryApplicant.firstName} on {moment(new Date(Number(item.submissionDate))).format("MM/DD/YYYY HH:MM")}. Click here to complete application
                      </Typography>
                    </div>
                  </Link>
                );
              })}
            </PageCard>
          )}
        {submittedPendingJointApplicationsAwaitingAction &&
          submittedPendingJointApplicationsAwaitingAction?.length > 0 && (

            <PageCard
              className="m-top-50"
              style={{ background: COLORS.darkBlueGradient, position: "relative", overflow: "hidden" }}
            >
              {submittedPendingJointApplicationsAwaitingAction?.map(item => {
                if (item.onboardStatus === OnboardStatuses.FAILURE) {
                  return (
                    <div className="row center">
                      <FontAwesomeIcon
                        size="lg"
                        className="m-right-15"
                        icon={faExclamation}
                        color={COLORS.green} />
                      <Typography className="acc-white">The <span className="acc-green acc-text">access</span> joint account application you previously submitted on {moment(new Date(Number(item.submissionDate))).format("MM/DD/YYYY HH:MM")} has resulted in failure, please contact support for further information.
                      </Typography>
                    </div>
                  );
                }
                return (
                  <div
                    key={item.accountObjectId}
                    className="row center">
                    <FontAwesomeIcon
                      size="lg"
                      className="m-right-15"
                      icon={faInfoCircle}
                      color={COLORS.green} />
                    <Typography className="acc-white">Your application for a joint <span className="acc-text acc-green">access</span> investment account has been submitted on {moment(new Date(Number(item.submissionDate))).format("MM/DD/YYYY HH:MM")}. Awaiting secondary details from {item.secondaryApplicant.firstName}.
                    </Typography>
                  </div>
                );
              })}
            </PageCard>
          )}
        <div className="m-top-30">
          <PerformanceBar />
        </div>
        <div className="row m-top-30 column-mobile">
          <div className="width-100 m-right-50 width-100-mobile m-top-30-mobile m-0-mobile">
            <DashboardStepper
              walletData={walletData?.getUserWallets!}
              walletFundingsData={walletFundingsData}
              accreditedInvestorData={accreditedInvestorData}
            />
            <Typography
              variant="h5"
              className="bold acc-darkblue">
              New Available Opportunities*
            </Typography>
            <br />
            <AvailableAssetsCard />
          </div>
          <div className="column width-35 width-100-mobile m-0-mobile">
            {noUserInvestmentsToShow ? null :
              <div
                className="width-100"
                style={{ marginTop: isStepperComplete ? "55px" : "0px" }}>
                <PortfolioAllocations data={getUserPortfolioInvestmentsData} />
              </div>}
            <PageCard
              className="m-0-mobile m-0-tablet width-100"
              style={{
                background: COLORS.greenCornerGradient,
                padding: "45px 25px",
                width: "100%",
              }}
            >
              <Typography
                variant="h5"
                className="acc-white bold">
                Share&nbsp;<span className="acc-text">access</span>&nbsp;with a friend
              </Typography>
              <br />
              <Typography
                variant="subtitle1"
                className="light acc-white">
                Share
                {" "}
                <span className="acc-text">access</span>
                {" "}
                with a friend to get the most exclusive deals and early access to new Opportunities.
              </Typography>
              <br />
              <br />
              <div className="row stretch end">
                <a href={emailHrefLinks.shareAccessWithAFriend}>
                  <AccButton
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    color={AccButtonColors.GREEN}
                    label={(<p>Share <span className="acc-text">access</span></p>)}
                  /></a>
                <FontAwesomeIcon
                  className="acc-green"
                  size="4x"
                  icon={faGift} />
              </div>
            </PageCard>
          </div>
        </div>
        <div
          className="row width-70 width-100-mobile">
          <span
            style={{ marginTop: 40, fontWeight: 300, fontSize: 10 }}>
            *Investment advisory services are provided by Access Capital Management LLC,
            an investment advisor registered (or expected to be registered) with the Securities and Exchange Commission and loan and
            other non-securities related investment services are provided by Access Key Management LLC.
          </span>
        </div>
      </PageWrapper >
    );
  });

export default Dashboard;
