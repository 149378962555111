import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../../../../common/components/PageTitle";
import AccButton, { AccButtonColors } from "../../../../common/components/AccButton";
import { verifyEmail } from "../../../../api/rest/user";
import PageWrapper from "../../../../common/components/PageWrapper";
import COLORS from "../../../../common/colors";
import BrokenCard from "../../../../assets/images/illustrations/access_404_page_illustration.png";
import { AuthContext } from "../../../../context";
import { Typography } from "@material-ui/core";
import DividerWithText from "../../../../common/components/DividerWithText";
import { isNotNil } from "../../../../common/utilities";
import Logo from "../../../../assets/images/illustrations/access_logo_regular.svg";
import LoadingAnimation from "../../../../common/components/LoadingAnimation";
import { AuthActions } from "../../../../App";
import { logOut } from "../../../../api/utils";

const EmailVerification: React.FC = () => {
  const [validUrl, setValidUrl] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [linkExpired, setLinkExpired] = useState<boolean>(false);
  const params = useParams<{ token: string }>();
  const dispatch = useContext(AuthContext);


  useEffect(() => {
    const verifyEmailUrl = async () => {
      try {
        const verifyEmailCall = await verifyEmail(params.token);
        verifyEmailCall?.data.msg === "TokenExpiredError: jwt expired" ? setLinkExpired(true) : setLinkExpired(false);
        if (verifyEmailCall?.data.success === true) {
          setValidUrl(true);
          await logOut();
          dispatch({ type: AuthActions.SIGN_OUT });
        } else {
          setValidUrl(false);
        }
      } catch (error) {
        console.log(error);
        setValidUrl(false);
      }
      setIsLoading(false);
    };
    verifyEmailUrl();
  }, [params]);

  if (isLoading) {
    return (
      <LoadingAnimation />
    );
  }

  const getYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div>
      {validUrl ? (
        <div style={styles.background as React.CSSProperties}>
          <div style={styles.container as React.CSSProperties}>
            <a href="/">
              <img
                style={{ width: "100%", paddingBottom: "30px" }}
                src={Logo}
                alt="" />
            </a>
            <div className="width-100 m-bottom-15">
              <PageTitle
                style={{ textAlign: "center", width: "400px" }}
                title={(<><b>Verified!</b></>)}
                subtitle="You have successfully verified your email address"
              />
            </div>
            <div className="width-100 m-top-30">
              <Link to="/" >
                <AccButton
                  submitOnEnter
                  id="signInButton"
                  color={isNotNil(validUrl)
                    ? AccButtonColors.GREEN
                    : AccButtonColors.WHITE}
                  label="Sign In" />
              </Link>
            </div>
            <div className="width-100 m-top-30">
              <DividerWithText>
                create your account today
              </DividerWithText>
              <Link to="/registration" >
                <AccButton
                  color={AccButtonColors.BLUE}
                  label="Sign Up"
                  className="m-top-30"
                />
              </Link>
            </div>
          </div>
          <Typography
            style={{ color: COLORS.white, margin: "10px auto", width: 320 }}>
            © {getYear()}&nbsp;
            <span style={{ color: COLORS.green }}>Access Alternative Investments, Inc.</span>
          </Typography>
        </div>
      ) : <div style={styles.parentDiv as React.CSSProperties}>
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <PageWrapper
            titleClassName="acc-white"
            wrapperClassName="column center stretch"
            wrapperStyle={styles.wrapper}>
            <img
              src={BrokenCard}
              style={{ height: "300px" }} />
          </PageWrapper>
          <Typography
            className="acc-white page-title"
            variant="h4"
            style={{ textAlign: "center", width: "500px", marginBottom: 10, fontWeight: 700 }}>{
              (<><b>{linkExpired ? "Your email address verification link has expired" : "Something went wrong"}</b></>)
            }</Typography>
          <Typography
            className="acc-lightgray page-subtitle"
            variant="h6"
            style={{ textAlign: "center", width: "500px", fontWeight: 300, paddingBottom: 50 }}>
            {linkExpired ? "Please sign in again to update your email address" : null}
          </Typography>
          <div className="width-60 m-top-30">
            <Link to="/" >
              <AccButton
                submitOnEnter
                id="signInButton"
                color={isNotNil(validUrl)
                  ? AccButtonColors.GREEN
                  : AccButtonColors.WHITE}
                label="Sign In" />
            </Link>
          </div>
        </div>
      </div>}
    </div>
  );
};

const styles = {
  wrapper: {
    padding: 60,
  },
  background: {
    background: "transparent linear-gradient(360deg, #001227 0%, #05254b 100%) 0% 0% no-repeat padding-box",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    backgroundColor: "white",
    margin: "30px",
    minWidth: 210,
    maxWidth: 400,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    padding: "40px 55px 30px 55px",
    height: 650,
    borderRadius: 16,
    boxShadow: "0px 0px 60px #00000066",
  },
  input: {
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: 16,
    opacity: 1,
    width: "100%",
    marginBottom: 25,
  },
  checkbox: {
    color: COLORS.darkBlue,
    "&.Mui-checked": {
      color: COLORS.darkBlue,
    },
  },
  parentDiv: {
    background: COLORS.darkBlueGradient,
    height: "100vh",
    position: "absolute",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
};

export default EmailVerification;