import React, { useState } from "react";
import { Box, Tab, Tabs, useMediaQuery } from "@mui/material";
import COLORS from "../../colors";
import { THEME_BREAKPOINTS } from "../../constants";

interface TabPanelProps {
    children?: React.ReactNode | string;
    index: number;
    value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {

    return (
        <div>
            {value === index && (
                <Box className="m-top-30">
                    {children}
                </Box>
            )}
        </div>
    );
};

interface Props {
    tabs: Array<{
        index: number;
        label: string;
        children: React.ReactNode;
        tabIcon?: string | React.ReactElement;
        disabled?: boolean;
    }>;
    tabValue?: number;
    setTabValue?: (arg: number) => void;
}

const TabView: React.FC<Props> = ({ tabs, tabValue, setTabValue }) => {

    const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);

    const [innerTabValue, setInnerTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue ? setTabValue(newValue) : setInnerTabValue(newValue);
    };

    return (
        <Box sx={{ width: "100%", marginTop: "50px" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    variant={mobileLayout ? "scrollable" : "fullWidth"}
                    value={tabValue ? tabValue : innerTabValue}
                    onChange={handleTabChange}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: COLORS.lightGreen,
                            zIndex: -5,
                        },
                    }}
                    sx={{
                        "& .MuiTab-root.Mui-selected": {
                            color: COLORS.darkBlue,
                            backgroundColor: COLORS.lightTeal,
                            transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                            zIndex: -5,
                        },
                    }}
                >
                    {tabs.map(tab => (
                        <Tab
                            disabled={tab.disabled}
                            disableRipple
                            label={tab.label}
                            value={tab.index}
                            key={tab.label}
                            icon={tab.tabIcon}
                            iconPosition="start"
                        />
                    ))}
                </Tabs>
            </Box>
            {tabs.map(tab => (
                <TabPanel
                    key={tab.label}
                    value={tabValue ? tabValue : innerTabValue}
                    index={tab.index}>
                    {tab.children}
                </TabPanel>
            ))}
        </Box>
    );
};

export default TabView;