import React from "react";
import { Link } from "react-router-dom";
import { Typography, useMediaQuery } from "@material-ui/core";
import AccButton, { AccButtonColors } from "../../../../common/components/AccButton";
import AccessCard from "../../../../assets/images/illustrations/access_card_3d_dark.svg";
import { formatCurrency } from "../../../../common/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FundingFormValue, FundingRequestDetail, FundingRequestTypes } from "../../../../common/types";
import { isNil } from "../../../../common/utilities";
import BrokenCard from "../../../../assets/images/illustrations/access_404_page_illustration.png";
import { useFormikContext } from "formik";
import { THEME_BREAKPOINTS } from "../../../../common/constants";

interface Props {
  fundingRequestDetails: FundingRequestDetail | null;
  fundingRequestType?: string;
}

const FundingConfirmation: React.FC<Props> = ({ fundingRequestDetails, fundingRequestType }) => {
  const { values } = useFormikContext<FundingFormValue>();
  const mobileLayout = useMediaQuery(THEME_BREAKPOINTS.mobile);
  return (
    <div
      className="row column-mobile"
      style={{ padding: 15 }}>
      {
        !mobileLayout &&
        <div className="width-40 column center">
          <img
            src={isNil(fundingRequestDetails) ? BrokenCard : AccessCard}
            style={{ height: mobileLayout ? 250 : 315 }} />
        </div>
      }
      <div
        className="width-60 column start width-100-mobile m-top-30-mobile"
        style={{ marginLeft: mobileLayout ? "" : "30px" }}>
        {isNil(fundingRequestDetails) ?
          <div
            className="column start"
            style={{ height: "100%", justifyContent: "center" }}>
            <Typography
              variant="h5"
              className="acc-white bold">
              {
                fundingRequestType === FundingRequestTypes.DEPOSIT
                  ? "Your funding request was not successful."
                  : "Your withdrawal request was not successful."
              }
            </Typography>
            <br />
            <Typography
              variant="h6"
              className="acc-white light">Please try again later.</Typography>
          </div>
          :
          <div>
            <Typography
              variant="h5"
              className="bold acc-white">
              Congratulations!
            </Typography>
            <Typography
              variant="h5"
              className="bold acc-white">
              {
                fundingRequestType === FundingRequestTypes.DEPOSIT
                  ? <p>You have successfully <br /> transferred <span className="acc-lightgreen">{" "}
                    {fundingRequestDetails?.amount === undefined ?
                      <FontAwesomeIcon icon={faSpinner} /> :
                      formatCurrency(fundingRequestDetails?.amount)}</span></p>
                  : <p>You have successfully <br /> withdrawn <span className="acc-lightgreen">{" "}
                    {values.amount === undefined ?
                      <FontAwesomeIcon icon={faSpinner} /> :
                      formatCurrency(values?.amount!)}</span></p>
              }
            </Typography>
            <br />
            <Typography
              variant="h6"
              className="bold acc-white">
              to Investment Account:
            </Typography>
            <Typography
              variant="h6"
              className="acc-lightgreen">
              {fundingRequestDetails?.wallet?.name === undefined ?
                <FontAwesomeIcon icon={faSpinner} /> :
                fundingRequestDetails?.wallet?.name}
            </Typography>
            <br />
            <Typography
              variant="subtitle2"
              className="light acc-white">
              Your funds will be available in 1 to 2 business days.
              In the meantime, you can browse opportunities available for investment.
            </Typography>
            <br />
            <Link
              to="/investor/invest"
              className="width-70">
              <AccButton
                color={AccButtonColors.GREEN}
                label="Discover Opportunities"
              />
            </Link >
          </div>
        }
      </div>
    </div>
  );
};

export default FundingConfirmation;